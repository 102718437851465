"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorType = void 0;
var ErrorType;
(function (ErrorType) {
    ErrorType["VALIDATION_FAILED"] = "VALIDATION_FAILED";
    ErrorType["UNAUTHORIZED"] = "UNAUTHORIZED";
    ErrorType["FORBIDDEN"] = "FORBIDDEN";
    ErrorType["NOT_FOUND"] = "NOT_FOUND";
    ErrorType["FAILED_TO_LOAD_PUBLIC_EMAIL_DOMAINS"] = "FAILED_TO_LOAD_PUBLIC_EMAIL_DOMAINS";
    ErrorType["INTERNAL_SERVER_ERROR"] = "INTERNAL_SERVER_ERROR";
    ErrorType["MAPPING_NOT_SUPPORTED"] = "MAPPING_NOT_SUPPORTED";
    ErrorType["UNABLE_TO_VERIFY_INTEGRATION"] = "UNABLE_TO_VERIFY_INTEGRATION";
    ErrorType["NO_APPLICATION_EVENT_HANDLER"] = "NO_APPLICATION_EVENT_HANDLER";
    ErrorType["MONITORS_QUOTA_REACHED"] = "MONITORS_QUOTA_REACHED";
    ErrorType["USERS_QUOTA_REACHED"] = "USERS_QUOTA_REACHED";
    ErrorType["CANNOT_REMOVE_ORGANIZATION_OWNER"] = "CANNOT_REMOVE_ORGANIZATION_OWNER";
    ErrorType["CONFLICT"] = "CONFLICT";
    ErrorType["STATUS_PAGE_QUOTA_REACHED"] = "STATUS_PAGE_QUOTA_REACHED";
    ErrorType["RATE_LIMIT_REACHED"] = "RATE_LIMIT_REACHED";
    ErrorType["NOTIFICATION_POLICY_QUOTA_REACHED"] = "NOTIFICATION_POLICY_QUOTA_REACHED";
    ErrorType["ON_CALL_SCHEDULE_LIMIT_REACHED"] = "ON_CALL_SCHEDULE_LIMIT_REACHED";
    ErrorType["UNEXPECTED_WORKSPACE_OBJECT"] = "UNEXPECTED_WORKSPACE_OBJECT";
    ErrorType["MEMBER_ALREADY_EXISTS"] = "MEMBER_ALREADY_EXISTS";
    ErrorType["MEMBER_ALREADY_ONBOARDED"] = "MEMBER_ALREADY_ONBOARDED";
    ErrorType["INVALID_INTEGRATION_API_KEY"] = "INVALID_INTEGRATION_API_KEY";
    ErrorType["MEMBER_ALREADY_INVITED"] = "MEMBER_ALREADY_INVITED";
    ErrorType["WORKSPACE_OBJECT_INACTIVE"] = "WORKSPACE_OBJECT_INACTIVE";
})(ErrorType || (exports.ErrorType = ErrorType = {}));

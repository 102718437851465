import { FC, HTMLProps } from 'react';
import { twMerge } from 'tailwind-merge';

export const SkeletonBlock: FC<HTMLProps<HTMLDivElement>> = ({ className }) => (
  <div
    className={twMerge(
      'my-1.5 h-2 w-full animate-background-move rounded-full bg-gradient-to-r from-m-olive-100 to-m-olive-200 bg-[size:500%]',
      className
    )}
  />
);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toInsightResponse = exports.InsightResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const types_1 = require("../types");
const memberResponse_1 = require("./memberResponse");
exports.InsightResponseDef = typebox_1.Type.Object({
    id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    accountId: typebox_1.Type.String({ format: 'uuid' }),
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    title: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    description: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    details: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    document_groups: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(typebox_1.Type.Object({
        id: typebox_1.Type.String({ format: 'uuid' }),
        name: typebox_1.Type.String(),
    }))),
    owner: (0, typebox_2.OptionalNullable)(memberResponse_1.MemberResponseDef),
    created_by: (0, typebox_2.OptionalNullable)(memberResponse_1.MemberResponseDef),
    updated_by: (0, typebox_2.OptionalNullable)(memberResponse_1.MemberResponseDef),
    created_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    updated_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    type: (0, typebox_2.OptionalNullable)(typebox_1.Type.Enum(types_1.InsightTypeVO)),
    source: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
});
const toInsightResponse = (insight) => {
    return {
        id: insight.id,
        accountId: insight.accountId,
        workspace_id: insight.workspaceId,
        title: insight.title,
        description: insight.description,
        details: insight.details,
        document_groups: insight.documentGroups,
        owner: (0, memberResponse_1.toMemberResponse)(insight.owner),
        created_by: (0, memberResponse_1.toMemberResponse)(insight.createdBy),
        updated_by: (0, memberResponse_1.toMemberResponse)(insight.updatedBy),
        created_at: insight.systemCreatedAt,
        updated_at: insight.systemUpdatedAt,
        type: insight.type,
        source: insight.source,
    };
};
exports.toInsightResponse = toInsightResponse;

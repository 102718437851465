import React, { useEffect, useMemo } from 'react';
import { useReportStore } from '../store';
import { ReportChartView, ReportChartViewProps } from '../components/ReportChartView.tsx';
import { ReportViewMode } from '../components/ReportViewModeSeparator.tsx';
import { getFilter, useFilterStore } from '../../../shared/filters/store';
import { useQueryReportsQuery } from '../data/useQueryReportsQuery.ts';
import { ReportBuilderParameters, useReportQueryBuilder } from './useReportQueryBuilder.tsx';
import { tracking, TrackingEvent } from '../../../tracking';
import { ChartType } from '@bigdelta/lib-shared';

export type ReportBuilderWithChartParameters = ReportBuilderParameters & {
  chartType: ChartType;
  chartViewProps?: Partial<ReportChartViewProps>;
};

export const useReportQueryBuilderWithChart = (parameters: ReportBuilderWithChartParameters) => {
  const { chartType, chartViewProps = {} } = parameters;
  const { builder, reportKey, filterKey, hasConfigChangedFromQuery, hasFilterChangedFromQuery, workspaceId, ready } = useReportQueryBuilder({
    ...parameters,
  });

  const {
    updateQuery,
    query: reportQueryState,
    setDefaultBreakdownSelectedValues,
    getConfigMetricId,
    queryHasSource,
    config: reportConfig,
    configHasSource,
    breakdownConfig,
    reportType,
  } = useReportStore(reportKey)((state) => state);
  const filter = useFilterStore((state) => getFilter(state, filterKey));
  const disabled = !ready || !queryHasSource();
  const reportQuery = useQueryReportsQuery({
    reportType,
    reportQueryState,
    chartType,
    metricId: getConfigMetricId(),
    workspaceId,
    disabled,
  });

  useEffect(() => {
    if (hasFilterChangedFromQuery && filter?.items.length > 0) {
      tracking.track(TrackingEvent.ReportFilterSelected, {
        'report id': parameters.reportId,
        'data type': reportConfig.metricType,
        'filter types': filter.items.map((f) => f.itemType),
      });
    }
  }, [filter, hasFilterChangedFromQuery, parameters.reportId, reportConfig.metricType]);

  const showOverlay = useMemo(
    () => reportQuery.isSuccess && (hasConfigChangedFromQuery || hasFilterChangedFromQuery),
    [hasConfigChangedFromQuery, hasFilterChangedFromQuery, reportQuery.isSuccess]
  );
  const handleGenerateReport = () => {
    tracking.track(TrackingEvent.ReportPresented, {
      'report id': parameters.reportId,
      'data type': reportConfig.metricType,
      'filter types': filter?.items.map((f) => f.itemType),
      'breakdown property id': reportConfig.breakdownProperty?.property_id,
      'breakdown property name': reportConfig.breakdownProperty?.property_name,
      'report period': reportQueryState.timeKey,
      'report granularity': reportQueryState.granularity,
    });
    updateQuery(filter?.items);
  };

  useEffect(() => {
    if (reportQuery.isSuccess) {
      setDefaultBreakdownSelectedValues(reportQuery.data);
    }
  }, [reportQuery.data, reportQuery.isSuccess, setDefaultBreakdownSelectedValues]);

  const report = (
    <ReportChartView
      reportType={reportType}
      workspaceId={workspaceId}
      chartType={chartType}
      reportViewMode={ReportViewMode.CHART}
      showOverlay={showOverlay}
      onGenerateReport={handleGenerateReport}
      reportQuery={reportQuery}
      timeGranularity={reportQueryState.granularity}
      isHasSource={configHasSource()}
      breakdownProperty={reportQueryState.breakdownProperty}
      breakdownPropertyPrefix={reportQueryState.breakdownPropertyPrefix}
      breakdownSelectedValues={breakdownConfig.breakdownSelectedValues}
      breakdownSelectedValuesColorMap={breakdownConfig.breakdownSelectedValuesColorMap}
      {...chartViewProps}
    />
  );

  return {
    report,
    builder,
    showOverlay,
    filterKey,
    reportKey,
    isInitialLoading: reportQuery.isInitialLoading,
    isSuccess: reportQuery.isSuccess,
  };
};

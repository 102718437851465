import { FC, HTMLProps, ReactNode, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const Root: FC<HTMLProps<HTMLTableElement>> = ({ children, className, ...props }) => (
  <table className={twMerge('w-full table-fixed text-left', className)} {...props}>
    {children}
  </table>
);
const BodyRow: FC<HTMLProps<HTMLTableRowElement>> = ({ children, className, ...props }) => (
  <tr className={twMerge('group/row cursor-default', className)} {...props}>
    {children}
  </tr>
);
const HeadCell: FC<HTMLProps<HTMLTableCellElement> & { resizeHandle?: ReactNode }> = ({ children, className, resizeHandle = null, ...props }) => (
  <th
    className={twMerge(
      'sticky top-0 cursor-default border border-t-0 border-m-gray-300 bg-m-white text-sm font-medium text-m-olive-500 first:border-l-0 last:border-r-0',
      className
    )}
    {...props}
  >
    <div className={twMerge('flex items-center gap-x-2 shadow-none')}>{children}</div>
    {resizeHandle}
  </th>
);

const BodyCell = forwardRef<HTMLTableCellElement, HTMLProps<HTMLTableCellElement>>(({ children, className, ...props }, ref) => (
  <td
    className={twMerge(
      'group/cell overflow-hidden text-ellipsis whitespace-nowrap border-0  text-sm [--shadow-color:theme(colors.m-gray.300)] first:border-l-0 last:border-r-0',
      className
    )}
    {...props}
    ref={ref}
  >
    {children}
  </td>
));

export { Root, BodyRow, HeadCell, BodyCell };

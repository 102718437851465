import { ButtonHTMLAttributes, forwardRef } from 'react';

// TODO: Replace with ui/Button-tertiary when it is fixed

export const DataAddButton = forwardRef<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement>>((props, ref) => (
  <button
    className="rounded-md border border-transparent bg-m-white p-1 text-m-olive-700 hover:bg-m-gray-300 disabled:bg-m-white disabled:text-m-olive-400 radix-state-open:bg-m-gray-300"
    ref={ref}
    {...props}
  ></button>
));

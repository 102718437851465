import { WorkspaceObjectType } from '@bigdelta/lib-shared';
import { FC } from 'react';
import { getWorkspaceObjectIcon } from '../../../../shared/utils/getWorkspaceObjectIcon';

interface ObjectIconProps {
  objectType?: WorkspaceObjectType | string;
}

export const ObjectIcon: FC<ObjectIconProps> = ({ objectType }) => {
  const Icon = getWorkspaceObjectIcon(objectType);

  return <Icon className="h-4 w-4 shrink-0 text-m-olive-400" />;
};

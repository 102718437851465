import { FC } from 'react';
import { TriggerType } from '../types';
import { EventsCombobox } from '../../../shared/components/EventsCombobox';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { Popover } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import { Trigger } from './ConfigTrigger';

interface EventOccurredConfigProps {
  triggerType: TriggerType;
  eventName?: string;
  onChange: (trigger: Trigger) => void;
}

export const EventOccurredConfig: FC<EventOccurredConfigProps> = ({ triggerType, eventName, onChange }) => {
  const { currentWorkspaceId } = useWorkspace();

  const handleChange = (data: { event_name: string } | null) => {
    onChange({
      triggerType,
      eventName: data?.event_name,
    });
  };

  return (
    <div>
      <label className="mb-1.5 block text-md">Event</label>
      <Popover>
        {({ close }) => (
          <Float
            portal
            offset={4}
            adaptiveWidth
            shift={{
              mainAxis: false,
              crossAxis: true,
            }}
          >
            <Popover.Button className="flex-start flex w-full items-center gap-x-2 rounded-lg border border-m-olive-100 p-2 text-sm hover:bg-m-gray-200">
              {eventName ? eventName : 'Select event..'}
            </Popover.Button>
            <Popover.Panel>
              <EventsCombobox value={eventName} onChange={handleChange} workspaceId={currentWorkspaceId} close={close} />
            </Popover.Panel>
          </Float>
        )}
      </Popover>
    </div>
  );

  return;
};

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { forwardRef } from 'react';
import { twMerge } from '../../../utils/twMerge';

export const DropdownMenuItem = forwardRef<HTMLDivElement, DropdownMenu.DropdownMenuItemProps>(({ children, className, ...props }, ref) => (
  <DropdownMenu.Item
    className={twMerge(
      'flex select-none items-center gap-x-2 rounded px-2 py-1.5 text-xs text-m-olive-800 outline-none radix-highlighted:bg-m-gray-200',
      className
    )}
    {...props}
    ref={ref}
  >
    {children}
  </DropdownMenu.Item>
));

import { FilterItem, FilterItemType } from '../filters/store';

export const validateFilterItem = (filterItem: FilterItem): boolean => {
  switch (filterItem.itemType) {
    case FilterItemType.RECORDS_PROPERTY:
      return !!filterItem.property && !!filterItem.propertyOperator && !!filterItem.data.value;
    case FilterItemType.RECORDS_EVENT_NAME:
      return !!filterItem.event && !!filterItem.eventOperator && !!filterItem.propertyRelationships[0];
    case FilterItemType.RECORDS_EVENT_PROPERTY:
      return (
        !!filterItem.property &&
        !!filterItem.propertyOperator &&
        !!filterItem.data.value &&
        !!filterItem.eventOperator &&
        !!filterItem.eventValue &&
        !!filterItem.propertyRelationships[0]?.relationshipName
      );
    case FilterItemType.EVENTS_NAME:
      return !!filterItem.data.value && !!filterItem.propertyOperator;
    case FilterItemType.EVENTS_PROPERTY:
      return !!filterItem.property && !!filterItem.propertyOperator && !!filterItem.data.value;
    case FilterItemType.EVENTS_RECORD_PROPERTY:
      return !!filterItem.property && !!filterItem.data?.value && !!filterItem.propertyRelationships.length && !!filterItem.propertyOperator;
    case FilterItemType.EVENTS_TIMEFRAME:
    default:
      return true;
  }
};

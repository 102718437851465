"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanModelCalculatedValuesDef = exports.PlanModelCalculationErrorsDef = exports.PlanModelMetricValueCalculationErrorDef = exports.PlanModelDef = exports.PlanModelPropertiesDef = exports.PlanModelConfigurationDef = exports.PlanModelMetricDef = exports.PlanModelValuesDef = exports.PlanModelValuesPeriodDef = exports.MetricValuesDef = exports.PlanModelPeriod = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const timestampedModel_1 = require("./timestampedModel");
var PlanModelPeriod;
(function (PlanModelPeriod) {
    PlanModelPeriod["MONTH"] = "month";
    PlanModelPeriod["QUARTER"] = "quarter";
    PlanModelPeriod["YEAR"] = "year";
})(PlanModelPeriod || (exports.PlanModelPeriod = PlanModelPeriod = {}));
exports.MetricValuesDef = typebox_1.Type.Object({
    actual: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
        value: typebox_1.Type.Number(),
    })),
    planned: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
        value: typebox_1.Type.Number(),
    })),
});
exports.PlanModelValuesPeriodDef = typebox_1.Type.Object({
    metrics: typebox_1.Type.Record(typebox_1.Type.String(), exports.MetricValuesDef),
});
exports.PlanModelValuesDef = typebox_1.Type.Object({
    start_values: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
        metrics: typebox_1.Type.Record(typebox_1.Type.String(), typebox_1.Type.Object({
            value: typebox_1.Type.Number(),
        })),
    })),
    period: (0, typebox_2.OptionalNullable)(typebox_1.Type.Record(typebox_1.Type.String({ format: 'date' }), exports.PlanModelValuesPeriodDef)),
});
exports.PlanModelMetricDef = typebox_1.Type.Object({
    name: typebox_1.Type.String(),
    key: typebox_1.Type.String(),
    parent_key: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    actual: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
        composition: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    })),
    planned: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
        composition: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    })),
});
exports.PlanModelConfigurationDef = typebox_1.Type.Object({
    period: (0, typebox_2.StringLiterals)(Object.values(PlanModelPeriod)),
    start_at: typebox_2.DateType,
    end_at: typebox_2.DateType,
});
exports.PlanModelPropertiesDef = typebox_1.Type.Object({
    configuration: exports.PlanModelConfigurationDef,
    metrics: typebox_1.Type.Array(exports.PlanModelMetricDef),
    values: exports.PlanModelValuesDef,
});
exports.PlanModelDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: typebox_1.Type.String({ format: 'uuid' }),
        workspace_id: typebox_1.Type.String({ format: 'uuid' }),
        name: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
        version: typebox_1.Type.Number(),
        latest: typebox_1.Type.Boolean(),
    }),
    exports.PlanModelPropertiesDef,
    timestampedModel_1.TimestampedModelDef,
]);
exports.PlanModelMetricValueCalculationErrorDef = typebox_1.Type.Object({
    message: typebox_1.Type.String(),
});
exports.PlanModelCalculationErrorsDef = typebox_1.Type.Object({
    global: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(exports.PlanModelMetricValueCalculationErrorDef)),
    metrics: typebox_1.Type.Record(typebox_1.Type.String(), typebox_1.Type.Object({
        actual: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(exports.PlanModelMetricValueCalculationErrorDef)),
        planned: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(exports.PlanModelMetricValueCalculationErrorDef)),
    })),
});
exports.PlanModelCalculatedValuesDef = typebox_1.Type.Composite([
    exports.PlanModelValuesDef,
    typebox_1.Type.Object({
        errors: (0, typebox_2.OptionalNullable)(exports.PlanModelCalculationErrorsDef),
    }),
]);

import { ComponentProps, FC, useMemo } from 'react';
import { StackedBarTimeseriesChart } from './StackedBarTimeseriesChart.tsx';
import { LineChart } from './LineChart.tsx';
import { BarElement, CategoryScale, Chart, LinearScale, LineElement, PointElement, TimeScale, Tooltip } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { QueryReportsCreateData } from '@bigdelta/lib-api-client';
import { ReportViewMode } from './ReportViewModeSeparator.tsx';
import { useHighlightedElementPropertyValue } from '../../../shared/hooks/useHighlightedElementPropertyValue.ts';
import { ChartType, ReportTypeVO, TimeGranularity } from '@bigdelta/lib-shared';

import { useChartDataMap } from '../hooks/useChartDataMap.ts';

import { Bar, Line } from 'react-chartjs-2';
import { PropertyNameObject } from '../../../shared/types.ts';
import { AliasPrefix } from '../store/index.ts';
import { VerticalBarChart } from './VerticalBarChart.tsx';

Chart.register(BarElement);
Chart.register(LineElement);

Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(PointElement);
Chart.register(LinearScale);
Chart.register(Tooltip);
Chart.register(TimeScale);

(Tooltip.positioners as any).pointer = function (_: any, evtPos: any) {
  return evtPos;
};

interface ReportChartTimeseriesProps {
  reportType: ReportTypeVO;
  chartType: ChartType;
  granularity: TimeGranularity;
  dataQueries?: QueryReportsCreateData['queries'];
  reportViewMode?: ReportViewMode;
  lineOptions?: ComponentProps<typeof Line>['options'];
  stackedBarOptions?: ComponentProps<typeof Bar>['options'];

  breakdownSelectedValues?: { property_value: string }[];
  breakdownProperty?: PropertyNameObject;
  breakdownPropertyPrefix?: AliasPrefix;
  breakdownSelectedValuesColorMap?: Record<string, string>;
}

export const ReportChart: FC<ReportChartTimeseriesProps> = ({
  reportType,
  chartType,
  granularity,
  reportViewMode,
  dataQueries,
  lineOptions,
  stackedBarOptions,
  breakdownProperty,
  breakdownSelectedValues,
  breakdownSelectedValuesColorMap,
  breakdownPropertyPrefix,
}) => {
  const highlightedElementPropertyValue = useHighlightedElementPropertyValue(breakdownSelectedValues);

  const dataMap = useChartDataMap({
    reportType,
    dataQueries,
    breakdownProperty,
    breakdownSelectedValues,
    breakdownSelectedValuesColorMap,
    breakdownPropertyPrefix,
  });

  const chartDatasets = useMemo(() => {
    return Object.keys(dataMap ?? {}).map((key) => {
      const data = { ...dataMap[key] };

      if (highlightedElementPropertyValue && key !== highlightedElementPropertyValue) {
        data.color = `${data.regularColor}30`;
      } else {
        data.color = data.regularColor;
      }

      let colorKey;
      switch (chartType) {
        case ChartType.STACKED:
          colorKey = 'backgroundColor';
          break;
        case ChartType.LINE:
          colorKey = 'borderColor';
          break;
        default:
          colorKey = 'color';
      }

      return { ...data, ...{ [colorKey]: data.color } };
    });
  }, [dataMap, highlightedElementPropertyValue, chartType]);

  const chartKey = useMemo(() => {
    return `${reportViewMode}-${JSON.stringify(chartDatasets)}`;
  }, [chartDatasets, reportViewMode]);

  if (reportType === ReportTypeVO.FUNNEL) {
    return <VerticalBarChart datasets={chartDatasets} key={chartKey} metadata={dataQueries?.[0]?.metadata} />;
  }

  if (chartType === 'stacked') {
    return (
      <StackedBarTimeseriesChart
        datasets={chartDatasets}
        key={chartKey}
        granularity={granularity}
        metadata={dataQueries?.[0]?.metadata}
        options={stackedBarOptions}
      />
    );
  }

  if (chartType === 'line') {
    return (
      <LineChart datasets={chartDatasets} key={chartKey} granularity={granularity} metadata={dataQueries?.[0]?.metadata} options={lineOptions} />
    );
  }

  return null;
};

import { ReactNode } from "react";

import { PropsWithClassName } from "../../../types";
import { twMerge } from "../../../utils/twMerge";

import { add, isAfter, isBefore, lastDayOfMonth, sub } from "date-fns";

import data from './dummy_events_breakdown.json';
import { RecordTimelineEvents } from "./RecordTimelineEvents";

type RowProps = PropsWithClassName & {
  children?: ReactNode;
}

type EventData = typeof data.result[number];

const byDate = (
  { timestamp: a }: EventData,
  { timestamp: b}: EventData
) => new Date(a).getTime() - new Date(b).getTime();

const groupByUser = (acc: Record<string, EventData[]>, event: EventData) => ({
  ...acc,
  [event.record_name]: [...(acc?.[event.record_name] ?? []), event]
});

const Row = ({ children, className, ...rest }: RowProps) => (
  <div className={twMerge("flex border-b border-r border-l items-center justify-start whitespace-nowrap flex-nowrap shrink-0 px-3 py-2.5 min-h-12", className)} {...rest}>
    {children || ' '}
  </div>
);

const DashRow = ({ children, className, ...rest }: RowProps) => (
  <Row className={twMerge("border-dashed border-l-transparent", className)} {...rest}>
    {children || ' '}
  </Row>
);

const getMonthsList = () => {
  const year = sub(new Date(), { years: 1 });

  return Array.from({ length: 12 }, (_, i) => {
    const date = add(year, { months: i + 1 });
    return `${new Intl.DateTimeFormat("en-US", { month: "short" }).format(date)} ${date.getFullYear()}`;
  });
}

export const RecordTimeline = () => {
  // const { objectSlug } = useParams();
  // const { currentWorkspaceId } = useWorkspace();

  const months = getMonthsList();
  const userEvents = data.result.reduce<Record<string, EventData[]>>(groupByUser, {});
  const users = Object.keys(userEvents);

  return (
      <div className="border-gray-200 flex flex-row">
        <div className="flex items-start justify-center flex-col w-1/5">
          <Row className="w-full border-l-transparent text-m-olive-500 text-sm font-medium">
            User
          </Row>
          {users.map((user) => (
            <Row
              className="w-full text-md text-m-olive-600 border-l-transparent"
              key={user}
            >
              {user}
            </Row>
          ))}
        </div>
        <div
          className="flex items-start justify-center flex-col w-4/5 overflow-x-auto"
        >
          <div className="flex items-center justify-center flex-row">
            {months.map((month) => {
              const monthStart = new Date(month);
              const monthEndDate = lastDayOfMonth(monthStart);
              const isBetweenDates = ({ timestamp }: EventData) => {
                const eventTime = new Date(timestamp);
                return isAfter(eventTime, monthStart) && isBefore(eventTime, monthEndDate)
              };

              return (
                <div
                  key={month}
                  className="flex items-center justify-center flex-col even:bg-m-gray-300"
                >
                  <DashRow className="w-full text-sm border-x-2 text-m-olive-500">
                    {month}
                  </DashRow>
                  {users.map(user => {
                    const monthEvents = userEvents[user]
                      .filter(isBetweenDates)
                      .sort(byDate);

                    return (
                      <DashRow
                        key={`${month}-${user}`}
                        className="w-full border-x-2 justify-center"
                      >
                        {monthEvents.length && (
                          <RecordTimelineEvents monthEvents={monthEvents} />
                        )}
                      </DashRow>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
  );
}
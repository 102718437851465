import { ChartType, PanelSize, ReportTypeVO, TimeGranularity } from '@bigdelta/lib-shared';
import { RecordProfilePanelBase } from './RecordProfilePanelBase';
import { ObjectsProfileLayoutsDetailData } from '@bigdelta/lib-api-client';
import { ReportChart } from '../../reports/components/ReportChart';
import { FC } from 'react';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';

interface RecordProfilePanelTableProps {
  panel: ObjectsProfileLayoutsDetailData['tabs'][number]['panels'][number];
}

export const RecordProfilePanelChart: FC<RecordProfilePanelTableProps> = ({ panel }) => {
  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();

  const chartQueryPayload = panel.data.chart?.query;

  const chartQuery = useQuery({
    queryKey: queryKeys.single('object', 'profile', 'panel', panel.name, chartQueryPayload, 'chart'),
    queryFn: () => bigdeltaAPIClient.v1.queryReportsCreate({ workspace_id: currentWorkspaceId }, chartQueryPayload!),
    enabled: !!chartQueryPayload,
  });

  return (
    <RecordProfilePanelBase title={panel.name} size={panel.size as PanelSize}>
      <ReportChart
        reportType={ReportTypeVO.CHART}
        chartType={ChartType.LINE}
        dataQueries={chartQuery.data?.queries}
        granularity={TimeGranularity.DAY}
        breakdownProperty={undefined}
        breakdownPropertyPrefix={undefined}
        breakdownSelectedValues={undefined}
        breakdownSelectedValuesColorMap={undefined}
        lineOptions={{
          scales: {
            y: {
              position: 'right',
              ticks: {
                maxTicksLimit: 3,
              },
            },
            x: {
              display: false,
            },
          },
        }}
        stackedBarOptions={{
          scales: {
            y: {
              position: 'right',
              ticks: {
                maxTicksLimit: 3,
              },
            },
            x: {
              display: false,
            },
          },
        }}
      />
    </RecordProfilePanelBase>
  );
};

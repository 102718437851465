import { CodeSnippet } from '../../../../shared/components/CodeSnippet';
import { Label } from './Label';
import { StepDescription } from './StepDescription';
import { StepHeading } from './StepHeading';

const CodeBlock = ({ label, code }) => (
  <div className="flex flex-col gap-2">
    <Label>{label}</Label>
    <CodeSnippet code={code} />
  </div>
);

export const Step4TrackCustomers: React.FC<{ trackingKey: string | null }> = ({ trackingKey: trackingKey }) => {
  const scriptSnippet = `<script type="application/javascript" src="https://cdn.jsdelivr.net/npm/@bigdelta/bigdelta-browser/dist/index.iife.min.js"></script>
<script type="text/javascript">
new Bigdelta({ trackingKey: '${
    trackingKey || '<TRACKING_KEY>'
  }', defaultTrackingConfig: { pageViews: { enabled: true, singlePageAppTracking: 'any' }, forms: { enabled: true }} });
</script>`;

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <StepHeading>Start tracking your customers</StepHeading>
        <StepDescription>
          By placing our tracker on your website and/or product, you'll be able to instantly collect visitor and user data, create profiles and
          populate your workspace
        </StepDescription>
      </div>
      <CodeBlock label="Copy code snippet / Send to your developer" code={scriptSnippet} />
    </div>
  );
};

import { RelationshipsListData } from '@bigdelta/lib-api-client';
import { Attribute } from '../filters/store';

interface GetObjectRelationshipToEventArgs {
  relationship: RelationshipsListData['relationships'][number];
  propertyName: string;
}

export const getRelatingAttributeByName = ({ relationship, propertyName }: GetObjectRelationshipToEventArgs) => {
  let attribute: Attribute | null = null;

  if (relationship.first_entity_property === propertyName) {
    if (!relationship.first_entity_id) {
      return null;
    }

    attribute = {
      attributeName: relationship.first_entity_property,
      attributeId: relationship.first_entity_id,
      attributeType: relationship.first_entity_type,
    };
  }
  if (relationship.second_entity_property === propertyName) {
    if (!relationship.second_entity_id) {
      return null;
    }

    attribute = {
      attributeName: relationship.second_entity_property,
      attributeId: relationship.second_entity_id,
      attributeType: relationship.second_entity_type,
    };
  }
  return attribute;
};

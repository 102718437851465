"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegrationDataBatchVODef = void 0;
const types_1 = require("../types");
const typebox_1 = require("@sinclair/typebox");
const generalIntegrationSettingsVO_1 = require("./generalIntegrationSettingsVO");
exports.IntegrationDataBatchVODef = typebox_1.Type.Object({
    createdAt: typebox_1.Type.String({ format: 'date-time' }),
    accountId: typebox_1.Type.String({ format: 'uuid' }),
    workspaceId: typebox_1.Type.String({ format: 'uuid' }),
    integrationId: typebox_1.Type.String({ format: 'uuid' }),
    integrationType: typebox_1.Type.Enum(types_1.IntegrationType),
    batchId: typebox_1.Type.String({ format: 'uuid' }),
    batchType: typebox_1.Type.Enum(types_1.IntegrationBatchType),
    fromTimestamp: typebox_1.Type.String({ format: 'date-time' }),
    toTimestamp: typebox_1.Type.String({ format: 'date-time' }),
    settings: generalIntegrationSettingsVO_1.GeneralIntegrationSettingsVODef,
});

import { FC, useMemo } from 'react';
import MetricIcon from '../../../assets/icons/calculator.svg?react';
import { Button } from '../../../shared/ui/Button/Button';

import ArrowUpIcon from '../../../assets/icons/arrow-up-right.svg?react';
import { pluralize } from '../../../shared/utils/pluralize';
import { IntegrationsListData } from '@bigdelta/lib-api-client';
import { Link, useNavigate } from 'react-router-dom';
import { toastSuccess } from '../../../utils/toast';
import { TrackingEvent, tracking } from '../../../tracking';
import { useUser } from '../../auth/hooks/useUser.ts';
import { IntegrationStatus } from '@bigdelta/lib-shared';
import { AppRoutes } from '../../../routes';
import { SVGComponent } from '../../../types.ts';

interface IntegrationRowProps {
  name: string;
  description: string;
  icon: SVGComponent;
  docs?: string;
  records: Array<{ icon: SVGComponent; label: string }>;
  metrics: string[];
  available: boolean;
  data?: IntegrationsListData[number];
  onConnect?: () => void;
  onShowMetrics?: (name: string) => void;
}

export const IntegrationRow: FC<IntegrationRowProps> = ({
  name,
  description,
  icon,
  docs,
  records,
  metrics,
  available,
  data,
  onConnect,
  onShowMetrics,
}) => {
  const IntegrationIcon = icon;
  const { user } = useUser();
  const navigate = useNavigate();

  const integration = useMemo(() => {
    if (data?.status === IntegrationStatus.BROKEN) {
      return null;
    } else {
      return data;
    }
  }, [data]);

  const handleRequestIntegration = (name: string) => {
    tracking.track(TrackingEvent.IntegrationRequested, { 'integration name': name, email: user.email });
    toastSuccess('Thanks, integration request sent!', '');
  };

  return (
    <tr className="border-t border-m-olive-100">
      <td className="py-3.5">
        <div className="flex min-w-72 gap-x-3">
          <IntegrationIcon className="h-12 w-12 shrink-0" />
          <div>
            <div className="text-lg font-medium">{name}</div>
            <div className="text-md">{description}</div>
          </div>
        </div>
      </td>
      <td className="w-full py-3.5">
        <div className="flex items-center gap-x-8 px-4">
          {records.map(({ icon, label }) => {
            const RecordIcon = icon;
            return (
              <div className="flex items-center gap-x-2" key={label}>
                <RecordIcon className="h-4 w-4" />
                <span className="text-xs font-medium">{label}</span>
              </div>
            );
          })}
          {metrics.length > 0 && (
            <div className="flex items-center gap-x-2">
              <MetricIcon className="h-4 w-4" />
              <span className="text-xs font-medium">
                <span>
                  {metrics.length} ready to use {pluralize(metrics.length, 'metric')}{' '}
                  {available && (
                    <a
                      href="#"
                      className="text-m-blue-600 hover:text-m-blue-700"
                      onClick={() => {
                        onShowMetrics && onShowMetrics(name);
                      }}
                    >
                      (show)
                    </a>
                  )}
                </span>
              </span>
            </div>
          )}
        </div>
      </td>
      <td className="flex items-center justify-end gap-x-2 py-3.5">
        {!integration && available && (
          <>
            {docs && (
              <Link target="_blank" to={docs}>
                <Button intent="secondary" trailingIcon={ArrowUpIcon} label="Read docs" size="md" />
              </Link>
            )}
            {data?.status !== IntegrationStatus.BROKEN && <Button intent="brand" onClick={onConnect} label="Connect" size="md" />}
            {data?.status === IntegrationStatus.BROKEN && <Button intent="destructive" onClick={onConnect} label="Reconnect" size="md" />}
          </>
        )}
        {!integration && !available && (
          <Button intent="secondary" onClick={() => handleRequestIntegration(name)} label="Request integration" size="md" />
        )}
        {integration && (
          <>
            {docs && (
              <Link target="_blank" to={docs}>
                <Button intent="secondary" trailingIcon={ArrowUpIcon} label="Read docs" size="md" />
              </Link>
            )}
            {data?.status === IntegrationStatus.ENABLED && (
              <Button intent="primary" onClick={() => navigate(`${AppRoutes.INTEGRATIONS}/${data?.id}`)} label="Manage" size="md" />
            )}
            {data?.status === IntegrationStatus.DRAFT && <Button intent="primary" onClick={onConnect} label="Continue" size="md" />}
          </>
        )}
      </td>
    </tr>
  );
};

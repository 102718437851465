import { useQuery } from '@tanstack/react-query';

import { useQueryKeys } from '../../features/auth/hooks/useQueryKeys.ts';
import { bigdeltaAPIClient } from '../../client/bigdeltaAPIClient.ts';

interface UseRelationshipsQueryArgs {
  workspaceId?: string;
}

export const useRelationshipsQuery = ({ workspaceId }: UseRelationshipsQueryArgs) => {
  const queryKeys = useQueryKeys();

  return useQuery({
    queryKey: queryKeys.list('object-relationship', workspaceId),
    queryFn: () => bigdeltaAPIClient.v1.relationshipsList({ workspace_id: workspaceId ?? '' }),
    enabled: !!workspaceId,
  });
};

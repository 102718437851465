"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthorDef = exports.AuthorType = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
var AuthorType;
(function (AuthorType) {
    AuthorType["member"] = "member";
    AuthorType["tracking_key"] = "tracking_key";
    AuthorType["api_key"] = "api_key";
    AuthorType["integration"] = "integration";
    AuthorType["automation"] = "automation";
    AuthorType["system"] = "system";
})(AuthorType || (exports.AuthorType = AuthorType = {}));
exports.AuthorDef = typebox_1.Type.Object({
    type: typebox_1.Type.Enum(AuthorType),
    id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
});

import { FC, ReactNode } from 'react';

export interface DataTableEmptyProps {
  heading: string;
  description?: string;
  actionSlot?: ReactNode;
}

export const DataTableEmpty: FC<DataTableEmptyProps> = ({ heading, description, actionSlot }) => (
  <div className="sticky left-0 top-0 flex justify-center text-center">
    <div className="flex max-w-2xl flex-col items-center justify-center gap-y-4 py-16">
      <div className="flex flex-col items-center justify-center">
        <h2 className="text-2xl font-semibold text-m-olive-800">{heading}</h2>
        {description && <p className="text-lg text-m-olive-500">{description}</p>}
      </div>
      {actionSlot}
    </div>
  </div>
);

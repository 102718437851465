import * as Checkbox from '@radix-ui/react-checkbox';
import { Label } from '@radix-ui/react-dropdown-menu';

interface TagCloudProps {
  label: string;
  tags: string[];
  onChange: (tags: string[]) => void;
  value: string[];
}

export const TagCloud: React.FC<TagCloudProps> = ({ label, tags, onChange, value = [] }) => (
  <div className="flex flex-col gap-2">
    <Label>{label}</Label>
    <div className="flex flex-wrap gap-2">
      {tags.map((tag) => (
        <Checkbox.Root
          key={tag}
          className="group"
          checked={value.includes(tag)}
          onCheckedChange={(checked) => {
            if (checked) {
              onChange([...value, tag]);
            } else {
              onChange(value.filter((t) => t !== tag));
            }
          }}
          asChild
        >
          <div
            className={`cursor-pointer rounded-full bg-m-beige-200 px-3 py-1 text-sm font-medium
              text-m-olive-600 transition-colors hover:bg-m-beige-300
              radix-state-checked:bg-m-blue-600 radix-state-checked:text-m-white`}
          >
            {tag}
          </div>
        </Checkbox.Root>
      ))}
    </div>
  </div>
);

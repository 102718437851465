"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetricGraphEdgeVODef = void 0;
const timestampedModel_1 = require("../model/timestampedModel");
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
exports.MetricGraphEdgeVODef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        workspace_id: typebox_1.Type.String({ format: 'uuid' }),
        graph_id: typebox_1.Type.String({ format: 'uuid' }),
        parent_node_id: typebox_1.Type.String({ format: 'uuid' }),
        child_node_id: typebox_1.Type.String({ format: 'uuid' }),
        influence: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
        created_by: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        updated_by: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    }),
    timestampedModel_1.TimestampedModelDef,
]);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toProjectResponse = exports.ProjectResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const documentGroupsResponse_1 = require("./documentGroupsResponse");
const memberResponse_1 = require("./memberResponse");
exports.ProjectResponseDef = typebox_1.Type.Object({
    id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    accountId: typebox_1.Type.String({ format: 'uuid' }),
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    title: typebox_1.Type.Optional(typebox_1.Type.String()),
    description: typebox_1.Type.Optional(typebox_1.Type.String()),
    details: typebox_1.Type.Optional(typebox_1.Type.String()),
    document_groups: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(typebox_1.Type.Object({
        id: typebox_1.Type.String({ format: 'uuid' }),
        name: typebox_1.Type.String(),
    }))),
    owner: (0, typebox_2.OptionalNullable)(memberResponse_1.MemberResponseDef),
    created_by: (0, typebox_2.OptionalNullable)(memberResponse_1.MemberResponseDef),
    updated_by: (0, typebox_2.OptionalNullable)(memberResponse_1.MemberResponseDef),
    created_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    updated_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    project_metric: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
    project_ambition: typebox_1.Type.Optional(typebox_1.Type.String()),
    impact_metric: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
    impact_size: typebox_1.Type.Optional(typebox_1.Type.String()),
    solutions_and_experiments: typebox_1.Type.Optional(typebox_1.Type.String()),
});
const toProjectResponse = (project) => {
    return {
        id: project.id,
        accountId: project.accountId,
        workspace_id: project.workspaceId,
        title: project.title,
        description: project.description,
        details: project.details,
        owner: (0, memberResponse_1.toMemberResponse)(project.owner),
        document_groups: (0, documentGroupsResponse_1.toDocumentGroupResponse)(project === null || project === void 0 ? void 0 : project.documentGroups),
        created_by: (0, memberResponse_1.toMemberResponse)(project.createdBy),
        updated_by: (0, memberResponse_1.toMemberResponse)(project.updatedBy),
        created_at: project.systemCreatedAt,
        updated_at: project.systemUpdatedAt,
        project_metric: project.projectMetric,
        project_ambition: project.projectAmbition,
        impact_size: project.impactSize,
        impact_metric: project.impactMetric,
        solutions_and_experiments: project.solutionsAndExperiments,
    };
};
exports.toProjectResponse = toProjectResponse;

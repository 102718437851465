import React, { FC } from 'react';
import { useReportsStore } from '../store';
import { useReportMetric } from '../../auth/context/reportMetricContext.tsx';
import { MetricTypeSelector } from './MetricTypeSelector.tsx';

interface ReportCreateSelectMetricTypeProps {}

export const ReportCreateSelectMetricType: FC<ReportCreateSelectMetricTypeProps> = () => {
  const { inline, selectorIntent } = useReportMetric();
  const setMetricType = useReportsStore((state) => state.setMetricType);

  return <MetricTypeSelector selectorIntent={selectorIntent} inline={inline} onChange={(value) => setMetricType(value)} />;
};

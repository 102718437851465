"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuickAccessLinkRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const quickAccessLink_1 = require("../model/quickAccessLink");
exports.QuickAccessLinkRequestDef = typebox_1.Type.Omit(quickAccessLink_1.QuickAccessLinkDef, [
    'id',
    'workspace_id',
    'member_id',
    'systemCreatedAt',
    'systemUpdatedAt',
]);

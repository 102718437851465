"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toDocumentGroupResponse = exports.DocumentGroupsResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
exports.DocumentGroupsResponseDef = (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    name: typebox_1.Type.String(),
})));
const toDocumentGroupResponse = (documentsGroup) => {
    if (!documentsGroup) {
        return null;
    }
    return documentsGroup.map((document) => {
        return {
            id: document.id,
            name: document.name,
        };
    });
};
exports.toDocumentGroupResponse = toDocumentGroupResponse;

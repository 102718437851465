import { FC } from 'react';
import { signInWithGoogle } from '../../../firebase.ts';
import GoogleButton from 'react-google-button';

interface GoogleSignInProps {
  onSuccess: (idToken: string) => void;
  onError: (error: any) => void;
}

export const GoogleSignIn: FC<GoogleSignInProps> = ({ onSuccess, onError }) => {
  const handleSignIn = async () => {
    try {
      const response = await signInWithGoogle();
      onSuccess(await response.user?.getIdToken());
    } catch (error) {
      onError(error);
    }
  };

  return <GoogleButton onClick={handleSignIn} />;
};

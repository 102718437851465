import { AutomationWorkflowBlockType } from '@bigdelta/lib-shared';
import { FC } from 'react';
import { AutomationsCreateData } from '@bigdelta/lib-api-client';
import { merge } from 'lodash';

type AutomationBlock = AutomationsCreateData['configuration']['trigger']['block'];

interface ActionDelayUntilConfigProps {
  id?: string;
  type: AutomationWorkflowBlockType;
  block?: AutomationBlock;
  onChange: (block: AutomationBlock) => void;
}

//TODO: Loading state
export const ActionDelayUntilConfig: FC<ActionDelayUntilConfigProps> = ({ id, block, onChange }) => {
  const handleChangeDatetime = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(
      merge(block, {
        id,
        type: AutomationWorkflowBlockType.DELAY_UNTIL,
        delay_until: {
          datetime: new Date(event.target.value).toISOString(),
        },
      })
    );
  };

  return (
    <div>
      <div className="flex flex-col items-stretch">
        <div className="flex flex-col gap-y-6">
          <div className="flex flex-col gap-y-2">
            <label className="text-md">Delay until</label>
            <input
              type="datetime-local"
              className="resize-none rounded-lg border border-m-olive-100 px-2.5 py-2 text-md"
              onChange={handleChangeDatetime}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

import { useMutation } from '@tanstack/react-query';

import { UpdateMemberRequest } from '@bigdelta/lib-shared';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

interface UseUpdateMemberMutationParameters {
  onSuccess?: () => void;
}

interface UpdateMemberMutationParameters {
  memberId: string;
  data: UpdateMemberRequest;
}

export const useUpdateProfileMutation = ({ onSuccess }: UseUpdateMemberMutationParameters = {}) => {
  return useMutation({
    mutationFn: ({ memberId, data }: UpdateMemberMutationParameters) => {
      return bigdeltaAPIClient.v1.membersPartialUpdate(memberId, {
        ...data,
        preferred_timezone: data.preferred_timezone as any,
      });
    },
    onSuccess,
  });
};

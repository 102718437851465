import { useReportMetric } from '../../auth/context/reportMetricContext.tsx';

export const FormulaMetricReference = ({ name = null }) => {
  const { formulaReference } = useReportMetric();
  const finalName = name || formulaReference;
  if (!finalName) {
    return null;
  }

  return <div className="rounded-md bg-m-olive-100 px-2 py-1 text-sm font-bold leading-4 text-m-olive-500">{finalName}</div>;
};

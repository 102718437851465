import { ObjectsListData, RelationshipsListData, ReportsDetailData } from '@bigdelta/lib-api-client';
import { TrendsBuilderInitialData } from '../../../store/TrendsBuilder';
import { getBuilderFromMetricMetric } from './getBuilderFromMetricMetric';
import { getBuilderFromEventMetric } from './getBuilderFromEventMetric';
import { getBuilderFromRecordMetric } from './getBuilderFromRecordMetric';
import { getBuilderFromFormulaMetric } from './getBuilderFromFormulaMetric';

export const getTrendsBuilderStateFromSavedReport = (
  queryMetric: NonNullable<ReportsDetailData['query']>['metrics'][number],
  workspaceId: string,
  relationships: RelationshipsListData['relationships'],
  objects: ObjectsListData['objects']
): TrendsBuilderInitialData | undefined => {
  if (queryMetric.records) {
    return getBuilderFromRecordMetric(queryMetric, workspaceId, relationships, objects);
  }

  if (queryMetric.events) {
    return getBuilderFromEventMetric(queryMetric, relationships);
  }

  if (queryMetric.metric) {
    return getBuilderFromMetricMetric(queryMetric);
  }

  if (queryMetric.formula) {
    return getBuilderFromFormulaMetric(queryMetric);
  }
};

import { FC } from 'react';
import { PropsWithClassName } from '../../../types';
import { TriggerTypeIconMap } from '../const';
import { TriggerType } from '../types';

interface TriggerIconProps extends PropsWithClassName {
  triggerType: TriggerType;
}

export const TriggerIcon: FC<TriggerIconProps> = ({ triggerType, className }) => {
  const Icon = TriggerTypeIconMap[triggerType];

  return <Icon className={className} />;
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportTypeVO = void 0;
var ReportTypeVO;
(function (ReportTypeVO) {
    ReportTypeVO["CHART"] = "CHART";
    ReportTypeVO["FUNNEL"] = "FUNNEL";
    ReportTypeVO["RETENTION"] = "RETENTION";
    ReportTypeVO["MAPS"] = "MAPS";
    ReportTypeVO["TEMPLATE"] = "TEMPLATE";
})(ReportTypeVO || (exports.ReportTypeVO = ReportTypeVO = {}));

import React, { FC, HTMLAttributes, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { Select } from '../../../../shared/ui/Select/Select.tsx';
import { FormElementWrapper } from '../../../../shared/ui/FormElementWrapper/FormElementWrapper.tsx';
import { ResourcePropertyType } from '@bigdelta/lib-shared';
import { Button } from '../../../../shared/ui/Button/Button.tsx';
import { PropertyFormSchema } from './PropertyFormSchema.ts';
import { FormInput } from '../../../../shared/ui/FormInput/FormInput.tsx';
import { mapToUserFriendlyDataType } from '../../utils/propertyTypeMapper.ts';

interface Props extends HTMLAttributes<HTMLElement> {
  initialValues?: PropertyFormSchema;
  reservedNames: string[];
  onCancel: () => void;
  renderSubmitButton?: (isValid: boolean, handleSubmit: (handler: (data: PropertyFormSchema) => any) => any) => React.ReactNode;
}

export const PropertyForm: FC<Props> = ({ className, initialValues, reservedNames, onCancel, renderSubmitButton }) => {
  const {
    register,
    formState: { errors, isValid },
    reset,
    handleSubmit,
    watch,
    setError,
    clearErrors,
  } = useForm<PropertyFormSchema>({
    defaultValues: initialValues,
  });

  useEffect(() => {
    reset(initialValues);
  }, [initialValues, reset]);

  const name = watch('name');

  useEffect(() => {
    if (reservedNames.includes(name === 'id' ? '$met_remote_id' : name)) {
      setError('name', { type: 'custom', message: `Such property already exists!` });
    } else {
      clearErrors('name');
    }
  }, [name, reservedNames, setError, clearErrors]);

  return (
    <div className={twMerge('relative flex flex-col gap-4 px-6 pb-6', className)}>
      <FormInput
        label="Name"
        inputProps={{
          ...register('name', { required: true }),
          placeholder: 'Name',
          size: 'sm',
        }}
        size="sm"
        errorMessage={errors.name?.message}
      />
      <FormElementWrapper label="Type" errorMessage={errors.type?.message} size="sm">
        <Select {...register('type', { required: true })} className="text-xs">
          {Object.values(ResourcePropertyType).map((type) => (
            <option key={type} value={type}>
              {mapToUserFriendlyDataType(type)}
            </option>
          ))}
        </Select>
      </FormElementWrapper>
      <div className="flex flex-col text-sm font-regular">
        <div className="flex flex-row items-center gap-2">
          <input type="checkbox" className="rounded border border-m-olive-200" {...register('required')} />
          <label>Required</label>
        </div>
        {errors.required?.message && <div className="mt-1 text-sm text-m-red-700">{errors.required?.message}</div>}
      </div>
      <div className="flex justify-end gap-x-2">
        <Button size="sm" intent="tertiary" onClick={onCancel} label="Cancel" />
        {renderSubmitButton &&
          renderSubmitButton(isValid && !errors.name?.message && !errors.type?.message && !errors.required?.message, (handler) =>
            handleSubmit(handler)
          )}
      </div>
    </div>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetadataEntityEventResponseDef = exports.EventGroup = void 0;
const typebox_1 = require("@sinclair/typebox");
var EventGroup;
(function (EventGroup) {
    EventGroup["ACTIONS"] = "ACTIONS";
    EventGroup["REVENUE"] = "REVENUE";
})(EventGroup || (exports.EventGroup = EventGroup = {}));
exports.MetadataEntityEventResponseDef = typebox_1.Type.Object({
    event_name: typebox_1.Type.String(),
    event_group: typebox_1.Type.Enum(EventGroup),
});

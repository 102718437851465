import { Combobox as ComboboxPrimitive } from '@headlessui/react';
import { PropsWithChildren, forwardRef } from 'react';
import { PropsWithClassName } from '../../types';
import { twMerge } from '../../utils/twMerge.ts';

interface ComboboxOptionsProps extends PropsWithChildren, PropsWithClassName {
  virtualHeight: number;
  maxHeight: number;
}

export const ComboboxOptions = forwardRef<HTMLUListElement, ComboboxOptionsProps>(({ children, virtualHeight, maxHeight, className = '' }, ref) => (
  <ComboboxPrimitive.Options static className={twMerge('overflow-y-scroll bg-m-white', className)} ref={ref} style={{ maxHeight: `${maxHeight}px` }}>
    <div
      className="relative w-full"
      style={{
        height: `${virtualHeight}px`,
      }}
    >
      {children}
    </div>
  </ComboboxPrimitive.Options>
));

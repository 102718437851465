import { Cell, CellContext, Row, flexRender } from '@tanstack/react-table';
import * as Table from '../../../components/Table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { SkeletonBlock } from '../../../components/SkeletonBlock';

import ArrowUpIcon from '../../../assets/icons/arrow-up.svg?react';
import ArrowDownIcon from '../../../assets/icons/arrow-down.svg?react';
import { twMerge } from 'tailwind-merge';
import { Tooltip } from 'react-tooltip';
import { Link, To } from 'react-router-dom';
import { ConditionalWrap } from '../../utils/ConditionalWrap';

const skeletonHeaderCols = [...Array(5).keys()];

const DataTableHeaderSkeleton = () => {
  return (
    <thead>
      <tr>
        {skeletonHeaderCols.map((item) => (
          <Table.HeadCell key={item}>
            <SkeletonBlock />
          </Table.HeadCell>
        ))}
      </tr>
    </thead>
  );
};

const skeletonRows = [...Array(10).keys()];

interface DataTableBodySkeletonProps {
  cols: any[];
}

interface DataTableCellProps {
  cell: Cell<any, unknown>;
  index: number;
  cellIndex: number;
  measureElement: (element: HTMLElement | null) => void;
  onCellMouseOver?: (e: React.MouseEvent<HTMLTableCellElement>, context: CellContext<any, any>) => void;
  link?: (row: Row<any>) => To;
}

const DataTableCell: FC<DataTableCellProps> = ({ cell, index, cellIndex, measureElement, onCellMouseOver, link }) => {
  const cellRef = useRef<HTMLTableCellElement | null>(null);

  const onRefChange = useCallback(
    (element: HTMLElement | null) => {
      measureElement(element);
      cellRef.current = element as HTMLTableCellElement;
    },
    [measureElement]
  );

  const isTruncated = cellRef.current ? cellRef.current?.scrollWidth > cellRef.current?.offsetWidth : false;

  const value = cell.getValue();
  const isValueString = typeof value === 'string';

  return (
    <Table.BodyCell
      data-index={index}
      ref={onRefChange}
      key={cell.id}
      data-tooltip-id={cell.id}
      className={twMerge(
        'bg-m-white group-hover/row:bg-m-gray-200',
        (cell.column.columnDef.meta as any)?.cellClassName,
        cell.column.getIsPinned() && 'sticky left-0 z-10',
        link && 'overflow-hidden p-0',
        cellIndex > 0 && 'shadow-border',
        cellIndex === 0 && 'shadow-[inset_-0.5px_-0.5px_0px_0.25px_var(--shadow-color)]'
      )}
      onMouseOver={(e) => onCellMouseOver && onCellMouseOver(e, cell.getContext())}
    >
      <ConditionalWrap
        condition={!!link}
        wrap={(children) => (
          <Link to={link ? link(cell.row) : ''} className="block truncate">
            {children}
          </Link>
        )}
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </ConditionalWrap>

      {isValueString && isTruncated && (
        <Tooltip id={cell.id} className="z-50" positionStrategy="fixed" place="right" delayShow={400}>
          {cell.getValue() as string}
        </Tooltip>
      )}
    </Table.BodyCell>
  );
};

const DataTableBodySkeleton: FC<DataTableBodySkeletonProps> = ({ cols }) => {
  return (
    <tbody>
      {skeletonRows.map((rowItem) => (
        <tr key={rowItem}>
          {cols.map((item, index) => (
            <Table.BodyCell key={`${index}-${item}`} className={twMerge('px-3 py-2')}>
              <SkeletonBlock />
            </Table.BodyCell>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

// TODO: replace any to a generic
interface DataTableProps {
  isDataFetching: boolean;
  isHeaderFetching?: boolean;
  table: import('@tanstack/table-core').Table<any>;
  tableContainerRef: React.RefObject<HTMLDivElement>;
  onRowClick?: (data: any) => void;
  onCellMouseOver?: (e: React.MouseEvent<HTMLTableCellElement>, context: CellContext<any, any>) => void;
  onRowMouseEnter?: (data: any) => void;
  onRowMouseLeave?: (data: any) => void;
  link?: (row: Row<any>) => To;
}

export const DataTable: FC<DataTableProps> = ({
  isDataFetching,
  isHeaderFetching = false,
  table,
  tableContainerRef,
  onRowClick,
  onCellMouseOver,
  onRowMouseEnter,
  onRowMouseLeave,
  link,
}) => {
  const [, setContainerAvailable] = useState(false);
  const headerGroups = table.getHeaderGroups();
  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => tableContainerRef?.current,
    overscan: 25,
    estimateSize: () => 45,
    count: rows.length,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();
  const totalSize = rowVirtualizer.getTotalSize();

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

  useEffect(() => {
    if (tableContainerRef?.current) {
      setContainerAvailable(true);
    }
  }, [tableContainerRef]);

  const showHeaderSkeleton = !table.getLeafHeaders().length && isHeaderFetching;

  const bodySkeletonCols = showHeaderSkeleton
    ? skeletonHeaderCols
    : table
        .getAllLeafColumns()
        .filter((_, i) => i > 0)
        .map(({ id }) => id);

  return (
    <Table.Root>
      {showHeaderSkeleton && <DataTableHeaderSkeleton />}
      {!!table.getLeafHeaders().length && (
        <thead className="sticky top-0 z-40">
          {headerGroups.map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => (
                <Table.HeadCell
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{ width: header.getSize() }}
                  className={twMerge(
                    'group border-0 [--shadow-color:theme(colors.m-gray.300)]',
                    (header.column.columnDef.meta as any)?.headerClassName,
                    header.column.getIsPinned() && 'sticky left-0 z-20',
                    index > 0 && 'shadow-[inset_0px_-0.5px_0px_0.25px_var(--shadow-color)]',
                    index === 0 && 'shadow-[inset_-0.5px_-0.5px_0px_0.25px_var(--shadow-color)]'
                  )}
                  onClick={header.column.getToggleSortingHandler()}
                  resizeHandle={
                    header.column.getCanResize() && (
                      <div
                        onDoubleClick={() => header.column.resetSize()}
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        className="absolute right-0 top-0 min-h-full w-1 cursor-col-resize bg-m-gray-300 opacity-0 group-hover:opacity-100"
                      />
                    )
                  }
                >
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}

                  {(header.column.getCanSort() &&
                    {
                      asc: <ArrowUpIcon className="h-4 w-4" />,
                      desc: <ArrowDownIcon className="h-4 w-4" />,
                    }[header.column.getIsSorted() as string]) ??
                    null}
                </Table.HeadCell>
              ))}
            </tr>
          ))}
        </thead>
      )}
      {!!rows.length && !showHeaderSkeleton && (
        <tbody>
          {paddingTop > 0 && (
            <tr>
              <td style={{ height: `${paddingTop}px` }} />
            </tr>
          )}
          {virtualRows.map((virtualRow) => {
            const row = rows[virtualRow.index];

            return (
              <Table.BodyRow
                key={row.id}
                onClick={() => onRowClick && onRowClick(row)}
                onMouseEnter={() => onRowMouseEnter && onRowMouseEnter(row)}
                onMouseLeave={() => onRowMouseLeave && onRowMouseLeave(row)}
              >
                {row.getVisibleCells().map((cell, idx) => (
                  <DataTableCell
                    key={cell.id}
                    cell={cell}
                    index={virtualRow.index}
                    measureElement={rowVirtualizer.measureElement}
                    onCellMouseOver={onCellMouseOver}
                    link={link}
                    cellIndex={idx}
                  />
                ))}
              </Table.BodyRow>
            );
          })}
          {paddingBottom > 0 && (
            <tr>
              <td style={{ height: `${paddingBottom}px` }} />
            </tr>
          )}
        </tbody>
      )}
      {(isDataFetching || isHeaderFetching) && <DataTableBodySkeleton cols={bodySkeletonCols} />}
    </Table.Root>
  );
};

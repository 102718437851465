import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../../features/auth/hooks/useQueryKeys.ts';
import { bigdeltaAPIClient } from '../../client/bigdeltaAPIClient.ts';

interface UseObjectsQueryArgs {
  workspaceId?: string;
}

export const useObjectsQuery = ({ workspaceId }: UseObjectsQueryArgs) => {
  const queryKeys = useQueryKeys();

  return useQuery({
    queryKey: queryKeys.list('object'),
    queryFn: () => bigdeltaAPIClient.v1.objectsList({ workspace_id: workspaceId ?? '' }),
    enabled: !!workspaceId,
  });
};

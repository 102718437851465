import GridIcon from '../../../assets/icons/grid-01.svg?react';
import { Button } from '../../../shared/ui/Button/Button';

export const AppsPage = () => {
  return (
    <div className="flex h-screen w-full items-center justify-center">
      <div className="flex flex-col items-center">
        <GridIcon height="3.5rem" width="3.5rem" />
        <div className="mt-5 text-2xl text-m-olive-700">No connected apps yet</div>
        <div className="mt-2 text-lg text-m-olive-400">Connect apps to get data from various tools.</div>
        <Button size="lg" intent="primary" label="Explore Appstore" />
      </div>
    </div>
  );
};

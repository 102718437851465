import { FC, ReactElement } from 'react';

interface SlackNodeContentProps {
  warning: ReactElement;
  slackChannelName?: string;
}

export const SlackNodeContent: FC<SlackNodeContentProps> = ({ warning, slackChannelName }) => {
  if (!slackChannelName) {
    return warning;
  }

  return <div className="text-sm font-regular text-m-olive-400">{`Channel: ${slackChannelName}`}</div>;
};

import { useQuery } from '@tanstack/react-query';
import { PageHeading } from '../../../components/PageHeading';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { AutomationsEmptyState } from '../components/AutomationsEmptyState';
import { AutomationsTable } from '../components/AutomationsTable';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';

import { Link } from 'react-router-dom';
import { Button } from '../../../shared/ui/Button/Button';
import { Divider } from '../../../shared/ui/Divider/Divider';
import { Container } from '../../../shared/ui/Container/Container';
import AutomationsIcon from '../../../assets/icons/dataflow-04.svg?react';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

export const AutomationsListPage = () => {
  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();

  const automationsQuery = useQuery({
    queryKey: queryKeys.automations(),
    queryFn: () => bigdeltaAPIClient.v1.automationsList({ workspace_id: currentWorkspaceId }),
  });

  const isEmpty = automationsQuery.isSuccess && automationsQuery.data.range.total === 0;

  return (
    <div className="relative flex h-full flex-col">
      <Container className="flex items-center justify-between">
        <PageHeading breadcrumbs={[{ label: 'Automations', icon: AutomationsIcon }]} />
        {!isEmpty && (
          <Link to="new">
            <Button intent="brand" size="lg" label="Create automation" />
          </Link>
        )}
      </Container>
      <Divider />
      {isEmpty && <AutomationsEmptyState />}
      {!isEmpty && automationsQuery.data && (
        <Container className="pt-3">
          <AutomationsTable data={automationsQuery.data} />
        </Container>
      )}
    </div>
  );
};

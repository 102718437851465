"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Context = void 0;
var Context;
(function (Context) {
    Context["PRODUCT"] = "PRODUCT";
    Context["REVENUE"] = "REVENUE";
    Context["MARKETING"] = "MARKETING";
    Context["SALES"] = "SALES";
})(Context || (exports.Context = Context = {}));

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toDocumentRelationshipResponse = exports.DocumentRelationshipResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_1 = require("../model");
exports.DocumentRelationshipResponseDef = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    account_id: typebox_1.Type.String(),
    workspace_id: typebox_1.Type.String(),
    first_document_type: typebox_1.Type.Enum(model_1.DocumentRelationshipDocumentType),
    first_document_workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    first_document_workspace_object_id: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
    first_document_id: typebox_1.Type.String(),
    second_document_type: typebox_1.Type.Enum(model_1.DocumentRelationshipDocumentType),
    second_document_workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    second_document_workspace_object_id: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
    second_document_id: typebox_1.Type.String(),
    created_at: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'date-time' })),
    updated_at: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'date-time' })),
});
const toDocumentRelationshipResponse = (documentRelationship) => {
    return {
        id: documentRelationship.id,
        account_id: documentRelationship.accountId,
        workspace_id: documentRelationship.workspaceId,
        first_document_type: documentRelationship.firstDocumentType,
        first_document_workspace_id: documentRelationship.firstDocumentWorkspaceId,
        first_document_workspace_object_id: documentRelationship.firstDocumentWorkspaceObjectId,
        first_document_id: documentRelationship.firstDocumentId,
        second_document_type: documentRelationship.secondDocumentType,
        second_document_workspace_id: documentRelationship.secondDocumentWorkspaceId,
        second_document_workspace_object_id: documentRelationship.secondDocumentWorkspaceObjectId,
        second_document_id: documentRelationship.secondDocumentId,
        created_at: documentRelationship.systemCreatedAt,
        updated_at: documentRelationship.systemUpdatedAt,
    };
};
exports.toDocumentRelationshipResponse = toDocumentRelationshipResponse;

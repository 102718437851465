import { FC } from 'react';
import { RelationType } from '../../../features/records/hooks/useRecordsTableColumns/utils/resolveRelations';
import { ObjectsListData } from '@bigdelta/lib-api-client';
import { renderValue } from '../utils/renderValue';
import { ObjectIcon } from '../../../features/reports/components/common/ObjectIcon';
import { createSearchParams, generatePath, Link } from 'react-router-dom';
import { RecordsRoutes } from '../../../routes';
import { MET_REMOTE_ID } from '../../../features/records/const';
import { FloatingPortal } from '@floating-ui/react';
import { useShowContentHover } from '../hooks/useShowContentHover';
import IconFromUrl from './IconFromUrl';

interface RelationProps {
  relation: RelationType;
  objectList: ObjectsListData['objects'];
}

const Relation: FC<RelationProps> = ({ relation, objectList }) => {
  const object = objectList.find((o) => o.id === relation.workspace_object_id);

  if (!object) {
    return null;
  }

  const labelProperties = object?.label_properties;

  const iconProperty = object?.icon_property;
  const objectType = object?.object_type;

  const iconUrl = iconProperty ? relation.properties?.[iconProperty] : undefined;

  const propertyValues = labelProperties.map((property) => relation.properties?.[property]);
  const propertyValue = propertyValues.find(Boolean) ?? relation.id;
  return (
    <Link
      to={{
        pathname: generatePath(RecordsRoutes.VIEW_RECORD, {
          objectSlug: object.api_slug,
          remoteRecordId: relation.properties?.[MET_REMOTE_ID],
        }),
        search: createSearchParams({ workspaceId: object.workspace_id }).toString(),
      }}
    >
      <div className="flex cursor-pointer items-center gap-x-1 rounded-md border border-m-olive-100 px-1 py-0.5 text-m-olive-600 hover:bg-m-gray-300 hover:shadow-sm">
        {!iconUrl && <ObjectIcon objectType={objectType} />}
        {iconUrl && <IconFromUrl iconUrl={iconUrl} />}
        {propertyValue && renderValue(propertyValue.toString())}
      </div>
    </Link>
  );
};

interface CellContentRelationsProps {
  relations: RelationType[];
  objectList: ObjectsListData['objects'];
}

export const CellContentRelations: FC<CellContentRelationsProps> = ({ relations, objectList }) => {
  const { floatingPortalContentProps, referenceProps, isOpen } = useShowContentHover();

  return (
    <>
      <div className="flex items-center gap-x-1 px-1" {...referenceProps}>
        {relations.map((relation) => (
          <Relation key={relation.id} relation={relation} objectList={objectList} />
        ))}
      </div>
      {isOpen && relations.length > 1 && (
        <FloatingPortal>
          <div {...floatingPortalContentProps}>
            <div className="flex flex-col gap-y-2 px-1 py-3">
              {relations.map((relation) => (
                <Relation key={relation.id} relation={relation} objectList={objectList} />
              ))}
            </div>
          </div>
        </FloatingPortal>
      )}
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRoutes } from '../../routes';
import { useQuery } from '@tanstack/react-query';
import { storeSelectedAccountId } from '../../shared/utils/selectedAccountId.ts';
import { CenteredLoader } from '../../shared/components/CenteredLoader.tsx';
import { AxiosError } from 'axios';
import { ErrorResponse } from '@bigdelta/lib-shared';
import { MembersInvitationsAcceptCreateData } from '@bigdelta/lib-api-client';
import { useUser } from '../auth/hooks/useUser.ts';
import { storeSelectedWorkspaceId } from '../../shared/utils/selectedWorkspaceId.ts';
import { tracking, TrackingEvent } from '../../tracking';
import { bigdeltaAPIClient } from '../../client/bigdeltaAPIClient.ts';

export const AcceptInvitationPage = () => {
  const { invitationToken } = useParams();
  const navigate = useNavigate();
  const { user, fetchUser } = useUser();

  const [displayError, setDisplayError] = useState(false);

  const acceptInviteQuery = useQuery<MembersInvitationsAcceptCreateData, AxiosError<ErrorResponse>>({
    queryKey: ['invitation', invitationToken],
    queryFn: () => bigdeltaAPIClient.v1.membersInvitationsAcceptCreate({ invitation_token: invitationToken }),
    retry: (failureCount, error) => ![404, 403, 401, 400].includes(error.response.status) && failureCount < 2,
    meta: {
      suppressError: [404, 401, 400],
    },
  });

  useEffect(() => {
    if (acceptInviteQuery.isError) {
      if (acceptInviteQuery.error.response.status === 404) {
        setDisplayError(true);
      } else if (acceptInviteQuery.error.response.status === 401) {
        window.location.href = AppRoutes.LOGIN + '?referrer=invitation&redirect=' + window.location.pathname;
      } else {
        navigate(AppRoutes.ROOT);
      }
    } else if (acceptInviteQuery.isSuccess) {
      fetchUser().then(() => {
        tracking.track(TrackingEvent.InvitationAccepted, {
          email: user?.email,
          'account id': acceptInviteQuery.data.account_id,
        });
        storeSelectedAccountId(acceptInviteQuery.data.account_id);
        storeSelectedWorkspaceId(null);
        navigate(AppRoutes.ROOT);
      });
    }
  }, [acceptInviteQuery, navigate]);

  return (
    <>
      {displayError && (
        <div className="relative">
          <div className="absolute flex h-full min-h-full w-screen items-center justify-center bg-m-coconut-600" />
          <div className="relative flex min-h-screen items-center justify-center">
            <div className="m-8 flex h-fit w-full max-w-[32rem] flex-col rounded-xl bg-white p-12 text-m-olive-700">
              <div className="mb-6 flex items-center justify-center">
                <p className="text-display-md font-regular">Bigdelta</p>
              </div>
              <div className="flex items-center justify-center py-2 text-display-xs font-medium text-m-red-700">Invalid invite link!</div>
              <div className="text-display-s flex items-center justify-center pt-3 font-medium">
                No active invite with your email address, {user?.email}, exists. Invites can only be redeemed by the exact email address to which they
                were sent. If you think you're using the right email to redeem an invite, please contact your administrator for help.
              </div>
            </div>
          </div>
        </div>
      )}
      {!displayError && <CenteredLoader />}
    </>
  );
};

import { Menu } from '@headlessui/react';
import { FloatWrapper } from './FloatWrapper.tsx';
import { Button, ButtonIntent } from '../../../shared/ui/Button/Button.tsx';
import { twMerge } from 'tailwind-merge';
import React, { FC } from 'react';
import { ReportSourceType } from '../store';
import ObjectIcon from '../../../assets/icons/cube-01.svg?react';
import EventIcon from '../../../assets/icons/cursor-click-01.svg?react';
import MetricIcon from '../../../assets/icons/calculator.svg?react';
import { Placement } from '@floating-ui/utils';
import { SVGComponent } from '../../../types.ts';

interface Option {
  label: string;
  value: ReportSourceType;
  description: string;
  icon: SVGComponent;
  iconClassName: string;
  iconContainerClassName: string;
}

const options: Option[] = [
  {
    label: 'Records',
    value: ReportSourceType.RECORDS,
    icon: ObjectIcon,
    description: 'Track entity changes',
    iconClassName: 'text-m-olive-600',
    iconContainerClassName: 'bg-m-coconut-600',
  },
  {
    label: 'Events',
    value: ReportSourceType.EVENTS,
    icon: EventIcon,
    description: 'Track behavioural changes',
    iconClassName: 'text-m-blue-600',
    iconContainerClassName: 'bg-m-blue-100',
  },
  {
    label: 'Metric',
    value: ReportSourceType.METRIC,
    icon: MetricIcon,
    description: 'Compositions of records or/and events',
    iconClassName: 'text-m-lime-800',
    iconContainerClassName: 'bg-m-lime-200',
  },
];

interface CardProps {
  label: string;
  description: string;
  icon: SVGComponent;
  iconClassName: string;
  iconContainerClassName: string;
}

const Card: FC<CardProps> = ({ label, description, icon: Icon, iconClassName, iconContainerClassName }) => {
  return (
    <div className={twMerge('flex items-center gap-2 gap-y-6 text-left')}>
      <div className={twMerge('rounded-full p-2', iconContainerClassName)}>
        <Icon className={twMerge('h-4 w-4', iconClassName)} />
      </div>
      <div className="flex flex-col">
        <span className="text-md font-medium text-m-gray-900">{label}</span>
        <span className="text-xs text-m-olive-700">{description}</span>
      </div>
    </div>
  );
};

type Props = {
  inline?: boolean;
  selectorIntent?: ButtonIntent;
  onChange: (type: ReportSourceType) => void;
  children?: React.ReactElement;
  placement?: Placement;
};

export const MetricTypeSelector: FC<Props> = ({ inline = false, selectorIntent, onChange, children = null, placement = null }) => {
  return (
    <Menu>
      <FloatWrapper portal adaptiveWidth={!children} offset={4} inline={inline} placement={placement} className="w-full">
        <Menu.Button as="div" className="flex w-full">
          {() => (children ? children : <Button fullWidth label="Select data" size="sm" intent={selectorIntent || 'brand'} className="w-full" />)}
        </Menu.Button>
        <Menu.Items
          className={twMerge(
            'flex w-full flex-col gap-y-0.5 overflow-hidden rounded-lg border border-m-gray-300 bg-m-white p-2 shadow-xl focus-visible:outline-none',
            inline && 'mt-2'
          )}
        >
          {options.map((option) => {
            return (
              <Menu.Item key={option.value}>
                {({ active }) => (
                  <button
                    className={twMerge(
                      active ? 'border-m-gray-200 bg-m-gray-200 text-m-olive-900' : 'border-m-gray-300 text-m-gray-700',
                      'flex w-full items-center gap-x-2 rounded-lg border p-4'
                    )}
                    onClick={() => onChange && onChange(option.value)}
                  >
                    <Card {...option} />
                  </button>
                )}
              </Menu.Item>
            );
          })}
        </Menu.Items>
      </FloatWrapper>
    </Menu>
  );
};

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.toManualIntegrationResponse = exports.ManualIntegrationResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const integration_1 = require("../../../model/integration");
const integrationResponse_1 = require("../integrationResponse");
exports.ManualIntegrationResponseDef = typebox_1.Type.Composite([
    integrationResponse_1.IntegrationResponseDef,
    typebox_1.Type.Object({
        configuration: integration_1.ManualIntegrationConfigurationDef,
        state: integration_1.ManualIntegrationStateDef,
    }),
]);
const toManualIntegrationResponse = (integration, memberResponseMapper) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    return Object.assign(Object.assign({}, (yield (0, integrationResponse_1.toIntegrationResponse)(integration, memberResponseMapper))), { configuration: Object.assign({}, integration.configuration.manual), state: Object.assign({}, (_a = integration.state) === null || _a === void 0 ? void 0 : _a.manual) });
});
exports.toManualIntegrationResponse = toManualIntegrationResponse;

import { ChangeEvent, FC } from 'react';
import { useFilterContext } from '../../context/useFilterContext';
import { useFilterStore } from '../../store';
import { Control } from './Control';

interface EventTimeframeBetweenProps {
  index: number;
}

export const EventTimeframeBetween: FC<EventTimeframeBetweenProps> = ({ index }) => {
  const { filterKey, mode } = useFilterContext();
  const filterItem = useFilterStore((state) => state.getFilterItem([...filterKey, index]));
  const setItemEventTimeframeStartAt = useFilterStore((state) => state.setItemEventTimeframeStartAt);
  const setItemEventTimeframeEndAt = useFilterStore((state) => state.setItemEventTimeframeEndAt);

  const handleChangeStart = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setItemEventTimeframeStartAt(filterKey, index, value);
  };

  const handleChangeEnd = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setItemEventTimeframeEndAt(filterKey, index, value);
  };

  if (filterItem?.timeframe.dateRangeType !== 'ABSOLUTE') {
    return null;
  }

  return (
    // <div className="flex w-full gap-2">
    <>
      <Control>
        <input
          type="date"
          className="shrink rounded border-0 bg-transparent text-[12px] text-sm font-regular text-m-olive-400 ring-transparent focus:border-0 focus:text-m-olive-600 focus:ring-transparent group-hover:bg-[#f0f0f0]"
          onChange={handleChangeStart}
          value={filterItem.timeframe.startAt ?? ''}
        />
      </Control>
      {mode === 'inline' && <span className="shrink-0 text-sm font-regular text-m-olive-400">and</span>}
      <Control>
        <input
          type="date"
          className="shrink rounded border-0 bg-transparent text-[12px] text-sm font-regular text-m-olive-400 ring-transparent focus:border-0 focus:text-m-olive-600 focus:ring-transparent group-hover:bg-[#f0f0f0]"
          onChange={handleChangeEnd}
          value={filterItem.timeframe.endAt ?? ''}
        />
      </Control>
    </>
    // </div>
  );
};

import { MetricsDetailData, ObjectsListData, QueryReportsCreateData, RelationshipsListData, ReportsDetailData } from '@bigdelta/lib-api-client';
import { AttributesValue, TemplateRelationship } from '../types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { PropertyNameObject, RelationshipObjectData } from '../../../shared/types';
import { ALL_EVENTS, Attribute, FilterItem } from '../../../shared/filters/store';
import ColorHash from 'color-hash';
import { getTimeRangeFromQueryTime } from '../utils/getTimeRangeFromQueryTime';
import { getBreakdownValuesToSelect } from '../utils/getBreakdownValuesToSelect';
import {
  DateRangeType,
  EventListItem,
  EventSequenceStepVO,
  FormulaNamedMetricVO,
  MetricQueryConfigurationVO,
  RelationshipEntityType,
  ReportGroupingConfigVO,
  ReportsQueryMetricEventQueryVO,
  ReportsQueryMetricFunnelQueryVO,
  ReportsQueryMetricRecordQueryVO,
  ReportsQueryMetricsFormulaQueryVO,
  ReportTypeVO,
  TemplateType,
  TimeGranularity,
  TimeRangeWithShiftVO,
  TimeUnit,
} from '@bigdelta/lib-shared';
import { cloneDeep, isEmpty, isUndefined, merge } from 'lodash';
import { getPropertyPrefix } from '../utils/getPropertyPrefix';
import { useReportStoreContext } from '../context/reportStoreContext.ts';
import { DeepPartial } from 'react-hook-form';

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const colorHash = new ColorHash();

export type AttributeType = 'string' | 'array';

export type MetricResource = any;

export type SourceObject = ObjectsListData['objects'][number];

export enum AliasPrefix {
  RECORD_ATTRIBUTE = 'record_attr',
  EVENT_ATTRIBUTE = 'event_attr',
}

export enum RecordsMath {
  TOTAL = 'total',
  AGGREGATE_SUM = 'aggregate_sum',
  AGGREGATE_AVERAGE = 'aggregate_average',
  AGGREGATE_MEDIAN = 'aggregate_median',
  AGGREGATE_NPS = 'aggregate_nps',
  AGGREGATE_DISTINCT_COUNT = 'aggregate_distinct_count',
}

export enum EventsMath {
  TOTAL_EVENTS = 'total_events',
  DISTINCT_RECORDS = 'distinct_records',
  AGGREGATE_SUM = 'aggregate_sum',
  AGGREGATE_AVERAGE = 'aggregate_average',
  AGGREGATE_MEDIAN = 'aggregate_median',
  AGGREGATE_NPS = 'aggregate_nps',
  AGGREGATE_DISTINCT_COUNT = 'aggregate_distinct_count',
}

export enum ReportSourceType {
  RECORDS = 'records',
  EVENTS = 'events',
  METRIC = 'metric',
  FORMULA = 'formula',
}

export enum UserSelectionExploreTemplateType {
  TRENDS = 'trends',
  FUNNEL = 'funnel',
}

export type TemplateRetentionPeriod = TimeGranularity.DAY | TimeGranularity.WEEK | TimeGranularity.MONTH;

export const UserSelectionTemplate = {
  ...TemplateType,
  ...UserSelectionExploreTemplateType,
};

// export type UserSelectionTemplate = typeof UserSelectionTemplate;
export type UserSelectionTemplate = UserSelectionExploreTemplateType | TemplateType;

// Type guard for ExploreTemplate
function isUserSelectionExploreTemplateType(template: any): template is UserSelectionExploreTemplateType {
  return Object.values(UserSelectionExploreTemplateType).includes(template);
}

// Type guard for TemplateType
function isTemplateType(template: any): template is TemplateType {
  return Object.values(TemplateType).includes(template);
}

interface ReportsActions {
  setReportType: (type: ReportTypeVO) => void;

  setGranularity: (granularity: TimeGranularity) => void;
  setMetricType: (type: ReportSourceType) => void;
  setMetricEvent: (name?: string) => void;
  setMetricObject: (name?: string) => void;
  setMetricMetric: (name?: string) => void;
  clearMetric: () => void;
  setTimeKey: (key: string) => void;
  setEventMath: (selection: EventsMath) => void;
  setEventMathRelatedObject: (object: RelationshipObjectData | null) => void;
  setEventMathProperty: (property: PropertyNameObject | null) => void;
  setBreakdownProperty: (property: PropertyNameObject | null) => void;
  setBreakdownSelectedValues: (values: AttributesValue[]) => void;

  setFormulaExpression: (expression: string) => void;
  setFormulaNamedMetrics: (metrics: FormulaNamedMetricVO[]) => void;
  setFormulaNamedMetric: (idx: number, metric: FormulaNamedMetricVO) => void;
  addFormulaNamedMetric: (query?: any) => void;
  removeFormulaNamedMetric: (idx: number) => void;

  setRecordsMath: (math: RecordsMath) => void;
  setRecordsMathPropertyWithRelationships: (property: Attribute | null, relationships: RelationshipObjectData[]) => void;

  hydrateGranularity: (granularityOptions?: NonNullable<NonNullable<ReportsDetailData['query']>['display_options']>['time_granularity']) => void;
  hydrateTime: (queryTime?: TimeRangeWithShiftVO) => void;
  hydrateMetrics: (query: NonNullable<ReportsDetailData['query']['metrics'][number]>) => void;
  hydrateBreakdown: (groupingConfig?: ReportGroupingConfigVO) => void;
  // @ts-expect-error type too deep
  hydrateReportQueryFromMetric: (query: MetricQueryConfigurationVO) => void;
  hydrateReportQueryFromNamedFormula: (data: FormulaNamedMetricVO) => void;

  getConfigMetricId: () => string | undefined;
  configHasSource: () => boolean;
  queryHasSource: () => boolean;

  hydrateRecordsMath: (recordsMetric: ReportsQueryMetricRecordQueryVO, relationships: RelationshipsListData['relationships']) => void;

  hydrateEventsMath: (eventsMetric: ReportsQueryMetricEventQueryVO, relationships: RelationshipsListData['relationships']) => void;
  hydrateMath: (metric: NonNullable<ReportsDetailData['query']['metrics'][number]>, relationships: RelationshipsListData['relationships']) => void;
  hydrateNamedFormulaMath: (data: FormulaNamedMetricVO, relationships: RelationshipsListData['relationships']) => void;
  hydrateMetricMath: (query: MetricQueryConfigurationVO, relationshipList) => void;

  hydrateRecordsMetric: (query: ReportsQueryMetricRecordQueryVO) => void;
  hydrateEventsMetric: (query: ReportsQueryMetricEventQueryVO) => void;
  hydrateFormulaMetric: (query: ReportsQueryMetricsFormulaQueryVO) => void;
  hydrateFunnelMetric: (query: ReportsQueryMetricFunnelQueryVO) => void;

  hydrateFromReportData: (
    data: ReportsDetailData,
    metric: NonNullable<ReportsDetailData['query']['metrics'][number]>,
    filter: FilterItem[] | null,
    relationships: RelationshipsListData['relationships']
  ) => void;
  // TODO
  hydrateFromReportTemplateData: (templateType: TemplateType, template: Template, templateRelationship: TemplateRelationship) => void;
  hydrateFromMetricData: (data: MetricsDetailData, filter: FilterItem[] | null, relationships: RelationshipsListData['relationships']) => void;
  hydrateFromNamedFormulaMetric: (
    data: FormulaNamedMetricVO,
    filter: FilterItem[] | null,
    relationships: RelationshipsListData['relationships']
  ) => void;

  setDefaultBreakdownSelectedValues: (data: QueryReportsCreateData) => void;

  updateQuery: (filter: FilterItem[] | null) => void;
  markHydrated: () => void;
  markWrappedInFormula: () => void;

  setFunnelRelationshipName: (name: string) => void;
  setFunnelSequenceCompletionTimeUnit: (timeUnit: TimeUnit) => void;
  setFunnelSequenceCompletionValue: (value: number) => void;
  setFunnelEventSequence: (sequence: EventSequenceStepVO[]) => void;

  setTemplateRelationship: (templateRelationship: TemplateRelationship) => void;

  setUserTemplateSelection: (templateSelection: UserSelectionTemplate) => void;
  setTemplateType: (templateType: TemplateType) => void;
  setTemplate: (template: ReportConfig['template'] | undefined) => void;

  reset: (state?: Partial<ReportsState>) => void;
}

export interface TemplateCustomerActivationNewSignups {
  events: (EventListItem | null)[];
}

export interface TemplateCustomerActivationActivationFlow {
  events: (EventListItem | null)[];
  sequenceCompletionTimeUnit?: TimeUnit | null;
  sequenceCompletionValue?: number | null;
}

export interface TemplateCustomerActivationActiveUsers {
  events: (EventListItem | null)[];
}

export interface TemplateCustomerRetentionRetention {
  signupEvent: EventListItem | null;
  retentionEvent: EventListItem | null;
}

export interface TemplateProductUsageFeatureReport {
  event: EventListItem | null;
  retentionPeriod: TimeGranularity.DAY | TimeGranularity.WEEK | TimeGranularity.MONTH;
}

export interface TemplateMapsActiveCustomers {
  events: (EventListItem | null)[];
}

export interface TemplateMapsNewSignups {
  events: (EventListItem | null)[];
}

export type TemplateRecurringRevenue = Record<string, never>;

export interface TemplateWebsiteWebsite {
  pageviewEvent: EventListItem | null;
  retentionPeriod: TimeGranularity.DAY | TimeGranularity.WEEK | TimeGranularity.MONTH;
}

type TemplateMapping = {
  [TemplateType.CUSTOMER_ACTIVATION_NEW_SIGNUPS]: TemplateCustomerActivationNewSignups;
  [TemplateType.CUSTOMER_ACTIVATION_ACTIVATION_FLOW]: TemplateCustomerActivationActivationFlow;
  [TemplateType.CUSTOMER_ACTIVATION_ACTIVE_USERS]: TemplateCustomerActivationActiveUsers;
  [TemplateType.CUSTOMER_RETENTION_RETENTION]: TemplateCustomerRetentionRetention;
  [TemplateType.PRODUCT_USAGE_FEATURE_REPORT]: TemplateProductUsageFeatureReport;
  [TemplateType.MAPS_NEW_SIGNUPS]: TemplateMapsNewSignups;
  [TemplateType.MAPS_ACTIVE_CUSTOMERS]: TemplateMapsActiveCustomers;
  [TemplateType.RECURRING_REVENUE]: TemplateRecurringRevenue;
  [TemplateType.WEBSITE_WEBSITE]: TemplateWebsiteWebsite;
};

export type Template = {
  [K in keyof TemplateMapping]?: TemplateMapping[K];
} & {
  [K in Exclude<TemplateType, keyof TemplateMapping>]?: never;
};

export interface ReportConfig {
  metricType?: ReportSourceType;
  metricEvent?: string;
  metricObject?: string;
  metricMetric?: string;

  eventMath: EventsMath | null;
  eventMathRelatedObject: RelationshipObjectData | null;
  eventMathProperty: PropertyNameObject | null;

  recordMath: RecordsMath;
  recordMathProperty: Attribute | null;
  recordMathRelationships: RelationshipObjectData[];

  breakdownProperty: PropertyNameObject | null;
  breakdownPropertyPrefix: AliasPrefix | null;

  formulaExpression?: string | null;
  formulaMetrics?: FormulaNamedMetricVO[] | null;

  funnelRelationshipName?: string | null;
  funnelSequenceCompletionTimeUnit?: TimeUnit | null;
  funnelSequenceCompletionValue?: number | null;
  funnelEventSequence?: EventSequenceStepVO[] | null;

  templateRelationship: TemplateRelationship;

  userTemplateSelection?: UserSelectionTemplate;
  templateType?: TemplateType;
  template: Template;
}

export interface BreakdownConfig {
  breakdownSelectedValues: AttributesValue[];
  breakdownSelectedValuesColorMap: Record<string, string>;
}

export interface ReportsState {
  id: string;
  isHydrated: boolean;
  isWrappedInFormula: boolean;
  reportType?: ReportTypeVO;
  config: ReportConfig;
  breakdownConfig: BreakdownConfig;
  query: {
    granularity: TimeGranularity;
    timeKey: string;
    filter: FilterItem[] | null;
  } & ReportConfig;
}

const defaultState: ReportsState = {
  id: '',

  reportType: ReportTypeVO.CHART,

  isHydrated: false,
  isWrappedInFormula: false,

  config: {
    metricType: undefined,
    metricEvent: undefined,
    metricObject: undefined,
    metricMetric: undefined,

    eventMath: EventsMath.TOTAL_EVENTS,
    eventMathRelatedObject: null,
    eventMathProperty: null,

    recordMath: RecordsMath.TOTAL,
    recordMathProperty: null,
    recordMathRelationships: [],

    breakdownProperty: null,
    breakdownPropertyPrefix: null,

    funnelRelationshipName: 'event_users',
    funnelEventSequence: [],
    funnelSequenceCompletionValue: 1,
    funnelSequenceCompletionTimeUnit: TimeUnit.DAY,

    templateRelationship: TemplateRelationship.EVENT_USERS,

    userTemplateSelection: UserSelectionTemplate.TRENDS,
    templateType: undefined,
    template: {},
  },

  breakdownConfig: {
    breakdownSelectedValues: [],
    breakdownSelectedValuesColorMap: {},
  },

  query: {
    granularity: TimeGranularity.WEEK,
    timeKey: '3M',
    // time: getTimeFromRange('3M'),
    breakdownProperty: null,
    breakdownPropertyPrefix: null,

    metricType: undefined,
    metricEvent: undefined,
    metricObject: undefined,

    recordMath: RecordsMath.TOTAL,
    recordMathProperty: null,
    recordMathRelationships: [],

    eventMath: EventsMath.TOTAL_EVENTS,
    eventMathRelatedObject: null,
    eventMathProperty: null,

    templateRelationship: TemplateRelationship.EVENT_USERS,

    filter: [],

    userTemplateSelection: undefined,
    templateType: undefined,
    template: {},
  },
};

export const reportConfigHasSource = (reportType: ReportTypeVO, config: ReportConfig): boolean => {
  if (reportType === ReportTypeVO.CHART) {
    switch (config.metricType) {
      case ReportSourceType.RECORDS:
        return !!config.metricObject;
      case ReportSourceType.EVENTS:
        return !!config.metricEvent;
      case ReportSourceType.METRIC:
        return !!config.metricMetric;
      case ReportSourceType.FORMULA:
        return (
          !!config.formulaExpression &&
          !isEmpty(config.formulaMetrics) &&
          config.formulaMetrics.every((item) => item.metric?.resource?.id || item.records?.resource?.id || item.events)
        );
      default:
        return false;
    }
  } else if (reportType === ReportTypeVO.FUNNEL) {
    return (
      !!config.funnelRelationshipName &&
      !!config.funnelEventSequence &&
      config.funnelEventSequence.every((item) => item?.event_name?.name) &&
      !!config.funnelSequenceCompletionValue &&
      !!config.funnelSequenceCompletionTimeUnit
    );
  } else if (reportType === ReportTypeVO.TEMPLATE) {
    return false;
  }
};

export const getConfigMetricId = (state: ReportsState) => {
  const { config } = state;

  switch (config.metricType) {
    case 'records':
      return config.metricObject;
    case 'events':
      return config.metricEvent;
    case 'metric':
      return config.metricMetric;
    default:
      return;
  }
};

const newReportStore = (id: string, initialState?: DeepPartial<ReportsState>) => {
  return create<ReportsState & ReportsActions>()(
    immer((set, get) => ({
      ...merge(cloneDeep(defaultState), cloneDeep(initialState)),
      id,

      setBreakdownSelectedValues: (value) =>
        set((state) => {
          state.breakdownConfig.breakdownSelectedValues = value;

          state.breakdownConfig.breakdownSelectedValuesColorMap = value.reduce(
            (acc, val) => {
              acc[val.property_value] = !isUndefined(val.property_value) ? colorHash.hex(val.property_value) : '#000000';
              return acc;
            },
            {} as Record<string, string>
          );
        }),
      getConfigMetricId: () => {
        return getConfigMetricId(get());
      },
      setGranularity: (granularity) =>
        set((state) => {
          state.query.granularity = granularity;
        }),

      setReportType: (type) => {
        set((state) => {
          state.reportType = type;
        });
      },
      setMetricType: (type) => {
        set((state) => {
          state.config.metricType = type;
          state.config.breakdownProperty = null;
          state.config.breakdownPropertyPrefix = getPropertyPrefix(type);
          state.breakdownConfig.breakdownSelectedValues = [];
        });
      },
      setFormulaExpression: (expression: string) =>
        set((state) => {
          state.config.formulaExpression = expression;
        }),
      setFormulaNamedMetrics: (metrics: FormulaNamedMetricVO[]) =>
        set((state) => {
          state.config.formulaMetrics = metrics;
        }),
      setFormulaNamedMetric: (idx: number, metric: FormulaNamedMetricVO) =>
        set((state) => {
          state.config.formulaMetrics[idx] = metric;
        }),
      addFormulaNamedMetric: (query: any) => {
        set((state) => {
          for (let i = 0; i < alphabet.length; i++) {
            const char = alphabet[i];

            if (!state.config.formulaMetrics.some((item) => item.name === char)) {
              const namedMetric = { name: char, ...(query || {}) };
              state.config.formulaMetrics.push(namedMetric);
              if (state.config.formulaMetrics.length === 1) {
                state.config.formulaExpression = namedMetric.name;
              }
              return;
            }
          }
        });
      },
      removeFormulaNamedMetric: (idx: number) => {
        set((state) => {
          state.config.formulaMetrics = state.config.formulaMetrics.filter((_, metricIdx) => metricIdx !== idx);
        });
      },

      setMetricEvent: (name) => {
        set((state) => {
          state.config.metricEvent = name;
          state.config.breakdownProperty = null;
          state.config.eventMath = EventsMath.TOTAL_EVENTS;
          state.config.eventMathProperty = null;
          state.config.eventMathRelatedObject = null;
          state.breakdownConfig.breakdownSelectedValues = [];
        });
      },
      setMetricObject: (name) => {
        set((state) => {
          state.config.metricObject = name;
          state.config.breakdownProperty = null;
          state.config.recordMath = RecordsMath.TOTAL;
          state.config.recordMathProperty = null;
          state.config.recordMathRelationships = [];
          state.breakdownConfig.breakdownSelectedValues = [];
        });
      },
      setMetricMetric: (name) => {
        set((state) => {
          state.config.metricMetric = name;
          state.config.breakdownProperty = null;
          state.config.eventMath = EventsMath.TOTAL_EVENTS;
          state.config.eventMathProperty = null;
          state.config.eventMathRelatedObject = null;
          state.breakdownConfig.breakdownSelectedValues = [];
        });
      },
      clearMetric: () => {
        set((state) => {
          state.config.metricType = undefined;
          state.config.metricEvent = undefined;
          state.config.metricObject = undefined;
          state.config.metricMetric = undefined;
          state.config.breakdownProperty = null;
          state.config.eventMath = EventsMath.TOTAL_EVENTS;
          state.config.eventMathProperty = null;
          state.config.eventMathRelatedObject = null;
          state.config.recordMath = RecordsMath.TOTAL;
          state.config.recordMathProperty = null;
          state.config.recordMathRelationships = [];
          state.breakdownConfig.breakdownSelectedValues = [];
        });
      },

      setTimeKey: (key) =>
        set((state) => {
          state.query.timeKey = key;
        }),

      setEventMath: (selection) => {
        set((state) => {
          state.config.eventMath = selection;
        });
      },

      setEventMathRelatedObject: (object) => {
        set((state) => {
          state.config.eventMathRelatedObject = object;
        });
      },

      setEventMathProperty: (property) => {
        set((state) => {
          state.config.eventMathProperty = property;
        });
      },

      setBreakdownProperty: (property) => {
        set((state) => {
          state.config.breakdownProperty = property;
        });
      },

      updateQuery: (filter: FilterItem[] | null) => {
        set((state) => {
          const { config } = get();

          Object.assign(state.query, { ...config, filter });
        });
      },

      hydrateGranularity: (timeGranularity) => {
        if (!timeGranularity) {
          return;
        }

        get().setGranularity(timeGranularity as TimeGranularity);
      },

      hydrateTime: (queryTime) => {
        if (!queryTime) {
          return;
        }

        const queryTimeRange = getTimeRangeFromQueryTime(queryTime);

        if (!queryTimeRange) {
          return;
        }

        get().setTimeKey(queryTimeRange);
      },

      hydrateRecordsMetric: (query: ReportsQueryMetricRecordQueryVO) => {
        get().setMetricType(ReportSourceType.RECORDS);
        get().setMetricObject(query.resource.id);
      },

      hydrateEventsMetric: (query: ReportsQueryMetricEventQueryVO) => {
        const eventNameCondition = query.filter?.conditions.find((condition) => condition && 'event_name' in condition);
        const eventName = 'event_name' in eventNameCondition ? eventNameCondition?.event_name?.value : null;

        get().setMetricType(ReportSourceType.EVENTS);
        get().setMetricEvent(eventName && typeof eventName === 'string' ? eventName : ALL_EVENTS);
      },

      hydrateFormulaMetric: (query: ReportsQueryMetricsFormulaQueryVO) => {
        get().setMetricType(ReportSourceType.FORMULA);
        get().setFormulaExpression(query.expression);
        get().setFormulaNamedMetrics(query.metrics || []);
      },

      hydrateFunnelMetric: (query: ReportsQueryMetricFunnelQueryVO) => {
        get().setReportType(ReportTypeVO.FUNNEL);
        get().setMetricType(null);
        get().setFunnelEventSequence(query.event_sequence);
        get().setFunnelRelationshipName(query.relationship_name);
        get().setFunnelSequenceCompletionTimeUnit((query.sequence_completion_window?.unit as TimeUnit) || TimeUnit.DAY);
        get().setFunnelSequenceCompletionValue(query.sequence_completion_window?.value || 1);
      },

      hydrateMetrics: (metric: NonNullable<ReportsDetailData['query']['metrics'][number]>) => {
        if (metric.records) {
          get().hydrateRecordsMetric(metric.records);
        }
        if (metric.events) {
          get().hydrateEventsMetric(metric.events);
        }
        if (metric.metric) {
          const metricId = metric.metric.resource.id;
          if (metricId) {
            get().setMetricType(ReportSourceType.METRIC);
            get().setMetricMetric(metricId);
          }
        }
        if (metric.formula) {
          get().hydrateFormulaMetric(metric.formula);
        }
        if (metric.funnel) {
          get().hydrateFunnelMetric(metric.funnel);
        }
      },

      hydrateReportQueryFromMetric: (query: MetricQueryConfigurationVO) => {
        if (query.metric_query?.records) {
          get().hydrateRecordsMetric(query.metric_query?.records);
        }
        if (query.metric_query?.events) {
          get().hydrateEventsMetric(query.metric_query?.events);
        }
        if (query.metric_query?.formula) {
          get().hydrateFormulaMetric(query.metric_query.formula);
        }
        if (query.metric_query?.funnel) {
          get().hydrateFunnelMetric(query.metric_query.funnel);
        }
      },

      hydrateReportQueryFromNamedFormula: (data: FormulaNamedMetricVO) => {
        if (data.records) {
          get().hydrateRecordsMetric(data.records);
        }
        if (data.events) {
          get().hydrateEventsMetric(data.events);
        }
        if (data.metric) {
          const metricId = data.metric.resource.id;
          if (metricId) {
            get().setMetricType(ReportSourceType.METRIC);
            get().setMetricMetric(metricId);
          }
        }
      },

      hydrateBreakdown: (groupingConfig) => {
        const selectedGroups = groupingConfig?.selected_groups;
        const groupProperty = groupingConfig?.groups?.[0];

        if (groupProperty?.attribute_id) {
          const breakdownVals = selectedGroups?.reduce((acc, selectedGroup) => {
            const property = selectedGroup.group_attributes?.[0];

            if (!property?.attribute_id) {
              return acc;
            }

            const breakdownProp = {
              property_name: property.attribute_name,
              property_id: property.attribute_id,
              property_value: property.attribute_value,
            };

            return [...acc, breakdownProp];
          }, [] as AttributesValue[]);

          if (!breakdownVals) {
            return;
          }

          get().setBreakdownProperty({
            property_name: groupProperty.attribute_name,
            property_id: groupProperty.attribute_id,
          });

          get().setBreakdownSelectedValues(breakdownVals);
        }
      },

      setDefaultBreakdownSelectedValues: (data) => {
        let prefix;

        switch (get().query.metricType) {
          case ReportSourceType.EVENTS:
          case ReportSourceType.METRIC:
            prefix = AliasPrefix.EVENT_ATTRIBUTE;
            break;
          case ReportSourceType.RECORDS:
            prefix = AliasPrefix.RECORD_ATTRIBUTE;
            break;
          default:
            prefix = '';
        }

        if (!prefix) return;

        get().setBreakdownSelectedValues(
          getBreakdownValuesToSelect(data, get().query.breakdownProperty, get().breakdownConfig.breakdownSelectedValues, prefix)
        );
      },

      hydrateRecordsMath: (recordsMetric, relationshipList) => {
        const getObjectIdFromRelationship = (relationshipName: string, objectId: string) => {
          const relationship = relationshipList.find((rel) => rel.name === relationshipName);
          if (!relationship) return;
          return relationship.first_entity_id === objectId ? relationship.second_entity_id : relationship.first_entity_id;
        };

        // TODO: refactor into a map
        const setRecordsMathFromQuery = (math: string) => {
          switch (math) {
            case 'sum':
              get().setRecordsMath(RecordsMath.AGGREGATE_SUM);
              break;
            case 'average':
              get().setRecordsMath(RecordsMath.AGGREGATE_AVERAGE);
              break;
            case 'median':
              get().setRecordsMath(RecordsMath.AGGREGATE_MEDIAN);
              break;
            case 'distinct total':
              get().setRecordsMath(RecordsMath.AGGREGATE_DISTINCT_COUNT);
              break;
          }
        };

        const math = recordsMetric.math;
        const mathTarget = recordsMetric.math_target;

        if (math === 'total') {
          get().setRecordsMath(RecordsMath.TOTAL);
          return;
        }

        if (math === 'nps' && mathTarget?.record_property) {
          get().setRecordsMath(RecordsMath.AGGREGATE_NPS);
          get().setRecordsMathPropertyWithRelationships(
            {
              attributeName: mathTarget.record_property.name,
              attributeId: '',
              attributeType: '',
            },
            [{ objectId: recordsMetric.resource.id, relationshipName: '', objectWorkspaceId: '' }]
          );
        }

        if (mathTarget?.related_records) {
          const relatedObjectId = getObjectIdFromRelationship(mathTarget.related_records.relationship_name, recordsMetric.resource.id);

          // TODO: error handling
          if (!relatedObjectId) {
            return;
          }

          const relationships = [
            { objectId: recordsMetric.resource.id, relationshipName: '', objectWorkspaceId: '' },
            {
              objectId: relatedObjectId,
              relationshipName: mathTarget.related_records.relationship_name,
              objectWorkspaceId: '',
            },
          ];

          const property = {
            attributeName: mathTarget.related_records.property_name!,
            attributeId: '',
            attributeType: '',
          };

          setRecordsMathFromQuery(math);
          get().setRecordsMathPropertyWithRelationships(property, relationships);
        }

        if (mathTarget?.record_property) {
          const property = { attributeName: mathTarget.record_property.name, attributeId: '', attributeType: '' };
          setRecordsMathFromQuery(math);
          get().setRecordsMathPropertyWithRelationships(property, [
            { objectId: recordsMetric.resource.id, relationshipName: '', objectWorkspaceId: '' },
          ]);
        }
      },

      hydrateEventsMath: (eventsMetric, relationshipList) => {
        const getObjectIdFromEventRelationship = (relationshipName: string) => {
          const relationship = relationshipList.find((rel) => rel.name === relationshipName);
          if (!relationship) return;
          return relationship.first_entity_type === RelationshipEntityType.EVENT ? relationship.second_entity_id : relationship.first_entity_id;
        };

        const setEventsMathFromQuery = (math: string) => {
          switch (math) {
            case 'sum':
              get().setEventMath(EventsMath.AGGREGATE_SUM);
              break;
            case 'average':
              get().setEventMath(EventsMath.AGGREGATE_AVERAGE);
              break;
            case 'median':
              get().setEventMath(EventsMath.AGGREGATE_MEDIAN);
              break;
            case 'distinct total':
              get().setEventMath(EventsMath.AGGREGATE_DISTINCT_COUNT);
              break;
          }
        };

        const math = eventsMetric.math;
        const mathTarget = eventsMetric.math_target;

        if (math === 'total') {
          get().setEventMath(EventsMath.TOTAL_EVENTS);
        }

        if (math === 'distinct total' && mathTarget?.related_records) {
          get().setEventMath(EventsMath.DISTINCT_RECORDS);

          const relatedObjectId = getObjectIdFromEventRelationship(mathTarget.related_records.relationship_name);

          // TODO: error handling
          if (!relatedObjectId) {
            return;
          }

          get().setEventMathRelatedObject({
            objectId: relatedObjectId,
            relationshipName: mathTarget.related_records.relationship_name,
            objectWorkspaceId: '',
          });
        }

        if (mathTarget?.event_property && math) {
          setEventsMathFromQuery(math);
          get().setEventMathProperty({
            property_name: mathTarget.event_property.name,
            property_id: '',
            property_type: '',
          });
        }
      },

      hydrateMath: (metric, relationshipList) => {
        if (metric?.records) {
          get().hydrateRecordsMath(metric.records, relationshipList);
        }

        if (metric?.events) {
          get().hydrateEventsMath(metric.events, relationshipList);
        }
      },

      hydrateMetricMath: (query: MetricQueryConfigurationVO, relationshipList) => {
        if (query.metric_query?.records) {
          get().hydrateRecordsMath(query.metric_query?.records, relationshipList);
        }

        if (query.metric_query?.events) {
          get().hydrateEventsMath(query.metric_query?.events, relationshipList);
        }
      },

      hydrateNamedFormulaMath: (data: FormulaNamedMetricVO, relationships: RelationshipsListData['relationships']) => {
        if (data.records) {
          get().hydrateRecordsMath(data.records, relationships);
        }

        if (data.events) {
          get().hydrateEventsMath(data.events, relationships);
        }
      },

      hydrateFromReportData: (data, metric, filter, relationships) => {
        if (!data.query) {
          return;
        }

        get().hydrateGranularity(data.query.display_options?.time_granularity);
        get().hydrateTime(data.query.time);
        get().hydrateMetrics(metric);
        get().hydrateBreakdown(data.grouping_config);
        get().hydrateMath(metric, relationships);
        get().updateQuery(filter);
        get().markHydrated();
      },

      hydrateFromMetricData: (data, filter, relationships) => {
        if (!data.query_configuration) {
          return;
        }

        get().hydrateGranularity('week');
        get().hydrateTime({
          date_range_type: DateRangeType.IN_THE_LAST,
          window: {
            value: 3,
            unit: TimeUnit.MONTH,
          },
        });
        get().hydrateReportQueryFromMetric(data.query_configuration);
        get().hydrateBreakdown(data.query_configuration.grouping_config);
        get().hydrateMetricMath(data.query_configuration, relationships);
        get().updateQuery(filter);
        get().markHydrated();
      },

      hydrateFromReportTemplateData: (templateType, template, templateRelationship) => {
        get().setTemplateType(templateType);
        get().setUserTemplateSelection(templateType as UserSelectionTemplate);
        get().setReportType(ReportTypeVO.TEMPLATE);
        get().setTemplate(template);
        get().setTemplateRelationship(templateRelationship);
        get().markHydrated();
      },

      hydrateFromNamedFormulaMetric: (
        data: FormulaNamedMetricVO,
        filter: FilterItem[] | null,
        relationships: RelationshipsListData['relationships']
      ) => {
        if (!data) {
          return;
        }

        get().hydrateGranularity('week');
        get().hydrateTime({
          date_range_type: DateRangeType.IN_THE_LAST,
          window: {
            value: 3,
            unit: TimeUnit.MONTH,
          },
        });
        get().hydrateReportQueryFromNamedFormula(data);
        // get().hydrateBreakdown(data.grouping_config);
        get().hydrateNamedFormulaMath(data, relationships);
        get().updateQuery(filter);
        get().markHydrated();
      },

      setRecordsMath: (math) => {
        set((state) => {
          state.config.recordMath = math;
        });
      },
      configHasSource: () => {
        const { reportType, config } = get();

        return reportConfigHasSource(reportType, config);
      },
      queryHasSource: () => {
        const { reportType, query } = get();

        return reportConfigHasSource(reportType, query);
      },

      setRecordsMathPropertyWithRelationships: (property, relationships) => {
        set((state) => {
          state.config.recordMathProperty = property;
          state.config.recordMathRelationships = relationships;
        });
      },
      markHydrated: () => {
        set((state) => {
          state.isHydrated = true;
        });
      },
      markWrappedInFormula: () => {
        set((state) => {
          state.isWrappedInFormula = true;
        });
      },
      setFunnelRelationshipName: (name: string) => {
        set((state) => {
          state.config.funnelRelationshipName = name;
        });
      },
      setFunnelSequenceCompletionTimeUnit: (timeUnit: TimeUnit) => {
        set((state) => {
          state.config.funnelSequenceCompletionTimeUnit = timeUnit;
        });
      },
      setFunnelSequenceCompletionValue: (value: number) => {
        set((state) => {
          state.config.funnelSequenceCompletionValue = value;
        });
      },
      setFunnelEventSequence: (sequence: EventSequenceStepVO[]) => {
        set((state) => {
          state.config.funnelEventSequence = sequence;
        });
      },
      reset: (partialState) => {
        set(merge(cloneDeep(defaultState), partialState ? partialState : {}));
      },

      setTemplateRelationship: (templateRelationship) => {
        set((state) => {
          state.config.templateRelationship = templateRelationship;
        });
      },

      setTemplate: (template) => {
        set((state) => {
          if (!template) {
            state.config.template = {};
          } else {
            state.config.template = template;
          }
        });
      },

      setTemplateType: (templateType) => {
        set((state) => {
          state.config.templateType = templateType;
        });
      },

      setUserTemplateSelection: (templateSelection) => {
        set((state) => {
          state.config.userTemplateSelection = templateSelection;

          if (isUserSelectionExploreTemplateType(templateSelection)) {
            if (templateSelection === UserSelectionExploreTemplateType.TRENDS) {
              state.reportType = ReportTypeVO.CHART;
              state.config.template = {};
              state.config.templateType = undefined;
              return;
            }

            if (templateSelection === UserSelectionExploreTemplateType.FUNNEL) {
              state.reportType = ReportTypeVO.FUNNEL;
              state.config.template = {};
              state.config.templateType = undefined;
              return;
            }
          } else if (isTemplateType(templateSelection)) {
            state.reportType = ReportTypeVO.TEMPLATE;
            // TODO: set default values for the templte type
            state.config.templateType = templateSelection;

            // state._setTemplateDefaults();
            state.config.template[TemplateType.CUSTOMER_ACTIVATION_NEW_SIGNUPS] = { events: [null] };
            state.config.template[TemplateType.CUSTOMER_ACTIVATION_ACTIVATION_FLOW] = {
              events: [null],
              sequenceCompletionValue: 1,
              sequenceCompletionTimeUnit: TimeUnit.DAY,
            };
            state.config.template[TemplateType.CUSTOMER_ACTIVATION_ACTIVE_USERS] = { events: [null] };
            state.config.template[TemplateType.CUSTOMER_RETENTION_RETENTION] = {
              signupEvent: null,
              retentionEvent: null,
            };
            state.config.template[TemplateType.PRODUCT_USAGE_FEATURE_REPORT] = {
              event: null,
              retentionPeriod: TimeGranularity.DAY,
            };
            state.config.template[TemplateType.MAPS_NEW_SIGNUPS] = { events: [null] };
            state.config.template[TemplateType.MAPS_ACTIVE_CUSTOMERS] = { events: [null] };
            state.config.template[TemplateType.RECURRING_REVENUE] = {};
            state.config.template[TemplateType.WEBSITE_WEBSITE] = {
              pageviewEvent: null,
              retentionPeriod: TimeGranularity.DAY,
            };
          } else {
            throw Error();
          }
        });
      },
    }))
  );
};

export type SingleQueryStore = ReturnType<typeof newReportStore>;

export type ReportQueryMetadata = {
  formulaMetricChildQuery?: boolean;
  topLevelReportQuery?: boolean;
};

export type QueryStoreWithMetadata = ReportQueryMetadata & {
  store: SingleQueryStore;
};

export type ReportQueriesStore = {
  queryStores: Record<string, QueryStoreWithMetadata>;
  addQueryStore: (id: string, metadata: ReportQueryMetadata, initialState?: DeepPartial<ReportsState>) => SingleQueryStore;
  deleteQueryStore: (id: string) => void;
  resetQueryStore: (id: string | undefined, state?: Partial<ReportsState>) => void;
  hasQuery: (id: string) => boolean;
  getQuery: (id: string) => SingleQueryStore;
  getTopLevelQueryKeys: () => string[];
  getTopLevelQueryStates: () => ReportsState[];
  reset: () => void;
};

export const useReportQueries = create<ReportQueriesStore>()(
  immer((set, get) => ({
    queryStores: {},
    addQueryStore: (id: string = 'default', metadata: ReportQueryMetadata, initialState?: DeepPartial<ReportsState>): SingleQueryStore => {
      set((state) => {
        if (state.queryStores[id]) {
          return;
        }

        const store = newReportStore(id, initialState);
        if (initialState?.config?.metricType) {
          store.getState().setMetricType(initialState.config.metricType);
        }

        state.queryStores[id] = { store, ...metadata };
      });

      return get().queryStores[id].store;
    },
    deleteQueryStore: (id: string = 'default') => {
      set((state) => {
        delete state.queryStores[id];
      });
    },
    resetQueryStore: (id: string = 'default', initialState) => {
      set((state) => {
        state.queryStores[id].store.setState((queryStore) => {
          queryStore.reset(initialState);
        });
      });
    },
    getQuery: (id: string = 'default') => get().queryStores[id]?.store,
    getTopLevelQueryKeys: (): string[] => {
      return Object.entries(get().queryStores)
        .filter(([id, store]) => store.topLevelReportQuery && !id.endsWith('-new'))
        .map(([id]) => id);
    },
    getTopLevelQueryStates: (): ReportsState[] => {
      return Object.entries(get().queryStores)
        .filter(([id, store]) => store.topLevelReportQuery && !id.endsWith('-new'))
        .map(([, state]) => state.store.getState());
    },
    hasQuery: (id: string = 'default') => id in get().queryStores,
    reset: () => {
      set((state) => {
        state.queryStores = {};
      });
    },
  }))
);

export const useReportStore = (id?: string, defaultMetadata?: ReportQueryMetadata) => {
  const { reportKey } = useReportStoreContext();
  const finalReportKey = id || reportKey || 'default';

  const { getQuery, addQueryStore, hasQuery } = useReportQueries((state) => state);
  if (!hasQuery(finalReportKey) && defaultMetadata) {
    addQueryStore(finalReportKey, defaultMetadata);
  }

  return getQuery(finalReportKey);
};

export const useReportsStore = <T>(extractor: (state: ReportsState & ReportsActions) => T, id?: string, defaultMetadata?: ReportQueryMetadata): T => {
  return useReportStore(id, defaultMetadata)(extractor);
};

import { ButtonHTMLAttributes, FC, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { useWorkspace } from '../auth/hooks/useWorkspace.tsx';
import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../auth/hooks/useQueryKeys.ts';
import { TrackingKeysTable } from './components/TrackingKeysTable.tsx';
import { ApiKeysTable } from './components/ApiKeysTable.tsx';
import { useAccount } from '../auth/hooks/useAccount.tsx';
import { Button } from '../../shared/ui/Button/Button.tsx';
import SearchIcon from '../../assets/icons/search-md.svg?react';
import KeyIcon from '../../assets/icons/key-01.svg?react';
import SendIcon from '../../assets/icons/send-01.svg?react';
import OpenBookIcon from '../../assets/icons/book-open-01.svg?react';
import TerminalIcon from '../../assets/icons/terminal-browser.svg?react';
import { EmptyState } from '../../shared/components/EmptyState.tsx';
import { CreateTrackingKeyDialog } from './components/CreateTrackingKeyDialog.tsx';
import { CreateApiKeyDialog } from './components/CreateApiKeyDialog.tsx';
import { bigdeltaAPIClient } from '../../client/bigdeltaAPIClient.ts';
import { CodeSnippet } from '../../shared/components/CodeSnippet';

const scriptSnippet = `<script type="application/javascript" src="https://cdn.jsdelivr.net/npm/@bigdelta/bigdelta-browser/dist/index.iife.min.js"></script>
<script type="text/javascript">
new Bigdelta({ trackingKey: '${'<TRACKING_KEY>'}', defaultTrackingConfig: { pageViews: { enabled: true, singlePageAppTracking: 'any' }, forms: { enabled: true }} });
</script>`;

const TRACKING_KEY_EMPTY_STATE_STAGES = [
  {
    icon: KeyIcon,
    title: 'Generate Your Key',
    description: 'Create a unique Tracking key for your integration.',
  },
  {
    icon: SendIcon,
    title: 'Send events and records',
    description: 'Use Tracking key to send data straight to your Bigdelta workspace.',
  },
  {
    icon: OpenBookIcon,
    title: 'Integration Guide',
    description: (
      <div>
        Explore step-by-step instructions in our{' '}
        <Link className="underline" target="_blank" to="https://docs.bigdelta.com/setup/install">
          documentation
        </Link>
        .
      </div>
    ),
  },
];

const API_KEY_EMPTY_STATE_STAGES = [
  {
    icon: KeyIcon,
    title: 'Generate Your Key',
    description: 'Create a unique API key for your integration.',
  },
  {
    icon: TerminalIcon,
    title: 'Access Workspace',
    description: 'Use API key to control every part of you workspace.',
  },
  {
    icon: OpenBookIcon,
    title: 'Integration Guide',
    description: (
      <div>
        Explore step-by-step instructions in our{' '}
        <Link className="underline" target="_blank" to="https://docs.bigdelta.com/sdks/http_api">
          documentation
        </Link>
        .
      </div>
    ),
  },
];

interface TabButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  tab: 'tracking_keys' | 'api_keys';
  currentTab: 'tracking_keys' | 'api_keys';
}

const TabButton: FC<TabButtonProps> = ({ label, tab, currentTab, ...props }) => {
  const isActive = tab === currentTab;

  return (
    <button
      className={twMerge(
        'text-m flex shrink-0 items-center px-3 py-4 font-medium',
        isActive ? 'border-b-2 border-m-blue-600 text-m-blue-600' : 'border-b border-m-gray-300 text-m-olive-600'
      )}
      {...props}
    >
      <div>{label}</div>
    </button>
  );
};

export const AccessKeysPage = () => {
  const queryKeys = useQueryKeys();
  const { currentWorkspaceId } = useWorkspace();
  const { currentAccountId } = useAccount();

  const [currentTab, setCurrentTab] = useState<'tracking_keys' | 'api_keys'>('tracking_keys');
  const [isTrackingKeyDialogOpen, setTrackingKeyDialogOpen] = useState(false);
  const [isApiKeyDialogOpen, setApiKeyDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const trackingKeysQuery = useQuery({
    queryKey: queryKeys.trackingKeys(),
    queryFn: () => bigdeltaAPIClient.v1.authTrackingKeyList({ workspace_id: currentWorkspaceId }),
  });

  const apiKeysQuery = useQuery({
    queryKey: queryKeys.apiKeys(),
    queryFn: () => bigdeltaAPIClient.v1.authApiKeyList({ account_id: currentAccountId! }),
    enabled: !!currentAccountId,
  });

  const isTrackingKeysEmpty = !trackingKeysQuery?.data || trackingKeysQuery?.data?.length === 0;
  const isApiKeysEmpty = !apiKeysQuery?.data || apiKeysQuery?.data?.length === 0;

  const filteredTrackingKeys = useMemo(() => {
    return trackingKeysQuery?.data?.filter((trackingKey) => trackingKey.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [trackingKeysQuery, searchTerm]);

  const filteredApiKeys = useMemo(() => {
    return apiKeysQuery?.data?.filter((apiKey) => apiKey.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [apiKeysQuery, searchTerm]);

  return (
    <>
      <div className="flex h-screen w-full flex-col">
        <div className="flex items-center justify-between" style={{ zIndex: 1 }}>
          <div className="flex">
            <TabButton
              tab="tracking_keys"
              currentTab={currentTab}
              label="Tracking Keys"
              onClick={() => {
                setCurrentTab('tracking_keys');
                setSearchTerm('');
              }}
            />
            <TabButton
              tab="api_keys"
              label="API Keys"
              currentTab={currentTab}
              onClick={() => {
                setCurrentTab('api_keys');
                setSearchTerm('');
              }}
            />
          </div>
          <div className="flex items-center gap-x-2">
            {((currentTab === 'tracking_keys' && !isTrackingKeysEmpty) || (currentTab === 'api_keys' && !isApiKeysEmpty)) && (
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
                  <SearchIcon className="h-5 w-5 text-m-gray-600" />
                </div>
                <input
                  type="text"
                  id="simple-search"
                  className="block w-full rounded-md border border-m-gray-300 p-2 ps-10 text-md"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            )}
            {currentTab === 'tracking_keys' && (
              <Button
                intent={isTrackingKeysEmpty ? 'secondary' : 'brand'}
                size="lg"
                label="Create Tracking Key"
                onClick={() => setTrackingKeyDialogOpen(true)}
              />
            )}
            {currentTab === 'api_keys' && (
              <Button intent={isApiKeysEmpty ? 'secondary' : 'brand'} size="lg" label="Create API Key" onClick={() => setApiKeyDialogOpen(true)} />
            )}
          </div>
        </div>
        <div className="w-full border-b border-m-gray-300 pt-5"></div>
        <div className="relative h-full overflow-scroll">
          {currentTab === 'tracking_keys' && !isTrackingKeysEmpty && (
            <div className="flex flex-col items-center gap-y-16">
              <TrackingKeysTable data={filteredTrackingKeys} />
              <CodeSnippet code={scriptSnippet} className="max-w-xl" />
            </div>
          )}
          {currentTab === 'tracking_keys' && isTrackingKeysEmpty && (
            <EmptyState heading="Create Tracking Key" stages={TRACKING_KEY_EMPTY_STATE_STAGES}>
              <Button intent="brand" size="xl" label="Create Tracking Key" onClick={() => setTrackingKeyDialogOpen(true)} />
            </EmptyState>
          )}
          {currentTab === 'api_keys' && !isApiKeysEmpty && <ApiKeysTable data={filteredApiKeys} />}
          {currentTab === 'api_keys' && isApiKeysEmpty && (
            <EmptyState heading="Create API Key" stages={API_KEY_EMPTY_STATE_STAGES}>
              <Button intent="brand" size="xl" label="Create API Key" onClick={() => setApiKeyDialogOpen(true)} />
            </EmptyState>
          )}
        </div>
      </div>
      <CreateTrackingKeyDialog isOpen={isTrackingKeyDialogOpen} onClose={() => setTrackingKeyDialogOpen(false)} />
      <CreateApiKeyDialog isOpen={isApiKeyDialogOpen} onClose={() => setApiKeyDialogOpen(false)} />
    </>
  );
};

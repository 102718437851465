"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkspaceObjectProfileLayoutDef = exports.TableType = exports.PanelType = exports.PanelSize = void 0;
const typebox_1 = require("../typebox");
const value_object_1 = require("../value-object");
const tableLayoutConfig_1 = require("./tableLayoutConfig");
const typebox_2 = require("@sinclair/typebox");
const timestampedModel_1 = require("./timestampedModel");
var PanelSize;
(function (PanelSize) {
    PanelSize["SM"] = "SM";
    PanelSize["MD"] = "MD";
})(PanelSize || (exports.PanelSize = PanelSize = {}));
var PanelType;
(function (PanelType) {
    PanelType["TABLE"] = "table";
    PanelType["CHART"] = "chart";
})(PanelType || (exports.PanelType = PanelType = {}));
var TableType;
(function (TableType) {
    TableType["EVENTS"] = "events";
    TableType["RECORDS"] = "records";
})(TableType || (exports.TableType = TableType = {}));
exports.WorkspaceObjectProfileLayoutDef = typebox_2.Type.Composite([
    typebox_2.Type.Object({
        id: typebox_2.Type.String(),
        workspace_id: typebox_2.Type.String(),
        workspace_object_id: typebox_2.Type.String(),
        tabs: typebox_2.Type.Array(typebox_2.Type.Object({
            name: typebox_2.Type.String(),
            panels: typebox_2.Type.Array(typebox_2.Type.Object({
                id: typebox_2.Type.String({ format: 'uuid' }),
                name: typebox_2.Type.String(),
                size: typebox_2.Type.Enum(PanelSize),
                type: typebox_2.Type.Enum(PanelType),
                data: typebox_2.Type.Object({
                    table: (0, typebox_1.OptionalNullable)(typebox_2.Type.Object({
                        type: typebox_2.Type.Enum(TableType),
                        query: value_object_1.RecordsDataQueryRequestDef || value_object_1.EventsDataQueryRequestDef,
                        layout_config: tableLayoutConfig_1.TableLayoutConfigDef,
                    })),
                    chart: (0, typebox_1.OptionalNullable)(typebox_2.Type.Object({
                        query: value_object_1.ReportsQueryRequestDef,
                    })),
                }),
            })),
        })),
    }),
    timestampedModel_1.TimestampedModelDef,
]);

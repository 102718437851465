"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IngestTrackedEventsRequestDef = void 0;
const typebox_1 = require("../typebox");
const model_1 = require("../model");
const typebox_2 = require("@sinclair/typebox");
exports.IngestTrackedEventsRequestDef = typebox_2.Type.Object({
    events: typebox_2.Type.Array(typebox_2.Type.Object({
        event_name: typebox_2.Type.String({
            description: 'Name of the tracked event.',
            examples: ['Signed Up'],
        }),
        properties: (0, typebox_1.OptionalNullable)(typebox_1.NestedObjectDef),
        relations: (0, typebox_1.OptionalNullable)(model_1.EventRelationsDef),
        track_ip_and_geolocation: (0, typebox_1.OptionalNullable)(typebox_2.Type.Boolean()),
        created_at: (0, typebox_1.OptionalNullable)(typebox_2.Type.String({
            format: 'date-time',
            description: 'Date-time when the event happened. If nothing is set, current time will be added automatically.',
        })),
    })),
}, {
    additionalProperties: false,
    examples: [
        {
            events: [
                {
                    event_name: 'Signed Up',
                    properties: {
                        page: 'https://bigdelta.com',
                        organic: true,
                    },
                    relations: [
                        {
                            object_slug: 'users',
                            record_id: '5272e0f14587654236482a58',
                        },
                        {
                            object_slug: 'accounts',
                            record_id: '5162e0f14587665236482a40',
                        },
                    ],
                },
            ],
        },
    ],
});

export interface JwtTokenPayload {
  jti: string;
}

const authenticationTokenKey = 'authentication-token';
const bigdeltaUserKey = 'bigdelta-user';
const bigdeltaUserIdKey = 'bigdelta-user-id';

const extractJWTPayload = (token: string): JwtTokenPayload => {
  const payloadBase64 = token.split('.')[1];
  const base64 = payloadBase64.replace(/-/g, '+').replace(/_/g, '/');

  return JSON.parse(window.atob(base64));
};

export const setAuthenticationToken = (token: string) => {
  const jwtPayload = extractJWTPayload(token);

  localStorage.setItem(authenticationTokenKey, token);
  localStorage.setItem(bigdeltaUserKey, JSON.stringify(jwtPayload));
  localStorage.setItem(bigdeltaUserIdKey, jwtPayload.jti);
};

export const getAuthenticationToken = (): string | null => {
  return localStorage.getItem(authenticationTokenKey);
};

export const getAuthenticatedUserId = (): string | null => {
  return localStorage.getItem(bigdeltaUserIdKey);
};

export const cleanupAuthenticationData = () => {
  localStorage.removeItem(authenticationTokenKey);
  localStorage.removeItem(bigdeltaUserKey);
  localStorage.removeItem(bigdeltaUserIdKey);
};

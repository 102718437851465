"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toWorkspaceObjectRecordsResponse = exports.toWorkspaceObjectRecordResponse = exports.WorkspaceObjectsRecordsResponseDef = exports.WorkspaceObjectRecordResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const dataQueryPropertiesResponse_1 = require("./dataQueryPropertiesResponse");
exports.WorkspaceObjectRecordResponseDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: typebox_1.Type.String(),
        system_id: typebox_1.Type.String({ format: 'uuid' }),
        properties: typebox_2.NestedObjectDef,
        relationships: typebox_1.Type.Optional(dataQueryPropertiesResponse_1.DataQueryRelationshipsResponseDef),
        created_at: typebox_1.Type.String({ format: 'date-time' }),
        updated_at: typebox_1.Type.String({ format: 'date-time' }),
    }),
], {
    examples: [
        {
            id: '5272e0f654fa423f1ad5a541',
            system_id: '6ed1f7c4-3772-4fa8-92a5-defb7f18dbc4',
            properties: {
                first_name: 'John',
                last_name: 'Johnson',
                active: true,
            },
            created_at: '2019-08-24T14:15:22Z',
            updated_at: '2019-08-24T14:15:22Z',
        },
    ],
});
exports.WorkspaceObjectsRecordsResponseDef = typebox_1.Type.Object({
    items: typebox_1.Type.Array(exports.WorkspaceObjectRecordResponseDef),
});
const toWorkspaceObjectRecordResponse = (record) => {
    return {
        id: record.remoteId,
        system_id: record.id,
        relationships: record.relationships,
        properties: record.properties,
        created_at: record.systemCreatedAt,
        updated_at: record.systemUpdatedAt,
    };
};
exports.toWorkspaceObjectRecordResponse = toWorkspaceObjectRecordResponse;
const toWorkspaceObjectRecordsResponse = (records) => {
    return records.map((record) => {
        return (0, exports.toWorkspaceObjectRecordResponse)(record);
    });
};
exports.toWorkspaceObjectRecordsResponse = toWorkspaceObjectRecordsResponse;

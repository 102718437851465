import { FC, ReactNode } from 'react';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import { twMerge } from '../../../utils/twMerge';
import { PanelSize } from '@bigdelta/lib-shared';

interface RecordProfilePanelBaseProps {
  size: PanelSize;
  children: ReactNode;
  title: string;
}

const sizeConfig = {
  [PanelSize.SM]: { width: 'col-span-1', aspectRatio: 2 },
  [PanelSize.MD]: { width: 'col-span-2', aspectRatio: 4 },
};

export const RecordProfilePanelBase: FC<RecordProfilePanelBaseProps> = ({ size, children, title }) => {
  const { width, aspectRatio } = sizeConfig[size];

  return (
    <div className={twMerge(width, 'rounded-lg border border-m-olive-100 p-4')}>
      <AspectRatio.Root ratio={aspectRatio} className="flex flex-col gap-4">
        <h3 className="text-md font-medium text-m-olive-600">{title}</h3>
        <div className="flex h-full min-h-0 shrink">{children}</div>
      </AspectRatio.Root>
    </div>
  );
};

import { useQuery } from '@tanstack/react-query';

import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

interface UseRecordQueryArgs {
  recordId?: string;
  workspaceObjectId?: string;
  workspaceId?: string;
}

export const useRecordQuery = ({ recordId, workspaceObjectId, workspaceId }: UseRecordQueryArgs) => {
  const queryKeys = useQueryKeys();

  return useQuery({
    queryKey: queryKeys.withWorkspace('record', recordId, workspaceObjectId, workspaceId),
    queryFn: () =>
      bigdeltaAPIClient.v1.objectsRecordDetail(recordId ?? '', workspaceObjectId ?? '', {
        workspace_id: workspaceId ?? '',
      }),
    enabled: !!recordId && !!workspaceObjectId && !!workspaceId,
  });
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentRelationshipReferenceDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_1 = require("../model");
exports.DocumentRelationshipReferenceDef = typebox_1.Type.Object({
    documentType: typebox_1.Type.Enum(model_1.DocumentRelationshipDocumentType),
    documentWorkspaceId: typebox_1.Type.String(),
    documentWorkspaceObjectId: typebox_1.Type.Optional(typebox_1.Type.String()),
    documentId: typebox_1.Type.String(),
});

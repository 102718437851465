"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BigQuerySyncPreviewResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../../../typebox");
exports.BigQuerySyncPreviewResponseDef = typebox_1.Type.Object({
    record_samples: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(typebox_1.Type.Object({
        id: typebox_1.Type.String(),
        properties: typebox_2.NestedObjectDef,
    }))),
});

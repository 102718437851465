import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import { AppRoutes } from '../../../routes';
import { EditMetricSidePanel } from '../components/EditMetricSidePanel.tsx';

export const EditMetricPage = () => {
  const { id: metricId } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return <EditMetricSidePanel metricId={metricId} onSuccess={() => navigate(AppRoutes.METRICS)} onClose={() => navigate(AppRoutes.METRICS)} />;
};

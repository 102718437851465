"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreatePlanModelVODef = void 0;
const typebox_1 = require("@sinclair/typebox");
const planModel_1 = require("../model/planModel");
const typebox_2 = require("../typebox");
exports.CreatePlanModelVODef = typebox_1.Type.Composite([
    typebox_1.Type.Omit(planModel_1.PlanModelDef, ['id', 'latest', 'version']),
    typebox_1.Type.Object({
        id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    }),
]);

import { useMemo } from 'react';
import { useReportViewContext } from '../reports/view/context/useReportViewContext.ts';

export const useHighlightedElementPropertyValue = (breakdownSelectedValues: { property_value: string }[] | undefined) => {
  const { highlightedElement } = useReportViewContext();

  const highlightedElementPropertyValue = useMemo(() => {
    if (!highlightedElement) {
      return null;
    }
    if (!breakdownSelectedValues?.some((item) => item.property_value === highlightedElement.property_value)) {
      return null;
    }

    return highlightedElement.property_value;
  }, [highlightedElement, breakdownSelectedValues]);

  return highlightedElementPropertyValue;
};

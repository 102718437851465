"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BigQueryGrantAccessCommandsResponseDef = exports.BigQueryGrantAccessCommandDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const types_1 = require("../../../types");
exports.BigQueryGrantAccessCommandDef = typebox_1.Type.Object({
    permission_type: typebox_1.Type.Enum(types_1.BigQueryPermissionType),
    command: typebox_1.Type.String(),
});
exports.BigQueryGrantAccessCommandsResponseDef = typebox_1.Type.Object({
    commands: typebox_1.Type.Array(exports.BigQueryGrantAccessCommandDef),
});

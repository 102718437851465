import { useContext } from 'react';
import { ReportViewContext } from './context.ts';

export const useReportViewContext = () => {
  const reportViewContext = useContext(ReportViewContext);
  if (!reportViewContext) {
    throw new Error('No ReportViewContext.Provider found when calling useFilterContext.');
  }
  return reportViewContext;
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateGroupVODef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const model_1 = require("../model");
exports.CreateGroupVODef = typebox_1.Type.Composite([
    model_1.TimestampedModelDef,
    typebox_1.Type.Object({
        id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        accountId: typebox_1.Type.String({ format: 'uuid' }),
        workspaceId: typebox_1.Type.String({ format: 'uuid' }),
        name: typebox_1.Type.Optional(typebox_1.Type.String()),
        createdBy: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
        updatedBy: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
    }),
]);

import { MetadataResourcesPropertiesNamesListData, MetadataResourcesPropertiesValuesListData } from '@bigdelta/lib-api-client';

export interface RelationshipObjectData {
  relationshipName: string | null;
  objectId: string;
  objectWorkspaceId: string;
}
export interface GenericResponse {
  items: any[];
}

export type PropertyNameObject = MetadataResourcesPropertiesNamesListData['items'][number];
export type PropertyValueObject = MetadataResourcesPropertiesValuesListData['items'][number];

export enum VariableInputElementType {
  PropertyVariable = 'property_variable',
  Metric = 'metric',
  Paragraph = 'paragraph',
}

import { useFunnelBuilderStore } from '../../store/Report';
import { Select, SelectItem } from '../common/Select';
import { WindowUnit } from '../../store/FunnelBuilder';
import { Input } from '../../../../shared/ui/Input/Input';

interface WindowUnitItem {
  label: string;
  value: WindowUnit;
}

const WINDOW_ITEMS: WindowUnitItem[] = [
  {
    label: 'Hour',
    value: 'hour',
  },
  {
    label: 'Day',
    value: 'day',
  },
  {
    label: 'Week',
    value: 'week',
  },
  {
    label: 'Month',
    value: 'month',
  },
  {
    label: 'Quarter',
    value: 'quarter',
  },
];

export const FunnelCountWindow = () => {
  const setConversionWindow = useFunnelBuilderStore(null, (state) => state.setConversionWindowData);
  const window = useFunnelBuilderStore(null, (state) => state?.data.object?.conversionWindow);

  const handleUnitSelect = (unit: string) => {
    setConversionWindow({ ...window, unit: unit as WindowUnit });
  };

  const handleUnitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConversionWindow({ ...window, value: parseInt(e.target.value) || 1 });
  };

  return (
    <div className="flex flex-col">
      <div className="text-sm mb-5">Conversion window</div>
      <div className="flex space-between gap-x-2">
        <Input
          type="number"
          min={1}
          value={window.value ?? ''}
          onChange={handleUnitChange}
        />
        <Select
          value={window.unit ?? 'hour'}
          onValueChange={handleUnitSelect}
          className='w-full'
          hasArrow
        >
          {WINDOW_ITEMS.map(({ label, ...itemProps }) => (
            <SelectItem key={label} {...itemProps}>
              {label}
            </SelectItem>
          ))}
        </Select>
      </div>
    </div>
  );
};

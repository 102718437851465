import { Combobox } from '@headlessui/react';
import EventIcon from '../../../assets/icons/cursor-click-01.svg?react';
import { FC, Fragment } from 'react';
import { TriggerType } from '../types';

import ObjectCreatedIcon from '../../../assets/icons/file-plus-02.svg?react';
import ObjectUpdatedIcon from '../../../assets/icons/edit-02.svg?react';
import ObjectDeletedIcon from '../../../assets/icons/trash-03.svg?react';

const GROUPED_TRIGGERS = [
  {
    name: 'Records',
    items: [
      { value: TriggerType.RECORD_CREATED, label: 'Record created', icon: ObjectCreatedIcon },
      { value: TriggerType.RECORD_UPDATED, label: 'Record updated', icon: ObjectUpdatedIcon },
      { value: TriggerType.RECORD_DELETED, label: 'Record deleted', icon: ObjectDeletedIcon },
    ],
  },
  {
    name: 'Events',
    items: [{ value: TriggerType.EVENT_OCCURRED, label: 'Event occurred', icon: EventIcon }],
  },
];

interface TriggerComboboxProps {
  onChange: (value: TriggerType | null) => void;
  value?: TriggerType | null;
}

export const TriggerCombobox: FC<TriggerComboboxProps> = ({ onChange, value }) => {
  return (
    <Combobox onChange={onChange} value={value}>
      {/* <Combobox.Input className="w-full" as={Input} placeholder="Search triggers..." /> */}
      <Combobox.Options className="flex w-full flex-col gap-y-2" static>
        {GROUPED_TRIGGERS.map(({ name, items }) => (
          <Fragment key={name}>
            <div className="pb-2 pl-4 pt-3 text-xxs font-medium text-m-gray-600">{name}</div>
            {items.map(({ value: itemValue, icon: Icon, label }) => (
              <Combobox.Option
                key={itemValue}
                value={itemValue}
                className="flex cursor-pointer items-center gap-x-2 rounded-lg border border-m-olive-100 px-4 py-3.5 text-md text-m-olive-600 ui-active:bg-m-gray-200"
                onClick={() => {
                  if (itemValue === value) {
                    onChange(null);
                    onChange(itemValue);
                  }
                }}
              >
                <Icon className="h-4 w-4 text-m-olive-500" />
                <span>{label}</span>
              </Combobox.Option>
            ))}
          </Fragment>
        ))}
      </Combobox.Options>
    </Combobox>
  );
};

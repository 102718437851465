import { QueryReportsCreateData } from '@bigdelta/lib-api-client';

export const formatMonetary = (
  rawValue: number,
  monetaryFormat: NonNullable<NonNullable<NonNullable<QueryReportsCreateData['queries'][number]['metadata']>['format']>['monetary']>
) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: monetaryFormat.code,
  }).format;

  let denominator = 1;

  if (monetaryFormat.unit === 'cents') {
    denominator = 100;
  }

  return formatter(rawValue / denominator);
};

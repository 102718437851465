import { ChartData } from 'chart.js';
import { ComponentProps, FC } from 'react';
import { Line } from 'react-chartjs-2';
import { DISPLAY_FORMATS } from '../const';
import { TimeGranularity } from '@bigdelta/lib-shared';
import { QueryReportsCreateData } from '@bigdelta/lib-api-client';
import { formatMonetary } from '../../../shared/utils/formatMonetary';
import { roundToDecimalPlaces } from '../../../utils/roundToDecimalPlaces.ts';
import { Datapoint } from '../types';
import { merge } from 'lodash';

interface LineChartProps {
  labels?: string[];
  datasets: ChartData<'line', Datapoint[], string>['datasets'];
  granularity: TimeGranularity;
  metadata: QueryReportsCreateData['queries'][number]['metadata'];
  options?: ComponentProps<typeof Line>['options'];
}

export const LineChart: FC<LineChartProps> = ({ datasets, granularity, metadata, options }) => {
  return (
    <Line
      data={{
        datasets,
      }}
      key={JSON.stringify(datasets)}
      redraw={false}
      options={merge(
        {
          plugins: {
            datalabels: {
              display: false,
            },
            grid: {
              display: false,
            },
            tooltip: {
              animation: false,
              enabled: true,
              position: 'nearest',
              caretPadding: 8,
              usePointStyle: true,
              callbacks: {
                labelPointStyle: () => {
                  return {
                    pointStyle: 'rectRounded',
                    rotation: 0,
                  };
                },
                labelColor: function (context) {
                  return {
                    borderColor: context?.dataset?.borderColor?.toString() ?? '',
                    backgroundColor: context?.dataset?.borderColor?.toString() ?? '',
                    borderWidth: 0,
                    borderRadius: 4,
                  };
                },
                label: (context) => {
                  const rawValue = context.parsed.y;

                  if (metadata?.format?.monetary && typeof rawValue === 'number') {
                    return formatMonetary(rawValue, metadata.format.monetary);
                  }

                  return context.formattedValue;
                },
              },
            },
          },
          y: {
            ticks: {
              color: '#898A7E',
              display: !!datasets?.length,
              callback: (rawValue) => {
                if (metadata?.format?.monetary && typeof rawValue === 'number') {
                  return formatMonetary(rawValue, metadata.format.monetary);
                } else if (typeof rawValue === 'number') {
                  return roundToDecimalPlaces(rawValue, 6);
                }
              },
            },
          },
          animation: {
            duration: 0,
          },
          interaction: {
            intersect: false,
          },
          maintainAspectRatio: false,
          responsive: true,
          elements: {
            point: {
              backgroundColor: '#5B70DF',
              hoverBackgroundColor: '#5B70DF',
              hoverBorderColor: '#fff',
              borderColor: 'transparent',
              borderWidth: 2,
              hoverBorderWidth: 2,
              radius: 3,
              hoverRadius: 5,
            },
            line: {
              borderColor: '#5B70DF',
              tension: 0.01,
            },
          },
          scales: {
            x: {
              type: 'time',
              time: {
                unit: granularity,
                displayFormats: DISPLAY_FORMATS,
              },
              ticks: {
                color: '#898A7E',
                display: !!datasets?.length,
                autoSkip: true,
                maxTicksLimit: 20,
                maxRotation: 0,
                minRotation: 0,
              },
              border: {
                display: false,
              },
              grid: {
                display: false,
              },
            },
            y: {
              ticks: {
                color: '#898A7E',
                display: !!datasets?.length,
                callback: (rawValue) => {
                  if (metadata?.format?.monetary && typeof rawValue === 'number') {
                    return formatMonetary(rawValue, metadata.format.monetary);
                  }

                  return rawValue;
                },
              },
              border: {
                display: false,
              },
              grid: {
                color: '#EBECEB',
              },
              beginAtZero: true,
            },
          },
        },
        options
      )}
    />
  );
};

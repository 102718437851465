import { FC, PropsWithChildren } from 'react';
import TrashIcon from '../../../../assets/icons/trash-04.svg?react';
import { twMerge } from 'tailwind-merge';
import { useFilterContext } from '../../context/useFilterContext';

interface RowContainerProps extends PropsWithChildren {
  onRemove: () => void;
}

export const RowContainer: FC<RowContainerProps> = ({ children, onRemove }) => {
  const { mode } = useFilterContext();

  const isInline = mode === 'inline';
  const isStacked = mode === 'stacked';

  return (
    <div>
      <div className={twMerge(isInline && 'flex flex-wrap items-center gap-2', isStacked && 'flex w-full flex-col items-center gap-2 px-4 py-2')}>
        {isStacked && (
          <div className="flex w-full items-center justify-between">
            <span className="text-xs text-m-olive-300">Where</span>
            <button className="rounded-full border-0 text-m-gray-500" onClick={() => onRemove()}>
              <TrashIcon className="h-4 w-4" />
            </button>
          </div>
        )}
        {children}
        {isInline && (
          <button className="rounded-full border-0 text-m-gray-500" onClick={() => onRemove()}>
            <TrashIcon className="h-4 w-4" />
          </button>
        )}
      </div>
      {isStacked && <hr className="h-px w-full bg-m-gray-300" />}
    </div>
  );
};

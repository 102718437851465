import { last } from 'lodash';
import { Group } from './Group.tsx';
import { Control } from './Control.tsx';
import { PropertyOperatorSelect } from './PropertyOperatorSelect.tsx';
import { PropertyValue } from './PropertyValue.tsx';
import { MetadataResourcePropertyType } from '@bigdelta/lib-shared';
import { FilterItem, FilterItemType, FilterKey, useFilterStore } from '../../store';
import { useFilterContext } from '../../context/useFilterContext.ts';
import { EventOperatorSelect } from '../EventOperatorSelect.tsx';
import { EventValue } from '../EventValue.tsx';
import { EventTimeframeOperatorSelect } from './EventTimeframeOperatorSelect.tsx';
import { EventTimeframeInTheLast } from './EventTimeframeInTheLast.tsx';
import { EventTimeframeBefore } from './EventTimeframeBefore.tsx';
import { EventTimeframeAfter } from './EventTimeframeAfter.tsx';
import { EventTimeframeBetween } from './EventTimeframeBetween.tsx';
import CloseIcon from '../../../../assets/icons/x-close.svg?react';
import EventIcon from '../../../../assets/icons/cursor-click-01.svg?react';
import FilterIcon from '../../../../assets/icons/filter-funnel-01.svg?react';
import CalendarIcon from '../../../../assets/icons/calendar.svg?react';
import { EventPropertySelect } from './EventPropertySelect.tsx';
import { twMerge } from '../../../../utils/twMerge.ts';
import { PropsWithClassName } from '../../../../types.ts';
import { ActionButtonWithTooltip } from './ActionButtonWithTooltip.tsx';
import { FilterContext } from '../../context/context.ts';
import { RecordPropertyPath } from '../records/RecordPropertyPath.tsx';
import { FilterOptionsPopover } from './FilterOptionsPopover.tsx';
import { WhiteElevatedCard } from './WhiteElevatedCard.tsx';
import { Fragment } from 'react';

interface FilterCardProps extends PropsWithClassName {
  filterIndex: number;
  filterItem: FilterItem;
  objectId: string;
  disableEventWindow?: boolean;
}

export const FilterCard = ({ objectId, filterIndex, filterItem, disableEventWindow = false, className }: FilterCardProps) => {
  const { filterKey, workspaceId } = useFilterContext();
  const setItemPropertyOperator = useFilterStore((state) => state.setItemPropertyOperator);
  const removeItem = useFilterStore((state) => state.removeItem);
  const setPropertyWithRelationships = useFilterStore((state) => state.setPropertyWithRelationships);
  const getFilter = useFilterStore((state) => state.getFilter);
  const setFilterItems = useFilterStore((state) => state.setFilterItems);
  const setPropertyRelationships = useFilterStore((state) => state.setPropertyRelationships);
  const setItemEvent = useFilterStore((state) => state.setItemEvent);
  const addNestedItem = useFilterStore((state) => state.addNestedItem);
  const currentResourceId = last(filterItem.propertyRelationships)?.objectId;

  if (filterItem.itemType === FilterItemType.TRENDS_COUNT_EVENT_PROPERTY) {
    return null;
  }

  return (
    <div
      className={twMerge(
        'group flex flex-col gap-2 rounded-t border-x border-m-olive-100 bg-[#fafafa] px-2 py-3 last:rounded-b last:rounded-t-none last:border-b',
        className
      )}
    >
      <WhiteElevatedCard>
        <div className="flex flex-1 flex-row items-center justify-start gap-1.5 overflow-hidden">
          {filterItem.itemType === FilterItemType.RECORDS_PROPERTY ? (
            <>
              <FilterOptionsPopover
                objectId={objectId}
                workspaceId={workspaceId}
                className="w-[20rem]"
                onOptionSelect={(option) => {
                  if (option.type === 'OBJECT') {
                    const currentFilters = getFilter(filterKey);
                    // TODO: Is early return correct here?
                    if (!currentFilters || !option.relationships) {
                      return;
                    }
                    setFilterItems(filterKey, [
                      ...currentFilters.items.slice(0, filterIndex),
                      { ...filterItem, itemType: FilterItemType.RECORDS_PROPERTY },
                      ...currentFilters.items.slice(filterIndex + 1),
                    ]);
                    setPropertyWithRelationships(
                      filterKey,
                      filterIndex,
                      {
                        attributeId: option.property.property_id,
                        attributeType: option.property.property_type ?? 'string',
                        attributeName: option.property.property_name,
                      },
                      option.relationships
                    );
                  }

                  if (option.type === 'EVENT') {
                    const currentFilters = getFilter(filterKey);
                    // TODO: Is early return correct here?
                    if (!currentFilters || !option.relationships) {
                      return;
                    }
                    setFilterItems(filterKey, [
                      ...currentFilters.items.slice(0, filterIndex),
                      { ...filterItem, itemType: FilterItemType.RECORDS_EVENT },
                      ...currentFilters.items.slice(filterIndex + 1),
                    ]);
                    setItemEvent(filterKey, filterIndex, option.event.event_name);
                    setPropertyRelationships(filterKey, filterIndex, option.relationships);
                  }
                }}
                trigger={
                  <button className="rounded hover:bg-[#f0f0f0]">
                    <div className="flex items-center gap-x-1.5 overflow-hidden px-2 py-1.5">
                      <RecordPropertyPath filterItem={filterItem} workspaceId={workspaceId} />
                    </div>
                  </button>
                }
              />
            </>
          ) : filterItem.itemType === FilterItemType.RECORDS_EVENT ? (
            <FilterOptionsPopover
              objectId={objectId}
              workspaceId={workspaceId}
              className="w-[20rem]"
              onOptionSelect={(option) => {
                if (option.type === 'OBJECT') {
                  const currentFilters = getFilter(filterKey);
                  // TODO: Is early return correct here?
                  if (!currentFilters || !option.relationships) {
                    return;
                  }
                  setFilterItems(filterKey, [
                    ...currentFilters.items.slice(0, filterIndex),
                    { ...filterItem, itemType: FilterItemType.RECORDS_PROPERTY },
                    ...currentFilters.items.slice(filterIndex + 1),
                  ]);
                  setPropertyWithRelationships(
                    filterKey,
                    filterIndex,
                    {
                      attributeId: option.property.property_id,
                      attributeType: option.property.property_type ?? 'string',
                      attributeName: option.property.property_name,
                    },
                    option.relationships
                  );
                }

                if (option.type === 'EVENT') {
                  const currentFilters = getFilter(filterKey);
                  // TODO: Is early return correct here?
                  if (!currentFilters || !option.relationships) {
                    return;
                  }
                  setFilterItems(filterKey, [
                    ...currentFilters.items.slice(0, filterIndex),
                    { ...filterItem, itemType: FilterItemType.RECORDS_EVENT },
                    ...currentFilters.items.slice(filterIndex + 1),
                  ]);
                  setItemEvent(filterKey, filterIndex, option.event.event_name);
                  setPropertyRelationships(filterKey, filterIndex, option.relationships);
                }
              }}
              trigger={
                <button className="rounded hover:bg-[#f0f0f0]">
                  <div className="flex select-none flex-row items-center gap-1.5 px-1 py-1.5">
                    <EventIcon className="h-4 w-4 text-m-olive-300" />
                    <span className="text-sm capitalize">{filterItem.event}</span>
                  </div>
                </button>
              }
            />
          ) : filterItem.itemType === FilterItemType.EVENTS_NAME ? (
            <div className="flex select-none flex-row items-center gap-1.5">
              <EventIcon className="h-4 w-4 text-m-olive-300" />
              <span className="capitalize">{filterItem.data.value}</span>
            </div>
          ) : null}
        </div>
        <div className="flex flex-row justify-end">
          {filterItem.itemType === FilterItemType.RECORDS_EVENT && (
            <ActionButtonWithTooltip
              tooltipText={'Filter event'}
              onClick={() => {
                addNestedItem(filterKey, filterIndex, FilterItemType.RECORDS_EVENT_PROPERTY, {});
              }}
            >
              <FilterIcon className="h-4 w-4" />
            </ActionButtonWithTooltip>
          )}
          <ActionButtonWithTooltip
            tooltipText={'Remove'}
            className="hover:bg-red-200 hover:text-red-600 group-hover:opacity-100"
            onClick={() => removeItem(filterKey, filterIndex)}
          >
            <CloseIcon className="h-4 w-4" />
          </ActionButtonWithTooltip>
        </div>
      </WhiteElevatedCard>
      {filterItem.itemType === FilterItemType.RECORDS_PROPERTY ? (
        <div className="flex flex-row items-center gap-2 px-2">
          <Group>
            <Control shouldShrink={false}>
              <PropertyOperatorSelect
                index={filterIndex}
                value={filterItem.propertyOperator}
                onChange={(index, value) => setItemPropertyOperator(filterKey, index, value)}
              />
            </Control>
            {currentResourceId && (
              <Control>
                <PropertyValue index={filterIndex} resourceId={currentResourceId} resourceType={MetadataResourcePropertyType.OBJECT} />
              </Control>
            )}
          </Group>
        </div>
      ) : filterItem.itemType === FilterItemType.RECORDS_EVENT ? (
        <div className="flex flex-col gap-1 px-3">
          {filterItem.event && !disableEventWindow && (
            <div className="flex flex-row items-center gap-1">
              <FilterIcon className="h-4 w-4 text-m-olive-300" />
              <Control shouldShrink={false}>
                <EventOperatorSelect index={filterIndex} />
              </Control>
              <Control>
                <EventValue index={filterIndex} />
              </Control>
            </div>
          )}
          {filterItem.items?.map((nestedItem, nestedIndex) => {
            const nestedKey = [...filterKey, 'items', filterIndex];
            return (
              <Fragment key={JSON.stringify(nestedItem.property)}>
                <Divider />
                <div className="z-50 flex flex-row justify-between">
                  <div className="flex flex-row items-center gap-2">
                    <FilterContext.Provider
                      value={{
                        filterKey: nestedKey as FilterKey,
                        workspaceId: workspaceId,
                        mode: 'inline',
                        inlineElements: false,
                      }}
                    >
                      <EventPropertySelect index={nestedIndex} filterItem={nestedItem} />
                      {nestedItem.property && (
                        <Group>
                          <Control shouldShrink={false}>
                            <PropertyOperatorSelect
                              index={nestedIndex}
                              value={nestedItem.propertyOperator}
                              onChange={(index, value) => setItemPropertyOperator(nestedKey as FilterKey, index, value)}
                            />
                          </Control>
                          <Control>
                            <PropertyValue index={nestedIndex} resourceType={MetadataResourcePropertyType.EVENT} />
                          </Control>
                        </Group>
                      )}
                      {nestedItem.data.value && (
                        <Group>
                          <Control shouldShrink={false}>
                            <EventOperatorSelect index={nestedIndex} />
                          </Control>
                          <Control>
                            <EventValue index={nestedIndex} />
                          </Control>
                        </Group>
                      )}
                    </FilterContext.Provider>
                  </div>
                  <ActionButtonWithTooltip tooltipText="Remove event property filter" onClick={() => removeItem(nestedKey as FilterKey, nestedIndex)}>
                    <CloseIcon className="h-4 w-4" />
                  </ActionButtonWithTooltip>
                </div>
              </Fragment>
            );
          })}
          {filterItem.eventOperator && !disableEventWindow && (
            <>
              <Divider />
              <div className="flex flex-1 flex-row flex-wrap items-center gap-1">
                <CalendarIcon className="h-4 w-4 shrink-0 text-m-olive-300" />
                <EventTimeframeOperatorSelect index={filterIndex} />
                <EventTimeframeInTheLast index={filterIndex} />
                <EventTimeframeBefore index={filterIndex} />
                <EventTimeframeAfter index={filterIndex} />
                <EventTimeframeBetween index={filterIndex} />
              </div>
            </>
          )}
        </div>
      ) : filterItem.itemType === FilterItemType.EVENTS_NAME ? (
        <div className="flex flex-1 flex-row items-center gap-1">
          <CalendarIcon className="h-4 w-4 shrink-0 text-m-olive-300" />
          <div className="flex flex-row items-center gap-1">
            <EventTimeframeOperatorSelect index={filterIndex} />
            <EventTimeframeInTheLast index={filterIndex} />
            <EventTimeframeBefore index={filterIndex} />
            <EventTimeframeAfter index={filterIndex} />
            <EventTimeframeBetween index={filterIndex} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

function Divider() {
  return (
    <div className="flex flex-row justify-center">
      <div className="h-[1px] w-[290px] bg-m-olive-100 text-center"></div>
    </div>
  );
}

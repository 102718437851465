import { Link } from 'react-router-dom';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { AppRoutes, ReportsRoutes } from '../../../routes';
import { Button } from '../../../shared/ui/Button/Button.tsx';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DashboardsDetailData } from '@bigdelta/lib-api-client';

import { toastError, toastSuccess } from '../../../utils/toast.tsx';
import { useWorkspace } from '../../auth/hooks/useWorkspace.tsx';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { Dialog } from '@headlessui/react';
import { ReportsTable } from '../../reports/components/ReportsTable.tsx';
import XIcon from '../../../assets/icons/x.svg?react';
import SearchIcon from '../../../assets/icons/search-md.svg?react';
import { tracking, TrackingEvent } from '../../../tracking';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

interface AddReportDialogProps {
  isOpen: boolean;
  onClose: () => void;
  dashboard: DashboardsDetailData;
}

export const AddReportDialog: FC<AddReportDialogProps> = ({ isOpen, onClose, dashboard }) => {
  const { currentWorkspaceId } = useWorkspace();
  const queryClient = useQueryClient();
  const queryKeys = useQueryKeys();

  const { data: reports } = useQuery({
    queryKey: queryKeys.reports(),
    queryFn: () => bigdeltaAPIClient.v1.reportsList({ workspace_id: currentWorkspaceId }),
    select: (reports) => reports.items.filter((r) => !dashboard?.panels.find((d) => d.configuration.report_id === r.id)),
  });

  const [reportsSearchTerm, setReportsSearchTerm] = useState('');

  const filteredReports = useMemo(() => {
    return reports?.filter((report) => report.title.toLowerCase().includes(reportsSearchTerm.toLowerCase()));
  }, [reports, reportsSearchTerm]);

  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const handleSelectedRows = useCallback(
    (selectedRows: string[]) => {
      setSelectedRows(selectedRows);
    },
    [setSelectedRows]
  );

  const addPanelsMutation = useMutation({
    mutationFn: (reportIds: string[]) =>
      bigdeltaAPIClient.v1.dashboardsPanelsCreate(
        dashboard?.id || '',
        reportIds.map((reportId) => {
          return {
            configuration: {
              report_id: reportId,
              icon: 'check',
              icon_color: 'green',
            },
          };
        })
      ),
    onSuccess: () => {
      tracking.track(TrackingEvent.ReportsAddedToDashboard, { 'report ids': selectedRows });
      toastSuccess(selectedRows.length + ' reports were added', 'Scroll to the bottom to find them');
      setSelectedRows([]);
      setReportsSearchTerm('');
      onClose();
      return queryClient.invalidateQueries(queryKeys.dashboard(dashboard?.id));
    },
    onError: () => {
      toastError('An error occurred while adding reports to the dashboard');
    },
  });

  const handleAddSelected = () => {
    addPanelsMutation.mutate(selectedRows);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-0" style={{ zIndex: 1000000 }}>
      <div className="fixed inset-0 bg-m-gray-700 opacity-95" aria-hidden="true" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="mx-auto flex h-3/4 w-10/12 flex-col gap-y-6 overflow-y-auto rounded-xl bg-white p-6">
          <div className="flex h-full flex-col justify-between">
            <div>
              <div className="flex items-center justify-between" style={{ zIndex: 1 }}>
                <Dialog.Title className="text-xl text-m-olive-700" style={{ zIndex: 1 }}>
                  Select reports to add to {dashboard?.name || 'dashboard'}
                </Dialog.Title>
                <div className="flex items-center gap-x-2">
                  {reports?.length > 0 && (
                    <div className="relative">
                      <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
                        <SearchIcon className="h-5 w-5 text-m-gray-600" />
                      </div>
                      <input
                        type="text"
                        id="simple-search"
                        className="block w-full rounded-md border border-m-gray-300 p-2 ps-10 text-md"
                        placeholder="Search reports"
                        value={reportsSearchTerm}
                        onChange={(e) => setReportsSearchTerm(e.target.value)}
                      />
                    </div>
                  )}
                  <XIcon className="h-6 w-6 cursor-pointer text-m-olive-300 hover:text-m-olive-400" onClick={onClose} style={{ zIndex: 1 }} />
                </div>
              </div>
              {reports?.length > 0 ? (
                <ReportsTable data={filteredReports} onRowSelected={handleSelectedRows} />
              ) : (
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="flex max-w-4xl flex-col items-center gap-y-10 text-center">
                    <h2 className="max-w-xl text-display-md font-semibold text-m-olive-900">There is nothing to choose from</h2>
                    <Link to={AppRoutes.REPORTS + '/' + ReportsRoutes.CREATE_REPORT}>
                      <Button label="Create New report" intent="brand" size="lg" />
                    </Link>
                  </div>
                </div>
              )}
            </div>
            <div className="flex justify-end pt-4" style={{ zIndex: 1 }}>
              <Button
                intent={selectedRows.length === 0 ? 'secondary' : 'brand'}
                size="lg"
                label={selectedRows.length !== 0 ? 'Add selected (' + selectedRows.length + ')' : 'Add selected'}
                onClick={handleAddSelected}
                disabled={selectedRows.length === 0}
              />
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

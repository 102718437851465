"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetadataResourcePropertyTypeType = exports.MetadataResourcePropertyType = void 0;
const typebox_1 = require("../typebox");
const typebox_2 = require("@sinclair/typebox");
var MetadataResourcePropertyType;
(function (MetadataResourcePropertyType) {
    MetadataResourcePropertyType["OBJECT"] = "OBJECT";
    MetadataResourcePropertyType["EVENT"] = "EVENT";
    MetadataResourcePropertyType["METRIC"] = "METRIC";
})(MetadataResourcePropertyType || (exports.MetadataResourcePropertyType = MetadataResourcePropertyType = {}));
exports.MetadataResourcePropertyTypeType = (0, typebox_1.EnumStrings)(typebox_2.Type.Enum(MetadataResourcePropertyType));

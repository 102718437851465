import { useReportStoreContext } from '../../../context/reportStoreContext';
import { useReportStore } from '../../../store';
import { ReportCreateFilterEvents } from '../../ReportCreateFilterEvents';
import { FilterKey } from '../../../../../shared/filters/store';
import { FC, useMemo } from 'react';
import { TEMPLATE_TYPE, SIGNUP_EVENT, RETENTION_EVENT } from './const';
import { EventsBuilder } from '../../EventsBuilder';

interface BuilderProps {
  filterKey: FilterKey;
}

export const Builder: FC<BuilderProps> = ({ filterKey }) => {
  const { reportKey } = useReportStoreContext();
  const { config, setTemplate } = useReportStore(reportKey)((state) => state);

  const template = config.template[TEMPLATE_TYPE];

  const handleRemoveEvent = (type: 'signupEvent' | 'retentionEvent') => {
    if (!template) {
      return;
    }

    setTemplate({
      [TEMPLATE_TYPE]: {
        ...template,
        [type]: null,
      },
    });
  };

  const handleSelectEvent = (
    type: 'signupEvent' | 'retentionEvent',
    value: {
      event_name: string;
    } | null,
    _idx: number
  ) => {
    if (!template) {
      return;
    }

    setTemplate({
      [TEMPLATE_TYPE]: {
        ...template,
        [type]: value ? { event_name: { name: value.event_name } } : null,
      },
    });
  };

  const entryEventFilterKey = useMemo(() => ['retention', ...filterKey, 'entryEvent'], [filterKey]);
  const retentionEventFilterKey = useMemo(() => ['retention', ...filterKey, 'retentionEvent'], [filterKey]);

  return (
    <div className="flex flex-col gap-y-4 rounded-lg text-sm">
      <div className="flex flex-col gap-y-2">
        <EventsBuilder
          title={SIGNUP_EVENT}
          allowMultipleEvents={false}
          events={[template?.signupEvent ?? null]}
          onAddEvent={() => {}}
          onRemoveEvent={() => handleRemoveEvent('signupEvent')}
          onSelectEvent={(event, idx) => handleSelectEvent('signupEvent', event, idx)}
        />
        <div className="-ml-2">
          <ReportCreateFilterEvents filterKey={entryEventFilterKey} />
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <EventsBuilder
          title={RETENTION_EVENT}
          allowMultipleEvents={false}
          events={[template?.retentionEvent ?? null]}
          onAddEvent={() => {}}
          onRemoveEvent={() => handleRemoveEvent('retentionEvent')}
          onSelectEvent={(event, idx) => handleSelectEvent('retentionEvent', event, idx)}
        />
        <div className="-ml-2">
          <ReportCreateFilterEvents filterKey={retentionEventFilterKey} />
        </div>
      </div>
    </div>
  );
};

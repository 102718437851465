import { ReactElement, useRef } from 'react';
import { FilterOptions, FilterTab, OnOptionSelectHandler } from './FilterOptions.tsx';
import { PropsWithClassName } from '../../../../types';
import { twMerge } from '../../../../utils/twMerge';
import * as Popover from '@radix-ui/react-popover';

interface FilterOptionsPopoverProps extends PropsWithClassName {
  objectId: string;
  workspaceId: string;
  trigger: ReactElement;
  onOptionSelect: OnOptionSelectHandler;
  defaultOpen?: Popover.PopoverProps['defaultOpen'];
  supportedTabs?: FilterTab[];
}

export const FilterOptionsPopover = ({
  objectId,
  workspaceId,
  trigger,
  onOptionSelect,
  className,
  defaultOpen = undefined,
  supportedTabs,
}: FilterOptionsPopoverProps) => {
  const closeRef = useRef<HTMLButtonElement>(null);

  return (
    <Popover.Root defaultOpen={defaultOpen}>
      <Popover.Trigger asChild>{trigger}</Popover.Trigger>
      <Popover.Portal>
        <Popover.Content sideOffset={4} align="end">
          <Popover.Close ref={closeRef} className="hidden" />
          <div
            className={twMerge(
              'flex w-[386px] flex-col gap-y-px rounded-xl border border-m-gray-300 bg-m-white text-md text-m-olive-900 shadow-xl',
              className
            )}
          >
            <FilterOptions
              objectId={objectId}
              supportedTabs={supportedTabs}
              workspaceId={workspaceId}
              onOptionSelect={(selectedOption) => {
                onOptionSelect(selectedOption);
                closeRef.current?.click();
              }}
            />
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

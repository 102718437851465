"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toMetricGraphVO = exports.CreateMetricGraphRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.CreateMetricGraphRequestDef = typebox_1.Type.Object({
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    name: typebox_1.Type.String(),
});
const toMetricGraphVO = (createMetricGraphRequest, createdBy, updatedBy) => {
    return {
        workspace_id: createMetricGraphRequest.workspace_id,
        name: createMetricGraphRequest.name,
        created_by: createdBy,
        updated_by: updatedBy,
    };
};
exports.toMetricGraphVO = toMetricGraphVO;

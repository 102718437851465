import { AutomationsCreateData } from '@bigdelta/lib-api-client';
import { FC, ReactElement } from 'react';

interface FilterEventContentProps {
  warning: ReactElement;
  block: AutomationsCreateData['configuration']['trigger']['block'];
}

// TODO: Validation
export const FilterEventContent: FC<FilterEventContentProps> = () => {
  // if (!block.filter_event) {
  //   return warning;
  // }

  return <div className="text-sm font-regular text-m-olive-400">Filter</div>;
};

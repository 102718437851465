"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryPropagatePropertyVODef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
exports.QueryPropagatePropertyVODef = typebox_1.Type.Object({
    name: typebox_1.Type.String(),
    alias: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    is_array_property: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
});

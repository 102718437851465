import { useReportStoreContext } from '../../../context/reportStoreContext';
import { useReportStore } from '../../../store';
import { ReportCreateFilterEvents } from '../../ReportCreateFilterEvents';
import { FilterKey } from '../../../../../shared/filters/store';
import { FC, useMemo } from 'react';
import { EVENT_NAME, TEMPLATE_TYPE } from './const';
import { EventsBuilder } from '../../EventsBuilder';
import { Listbox, Option } from '../../../../../shared/components/Listbox';
import { TimeGranularity } from '@bigdelta/lib-shared';
import { capitalize } from 'lodash';

interface BuilderProps {
  filterKey: FilterKey;
}

export const Builder: FC<BuilderProps> = ({ filterKey }) => {
  const { reportKey } = useReportStoreContext();
  const { config, setTemplate } = useReportStore(reportKey)((state) => state);

  const template = config.template[TEMPLATE_TYPE];

  const handleSelectEvent = (
    value: {
      event_name: string;
    } | null
  ) => {
    if (!template) {
      return;
    }

    console.log(value);

    setTemplate({
      [TEMPLATE_TYPE]: {
        ...template,
        event: value ? { event_name: { name: value?.event_name } } : null,
      },
    });
  };

  const handleSetRetentionPeriod = (value: Option) => {
    if (!template) {
      return;
    }

    setTemplate({
      [TEMPLATE_TYPE]: {
        ...template,
        retentionPeriod: value.value,
      },
    });
  };

  const retentionPeriodOptions = useMemo(
    () =>
      [TimeGranularity.DAY, TimeGranularity.WEEK, TimeGranularity.MONTH].map((value) => ({
        value,
        label: capitalize(value),
      })),
    []
  );

  const selectedOption = retentionPeriodOptions.find((option) => option.value === template?.retentionPeriod) ?? null;

  return (
    <div className="flex flex-col gap-y-4 rounded-lg text-sm">
      <div className="flex flex-col gap-y-2">
        <EventsBuilder
          title={EVENT_NAME}
          allowMultipleEvents={false}
          events={[template?.event ?? null]}
          onAddEvent={() => {}}
          onRemoveEvent={() => {}}
          onSelectEvent={handleSelectEvent}
        />
        <div className="flex flex-col gap-y-2">
          <div className="font-medium">Retention period</div>
          <Listbox options={retentionPeriodOptions} value={selectedOption} onChange={handleSetRetentionPeriod} />
        </div>
        <ReportCreateFilterEvents filterKey={filterKey} />
      </div>
    </div>
  );
};

import { QueryReportsCreatePayload } from '@bigdelta/lib-api-client';
import { PropertyNameObject } from '../../../shared/types';
import { ReportSourceType } from '../store';
import { ChartType } from '@bigdelta/lib-shared';

export const getGrouping = (
  metricType: ReportSourceType,
  breakdownProperty: PropertyNameObject | null,
  chartType?: ChartType
): QueryReportsCreatePayload['grouping'] => {
  if (!breakdownProperty) {
    return null;
  }

  if (metricType === ReportSourceType.EVENTS) {
    return {
      groups: [
        {
          event_property: {
            name: breakdownProperty.property_name,
            value_type: 'string',
          },
        },
      ],
    };
  }

  if (metricType === ReportSourceType.METRIC) {
    return {
      groups: [
        {
          event_property: {
            name: breakdownProperty.property_name,
            value_type: 'string',
          },
        },
      ],
    };
  }

  if (metricType === ReportSourceType.RECORDS) {
    return {
      filter:
        chartType === ChartType.HORIZONTAL
          ? [
              {
                data_created: {
                  operator: 'in period',
                },
              },
            ]
          : null,
      groups: [
        {
          record_property: {
            name: breakdownProperty.property_name,
            value_type: 'string',
          },
        },
      ],
    };
  }
};

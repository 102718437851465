import { QueryReportsCreateData } from '@bigdelta/lib-api-client';
import { AttributesValue } from '../types.ts';
import { take } from 'lodash';
import { PropertyNameObject } from '../../../shared/types.ts';
import { AliasPrefix } from '../store/index.ts';
import { getBreakdownPropertyKey } from './getBreakdownPropertyKey.ts';

export const getBreakdownValuesToSelect = (
  data: QueryReportsCreateData,
  breakdownProperty: PropertyNameObject | null,
  breakdownSelectedValues: AttributesValue[],
  prefix: AliasPrefix
): AttributesValue[] => {
  if (!breakdownProperty) {
    return breakdownSelectedValues;
  }

  const possibleValues = data?.queries?.[0].metadata?.aggregation?.result || [];
  if (possibleValues.length === 0) {
    return [];
  }

  const breakdownPropertyKey = getBreakdownPropertyKey(prefix, breakdownProperty?.property_name);

  const existingSelectedValues = breakdownSelectedValues.filter((value) =>
    possibleValues.some((possibleValue) => value.property_value === possibleValue[breakdownPropertyKey])
  );
  if (existingSelectedValues.length !== 0) {
    return existingSelectedValues;
  }

  const valuesToSelect = take(possibleValues, 10);
  return valuesToSelect.map((item) => ({ property_value: item[breakdownPropertyKey] }));
};

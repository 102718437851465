import { FC } from 'react';
import { FilterItem, FilterItemType, FilterKey, getFilter, useFilterStore } from '../../store';
import { FilterContext } from '../../context/context';
import { EventsFilterAdd } from './EventsFilterAdd';
import { EventsPropertyFilterRow } from './EventsPropertyFilterRow';
import { EventsRecordPropertyFilterRow } from './EventsRecordPropertyFilterRow';
import { EventsNameFilterRow } from './EventsNameFilterRow';
import { EventsTimeframeFilterRow } from './EventsTimeframeFilterRow';
import { ReportConfigSectionButton } from '../../../../features/reports/components/ReportConfigSectionButton';
import { twMerge } from 'tailwind-merge';

interface EventsFilter {
  filterKey: FilterKey;
  workspaceId: string;
  mode?: 'inline' | 'stacked';
  segments?: boolean;
  inlineElements?: boolean;
  filterItemTypes: FilterItemType[];
  defaultFilterItem?: Partial<FilterItem>;
}

export const EventsFilter: FC<EventsFilter> = ({
  filterKey,
  workspaceId,
  inlineElements = false,
  mode = 'inline',
  filterItemTypes,
  defaultFilterItem,
}) => {
  const filter = useFilterStore((state) => getFilter(state, filterKey));

  return (
    <FilterContext.Provider value={{ filterKey, workspaceId, mode, inlineElements }}>
      <div className="flex items-start justify-between">
        <div className={twMerge('flex max-w-full flex-1 flex-col items-start', mode === 'inline' && 'pb-6')}>
          {!!filter?.items?.length && (
            <div className={twMerge('flex w-full flex-col ', mode === 'inline' && 'gap-y-2')}>
              {filter?.items.map((filterItem, index) => {
                const key = `${JSON.stringify(filterItem)}-${index}`;
                switch (filterItem.itemType) {
                  case FilterItemType.EVENTS_PROPERTY:
                    return <EventsPropertyFilterRow index={index} key={key} filter={filterItem} />;
                  case FilterItemType.EVENTS_RECORD_PROPERTY:
                    return <EventsRecordPropertyFilterRow index={index} key={key} filter={filterItem} />;
                  case FilterItemType.EVENTS_NAME:
                    return <EventsNameFilterRow index={index} key={key} filter={filterItem} />;
                  case FilterItemType.EVENTS_TIMEFRAME:
                    return <EventsTimeframeFilterRow index={index} key={key} />;
                  default:
                    return null;
                }
              })}
            </div>
          )}
          {mode === 'stacked' && (
            <div className="mt-2 px-2">
              <EventsFilterAdd as={ReportConfigSectionButton} filterItemTypes={filterItemTypes} defaultFilterItem={defaultFilterItem} />
            </div>
          )}
          {mode === 'inline' && (
            <div className="mt-2">
              <EventsFilterAdd filterItemTypes={filterItemTypes} defaultFilterItem={defaultFilterItem} />
            </div>
          )}
        </div>
        {/*<div>{segments && filter ? <SaveSegmentButtons objectId={undefined} filter={filter} queryType="EVENTS" /> : null}</div>*/}
      </div>
    </FilterContext.Provider>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataQueryRelationshipsResponseDef = exports.DataQueryRelationshipRelationResponseDef = exports.DataQueryRelationshipPropertiesResponseDef = exports.DataQueryPropertiesResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
exports.DataQueryPropertiesResponseDef = typebox_1.Type.Array(typebox_2.NestedObjectDef);
const DataQueryRelationshipRelationCommonProperties = {
    workspace_object_id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    properties: (0, typebox_2.OptionalNullable)(typebox_2.NestedObjectDef),
};
exports.DataQueryRelationshipPropertiesResponseDef = typebox_1.Type.Recursive((Self) => typebox_1.Type.Object({
    relationship_name: typebox_1.Type.String(),
    relations: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(typebox_1.Type.Object(Object.assign(Object.assign({}, DataQueryRelationshipRelationCommonProperties), { relationship_properties: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(Self)) })))),
    properties: (0, typebox_2.OptionalNullable)(exports.DataQueryPropertiesResponseDef),
    relationship_properties: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(Self)),
}));
exports.DataQueryRelationshipRelationResponseDef = typebox_1.Type.Object(Object.assign(Object.assign({}, DataQueryRelationshipRelationCommonProperties), { relationship_properties: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(exports.DataQueryRelationshipPropertiesResponseDef)) }));
exports.DataQueryRelationshipsResponseDef = typebox_1.Type.Object({
    relationship_properties: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(exports.DataQueryRelationshipPropertiesResponseDef)),
});

import { Chart as ChartJS, ChartConfiguration } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { ChoroplethController, ColorScale, GeoFeature, ProjectionScale, topojson } from 'chartjs-chart-geo';
import countries50m from '../../../assets/geo/countries110m.json';
import { QueryReportTemplatesCreateData } from '@bigdelta/lib-api-client';
import { FC, useMemo } from 'react';
import iso from 'iso-3166-1';

ChartJS.register(ChoroplethController, ProjectionScale, ColorScale, GeoFeature);

const countries: Array<GeoJSON.Feature<null, { name: string }>> = (
  topojson.feature(countries50m as any, countries50m.objects.countries as any) as any
).features;

interface ReportTemplateMapProps {
  query: NonNullable<NonNullable<QueryReportTemplatesCreateData['maps_new_signups']>['unique_relations_by_country_7_days']>;
}

export const ReportTemplateMap: FC<ReportTemplateMapProps> = ({ query }) => {
  const countryCodeMetricMap = useMemo(() => {
    const map = new Map<string, number>();

    query.result.forEach((r) => {
      const metric = query.result.find((d) => d.event_attr_country_code === r.event_attr_country_code)?.metric;

      const numericCountryCode = iso.whereAlpha2(r.event_attr_country_code)?.numeric;

      if (!numericCountryCode) {
        return;
      }

      map.set(numericCountryCode, metric);
    });

    return map;
  }, [query?.result]);

  const countriesWithMetrics = useMemo(() => {
    return countries.map((d) => {
      return {
        ...d,
        metric: countryCodeMetricMap.get(String(d.id)) ?? 0,
      };
    });
  }, [countryCodeMetricMap]);

  const data: ChartConfiguration<'choropleth'>['data'] = useMemo(
    () => ({
      labels: countriesWithMetrics.map((d) => d.properties.name),
      datasets: [
        {
          label: 'Countries',
          data: countriesWithMetrics.map((d) => {
            return {
              feature: d,
              value: d.metric,
            };
          }),
        },
      ],
    }),
    [countriesWithMetrics]
  );

  return (
    <Chart
      type="choropleth"
      data={data}
      options={{
        plugins: {
          datalabels: {
            display: false,
          },
        },
        scales: {
          projection: {
            axis: 'x',
            projection: 'naturalEarth1',
            beginAtZero: true,
          },

          color: {
            axis: 'x',
            beginAtZero: true,
            quantize: 5,
            legend: {
              position: 'bottom-right',
              align: 'right',
            },
          },
        },
      }}
    />
  );
};

import { FC } from 'react';
import * as Select from '../../../../components/SelectSmall';
import { FilterItem, Operator, useFilterStore } from '../../store';
import { useFilterContext } from '../../context/useFilterContext';
import { eventNameOperatorMap } from '../../const';

interface EventNameOperatorSelectProps {
  index: number;
  value?: Operator;
  filterItem: FilterItem;
}

// TODO: combine into one operator select component
export const EventNameOperatorSelect: FC<EventNameOperatorSelectProps> = ({ index, filterItem }) => {
  const { filterKey } = useFilterContext();
  const setItemPropertyOperator = useFilterStore((state) => state.setItemPropertyOperator);
  // const filterItem = useFilterStore((state) => state.getFilterItem([...filterKey, index]));

  const getOperators = () => {
    return Object.keys(eventNameOperatorMap);
  };

  const operators = getOperators();

  // if (!filterItem?.attribute?.attributeType) {
  //   return null;
  // }

  const handleValueChange = (data: string) => {
    setItemPropertyOperator(filterKey, index, data as Operator);
  };

  return (
    <Select.Root value={filterItem.propertyOperator} onValueChange={handleValueChange}>
      <Select.Trigger placeholder="Select operator" label={filterItem.propertyOperator ?? ''} />
      {operators && (
        <Select.Content>
          {operators.map((operator) => (
            <Select.Item key={operator} value={operator} label={operator} />
          ))}
        </Select.Content>
      )}
    </Select.Root>
  );
};

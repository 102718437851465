import React, { useState } from 'react';
import { Button } from '../ui/Button/Button.tsx';
import ClipboardIcon from '../../assets/icons/clipboard.svg?react';
import ClipboardCheckIcon from '../../assets/icons/clipboard-check.svg?react';
import { twMerge } from 'tailwind-merge';
import { PropsWithClassName } from '../../types';

interface CodeSnippetProps extends PropsWithClassName {
  code: string;
}

export const CodeSnippet: React.FC<CodeSnippetProps> = ({ code, className }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigator.clipboard.writeText(code);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div className={twMerge('relative flex w-full flex-col rounded-xl bg-m-coconut-600 p-5 text-xs', className)}>
      <Button
        className="absolute right-2 top-2"
        trailingIcon={copied ? ClipboardCheckIcon : ClipboardIcon}
        intent="brand"
        size="sm"
        onClick={handleCopy}
      />
      <pre className="m-0 mr-20 text-wrap">
        <code>{code}</code>
      </pre>
    </div>
  );
};

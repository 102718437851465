"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MappedRecordDataQueryResultVODef = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_1 = require("../model");
const typebox_2 = require("../typebox");
const response_1 = require("../response");
exports.MappedRecordDataQueryResultVODef = typebox_1.Type.Composite([
    model_1.TimestampedModelDef,
    typebox_1.Type.Object({
        id: typebox_1.Type.String({ format: 'uuid' }),
        remoteId: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
        accountId: typebox_1.Type.String({ format: 'uuid' }),
        workspaceId: typebox_1.Type.String({ format: 'uuid' }),
        workspaceObjectId: typebox_1.Type.String({ format: 'uuid' }),
        properties: typebox_2.NestedObjectDef,
        relationships: typebox_1.Type.Optional(response_1.DataQueryRelationshipsResponseDef),
        version: typebox_1.Type.Number(),
        activeSince: typebox_1.Type.String({ format: 'date-time' }),
        activeUntil: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'date-time' })),
    }),
]);

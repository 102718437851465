import { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../../routes';
import { tracking, TrackingEvent } from '../../../../tracking';
import { Button } from '../../../../shared/ui/Button/Button.tsx';
import { UserContext } from '../../../auth/context/userContext.tsx';
import { FormInput } from '../../../../shared/ui/FormInput/FormInput.tsx';
import { handleFormErrorResponse } from '../../../../utils/handleFormErrorResponse.ts';
import { AuthProvider } from '@bigdelta/lib-shared';
import { bigdeltaAPIClient } from '../../../../client/bigdeltaAPIClient.ts';

interface MemberOnboardingFormSchema {
  first_name: string;
  last_name: string;
  password: string;
}

export const MemberOnboardingPage = () => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setError: setFieldError,
    formState: { isValid, errors },
  } = useForm<MemberOnboardingFormSchema>({
    defaultValues: {
      first_name: userContext?.user?.first_name,
      last_name: userContext?.user?.last_name,
    },
  });

  const onSubmit: SubmitHandler<MemberOnboardingFormSchema> = async (data) => {
    setLoading(true);

    try {
      setError('');

      try {
        await bigdeltaAPIClient.v1.membersOnboardingCreate(data);

        if (userContext.fetchUser) {
          const result = await userContext.fetchUser();

          if (result) {
            setError('');
            tracking.track(TrackingEvent.SignupStepTwoCompleted);
            navigate(AppRoutes.ROOT);
          }
        }
      } catch (e: any) {
        handleFormErrorResponse(e, setError, setFieldError);
      }
    } finally {
      setLoading(false);
    }
  };

  if (userContext.user && userContext.authenticated && userContext.ready && userContext.user.is_onboarding_form_filled) {
    return <Navigate to={AppRoutes.ROOT} />;
  }

  return (
    <div className="relative">
      <div className="absolute flex h-full min-h-full w-screen items-center justify-center bg-m-coconut-600" />
      <div className="relative flex min-h-screen items-center justify-center">
        <div className="m-8 flex h-fit w-full max-w-[32rem] flex-col rounded-xl bg-white p-12 text-m-olive-700">
          <div className="mb-12 flex items-center justify-center">
            <p className="text-display-md font-regular">Bigdelta</p>
          </div>
          <div className="flex justify-center text-display-xs font-medium">Create your free account</div>
          <div className="mt-1 flex justify-center text-sm font-regular text-m-olive-500">Signing up as {userContext.user?.email}</div>
          <div className="flex h-16 items-center justify-center">
            {error && <div className="w-full rounded-lg border border-m-red-300 bg-m-red-200 px-4 py-2 text-md text-m-red-700">{error}</div>}
          </div>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="flex flex-col gap-4">
              <FormInput
                label="First name"
                inputProps={{ ...register('first_name', { required: true }), placeholder: 'First name' }}
                errorMessage={errors.first_name?.message}
              />
              <FormInput
                label="Last name"
                inputProps={{ ...register('last_name', { required: true }), placeholder: 'Last name' }}
                errorMessage={errors.last_name?.message}
              />
              {userContext.user?.auth_provider === AuthProvider.PASSWORD && (
                <FormInput
                  label="Password"
                  inputProps={{
                    ...register('password', { required: true }),
                    autoComplete: 'new-password',
                    type: 'password',
                    placeholder: '••••••••',
                  }}
                  errorMessage={errors.password?.message}
                />
              )}
              <Button label="Continue" className="w-full" size="lg" type="submit" disabled={!isValid || loading} loading={loading} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

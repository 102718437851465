"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuickAccessLinkDef = exports.QuickAccessLinkTypeType = exports.QuickAccessLinkType = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const timestampedModel_1 = require("./timestampedModel");
var QuickAccessLinkType;
(function (QuickAccessLinkType) {
    QuickAccessLinkType["OBJECT"] = "object";
    QuickAccessLinkType["RECORD"] = "record";
    QuickAccessLinkType["REPORT"] = "report";
    QuickAccessLinkType["DASHBOARD"] = "dashboard";
    QuickAccessLinkType["AUTOMATION"] = "automation";
    QuickAccessLinkType["INTEGRATION"] = "integration";
    QuickAccessLinkType["EVENTS"] = "events";
})(QuickAccessLinkType || (exports.QuickAccessLinkType = QuickAccessLinkType = {}));
exports.QuickAccessLinkTypeType = (0, typebox_2.EnumStrings)(typebox_1.Type.Enum(QuickAccessLinkType));
exports.QuickAccessLinkDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: typebox_1.Type.String({ format: 'uuid' }),
        workspace_id: typebox_1.Type.String({ format: 'uuid' }),
        member_id: typebox_1.Type.String({ format: 'uuid' }),
        type: exports.QuickAccessLinkTypeType,
        order: typebox_1.Type.Number(),
        label: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
        data: typebox_1.Type.Object({
            object: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
                workspace_object_id: typebox_1.Type.String({ format: 'uuid' }),
            })),
            record: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
                workspace_object_id: typebox_1.Type.String({ format: 'uuid' }),
                record_id: typebox_1.Type.String(),
            })),
            report: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
                report_id: typebox_1.Type.String({ format: 'uuid' }),
            })),
            dashboard: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
                dashboard_id: typebox_1.Type.String({ format: 'uuid' }),
            })),
            automation: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
                automation_id: typebox_1.Type.String({ format: 'uuid' }),
            })),
            integration: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
                integration_id: typebox_1.Type.String({ format: 'uuid' }),
            })),
        }),
    }),
    timestampedModel_1.TimestampedModelDef,
]);

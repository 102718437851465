import { FC } from 'react';
import { useFilterContext } from '../../context/useFilterContext';
import { useFilterStore } from '../../store';
import { EventTimeframeOperatorSelect } from '../common/EventTimeframeOperatorSelect';
import { EventTimeframeInTheLast } from '../common/EventTimeframeInTheLast';
import { EventTimeframeBefore } from '../common/EventTimeframeBefore';
import { EventTimeframeAfter } from '../common/EventTimeframeAfter';
import { EventTimeframeBetween } from '../common/EventTimeframeBetween';
import { RowContainer } from '../common/RowContainer';
import { Group } from '../common/Group';

interface EventsTimeframeFilterRowProps {
  index: number;
}

export const EventsTimeframeFilterRow: FC<EventsTimeframeFilterRowProps> = ({ index }) => {
  const { filterKey } = useFilterContext();

  const removeItem = useFilterStore((state) => state.removeItem);

  return (
    <RowContainer onRemove={() => removeItem(filterKey, index)}>
      <Group>
        <EventTimeframeOperatorSelect index={index} />
      </Group>
      <Group>
        <EventTimeframeInTheLast index={index} />
        <EventTimeframeBefore index={index} />
        <EventTimeframeAfter index={index} />
        <EventTimeframeBetween index={index} />
      </Group>
    </RowContainer>
  );
};

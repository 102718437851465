import 'reactflow/dist/style.css';
import ReactFlow, {
  Background,
  BackgroundVariant,
  Edge,
  MarkerType,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
  useEdgesState,
  useNodesState,
  useOnSelectionChange,
  useReactFlow,
} from 'reactflow';

import { v4 as uuid } from 'uuid';

import { AutomationSidePanel } from './AutomationSidePanel';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { NodeAutomationTrigger, NodeAutomationTriggerType } from './NodeAutomationTrigger';
import { useAutomationsStore } from '../store';
import { NodeAutomationBlockEmpty, NodeAutomationBlockEmptyType } from './NodeAutomationBlockEmpty';
import { toastError } from '../../../utils/toast';
import { NodeAutomationBlock, NodeAutomationBlockType } from './NodeAutomationBlock';
import { ConfigAutomationBlock } from './ConfigAutomationBlock';
import { ConfigTrigger, Trigger } from './ConfigTrigger';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';
import { useQuery } from '@tanstack/react-query';

import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { AutomationBlock, CustomNode, TriggerType } from '../types';
import {
  AutomationWorkflowBlockType,
  IntegrationType,
  NestedQueryFilterOperator,
  QueryValueFilterOperator,
  RelationshipEntityType,
} from '@bigdelta/lib-shared';
import { TEMP_NODE_PREFIX, TRIGGER_NODE_ID } from '../const';
import { useParams } from 'react-router-dom';
import { isNodeAutomationTrigger, isNodeAutomationBlockEmpty, isNodeAutomationBlock } from '../utils/graphNodeTypeguards';
import { getLayoutedElements } from '../utils/getLayoutedElements';

import { AutomationStats } from './AutomationStats';
import { Key } from 'ts-key-enum';
import { getEdge } from '../utils/getEdge';
import { AutomationsDetailData } from '@bigdelta/lib-api-client';
import { produce } from 'immer';
import { useTriggerNode } from '../hooks/useTriggerNode';
import { FilterItemType, useFilterStore } from '../../../shared/filters/store';
import { getRecordFilterItemsFromSegment } from '../../../shared/utils/getRecordFilterItemsFromSegment';
import { getEventFilterItemsFromSegment } from '../../../shared/utils/getEventFilterItemsFromSegment';
import { getBlockFilterKey } from '../utils/getBlockFilterKey';
import { getEventObjectFilterItem } from '../../../shared/utils/getEventObjectFilterItem';
import { ValueSelectType } from '../../../shared/filters/const';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

const nodeTypes = {
  [NodeAutomationTriggerType]: NodeAutomationTrigger,
  [NodeAutomationBlockEmptyType]: NodeAutomationBlockEmpty,
  [NodeAutomationBlockType]: NodeAutomationBlock,
};

const DEFAULT_INITIAL_NODE = {
  id: TRIGGER_NODE_ID,
  type: NodeAutomationTriggerType,
  position: { x: 0, y: 0 },
  data: {
    isEmpty: true,
  },
  selectable: true,
  selected: true,
};

// TODO: Prevent from deleting edges
// https://github.com/xyflow/xyflow/issues/3092

export const AutomationGraph = () => {
  const { id } = useParams();

  const { currentWorkspaceId } = useWorkspace();

  const [initialized, setInitialized] = useState(false);

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [selectedNode, setSelectedNode] = useState<NodeAutomationTrigger | NodeAutomationBlock | NodeAutomationBlockEmpty | undefined>(undefined);
  const [selectedEdge, setSelectedEdge] = useState<Edge | undefined>(undefined);

  const triggerNode = useTriggerNode();

  const isInitialState = nodes.find((n) => n.id === TRIGGER_NODE_ID)?.data.isEmpty;

  const { createNodeData, setCreateNodeData } = useAutomationsStore();

  const { getNodes, getEdges, deleteElements } = useReactFlow();

  const setFilter = useFilterStore((state) => state.setFilter);

  const queryKeys = useQueryKeys();

  const relationshipsQuery = useQuery({
    queryKey: queryKeys.list('relationship'),
    queryFn: () => bigdeltaAPIClient.v1.relationshipsList({ workspace_id: currentWorkspaceId }),
  });

  const automationQuery = useQuery({
    queryKey: queryKeys.automation(id),
    queryFn: () => bigdeltaAPIClient.v1.automationsDetail(id!),
    enabled: !!id,
  });

  const objectsQuery = useQuery({
    queryKey: queryKeys.list('object'),
    queryFn: () => bigdeltaAPIClient.v1.objectsList({ workspace_id: currentWorkspaceId }),
  });

  const integrationsQuery = useQuery({
    queryKey: queryKeys.integrations(),
    queryFn: () => bigdeltaAPIClient.v1.integrationsList({ workspace_id: currentWorkspaceId }),
  });

  // TODO: What happens if the integration is disconnected and reconnected? Does id change?
  const slackIntegration = integrationsQuery.data?.find((integration) => integration.type === IntegrationType.SLACK);

  const slackChannelsQuery = useQuery({
    queryKey: [...queryKeys.integration(slackIntegration?.id), 'channels'],
    queryFn: () => bigdeltaAPIClient.v1.integrationsSlackChannelsDetail(slackIntegration?.id ?? ''),
    enabled: !!slackIntegration?.id,
  });

  // Only supports one slack integration within workspace
  const removeTempElements = useCallback(() => {
    setNodes((nds) => nds.filter((n) => !n.id?.startsWith(TEMP_NODE_PREFIX)));
    setEdges((eds) => eds.filter((n) => !n.id?.startsWith(TEMP_NODE_PREFIX)));
    setCreateNodeData(undefined);
  }, [setCreateNodeData, setEdges, setNodes]);

  // TODO: Refactor so it returns something like { nodes, edges, filters } and move this out
  const parseBlock = useCallback(
    (block: AutomationsDetailData['configuration']['trigger']['block'], nds, eds, parentId: string | null = null) => {
      if (!block || !relationshipsQuery.data || !automationQuery.data || !objectsQuery.data) return;

      const blockWithoutChildren = produce(block, (draft) => {
        draft[draft.type.toLocaleLowerCase()].block = null;
      });

      const triggerBlock = automationQuery.data.configuration.trigger;

      const blockNode = {
        id: block.id as string,
        type: NodeAutomationBlockType,
        position: { x: 0, y: 0 },
        data: {
          id: block.id,
          automationActionType: block.type,
          block: blockWithoutChildren,
          // TODO: should object be appended here?
          workspaceObjectId: triggerBlock.workspace_object_id,
          slackChannelName:
            block.type === AutomationWorkflowBlockType.SEND_SLACK_MESSAGE
              ? slackChannelsQuery.data?.find((channel) => channel.id === block.send_slack_message?.channel_id)?.name
              : undefined,
        },
        selectable: true,
      };

      if (block.type === AutomationWorkflowBlockType.FILTER_RECORD && block.id) {
        const objectId = triggerBlock.workspace_object_id;
        const conditions = block.filter_record?.filter.conditions;

        if (!conditions || !objectId) {
          return;
        }

        const items = getRecordFilterItemsFromSegment(conditions, objectId, relationshipsQuery.data.relationships);
        const operator = block.filter_record?.filter.operator ?? 'and';

        setFilter(['automations', 'block', block.id], { items, operator });
      }

      if (block.type === AutomationWorkflowBlockType.FILTER_EVENT && block.id) {
        const [objectFilter, eventFilter] = block.filter_event?.filter.conditions ?? [];

        const setEventObjectFilter = () => {
          if (!block.id) {
            return;
          }

          if ('related_records' in objectFilter) {
            let workspaceObjectId;
            const relationshipName = objectFilter.related_records?.relationship_name;
            const relationship = relationshipsQuery.data.relationships.find((r) => r.name === relationshipName);
            workspaceObjectId = relationship?.first_entity_type === RelationshipEntityType.EVENT ? relationship?.second_entity_id : undefined;
            workspaceObjectId = relationship?.second_entity_type === RelationshipEntityType.EVENT ? relationship?.first_entity_id : workspaceObjectId;
            const workspaceObject = objectsQuery.data?.objects.find((o) => o.id === workspaceObjectId);
            blockNode.data.workspaceObjectId = workspaceObjectId;

            if (!relationship || !workspaceObject) {
              return null;
            }

            const [implicitObjectFilterConds, objectPropertyConds] = objectFilter.related_records?.filter?.conditions ?? [];

            const eventObjectFilterItem = getEventObjectFilterItem(implicitObjectFilterConds, relationship.name, workspaceObject);

            if (!objectPropertyConds || !('conditions' in objectPropertyConds)) {
              return null;
            }

            const objectPropertiesFilter = objectPropertyConds
              ? getRecordFilterItemsFromSegment(objectPropertyConds.conditions, workspaceObjectId, relationshipsQuery.data.relationships).map(
                  (item) => {
                    return {
                      ...item,
                      itemType: FilterItemType.EVENTS_RECORD_PROPERTY,
                      propertyRelationships: [
                        {
                          relationshipName: relationship.name,
                          objectId: workspaceObjectId,
                          objectWorkspaceId: workspaceObject.workspace_id,
                        },
                        ...item.propertyRelationships.slice(1),
                      ],
                    };
                  }
                )
              : undefined;

            const eventObjectWithObjectPropertiesFilterItems = [
              ...(eventObjectFilterItem ? [eventObjectFilterItem] : []),
              ...(objectPropertiesFilter ?? []),
            ];

            setFilter([...getBlockFilterKey(block.id), 'object'], {
              operator: NestedQueryFilterOperator.AND,
              items: eventObjectWithObjectPropertiesFilterItems,
            });
          }
        };

        const setEventFilter = () => {
          if (!block.id) {
            return;
          }

          if ('conditions' in eventFilter) {
            const conds = eventFilter.conditions.flatMap((c) => (c ? [c] : []));
            const eventFilterItems = eventFilter.conditions ? getEventFilterItemsFromSegment(conds, relationshipsQuery.data.relationships, true) : [];

            setFilter([...getBlockFilterKey(block.id), 'event'], { operator: NestedQueryFilterOperator.AND, items: eventFilterItems });
          }
        };

        setEventObjectFilter();
        setEventFilter();
      }

      nds.push(blockNode);

      if (parentId) {
        eds.push({
          id: `edge-${parentId}-${block.id}`,
          source: parentId,
          target: block.id,
          type: 'smoothstep',
          markerEnd: {
            type: MarkerType.Arrow,
            color: '#898A7E',
          },
          style: {
            strokeWidth: 4,
            stroke: '#898A7E',
          },
        });
      }

      const key = Object.keys(block).find((k) => {
        return AutomationWorkflowBlockType[k.toUpperCase()];
      });

      if (key && block[key].block) {
        parseBlock(block[key].block, nds, eds, block.id);
      }
    },
    [automationQuery.data, objectsQuery.data, relationshipsQuery.data, setFilter, slackChannelsQuery.data]
  );

  const initialElements = useMemo(() => {
    if (!id) {
      return { nodes: [DEFAULT_INITIAL_NODE], edges: [] };
    }

    if (
      automationQuery.data &&
      objectsQuery.data &&
      relationshipsQuery.data &&
      ((integrationsQuery.data && slackChannelsQuery.data) || !slackIntegration)
    ) {
      const { trigger } = automationQuery.data.configuration;

      const triggerNode = {
        id: TRIGGER_NODE_ID,
        type: NodeAutomationTriggerType,
        position: { x: 0, y: 0 },
        data: {
          triggerType: trigger.type,
          workspaceObject: objectsQuery.data.objects.find((object) => object.id === trigger.workspace_object_id),
          workspaceObjectProperty: trigger.workspace_object_property,
          eventName: trigger.event_name,
          isEmpty: false,
        },
        selectable: true,
        selected: false,
      };

      const nds = [triggerNode];
      const eds = [];

      parseBlock(trigger.block, nds, eds, TRIGGER_NODE_ID);

      return getLayoutedElements(nds, eds);
    }
  }, [
    automationQuery.data,
    id,
    integrationsQuery.data,
    objectsQuery.data,
    parseBlock,
    relationshipsQuery.data,
    slackChannelsQuery.data,
    slackIntegration,
  ]);

  const onSelectionChange = useCallback(
    (selection) => {
      // Prevents deselecting node in initial state
      if (isInitialState) {
        setNodes((nds) => nds.map((n) => (n.id === TRIGGER_NODE_ID ? { ...n, selected: true } : n)));
        setSelectedNode(nodes.find((n) => n.id === TRIGGER_NODE_ID));
      }

      if (selection.nodes.length === 1) {
        setSelectedNode(selection.nodes[0]);
        if (!selection.nodes[0].id.startsWith(TEMP_NODE_PREFIX)) {
          removeTempElements();
        }
      }
      if (selection.nodes.length === 0) {
        setSelectedNode(undefined);
      }

      if (selection.nodes.length > 1) {
        toastError('More than one node selected');
      }

      if (selection.edges.length >= 1) {
        setSelectedEdge(selection.edges[0]);
      }

      if (selection.edges.length === 0) {
        setSelectedEdge(undefined);
      }
    },
    [isInitialState, nodes, removeTempElements, setNodes]
  );

  useOnSelectionChange({
    onChange: onSelectionChange,
  });

  useEffect(() => {
    if (!selectedNode) {
      removeTempElements();
    }
  }, [removeTempElements, selectedNode, setEdges, setNodes]);

  useEffect(() => {
    if (!initialized && initialElements) {
      setNodes(initialElements.nodes as CustomNode[]);
      setEdges(initialElements.edges as Edge[]);
      setInitialized(true);
    }
  }, [initialElements, initialElements?.edges, initialElements?.nodes, initialized, setEdges, setNodes]);

  useEffect(() => {
    if (createNodeData) {
      setNodes((nds) => {
        return nds.filter((node) => !node.id.startsWith(TEMP_NODE_PREFIX));
      });

      setEdges((eds) => {
        return eds.filter((edge) => !edge.id.startsWith(TEMP_NODE_PREFIX));
      });

      // TODO: id?
      if (createNodeData.source) {
        const nds = [
          ...getNodes().map((n) => ({ ...n, selected: false })),
          {
            id: 'temp-1',
            type: NodeAutomationBlockEmptyType,
            width: 288,
            height: 219,
            position: {
              x: 0,
              y: 0,
            },
            data: {},
            selected: true,
          },
        ];

        const eds = [...getEdges(), getEdge([TEMP_NODE_PREFIX, createNodeData.source].join(''), createNodeData.source, 'temp-1')];

        const layoutedElements = getLayoutedElements(nds, eds);

        setNodes(layoutedElements.nodes);
        setEdges(layoutedElements.edges);
      }
    }
  }, [createNodeData, getEdges, getNodes, setEdges, setNodes]);

  // Update selectedNode with updated data
  useEffect(() => {
    if (selectedNode) {
      setSelectedNode(nodes.find((n) => n.id === selectedNode.id));
    }
  }, [nodes, selectedNode]);

  const resetTrigger = () => {
    setNodes((nds) => nds.map((n) => (n.id === TRIGGER_NODE_ID ? { ...n, data: { triggerType: null, isEmpty: true }, selected: true } : n)));

    const triggerNode = nodes.find((n) => n.id === TRIGGER_NODE_ID);
    if (triggerNode && isNodeAutomationTrigger(triggerNode)) {
      setSelectedNode({
        ...triggerNode,
        type: NodeAutomationTriggerType,
        data: { triggerType: null, isEmpty: true },
      });
    }
  };

  const handleUpdateTrigger = (trigger: Trigger) => {
    const { workspaceObjectId, workspaceObjectProperty, triggerType, eventName } = trigger;

    if (triggerType === null) {
      resetTrigger();
      return;
    }

    if ([TriggerType.RECORD_CREATED, TriggerType.RECORD_UPDATED, TriggerType.RECORD_DELETED].includes(triggerType)) {
      const workspaceObject = objectsQuery.data?.objects.find((object) => object.id === workspaceObjectId);

      setNodes((nds) =>
        nds.map((n) =>
          n.id === TRIGGER_NODE_ID
            ? {
                ...n,
                data: { ...n.data, isEmpty: false, triggerType, workspaceObject, workspaceObjectProperty, eventName: undefined },
                selected: true,
              }
            : n
        )
      );
    }

    if ([TriggerType.EVENT_OCCURRED].includes(triggerType)) {
      setNodes((nds) =>
        nds.map((n) =>
          n.id === TRIGGER_NODE_ID ? { ...n, data: { ...n.data, isEmpty: false, triggerType, eventName, workspaceObjectId }, selected: true } : n
        )
      );
    }
  };

  const handlePaneClick = () => {
    if (!isInitialState) {
      setNodes((nds) => nds.map((n) => ({ ...n, selected: false })));
    }
  };

  const getDefaultBlock = (actionType: AutomationWorkflowBlockType): AutomationBlock | null => {
    switch (actionType) {
      case AutomationWorkflowBlockType.SEND_SLACK_MESSAGE:
        return {
          type: AutomationWorkflowBlockType.SEND_SLACK_MESSAGE,
          send_slack_message: {
            channel_id: '',
            message_template: '',
            integration_id: '',
            block: null,
          },
        };
      case AutomationWorkflowBlockType.DELAY:
        return {
          type: AutomationWorkflowBlockType.DELAY,
          delay: {
            value: 1,
            unit: 'day',
          },
        };
      case AutomationWorkflowBlockType.DELAY_UNTIL:
        return {
          type: AutomationWorkflowBlockType.DELAY_UNTIL,
          delay_until: {
            datetime: new Date().toISOString(),
          },
        };
      case AutomationWorkflowBlockType.FILTER_RECORD:
        return {
          type: AutomationWorkflowBlockType.FILTER_RECORD,
        };
      case AutomationWorkflowBlockType.FILTER_EVENT:
        return {
          type: AutomationWorkflowBlockType.FILTER_EVENT,
        };
      default:
        return null;
    }
  };

  const handleActionSelect = (actionType: AutomationWorkflowBlockType) => {
    if (selectedNode?.type === NodeAutomationBlockEmptyType) {
      const triggerWorkspaceObjectId = triggerNode?.data.workspaceObject?.id;
      const triggerEventName = triggerNode?.data.eventName;

      const nodeId = uuid();

      if (actionType === AutomationWorkflowBlockType.FILTER_EVENT) {
        setFilter([...getBlockFilterKey(nodeId), 'event'], {
          items: [
            {
              itemType: FilterItemType.EVENTS_NAME,
              propertyRelationships: [],
              propertyOperator: QueryValueFilterOperator.EQUALS,
              data: { valueType: ValueSelectType.TEXT_FIELD, value: triggerEventName },
              timeframe: {},
            },
          ],
          operator: 'and',
        });
      }

      setNodes((nds) => {
        return nds.map((n) => {
          if (n.id.startsWith(TEMP_NODE_PREFIX)) {
            return {
              id: nodeId,
              type: NodeAutomationBlockType,
              position: n.position,
              data: {
                automationActionType: actionType,
                block: { ...getDefaultBlock(actionType), id: nodeId },
                workspaceObjectId: triggerWorkspaceObjectId,
                isDraft: true,
              },
              selected: true,
            };
          }
          return n;
        });
      });
      setEdges((eds) => {
        return eds.map((e) => {
          if (e.id.startsWith(TEMP_NODE_PREFIX)) {
            return getEdge(uuid(), createNodeData?.source as string, nodeId);
          }
          return e;
        });
      });
      removeTempElements();
    }
  };

  const handleActionUpdateBlock = (block: AutomationBlock) => {
    const selectedNodeBlockId = selectedNode && isNodeAutomationBlock(selectedNode) ? selectedNode?.data.block?.id : undefined;

    if (!selectedNodeBlockId) {
      return;
    }

    if (block.id !== selectedNodeBlockId) {
      return;
    }

    if (block.type === AutomationWorkflowBlockType.SEND_SLACK_MESSAGE) {
      const slackChannelName = slackChannelsQuery.data?.find((channel) => channel.id === block.send_slack_message?.channel_id)?.name;

      setNodes((nds) =>
        nds.map((n: CustomNode) =>
          isNodeAutomationBlock(n) && n.data.block.id === block.id ? { ...n, data: { ...n.data, block, slackChannelName } } : n
        )
      );
    }

    if (block.type === AutomationWorkflowBlockType.DELAY_UNTIL) {
      setNodes((nds) =>
        nds.map((n: CustomNode) => (isNodeAutomationBlock(n) && n.data.block.id === block.id ? { ...n, data: { ...n.data, block } } : n))
      );
    }

    if (block.type === AutomationWorkflowBlockType.DELAY) {
      setNodes((nds) =>
        nds.map((n: CustomNode) => (isNodeAutomationBlock(n) && n.data.block.id === block.id ? { ...n, data: { ...n.data, block } } : n))
      );
    }
  };

  const handleDeselectNode = () => {
    setNodes((nds) => nds.map((n) => ({ ...n, selected: false })));
  };

  const onNodesDelete = useCallback(
    (deleted) => {
      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => getEdge(`${node.id}->${target}`, source, target))
          );

          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
    },
    [setEdges, edges, nodes]
  );

  const deleteKeyCode = useMemo(() => {
    if (selectedEdge || selectedNode?.type === NodeAutomationTriggerType) {
      return '';
    }

    return [Key.Delete, Key.Backspace];
  }, [selectedEdge, selectedNode?.type]);

  const handleDeleteNode = (id: string) => {
    deleteElements({ nodes: [{ id }] });
  };

  return (
    <div className="flex h-full">
      <AutomationSidePanel>
        {!!selectedNode && isNodeAutomationTrigger(selectedNode) && (
          <ConfigTrigger
            key={selectedNode.data.triggerType}
            onChange={handleUpdateTrigger}
            triggerType={selectedNode.data.triggerType}
            workspaceObjectId={selectedNode.data.workspaceObject?.id}
            workspaceObjectProperty={selectedNode.data.workspaceObjectProperty}
            eventName={selectedNode.data.eventName}
          />
        )}
        {!!selectedNode && isNodeAutomationBlockEmpty(selectedNode) && (
          <ConfigAutomationBlock
            id={undefined}
            onActionSelect={handleActionSelect}
            onDeselectNode={handleDeselectNode}
            onDeleteNode={handleDeleteNode}
          />
        )}
        {!!selectedNode && isNodeAutomationBlock(selectedNode) && (
          <ConfigAutomationBlock
            id={selectedNode.id}
            onActionSelect={handleActionSelect}
            actionType={selectedNode?.data.automationActionType}
            block={selectedNode?.data?.block}
            onChange={handleActionUpdateBlock}
            onDeselectNode={handleDeselectNode}
            onDeleteNode={handleDeleteNode}
          />
        )}
        {!selectedNode && (
          <div className="flex flex-col gap-y-9">
            <div className="text-xl font-medium">{automationQuery.data?.name}</div>
            <div className="w-full rounded-lg border border-m-olive-100 bg-m-gray-200 py-4 text-center text-xs text-m-olive-900">
              Select automation block to get started
            </div>
            {automationQuery.data && (
              <AutomationStats
                completed={automationQuery.data.completed_run_count}
                running={automationQuery.data.in_progress_run_count}
                failed={automationQuery.data.failed_run_count}
              />
            )}
          </div>
        )}
      </AutomationSidePanel>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        panOnScroll={true}
        panOnScrollSpeed={1.25}
        minZoom={0.1}
        maxZoom={1}
        onPaneClick={handlePaneClick}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onNodesDelete={onNodesDelete}
        nodesDraggable={false}
        nodesConnectable={false}
        proOptions={{ hideAttribution: true }}
        edgesUpdatable={false}
        edgesFocusable={!isInitialState}
        nodesFocusable={false}
        panOnDrag={!isInitialState}
        elementsSelectable={!isInitialState}
        fitView={true}
        deleteKeyCode={deleteKeyCode}
      >
        <Background id="1" gap={20} color="#D4D4D2" variant={BackgroundVariant.Dots} size={2} />
      </ReactFlow>
    </div>
  );
};

import { FC } from 'react';
import { useObjectQuery } from '../../../data/useObjectQuery';
import { FilterItem, useFilterStore } from '../../store';
import { useFilterContext } from '../../context/useFilterContext';
import { PropertyValue } from '../common/PropertyValue';
import { RowContainer } from '../common/RowContainer';
import { MetadataResourcePropertyType, RelationshipEntityType } from '@bigdelta/lib-shared';
import { PropertyOperatorSelect } from '../common/PropertyOperatorSelect';
import { Group } from '../common/Group';
import { Control } from '../common/Control';
import { RecordPropertyMultilevelSelect } from '../common/RecordPropertyMultilevelSelect';
import { last } from 'lodash';

interface RecordsPropertyFilterRowProps {
  index: number;
  filterItem: FilterItem;
  objectSlug: string;
}

export const RecordsPropertyFilterRow: FC<RecordsPropertyFilterRowProps> = ({ index, objectSlug, filterItem }) => {
  const { filterKey, workspaceId } = useFilterContext();

  const setItemPropertyOperator = useFilterStore((state) => state.setItemPropertyOperator);
  const removeItem = useFilterStore((state) => state.removeItem);

  const { data: object } = useObjectQuery({ objectSlug, workspaceId });

  const currentResourceId = last(filterItem.propertyRelationships)?.objectId ?? object?.id;

  return (
    <RowContainer onRemove={() => removeItem(filterKey, index)}>
      <Group>
        {object?.id && (
          <Control>
            <RecordPropertyMultilevelSelect
              index={index}
              filterDataItem={filterItem}
              parentEntityType={RelationshipEntityType.OBJECT}
              parentEntityId={object.id}
            />
          </Control>
        )}
      </Group>
      <Group>
        <Control shouldShrink={false}>
          <PropertyOperatorSelect
            index={index}
            value={filterItem.propertyOperator}
            onChange={(index, value) => setItemPropertyOperator(filterKey, index, value)}
          />
        </Control>
        {currentResourceId && (
          <Control>
            <PropertyValue index={index} resourceId={currentResourceId} resourceType={MetadataResourcePropertyType.OBJECT} />
          </Control>
        )}
      </Group>
    </RowContainer>
  );
};

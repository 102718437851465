import { SidePanel, SidePanelFooterWrapper } from '../../../shared/components/SidePanel.tsx';
import { useNavigate } from 'react-router-dom';
import React, { useCallback, useRef } from 'react';
import { DashboardsRoutes } from '../../../routes';
import { Button } from '../../../shared/ui/Button/Button.tsx';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DashboardsCreatePayload } from '@bigdelta/lib-api-client';

import { toastError, toastSuccess } from '../../../utils/toast.tsx';
import { useWorkspace } from '../../auth/hooks/useWorkspace.tsx';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { useOutsideClickHandler } from '../../../shared/hooks/useOutsideClickHandler.ts';
import { DashboardProfileForm } from './DashboardProfileForm.tsx';
import { DashboardFormSchema } from './DashboardFormSchema.ts';
import { useUser } from '../../auth/hooks/useUser.ts';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

export const CreateDashboardPage = () => {
  const navigate = useNavigate();
  const { currentWorkspaceId } = useWorkspace();
  const { user } = useUser();
  const queryClient = useQueryClient();
  const queryKeys = useQueryKeys();

  const createDashboardMutation = useMutation({
    mutationFn: ({ payload }: { payload: DashboardsCreatePayload }) => bigdeltaAPIClient.v1.dashboardsCreate(payload),
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.dashboards());
      toastSuccess('Created', 'Dashboard created successfully');
      navigate(DashboardsRoutes.INDEX);
    },
    onError: () => {
      toastError('An error occurred while creating the dashboard');
    },
  });

  const onSubmit = useCallback(
    (data: DashboardFormSchema) => {
      createDashboardMutation.mutate({
        payload: {
          name: data.name,
          description: data.description,
          owner: data.owner,
          workspace_id: currentWorkspaceId,
        },
      });
    },
    [currentWorkspaceId, createDashboardMutation]
  );

  const sidePanelRef = useRef();
  useOutsideClickHandler(sidePanelRef, () => {
    navigate(DashboardsRoutes.INDEX);
  });

  return (
    <SidePanel ref={sidePanelRef} title="Create new Dashboard" onClose={() => navigate(DashboardsRoutes.INDEX)}>
      <DashboardProfileForm
        defaultValues={{ owner: user.id }}
        renderSubmitButton={(handleSubmit) => (
          <SidePanelFooterWrapper>
            <Button label="Create Dashboard" intent="brand" fullWidth onClick={handleSubmit(onSubmit)} />
          </SidePanelFooterWrapper>
        )}
      />
    </SidePanel>
  );
};

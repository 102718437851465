import { FC } from 'react';
import { UserSelectionTemplate, useReportStore } from '../store';
import { FilterKey } from '../../../shared/filters/store';

import * as NewSignupsTemplate from './templates/NewSignups';
import * as ActivationFlowTemplate from './templates/ActivationFlow';
import * as ActiveUsersTemplate from './templates/ActiveUsers';
import * as RetentionTemplate from './templates/Retention';
import * as FeatureReportTemplate from './templates/FeatureReport';
import * as MapsNewSignupsTemplate from './templates/MapsNewSignups';
import * as MapsActiveCustomersTemplate from './templates/MapsActiveCustomers';
import * as WebsiteWebsiteTemplate from './templates/WebsiteWebsite';

type Props = {
  reportKey?: string;
  filterKey: FilterKey;
};

export const ReportTemplateBuilder: FC<Props> = ({ reportKey, filterKey }) => {
  const { config } = useReportStore(reportKey)((state) => state);

  if (!config.templateType) {
    return null;
  }

  switch (config.templateType) {
    case UserSelectionTemplate.CUSTOMER_ACTIVATION_NEW_SIGNUPS:
      return <NewSignupsTemplate.Builder filterKey={filterKey} />;
    case UserSelectionTemplate.CUSTOMER_ACTIVATION_ACTIVATION_FLOW:
      return <ActivationFlowTemplate.Builder filterKey={filterKey} />;
    case UserSelectionTemplate.CUSTOMER_ACTIVATION_ACTIVE_USERS:
      return <ActiveUsersTemplate.Builder filterKey={filterKey} />;
    case UserSelectionTemplate.CUSTOMER_RETENTION_RETENTION:
      return <RetentionTemplate.Builder filterKey={filterKey} />;
    case UserSelectionTemplate.PRODUCT_USAGE_FEATURE_REPORT:
      return <FeatureReportTemplate.Builder filterKey={filterKey} />;
    case UserSelectionTemplate.MAPS_NEW_SIGNUPS:
      return <MapsNewSignupsTemplate.Builder filterKey={filterKey} />;
    case UserSelectionTemplate.MAPS_ACTIVE_CUSTOMERS:
      return <MapsActiveCustomersTemplate.Builder filterKey={filterKey} />;
    case UserSelectionTemplate.WEBSITE_WEBSITE:
      return <WebsiteWebsiteTemplate.Builder filterKey={filterKey} />;
    default:
      return null;
  }
};

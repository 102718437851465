import React, { ButtonHTMLAttributes, FC, useCallback, useMemo, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { useWorkspace } from '../../auth/hooks/useWorkspace.tsx';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { PageHeading } from '../../../components/PageHeading.tsx';
import { Button } from '../../../shared/ui/Button/Button.tsx';
import SearchIcon from '../../../assets/icons/search-md.svg?react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { capitalize, compact } from 'lodash';
import { CenteredLoader } from '../../../shared/components/CenteredLoader.tsx';
import { ObjectProfileForm } from '../components/ObjectProfileForm.tsx';
import { ObjectFormSchema } from '../components/ObjectFormSchema.ts';
import { ObjectsCreateData, ObjectsCreatePayload, ObjectsPartialUpdatePayload } from '@bigdelta/lib-api-client';
import { toastError, toastSuccess } from '../../../utils/toast.tsx';
import { ObjectsRoutes } from '../../../routes';
import { ResourcePropertyType, WorkspaceObjectStatus, WorkspaceObjectType } from '@bigdelta/lib-shared';
import { Dialog } from '@headlessui/react';
import { ObjectPropertiesTable } from '../components/properties/ObjectPropertiesTable.tsx';
import { PropertyForm } from '../components/properties/PropertyForm.tsx';
import { PropertyFormSchema } from '../components/properties/PropertyFormSchema.ts';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

interface TabButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  tab: 'configuration' | 'properties';
  currentTab: 'configuration' | 'properties';
  className?: string;
}

const TabButton: FC<TabButtonProps> = ({ label, tab, currentTab, className, ...props }) => {
  const isActive = tab === currentTab;

  return (
    <button
      className={twMerge(
        'text-m flex shrink-0 items-center px-3 py-4 font-medium',
        isActive ? 'border-b-2 border-m-blue-600 text-m-blue-600' : 'border-b border-m-gray-300 text-m-olive-600',
        className
      )}
      {...props}
    >
      <div>{label}</div>
    </button>
  );
};

export const ObjectPage = () => {
  const queryKeys = useQueryKeys();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { currentWorkspaceId } = useWorkspace();
  const { objectId } = useParams();

  const [currentTab, setCurrentTab] = useState<'configuration' | 'properties'>('configuration');
  const [searchTerm, setSearchTerm] = useState('');

  const [isDeactivateConfirmationOpen, setIsDeactivateConfirmationOpen] = useState(false);
  const [isAddPropertyDialogOpen, setIsAddPropertyDialogOpen] = useState(false);

  const objectQuery = useQuery({
    queryKey: queryKeys.object(objectId),
    queryFn: () => bigdeltaAPIClient.v1.objectsDetail(objectId, { workspace_id: currentWorkspaceId }),
    enabled: !!objectId,
  });

  const defaultValues: ObjectFormSchema = useMemo(() => {
    if (!objectQuery.isSuccess) {
      return null;
    }

    return {
      singular_noun: objectQuery.data.singular_noun,
      plural_noun: objectQuery.data.plural_noun,
      api_slug: objectQuery.data.api_slug,
      label_property_0: objectQuery.data.label_properties.length > 0 ? objectQuery.data.label_properties[0] : null,
      label_property_1: objectQuery.data.label_properties.length > 1 ? objectQuery.data.label_properties[1] : null,
    };
  }, [objectQuery.isSuccess, objectQuery.data]);

  const createObjectMutation = useMutation({
    mutationFn: ({ workspaceId, payload }: { workspaceId: string; payload: ObjectsCreatePayload }) =>
      bigdeltaAPIClient.v1.objectsCreate({ workspace_id: workspaceId }, payload),
    onSuccess: (object: ObjectsCreateData) => {
      queryClient.invalidateQueries(queryKeys.list('object'));
      queryClient.invalidateQueries(queryKeys.object(objectId));
      toastSuccess('Created', 'Object created successfully');
      navigate(ObjectsRoutes.SETTINGS_WORKSPACE_LIST + '/' + object.id);
    },
    onError: () => {
      toastError('An error occurred while creating the object');
    },
  });

  const updateObjectMutation = useMutation({
    mutationFn: ({ objectId, workspaceId, payload }: { objectId: string; workspaceId: string; payload: ObjectsPartialUpdatePayload }) =>
      bigdeltaAPIClient.v1.objectsPartialUpdate(objectId, { workspace_id: workspaceId }, payload),
    onSuccess: (data) => {
      queryClient.invalidateQueries(queryKeys.list('object'));
      queryClient.invalidateQueries(queryKeys.object(objectId));
      queryClient.invalidateQueries(queryKeys.single('object', currentWorkspaceId, data.api_slug));
      queryClient.invalidateQueries(queryKeys.list('object-record', objectId));
      queryClient.removeQueries(queryKeys.list('object-record', objectId));
      toastSuccess('Updated', 'Object updated successfully');
      if (isAddPropertyDialogOpen) {
        setIsAddPropertyDialogOpen(false);
      }
    },
    onError: () => {
      toastError('An error occurred while updating the object');
    },
  });

  const deactivateObjectMutation = useMutation({
    mutationFn: (objectId: string) =>
      bigdeltaAPIClient.v1.objectsStatusPartialUpdate(objectId, { workspace_id: currentWorkspaceId }, { status: WorkspaceObjectStatus.INACTIVE }),
    onSuccess: () => {
      toastSuccess('Object deactivated', 'The object has been deactivated successfully');
      queryClient.invalidateQueries(queryKeys.list('object'));
      navigate(ObjectsRoutes.SETTINGS_WORKSPACE_LIST);
    },
    onError: () => {
      toastError('Failed to deactivate object');
    },
  });

  const onCreateObject = useCallback(
    (input: ObjectFormSchema) => {
      createObjectMutation.mutate({
        workspaceId: currentWorkspaceId,
        payload: {
          singular_noun: input.singular_noun,
          plural_noun: input.plural_noun,
          api_slug: input.api_slug,
          label_properties: compact([input.label_property_0, input.label_property_1]),
          records_changelog_enabled: true,
        },
      });
    },
    [currentWorkspaceId, createObjectMutation]
  );

  const onUpdateObject = useCallback(
    (input: ObjectFormSchema) => {
      const payload =
        objectQuery.data?.object_type === WorkspaceObjectType.CUSTOM
          ? {
              singular_noun: input.singular_noun,
              plural_noun: input.plural_noun,
              label_properties: compact([input.label_property_0, input.label_property_1]),
            }
          : {
              label_properties: compact([input.label_property_0, input.label_property_1]),
            };

      updateObjectMutation.mutate({
        objectId: objectId,
        workspaceId: currentWorkspaceId,
        payload,
      });
    },
    [currentWorkspaceId, objectId, objectQuery.data?.object_type, updateObjectMutation]
  );

  const onAddProperty = useCallback(
    (input: PropertyFormSchema) => {
      const mergedProperties = [...(objectQuery.data?.properties || []), input];
      updateObjectMutation.mutate({
        objectId: objectId,
        workspaceId: currentWorkspaceId,
        payload: {
          properties: mergedProperties,
        },
      });
    },
    [currentWorkspaceId, objectId, objectQuery.data?.properties, updateObjectMutation]
  );

  const allObjectProperties = useMemo(() => {
    return [
      { name: '$met_remote_id', type: ResourcePropertyType.STRING, required: true, unique: true },
      ...(objectQuery.data?.properties
        ?.filter((p) => p.name !== '$met_remote_id')
        ?.map((p) => {
          return { name: p.name, type: p.type, required: p.required, unique: false };
        }) || []),
    ];
  }, [objectQuery.data?.properties]);

  const filteredObjectProperties = useMemo(() => {
    return allObjectProperties.filter((property) => {
      const searchablePropertyName = property.name === '$met_remote_id' ? 'id' : property.name;
      return searchablePropertyName.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [allObjectProperties, searchTerm]);

  if (objectId && objectQuery.data?.status === WorkspaceObjectStatus.INACTIVE) {
    return <Navigate to={ObjectsRoutes.SETTINGS_WORKSPACE_LIST} />;
  }

  return (
    <div className="flex h-screen w-full flex-col">
      <div className="flex items-center justify-between" style={{ zIndex: 1 }}>
        <div>
          <PageHeading
            breadcrumbs={[
              {
                label: 'Objects',
                to: ObjectsRoutes.SETTINGS_WORKSPACE_LIST,
                description: 'Manage objects and their properties',
              },
              { label: objectId ? capitalize(objectQuery.data?.plural_noun) || '' : 'New Object' },
            ]}
          />
        </div>
        <div className="flex items-center gap-x-2">
          {currentTab === 'properties' && objectId && (
            <>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
                  <SearchIcon className="h-5 w-5 text-m-gray-600" />
                </div>
                <input
                  type="text"
                  id="simple-search"
                  className="block w-full rounded-md border border-m-gray-300 p-2 ps-10 text-md"
                  placeholder="Search property"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <Button intent="brand" size="lg" label="Add property" onClick={() => setIsAddPropertyDialogOpen(true)} />
            </>
          )}
        </div>
      </div>
      <div className="flex pt-1">
        <TabButton
          tab="configuration"
          currentTab={currentTab}
          label="Configuration"
          onClick={() => {
            setCurrentTab('configuration');
            setSearchTerm('');
          }}
        />
        <TabButton
          tab="properties"
          label="Properties"
          currentTab={currentTab}
          onClick={() => {
            setCurrentTab('properties');
            setSearchTerm('');
          }}
          disabled={!objectId}
          className={twMerge(!objectId && 'cursor-not-allowed text-m-gray-600')}
        />
      </div>
      <div className="w-full border-t border-m-gray-300"></div>
      <div className="flex justify-center overflow-scroll">
        {currentTab === 'configuration' && (
          <>
            {objectId && (
              <>
                {!objectQuery.isInitialLoading && (
                  <>
                    <ObjectProfileForm
                      isCustomObject={objectQuery.data?.object_type === WorkspaceObjectType.CUSTOM}
                      initialValues={defaultValues}
                      objectProperties={[...(objectQuery.data?.properties?.filter((p) => p.type === 'string').map((p) => p.name) || [])]}
                      deactivateButton={
                        <Button label="Deactivate object" intent="destructive" fullWidth onClick={() => setIsDeactivateConfirmationOpen(true)} />
                      }
                      renderSubmitButton={(isDirty, isValid, handleSubmit) => (
                        <Button disabled={!isDirty} label="Save" intent="brand" fullWidth onClick={handleSubmit(onUpdateObject)} />
                      )}
                      className="w-1/2"
                    />
                    <Dialog
                      open={isDeactivateConfirmationOpen}
                      onClose={() => setIsDeactivateConfirmationOpen(false)}
                      className="relative z-[999999]"
                    >
                      <div className="fixed inset-0 bg-m-gray-700 opacity-95" aria-hidden="true" />
                      <div className="fixed inset-0 flex w-screen items-center justify-center">
                        <Dialog.Panel className="mx-auto flex w-3/5 max-w-lg flex-col gap-y-6 rounded-xl bg-white">
                          <Dialog.Title className="p-6 pb-0 text-xl text-m-olive-700">Please Confirm</Dialog.Title>
                          <div className="px-6 text-sm">Are you sure you want to deactivate this object?</div>
                          <div className="flex justify-end gap-x-2 px-6 pb-6">
                            <Button size="sm" intent="tertiary" onClick={() => setIsDeactivateConfirmationOpen(false)} label="Cancel" />
                            <Button size="sm" intent="destructive" onClick={() => deactivateObjectMutation.mutate(objectId)} label="Deactivate" />
                          </div>
                        </Dialog.Panel>
                      </div>
                    </Dialog>
                  </>
                )}
                {objectQuery.isInitialLoading && <CenteredLoader />}
              </>
            )}
            {!objectId && (
              <ObjectProfileForm
                isCustomObject={true}
                initialValues={{}}
                objectProperties={[]}
                renderSubmitButton={(isDirty, isValid, handleSubmit) => (
                  <Button label="Create object" intent="brand" fullWidth onClick={handleSubmit(onCreateObject)} disabled={!isValid} />
                )}
                className="w-1/2"
              />
            )}
          </>
        )}
        {currentTab === 'properties' && objectId && (
          <div>
            <ObjectPropertiesTable objectId={objectId} properties={filteredObjectProperties} />
            <Dialog open={isAddPropertyDialogOpen} onClose={() => setIsAddPropertyDialogOpen(false)} className="relative z-[999999]">
              <div className="fixed inset-0 bg-m-gray-700 opacity-95" aria-hidden="true" />
              <div className="fixed inset-0 flex w-screen items-center justify-center">
                <Dialog.Panel className="mx-auto flex w-3/5 max-w-lg flex-col gap-y-6 rounded-xl bg-white">
                  <Dialog.Title className="p-6 pb-0 text-xl text-m-olive-700">Add property</Dialog.Title>
                  <PropertyForm
                    initialValues={{ type: ResourcePropertyType.STRING, required: true }}
                    reservedNames={allObjectProperties.map((p) => p.name)}
                    onCancel={() => setIsAddPropertyDialogOpen(false)}
                    renderSubmitButton={(isValid, handleSubmit) => (
                      <Button label="Add" intent="brand" size="sm" onClick={handleSubmit(onAddProperty)} disabled={!isValid} />
                    )}
                  />
                </Dialog.Panel>
              </div>
            </Dialog>
          </div>
        )}
      </div>
    </div>
  );
};

import { useCallback, useEffect } from 'react';
import { FilterItem, FilterKey, useFilterStore } from '../../../shared/filters/store';
import { getRecordFilterItemsFromSegment } from '../../../shared/utils/getRecordFilterItemsFromSegment.ts';
import { getEventFilterItemsFromSegment } from '../../../shared/utils/getEventFilterItemsFromSegment.ts';
import { useRelationshipsQuery } from '../../../shared/data/useRelationshipsQuery.ts';
import { useWorkspace } from '../../auth/hooks/useWorkspace.tsx';
import { useReportStore } from '../store';
import { useMetricQuery } from '../../../shared/data/useMetricQuery.ts';

type Parameters = {
  enabled?: boolean;
  metricId?: string | null;
  filterKey: FilterKey;
  reportKey: string;
};

export const useHydrateReportStoreFromMetric = ({ metricId, filterKey, enabled, reportKey }: Parameters) => {
  const { currentWorkspaceId } = useWorkspace();
  const { isHydrated, hydrateFromMetricData, markHydrated } = useReportStore(reportKey)((state) => state);

  const relationshipsQuery = useRelationshipsQuery({ workspaceId: currentWorkspaceId });
  const { setFilterItems } = useFilterStore((state) => state);
  const metricQuery = useMetricQuery({ metricId });

  const hydrateStoreFromMetric = useCallback(() => {
    if (!metricId) {
      markHydrated();
      return null;
    }

    if (
      metricQuery.isSuccess &&
      !isHydrated &&
      relationshipsQuery.isSuccess &&
      metricQuery.data.query_configuration &&
      relationshipsQuery.isSuccess
    ) {
      const {
        data: { query_configuration },
      } = metricQuery;
      let filterItems: FilterItem[] = [];

      if (query_configuration?.metric_query?.records && query_configuration?.metric_query?.records?.filter) {
        filterItems = getRecordFilterItemsFromSegment(
          query_configuration.metric_query.records.filter.conditions,
          query_configuration.metric_query.records.resource.id,
          relationshipsQuery.data.relationships
        ).filter((item) => typeof item !== 'undefined');
      }

      if (query_configuration?.metric_query?.events && query_configuration?.metric_query?.events?.filter) {
        filterItems = getEventFilterItemsFromSegment(
          query_configuration?.metric_query?.events.filter.conditions,
          relationshipsQuery.data.relationships,
          false
        ).filter((item) => typeof item !== 'undefined');
      }

      setFilterItems(filterKey, filterItems);
      hydrateFromMetricData(metricQuery.data, filterItems, relationshipsQuery.data.relationships);
    }
  }, [
    metricId,
    metricQuery,
    isHydrated,
    relationshipsQuery.isSuccess,
    relationshipsQuery.data,
    markHydrated,
    setFilterItems,
    filterKey,
    hydrateFromMetricData,
  ]);

  useEffect(() => {
    if (enabled) {
      hydrateStoreFromMetric();
    }
  }, [hydrateStoreFromMetric, enabled]);

  return null;
};

import { FilterItem, FilterItemType, useFilterStore } from '../../store';
import { useFilterContext } from '../../context/useFilterContext';
import { Listbox } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { FC } from 'react';
import { Button } from '../../../ui/Button/Button';
import PlusIcon from '../../../../assets/icons/plus.svg?react';
import { useReportMetric } from '../../../../features/auth/context/reportMetricContext.tsx';
import { FloatWrapper } from '../../../../features/reports/components/FloatWrapper.tsx';
import { SVGComponent } from '../../../../types.ts';

interface FilterAddOption {
  value: FilterItemType;
  label: string;
  icon: SVGComponent;
}

interface FilterAddProps {
  options: FilterAddOption[];
  as?: FC;
  defaultFilterItem?: Partial<FilterItem>;
}

export const FilterAdd: FC<FilterAddProps> = ({ options, as, defaultFilterItem = {} }) => {
  const { inline } = useReportMetric();
  const addFilterItem = useFilterStore((state) => state.addFilterItem);
  const { filterKey } = useFilterContext();

  const handleChange = (filterItemType: FilterItemType) => {
    addFilterItem(filterKey, filterItemType, defaultFilterItem);
  };

  const buttonProps = !as ? { label: 'Add Filter', size: 'sm', leadingIcon: PlusIcon } : {};

  return (
    <Listbox value={null} onChange={handleChange}>
      <FloatWrapper offset={4} portal inline={inline}>
        <Listbox.Button as={as || Button} {...buttonProps} />
        <Listbox.Options
          className={twMerge(
            'flex w-full flex-col gap-y-0.5 rounded-lg outline-none',
            'border border-m-gray-300 bg-m-white px-1.5 py-1',
            inline && 'mt-2'
          )}
        >
          {options.map((option) => {
            const Icon = option.icon;
            return (
              <Listbox.Option
                className="flex cursor-default items-center gap-x-2 rounded-md px-2.5 py-2 text-sm text-m-olive-600 outline-none hover:bg-m-gray-200 ui-checked:bg-m-gray-200 ui-active:bg-m-gray-200 ui-disabled:text-m-olive-300"
                value={option.value}
                key={option.value}
              >
                <Icon className="h-4 w-4" />
                <span>{option.label}</span>
              </Listbox.Option>
            );
          })}
        </Listbox.Options>
      </FloatWrapper>
    </Listbox>
  );
};

import { Dialog } from '@headlessui/react';
import PencilIcon from '../../../assets/icons/edit-02.svg?react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  QueryReportTemplatesCreatePayload,
  QueryReportsCreatePayload,
  ReportsCreatePayload,
  ReportsUpdatePayload,
} from '@bigdelta/lib-api-client';
import { useForm } from 'react-hook-form';
import React, { useCallback, useState } from 'react';
import LoadingSvg from '../../../assets/loading.svg?react';
import { twMerge } from 'tailwind-merge';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { GroupingConfig } from '../utils/getGroupingConfig.ts';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toastSuccess } from '../../../utils/toast.tsx';
import { Button } from '../../../shared/ui/Button/Button.tsx';
import { Tooltip } from 'react-tooltip';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { tracking, TrackingEvent } from '../../../tracking';
import { Context, ReportTypeVO } from '@bigdelta/lib-shared';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

interface SaveReportProps {
  reportType: ReportTypeVO;
  reportQueryRequest: QueryReportsCreatePayload;
  reportTemplateQueryRequest?: QueryReportTemplatesCreatePayload;
  groupingConfig?: GroupingConfig;
  disabled?: boolean;
}

interface SaveReportFormFields {
  title: string;
  description: string;
  details: string;
  owner: string;
}

export const SaveReport: React.FC<SaveReportProps> = ({
  reportType,
  reportQueryRequest,
  reportTemplateQueryRequest,
  groupingConfig,
  disabled = false,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const queryKeys = useQueryKeys();

  const [searchParams] = useSearchParams();

  const context = searchParams.get('context') && Context[searchParams.get('context')!];

  const { reportId } = useParams();

  const { currentWorkspaceId } = useWorkspace();

  const [isOpen, setIsOpen] = useState(false);

  const savedReportQuery = useQuery({
    queryKey: queryKeys.report(reportId),
    queryFn: () => bigdeltaAPIClient.v1.reportsDetail(reportId ?? '', { workspace_id: currentWorkspaceId }),
    enabled: !!reportId,
    staleTime: Infinity,
  });

  const saveReportMutation = useMutation({
    mutationFn: (payload: ReportsCreatePayload) => bigdeltaAPIClient.v1.reportsCreate(payload),
    onSuccess: (response) => {
      tracking.track(TrackingEvent.ReportSaved, { 'report id': response.id });
      toastSuccess('Created', 'Report saved successfully');
      handleClose();
      navigate(`/reports/${response.id}`);
    },
  });

  const updateReportMutation = useMutation({
    mutationFn: (mutationBody: { reportId: string; payload: ReportsUpdatePayload }) =>
      bigdeltaAPIClient.v1.reportsUpdate(mutationBody.reportId, mutationBody.payload),
    onSuccess: () => {
      toastSuccess('Updated', 'Report updated successfully');
      queryClient.invalidateQueries(['reports', reportId]);
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<SaveReportFormFields>();

  const getReportPayload = useCallback(
    (data: Partial<SaveReportFormFields>) => {
      if (reportType !== ReportTypeVO.TEMPLATE && !reportQueryRequest) {
        return;
      }

      if (reportType === ReportTypeVO.TEMPLATE && !reportTemplateQueryRequest) {
        return;
      }

      return {
        workspace_id: currentWorkspaceId,
        context,
        title: data.title,
        description: data.description,
        details: data.details,
        owner: data.owner,
        type: reportType,
        grouping_config: groupingConfig,
        query: reportQueryRequest ?? undefined,
        template: reportTemplateQueryRequest ?? undefined,
      };
    },
    [context, currentWorkspaceId, groupingConfig, reportQueryRequest, reportTemplateQueryRequest, reportType]
  );

  const onSubmit = handleSubmit((data) => {
    const payload = getReportPayload(data);

    // TODO: handle error
    if (!payload) {
      return;
    }

    saveReportMutation.mutate(payload);
  });

  const membersQuery = useQuery({
    queryKey: queryKeys.members(),
    queryFn: () => bigdeltaAPIClient.v1.membersList({ workspace_id: currentWorkspaceId }),
  });

  const handleClose = () => {
    setIsOpen(false);
    reset();
  };

  const onSaveButtonClick = () => {
    if (reportId && savedReportQuery.data) {
      const payload = getReportPayload({
        title: savedReportQuery.data.title,
        description: savedReportQuery.data.description,
        details: savedReportQuery.data.details,
        owner: savedReportQuery.data.owner?.id ?? undefined,
      });

      // TODO: error handling
      if (!payload) {
        return;
      }

      updateReportMutation.mutate({
        reportId,
        payload,
      });
    }

    if (!reportId) {
      setIsOpen(true);
    }
  };

  return (
    <>
      <Button
        label="Save Report"
        size="lg"
        leadingIcon={PencilIcon}
        intent="secondary"
        onClick={onSaveButtonClick}
        disabled={disabled}
        data-tooltip-id="save-report-tooltip"
        data-tooltip-content="Build report to save it"
      />
      {disabled && <Tooltip id="save-report-tooltip" className="z-10" />}
      <Dialog open={isOpen} onClose={handleClose} className="relative z-[999999]">
        <div className="fixed inset-0 bg-m-gray-700 opacity-95" aria-hidden="true" />
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <Dialog.Panel className="mx-auto flex w-1/3 max-w-md flex-col gap-y-6 rounded-xl bg-white p-6">
            <Dialog.Title className="text-xl text-m-olive-700">Save Report</Dialog.Title>
            <form onSubmit={onSubmit} className="flex flex-col gap-y-6">
              <div className="flex flex-col gap-y-1.5">
                <label htmlFor="segment_name" className="text-md text-m-olive-700">
                  Name
                </label>
                <input
                  className={twMerge('rounded-lg border border-m-gray-300 px-3 py-2', errors.title && 'border-m-red-300')}
                  {...register('title', { required: true })}
                />
              </div>
              <div className="flex flex-col gap-y-1.5">
                <label htmlFor="insight_owner" className="text-md text-m-olive-700">
                  Owner
                </label>
                <select className="rounded-lg border border-m-gray-300 px-3 py-2" {...register('owner')}>
                  {membersQuery.data?.map((member) => (
                    <option key={member.id} value={member.id}>
                      {member.email}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex justify-end gap-x-3.5">
                <Button label="Cancel" intent="secondary" size="xl" onClick={handleClose} />
                <Button
                  label={!saveReportMutation.isLoading ? 'Save' : <LoadingSvg className="h-4 w-4 animate-spin" />}
                  intent="brand"
                  size="xl"
                  type="submit"
                />
              </div>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

import { HTMLAttributes } from 'react';
import { lowerCase, snakeCase } from 'lodash';
import { twMerge } from '../../../../utils/twMerge.ts';
import { Tooltip } from '../../../ui/Tooltip/Tooltip';

type ActionButtonWithTooltipProps = HTMLAttributes<HTMLButtonElement> & { tooltipText: string };
export const ActionButtonWithTooltip = ({ children, tooltipText, className = '', ...restProps }: ActionButtonWithTooltipProps) => {
  const tooltipId = `${snakeCase(lowerCase(tooltipText))}-${+new Date()}`;
  return (
    <>
      <Tooltip content={tooltipText}>
        <button data-tooltip-id={tooltipId} className={twMerge('rounded p-1 text-m-olive-300 hover:bg-m-gray-300', className)} {...restProps}>
          {children}
        </button>
      </Tooltip>
    </>
  );
};

import { FC } from 'react';
import ChevronLeftIcon from '../../../assets/icons/chevron-left.svg?react';

interface AutomationSidePanelHeadingProps {
  title?: string;
  onClickBack: () => void;
}

export const AutomationSidePanelHeading: FC<AutomationSidePanelHeadingProps> = ({ title, onClickBack }) => (
  <div className="flex items-center gap-x-3.5">
    <button onClick={onClickBack}>
      <ChevronLeftIcon className="h-6 w-6" />
    </button>
    <div className="text-xl font-medium">{title}</div>
  </div>
);

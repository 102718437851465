import { useMemo } from 'react';
import { useNodes } from 'reactflow';
import { ActionTypeLabelMap, TRIGGER_NODE_ID } from '../const';
import { isNodeAutomationBlock, isNodeAutomationTrigger } from '../utils/graphNodeTypeguards';
import { NodeAutomationBlockType } from '../components/NodeAutomationBlock';
import { CustomNode } from '../types';
import { AutomationWorkflowBlockType } from '@bigdelta/lib-shared';

export const useAutomationValidation = () => {
  const nodes = useNodes<CustomNode['data']>();

  const errors = useMemo(() => {
    const validationErrors: string[] = [];

    const hasTrigger = nodes.some((node) => node.id === TRIGGER_NODE_ID);
    if (!hasTrigger) {
      validationErrors.push('Automation must have a trigger');
    }

    const triggerNode = nodes.find((node) => node.id === TRIGGER_NODE_ID);
    if (triggerNode) {
      if (isNodeAutomationTrigger(triggerNode) && !triggerNode.data.workspaceObject && !triggerNode.data.eventName) {
        validationErrors.push('Trigger is not fully configured');
      }
    }

    const hasAutomationAction = nodes.some((node) => node.type === NodeAutomationBlockType);
    if (!hasAutomationAction) {
      validationErrors.push('Automation must have at least one action');
    }

    nodes
      .filter((node) => node.type === NodeAutomationBlockType)
      .forEach((node) => {
        if (isNodeAutomationBlock(node) && node.data.block?.type === AutomationWorkflowBlockType.SEND_SLACK_MESSAGE && !node.data.slackChannelName) {
          validationErrors.push(`"${ActionTypeLabelMap[node.data.automationActionType]}" action is not fully configured`);
        }

        if (
          isNodeAutomationBlock(node) &&
          node.data.block?.type === AutomationWorkflowBlockType.DELAY_UNTIL &&
          !node.data.block.delay_until?.datetime
        ) {
          validationErrors.push(`"${ActionTypeLabelMap[node.data.automationActionType]}" action is not fully configured`);
        }
      });

    return validationErrors;
  }, [nodes]);

  return useMemo(
    () => ({
      errors,
      isValid: errors.length === 0,
    }),
    [errors]
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutomationResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const automation_1 = require("../model/automation");
const typebox_2 = require("../typebox");
const memberResponse_1 = require("./memberResponse");
exports.AutomationResponseDef = typebox_1.Type.Composite([
    typebox_1.Type.Omit(automation_1.AutomationDef, ['created_by', 'updated_by']),
    typebox_1.Type.Object({
        created_by: (0, typebox_2.OptionalNullable)(memberResponse_1.MemberResponseDef),
        updated_by: (0, typebox_2.OptionalNullable)(memberResponse_1.MemberResponseDef),
    }),
]);

import { MetricMath, ResourcePropertyType } from '@bigdelta/lib-shared';

export const CHART_COLORS = [
  '#DC143C', // Crimson Red
  '#50C878', // Emerald Green
  '#4682B4', // Steel Blue
  '#FFD700', // Golden Yellow
  '#6A5ACD', // Slate Blue
  '#FF8C00', // Dark Orange
  '#20B2AA', // Light Sea Green
  '#C71585', // Medium Violet Red
  '#F08080', // Light Coral
  '#32CD32', // Lime Green
  '#8A2BE2', // Blue Violet
  '#FA8072', // Salmon
  '#2E8B57', // Sea Green
  '#DAA520', // Goldenrod
  '#7B68EE', // Medium Slate Blue
  '#FF6347', // Tomato
  '#40E0D0', // Turquoise
  '#EE82EE', // Violet
  '#B0C4DE', // Light Steel Blue
  '#F4A460', // Sandy Brown
];

export const DISPLAY_FORMATS = {
  hour: 'HH:mm',
  day: 'MMM dd',
  week: 'MMM dd',
  month: 'MMM dd',
  quarter: 'MMM dd',
};

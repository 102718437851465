"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetricScenarioResponseDef = exports.MetricScenarioDef = exports.UpdateMetricScenarioRequestDef = exports.CreateMetricScenarioRequestDef = exports.CommonMetricScenarioProperties = exports.MetricScenarioCalculationPropertiesDef = exports.MetricScenarioSegmentDef = exports.MetricChangeType = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const timestampedModel_1 = require("./timestampedModel");
const queryTypes_1 = require("../value-object/queryTypes");
const eventsDataQueryVO_1 = require("../value-object/eventsDataQueryVO");
const response_1 = require("../response");
var MetricChangeType;
(function (MetricChangeType) {
    MetricChangeType["PERCENT"] = "percent";
    MetricChangeType["ABSOLUTE"] = "absolute";
})(MetricChangeType || (exports.MetricChangeType = MetricChangeType = {}));
exports.MetricScenarioSegmentDef = typebox_1.Type.Object({
    filter: (0, typebox_2.OptionalNullableRef)(typebox_1.Type.Ref(eventsDataQueryVO_1.EventsQueryRequestQueryDef)),
});
exports.MetricScenarioCalculationPropertiesDef = typebox_1.Type.Object({
    id: typebox_1.Type.Optional(typebox_1.Type.String()),
    metric_id: typebox_1.Type.String(),
    start_at: typebox_2.DateType,
    end_at: typebox_2.DateType,
    period: queryTypes_1.TimeRangeWindowVODef,
    start_value: typebox_1.Type.Number(),
    target_value: typebox_1.Type.Optional(typebox_1.Type.Number()),
    target_configuration: typebox_1.Type.Object({
        metric_change_type: typebox_1.Type.Enum(MetricChangeType),
        value: typebox_1.Type.Number(),
    }),
    segment: (0, typebox_2.OptionalNullable)(exports.MetricScenarioSegmentDef),
}, { additionalProperties: false });
exports.CommonMetricScenarioProperties = typebox_1.Type.Composite([
    exports.MetricScenarioCalculationPropertiesDef,
    typebox_1.Type.Object({
        name: typebox_1.Type.String(),
        workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    }),
], {
    additionalProperties: false,
});
exports.CreateMetricScenarioRequestDef = typebox_1.Type.Composite([
    exports.CommonMetricScenarioProperties,
    typebox_1.Type.Object({
        owner: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
    }),
], {
    additionalProperties: false,
});
exports.UpdateMetricScenarioRequestDef = exports.CreateMetricScenarioRequestDef;
exports.MetricScenarioDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: typebox_1.Type.Optional(typebox_1.Type.String()),
        owner: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
    }),
    exports.CommonMetricScenarioProperties,
    timestampedModel_1.TimestampedModelDef,
]);
exports.MetricScenarioResponseDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: typebox_1.Type.String(),
        owner: (0, typebox_2.OptionalNullable)(response_1.MemberResponseDef),
    }),
    exports.CommonMetricScenarioProperties,
    timestampedModel_1.TimestampedModelDef,
]);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InsightVODef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const model_1 = require("../model");
const types_1 = require("../types");
exports.InsightVODef = typebox_1.Type.Composite([
    model_1.TimestampedModelDef,
    typebox_1.Type.Object({
        id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        accountId: typebox_1.Type.String({ format: 'uuid' }),
        workspaceId: typebox_1.Type.String({ format: 'uuid' }),
        title: typebox_1.Type.Optional(typebox_1.Type.String()),
        description: typebox_1.Type.Optional(typebox_1.Type.String()),
        details: typebox_1.Type.Optional(typebox_1.Type.String()),
        owner: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
        documentGroupIds: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(typebox_1.Type.String({ format: 'uuid' }))),
        createdBy: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
        updatedBy: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
        type: typebox_1.Type.Optional(typebox_1.Type.Enum(types_1.InsightTypeVO)),
        source: typebox_1.Type.Optional(typebox_1.Type.String()),
    }),
]);

import { useParams } from 'react-router-dom';
import { useUrlQuery } from '../hooks/useQuery';
import { RecordsList } from './RecordsList';
import { useWorkspace } from '../../auth/hooks/useWorkspace.tsx';

export const RecordsListPageWrapper = () => {
  const { objectSlug } = useParams();
  const { currentWorkspaceId } = useWorkspace();
  const query = useUrlQuery();

  const workspaceId = query.get('workspaceId') || currentWorkspaceId;

  if (!objectSlug || !workspaceId) {
    return null;
  }

  return <RecordsList objectSlug={objectSlug} workspaceId={workspaceId} />;
};

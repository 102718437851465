"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetricDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const model_1 = require("../model");
const value_object_1 = require("../value-object");
const types_1 = require("../types");
exports.MetricDef = typebox_1.Type.Composite([
    model_1.TimestampedModelDef,
    typebox_1.Type.Object({
        id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        key: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
        description: typebox_1.Type.Optional(typebox_1.Type.String()),
        displayName: typebox_1.Type.String(),
        accountId: typebox_1.Type.String({ format: 'uuid' }),
        workspaceId: typebox_1.Type.String({ format: 'uuid' }),
        source: typebox_1.Type.Optional(typebox_1.Type.Enum(types_1.MetricSource)),
        owner: (0, typebox_2.OptionalNullable)(model_1.MemberDef),
        createdBy: (0, typebox_2.OptionalNullable)(model_1.MemberDef),
        updatedBy: (0, typebox_2.OptionalNullable)(model_1.MemberDef),
        queryConfiguration: typebox_1.Type.Unsafe(value_object_1.MetricQueryConfigurationVODef),
        systemManaged: typebox_1.Type.Boolean(),
    }),
]);

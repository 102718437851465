import { FilterItem, useFilterStore } from '../../store';
import { FC } from 'react';
import { useFilterContext } from '../../context/useFilterContext';
import { MetadataEventsListData } from '@bigdelta/lib-api-client';
import { EventNameComboboxSingle } from './EventNameComboboxSingle';

type EventListItem = MetadataEventsListData['items'][number];

interface EventSelectProps {
  filterDataItem: FilterItem;
  index: number;
  includeAny?: boolean;
}

export const EventSelect: FC<EventSelectProps> = ({ filterDataItem, index, includeAny = false }) => {
  const { filterKey, inlineElements } = useFilterContext();

  const setItemEvent = useFilterStore((state) => state.setItemEvent);

  const handleEventChange = (data: EventListItem | null) => {
    if (data?.event_name) {
      setItemEvent(filterKey, index, data.event_name);
    }
  };

  return (
    <EventNameComboboxSingle value={filterDataItem.event} onChange={handleEventChange} includeAny={includeAny} inlineElements={inlineElements} />
  );
};

import { useLocation } from 'react-router-dom';
import { tracking, TrackingEvent } from '.';
import { AppRoutes, DashboardsRoutes, RecordsRoutes, ReportsRoutes } from '../routes';
import { useEffect } from 'react';

export const PageViewTracker = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === AppRoutes.SIGN_UP) {
      tracking.track(TrackingEvent.SignupStarted);
    }

    if (location.pathname === DashboardsRoutes.INDEX) {
      tracking.track(TrackingEvent.ListViewed, { list: 'dashboard' });
    }

    if (location.pathname === RecordsRoutes.INDEX) {
      tracking.track(TrackingEvent.ListViewed, { list: 'record' });
    }

    if (location.pathname === AppRoutes.SEGMENTS) {
      tracking.track(TrackingEvent.ListViewed, { list: 'segment' });
    }

    if (location.pathname === AppRoutes.REPORTS) {
      tracking.track(TrackingEvent.ListViewed, { list: 'report' });
    }

    if (location.pathname === AppRoutes.METRICS) {
      tracking.track(TrackingEvent.ListViewed, { list: 'metric' });
    }

    if (location.pathname === AppRoutes.REPORTS + '/' + ReportsRoutes.CREATE_REPORT) {
      tracking.track(TrackingEvent.StartedCreatingReport);
    }

    if (location.pathname === AppRoutes.INTEGRATIONS) {
      tracking.track(TrackingEvent.ListViewed, { list: 'integration' });
    }

    if (location.pathname === AppRoutes.AUTOMATIONS) {
      tracking.track(TrackingEvent.ListViewed, { list: 'automation' });
    }
  }, [location]);

  return null;
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toCreateWorkspaceObjectRecordVO = exports.PrivateCreateWorkspaceObjectRecordRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const model_1 = require("../model");
exports.PrivateCreateWorkspaceObjectRecordRequestDef = typebox_1.Type.Object({
    id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    remote_id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    workspace_id: typebox_1.Type.String(),
    workspace_object_slug: typebox_1.Type.String(),
    version: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
    active_since: typebox_1.Type.Optional(typebox_2.DateType),
    active_until: typebox_1.Type.Optional(typebox_2.DateType),
    properties: (0, typebox_2.OptionalNullable)(typebox_2.NestedObjectDef),
    properties_once: (0, typebox_2.OptionalNullable)(typebox_2.NestedObjectDef),
    modified_by: model_1.AuthorDef,
});
const toCreateWorkspaceObjectRecordVO = (request) => {
    return {
        id: request.id,
        remoteId: request.remote_id,
        workspaceId: request.workspace_id,
        workspaceObjectIdOrSlug: request.workspace_object_slug,
        properties: request.properties,
        propertiesOnce: request.properties_once,
        version: request.version,
        activeSince: request.active_since,
        activeUntil: request.active_until,
        modifiedBy: request.modified_by,
    };
};
exports.toCreateWorkspaceObjectRecordVO = toCreateWorkspaceObjectRecordVO;

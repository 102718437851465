import { ReactNode } from "react";
import { PropsWithClassName } from "../../types";
import { twMerge } from "../../utils/twMerge";

type SpanElementProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

interface OverflowTextProps extends PropsWithClassName, SpanElementProps {
  children: ReactNode;
  visible?: boolean;
}

export const OverflowText = ({children, visible, className, ...rest }: OverflowTextProps) => {
  return (
    <span {...rest} className={twMerge(!visible && "truncate", className)}>
      {children}
    </span>
  );
}
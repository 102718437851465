import { FC } from 'react';
import { useFunnelBuilderStore } from '../../store/Report';
import * as ReportBuilderBase from '../common/ReportBuilderBase';
import { useWorkspace } from '../../../auth/hooks/useWorkspace';
import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../../../auth/hooks/useQueryKeys';
import { capitalize } from 'lodash';
import { getWorkspaceObjectIcon } from '../../../../shared/utils/getWorkspaceObjectIcon';
import { RecordsFilterAdd } from '../common/RecordsFilterAdd';
import { FilterCard } from '../../../../shared/filters/components/common/FilterCard';
import { useFilterContext } from '../../../../shared/filters/context/useFilterContext';
import { getFilter, useFilterStore } from '../../../../shared/filters/store';
import { FunnelCountSelect } from './FunnelCountSelect';
import { FunnelCountWindow } from './FunnelCountWindow';
import { FunnelSteps } from './FunnelSteps';
import { FilterContext } from '../../../../shared/filters/context/context';
import { bigdeltaAPIClient } from '../../../../client/bigdeltaAPIClient';

export const FunnelBuilderObject: FC = () => {
  const queryKeys = useQueryKeys();

  const { currentWorkspaceId } = useWorkspace();

  const { filterKey: funnelKey } = useFilterContext();
  const filterKey = [...funnelKey, 'object_filters'];
  const filter = useFilterStore((state) => getFilter(state, filterKey))?.items ?? [];

  const workspaceObjectId = useFunnelBuilderStore(null, (state) => state?.data.object?.workspaceObjectId);

  const objectsQuery = useQuery({
    queryKey: queryKeys.list('object', 'active_and_virtual'),
    queryFn: () => bigdeltaAPIClient.v1.objectsList({ workspace_id: currentWorkspaceId, status: 'ACTIVE_AND_VIRTUAL' }),
  });

  const object = objectsQuery.data?.objects?.find((object) => object.id === workspaceObjectId);

  if (!object) {
    return null;
  }

  return (
    <ReportBuilderBase.Container storeSelector={useFunnelBuilderStore}>
      <div className="flex flex-col gap-y-5">
        <div className="flex flex-col gap-y-4">
        <FilterContext.Provider
          value={{
          filterKey,
          workspaceId: currentWorkspaceId,
          mode: 'inline',
          inlineElements: false,
        }}
      >
          <ReportBuilderBase.Selected
            name={capitalize(object.plural_noun)}
            icon={getWorkspaceObjectIcon(object.object_type)}
            endSlot={<RecordsFilterAdd objectId={object.id} supportedTabs={['properties']}/>}
            filters={
              filter.map((filterItem, index) => (
                <FilterCard
                  key={`${JSON.stringify(filterItem.property)}-${index}`}
                  filterIndex={index}
                  filterItem={filterItem}
                  objectId={object.id}
                  className="rounded-t-none border-t-0"
                />
              ))
            }
          />
          </FilterContext.Provider>
          <FunnelSteps object={object} />
          <div className="flex flex-col gap-y-6">
            <FunnelCountSelect />
            <FunnelCountWindow />
          </div>
        </div>
      </div>
    </ReportBuilderBase.Container>
  );
};

import { Float } from '@headlessui-float/react';
import { Popover } from '@headlessui/react';
import { ChangeEvent, FC, useMemo, useState } from 'react';
import { ComboboxInfinite } from '../../../components/Combobox/ComboboxInfinite';
import { useMetadataPropertiesNamesInfiniteQuery } from '../../../shared/data/useMetadataAttributeNamesInfiniteQuery';
import { MetadataResourcePropertyType } from '@bigdelta/lib-shared';
import { useReportsStore } from '../store';
import { useDebounce } from '@uidotdev/usehooks';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { ReportCreateSelectedMetricAggregateTrigger } from './ReportCreateSelectedMetricContainer';

interface EventPropertySelectProps {}

export const EventPropertySelect: FC<EventPropertySelectProps> = () => {
  const { currentWorkspaceId } = useWorkspace();

  const eventMathProperty = useReportsStore((state) => state.config.eventMathProperty);
  const setEventMathProperty = useReportsStore((state) => state.setEventMathProperty);

  const [rawSearchQuery, setRawSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(rawSearchQuery, 700);

  const metricEvent = useReportsStore((state) => state.config.metricEvent);

  const eventPropertiesQuery = useMetadataPropertiesNamesInfiniteQuery({
    workspaceId: currentWorkspaceId,
    resourceType: MetadataResourcePropertyType.EVENT,
    searchQuery: debouncedSearchQuery,
    objectId: metricEvent,
  });

  const flatProperties = useMemo(
    () => (eventPropertiesQuery.data ? [...eventPropertiesQuery.data.pages.flatMap((d) => d.items)] : []),
    [eventPropertiesQuery]
  );

  return (
    <Popover>
      {({ close }) => (
        <Float placement="bottom-start" offset={4} portal>
          <Popover.Button as={ReportCreateSelectedMetricAggregateTrigger}>
            {eventMathProperty ? eventMathProperty.property_name : 'Select property'}
          </Popover.Button>
          <Popover.Panel>
            {/* <div className="border-b border-m-gray-300 px-4 py-2.5">
              <RelatedObjectSelect
                workspaceId={workspaceId}
                onChange={handleChangeSelectedRelatedObj}
                value={selectedRelatedObjId}
                options={objectIdRelNameList}
              />
            </div> */}
            <ComboboxInfinite
              items={flatProperties}
              selectedItems={eventMathProperty}
              onChange={(data) => {
                setEventMathProperty(data);
                close();
              }}
              onQueryChange={(e: ChangeEvent<HTMLInputElement>) => setRawSearchQuery(e.target.value)}
              query={rawSearchQuery}
              catchInputFocus
              height={450}
              renderOption={(item) => item.property_name}
              hasNextPage={!!eventPropertiesQuery.hasNextPage}
              isFetchingNextPage={eventPropertiesQuery.isFetchingNextPage}
              fetchNextPage={eventPropertiesQuery.fetchNextPage}
              isFetching={eventPropertiesQuery.isFetching}
              isSuccess={eventPropertiesQuery.isSuccess}
              // className="border-0"
            />
          </Popover.Panel>
        </Float>
      )}
    </Popover>
  );
};

import { FC } from 'react';
import StarIcon from '../../assets/icons/star-01.svg?react';
import { Tooltip } from '../ui/Tooltip/Tooltip';
interface FavoriteButtonProps {
  active: boolean;
  onToggle: () => void;
}

export const FavoriteButton: FC<FavoriteButtonProps> = ({ active, onToggle }) => {
  return (
    <>
      <Tooltip content={active ? 'Remove from shortcuts' : 'Add to shortcuts'}>
        <button onClick={onToggle} className="rounded hover:bg-m-gray-300">
          {!active && <StarIcon className="h-5 w-5 text-m-olive-300" />}
          {active && <StarIcon className="h-5 w-5 fill-m-orange-600 text-m-orange-600" />}
        </button>
      </Tooltip>
    </>
  );
};

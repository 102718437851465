import { capitalize } from 'lodash';

export const getUserFriendlyPropertyName = (propertyName: string | undefined) => {
  if (!propertyName) {
    return '';
  }

  return propertyName
    .split('.')
    .map((w) => capitalize(w))
    .join(' -> ')
    .split('_')
    .join(' ');
};

import { Edge } from 'reactflow';
import { AutomationBlock, CustomNode } from '../types';
import { cloneDeep, omit } from 'lodash';
import { AutomationWorkflowBlockType, NestedQueryFilterOperator } from '@bigdelta/lib-shared';
import { BlockFilterMap, isBlockFilterMap, isFilter } from '../hooks/useAutomationBlocksFilterMap';
import { getConditions } from '../../records/data/getRecordsQuery';
import { getEventsQueryWithImplicitFilters } from '../../events/data/getEventsQueryWithImplicitFilters';

// TODO: throw instead of returning null
export const buildBlock = (node: CustomNode, nodes: CustomNode[], edges: Edge[], blockFilterMap: BlockFilterMap): AutomationBlock | null => {
  const childEdge = edges.find((e) => e.source === node.id);
  const childNode = childEdge ? nodes.find((n) => n.id === childEdge.target) : null;

  if ('block' in node.data) {
    // Omit locally draft node ID
    const block: AutomationBlock = omit(cloneDeep(node.data.block), node.data.isDraft ? ['id'] : []);

    if (block.type === AutomationWorkflowBlockType.FILTER_RECORD) {
      const filter = blockFilterMap[node.id];
      if (isFilter(filter)) {
        block.filter_record = {
          filter: { operator: NestedQueryFilterOperator.AND, conditions: getConditions(filter) },
        };
      } else {
        return null;
      }
    }

    if (block.type === AutomationWorkflowBlockType.FILTER_EVENT) {
      const filterMap = blockFilterMap[node.id];
      if (isBlockFilterMap(filterMap) && 'event' in filterMap && 'object' in filterMap) {
        if (isFilter(filterMap.event) && isFilter(filterMap.object)) {
          const filter = getEventsQueryWithImplicitFilters(filterMap.object, filterMap.event)?.filter ?? {
            conditions: [],
            operator: NestedQueryFilterOperator.AND,
          };
          block.filter_event = { filter };
        } else {
          return null;
        }
      } else {
        return null;
      }
    }

    if (!childNode) {
      return block;
    }

    if (block.type === AutomationWorkflowBlockType.SEND_SLACK_MESSAGE && block.send_slack_message) {
      block.send_slack_message.block = buildBlock(childNode, nodes, edges, blockFilterMap);
    }

    if (block.type === AutomationWorkflowBlockType.DELAY && block.delay) {
      block.delay.block = buildBlock(childNode, nodes, edges, blockFilterMap);
    }

    if (block.type === AutomationWorkflowBlockType.DELAY_UNTIL && block.delay_until) {
      block.delay_until.block = buildBlock(childNode, nodes, edges, blockFilterMap);
    }

    if (block.type === AutomationWorkflowBlockType.FILTER_RECORD && block.filter_record) {
      block.filter_record.block = buildBlock(childNode, nodes, edges, blockFilterMap);
    }

    if (block.type === AutomationWorkflowBlockType.FILTER_EVENT && block.filter_event) {
      block.filter_event.block = buildBlock(childNode, nodes, edges, blockFilterMap);
    }

    return block;
  }

  return null;
};

export const INDUSTRIES = [
  'Specialty Retail',
  'Consumer Goods',
  'Hotels, Restaurants & Leisure',
  'Education Services',
  'Media',
  'Retailing',
  'Banks',
  'Real Estate',
  'Health Care Providers & Services',
  'Pharmaceuticals',
  'Construction & Engineering',
  'Professional Services',
  'Air Freight & Logistics',
  'Software',
  'Utilities',
];

export const ROLES = [
  'Sales',
  'Customer service',
  'Design',
  'Education',
  'Engineering',
  'Finance',
  'Health',
  'Human resources',
  'Legal',
  'Marketing',
  'Media',
  'Operations',
  'Public relations',
  'Real estate',
  'Trades',
];

import { twMerge } from 'tailwind-merge';
import { Tooltip } from 'react-tooltip';
import { FC, PropsWithChildren } from 'react';
import { RelationshipObjectData } from '../types';
import { Attribute } from '../filters/store';
import { useWorkspace } from '../../features/auth/hooks/useWorkspace';
import { useObjectsQuery } from '../data/useObjectsQuery';

const Arrow = () => <span>{' -> '}</span>;

interface PropertyWrapperProps extends PropsWithChildren {
  tooltipContent: string;
}

const PropertyWrapper: FC<PropertyWrapperProps> = ({ children, tooltipContent }) => {
  return (
    <span
      className="flex w-full max-w-full gap-x-2 overflow-hidden"
      data-tooltip-id="record-property-tooltip"
      data-tooltip-content={tooltipContent}
      data-tooltip-offset={16}
    >
      {children}
    </span>
  );
};

interface RecordPropertyMultilevelTriggerLabelProps {
  propertyRelationships: (RelationshipObjectData | { objectId: string })[];
  property?: Attribute;
}

export const RecordPropertyMultilevelTriggerLabel = ({ propertyRelationships, property }: RecordPropertyMultilevelTriggerLabelProps) => {
  const { currentWorkspaceId } = useWorkspace();
  const { data: objectList } = useObjectsQuery({ workspaceId: currentWorkspaceId });

  if (!property?.attributeName || !propertyRelationships.length) {
    return 'Select property';
  }

  const getObject = (objectId: string) => {
    return objectList?.objects.find((o) => o.id === objectId);
  };

  const tooltipContent = () => {
    const objects = propertyRelationships.map((rel) => {
      return getObject(rel.objectId)?.plural_noun;
    });
    return [...objects, property?.attributeName].join(' -> ');
  };

  if (propertyRelationships.length === 1) {
    return <span>{property?.attributeName}</span>;
  }

  if (propertyRelationships.length > 1) {
    return (
      <>
        <Tooltip id="record-property-tooltip" className="z-10" />
        <PropertyWrapper tooltipContent={tooltipContent()}>
          <>
            <span className={twMerge('min-w-0 shrink-0 overflow-hidden text-ellipsis')}>
              {getObject(propertyRelationships[1].objectId)?.plural_noun}
            </span>
            <Arrow />

            {propertyRelationships.length === 3 && (
              <>
                <span className={twMerge('min-w-0 overflow-hidden text-ellipsis')}>{getObject(propertyRelationships[2].objectId)?.plural_noun}</span>
                <Arrow />
              </>
            )}

            {propertyRelationships.length > 3 && (
              <>
                <span>{`(${propertyRelationships.length - 2})`}</span>
                <Arrow />
              </>
            )}
          </>
          <span className="overflow-hidden text-ellipsis">{property?.attributeName}</span>
        </PropertyWrapper>
      </>
    );
  }

  return null;
};

import { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useUser } from '../hooks/useUser';
import { getStoredSelectedWorkspaceId, storeSelectedWorkspaceId } from '../../../shared/utils/selectedWorkspaceId.ts';
import { useAccount } from '../hooks/useAccount.tsx';
import { tracking } from '../../../tracking';

export interface WorkspaceContextType {
  currentWorkspaceId: string;
  selectWorkspace: (workspaceId: string) => void;
}

export const WorkspaceContext = createContext<WorkspaceContextType | null>(null);

export const WorkspaceProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [currentWorkspaceId, setCurrentWorkspaceId] = useState<string>();
  const { user } = useUser();
  const { currentAccountId } = useAccount();
  const { workspaces } = useUser();
  const selectWorkspace = useCallback(
    (workspaceId: string) => {
      if (!user || !currentAccountId) {
        return;
      }

      setCurrentWorkspaceId(workspaceId);
      storeSelectedWorkspaceId(workspaceId);
      tracking.identify(user?.id, currentAccountId, workspaceId);
    },
    [currentAccountId, user]
  );

  useEffect(() => {
    let workspaceId = workspaces?.find((w) => w.account_id === currentAccountId)?.id;
    const storedWorkspaceId = getStoredSelectedWorkspaceId();
    if (storedWorkspaceId && workspaces?.some((item) => item.id === storedWorkspaceId && item.account_id === currentAccountId)) {
      workspaceId = storedWorkspaceId;
    }

    if (workspaceId) {
      selectWorkspace(workspaceId);
    }
  }, [currentAccountId, selectWorkspace, workspaces]);

  if (!currentWorkspaceId) {
    return null;
  }

  return <WorkspaceContext.Provider value={{ currentWorkspaceId, selectWorkspace }}>{children}</WorkspaceContext.Provider>;
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toMetricGraphNodeVO = exports.CreateMetricGraphNodeRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const value_object_1 = require("../value-object");
const typebox_2 = require("../typebox");
exports.CreateMetricGraphNodeRequestDef = typebox_1.Type.Object({
    key: typebox_1.Type.String(),
    impact: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
        expression: typebox_1.Type.String(),
    })),
    type: typebox_1.Type.Enum(value_object_1.MetricGraphMetricDefinitionType),
    metric_id: typebox_1.Type.String({ format: 'uuid' }),
    parent_id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    child_ids: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(typebox_1.Type.String({ format: 'uuid' }))),
});
const toMetricGraphNodeVO = (workspaceId, graphId, createMetricGraphNodeRequest, createdBy, updatedBy) => {
    return {
        workspace_id: workspaceId,
        graph_id: graphId,
        key: createMetricGraphNodeRequest.key,
        composition: createMetricGraphNodeRequest.impact,
        type: createMetricGraphNodeRequest.type,
        metric_id: createMetricGraphNodeRequest.metric_id,
        created_by: createdBy,
        updated_by: updatedBy,
    };
};
exports.toMetricGraphNodeVO = toMetricGraphNodeVO;

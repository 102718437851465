import { FC, useMemo } from 'react';
import { RecordsFilter } from '../../../shared/filters/components/records/RecordsFilter';
import { FilterKey } from '../../../shared/filters/store';
import { useObjectsQuery } from '../../../shared/data/useObjectsQuery';
import { useWorkspace } from '../../auth/hooks/useWorkspace';

interface ReportCreateFilterRecords {
  objectId: string;
  filterKey: FilterKey;
}

export const ReportCreateFilterRecords: FC<ReportCreateFilterRecords> = ({ objectId, filterKey }) => {
  const { currentWorkspaceId } = useWorkspace();

  const objectsQuery = useObjectsQuery({ workspaceId: currentWorkspaceId });

  const currentObject = useMemo(() => {
    return objectsQuery.data?.objects.find((object) => object.id === objectId);
  }, [objectId, objectsQuery.data?.objects]);

  if (!currentObject) {
    return null;
  }

  return <RecordsFilter workspaceId={currentObject.workspace_id} objectSlug={currentObject.api_slug} filterKey={filterKey} mode={'stacked'} />;
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataImportJobDef = exports.DataImportJobStatus = exports.DataImportJobType = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const timestampedModel_1 = require("./timestampedModel");
var DataImportJobType;
(function (DataImportJobType) {
    DataImportJobType["INITIAL"] = "INITIAL";
    DataImportJobType["INCREMENTAL"] = "INCREMENTAL";
})(DataImportJobType || (exports.DataImportJobType = DataImportJobType = {}));
var DataImportJobStatus;
(function (DataImportJobStatus) {
    DataImportJobStatus["SCHEDULED"] = "SCHEDULED";
    DataImportJobStatus["IN_PROGRESS"] = "IN_PROGRESS";
    DataImportJobStatus["COMPLETED"] = "COMPLETED";
    DataImportJobStatus["FAILED_PERMANENTLY"] = "FAILED_PERMANENTLY";
    DataImportJobStatus["FAILED_RETRY_SCHEDULED"] = "FAILED_RETRY_SCHEDULED";
})(DataImportJobStatus || (exports.DataImportJobStatus = DataImportJobStatus = {}));
exports.DataImportJobDef = typebox_1.Type.Composite([
    timestampedModel_1.TimestampedModelDef,
    typebox_1.Type.Object({
        id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        integration_id: typebox_1.Type.String({ format: 'uuid' }),
        sync_id: typebox_1.Type.String({ format: 'uuid' }),
        type: typebox_1.Type.Enum(DataImportJobType),
        status: typebox_1.Type.Enum(DataImportJobStatus),
        next_processing_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
        retry_count: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
    }),
]);

import { FC, PropsWithChildren, useState } from 'react';
import { ActionSlackConfig } from './blocks/Slack/ActionSlackConfig';
import { AutomationBlock, CustomNodeData, TriggerType } from '../types';
import { AutomationWorkflowBlockType } from '@bigdelta/lib-shared';

import SlackIcon from '../../../assets/integrations/Slack.svg?react';

import ClockIcon from '../../../assets/icons/clock-snooze.svg?react';

import { ActionTypeLabelMap } from '../const';
import { AutomationSidePanelHeading } from './AutomationSidePanelHeading';
import { Button, ButtonComponentProps } from '../../../shared/ui/Button/Button';
import { ActionDelayUntilConfig } from './blocks/DelayUntil/ActionDelayUntilConfig';
import { ActionDelayConfig } from './blocks/Delay/ActionDelayConfig';
import { FilterRecordConfig } from './blocks/FilterRecord/FilterRecordConfig';
import { SVGComponent } from '../../../types';
import { useNodes } from 'reactflow';
import { NodeAutomationTriggerType } from './NodeAutomationTrigger';
import { isNodeAutomationTrigger } from '../utils/graphNodeTypeguards';
import { FilterEventConfig } from './blocks/FilterEvent/FilterEventConfig';

const OptionsGroupHeading: FC<PropsWithChildren> = ({ children }) => {
  return <div className="pl-4 pt-3 text-xxs font-medium text-m-gray-600">{children}</div>;
};

const OptionsGroupContainer: FC<PropsWithChildren> = ({ children }) => {
  return <div className="flex flex-col gap-y-2">{children}</div>;
};

interface BlockOptionProps extends ButtonComponentProps {
  icon: SVGComponent;
  label: string;
}

const BlockOption: FC<BlockOptionProps> = ({ icon, label, ...props }) => {
  const Icon = icon;

  return (
    <button
      className="flex w-full cursor-pointer items-center gap-x-2 rounded-lg border border-m-olive-100 px-3 py-2 text-md text-m-olive-600 hover:bg-m-gray-200"
      {...props}
    >
      <Icon className="h-3 w-3" />
      <div>{label}</div>
    </button>
  );
};

enum EditStep {
  ACTION_SELECT = 'ACTION_SELECT',
  ACTION_CONFIG = 'ACTION_CONFIG',
}

interface BaseActionsConfigProps {
  id?: string;
  type: AutomationWorkflowBlockType;
  block?: AutomationBlock;
  onChange: (block: AutomationBlock) => void;
}

const actionTypeMap: Partial<Record<AutomationWorkflowBlockType, FC<BaseActionsConfigProps>>> = {
  [AutomationWorkflowBlockType.SEND_SLACK_MESSAGE]: ActionSlackConfig,
  [AutomationWorkflowBlockType.DELAY]: ActionDelayConfig,
  [AutomationWorkflowBlockType.DELAY_UNTIL]: ActionDelayUntilConfig,
  [AutomationWorkflowBlockType.FILTER_RECORD]: FilterRecordConfig,
  [AutomationWorkflowBlockType.FILTER_EVENT]: FilterEventConfig,
};

interface ConfigAutomationBlockProps {
  id?: string;
  onActionSelect: (action: AutomationWorkflowBlockType) => void;
  actionType?: AutomationWorkflowBlockType;
  block?: AutomationBlock;
  onChange?: (block: AutomationBlock) => void;
  onDeselectNode: () => void;
  onDeleteNode: (id: string) => void;
}

export const ConfigAutomationBlock: FC<ConfigAutomationBlockProps> = ({
  id,
  onActionSelect,
  actionType,
  block,
  onChange,
  onDeselectNode,
  onDeleteNode,
}) => {
  const ActionConfigComponent = actionType ? actionTypeMap[actionType] : null;

  const [step, setStep] = useState<EditStep>(() => {
    if (!actionType) {
      return EditStep.ACTION_SELECT;
    }
    if (actionType) {
      return EditStep.ACTION_CONFIG;
    }
    return EditStep.ACTION_SELECT;
  });
  const [stepHistory, setStepHistory] = useState<EditStep[]>([step]);
  const nodes = useNodes<CustomNodeData>();

  const heading = step === EditStep.ACTION_CONFIG && actionType ? ActionTypeLabelMap[AutomationWorkflowBlockType[actionType]] : 'Select block';

  const handleClickBack = () => {
    if (stepHistory.length > 1) {
      setStepHistory((prev) => prev.slice(0, -1));
      setStep(stepHistory[stepHistory.length - 2]);
    } else {
      onDeselectNode();
    }
  };

  const nodeWithTriggerType = nodes.find((node) => node.type === NodeAutomationTriggerType);
  const isTriggerNode = nodeWithTriggerType && isNodeAutomationTrigger(nodeWithTriggerType);
  const triggerNode = isTriggerNode ? nodeWithTriggerType : undefined;

  const canFilterRecords =
    triggerNode?.data.triggerType &&
    [TriggerType.RECORD_CREATED, TriggerType.RECORD_UPDATED, TriggerType.RECORD_DELETED].includes(triggerNode.data.triggerType);
  const canFilterEvents = triggerNode?.data.triggerType && [TriggerType.EVENT_OCCURRED].includes(triggerNode.data.triggerType);

  return (
    <div className="flex flex-col gap-y-9">
      <AutomationSidePanelHeading title={heading} onClickBack={handleClickBack} />
      {step === EditStep.ACTION_SELECT && (
        <div className="flex flex-col gap-y-4">
          <OptionsGroupHeading>Actions</OptionsGroupHeading>
          <OptionsGroupContainer>
            <BlockOption
              icon={SlackIcon}
              label="Send message to Slack"
              onClick={() => onActionSelect(AutomationWorkflowBlockType.SEND_SLACK_MESSAGE)}
            />
          </OptionsGroupContainer>
          <OptionsGroupHeading>Delays</OptionsGroupHeading>
          <OptionsGroupContainer>
            <BlockOption icon={ClockIcon} label="Delay" onClick={() => onActionSelect(AutomationWorkflowBlockType.DELAY)} />
            <BlockOption icon={ClockIcon} label="Delay until" onClick={() => onActionSelect(AutomationWorkflowBlockType.DELAY_UNTIL)} />
          </OptionsGroupContainer>
          <OptionsGroupHeading>Conditions</OptionsGroupHeading>
          <OptionsGroupContainer>
            {canFilterRecords && (
              <BlockOption icon={ClockIcon} label="Filter" onClick={() => onActionSelect(AutomationWorkflowBlockType.FILTER_RECORD)} />
            )}
            {canFilterEvents && (
              <BlockOption icon={ClockIcon} label="Filter" onClick={() => onActionSelect(AutomationWorkflowBlockType.FILTER_EVENT)} />
            )}
          </OptionsGroupContainer>
        </div>
      )}

      {step === EditStep.ACTION_CONFIG && actionType && onChange && ActionConfigComponent && (
        <div className="flex flex-col items-end">
          <div className="w-full">
            <ActionConfigComponent id={id} type={actionType} onChange={onChange} block={block} />
          </div>
          <hr className="my-6 w-full border-m-olive-100" />
          <Button intent="destructive" label="Delete" onClick={() => onDeleteNode(id!)} />
        </div>
      )}
    </div>
  );
};

import React from 'react';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import { AppRoutes } from '../../../routes';
import { Button } from '../../../shared/ui/Button/Button.tsx';
import { Link } from '../../../shared/ui/Link/Link.tsx';
import { FormInput } from '../../../shared/ui/FormInput/FormInput.tsx';
import { GoogleSignIn } from '../../auth/components/GoogleSignIn.tsx';
import { SignUpFormSchema, useAuth } from '../../auth/hooks/useAuth.tsx';

export const SignUpPage = () => {
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<SignUpFormSchema>();

  const { userContext, searchParams, onGoogleSignIn, onSignUp, error, loading, invited } = useAuth();

  if (userContext.user && userContext.authenticated && userContext.ready) {
    return <Navigate to={AppRoutes.ROOT} />;
  }

  return (
    <div className="relative">
      <div className="absolute flex h-full min-h-full w-screen items-center justify-center bg-m-coconut-600" />
      <div className="relative flex min-h-screen items-center justify-center">
        <div className="m-8 flex h-fit w-full max-w-[32rem] flex-col rounded-xl bg-white p-12 text-m-olive-700">
          <div className="mb-12 flex items-center justify-center">
            <p className="text-display-md font-regular">Bigdelta</p>
          </div>
          <div className="flex items-center justify-center text-display-xs font-medium">
            {invited ? 'Join your team on Bigdelta' : 'Create your free account'}
          </div>
          <div className="flex items-center justify-center py-3">
            <GoogleSignIn onSuccess={(idToken) => onGoogleSignIn(idToken)} onError={() => onGoogleSignIn(null)} />
          </div>
          <div className="flex items-center justify-center">
            {error && <div className="w-full rounded-lg border border-m-red-300 bg-m-red-200 px-4 py-2 text-md text-m-red-700">{error}</div>}
          </div>
          <form onSubmit={handleSubmit(onSignUp)} className="pt-3">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col">
                <FormInput
                  label="Work email"
                  inputProps={{
                    ...register('email', { required: true }),
                    type: 'email',
                    placeholder: 'name@example.com',
                  }}
                />
              </div>
              <Button label="Continue" className="w-full" size="lg" type="submit" disabled={!isValid || loading} loading={loading} />
            </div>
          </form>
          <div className="align-center mt-6 flex justify-center text-sm">
            Already have an account?
            <Link className="ml-1" to={{ pathname: '/login', search: searchParams.toString() }} intent="primary">
              {' '}
              Log in
            </Link>
          </div>
          <div className="mt-16 flex flex-col items-center justify-center text-sm font-regular text-m-olive-300">
            <div>By continuing, you agree to the</div>
            <div>
              <Link intent="subdued" size="sm" to="https://www.bigdelta.com/terms" target="_blank" className="underline">
                Terms of Service
              </Link>{' '}
              &{' '}
              <Link intent="subdued" size="sm" to="https://www.bigdelta.com/privacy" target="_blank" className="underline">
                Privacy Policy
              </Link>
              .
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toWorkspaceResponse = exports.WorkspaceResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
exports.WorkspaceResponseDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
        account_id: typebox_1.Type.String({ format: 'uuid' }),
        name: typebox_1.Type.String(),
        logo_image_url: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    }),
]);
const toWorkspaceResponse = (workspace) => {
    return {
        id: workspace.id,
        account_id: workspace.accountId,
        name: workspace.name,
        logo_image_url: workspace.logo_image_url,
    };
};
exports.toWorkspaceResponse = toWorkspaceResponse;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateWorkspaceObjectRecordVODef = void 0;
const typebox_1 = require("../typebox");
const typebox_2 = require("@sinclair/typebox");
const upsertWorkspaceObjectRecordVO_1 = require("./upsertWorkspaceObjectRecordVO");
exports.UpdateWorkspaceObjectRecordVODef = typebox_2.Type.Composite([
    upsertWorkspaceObjectRecordVO_1.UpsertWorkspaceObjectRecordVODef,
    typebox_2.Type.Object({ propertiesOnce: (0, typebox_1.OptionalNullable)(typebox_1.NestedObjectDef) }),
]);

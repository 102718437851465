"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toSegmentVO = exports.CreateSegmentRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const value_object_1 = require("../value-object");
const typebox_2 = require("../typebox");
const types_1 = require("../types");
exports.CreateSegmentRequestDef = typebox_1.Type.Object({
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    name: typebox_1.Type.String(),
    description: typebox_1.Type.Optional(typebox_1.Type.String()),
    resource_type: typebox_1.Type.Enum(types_1.SegmentResourceType),
    events_query: (0, typebox_2.OptionalNullableRef)(typebox_1.Type.Ref(value_object_1.EventsDataQueryRequestDef)),
    records_query: (0, typebox_2.OptionalNullable)(value_object_1.RecordsDataQueryRequestDef),
});
const toSegmentVO = (segment, accountId) => {
    return {
        accountId,
        recordsQuery: segment.records_query,
        eventsQuery: segment.events_query,
        name: segment.name,
        resourceType: segment.resource_type,
        workspaceId: segment.workspace_id,
        description: segment.description,
    };
};
exports.toSegmentVO = toSegmentVO;

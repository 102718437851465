import { FilterContext } from '../../../../shared/filters/context/context';
import { useWorkspace } from '../../../auth/hooks/useWorkspace';


import { FunnelBuilderObject } from './FunnelBuilderObject';


export const FunnelBuilder = () => {
  const { currentWorkspaceId } = useWorkspace();
  const filterKey = ['funnel'];

  return (
    <FilterContext.Provider value={{ filterKey, workspaceId: currentWorkspaceId, mode: 'inline', inlineElements: false }}>
        <FunnelBuilderObject />
    </FilterContext.Provider>
  );
};

import { FC, useCallback } from 'react';
import { Popover } from '@headlessui/react';
import { FilterTrigger } from '../../../../components/FilterTrigger.tsx';
import { Combobox } from '../../../../components/Combobox';
import { ALL_EVENTS, FilterItem, useFilterStore } from '../../store';
import { useFilterContext } from '../../context/useFilterContext';
import { MetadataResourcesPropertiesNamesListData } from '@bigdelta/lib-api-client';
import { useQuery } from '@tanstack/react-query';

import { PropertyNameObject } from '../../../types';
import { useWorkspace } from '../../../../features/auth/hooks/useWorkspace';
import { OpenTrigger } from '../../../components/OpenTrigger';
import { MetadataResourcePropertyType } from '@bigdelta/lib-shared';
import { useQueryKeys } from '../../../../features/auth/hooks/useQueryKeys.ts';
import { FloatWrapper } from '../../../../features/reports/components/FloatWrapper.tsx';
import { twMerge } from 'tailwind-merge';
import { bigdeltaAPIClient } from '../../../../client/bigdeltaAPIClient.ts';

type PropertyNameObj = MetadataResourcesPropertiesNamesListData['items'][number];

interface EventPropertySelectProps {
  index: number;
  filterItem: FilterItem;
}

export const EventPropertySelect: FC<EventPropertySelectProps> = ({ filterItem, index }) => {
  const { filterKey, inlineElements } = useFilterContext();
  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();

  const setPropertyWithRelationships = useFilterStore((state) => state.setPropertyWithRelationships);

  const { data: metadataPropertyNames } = useQuery({
    queryKey: queryKeys.list('metadata', 'events', 'properties', 'names', filterItem.event),
    queryFn: () =>
      bigdeltaAPIClient.v1.metadataResourcesPropertiesNamesList({
        workspace_id: currentWorkspaceId,
        resource_type: MetadataResourcePropertyType.EVENT,
        resource_id: filterItem.event === ALL_EVENTS ? undefined : filterItem.event,
      }),
  });

  const filterCompare = useCallback((item: PropertyNameObj, query: string) => item.property_name.toLocaleLowerCase().includes(query), []);

  const handlePropertyChange = (data: PropertyNameObject | null) => {
    if (data) {
      setPropertyWithRelationships(
        filterKey,
        index,
        {
          attributeId: data.property_id,
          attributeType: data.property_type ?? 'string',
          attributeName: data.property_name,
        },
        []
      );
    }
  };

  return (
    <Popover>
      {({ open, close }) => (
        <FloatWrapper placement="bottom-start" offset={4} portal inline={inlineElements}>
          <Popover.Button as={FilterTrigger} open={open} className="w-full group-hover:bg-[#f0f0f0]">
            {filterItem.property?.attributeName ?? 'Select property'}
            <OpenTrigger shouldOpen={!filterItem.property?.attributeName} />
          </Popover.Button>
          <Popover.Panel>
            <Combobox
              items={metadataPropertyNames?.items ?? []}
              selectedItems={
                filterItem.property
                  ? {
                      property_id: filterItem.property.attributeId,
                      property_name: filterItem.property.attributeName,
                    }
                  : null
              }
              onChange={(data) => {
                handlePropertyChange(data);
                close();
              }}
              catchInputFocus
              height={380}
              renderOption={(item) => item.property_name}
              filterCompare={filterCompare}
              className={twMerge(inlineElements && 'mt-2')}
            />
          </Popover.Panel>
        </FloatWrapper>
      )}
    </Popover>
  );
};

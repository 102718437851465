import { AutomationsCreateData } from '@bigdelta/lib-api-client';
import { FC, ReactElement } from 'react';
import { pluralize } from '../../../../../shared/utils/pluralize';

interface DelayNodeContentProps {
  warning: ReactElement;
  block: AutomationsCreateData['configuration']['trigger']['block'];
}

export const DelayNodeContent: FC<DelayNodeContentProps> = ({ warning, block }) => {
  if (!block.delay?.unit || !block.delay?.value) {
    return warning;
  }

  return <div className="text-sm font-regular text-m-olive-400">{`${block.delay.value} ${pluralize(block.delay.value, block.delay.unit)}`}</div>;
};

import * as TabsBase from '@radix-ui/react-tabs';
import { forwardRef } from 'react';
import { twMerge } from '../../../utils/twMerge';

export const Root = TabsBase.Root;
export const Content = TabsBase.Content;

export const List = forwardRef<HTMLDivElement, TabsBase.TabsListProps>(({ className, ...props }, ref) => {
  return <TabsBase.List className={twMerge('flex shrink-0 border-b border-b-m-olive-100', className)} {...props} ref={ref} />;
});

export const Trigger = forwardRef<HTMLButtonElement, TabsBase.TabsTriggerProps>(({ className, ...props }, ref) => {
  return (
    <TabsBase.Trigger
      className={twMerge(
        'flex shrink grow cursor-pointer select-none items-center justify-center gap-2 bg-white px-3 py-4 text-xs font-medium leading-none outline-none radix-state-active:shadow-tab-active radix-state-active:[--shadow-color:theme(colors.m-blue.600)]',
        className
      )}
      {...props}
      ref={ref}
    />
  );
});

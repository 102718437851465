"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toOpportunityResponse = exports.OpportunityResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const documentGroupsResponse_1 = require("./documentGroupsResponse");
const memberResponse_1 = require("./memberResponse");
exports.OpportunityResponseDef = typebox_1.Type.Object({
    id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    accountId: typebox_1.Type.String({ format: 'uuid' }),
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    title: typebox_1.Type.Optional(typebox_1.Type.String()),
    description: typebox_1.Type.Optional(typebox_1.Type.String()),
    details: typebox_1.Type.Optional(typebox_1.Type.String()),
    document_groups: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(typebox_1.Type.Object({
        id: typebox_1.Type.String({ format: 'uuid' }),
        name: typebox_1.Type.String(),
    }))),
    owner: (0, typebox_2.OptionalNullable)(memberResponse_1.MemberResponseDef),
    created_by: (0, typebox_2.OptionalNullable)(memberResponse_1.MemberResponseDef),
    updated_by: (0, typebox_2.OptionalNullable)(memberResponse_1.MemberResponseDef),
    created_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    updated_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    opportunity_metric: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
    opportunity_ambition: typebox_1.Type.Optional(typebox_1.Type.String()),
    impact_metric: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
    impact_size: typebox_1.Type.Optional(typebox_1.Type.String()),
});
const toOpportunityResponse = (opportunity) => {
    return {
        id: opportunity.id,
        accountId: opportunity.accountId,
        workspace_id: opportunity.workspaceId,
        title: opportunity.title,
        description: opportunity.description,
        details: opportunity.details,
        owner: (0, memberResponse_1.toMemberResponse)(opportunity.owner),
        document_groups: (0, documentGroupsResponse_1.toDocumentGroupResponse)(opportunity === null || opportunity === void 0 ? void 0 : opportunity.documentGroups),
        created_by: (0, memberResponse_1.toMemberResponse)(opportunity.createdBy),
        updated_by: (0, memberResponse_1.toMemberResponse)(opportunity.updatedBy),
        created_at: opportunity.systemCreatedAt,
        updated_at: opportunity.systemUpdatedAt,
        opportunity_metric: opportunity.opportunityMetric,
        opportunity_ambition: opportunity.opportunityAmbition,
        impact_size: opportunity.impactSize,
        impact_metric: opportunity.impactMetric,
    };
};
exports.toOpportunityResponse = toOpportunityResponse;

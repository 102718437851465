import { AutomationWorkflowBlockType } from '@bigdelta/lib-shared';
import { FC, useEffect, useMemo, useState } from 'react';
import { AutomationsCreateData } from '@bigdelta/lib-api-client';
import { merge } from 'lodash';
import { useDebounce } from '@uidotdev/usehooks';
import { FilterContext } from '../../../../../shared/filters/context/context';
import { useWorkspace } from '../../../../auth/hooks/useWorkspace';
import { FilterRecordConfigObjectBlockWithFilter } from './FilterRecordConfigObjectBlockWithFilter';
import { useNodes } from 'reactflow';
import { CustomNodeData } from '../../../types';
import { isNodeAutomationBlock } from '../../../utils/graphNodeTypeguards';
import { useObjectsQuery } from '../../../../../shared/data/useObjectsQuery';

type AutomationBlock = AutomationsCreateData['configuration']['trigger']['block'];

interface FilterRecordConfigProps {
  id?: string;
  type: AutomationWorkflowBlockType;
  block?: AutomationBlock;
  onChange: (block: AutomationBlock) => void;
}

//TODO: Loading state
export const FilterRecordConfig: FC<FilterRecordConfigProps> = ({ id, block, onChange }) => {
  const node = useNodes<CustomNodeData>().find((node) => node.id === id);
  const workspaceObjectId = node && isNodeAutomationBlock(node) ? node.data.workspaceObjectId : undefined;

  const { currentWorkspaceId } = useWorkspace();

  const [rawValue] = useState(block?.delay?.value?.toString() ?? '');
  const debouncedValue = useDebounce(rawValue, 400);

  const objectsQuery = useObjectsQuery({ workspaceId: currentWorkspaceId });

  const workspaceObject = objectsQuery.data?.objects.find((object) => object.id === workspaceObjectId);

  useEffect(() => {
    if (
      (block?.delay?.value === undefined && !debouncedValue) ||
      block?.delay?.value === parseInt(debouncedValue) ||
      isNaN(parseInt(debouncedValue))
    ) {
      return;
    }

    onChange(
      merge(block, {
        id,
        type: AutomationWorkflowBlockType.DELAY,
        delay: {
          value: parseInt(debouncedValue),
        },
      })
    );
  }, [block, debouncedValue, id, onChange]);

  const filterKey = useMemo(() => {
    if (!block?.id) {
      return;
    }

    return ['automations', 'block', block?.id];
  }, [block?.id]);

  if (!filterKey || !objectsQuery.isSuccess) {
    return null;
  }

  return (
    <div className="group/builder">
      <div className="flex flex-col items-stretch">
        <div className="flex flex-col gap-y-6">
          <div className="flex flex-col gap-y-2">
            {workspaceObject && (
              <FilterContext.Provider value={{ filterKey, workspaceId: currentWorkspaceId, mode: 'inline', inlineElements: false }}>
                <FilterRecordConfigObjectBlockWithFilter workspaceObject={workspaceObject} />
              </FilterContext.Provider>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { ToolsTable } from '../../../../shared/components/ToolsTable.tsx';

import { suppressConsoleWarn } from '../../../../shared/utils/suppressConsoleWarn.ts';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useQueryKeys } from '../../../auth/hooks/useQueryKeys.ts';
import { Button } from '../../../../shared/ui/Button/Button.tsx';
import { useWorkspace } from '../../../auth/hooks/useWorkspace.tsx';
import PlusIcon from '../../../../assets/icons/plus.svg?react';
import { ObjectRelationship, RelationshipCell } from '../relationships/RelationshipCell.tsx';
import { RelationshipEntityType, ResourcePropertyType } from '@bigdelta/lib-shared';
import { capitalize, includes } from 'lodash';
import { Dialog } from '@headlessui/react';
import { RelationshipForm } from '../relationships/RelationshipForm.tsx';
import { RelationshipFormSchema } from '../relationships/RelationshipFormSchema.ts';
import { toastError, toastSuccess } from '../../../../utils/toast.tsx';
import { RelationshipsCreatePayload } from '@bigdelta/lib-api-client';
import { mapToUserFriendlyDataType } from '../../utils/propertyTypeMapper.ts';
import { bigdeltaAPIClient } from '../../../../client/bigdeltaAPIClient.ts';

const columnHelper = createColumnHelper<ObjectProperty[][number]>();

export interface ObjectProperty {
  name: string;
  type: ResourcePropertyType;
  unique: boolean;
  required: boolean;
}

interface ObjectPropertiesTableProps {
  objectId: string;
  properties?: ObjectProperty[];
}

export const ObjectPropertiesTable: FC<ObjectPropertiesTableProps> = ({ objectId, properties }) => {
  const queryClient = useQueryClient();
  const queryKeys = useQueryKeys();
  const { currentWorkspaceId } = useWorkspace();
  const [isAddRelationshipDialogOpen, setIsAddRelationshipDialogOpen] = useState(false);
  const [targetedProperty, setTargetedProperty] = useState(null);

  const objectsQuery = useQuery({
    queryKey: queryKeys.list('object'),
    queryFn: () => bigdeltaAPIClient.v1.objectsList({ workspace_id: currentWorkspaceId }),
  });

  const objectNameById = useMemo(() => {
    return new Map(objectsQuery.data?.objects?.map((o) => [o.id, o.singular_noun]));
  }, [objectsQuery.data?.objects]);

  const relationshipsQuery = useQuery({
    queryKey: queryKeys.relationships(),
    queryFn: () => bigdeltaAPIClient.v1.relationshipsList({ workspace_id: currentWorkspaceId }),
    enabled: !!objectsQuery.data,
  });

  const relationshipsByProperty = useMemo(() => {
    const result: Map<string, ObjectRelationship[]> = new Map();

    const workspaceRelationships = relationshipsQuery.data?.relationships?.filter(
      (r) => r.first_entity_workspace_id === r.second_entity_workspace_id
    );

    const outgoingObjectRelationships: ObjectRelationship[] =
      workspaceRelationships
        ?.filter(
          (r) =>
            r.first_entity_type === RelationshipEntityType.OBJECT &&
            r.first_entity_id === objectId &&
            r.second_entity_type === RelationshipEntityType.OBJECT
        )
        .map((r) => {
          return {
            id: r.id,
            property: r.first_entity_property,
            related_entity_id: r.second_entity_id,
            related_entity_name: capitalize(objectNameById.get(r.second_entity_id)),
            related_entity_property: r.second_entity_property,
          };
        }) || [];

    const incomingObjectRelationships: ObjectRelationship[] =
      workspaceRelationships
        ?.filter(
          (r) =>
            r.second_entity_type === RelationshipEntityType.OBJECT &&
            r.second_entity_id === objectId &&
            r.first_entity_type === RelationshipEntityType.OBJECT
        )
        .map((r) => {
          return {
            id: r.id,
            property: r.second_entity_property,
            related_entity_id: r.first_entity_id,
            related_entity_name: capitalize(objectNameById.get(r.first_entity_id)),
            related_entity_property: r.first_entity_property,
          };
        }) || [];

    [...outgoingObjectRelationships, ...incomingObjectRelationships].forEach((or) => {
      const propertyRelationships = result.get(or.property);
      propertyRelationships ? propertyRelationships.push(or) : result.set(or.property, [or]);
    });

    return result;
  }, [objectId, objectNameById, relationshipsQuery.data?.relationships]);

  const addRelationshipMutation = useMutation({
    mutationFn: (payload: RelationshipsCreatePayload) => bigdeltaAPIClient.v1.relationshipsCreate({ workspace_id: currentWorkspaceId }, payload),
    onSuccess: () => {
      toastSuccess('Relationship added', 'The object relationship has been added successfully');
    },
    onError: () => {
      toastError('Failed to add object relationship');
    },
    onSettled: () => {
      setIsAddRelationshipDialogOpen(false);
      return queryClient.invalidateQueries(queryKeys.relationships());
    },
  });

  const onAddRelationship = useCallback(
    (input: RelationshipFormSchema) => {
      const currentObjectName = objectNameById.get(objectId);
      const otherObjectName = objectNameById.get(input.entity_id);

      addRelationshipMutation.mutate({
        name:
          `${removeSpecialChars(currentObjectName)}_${removeSpecialChars(targetedProperty)}_` +
          `${removeSpecialChars(otherObjectName)}_${removeSpecialChars(input.entity_property)}`,
        display_name: `${capitalize(currentObjectName)}(${targetedProperty}) & ${capitalize(otherObjectName)}(${input.entity_property})`,
        first_entity_id: objectId,
        first_entity_type: RelationshipEntityType.OBJECT,
        first_entity_property: targetedProperty,
        second_entity_id: input.entity_id,
        second_entity_type: RelationshipEntityType.OBJECT,
        second_entity_property: input.entity_property,
      });
    },
    [addRelationshipMutation, objectId, objectNameById, targetedProperty]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        header: 'Name',
        maxSize: 75,
        cell: (props) => {
          let value;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          return value === '$met_remote_id' ? 'id' : value;
        },
      }),
      columnHelper.accessor('type', {
        id: 'type',
        header: 'Type',
        maxSize: 50,
        cell: (props) => {
          let value;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          return mapToUserFriendlyDataType(value);
        },
      }),
      columnHelper.display({
        id: 'flags',
        header: 'Property flags',
        maxSize: 50,
        cell: (context) => {
          return (
            <div className="flex flex-row gap-1">
              {context.row.id === '$met_remote_id' && (
                <span className="rounded-full bg-gray-200 px-2.5 py-0.5 text-xs font-medium text-gray-700">system</span>
              )}
            </div>
          );
        },
      }),
      columnHelper.display({
        id: 'constraints',
        header: 'Constraints',
        maxSize: 75,
        cell: (context) => {
          return (
            <div className="flex flex-row gap-1">
              {context.row.original.unique && (
                <span className="rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-500">unique</span>
              )}
              {context.row.original.required && (
                <span className="rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-500">required</span>
              )}
            </div>
          );
        },
      }),
      columnHelper.display({
        id: 'relationships',
        header: 'Relates to',
        cell: (context) => {
          return (
            <div className="flex flex-row flex-wrap gap-2">
              {relationshipsByProperty.get(context.row.id)?.map((or) => <RelationshipCell key={or.id} objectId={objectId} relationship={or} />)}
              {includes([ResourcePropertyType.STRING, ResourcePropertyType.STRING_ARRAY], context.row.original.type) && (
                <div className="flex gap-x-2">
                  <Button
                    intent="brand"
                    size="xs"
                    label="Add"
                    fullWidth
                    onClick={(event) => {
                      event.stopPropagation();
                      setTargetedProperty(context.row.id);
                      setIsAddRelationshipDialogOpen(true);
                    }}
                    className="max-w-fit rounded-full"
                    leadingIcon={PlusIcon}
                  />
                </div>
              )}
            </div>
          );
        },
      }),
    ],
    [objectId, relationshipsByProperty]
  );

  const table = useReactTable({
    columns,
    data: properties ?? [],
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.name,
  });

  return (
    <>
      <ToolsTable data={properties} table={table} />
      <Dialog open={isAddRelationshipDialogOpen} onClose={() => setIsAddRelationshipDialogOpen(false)} className="relative z-[999999]">
        <div className="fixed inset-0 bg-m-gray-700 opacity-95" aria-hidden="true" />
        <div className="fixed inset-0 flex w-screen items-center justify-center">
          <Dialog.Panel className="mx-auto flex w-3/5 max-w-lg flex-col gap-y-6 rounded-xl bg-white">
            <Dialog.Title className="p-6 pb-0 text-xl text-m-olive-700">Relationship</Dialog.Title>
            <RelationshipForm
              objectId={objectId}
              existingRelationships={relationshipsByProperty.get(targetedProperty)}
              onCancel={() => setIsAddRelationshipDialogOpen(false)}
              renderSubmitButton={(isValid, handleSubmit) => (
                <Button label="Add" intent="brand" size="sm" onClick={handleSubmit(onAddRelationship)} disabled={!isValid} />
              )}
            />
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

const removeSpecialChars = (value: string): string => {
  return value.replace(/[^A-Za-z0-9]/g, '');
};

import { useCallback, useEffect } from 'react';
import { FilterKey, useFilterStore } from '../../../shared/filters/store';
import { ReportSourceType, useReportStore } from '../store';
import { MetricResourceType } from '@bigdelta/lib-shared';
import { getMetricStateKey } from '../utils/getMetricStateKey.ts';
import { getMetrics } from '../utils/getMetrics.ts';

type Parameters = {
  enabled?: boolean;
  afterHydration?: boolean;
  filterKey: FilterKey;
  reportKey: string;
};

export const useWrapReportQueryInFormula = ({ filterKey, enabled, afterHydration, reportKey }: Parameters) => {
  const {
    reportType,
    isHydrated,
    isWrappedInFormula,
    markWrappedInFormula,
    hydrateReportQueryFromMetric,
    config,
    addFormulaNamedMetric,
    updateQuery,
  } = useReportStore(reportKey)((state) => state);
  const { getFilter } = useFilterStore((state) => state);

  const wrapInFormula = useCallback(() => {
    if (!enabled || (afterHydration && !isHydrated) || isWrappedInFormula) {
      return;
    }
    const { metricType } = config;

    const filter = getFilter(filterKey);
    if (!afterHydration || (afterHydration && metricType !== ReportSourceType.FORMULA)) {
      hydrateReportQueryFromMetric({
        metric_query: {
          [MetricResourceType.FORMULA]: {
            expression: '',
            metrics: [],
          },
        },
      });
      const queryMetricId = config[getMetricStateKey(metricType)];
      const metrics = getMetrics(reportType, metricType, queryMetricId, filter, config) ?? [];

      for (const metric of metrics) {
        addFormulaNamedMetric(metric);
      }

      updateQuery(filter.items);
    }

    markWrappedInFormula();
  }, [
    enabled,
    afterHydration,
    isHydrated,
    isWrappedInFormula,
    config,
    hydrateReportQueryFromMetric,
    getFilter,
    filterKey,
    updateQuery,
    markWrappedInFormula,
    addFormulaNamedMetric,
    reportType,
  ]);

  useEffect(() => {
    if (enabled) {
      wrapInFormula();
    }
  }, [wrapInFormula, enabled]);

  return null;
};

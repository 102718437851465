"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toInvitationVO = exports.InvitationRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.InvitationRequestDef = typebox_1.Type.Object({
    account_id: typebox_1.Type.String({ format: 'uuid' }),
    email: typebox_1.Type.String({ format: 'email' }),
});
const toInvitationVO = (invitationRequest) => {
    return {
        account_id: invitationRequest.account_id,
        email: invitationRequest.email,
    };
};
exports.toInvitationVO = toInvitationVO;

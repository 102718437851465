import { ObjectsProfileLayoutsDetailData } from '@bigdelta/lib-api-client';
import { FC } from 'react';
import { PanelType } from '@bigdelta/lib-shared';
import { RecordProfilePanelTable } from './RecordProfilePanelTable';
import { RecordProfilePanelChart } from './RecordProfilePanelChart';

interface RecordProfileTabProps {
  tab: ObjectsProfileLayoutsDetailData['tabs'][number];
}
export const RecordProfileTab: FC<RecordProfileTabProps> = ({ tab }) => {
  return tab.panels.map((panel) => {
    if (panel.type === PanelType.TABLE) {
      return <RecordProfilePanelTable key={panel.id} panel={panel} />;
    }
    if (panel.type === PanelType.CHART) {
      return <RecordProfilePanelChart key={panel.id} panel={panel} />;
    }
    return null;
  });
};

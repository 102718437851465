"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardDef = exports.DashboardPropertiesDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const timestampedModel_1 = require("./timestampedModel");
const typebox_2 = require("../typebox");
exports.DashboardPropertiesDef = typebox_1.Type.Object({
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    name: typebox_1.Type.String(),
    description: typebox_1.Type.Optional(typebox_1.Type.String()),
    is_default: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    owner: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    created_by: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    updated_by: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
});
exports.DashboardDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: typebox_1.Type.String({ format: 'uuid' }),
    }),
    exports.DashboardPropertiesDef,
    timestampedModel_1.TimestampedModelDef,
]);

import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';

import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { useNavigate, useParams } from 'react-router-dom';
import { IntegrationStatus } from '@bigdelta/lib-shared';
import { AppRoutes } from '../../../routes';
import { orderBy } from 'lodash';
import { CenteredLoader } from '../../../shared/components/CenteredLoader.tsx';
import React, { useEffect } from 'react';
import { AVAILABLE_INTEGRATIONS } from '../const.ts';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

export const IntegrationProfilePage = () => {
  const { currentWorkspaceId } = useWorkspace();
  const { integrationId } = useParams();
  const queryKeys = useQueryKeys();
  const navigate = useNavigate();

  const integrationQuery = useQuery({
    queryKey: queryKeys.integrations(),
    queryFn: () => bigdeltaAPIClient.v1.integrationsList({ workspace_id: currentWorkspaceId }),
    select: (integrations) => orderBy(integrations, 'created_at', 'desc')?.find((data) => data.id === integrationId),
  });

  const ProfileComponent = AVAILABLE_INTEGRATIONS.find((ai) => ai.type === integrationQuery.data?.type)?.profileComponent;

  useEffect(() => {
    if (
      integrationQuery.isError ||
      (integrationQuery.isSuccess && (!integrationQuery.data || integrationQuery.data.status !== IntegrationStatus.ENABLED || !ProfileComponent))
    ) {
      navigate(AppRoutes.INTEGRATIONS);
    }
  }, [integrationQuery, navigate]);

  return <>{integrationQuery.data?.id ? <ProfileComponent integrationId={integrationQuery.data.id} /> : <CenteredLoader />}</>;
};

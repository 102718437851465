"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toSlackIntegrationChannelResponse = exports.SlackIntegrationChannelResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.SlackIntegrationChannelResponseDef = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    name: typebox_1.Type.String(),
    is_private: typebox_1.Type.Boolean(),
});
const toSlackIntegrationChannelResponse = (channel) => {
    return {
        id: channel.id,
        name: channel.name,
        is_private: channel.is_private,
    };
};
exports.toSlackIntegrationChannelResponse = toSlackIntegrationChannelResponse;

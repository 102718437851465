"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetricGraphNodeDef = void 0;
const timestampedModel_1 = require("./timestampedModel");
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const value_object_1 = require("../value-object");
const member_1 = require("./member");
exports.MetricGraphNodeDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        workspace_id: typebox_1.Type.String({ format: 'uuid' }),
        graph_id: typebox_1.Type.String({ format: 'uuid' }),
        key: typebox_1.Type.String(),
        composition: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
            expression: typebox_1.Type.String(),
        })),
        type: typebox_1.Type.Enum(value_object_1.MetricGraphMetricDefinitionType),
        metric_id: typebox_1.Type.String({ format: 'uuid' }),
        created_by: (0, typebox_2.OptionalNullable)(member_1.MemberDef),
        updated_by: (0, typebox_2.OptionalNullable)(member_1.MemberDef),
    }),
    timestampedModel_1.TimestampedModelDef,
]);

import { FC } from 'react';
import { DebouncedInput } from './DebouncedInput';

interface PropertyValueTextFieldProps {
  onChange: (value: string) => void;
  value: string;
}

export const PropertyValueNumberField: FC<PropertyValueTextFieldProps> = ({ value, onChange }) => {
  return <DebouncedInput type="number" value={value} onChange={onChange} placeholder="Enter value..." />;
};

import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { PageHeading } from '../../../components/PageHeading';

import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { useEffect, useMemo, useState } from 'react';
import { getTimeFromRange } from '../../reports/utils/getTimeFromRange';
import { ReportChartTimeRange } from '../../reports/components/ReportChartTimeRange';
import { useNavigate, useParams } from 'react-router-dom';
import { tracking, TrackingEvent } from '../../../tracking';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { ChartGranularitySelect } from '../../reports/components/ChartGranularitySelect.tsx';
import { ChartType, QuickAccessLinkType, ReportTypeVO, TimeGranularity } from '@bigdelta/lib-shared';
import { merge, omit, orderBy } from 'lodash';
import { QueryReportsCreatePayload, ReportsDetailData } from '@bigdelta/lib-api-client';
import { Button } from '../../../shared/ui/Button/Button.tsx';
import { EmptyState } from '../../../shared/components/EmptyState.tsx';
import PlusIcon from '../../../assets/icons/plus-circle.svg?react';
import EditIcon from '../../../assets/icons/edit-02.svg?react';
import ReportsIcon from '../../../assets/icons/line-chart-up-01.svg?react';
import { AppRoutes } from '../../../routes';
import SearchIcon from '../../../assets/icons/search-md.svg?react';
import { CenteredLoader } from '../../../shared/components/CenteredLoader.tsx';
import { AddReportDialog } from '../components/AddReportDialog.tsx';
import { DashboardItem } from '../components/DashboardItem.tsx';
import { ReportStoreContext } from '../../reports/context/reportStoreContext.ts';
import { Container } from '../../../shared/ui/Container/Container';
import { Divider } from '../../../shared/ui/Divider/Divider';

import PresentationIcon from '../../../assets/icons/presentation-chart-01.svg?react';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

const EMPTY_STATE_STAGES = [
  {
    icon: PlusIcon,
    title: 'Add reports',
    description: 'Select one or more saved reports and add them to the dashboard.',
  },
  {
    icon: EditIcon,
    title: 'Edit dashboard',
    description: 'Add new or remove existing reports from dashboard any time.',
  },
  {
    icon: ReportsIcon,
    title: 'Create new reports',
    description: 'If you need more reports to be tracked, create them in the Report tab.',
  },
];

export const Dashboard = () => {
  const { currentWorkspaceId } = useWorkspace();
  const { dashboardId } = useParams();
  const queryKeys = useQueryKeys();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [timeRange, setTimeRange] = useState('7D');
  const [granularity, setGranularity] = useState(TimeGranularity.DAY);

  useEffect(() => {
    tracking.track(TrackingEvent.DashboardViewed, { 'dashboard id': dashboardId });
  }, [dashboardId]);

  const dashboardQuery = useQuery({
    queryKey: queryKeys.dashboard(dashboardId),
    queryFn: () => bigdeltaAPIClient.v1.dashboardsDetail(dashboardId || ''),
    select: (dashboard) => {
      return {
        ...dashboard,
        panels: orderBy(dashboard.panels, (i) => i, 'asc'),
      };
    },
  });

  const panelReportQueries = useQueries({
    queries: dashboardQuery?.data
      ? dashboardQuery?.data.panels.map((panel) => {
          return {
            queryKey: queryKeys.report(panel.configuration.report_id),
            queryFn: () => {
              return bigdeltaAPIClient.v1.reportsDetail(panel.configuration.report_id, { workspace_id: currentWorkspaceId });
            },
            select: (report: ReportsDetailData) => {
              return {
                panel: panel,
                report: report,
              };
            },
          };
        })
      : [],
  });

  const dashboardQueries = useQueries({
    queries: panelReportQueries.map((panelReportQuery) => ({
      queryKey: queryKeys.queryReport(panelReportQuery.data?.report.id, timeRange, granularity),
      queryFn: () => {
        const time = getTimeFromRange(timeRange);

        let query: QueryReportsCreatePayload = merge(panelReportQuery.data?.report.query, {
          display_options: {
            time_granularity: granularity,
          },
          time,
        });

        const isHorizontalPeriodChart = panelReportQuery.data?.report.query?.display_options?.chart_type === ChartType.HORIZONTAL;

        if (isHorizontalPeriodChart) {
          query = merge(query, {
            grouping: {
              filter: [{ data_created: { operator: 'in period' } }],
            },
          });

          query = omit(query, ['display_options']);
        }

        return bigdeltaAPIClient.v1.queryReportsCreate({ workspace_id: currentWorkspaceId }, query);
      },
      select: (queryResult) => {
        return {
          ...panelReportQuery.data,
          queryResult: queryResult,
        };
      },
      enabled: !!panelReportQuery.data?.report.id,
    })),
  });

  const deletePanelMutation = useMutation({
    mutationFn: (panelId: string) => bigdeltaAPIClient.v1.dashboardsPanelsDelete(dashboardId || '', panelId),
    onSuccess: () => {
      return queryClient.invalidateQueries(queryKeys.dashboard(dashboardId));
    },
  });

  const handleRemoveFromDashboard = (panelId: string) => {
    deletePanelMutation.mutate(panelId);
  };

  const handleViewReport = (report: ReportsDetailData) => {
    tracking.track(TrackingEvent.DashboardReportClicked, { 'report id': report.id, 'report name': report.title });
    navigate(`${AppRoutes.REPORTS}/${report.id}`);
  };

  const isEmpty = !dashboardQuery?.data?.panels || dashboardQuery?.data?.panels.length === 0;

  const [panelsSearchTerm, setPanelsSearchTerm] = useState('');

  const filteredDashboardItems = useMemo(() => {
    return dashboardQueries?.filter(
      (dashboardQuery) =>
        dashboardQuery.data?.report?.title && dashboardQuery.data.report.title.toLowerCase().includes(panelsSearchTerm.toLowerCase())
    );
  }, [dashboardQueries, panelsSearchTerm]);

  const [isAddReportDialogOpen, setIsAddReportDialogOpen] = useState(false);

  const handleAddReportDialogClose = () => {
    setIsAddReportDialogOpen(false);
  };

  return (
    <ReportStoreContext.Provider value={{ reportKey: 'default' }}>
      <>
        {!dashboardQuery.isInitialLoading && (
          <>
            <Container>
              <PageHeading
                breadcrumbs={[
                  {
                    label: 'Dashboards',
                    to: '/dashboards',
                    icon: PresentationIcon,
                  },
                  { label: dashboardQuery?.data?.name || '' },
                ]}
                favoriteLinkConfig={
                  dashboardQuery.data
                    ? {
                        type: QuickAccessLinkType.DASHBOARD,
                        label: dashboardQuery.data.name || '',
                        data: {
                          dashboard: {
                            dashboardId: dashboardQuery.data.id,
                          },
                        },
                      }
                    : undefined
                }
              />
            </Container>
            <Divider />
            <Container className="py-3">
              <div className="flex items-center gap-x-2">
                {!isEmpty && (
                  <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
                      <SearchIcon className="h-5 w-5 text-m-gray-600" />
                    </div>
                    <input
                      type="text"
                      id="simple-search"
                      className="block w-full rounded-md border border-m-gray-300 p-2 ps-10 text-md"
                      placeholder="Search reports"
                      value={panelsSearchTerm}
                      onChange={(e) => setPanelsSearchTerm(e.target.value)}
                    />
                  </div>
                )}
                <Button intent={isEmpty ? 'secondary' : 'brand'} size="lg" label="Add reports" onClick={() => setIsAddReportDialogOpen(true)} />
              </div>
            </Container>
            <Container className="py-3">
              <div className="flex flex-col gap-4">
                {!isEmpty ? (
                  <>
                    <div className="relative flex items-center justify-between">
                      <ReportChartTimeRange onChange={setTimeRange} value={timeRange} />
                      <div className="flex items-center">
                        <ChartGranularitySelect onChange={setGranularity} value={granularity} />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 overflow-y-auto">
                      {filteredDashboardItems.map(({ data }, index) => {
                        if (!data?.panel || !data.report) {
                          return null;
                        }

                        return (
                          <DashboardItem
                            reportType={data.report.type as ReportTypeVO}
                            key={data.panel.id}
                            index={index}
                            panelId={data.panel.id}
                            granularity={granularity}
                            queries={data?.queryResult.queries}
                            onViewReport={handleViewReport}
                            onRemoveFromDashboard={handleRemoveFromDashboard}
                            report={data.report}
                          />
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <EmptyState heading="Add reports to the dashboard" stages={EMPTY_STATE_STAGES}>
                    <Button intent="brand" size="xl" label="Add reports" onClick={() => setIsAddReportDialogOpen(true)} />
                  </EmptyState>
                )}
              </div>
            </Container>
          </>
        )}
        {dashboardQuery.isInitialLoading && <CenteredLoader />}
        <AddReportDialog isOpen={isAddReportDialogOpen} onClose={handleAddReportDialogClose} dashboard={dashboardQuery?.data} />
      </>
    </ReportStoreContext.Provider>
  );
};

import React, { useCallback, useEffect, useMemo } from 'react';
import { useWorkspace } from '../../auth/hooks/useWorkspace.tsx';
import { ReportsState, useReportStore } from '../store';
import { useObjectsQuery } from '../../../shared/data/useObjectsQuery.ts';
import { ReportBuilderProps, ReportQueryBuilder } from '../components/ReportQueryBuilder.tsx';
import { ReportChartViewProps } from '../components/ReportChartView.tsx';
import { useHydrateReportStoreFromMetric } from './useHydrateReportStoreFromMetric.ts';
import { FilterKey, getFilter, useFilterStore } from '../../../shared/filters/store';
import { useHydrateReportQueryStoreFromReport } from './useHydrateReportQueryStoreFromReport.ts';
import { ReportStoreContext } from '../context/reportStoreContext.ts';
import { useHydrateReportStoreFromFormulaMetric } from './useHydrateReportStoreFromFormulaMetric.ts';
import { ChartType, FormulaNamedMetricVO, ReportTypeVO } from '@bigdelta/lib-shared';
import { isEmpty, isEqual } from 'lodash';
import { validateFilterItem } from '../../../shared/utils/validateFilterItem.ts';
import { getMetrics } from '../utils/getMetrics.ts';
import { getMetricStateKey } from '../utils/getMetricStateKey.ts';
import { useWrapReportQueryInFormula } from './useWrapReportQueryInFormula.ts';
import usePrevious from '../../tree/hooks/usePrevious.ts';
import { ReportFunnelBuilder } from '../components/ReportFunnelBuilder.tsx';
import { ReportTemplateBuilder } from '../components/ReportTemplateBuilder.tsx';

export type ReportBuilderParameters = {
  metricId?: string | null;
  reportId?: string | null;
  reportKey?: string | null;
  filterKey?: FilterKey | null;
  hydrateReportStoreFromMetric?: boolean;
  hydrateReportStoreFromReport?: boolean;
  hydrateReportStoreFromFormulaMetric?: boolean;
  formulaNamedMetric?: FormulaNamedMetricVO;
  inline?: boolean;
  chartType: ChartType;
  autoUpdateQueryConfig?: boolean;
  wrapInFormula?: boolean;
  breakdownDisabled?: boolean;
  chartViewProps?: Partial<ReportChartViewProps>;
  reportBuilderProps?: Partial<ReportBuilderProps>;
  onMetricQueryChange?: (metricQuery: any, state: ReportsState) => void;
};

export type ReportQueryBuilderState = Partial<ReturnType<typeof useReportQueryBuilder>>;

export const useReportQueryBuilder = (parameters: ReportBuilderParameters) => {
  const {
    metricId,
    reportId,
    hydrateReportStoreFromMetric,
    hydrateReportStoreFromReport,
    hydrateReportStoreFromFormulaMetric,
    inline = false,
    autoUpdateQueryConfig,
    reportBuilderProps = {},
    formulaNamedMetric,
    onMetricQueryChange,
    wrapInFormula,
  } = parameters;
  const reportKey = useMemo(() => parameters.reportKey || metricId || reportId || 'default', [metricId, parameters.reportKey, reportId]);
  const filterKey = useMemo(() => parameters.filterKey || [reportKey], [parameters.filterKey, reportKey]);
  const { isFormulaMetricChild } = reportBuilderProps;
  const reportStore = useReportStore(reportKey, {
    topLevelReportQuery: !isFormulaMetricChild,
    formulaMetricChildQuery: isFormulaMetricChild,
  });
  const state = reportStore((state) => state);
  const { reportType, updateQuery, config: reportConfigState, query: reportQueryState, isHydrated, isWrappedInFormula } = state;
  const filter = useFilterStore((state) => getFilter(state, filterKey));
  const shouldBeHydrated = useMemo(
    () => hydrateReportStoreFromMetric || hydrateReportStoreFromReport || hydrateReportStoreFromFormulaMetric,
    [hydrateReportStoreFromFormulaMetric, hydrateReportStoreFromMetric, hydrateReportStoreFromReport]
  );
  const ready = useMemo(() => {
    return (!wrapInFormula || isWrappedInFormula) && (!shouldBeHydrated || isHydrated);
  }, [isHydrated, isWrappedInFormula, shouldBeHydrated, wrapInFormula]);

  const previousFilter = usePrevious(filter);
  const previousReportConfigState = usePrevious(reportConfigState);

  const notifyMetricQueryChange = useCallback(
    (filter, state: ReportsState) => {
      if (ready && onMetricQueryChange) {
        const { metricType } = state.config;
        const queryMetricId = state.config[getMetricStateKey(metricType)];
        const [metricQuery] = getMetrics(reportType, metricType, queryMetricId, filter, state.config) ?? [];

        onMetricQueryChange(metricQuery, state);
      }
    },
    [onMetricQueryChange, ready, reportType]
  );

  useEffect(() => {
    if ((!isEmpty(filter) || !isEmpty(previousFilter)) && !isEqual(filter, previousFilter)) {
      notifyMetricQueryChange(filter.items, state);
    }
  }, [filter, notifyMetricQueryChange, previousFilter, state]);

  useEffect(() => {
    if (!isEqual(reportConfigState, previousReportConfigState)) {
      notifyMetricQueryChange(filter.items, state);
    }
  }, [filter, notifyMetricQueryChange, previousFilter, previousReportConfigState, reportConfigState, state]);

  useHydrateReportStoreFromMetric({ metricId, filterKey, enabled: hydrateReportStoreFromMetric, reportKey });
  useHydrateReportQueryStoreFromReport({ reportId, filterKey, enabled: hydrateReportStoreFromReport, reportKey });
  useHydrateReportStoreFromFormulaMetric({
    enabled: hydrateReportStoreFromFormulaMetric,
    filterKey,
    formulaNamedMetric,
    reportKey,
  });
  useWrapReportQueryInFormula({
    reportKey,
    filterKey,
    enabled: wrapInFormula,
    afterHydration: shouldBeHydrated,
  });

  const { currentWorkspaceId } = useWorkspace();
  const objectsQuery = useObjectsQuery({ workspaceId: currentWorkspaceId });
  const getObjectWorkspaceId = useCallback(
    (metricObject: string | undefined) => {
      if (reportConfigState.metricType === 'records' && metricObject) {
        return objectsQuery?.data?.objects.find((object) => object.id === metricObject)?.workspace_id;
      }

      return currentWorkspaceId;
    },
    [currentWorkspaceId, objectsQuery?.data?.objects, reportConfigState.metricType]
  );
  const workspaceId = getObjectWorkspaceId(reportConfigState.metricObject);
  const hasConfigChangedFromQuery = useMemo(() => {
    const configKeys = Object.keys(reportConfigState);

    const queryConfigObj = configKeys.reduce((acc, key) => {
      acc[key] = reportQueryState[key];
      return acc;
    }, {});

    return !isEqual(reportConfigState, queryConfigObj);
  }, [reportConfigState, reportQueryState]);
  const hasFilterChangedFromQuery = useMemo(() => {
    return !isEqual(reportQueryState.filter?.filter(validateFilterItem), filter?.items.filter(validateFilterItem));
  }, [filter, reportQueryState.filter]);
  const handleUpdateQuery = useCallback(() => {
    updateQuery(filter.items);
  }, [filter, updateQuery]);

  useEffect(() => {
    if (autoUpdateQueryConfig && (hasFilterChangedFromQuery || hasConfigChangedFromQuery)) {
      handleUpdateQuery();
    }
  }, [autoUpdateQueryConfig, handleUpdateQuery, hasConfigChangedFromQuery, hasFilterChangedFromQuery]);

  const builder = (
    <ReportStoreContext.Provider value={{ reportKey }}>
      {reportType === ReportTypeVO.CHART && (
        <ReportQueryBuilder
          workspaceId={workspaceId}
          reportId={reportId}
          inline={inline}
          filterKey={filterKey}
          disableRemoval={wrapInFormula}
          {...reportBuilderProps}
        />
      )}
      {reportType === ReportTypeVO.FUNNEL && <ReportFunnelBuilder />}
      {reportType === ReportTypeVO.TEMPLATE && <ReportTemplateBuilder filterKey={filterKey} />}
    </ReportStoreContext.Provider>
  );

  return {
    builder,
    filterKey,
    reportKey,
    workspaceId,
    hasConfigChangedFromQuery,
    hasFilterChangedFromQuery,
    ready,
    updateQuery: handleUpdateQuery,
  };
};

import { FC } from 'react';
import { AuthScreenTitle } from './AuthScreenTitle';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from '../../../shared/ui/Button/Button';

interface ForgotPasswordSuccessProps {
  email: string;
}

export const ForgotPasswordSuccess: FC<ForgotPasswordSuccessProps> = ({ email }) => (
  <>
    <AuthScreenTitle
      title="Check Your Email"
      subtitle={`An email with password reset instructions has been sent to ${email}. Follow the steps in the email to reset your password.`}
    />
    <RouterLink to="https://bigdelta.com">
      <Button label="Bigdelta Home" className="w-full" size="lg" type="submit" />
    </RouterLink>
  </>
);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateDocumentRelationshipVODef = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_1 = require("../model");
exports.CreateDocumentRelationshipVODef = typebox_1.Type.Object({
    id: typebox_1.Type.Optional(typebox_1.Type.String()),
    accountId: typebox_1.Type.String(),
    workspaceId: typebox_1.Type.String(),
    firstDocumentType: typebox_1.Type.Enum(model_1.DocumentRelationshipDocumentType),
    firstDocumentWorkspaceId: typebox_1.Type.String({ format: 'uuid' }),
    firstDocumentWorkspaceObjectId: typebox_1.Type.Optional(typebox_1.Type.String()),
    firstDocumentId: typebox_1.Type.String(),
    secondDocumentType: typebox_1.Type.Enum(model_1.DocumentRelationshipDocumentType),
    secondDocumentWorkspaceId: typebox_1.Type.String({ format: 'uuid' }),
    secondDocumentWorkspaceObjectId: typebox_1.Type.Optional(typebox_1.Type.String()),
    secondDocumentId: typebox_1.Type.String(),
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toCreateTrackedEvent = exports.PrivateCreateTrackedEventRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_1 = require("../model");
const typebox_2 = require("../typebox");
exports.PrivateCreateTrackedEventRequestDef = typebox_1.Type.Object({
    account_id: typebox_1.Type.String(),
    workspace_id: typebox_1.Type.String(),
    email: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    event_name: typebox_1.Type.String(),
    properties: (0, typebox_2.OptionalNullable)(typebox_2.NestedObjectDef),
    relations: (0, typebox_2.OptionalNullable)(model_1.EventRelationsDef),
    created_at: (0, typebox_2.OptionalNullable)(typebox_2.DateType),
    track_ip_and_geolocation: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
    ip: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
});
const toCreateTrackedEvent = (request) => {
    return {
        workspaceId: request.workspace_id,
        accountId: request.account_id,
        email: request.email,
        eventName: request.event_name,
        properties: request.properties,
        relations: request.relations,
        createdAt: request.created_at,
        ip: request.ip,
        trackIpAndGeolocation: request.track_ip_and_geolocation,
    };
};
exports.toCreateTrackedEvent = toCreateTrackedEvent;

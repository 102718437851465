import { ColumnActionType } from '../../../../../shared/tables/components/DataTableColumnActions';

export const getColumnActions = (index: number, columnsCount: number, isLabel: boolean): ColumnActionType[] => {
  const actions: ColumnActionType[] = [];

  if (!isLabel) {
    actions.push(ColumnActionType.ADD_LEFT, ColumnActionType.ADD_RIGHT, ColumnActionType.HIDE, ColumnActionType.SORT);
  } else {
    actions.push(ColumnActionType.SORT, ColumnActionType.ADD_RIGHT);
  }

  if (index > 0) {
    actions.push(ColumnActionType.MOVE_LEFT);
  }
  if (index < columnsCount - 1) {
    actions.push(ColumnActionType.MOVE_RIGHT);
  }

  return actions;
};

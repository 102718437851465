import { RecordQueryFiltersRequestDef } from '@bigdelta/lib-api-client';
import { RelationshipObjectData } from '../types';

export const getRelatedRecordConditions = (
  relationships: RelationshipObjectData[],
  queryRecordPropertyObject: NonNullable<Exclude<RecordQueryFiltersRequestDef['conditions'][number], RecordQueryFiltersRequestDef>>['record_property']
): RecordQueryFiltersRequestDef['conditions'] => {
  if (!relationships.length) {
    return [{ record_property: queryRecordPropertyObject }];
  }

  return [
    {
      related_records: {
        relationship_name: relationships[0]?.relationshipName ?? '',
        filter: {
          operator: 'and',
          conditions: getRelatedRecordConditions(relationships.slice(1), queryRecordPropertyObject),
        },
      },
    },
  ] as RecordQueryFiltersRequestDef['conditions'];
};

import { PropertyNameObject } from '../../../shared/types.ts';
import { BreakdownConfig } from '../store';
import { ReportsCreatePayload } from '@bigdelta/lib-api-client';

export type GroupingConfig = ReportsCreatePayload['grouping_config'];

export const getGroupingConfig = (breakdownConfig: BreakdownConfig, breakdownProperty: PropertyNameObject | null): GroupingConfig | null => {
  if (!breakdownConfig || !breakdownProperty) {
    return null;
  }

  return {
    groups: breakdownProperty
      ? [
          {
            attribute_id: breakdownProperty.property_id,
            attribute_name: breakdownProperty.property_name,
          },
        ]
      : [],
    selected_groups: (breakdownConfig.breakdownSelectedValues || []).map((item) => ({
      group_attributes: [
        {
          attribute_id: breakdownProperty.property_id,
          attribute_name: breakdownProperty.property_name,
          attribute_value: item.property_value,
        },
      ],
    })),
  };
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutomationDef = exports.AutomationBaseDef = exports.AutomationConfigurationDef = exports.AutomationWorkflowBlockDef = exports.DeleteRecordWorkflowBlockDef = exports.MutateRecordWorkflowBlockDef = exports.SetRecordPropertyDef = exports.AutomationValueExtractorDef = exports.DelayTimeUnitStringType = exports.AutomationValueExtractorStringType = exports.ConditionCheckTypeStringType = exports.AutomationWorkflowBlockTypeStringType = exports.AutomationTriggerTypeStringType = exports.DelayTimeUnit = exports.AutomationValueExtractorType = exports.ConditionCheckType = exports.AutomationWorkflowBlockType = exports.AutomationTriggerType = void 0;
const typebox_1 = require("@sinclair/typebox");
const timestampedModel_1 = require("./timestampedModel");
const typebox_2 = require("../typebox");
const recordsDataQueryVO_1 = require("../value-object/recordsDataQueryVO");
const eventsDataQueryVO_1 = require("../value-object/eventsDataQueryVO");
var AutomationTriggerType;
(function (AutomationTriggerType) {
    AutomationTriggerType["RECORD_CREATED"] = "RECORD_CREATED";
    AutomationTriggerType["RECORD_UPDATED"] = "RECORD_UPDATED";
    AutomationTriggerType["RECORD_DELETED"] = "RECORD_DELETED";
    AutomationTriggerType["EVENT_OCCURRED"] = "EVENT_OCCURRED";
})(AutomationTriggerType || (exports.AutomationTriggerType = AutomationTriggerType = {}));
var AutomationWorkflowBlockType;
(function (AutomationWorkflowBlockType) {
    AutomationWorkflowBlockType["SEND_SLACK_MESSAGE"] = "SEND_SLACK_MESSAGE";
    AutomationWorkflowBlockType["FILTER_EVENT"] = "FILTER_EVENT";
    AutomationWorkflowBlockType["FILTER_RECORD"] = "FILTER_RECORD";
    AutomationWorkflowBlockType["CHECK_CONDITION"] = "CHECK_CONDITION";
    AutomationWorkflowBlockType["DELAY"] = "DELAY";
    AutomationWorkflowBlockType["DELAY_UNTIL"] = "DELAY_UNTIL";
    AutomationWorkflowBlockType["CREATE_OR_UPDATE_RECORD"] = "CREATE_OR_UPDATE_RECORD";
    AutomationWorkflowBlockType["CREATE_RECORD"] = "CREATE_RECORD";
    AutomationWorkflowBlockType["UPDATE_RECORD"] = "UPDATE_RECORD";
    AutomationWorkflowBlockType["DELETE_RECORD"] = "DELETE_RECORD";
})(AutomationWorkflowBlockType || (exports.AutomationWorkflowBlockType = AutomationWorkflowBlockType = {}));
var ConditionCheckType;
(function (ConditionCheckType) {
    ConditionCheckType["EVENT_FILTER"] = "EVENT_FILTER";
    ConditionCheckType["RECORD_FILTER"] = "RECORD_FILTER";
})(ConditionCheckType || (exports.ConditionCheckType = ConditionCheckType = {}));
var AutomationValueExtractorType;
(function (AutomationValueExtractorType) {
    AutomationValueExtractorType["PLAIN_VALUE"] = "PLAIN_VALUE";
    AutomationValueExtractorType["TRIGGER_DATA_PROPERTY_VALUE"] = "TRIGGER_DATA_PROPERTY_VALUE";
    AutomationValueExtractorType["TRACKED_EVENT_RELATION_VALUE"] = "TRACKED_EVENT_RELATION_VALUE";
})(AutomationValueExtractorType || (exports.AutomationValueExtractorType = AutomationValueExtractorType = {}));
var DelayTimeUnit;
(function (DelayTimeUnit) {
    DelayTimeUnit["MINUTE"] = "minute";
    DelayTimeUnit["HOUR"] = "hour";
    DelayTimeUnit["DAY"] = "day";
    DelayTimeUnit["WEEK"] = "week";
    DelayTimeUnit["MONTH"] = "month";
    DelayTimeUnit["QUARTER"] = "quarter";
    DelayTimeUnit["YEAR"] = "year";
})(DelayTimeUnit || (exports.DelayTimeUnit = DelayTimeUnit = {}));
exports.AutomationTriggerTypeStringType = (0, typebox_2.EnumStrings)(typebox_1.Type.Enum(AutomationTriggerType));
exports.AutomationWorkflowBlockTypeStringType = (0, typebox_2.EnumStrings)(typebox_1.Type.Enum(AutomationWorkflowBlockType));
exports.ConditionCheckTypeStringType = (0, typebox_2.EnumStrings)(typebox_1.Type.Enum(ConditionCheckType));
exports.AutomationValueExtractorStringType = (0, typebox_2.EnumStrings)(typebox_1.Type.Enum(AutomationValueExtractorType));
exports.DelayTimeUnitStringType = (0, typebox_2.EnumStrings)(typebox_1.Type.Enum(DelayTimeUnit));
exports.AutomationValueExtractorDef = typebox_1.Type.Object({
    type: exports.AutomationValueExtractorStringType,
    value: typebox_2.PrimitiveTypeDef,
});
exports.SetRecordPropertyDef = typebox_1.Type.Object({
    property_name_extractor: exports.AutomationValueExtractorDef,
    value_extractor: exports.AutomationValueExtractorDef,
});
const MutateRecordWorkflowBlockDef = (blockDef) => typebox_1.Type.Object({
    workspace_object_slug_extractor: exports.AutomationValueExtractorDef,
    record_id_extractor: exports.AutomationValueExtractorDef,
    properties: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(exports.SetRecordPropertyDef)),
    properties_once: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(exports.SetRecordPropertyDef)),
    block: (0, typebox_2.OptionalNullable)(blockDef),
});
exports.MutateRecordWorkflowBlockDef = MutateRecordWorkflowBlockDef;
const DeleteRecordWorkflowBlockDef = (blockDef) => typebox_1.Type.Object({
    workspace_object_slug_extractor: exports.AutomationValueExtractorDef,
    record_id_extractor: exports.AutomationValueExtractorDef,
    block: (0, typebox_2.OptionalNullable)(blockDef),
});
exports.DeleteRecordWorkflowBlockDef = DeleteRecordWorkflowBlockDef;
exports.AutomationWorkflowBlockDef = typebox_1.Type.Recursive((Self) => typebox_1.Type.Object({
    id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    type: exports.AutomationWorkflowBlockTypeStringType,
    send_slack_message: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
        integration_id: typebox_1.Type.Union([typebox_1.Type.String({ format: 'uuid' }), typebox_1.Type.Literal('')]),
        channel_id: typebox_1.Type.String(),
        message_template: typebox_1.Type.String(),
        block: (0, typebox_2.OptionalNullable)(Self),
    })),
    filter_event: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
        filter: typebox_1.Type.Ref(eventsDataQueryVO_1.EventsQueryRequestQueryDef),
        block: (0, typebox_2.OptionalNullable)(Self),
    })),
    filter_record: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
        filter: typebox_1.Type.Ref(recordsDataQueryVO_1.RecordQueryFiltersRequestDef),
        block: (0, typebox_2.OptionalNullable)(Self),
    })),
    check_condition: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
        type: exports.ConditionCheckTypeStringType,
        event_filter: (0, typebox_2.OptionalNullable)(typebox_1.Type.Ref(eventsDataQueryVO_1.EventsQueryRequestQueryDef)),
        record_filter: (0, typebox_2.OptionalNullable)(typebox_1.Type.Ref(recordsDataQueryVO_1.RecordQueryFiltersRequestDef)),
        true_block: (0, typebox_2.OptionalNullable)(Self),
        false_block: (0, typebox_2.OptionalNullable)(Self),
    })),
    delay: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
        unit: exports.DelayTimeUnitStringType,
        value: typebox_1.Type.Number({ minimum: 1 }),
        block: (0, typebox_2.OptionalNullable)(Self),
    })),
    delay_until: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
        datetime: typebox_1.Type.String({ format: 'date-time' }),
        block: (0, typebox_2.OptionalNullable)(Self),
    })),
    create_or_update_record: (0, typebox_2.OptionalNullable)((0, exports.MutateRecordWorkflowBlockDef)(Self)),
    create_record: (0, typebox_2.OptionalNullable)((0, exports.MutateRecordWorkflowBlockDef)(Self)),
    update_record: (0, typebox_2.OptionalNullable)((0, exports.MutateRecordWorkflowBlockDef)(Self)),
    delete_record: (0, typebox_2.OptionalNullable)((0, exports.DeleteRecordWorkflowBlockDef)(Self)),
}), {
    $id: 'AutomationWorkflowBlockDef',
});
exports.AutomationConfigurationDef = typebox_1.Type.Object({
    trigger: typebox_1.Type.Object({
        type: exports.AutomationTriggerTypeStringType,
        workspace_object_id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        workspace_object_property: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
        event_name: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
        block: exports.AutomationWorkflowBlockDef,
    }),
});
exports.AutomationBaseDef = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    name: typebox_1.Type.String(),
    version: typebox_1.Type.Number(),
    configuration: exports.AutomationConfigurationDef,
    enabled: typebox_1.Type.Boolean(),
    total_run_count: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
    failed_run_count: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
    in_progress_run_count: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
    completed_run_count: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
    processed_block_count: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
    created_by: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    updated_by: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    updated_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    deleted_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
});
const MutateRecordWorkflowBlock = (0, exports.MutateRecordWorkflowBlockDef)(exports.AutomationWorkflowBlockDef);
exports.AutomationDef = typebox_1.Type.Composite([exports.AutomationBaseDef, timestampedModel_1.TimestampedModelDef]);

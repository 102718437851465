import { Link } from "react-router-dom";
import { AppRoutes } from "../../routes";
import { Button } from "../ui/Button/Button";
import { DataTableEmptyProps } from "./components/DataTableEmpty";

export const NO_EVENTS_TABLE_EMPTY: DataTableEmptyProps = {
  heading: 'Add tracking script',
  description: `Looks like Bigdelta isn't collecting activity on your site and product yet. 
    Install tracking script to see the customers on your site and product right now.`,
  actionSlot: (
    <Link to={AppRoutes.ACCESS_KEYS}>
      <Button intent="primary" label="Add tracking to your website" size="sm" />
    </Link>
  ),
}
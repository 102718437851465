import { Outlet, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';
import { UserContext } from '../context/userContext.tsx';
import { AppRoutes } from '../../../routes';

export const ProtectedRoute: React.FC = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  useEffect(() => {
    if (userContext.ready) {
      if (!userContext.authenticated) {
        navigate(AppRoutes.LOGIN);
      } else if (userContext.authenticated && userContext.user) {
        if (!userContext.user.is_onboarding_form_filled) {
          navigate(AppRoutes.MEMBERS_ONBOARDING);
        }
      }
    }
  }, [navigate, userContext.authenticated, userContext.ready, userContext.user]);

  if (!userContext.ready || !userContext.authenticated) {
    return null;
  }

  return <Outlet />;
};

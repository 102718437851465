import { RelationshipsListData } from '@bigdelta/lib-api-client';
import { RelationshipEntityType } from '@bigdelta/lib-shared';

interface GetObjectRelationshipToEventArgs {
  relationships: RelationshipsListData['relationships'];
  objectId: string;
}

export const getObjectRelationshipToEvent = ({ relationships, objectId }: GetObjectRelationshipToEventArgs) => {
  return relationships.find((relationship) => {
    const isRecordObjectFirstEntity = objectId === relationship.first_entity_id && relationship.first_entity_type === RelationshipEntityType.OBJECT;
    const isRecordObjectSecondEntity =
      objectId === relationship.second_entity_id && relationship.second_entity_type === RelationshipEntityType.OBJECT;
    const isEventFirstEntity = relationship.first_entity_type === RelationshipEntityType.EVENT;
    const isEventSecondEntity = relationship.second_entity_type === RelationshipEntityType.EVENT;

    return (isRecordObjectFirstEntity && isEventSecondEntity) || (isRecordObjectSecondEntity && isEventFirstEntity);
  });
};

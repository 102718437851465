"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanModelVODef = void 0;
const typebox_1 = require("@sinclair/typebox");
const planModel_1 = require("../model/planModel");
const typebox_2 = require("../typebox");
exports.PlanModelVODef = typebox_1.Type.Composite([
    planModel_1.PlanModelDef,
    typebox_1.Type.Object({
        calculated_values: (0, typebox_2.OptionalNullable)(planModel_1.PlanModelCalculatedValuesDef),
    }),
]);

import { useReportStoreContext } from '../../../context/reportStoreContext';
import { useReportStore } from '../../../store';
import { ReportCreateFilterEvents } from '../../ReportCreateFilterEvents';
import { FilterKey } from '../../../../../shared/filters/store';
import { FC } from 'react';
import { EVENT_NAME, TEMPLATE_TYPE } from './const';
import { EventsBuilder } from '../../EventsBuilder';

interface BuilderProps {
  filterKey: FilterKey;
}

export const Builder: FC<BuilderProps> = ({ filterKey }) => {
  const { reportKey } = useReportStoreContext();
  const { config, setTemplate } = useReportStore(reportKey)((state) => state);

  const handleAddEvent = () => {
    const currentEvents = config.template[TEMPLATE_TYPE]?.events ?? [];
    setTemplate({
      [TEMPLATE_TYPE]: {
        events: [...currentEvents, null],
      },
    });
  };

  const handleRemoveEvent = (idx: number) => {
    const currentEvents = config.template[TEMPLATE_TYPE]?.events ?? [];
    setTemplate({
      [TEMPLATE_TYPE]: {
        events: currentEvents.filter((_, eventIdx) => eventIdx !== idx),
      },
    });
  };

  const handleSelectEvent = (
    value: {
      event_name: string;
    } | null,
    idx: number
  ) => {
    const currentEvents = config.template[TEMPLATE_TYPE]?.events ?? [];
    setTemplate({
      [TEMPLATE_TYPE]: {
        events: currentEvents.map((item, eventIdx) => {
          if (eventIdx !== idx) {
            return item;
          }

          return value ? { event_name: { name: value?.event_name } } : null;
        }),
      },
    });
  };

  const templateEvents = config.template[TEMPLATE_TYPE]?.events ?? [];

  return (
    <div className="flex flex-col gap-y-4 rounded-lg text-sm">
      <div className="flex flex-col gap-y-2">
        <EventsBuilder
          title={EVENT_NAME}
          allowMultipleEvents={true}
          events={templateEvents}
          onAddEvent={handleAddEvent}
          onRemoveEvent={handleRemoveEvent}
          onSelectEvent={handleSelectEvent}
        />
        <ReportCreateFilterEvents filterKey={filterKey} />
      </div>
    </div>
  );
};

import { FC, useEffect } from 'react';
import { useFilterContext } from '../../context/useFilterContext';
import { FilterItem, useFilterStore } from '../../store';
import { EventSelect } from '../common/EventSelect';
import { EventOperatorSelect } from '../EventOperatorSelect';
import { EventValue } from '../EventValue';
import { EventTimeframeOperatorSelect } from '../common/EventTimeframeOperatorSelect';
import { EventTimeframeInTheLast } from '../common/EventTimeframeInTheLast';
import { EventTimeframeBefore } from '../common/EventTimeframeBefore';
import { EventTimeframeAfter } from '../common/EventTimeframeAfter';
import { EventTimeframeBetween } from '../common/EventTimeframeBetween';
import { RowContainer } from '../common/RowContainer';
import { useRelationshipsQuery } from '../../../data/useRelationshipsQuery';
import { getObjectRelationshipToEvent } from '../../../utils/getObjectRelationshipToEvent';
import { useObjectQuery } from '../../../data/useObjectQuery';
import { Group } from '../common/Group';
import { Control } from '../common/Control';
import { useWorkspace } from '../../../../features/auth/hooks/useWorkspace';

interface RecordsEventNameFilterRowProps {
  index: number;
  filter: FilterItem;
  objectSlug: string;
}

export const RecordsEventNameFilterRow: FC<RecordsEventNameFilterRowProps> = ({ index, filter, objectSlug }) => {
  const { currentWorkspaceId } = useWorkspace();
  const { filterKey } = useFilterContext();

  const removeItem = useFilterStore((state) => state.removeItem);
  const setPropertyRelationships = useFilterStore((state) => state.setPropertyRelationships);

  const { data: relationshipData } = useRelationshipsQuery({ workspaceId: currentWorkspaceId });
  const { data: object } = useObjectQuery({ objectSlug, workspaceId: currentWorkspaceId });

  const eventRel =
    object?.id && relationshipData?.relationships
      ? getObjectRelationshipToEvent({ relationships: relationshipData.relationships, objectId: object.id })
      : undefined;

  // TODO: move this to on filter add, fix empty strings for object-event relationships
  useEffect(() => {
    if (!filter.propertyRelationships.length && eventRel?.name) {
      setPropertyRelationships(filterKey, index, [
        {
          objectId: '',
          relationshipName: eventRel.name,
          objectWorkspaceId: '',
        },
      ]);
    }
  }, [eventRel?.name, filter, filterKey, index, setPropertyRelationships]);

  return (
    <RowContainer onRemove={() => removeItem(filterKey, index)}>
      <Group>
        <Control>
          <EventSelect index={index} filterDataItem={filter} />
        </Control>
      </Group>
      {filter.event && (
        <Group>
          <Control shouldShrink={false}>
            <EventOperatorSelect index={index} />
          </Control>
          <Control>
            <EventValue index={index} />
          </Control>
        </Group>
      )}
      {filter.eventOperator && (
        <Group>
          <Control>
            <EventTimeframeOperatorSelect index={index} />
          </Control>
        </Group>
      )}
      <Group>
        <EventTimeframeInTheLast index={index} />
        <EventTimeframeBefore index={index} />
        <EventTimeframeAfter index={index} />
        <EventTimeframeBetween index={index} />
      </Group>
    </RowContainer>
  );
};

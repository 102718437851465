import React, { FC, HTMLAttributes, useEffect } from 'react';
import { FormInput } from '../../../shared/ui/FormInput/FormInput.tsx';
import { useForm } from 'react-hook-form';
import { FormTextArea } from '../../../shared/ui/TextArea/FormTextArea.tsx';
import { twMerge } from 'tailwind-merge';
import { ButtonComponentProps } from '../../../shared/ui/Button/Button.tsx';
import { DashboardFormSchema } from './DashboardFormSchema.ts';
import { Select } from '../../../shared/ui/Select/Select.tsx';
import { FormElementWrapper } from '../../../shared/ui/FormElementWrapper/FormElementWrapper.tsx';
import { useQuery } from '@tanstack/react-query';

import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { useWorkspace } from '../../auth/hooks/useWorkspace.tsx';
import { CenteredLoader } from '../../../shared/components/CenteredLoader.tsx';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

interface Props extends HTMLAttributes<HTMLElement> {
  defaultValues?: DashboardFormSchema;
  primaryAction?: ButtonComponentProps;
  renderSubmitButton?: (handleSubmit: (handler: (data: DashboardFormSchema) => any) => any) => React.ReactNode;
}

export const DashboardProfileForm: FC<Props> = ({ className, defaultValues, renderSubmitButton }) => {
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<DashboardFormSchema>({
    defaultValues,
  });

  const queryKeys = useQueryKeys();
  const { currentWorkspaceId } = useWorkspace();

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const membersQuery = useQuery({
    queryKey: queryKeys.members(),
    queryFn: () => bigdeltaAPIClient.v1.membersList({ workspace_id: currentWorkspaceId }),
  });

  return (
    <>
      {!membersQuery.isInitialLoading && (
        <div className={twMerge('relative flex flex-col gap-4 p-6', className)}>
          <FormInput
            label="Dashboard name"
            inputProps={{
              ...register('name', { required: true }),
              placeholder: 'Dashboard name',
              size: 'sm',
            }}
            size="sm"
            errorMessage={errors.name?.message}
          />
          <FormElementWrapper label="Owner" errorMessage={errors.owner?.message} size="sm">
            <Select {...register('owner', { required: true })} className="text-xs">
              <option value="">Select owner</option>
              {membersQuery.data?.map((member) => (
                <option key={member.id} value={member.id}>
                  {member.email}
                </option>
              ))}
            </Select>
          </FormElementWrapper>
          {renderSubmitButton && renderSubmitButton((handler) => handleSubmit(handler))}
        </div>
      )}
      {membersQuery.isInitialLoading && <CenteredLoader />}
    </>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetadataResourcePropertyResponseDef = exports.MetadataResourcePropertyResponseType = void 0;
const typebox_1 = require("@sinclair/typebox");
var MetadataResourcePropertyResponseType;
(function (MetadataResourcePropertyResponseType) {
    MetadataResourcePropertyResponseType["OBJECT"] = "OBJECT";
    MetadataResourcePropertyResponseType["EVENT"] = "EVENT";
})(MetadataResourcePropertyResponseType || (exports.MetadataResourcePropertyResponseType = MetadataResourcePropertyResponseType = {}));
exports.MetadataResourcePropertyResponseDef = typebox_1.Type.Object({
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    resource_type: typebox_1.Type.Enum(MetadataResourcePropertyResponseType),
    resource_id: typebox_1.Type.String(),
    property_id: typebox_1.Type.String({ format: 'uuid' }),
    property_name: typebox_1.Type.String(),
    property_value: typebox_1.Type.String(),
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toUpdateMetricVO = exports.toCreateMetricVO = exports.CreateMetricRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const value_object_1 = require("../value-object");
const types_1 = require("../types");
const typebox_2 = require("../typebox");
exports.CreateMetricRequestDef = typebox_1.Type.Object({
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    description: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    source: (0, typebox_2.OptionalNullable)(typebox_1.Type.Enum(types_1.MetricSource)),
    owner: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    display_name: typebox_1.Type.String(),
    query_configuration: typebox_1.Type.Unsafe(value_object_1.MetricQueryConfigurationVODef),
});
const toCreateMetricVO = (accountId, metric, createdBy) => {
    return {
        accountId: accountId,
        workspaceId: metric.workspace_id,
        owner: metric.owner,
        description: metric.description,
        source: metric.source,
        createdBy: createdBy,
        displayName: metric.display_name,
        queryConfiguration: metric.query_configuration,
        key: metric.display_name.replace(/ /g, '_').replace(/\W/g, ''),
        systemManaged: false,
    };
};
exports.toCreateMetricVO = toCreateMetricVO;
const toUpdateMetricVO = (metric, updatedBy) => {
    return {
        owner: metric.owner,
        description: metric.description,
        source: metric.source,
        updatedBy: updatedBy,
        displayName: metric.display_name,
        queryConfiguration: metric.query_configuration,
    };
};
exports.toUpdateMetricVO = toUpdateMetricVO;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toMemberPermissionResponse = exports.MemberPermissionResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_1 = require("../model");
exports.MemberPermissionResponseDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: typebox_1.Type.String({ format: 'uuid' }),
        member_id: typebox_1.Type.String({ format: 'uuid' }),
        entity_id: typebox_1.Type.String(),
        entity_type: typebox_1.Type.Enum(model_1.PermissionEntityType),
        role: typebox_1.Type.Optional(typebox_1.Type.Enum(model_1.MemberPermissionRole)),
    }),
]);
const toMemberPermissionResponse = (permission) => {
    return {
        id: permission.id,
        member_id: permission.memberId,
        entity_id: permission.entityId,
        entity_type: permission.entityType,
        role: permission.role,
    };
};
exports.toMemberPermissionResponse = toMemberPermissionResponse;

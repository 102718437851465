import { ReportsDetailData } from '@bigdelta/lib-api-client';
import { ReportState } from '../../store/Report';
import { ChartType, ReportTypeVO, TimeGranularity } from '@bigdelta/lib-shared';
import { getTimerangeFromQueryTime } from './getTimerangeFromQueryTime';

export const getReportStateFromSavedReport = (data: ReportsDetailData) => {
  if (!data.query) {
    return;
  }

  const reportState: ReportState = {
    id: data.id ?? null,
    title: data.title ?? null,
    reportType: data.type ? ReportTypeVO[data.type] : null,
    isHydrated: true,
    report: {
      trends: {
        builders: [],
      },
      funnel: {
        builder: null,
      }
    },
    breakdown: {
      properties: [],
      values: [],
      colors: {},
    },
    display: {
      timerange: getTimerangeFromQueryTime(data.query?.time) ?? null,
      granularity: (data.query.display_options?.time_granularity as TimeGranularity) ?? null,
      visualization: (data.query.display_options?.chart_type as ChartType) ?? null,
    },
  };

  return reportState;
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteMetricGraphNodeResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const metricGraphNodeResponse_1 = require("./metricGraphNodeResponse");
const model_1 = require("../model");
exports.DeleteMetricGraphNodeResponseDef = typebox_1.Type.Object({
    deleted: typebox_1.Type.Object({
        nodes: typebox_1.Type.Array(metricGraphNodeResponse_1.MetricGraphNodeResponseDef),
        edges: typebox_1.Type.Array(model_1.MetricGraphEdgeDef),
    }),
});

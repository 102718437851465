import { SidePanel, SidePanelFooterWrapper } from '../../../shared/components/SidePanel.tsx';
import { useReportsStore, useReportStore } from '../../reports/store';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useMetricQuery } from '../../../shared/data/useMetricQuery.ts';
import { MetricForm } from '../../../components/MetricForm/MetricForm.tsx';
import { SkeletonBlock } from '../../../components/SkeletonBlock.tsx';
import { MetricFormSchema } from '../../../components/MetricForm/MetricFormSchema.ts';
import { CenteredLoader } from '../../../shared/components/CenteredLoader.tsx';
import { Button } from '../../../shared/ui/Button/Button.tsx';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MetricsUpdatePayload } from '@bigdelta/lib-api-client';

import { toastError, toastSuccess } from '../../../utils/toast.tsx';
import { useWorkspace } from '../../auth/hooks/useWorkspace.tsx';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { useOutsideClickHandler } from '../../../shared/hooks/useOutsideClickHandler.ts';
import { tracking, TrackingEvent } from '../../../tracking';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

type Props = {
  metricId: string;
  onSuccess?: () => void;
  onClose?: () => void;
};

export const EditMetricSidePanel: FC<Props> = ({ metricId, onSuccess, onClose }) => {
  const { currentWorkspaceId } = useWorkspace();
  const { reset } = useReportStore(metricId, { topLevelReportQuery: true })((state) => state);
  const metricQuery = useMetricQuery({ metricId });
  const queryClient = useQueryClient();
  const queryKeys = useQueryKeys();
  const sidePanelRef = useRef();
  useOutsideClickHandler(sidePanelRef, () => {
    // todo handle when all portal elements no longer trigger this
    // onClose();
  });

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const { isLoading, isSuccess, isInitialLoading } = metricQuery;
  const defaultValues: MetricFormSchema = useMemo(() => {
    if (!isSuccess) {
      return null;
    }

    const metric = metricQuery.data;
    return {
      key: metric.key,
      display_name: metric.display_name,
      description: metric.description,
      owner: metric.owner?.id,
    };
  }, [isSuccess, metricQuery.data]);

  const saveMetricMutation = useMutation({
    mutationFn: ({ metricId, payload }: { metricId: string; payload: MetricsUpdatePayload }) => bigdeltaAPIClient.v1.metricsUpdate(metricId, payload),
    onSuccess: (data) => {
      tracking.track(TrackingEvent.MetricRulesEdited, {
        'metric id': data.id,
        'metric display name': data.display_name,
      });
      queryClient.invalidateQueries(queryKeys.metrics());
      queryClient.invalidateQueries(queryKeys.metric(metricId));
      toastSuccess('Metric updated', 'Metric updated successfully');

      onSuccess && onSuccess();
    },
    onError: (error: any) => {
      if (error?.response?.data?.message) {
        toastError(`An error occurred while updating the metric: ${error?.response?.data?.message}`);
      } else {
        toastError('An error occurred while updating the metric');
      }
    },
  });

  const onSubmit = useCallback(
    (data: MetricFormSchema) => {
      saveMetricMutation.mutate({
        metricId: metricId,
        payload: {
          display_name: data.display_name,
          description: data.description,
          owner: data.owner,
          query_configuration: data.query_configuration as MetricsUpdatePayload['query_configuration'],
          workspace_id: currentWorkspaceId,
        },
      });
    },
    [currentWorkspaceId, metricId, saveMetricMutation]
  );
  const systemManagedMetric = useMemo(() => metricQuery.data?.system_managed, [metricQuery.data]);

  return (
    <SidePanel
      ref={sidePanelRef}
      title={isLoading ? <SkeletonBlock className="m-0 h-5 w-32" /> : isSuccess ? metricQuery.data?.display_name : null}
      onClose={onClose}
    >
      {!isInitialLoading && (
        <MetricForm
          metricId={metricId}
          disabled={systemManagedMetric}
          defaultValues={defaultValues}
          reportBuilderParams={{ hydrateReportStoreFromMetric: true }}
          renderSubmitButton={(handleSubmit, disabled) => {
            if (systemManagedMetric) {
              return null;
            }

            return (
              <SidePanelFooterWrapper>
                <Button label="Save metric" intent="brand" fullWidth onClick={handleSubmit(onSubmit)} disabled={disabled} />
              </SidePanelFooterWrapper>
            );
          }}
        />
      )}
      {isInitialLoading && <CenteredLoader />}
    </SidePanel>
  );
};

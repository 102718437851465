import { useInfiniteQuery } from '@tanstack/react-query';
import { bigdeltaAPIClient } from '../../client/bigdeltaAPIClient.ts';

interface UseMetadataEventsInfiniteQueryArgs {
  workspaceId?: string;
  searchQuery?: string;
}

export const useMetadataEventsInfiniteQuery = ({ workspaceId, searchQuery }: UseMetadataEventsInfiniteQueryArgs) => {
  return useInfiniteQuery({
    queryKey: ['metadata', 'events', searchQuery],
    queryFn: ({ pageParam }) =>
      bigdeltaAPIClient.v1.metadataEventsList({ workspace_id: workspaceId!, limit: 50, offset: pageParam ?? 0, query: searchQuery }),
    enabled: !!workspaceId,
    getNextPageParam: (lastPage) => {
      if (lastPage.range.to + 1 < lastPage.range.total) {
        return lastPage.range.to + 1;
      } else {
        return undefined;
      }
    },
  });
};

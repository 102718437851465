import { FC } from 'react';
import * as Select from '../../../components/SelectSmall';
import ClockIcon from '../../../assets/icons/clock.svg?react';
import { TimeGranularity } from '@bigdelta/lib-shared';

const values = [
  { label: 'Hour', value: 'hour', icon: ClockIcon },
  { label: 'Day', value: 'day', icon: ClockIcon },
  { label: 'Week', value: 'week', icon: ClockIcon },
  { label: 'Month', value: 'month', icon: ClockIcon },
  { label: 'Quarter', value: 'quarter', icon: ClockIcon },
];

interface ChartGranularitySelectProps {
  onChange: (value: TimeGranularity) => void;
  value: TimeGranularity;
}

export const ChartGranularitySelect: FC<ChartGranularitySelectProps> = ({ onChange, value }) => {
  const getSelectItem = (val: string) => values.find((v) => v.value === val);

  return (
    <Select.Root value={value} onValueChange={(val) => onChange(val as TimeGranularity)}>
      <Select.Trigger placeholder="Granularity" label={getSelectItem(value)?.label ?? ''} icon={getSelectItem(value)?.icon} />
      <Select.Content fullWidth>
        {values.map(({ label, value, icon }) => (
          <Select.Item label={label} value={value} icon={icon} key={label} />
        ))}
      </Select.Content>
    </Select.Root>
  );
};

import { FC, useState } from 'react';
import { TriggerType } from '../types';
import { WorkspaceObjectSelect } from '../../../shared/components/WorkspaceObjectSelect';
import { Trigger } from './ConfigTrigger';
import { WorkspaceObjectPropertySelect } from '../../../shared/components/WorkspaceObjectPropertySelect.tsx';

interface RecordTriggerConfigProps {
  triggerType: TriggerType;
  workspaceObjectId?: string;
  workspaceObjectProperty?: string;
  onChange: (trigger: Trigger) => void;
}

export const RecordTriggerConfig: FC<RecordTriggerConfigProps> = ({ onChange, workspaceObjectId, workspaceObjectProperty, triggerType }) => {
  const handleChange = (id: string | null, property: string | null) => {
    onChange({ workspaceObjectId: id ?? undefined, workspaceObjectProperty: property ?? undefined, triggerType });
  };

  const [selectedWorkspaceObjectId, setSelectedWorkspaceObjectId] = useState(workspaceObjectId);

  return (
    <div className="flex flex-col gap-y-4">
      <div className="text-lg font-medium">Input</div>
      <div>
        <label className="mb-1.5 block text-md">Object</label>
        <WorkspaceObjectSelect
          value={workspaceObjectId ?? null}
          onChange={(id) => {
            setSelectedWorkspaceObjectId(id);
            handleChange(id, null);
          }}
        />
      </div>
      {triggerType === TriggerType.RECORD_UPDATED && selectedWorkspaceObjectId && (
        <div>
          <label className="mb-1.5 block text-md">
            Property <span className="text-xs">(optional)</span>
          </label>
          <WorkspaceObjectPropertySelect
            workspaceObjectId={selectedWorkspaceObjectId}
            value={workspaceObjectProperty ?? null}
            onChange={(property) => handleChange(selectedWorkspaceObjectId, property)}
          />
          <div className="mt-1.5 text-xs">
            Trigger only when this property is updated. Select <b>(any)</b> to trigger on any change.
          </div>
        </div>
      )}
    </div>
  );
};

import { FC, useMemo, useState } from 'react';
import { ToolsTable } from '../../../shared/components/ToolsTable';
import { suppressConsoleWarn } from '../../../shared/utils/suppressConsoleWarn';
import { InsightsListData, MetricsListData } from '@bigdelta/lib-api-client';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../routes';
import TrashIcon from '../../../assets/icons/trash-03.svg?react';
import EditIcon from '../../../assets/icons/edit-02.svg?react';
import { Dialog } from '@headlessui/react';
import { Button } from '../../../shared/ui/Button/Button.tsx';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { toastError, toastSuccess } from '../../../utils/toast.tsx';
import { UserTableCell } from '../../../shared/ui/UserTableCell/UserTableCell.tsx';
import { useWorkspace } from '../../auth/hooks/useWorkspace.tsx';
import { isNil } from 'lodash';
import { CenteredLoader } from '../../../shared/components/CenteredLoader.tsx';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

type Metric = MetricsListData['items'][number];

const columnHelper = createColumnHelper<Metric>();

type Props = {
  metrics: MetricsListData['items'];
};

export const MetricsTable: FC<Props> = ({ metrics }) => {
  const navigate = useNavigate();
  const [metricToDelete, setMetricToDelete] = useState<Metric>(null);
  const queryClient = useQueryClient();
  const queryKeys = useQueryKeys();
  const { currentWorkspaceId } = useWorkspace();

  const { data: dependantReports } = useQuery({
    queryKey: [...queryKeys.reports(), 'metricId', metricToDelete],
    queryFn: () => bigdeltaAPIClient.v1.reportsList({ workspace_id: currentWorkspaceId, metric_id: metricToDelete.id }),
    enabled: !isNil(metricToDelete?.id),
  });

  const { data: dependantMetrics } = useQuery({
    queryKey: [...queryKeys.metrics(), 'childMetricId', metricToDelete],
    queryFn: () => bigdeltaAPIClient.v1.metricsList({ workspace_id: currentWorkspaceId, child_metric_id: metricToDelete.id }),
    enabled: !isNil(metricToDelete?.id),
  });

  const { data: dependantMetricTrees } = useQuery({
    queryKey: [...queryKeys.single('metric-tree'), 'metricId', metricToDelete],
    queryFn: () => bigdeltaAPIClient.v1.metricGraphsList({ workspace_id: currentWorkspaceId, metric_id: metricToDelete.id }),
    enabled: !isNil(metricToDelete?.id),
  });

  const isDependencyFor = useMemo(() => {
    const result: string[] = [];

    if (dependantReports?.range.total > 0) {
      result.push(`${dependantReports?.range.total} report(s)`);
    }

    if (dependantMetrics?.range.total > 0) {
      result.push(`${dependantMetrics?.range.total} other metric(s)`);
    }

    if (dependantMetricTrees?.length > 0) {
      result.push(`${dependantMetricTrees?.length} metric graph(s)`);
    }

    return result;
  }, [dependantReports, dependantMetrics, dependantMetricTrees]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('display_name', {
        id: 'display_name',
        header: 'Name',
        cell: (props) => {
          let value;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          return value;
        },
      }),
      columnHelper.accessor('owner', {
        id: 'owner',
        header: 'Owner',
        cell: (props) => {
          let value: InsightsListData['items'][number]['owner'] | undefined;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          if (!value?.email) {
            return '';
          }

          return <UserTableCell email={value.email} />;
        },
      }),
      columnHelper.accessor('created_at', {
        id: 'created_at',
        header: 'Date created',
        size: 100,
        cell: (props) => {
          let value;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          if (!value) {
            return '';
          }

          return format(parseISO(value), 'dd/MM/yyyy');
        },
      }),
      columnHelper.accessor('created_by', {
        id: 'created_by',
        header: 'Created by',
        cell: (props) => {
          let value: InsightsListData['items'][number]['owner'] | undefined;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          if (!value?.email) {
            return '';
          }

          return <UserTableCell email={value.email} />;
        },
      }),
      columnHelper.accessor('updated_at', {
        id: 'updated_at',
        header: 'Date updated',
        size: 100,
        cell: (props) => {
          let value;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          if (!value) {
            return '';
          }

          return format(parseISO(value), 'dd/MM/yyyy');
        },
      }),
      columnHelper.accessor('updated_by', {
        id: 'updated_by',
        header: 'Updated by',
        cell: (props) => {
          let value: InsightsListData['items'][number]['owner'] | undefined;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          if (!value?.email) {
            return '';
          }

          return <UserTableCell email={value.email} />;
        },
      }),
      columnHelper.display({
        id: 'actions',
        header: 'Actions',
        cell: (props) => {
          return (
            <div className="flex items-center justify-start gap-x-2">
              <EditIcon
                className="h-5 w-5 cursor-pointer text-m-olive-300 hover:text-m-olive-400"
                onClick={() => navigate(AppRoutes.EDIT_METRIC(props.row.original.id))}
              />
              <TrashIcon
                className="h-5 w-5 cursor-pointer text-m-red-600 hover:text-m-red-700"
                onClick={() => setMetricToDelete(props.row.original)}
              />
            </div>
          );
        },
      }),
    ],
    [navigate]
  );

  const table = useReactTable({
    columns,
    data: metrics ?? [],
    getCoreRowModel: getCoreRowModel(),
  });
  const deleteMetricMutation = useMutation({
    mutationFn: ({ metricId }: { metricId: string }) => bigdeltaAPIClient.v1.metricsDelete(metricId),
    onSuccess: () => {
      toastSuccess('Metric deleted', 'The metric has been deleted successfully');

      queryClient.invalidateQueries(queryKeys.metrics());
      queryClient.invalidateQueries(queryKeys.single('metric-tree'));

      setMetricToDelete(null);
    },
    onError: () => {
      toastError('An error occurred while deleting the metric');

      setMetricToDelete(null);
    },
  });
  const handleConfirmDeleteMetric = () => {
    deleteMetricMutation.mutate({ metricId: metricToDelete.id });
  };

  return (
    <>
      <Dialog open={!!metricToDelete || deleteMetricMutation.isLoading} onClose={() => setMetricToDelete(null)} className="relative z-[999999]">
        <div className="fixed inset-0 bg-m-gray-700 opacity-95" aria-hidden="true" />
        <div className="fixed inset-0 flex w-screen items-center justify-center">
          {dependantReports && dependantMetrics && dependantMetricTrees && (
            <Dialog.Panel className="mx-auto flex w-3/5 max-w-lg flex-col gap-y-6 rounded-xl bg-white">
              <Dialog.Title className="p-6 pb-0 text-xl font-bold text-m-olive-700">
                {isDependencyFor.length === 0 ? 'Confirm metric deletion' : "Metric can't be removed!"}
              </Dialog.Title>
              {isDependencyFor.length === 0 && (
                <div className="px-6 text-lg">
                  Are you sure you want to delete metric <span className="font-bold">{metricToDelete?.display_name}</span>?
                </div>
              )}
              {isDependencyFor.length > 0 && <div className="px-6 text-lg">It is used in {isDependencyFor.join(', ')}.</div>}
              <div className="px-6 text-sm font-medium"></div>
              <div className="flex justify-end gap-x-2 px-6 pb-6">
                <Button
                  size="sm"
                  intent="tertiary"
                  disabled={deleteMetricMutation.isLoading}
                  onClick={() => setMetricToDelete(null)}
                  label="Cancel"
                />
                <Button
                  size="sm"
                  intent="destructive"
                  disabled={isDependencyFor.length > 0 || deleteMetricMutation.isLoading}
                  onClick={handleConfirmDeleteMetric}
                  label="Remove"
                />
              </div>
            </Dialog.Panel>
          )}
          {(!dependantReports || !dependantMetrics || !dependantMetricTrees) && <CenteredLoader />}
        </div>
      </Dialog>
      <ToolsTable data={metrics} table={table} />
    </>
  );
};

import { FilterItem, FilterKey, useFilterStore } from '../../store';
import { useMemo, useRef } from 'react';
import { useRelationshipsQuery } from '../../../data/useRelationshipsQuery.ts';
import { last } from 'lodash';
import { WhiteElevatedCard } from '../common/WhiteElevatedCard.tsx';
import * as Popover from '@radix-ui/react-popover';
import { RecordPropertyMultilevelSelectContent } from '../../../components/RecordPropertyMultilevelSelectContent.tsx';
import { MetadataResourcePropertyType, RelationshipEntityType } from '@bigdelta/lib-shared';
import { ActionButtonWithTooltip } from '../common/ActionButtonWithTooltip.tsx';
import { Group } from '../common/Group.tsx';
import { Control } from '../common/Control.tsx';
import { PropertyOperatorSelect } from '../common/PropertyOperatorSelect.tsx';
import { PropertyValue } from '../common/PropertyValue.tsx';
import { RecordPropertyPath } from '../records/RecordPropertyPath.tsx';
import Cross2Icon from '../../../../assets/icons/x.svg?react';
import CloseIcon from '../../../../assets/icons/x-close.svg?react';
import { getObjectRelationshipToEvent } from '../../../utils/getObjectRelationshipToEvent.ts';

type EventRecordPropertyProps = {
  filterKey: FilterKey;
  index: number;
  filterItem: FilterItem;
  objectId: string;
  workspaceId: string;
};

export function EventRecordProperty({ filterKey, index, filterItem, objectId, workspaceId }: EventRecordPropertyProps) {
  const closeRef = useRef<HTMLButtonElement>(null);
  const { data: relationshipData } = useRelationshipsQuery({ workspaceId: workspaceId });
  const setPropertyWithRelationships = useFilterStore((state) => state.setPropertyWithRelationships);

  const setItemPropertyOperator = useFilterStore((state) => state.setItemPropertyOperator);
  const removeItem = useFilterStore((state) => state.removeItem);

  const objectToEventRelationship = useMemo(() => {
    if (!relationshipData?.relationships) {
      return null;
    }
    return getObjectRelationshipToEvent({ relationships: relationshipData.relationships, objectId });
  }, [objectId, relationshipData?.relationships]);

  const currentResourceId = last(filterItem.propertyRelationships)?.objectId;
  return (
    <div className="group flex flex-col gap-2">
      <WhiteElevatedCard>
        <Popover.Root>
          <Popover.Trigger className="flex w-full flex-1 items-center justify-start rounded px-2 py-1.5 hover:bg-m-gray-300">
            <RecordPropertyPath filterItem={filterItem} workspaceId={workspaceId} />
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content align="start" alignOffset={-20} side="bottom" sideOffset={0}>
              <RecordPropertyMultilevelSelectContent
                maxLevels={3}
                parentEntityType={RelationshipEntityType.OBJECT}
                parentEntityId={objectId}
                onChange={(property, relationships) => {
                  const option = { property, relationships };

                  if (!option?.relationships) {
                    return;
                  }

                  closeRef.current?.click();
                  const relationshipsWithEventRelationship =
                    typeof objectToEventRelationship !== 'undefined'
                      ? [
                          {
                            objectId: option.relationships[0].objectId,
                            relationshipName: objectToEventRelationship?.name ?? null,
                            objectWorkspaceId: workspaceId,
                          },
                          ...option.relationships.slice(1),
                        ]
                      : option.relationships;

                  setPropertyWithRelationships(
                    filterKey,
                    index,
                    {
                      attributeId: property.property_id,
                      attributeType: property.property_type ?? '',
                      attributeName: property.property_name,
                    },
                    relationshipsWithEventRelationship
                  );
                }}
              />
              <Popover.Close ref={closeRef} className="hidden" aria-label="Close">
                <Cross2Icon />
              </Popover.Close>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
        <ActionButtonWithTooltip
          onClick={() => {
            removeItem(filterKey, index);
          }}
          tooltipText="Remove filter"
        >
          <CloseIcon className="h-4 w-4" />
        </ActionButtonWithTooltip>
      </WhiteElevatedCard>
      <div>
        <Group>
          <Control shouldShrink={false}>
            <PropertyOperatorSelect
              index={index}
              value={filterItem.propertyOperator}
              onChange={(index, value) => setItemPropertyOperator(filterKey, index, value)}
            />
          </Control>
          {currentResourceId && (
            <Control>
              <PropertyValue index={index} resourceId={currentResourceId} resourceType={MetadataResourcePropertyType.OBJECT} />
            </Control>
          )}
        </Group>
      </div>
    </div>
  );
}

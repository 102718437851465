import { FC, useEffect, useState } from 'react';
import { PageHeading } from '../../../components/PageHeading';
import { EventsTable } from '../components/EventsTable';
import { FiltersButton } from '../../../shared/filters/components/FiltersButton';
import { FilterItemType, getFilter, useFilterStore } from '../../../shared/filters/store';
import { useWorkspace } from '../../auth/hooks/useWorkspace';

import { EventsFilterNew } from '../../../shared/filters/components/events/EventsFilterNew.tsx';
import { QuickAccessLinkType } from '@bigdelta/lib-shared';
import CursorClickIcon from '../../../assets/icons/cursor-click-01.svg?react';
import { Container } from '../../../shared/ui/Container/Container';
import { Divider } from '../../../shared/ui/Divider/Divider';
import { tracking, TrackingEvent } from '../../../tracking';
import { useObjectsQuery } from '../../../shared/data/useObjectsQuery';

export const EventsPage: FC = () => {
  const [showFilters, setShowFilters] = useState(false);

  const { currentWorkspaceId } = useWorkspace();

  const filterEvent = useFilterStore((state) => getFilter(state, ['events', 'event']));
  const filterObject = useFilterStore((state) => getFilter(state, ['events', 'object']));

  const objectsQuery = useObjectsQuery({ workspaceId: currentWorkspaceId });

  useEffect(() => {
    if (objectsQuery?.isSuccess) {
      const parentObjectId = filterObject?.items.find((filterItem) => filterItem.itemType === FilterItemType.EVENTS_OBJECT)
        ?.propertyRelationships?.[0]?.objectId;

      const eventName = filterEvent?.items.find((filterItem) => filterItem.itemType === FilterItemType.EVENTS_NAME)?.data?.value;

      if (!parentObjectId && !eventName) {
        return;
      }

      tracking.track(TrackingEvent.EventListFiltered, {
        'object id': parentObjectId,
        'event name': eventName,
        'filter type': filterEvent?.items.map((f) => f.itemType),
      });
    }
  }, [objectsQuery?.isSuccess, objectsQuery.data?.objects, filterObject?.items, filterObject, filterEvent]);

  return (
    <>
      <Container>
        <PageHeading
          breadcrumbs={[{ label: 'Events', description: 'A list of all events', icon: CursorClickIcon }]}
          favoriteLinkConfig={{
            type: QuickAccessLinkType.EVENTS,
            data: {},
            label: 'Events',
          }}
        />
      </Container>
      <Divider />
      <Container>
        <div className="flex flex-row items-center justify-between gap-x-2 py-4">
          <FiltersButton onClick={() => setShowFilters((s) => !s)} isOpen={showFilters} />
        </div>
      </Container>
      <Divider />
      <Container className="flex shrink grow">
        <div className="flex flex-1 flex-row gap-4 overflow-y-auto">
          {showFilters && (
            <div className="flex w-[352px] flex-col pt-3">
              <EventsFilterNew workspaceId={currentWorkspaceId} />
            </div>
          )}
          {showFilters && <hr className="h-full w-px bg-m-olive-100" />}
          <EventsTable className="flex-1" />
        </div>
      </Container>
    </>
  );
};

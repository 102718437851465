import { QueryValueFilterOperator } from '@bigdelta/lib-shared';
import { eventNameOperatorMap } from '../filters/const';
import { FilterItem, FilterItemType } from '../filters/store';
import { EventQueryFiltersCondition } from './getEventFilterItemsFromSegment';

export const getEventNameFilterFromCondition = (condition: EventQueryFiltersCondition): FilterItem | undefined => {
  if (!('event_name' in condition) || !condition?.event_name?.operator || !condition?.event_name?.operator) {
    return;
  }

  return {
    itemType: FilterItemType.EVENTS_NAME,
    propertyOperator: condition.event_name.operator as QueryValueFilterOperator,
    propertyRelationships: [],
    data: {
      valueType: eventNameOperatorMap[condition?.event_name?.operator],
      value: condition?.event_name?.value,
    },
    timeframe: {},
  };
};

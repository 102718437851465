import StripeIcon from '../../assets/integrations/Stripe.svg?react';
import AmplitudeIcon from '../../assets/integrations/Amplitude.svg?react';
import MixpanelIcon from '../../assets/integrations/Mixpanel.svg?react';

import ZendeskIcon from '../../assets/integrations/Zendesk.svg?react';
import IntercomIcon from '../../assets/integrations/Intercom.svg?react';
import BigqueryIcon from '../../assets/integrations/Bigquery.svg?react';
import SnowflakeIcon from '../../assets/integrations/Snowflake.svg?react';
import BrainTreeIcon from '../../assets/integrations/Braintree.svg?react';
import SlackIcon from '../../assets/integrations/Slack.svg?react';

import CustomerIcon from '../../assets/icons/users-01.svg?react';
import EventIcon from '../../assets/icons/cursor-click-01.svg?react';

// import UsersPlusIcon from '../../assets/icons/user-plus-01.svg?react';
// import BuildingIcon from '../../assets/icons/building-05.svg?react';
import FeedbackIcon from '../../assets/icons/message-chat-circle.svg?react';
import NotificationIcon from '../../assets/icons/notification-message.svg?react';

import DefaultObjectIcon from '../../assets/icons/file-plus-02.svg?react';

import { IntegrationType } from '@bigdelta/lib-shared';
import { FC } from 'react';
import { BigQueryIntegrationSetup } from './components/BigQueryIntegrationSetup.tsx';
import { SlackIntegrationProfile } from './components/SlackIntegrationProfile.tsx';
import { BigQueryIntegrationProfile } from './components/BigQueryIntegrationProfile.tsx';
import { SVGComponent } from '../../types.ts';
import { StripeIntegrationSetup } from './components/StripeIntegrationSetup.tsx';
import { StripeIntegrationProfile } from './components/StripeIntegrationProfile.tsx';

export interface IntegrationRowData {
  name: string;
  description: string;
  type: IntegrationType | null;
  icon: SVGComponent;
  records: Array<{ icon: SVGComponent; label: string }>;
  metrics: string[];
  docs?: string;
  setupComponent?: FC<{ close: () => void }>;
  profileComponent?: FC<{ integrationId: string }>;
  link?: string;
}

export const AVAILABLE_INTEGRATIONS: IntegrationRowData[] = [
  {
    name: 'Stripe',
    description: 'Import payments from Stripe',
    type: IntegrationType.STRIPE,
    icon: StripeIcon,
    link: `https://marketplace.stripe.com/oauth/v2/authorize?client_id=${import.meta.env.VITE_APP_STRIPE_CLIENT_ID}&redirect_uri=${
      import.meta.env.VITE_APP_APP_BASE_URL
    }/integrations/stripe/auth`,
    setupComponent: StripeIntegrationSetup,
    profileComponent: StripeIntegrationProfile,
    records: [
      {
        icon: CustomerIcon,
        label: 'Customer List',
      },
    ],
    metrics: [
      'MRR',
      'New MRR',
      'Net MRR Movement',
      'Retained MRR',
      'New Paying Customers',
      'Average Sales Price',
      'Expansion Mrr',
      'Number of Expanded Customers',
      'Average Expansion Amount',
      'Contraction MRR',
      'Number of Contracted Customers',
      'Average Contraction Amount',
      'Churn MRR',
      'Number of Churned Customers',
      'Average Churn Amount',
      'Reactivation MRR',
      'Number of Reactivated Customers',
      'Average Reactivation Amount',
      'Number of Retained Customers',
      'Average Retention Amount',
    ],
  },
  {
    name: 'Slack',
    description: 'Receive notifications to your Slack workspace',
    type: IntegrationType.SLACK,
    icon: SlackIcon,
    link: `https://slack.com/oauth/v2/authorize?client_id=5816850526962.7172635866016&scope=channels:join,channels:read,groups:read,groups:write,chat:write&redirect_uri=${
      import.meta.env.VITE_APP_APP_BASE_URL
    }/integrations/slack/auth`,
    profileComponent: SlackIntegrationProfile,
    records: [
      {
        icon: NotificationIcon,
        label: 'Notifications',
      },
    ],
    metrics: [],
  },
  {
    name: 'BigQuery',
    description: 'Import records from BigQuery',
    type: IntegrationType.BIGQUERY,
    icon: BigqueryIcon,
    records: [
      {
        icon: DefaultObjectIcon,
        label: 'Records',
      },
    ],
    metrics: [],
    setupComponent: BigQueryIntegrationSetup,
    profileComponent: BigQueryIntegrationProfile,
  },
];

export const UNAVAILABLE_INTEGRATIONS: IntegrationRowData[] = [
  {
    name: 'Braintree',
    description: 'Import payments from Braintree',
    type: null,
    icon: BrainTreeIcon,
    records: [
      {
        icon: CustomerIcon,
        label: 'Customer List',
      },
    ],
    metrics: [
      'MRR',
      'New MRR',
      'Net MRR Movement',
      'Retained MRR',
      'New Paying Customers',
      'Average Sales Price',
      'Expansion Mrr',
      'Number of Expanded Customers',
      'Average Expansion Amount',
      'Contraction MRR',
      'Number of Contracted Customers',
      'Average Contraction Amount',
      'Churn MRR',
      'Number of Churned Customers',
      'Average Churn Amount',
      'Reactivation MRR',
      'Number of Reactivated Customers',
      'Average Reactivation Amount',
      'Number of Retained Customers',
      'Average Retention Amount',
    ],
  },
  {
    name: 'Amplitude',
    description: 'Import any events from Amplitude',
    type: null,
    icon: AmplitudeIcon,
    records: [
      {
        icon: EventIcon,
        label: 'Event List',
      },
    ],
    metrics: [''],
  },
  {
    name: 'Mixpanel',
    description: 'Import any events from Mixpanel',
    type: null,
    icon: MixpanelIcon,
    records: [
      {
        icon: EventIcon,
        label: 'Event List',
      },
    ],
    metrics: [],
  },
  // {
  //   name: 'Salesforce',
  //   description: 'Import users, accounts, leads, opportunities from Salesforce',
  //   type: null,
  //   icon: SalesforceIcon,
  //   records: [
  //     {
  //       icon: UsersPlusIcon,
  //       label: 'User List',
  //     },
  //     {
  //       icon: BuildingIcon,
  //       label: 'Account List',
  //     },
  //     {
  //       icon: DefaultObjectIcon,
  //       label: 'Lead List',
  //     },
  //     {
  //       icon: DefaultObjectIcon,
  //       label: 'Opportunity List',
  //     },
  //   ],
  //   metrics: [],
  // },
  // {
  //   name: 'Hubspot',
  //   description: 'Import users, accounts, leads, opportunities from Hubspot',
  //   type: null,
  //   icon: HubspotIcon,
  //   records: [
  //     {
  //       icon: UsersPlusIcon,
  //       label: 'User List',
  //     },
  //     {
  //       icon: BuildingIcon,
  //       label: 'Account List',
  //     },
  //     {
  //       icon: DefaultObjectIcon,
  //       label: 'Lead List',
  //     },
  //     {
  //       icon: DefaultObjectIcon,
  //       label: 'Opportunity List',
  //     },
  //   ],
  //   metrics: [],
  // },
  {
    name: 'Zendesk',
    description: 'Import feedback records from Zendesk',
    type: null,
    icon: ZendeskIcon,
    records: [
      {
        icon: FeedbackIcon,
        label: 'Feedback List',
      },
    ],
    metrics: [],
  },
  {
    name: 'Intercom',
    description: 'Import feedback records from Intercom',
    type: null,
    icon: IntercomIcon,
    records: [
      {
        icon: FeedbackIcon,
        label: 'Feedback List',
      },
    ],
    metrics: [],
  },
  {
    name: 'Snowflake',
    description: 'Import any records & events from Snowflake',
    type: null,
    icon: SnowflakeIcon,
    records: [
      {
        icon: DefaultObjectIcon,
        label: 'Records',
      },
      {
        icon: EventIcon,
        label: 'Events',
      },
    ],
    metrics: [],
  },
];

import { createColumnHelper, useReactTable, getCoreRowModel } from '@tanstack/react-table';
import React, { FC, FormEvent, useMemo, useState } from 'react';
import { ToolsTable } from '../../../shared/components/ToolsTable';

import { suppressConsoleWarn } from '../../../shared/utils/suppressConsoleWarn';
import DeleteIcon from '../../../assets/icons/trash-01.svg?react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { Dialog } from '@headlessui/react';
import { Button } from '../../../shared/ui/Button/Button.tsx';
import { format, parseISO } from 'date-fns';
import { UserTableCell } from '../../../shared/ui/UserTableCell/UserTableCell.tsx';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';
import { AuthTrackingKeyListData } from '@bigdelta/lib-api-client';

const columnHelper = createColumnHelper<AuthTrackingKeyListData[number]>();

interface TrackingKeysTableProps {
  data?: AuthTrackingKeyListData;
}

export const TrackingKeysTable: FC<TrackingKeysTableProps> = ({ data }) => {
  const queryClient = useQueryClient();
  const queryKeys = useQueryKeys();

  const [isDeleteTrackingKeyDialogOpen, setDeleteTrackingKeyDialogOpen] = useState(false);
  const [targetedTrackingKeyId, setTargetedTrackingKeyId] = useState('');
  const [deleteConfirmationText, setDeleteConfirmationText] = useState('');

  const mutation = useMutation({
    mutationFn: (trackingKeyId: string) => bigdeltaAPIClient.v1.authTrackingKeyDelete(trackingKeyId),
    onSuccess: () => {
      handleDeleteTrackingKeyDialogClose();
      return queryClient.invalidateQueries(queryKeys.trackingKeys());
    },
  });

  const EXPECTED_CONFIRMATION_TEXT = 'Delete Tracking Key';

  const handleDeleteTrackingKey = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (deleteConfirmationText === EXPECTED_CONFIRMATION_TEXT) {
      mutation.mutate(targetedTrackingKeyId);
    }
  };

  const handleDeleteTrackingKeyDialogClose = () => {
    setTargetedTrackingKeyId('');
    setDeleteConfirmationText('');
    setDeleteTrackingKeyDialogOpen(false);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        header: 'Name',
        cell: (props) => {
          let value;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          return value;
        },
      }),
      columnHelper.accessor('key', {
        id: 'key',
        header: 'Tracking Key',
        size: 330,
        cell: (props) => {
          let value;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          return value;
        },
      }),
      columnHelper.accessor('created_by', {
        id: 'created_by',
        header: 'Created by',
        size: 200,
        cell: (props) => {
          let value: AuthTrackingKeyListData[number]['created_by'] | undefined;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          if (!value) {
            return '';
          }

          return <UserTableCell email={value.email} />;
        },
      }),
      columnHelper.accessor('created_at', {
        id: 'created_at',
        header: 'Date created',
        size: 130,
        cell: (props) => {
          let value;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          if (!value) {
            return '';
          }

          return format(parseISO(value), 'dd/MM/yyyy');
        },
      }),
      columnHelper.display({
        id: 'actions',
        header: 'Actions',
        size: 80,
        cell: (context) => {
          return (
            <div className="flex items-center gap-x-2">
              <button
                onClick={() => {
                  setTargetedTrackingKeyId(context.row.id);
                  setDeleteTrackingKeyDialogOpen(true);
                }}
              >
                <DeleteIcon className="h-5 w-5 cursor-pointer text-m-red-600 hover:text-m-red-700" />
              </button>
            </div>
          );
        },
      }),
    ],
    []
  );

  const table = useReactTable({
    columns,
    data: data ?? [],
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id,
  });

  return (
    <>
      <ToolsTable data={data} table={table} />
      <Dialog open={isDeleteTrackingKeyDialogOpen} onClose={handleDeleteTrackingKeyDialogClose} className="relative z-50">
        <div className="fixed inset-0 bg-m-gray-700 opacity-95" aria-hidden="true" />
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <Dialog.Panel className="mx-auto flex w-1/3 max-w-xl flex-col gap-y-6 rounded-xl bg-white p-6">
            <Dialog.Title className="text-xl text-m-olive-700">Delete Tracking Key</Dialog.Title>
            <form onSubmit={handleDeleteTrackingKey} className="flex flex-col gap-y-6">
              <div className="flex flex-col gap-y-1.5">
                <p className="text-lg text-m-olive-700">Type "{EXPECTED_CONFIRMATION_TEXT}" to continue:</p>
                <input
                  name="delete_confirmation"
                  className="mt-5 rounded-lg border border-m-gray-300 px-3 py-2"
                  value={deleteConfirmationText}
                  placeholder={EXPECTED_CONFIRMATION_TEXT}
                  onChange={(e) => setDeleteConfirmationText(e.target.value)}
                />
              </div>
              <div className="flex justify-end gap-x-3.5">
                <Button type="button" label="Cancel" intent="secondary" size="lg" onClick={handleDeleteTrackingKeyDialogClose} />
                <Button
                  label="Delete Tracking Key"
                  intent={deleteConfirmationText !== EXPECTED_CONFIRMATION_TEXT ? 'tertiary' : 'destructive'}
                  size="lg"
                  type="submit"
                  disabled={deleteConfirmationText !== EXPECTED_CONFIRMATION_TEXT}
                />
              </div>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

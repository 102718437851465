import { Descendant } from 'slate';
import { TrendsBuilderState } from '../../store/TrendsBuilder';
import * as math from 'mathjs';

const FormulaError = {
  invalidFormula: () => 'Invalid formula',
  cannotReferenceItself: () => 'Formula cannot reference itself',
  variablesNotFound: (vars: string[]) => `Variables not found: ${vars.join(', ')}`,
};

interface GetParsedFormulaDataResult {
  error: string;
  missingBuilders?: string[];
  expressionMetrics?: { id: string; queryName: string }[];
  formulaBuilders?: TrendsBuilderState[];
  expression?: string;
}

export const getParsedFormulaData = (
  expressionRaw: Descendant[],
  builderName: string,
  builders: TrendsBuilderState[]
): GetParsedFormulaDataResult => {
  let error = '';

  if (expressionRaw) {
    let expression = '';
    const expressionMetrics: { id: string; queryName: string }[] = [];

    const parentNode = expressionRaw[0];

    if (!parentNode) {
      error = FormulaError.invalidFormula();
    }

    if ('children' in parentNode) {
      const nodes = parentNode.children;

      nodes.forEach((node) => {
        if (!('children' in node)) {
          expression += node.text;
          return;
        }

        if (node.type === 'metric') {
          const queryName = 'SAVED_' + String.fromCharCode(expressionMetrics.length + 65);

          if (!node.metric.id) {
            return {
              error: FormulaError.invalidFormula(),
            };
          }

          expressionMetrics.push({ id: node.metric.id, queryName });

          expression += queryName;
        }
      });
    }

    const missingBuilders: string[] = [];

    try {
      const expressionNode = math.parse(expression);
      const variables = expressionNode.filter((node) => node.type === 'SymbolNode') as math.SymbolNode[];
      const formulaBuilders: TrendsBuilderState[] = [];

      const referenceToItself = variables.find((node) => node.name === builderName);

      if (referenceToItself) {
        return {
          error: FormulaError.cannotReferenceItself(),
        };
      }

      variables.forEach((node) => {
        if (node.name.startsWith('SAVED_')) {
          if (!expressionMetrics.find((metric) => metric.queryName === node.name)) {
            missingBuilders.push(node.name);
          }
        } else {
          const b = builders.find((builder) => builder.name === node.name);

          if (b) {
            formulaBuilders.push(b);
          } else {
            missingBuilders.push(node.name);
          }
        }
      });

      if (missingBuilders.length) {
        error = FormulaError.variablesNotFound(missingBuilders);
      }

      return {
        error,
        missingBuilders,
        expressionMetrics,
        formulaBuilders,
        expression,
      };
    } catch (e) {
      return {
        error: 'Invalid formula',
      };
    }
  }

  return {
    error: 'Invalid formula',
  };
};

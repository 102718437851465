"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdatedAutomationRunDef = exports.AutomationRunDef = exports.AutomationRunStateDef = exports.AutomationRunBlockDataDef = exports.AutomationRunSingleBlockDataDef = exports.AutomationRunErrorDef = exports.AutomationRunBlockErrorDef = exports.AutomationRunStatusStringType = exports.AutomationRunStatus = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const timestampedModel_1 = require("./timestampedModel");
var AutomationRunStatus;
(function (AutomationRunStatus) {
    AutomationRunStatus["CREATED"] = "CREATED";
    AutomationRunStatus["PROCESSING"] = "PROCESSING";
    AutomationRunStatus["PROCESSING_SCHEDULED"] = "PROCESSING_SCHEDULED";
    AutomationRunStatus["CANCELED"] = "CANCELED";
    AutomationRunStatus["ERRORED"] = "ERRORED";
    AutomationRunStatus["ERROR_GIVEN_UP"] = "ERROR_GIVEN_UP";
    AutomationRunStatus["COMPLETED"] = "COMPLETED";
})(AutomationRunStatus || (exports.AutomationRunStatus = AutomationRunStatus = {}));
exports.AutomationRunStatusStringType = (0, typebox_2.EnumStrings)(typebox_1.Type.Enum(AutomationRunStatus));
exports.AutomationRunBlockErrorDef = typebox_1.Type.Object({
    block_id: typebox_1.Type.String(),
    message: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    stack: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
}, { additionalProperties: true });
exports.AutomationRunErrorDef = typebox_1.Type.Record(typebox_1.Type.String(), exports.AutomationRunBlockErrorDef);
exports.AutomationRunSingleBlockDataDef = typebox_1.Type.Object({
    processing_started_at: typebox_1.Type.String({ format: 'date-time' }),
    processing_finished_at: typebox_1.Type.String({ format: 'date-time' }),
    success: typebox_1.Type.Boolean(),
    isConditionMet: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
}, { additionalProperties: true });
exports.AutomationRunBlockDataDef = typebox_1.Type.Record(typebox_1.Type.String(), exports.AutomationRunSingleBlockDataDef);
exports.AutomationRunStateDef = typebox_1.Type.Object({
    trigger_data: typebox_1.Type.Record(typebox_1.Type.String(), typebox_1.Type.Any()),
    block_data: exports.AutomationRunBlockDataDef,
});
exports.AutomationRunDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: typebox_1.Type.String({ format: 'uuid' }),
        trigger_id: typebox_1.Type.String(),
        automation_id: typebox_1.Type.String({ format: 'uuid' }),
        automation_version: typebox_1.Type.Number(),
        processing_worker_id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        processing_timeout_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
        status: exports.AutomationRunStatusStringType,
        state: exports.AutomationRunStateDef,
        error: (0, typebox_2.OptionalNullable)(exports.AutomationRunErrorDef),
        next_processing_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
        last_processed_block_id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    }),
    timestampedModel_1.TimestampedModelDef,
]);
exports.UpdatedAutomationRunDef = typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.AutomationRunDef, ['state']),
    typebox_1.Type.Object({
        processed_block_count: typebox_1.Type.Number(),
    }),
]);

import { FC, PropsWithChildren } from 'react';

export const AuthScreenContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="relative">
      <div className="absolute flex h-full min-h-full w-screen items-center justify-center bg-m-coconut-600" />
      <div className="relative flex min-h-screen items-center justify-center">
        <div className="m-8 flex h-fit w-full max-w-[32rem] flex-col rounded-xl bg-white p-12 text-m-olive-700">{children}</div>
      </div>
    </div>
  );
};

export const DashboardsRoutes = {
  INDEX: '/dashboards',
  VIEW_DASHBOARD: '/dashboards/:dashboardId',
  CREATE_DASHBOARD: '/dashboards/create',
  EDIT_DASHBOARD: '/dashboards/edit/:dashboardId',
};

export const AppRoutes = {
  ROOT: '/',
  TREE: '/tree',
  PLANNING: '/planning',
  PLANNING2: '/planning2',
  REPORTS: '/reports',
  SEGMENTS: '/segments',
  INSIGHTS: '/insights',
  VIEW_INSIGHT: '/insights/:insightId',
  OPPORTUNITIES: '/opportunities',
  VIEW_OPPORTUNITY: '/opportunities/:opportunityId',
  PROJECTS: '/projects',
  VIEW_PROJECT: '/projects/:projectId',
  APPS: '/apps',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:token',
  EVENTS: '/events',
  METRICS: '/metrics',
  EDIT_METRIC: (id: string) => `/metrics/${id}`,
  CREATE_METRIC: `/metrics/new`,
  SIGN_UP: '/sign-up',
  MEMBERS_ONBOARDING: '/members/onboarding',
  ACCOUNT_ONBOARDING: '/account/onboarding',
  WORKSPACES_ONBOARDING: '/workspaces/onboarding/:accountId',
  ACCESS_KEYS: '/access-keys',
  VIEW_INTEGRATION: '/integrations/:integrationId',
  INTEGRATIONS: '/integrations',
  INTEGRATIONS_SLACK_AUTH: '/integrations/slack/auth',
  INTEGRATIONS_STRIPE_AUTH: '/integrations/stripe/auth',
  MEMBERS: '/members',
  ACCEPT_INVITATION: '/accept-invitation/:invitationToken',
  AUTOMATIONS: '/automations',
  AUTOMATIONS_VIEW: (id: string) => `/automations/${id}`,
  AUTOMATIONS_CREATE: '/automations/new',
  SETTINGS_WORKSPACE_GENERAL: '/settings/workspace/general',
  SETTINGS_PROFILE: '/settings/profile',
  BILLING: '/settings/billing',
  DATA: '/data',
};

export const RecordsRoutes = {
  INDEX: '/records',
  LIST: '/records/:objectSlug',
  VIEW_RECORD: '/records/:objectSlug/:remoteRecordId',
};

export const RecordDetailsRoutes = {
  EVENTS: 'events',
  RELATED: 'related',
};

export const ReportsRoutes = {
  CREATE_REPORT: 'new',
  VIEW_REPORT: ':reportId',
};

export const ObjectsRoutes = {
  SETTINGS_WORKSPACE_LIST: '/settings/workspace/objects',
  SETTINGS_WORKSPACE_PREVIEW: '/settings/workspace/objects/:objectId',
  SETTINGS_WORKSPACE_NEW: '/settings/workspace/objects/new',
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SegmentVODef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const model_1 = require("../model");
const eventsDataQueryVO_1 = require("./eventsDataQueryVO");
const recordsDataQueryVO_1 = require("./recordsDataQueryVO");
const types_1 = require("../types");
exports.SegmentVODef = typebox_1.Type.Composite([
    model_1.TimestampedModelDef,
    typebox_1.Type.Object({
        id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        accountId: typebox_1.Type.String({ format: 'uuid' }),
        workspaceId: typebox_1.Type.String({ format: 'uuid' }),
        resourceType: typebox_1.Type.Enum(types_1.SegmentResourceType),
        name: typebox_1.Type.String(),
        description: typebox_1.Type.Optional(typebox_1.Type.String()),
        eventsQuery: eventsDataQueryVO_1.EventsDataQueryRequestDef,
        recordsQuery: typebox_1.Type.Optional(recordsDataQueryVO_1.RecordsDataQueryRequestDef),
    }),
]);

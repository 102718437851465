import { twMerge } from 'tailwind-merge';
import PlusIcon from '../../assets/icons/plus.svg?react';
import { ButtonHTMLAttributes, forwardRef } from 'react';

export interface AddGraphNodeTriggerProps {
  position: 'top' | 'bottom';
  show?: boolean;
}

export const AddGraphNodeTrigger = forwardRef<HTMLButtonElement, AddGraphNodeTriggerProps & ButtonHTMLAttributes<HTMLButtonElement>>(
  ({ position, show, className, ...props }, ref) => (
    <button
      className={twMerge(
        'nodrag invisible absolute left-1/2 z-20 -translate-x-1/2 rounded-full bg-m-blue-600 transition-transform hover:scale-125 group-hover/node:visible',
        show && 'visible',
        position === 'top' && 'top-0',
        position === 'bottom' && 'bottom-0',
        className
      )}
      {...props}
      ref={ref}
    >
      <PlusIcon className="h-6 w-6 text-m-white" />
    </button>
  )
);

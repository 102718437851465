import { ValueSelectType, attributeTypeOperatorValueSelectMap } from '../filters/const';
import { ALL_EVENTS, FilterItem, FilterItemType } from '../filters/store';
import { EventQueryFiltersCondition } from './getEventFilterItemsFromSegment';

export const getEventPropertyFilterFromCondition = (condition: EventQueryFiltersCondition): FilterItem | undefined => {
  if (
    !('event_property' in condition) ||
    !condition?.event_property?.name ||
    !condition?.event_property?.property_id ||
    !condition?.event_property.property_type ||
    !condition.event_property.value
  ) {
    return;
  }

  const dataValType: ValueSelectType =
    attributeTypeOperatorValueSelectMap[condition.event_property?.property_type][condition?.event_property.operator];

  if (!dataValType) {
    return;
  }

  return {
    itemType: FilterItemType.EVENTS_PROPERTY,
    property: {
      attributeName: condition.event_property?.name,
      attributeId: condition.event_property?.property_id,
      attributeType: condition.event_property?.property_type,
    },
    propertyRelationships: [],
    data: {
      value: condition.event_property?.value as any,
      valueType: dataValType,
    },
    propertyOperator: condition.event_property?.operator as any,
    event: ALL_EVENTS,
    timeframe: {},
  };
};

import * as ToggleGroup from '@radix-ui/react-toggle-group';
import { FC } from 'react';
import { ReportTimerange, useReportStore } from '../../store/Report';

export const Timerange: FC = () => {
  const timerange = useReportStore((state) => state.display.timerange);
  const setTimerange = useReportStore((state) => state.setTimerange);

  if (!timerange) return null;

  return (
    <ToggleGroup.Root type="single" value={timerange} onValueChange={setTimerange} className="flex gap-x-px rounded-lg bg-m-olive-100 px-px">
      {Object.keys(ReportTimerange).map((timeframe) => (
        <ToggleGroup.Item
          key={timeframe}
          value={timeframe}
          className="border-b border-t border-m-olive-100 bg-m-white px-3 py-1.5 text-sm text-m-olive-700 first:rounded-l-lg last:rounded-r-lg radix-state-on:bg-m-gray-300"
        >
          {timeframe}
        </ToggleGroup.Item>
      ))}
    </ToggleGroup.Root>
  );
};

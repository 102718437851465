import { createContext } from 'react';
import { FilterKey } from '../store';

export interface FilterContextState {
  filterKey: FilterKey;
  workspaceId: string;
  mode: 'inline' | 'stacked';
  inlineElements?: boolean;
}

export const FilterContext = createContext<FilterContextState | undefined>(undefined);

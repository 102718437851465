"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const dashboardPanelResponse_1 = require("./dashboardPanelResponse");
const memberResponse_1 = require("./memberResponse");
exports.DashboardResponseDef = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    name: typebox_1.Type.String(),
    description: typebox_1.Type.Optional(typebox_1.Type.String()),
    is_default: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    panels: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(dashboardPanelResponse_1.DashboardPanelResponseDef)),
    created_at: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'date-time' })),
    updated_at: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'date-time' })),
    owner: (0, typebox_2.OptionalNullable)(memberResponse_1.MemberResponseDef),
    created_by: (0, typebox_2.OptionalNullable)(memberResponse_1.MemberResponseDef),
    updated_by: (0, typebox_2.OptionalNullable)(memberResponse_1.MemberResponseDef),
});

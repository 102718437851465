import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../../features/auth/hooks/useQueryKeys.ts';
import { bigdeltaAPIClient } from '../../client/bigdeltaAPIClient.ts';

interface ObjectQueryArgs {
  objectSlug?: string | null;
  workspaceId?: string;
}

export const useObjectQuery = ({ objectSlug, workspaceId }: ObjectQueryArgs) => {
  const queryKeys = useQueryKeys();

  return useQuery(
    queryKeys.single('object', workspaceId, objectSlug),
    () => bigdeltaAPIClient.v1.objectsDetail(objectSlug!, { workspace_id: workspaceId! }),
    {
      refetchOnWindowFocus: false,
      enabled: !!workspaceId && !!objectSlug,
    }
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toMetricGraphEdgeVO = exports.CreateMetricGraphEdgeRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.CreateMetricGraphEdgeRequestDef = typebox_1.Type.Object({
    parent_node_id: typebox_1.Type.String({ format: 'uuid' }),
    child_node_id: typebox_1.Type.String({ format: 'uuid' }),
    influence: typebox_1.Type.Boolean(),
});
const toMetricGraphEdgeVO = (workspaceId, graphId, createMetricGraphEdgeRequest, createdBy, updatedBy) => {
    return {
        workspace_id: workspaceId,
        graph_id: graphId,
        parent_node_id: createMetricGraphEdgeRequest.parent_node_id,
        child_node_id: createMetricGraphEdgeRequest.child_node_id,
        influence: createMetricGraphEdgeRequest.influence,
        created_by: createdBy,
        updated_by: updatedBy,
    };
};
exports.toMetricGraphEdgeVO = toMetricGraphEdgeVO;

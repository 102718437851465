"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetricQueryStatus = void 0;
var MetricQueryStatus;
(function (MetricQueryStatus) {
    MetricQueryStatus["CREATED"] = "CREATED";
    MetricQueryStatus["PROCESSING"] = "PROCESSING";
    MetricQueryStatus["ERROR"] = "ERROR";
    MetricQueryStatus["COMPLETED"] = "COMPLETED";
})(MetricQueryStatus || (exports.MetricQueryStatus = MetricQueryStatus = {}));

import { RadioGroup } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

interface ReportChartTimeRange {
  onChange: (value: string) => void;
  value: string;
}

export const ReportChartTimeRange = ({ onChange, value }) => {
  const timeRanges = ['Today', '7D', '30D', '3M', '6M', '12M', '24M'];

  const handleTimeRangeChange = (value: string) => {
    onChange(value);
  };

  return (
    <RadioGroup value={value} onChange={handleTimeRangeChange}>
      <RadioGroup.Label className="sr-only">Time Range</RadioGroup.Label>
      <div className="flex overflow-hidden rounded-lg border border-m-gray-300">
        {timeRanges.map((timeRange) => (
          <RadioGroup.Option
            key={timeRange}
            value={timeRange}
            className={({ checked }) =>
              twMerge(
                'cursor-default select-none bg-m-gray-100 px-4 py-1.5 text-sm font-medium text-m-olive-600 hover:bg-m-gray-200',
                checked && 'bg-m-gray-300 hover:bg-m-gray-300'
              )
            }
          >
            {() => <span>{timeRange}</span>}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

import { CSSProperties, ReactNode } from "react";
import { PropsWithClassName } from '../../types';
import { twMerge } from '../../utils/twMerge';

type SpanElementProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

type BadgeVariant = 'default' | 'grey';

export interface BadgeProps extends PropsWithClassName, SpanElementProps {
  children: ReactNode;
  style?: CSSProperties;
  variant?: BadgeVariant;
}

export const Badge = ({ children, variant = 'default', className, ...rest }: BadgeProps) => {
  const textColor = ({
    ['default']: '',
    ['grey']: 'border border-m-gray-400 bg-m-gray-300 text-m-olive-500',
  })[variant];

  return (
    <span
      {...rest}
      title={children as string}
      className={twMerge(
        'flex flex-nowrap justify-center items-center text-center inline-block rounded-lg px-2 py-0.5 text-xs',
        'max-w-36 whitespace-nowrap overflow-hidden text-ellipsis',
        textColor,
        className
      )}
    >
      {children}
    </span>
  );
}
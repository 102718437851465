import * as SelectPrimitive from '@radix-ui/react-select';

import ChevronUpIcon from '../../../../assets/icons/chevron-up.svg?react';
import ChevronDownIcon from '../../../../assets/icons/chevron-down.svg?react';
import { forwardRef } from 'react';
import { twMerge } from '../../../../utils/twMerge';
import { SVGComponent } from '../../../../types';

export interface SelectProps extends SelectPrimitive.SelectProps {
  className?: string;
  hasArrow?: boolean;
  size?: 'sm' | 'md';
}

// TODO: write up sizes with cva
export const Select = forwardRef<HTMLButtonElement, SelectProps>(({ children, hasArrow, size = 'md', className, ...props }, forwardedRef) => {
  return (
    <SelectPrimitive.Root {...props}>
      <SelectPrimitive.Trigger
        ref={forwardedRef}
        className={twMerge(
          'flex items-center justify-between gap-x-1 whitespace-nowrap rounded-lg border border-m-olive-100 bg-m-white text-sm font-regular radix-state-open:bg-m-gray-300',
          size === 'md' && 'px-3 py-2',
          size === 'sm' && 'px-2 py-1.5',
          className,
        )}
      >
        <SelectPrimitive.Value />
        {hasArrow && (
          <SelectPrimitive.Icon asChild>
            <ChevronDownIcon className="h-4 w-4 text-m-olive-400" />
          </SelectPrimitive.Icon>
        )}
      </SelectPrimitive.Trigger>
      <SelectPrimitive.Portal>
        <SelectPrimitive.Content className="rounded-lg border border-m-olive-100 bg-m-white p-2 shadow-md">
          <SelectPrimitive.ScrollUpButton className="flex items-center justify-center">
            <ChevronUpIcon className="h-4 w-4 text-m-olive-400" />
          </SelectPrimitive.ScrollUpButton>
          <SelectPrimitive.Viewport>{children}</SelectPrimitive.Viewport>
          <SelectPrimitive.ScrollDownButton className="flex items-center justify-center">
            <ChevronDownIcon className="h-4 w-4 text-m-olive-400" />
          </SelectPrimitive.ScrollDownButton>
        </SelectPrimitive.Content>
      </SelectPrimitive.Portal>
    </SelectPrimitive.Root>
  );
});


export interface SelectItemProps extends SelectPrimitive.SelectItemProps {
  icon?: SVGComponent;
  iconClassName?: string;
}

export const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(({ children, icon, iconClassName, ...props }, forwardedRef) => {
  const Icon = icon;

  return (
    <SelectPrimitive.Item
      className="cursor-default whitespace-nowrap rounded px-3 py-2 text-sm outline-none focus:ring-transparent radix-highlighted:bg-m-gray-300"
      {...props}
      ref={forwardedRef}
    >
      <SelectPrimitive.ItemText>
        <div className="flex items-center gap-x-2">
          {Icon && <Icon className={twMerge('h-4 w-4 text-m-olive-400', iconClassName)} />}
          {children}
        </div>
      </SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
  );
});

import { createScopedStore, stateReq } from '@lukesmurray/zustand-scoped';
import { produce } from 'immer';
import { cloneDeep } from 'lodash';
import { ConversionRateCount, EventCount, ReportBuilderState, ReportBuilderStateData } from './common';

export type WindowUnit = 'hour' | 'day' | 'week' | 'month' | 'quarter';
export interface ConversionWindowData {
  unit: WindowUnit;
  value?: number;
}

export type FunnelCount = ConversionRateCount; // Will be more in the future

export type NewStepData = EventCount;

export interface StepData extends NewStepData {
  id: string;
  index: number;
}

export interface FunnelBuilderStateData extends ReportBuilderStateData<FunnelBuilderStateData>{
  data: {
    object: {
      workspaceObjectId?: string;
      count: FunnelCount;
      conversionWindow: ConversionWindowData;
    };
  };
}

export interface FunnelBuilderState extends FunnelBuilderStateData, ReportBuilderState {
  setCount: (count: FunnelCount) => void;
  setConversionWindowData: (conversionWindowData: ConversionWindowData) => void;
}

export interface FunnelBuilderInitialData {
  id: string;
  data: FunnelBuilderState['data'];
  metricId?: FunnelBuilderState['metricId'];
  initialMetricBuilderData?: FunnelBuilderState['initialMetricBuilderData'];
}

export const createFunnelBuilderStore = createScopedStore<FunnelBuilderState, FunnelBuilderInitialData>()((initialData) => {
  return stateReq((set) => ({
    ...initialData,
    resetInitialMetricBuilderData: () =>
      set((state) =>
        produce(state, (draft) => {
          const { initialMetricBuilderData: _, ...builderClone } = cloneDeep(state);

          draft.initialMetricBuilderData = builderClone;
        })
      ),
    setBuilder: (builder) =>
      set((state) =>
        produce(state, (draft) => {
          Object.assign(draft, builder);
        })
      ),
    setConversionWindowData: (conversionWindowData) =>
      set((state) =>
        produce(state, (draft) => {
          if (!draft.data.object) {
            return;
          }

          draft.data.object.conversionWindow = conversionWindowData;
        })
      ),
    setCount: (count) =>
      set((state) =>
        produce(state, (draft) => {
          if (!draft.data.object) {
            return;
          }
          draft.data.object.count = count;
        })
      ),          
  }));
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toReportResponse = exports.ReportResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const value_object_1 = require("../value-object");
const reportTemplatesQueryRequest_1 = require("../value-object/reportTemplatesQueryRequest");
const typebox_2 = require("../typebox");
const types_1 = require("../types");
const documentGroupsResponse_1 = require("./documentGroupsResponse");
const memberResponse_1 = require("./memberResponse");
exports.ReportResponseDef = typebox_1.Type.Object({
    id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    accountId: typebox_1.Type.String({ format: 'uuid' }),
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    title: typebox_1.Type.Optional(typebox_1.Type.String()),
    description: typebox_1.Type.Optional(typebox_1.Type.String()),
    details: typebox_1.Type.Optional(typebox_1.Type.String()),
    document_groups: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(typebox_1.Type.Object({
        id: typebox_1.Type.String({ format: 'uuid' }),
        name: typebox_1.Type.String(),
    }))),
    owner: (0, typebox_2.OptionalNullable)(memberResponse_1.MemberResponseDef),
    created_by: (0, typebox_2.OptionalNullable)(memberResponse_1.MemberResponseDef),
    updated_by: (0, typebox_2.OptionalNullable)(memberResponse_1.MemberResponseDef),
    type: (0, typebox_2.OptionalNullable)(typebox_1.Type.Enum(types_1.ReportTypeVO)),
    insights: typebox_1.Type.Optional(typebox_1.Type.Array(typebox_1.Type.String({ format: 'uuid' }))),
    opportunities: typebox_1.Type.Optional(typebox_1.Type.Array(typebox_1.Type.String({ format: 'uuid' }))),
    projects: typebox_1.Type.Optional(typebox_1.Type.Array(typebox_1.Type.String({ format: 'uuid' }))),
    query: (0, typebox_2.OptionalNullable)(value_object_1.ReportsQueryRequestDef),
    grouping_config: (0, typebox_2.OptionalNullable)(value_object_1.ReportGroupingConfigVODef),
    created_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    updated_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    context: (0, typebox_2.OptionalNullable)(typebox_1.Type.Enum(types_1.Context)),
    template: (0, typebox_2.OptionalNullable)(reportTemplatesQueryRequest_1.ReportTemplatesQueryRequestDef),
});
const toReportResponse = (report) => {
    return {
        id: report.id,
        accountId: report.accountId,
        workspace_id: report.workspaceId,
        title: report.title,
        description: report.description,
        details: report.details,
        owner: (0, memberResponse_1.toMemberResponse)(report.owner),
        document_groups: (0, documentGroupsResponse_1.toDocumentGroupResponse)(report === null || report === void 0 ? void 0 : report.documentGroups),
        created_by: (0, memberResponse_1.toMemberResponse)(report.createdBy),
        updated_by: (0, memberResponse_1.toMemberResponse)(report.updatedBy),
        type: report.type,
        query: report.query,
        grouping_config: report.groupingConfig,
        created_at: report.systemCreatedAt,
        updated_at: report.systemUpdatedAt,
        context: report.context,
        template: report.template,
    };
};
exports.toReportResponse = toReportResponse;

import { FC } from 'react';
import * as Select from '../../../../components/SelectFilter.tsx';
import { Timeframe, useFilterStore } from '../../store';
import { useFilterContext } from '../../context/useFilterContext';

interface EventTimeframeOperatorSelectProps {
  index: number;
}

const options = [
  { value: 'OVER_ALL_TIME', label: 'over all time' },
  { value: 'IN_THE_LAST', label: 'in the last' },
  { value: 'BEFORE', label: 'before' },
  { value: 'AFTER', label: 'after' },
  { value: 'ABSOLUTE', label: 'between' },
];

export const EventTimeframeOperatorSelect: FC<EventTimeframeOperatorSelectProps> = ({ index }) => {
  const { filterKey } = useFilterContext();
  const filterItem = useFilterStore((state) => state.getFilterItem([...filterKey, index]));
  const setItemEventTimeframeOperator = useFilterStore((state) => state.setItemEventTimeframeOperator);

  const getLabel = (val?: string) => {
    return options.find(({ value }) => val === value)?.label;
  };

  // TODO: Reenable for record events
  // if (!filterItem?.operator) {
  //   return null;
  // }

  // if (filterItem?.operator === RecordQueryFilterOperator.ZERO_TIMES) {
  //   return null;
  // }

  return (
    <Select.Root
      value={filterItem?.timeframe.dateRangeType}
      onValueChange={(v) => setItemEventTimeframeOperator(filterKey, index, v as Timeframe['dateRangeType'])}
    >
      <Select.Trigger placeholder="[timeframe operator]" label={getLabel(filterItem?.timeframe.dateRangeType) ?? ''} />
      {options && (
        <Select.Content>
          {options.map((option) => (
            <Select.Item key={option.value} value={option.value} label={option.label} />
          ))}
        </Select.Content>
      )}
    </Select.Root>
  );
};

import { FC, useEffect } from 'react';
import { RecordsPropertyFilterRow } from './RecordsPropertyFilterRow';
import { FilterItemType, FilterKey, useFilterStore } from '../../store';
import { FilterContext } from '../../context/context';

import { RecordsEventNameFilterRow } from './RecordsEventNameFilterRow';
import { RecordsFilterAdd } from './RecordsFilterAdd';
import { RecordsEventPropertyFilterRow } from './RecordsEventPropertyFilterRow';

import { useObjectQuery } from '../../../data/useObjectQuery';
import { ReportConfigSectionButton } from '../../../../features/reports/components/ReportConfigSectionButton';
import { twMerge } from 'tailwind-merge';

interface RecordsFilter {
  objectSlug: string;
  filterKey: FilterKey;
  workspaceId: string;
  segments?: boolean;
  mode?: 'inline' | 'stacked';
}

export const RecordsFilter: FC<RecordsFilter> = ({ objectSlug, filterKey, workspaceId, segments = false, mode = 'inline' }) => {
  const filter = useFilterStore((state) => state.getFilter(filterKey));
  const createRecordFilter = useFilterStore((state) => state.createRecordFilter);

  useEffect(() => {
    if (filter === null) {
      createRecordFilter(filterKey[1]);
    }
  }, [filter, createRecordFilter, filterKey]);

  return (
    <FilterContext.Provider value={{ filterKey, workspaceId, mode }}>
      <div className="flex items-start justify-between">
        <div className={twMerge('flex max-w-full flex-1 flex-col items-start', mode === 'inline' && 'pb-6')}>
          {!!filter?.items?.length && (
            <div className={twMerge('flex w-full flex-col ', mode === 'inline' && 'gap-y-2')}>
              {filter?.items.map((filterItem, index) => {
                const key = `${JSON.stringify(filterItem.property)}-${index}`;
                switch (filterItem.itemType) {
                  case FilterItemType.RECORDS_PROPERTY:
                    return <RecordsPropertyFilterRow objectSlug={objectSlug} index={index} key={key} filterItem={filterItem} />;
                  case FilterItemType.RECORDS_EVENT_NAME:
                    return <RecordsEventNameFilterRow index={index} objectSlug={objectSlug} key={key} filter={filterItem} />;
                  case FilterItemType.RECORDS_EVENT_PROPERTY:
                    return <RecordsEventPropertyFilterRow index={index} objectSlug={objectSlug} key={key} filter={filterItem} />;
                  default:
                    return null;
                }
              })}
            </div>
          )}
          {mode === 'inline' && (
            <div className="pt-2">
              <RecordsFilterAdd />
            </div>
          )}
          {mode === 'stacked' && (
            <div className="px-2 pt-2">
              <RecordsFilterAdd as={ReportConfigSectionButton} />
            </div>
          )}
        </div>
        {/*<div>{segments && object && filter ? <SaveSegmentButtons objectId={object.id} filter={filter} queryType="RECORDS" /> : null}</div>*/}
      </div>
    </FilterContext.Provider>
  );
};

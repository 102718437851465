import { QueryRecordsCreateData, RelationshipColumnDef } from '@bigdelta/lib-api-client';
import { TableColumnType } from '@bigdelta/lib-shared';

// TODO: fix return type
export const resolvePropertyValue = (
  relationshipProperties: NonNullable<QueryRecordsCreateData['items'][number]['relationships']>['relationship_properties'],
  columnDef: RelationshipColumnDef
) => {
  if (columnDef.type === TableColumnType.PROPERTY && columnDef.property) {
    const relationshipName = columnDef.name;
    const propertyName = columnDef.property.property_name;
    const foundRelationshipObject = relationshipProperties?.find((rel) => rel.relationship_name === relationshipName);

    const relationsProperties = foundRelationshipObject?.relations?.map((relation) => relation.properties?.[propertyName]);

    return relationsProperties;
  }

  if (columnDef.type === TableColumnType.RELATIONSHIP && columnDef.relationship) {
    const relationshipName = columnDef.name;
    const foundRelationshipPropertiesObject = relationshipProperties?.find((rel) => rel.relationship_name === relationshipName);

    const nestedRelationshipDef = columnDef.relationship;

    const value =
      foundRelationshipPropertiesObject?.relations?.map((relation) =>
        resolvePropertyValue(relation.relationship_properties, nestedRelationshipDef)
      ) ?? null;

    return value;
  }
};

import { Handle, Node, NodeProps, Position } from 'reactflow';
import { twMerge } from '../../../utils/twMerge';

import PlusIcon from '../../../assets/icons/plus.svg?react';

export const NodeAutomationBlockEmptyType = 'automation-block-empty';

interface NodeAutomationBlockEmptyData {}

export type NodeAutomationBlockEmpty = Node<NodeAutomationBlockEmptyData>;

export const NodeAutomationBlockEmpty = ({ selected }: NodeProps<NodeAutomationBlockEmptyData>) => {
  return (
    <div
      className={twMerge(
        'group/node flex w-72 flex-col gap-y-3 rounded-2xl  border-2 bg-m-white p-5 shadow-md',
        selected ? 'border-m-lime-700' : 'border-m-olive-100'
      )}
    >
      <Handle position={Position.Top} type="target" />
      <div className="flex items-center justify-center p-5 text-sm font-regular text-m-gray-700">
        <PlusIcon className="h-6 w-6" />
      </div>
      <Handle position={Position.Top} type="source" />
    </div>
  );
};

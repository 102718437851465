import { FC, useMemo, useRef } from 'react';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { QueryEventsCreateData } from '@bigdelta/lib-api-client';
import { useEventsInfiniteQuery } from '../data/useEventsInfiniteQuery';
import { useFetchMoreOnBottomReached } from '../hooks/useFetchMoreOnBottomReached';
import { FilterItem, FilterItemType, getFilter, useFilterStore } from '../../../shared/filters/store';
import { useEventsMetadataPropertiesNamesQuery } from '../../../shared/data/useEventsMetadataPropertiesNamesQuery';
import { useEventsTableColumns } from '../hooks/useEventsTableColumns';
import { DataTable } from '../../../shared/tables/components/DataTable';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { Button } from '../../../shared/ui/Button/Button';
import { DateRangeType } from '@bigdelta/lib-shared';
import { DataTableEmpty, DataTableEmptyProps } from '../../../shared/tables/components/DataTableEmpty';
import { NO_EVENTS_TABLE_EMPTY } from '../../../shared/tables/common';

enum EmptyStateType {
  NoEvents = 'no-events',
  NoEventsMatchingFilter = 'no-events-matching-filter',
  Error = 'error',
}

const columnHelper = createColumnHelper<QueryEventsCreateData['items'][number]>();
interface EventsTableProps {
  className?: string;
}

const objectFilterItemsSelector = (state) => getFilter(state, ['events', 'object']);
const eventFilterItemsSelector = (state) => getFilter(state, ['events', 'event']);

export const EventsTable: FC<EventsTableProps> = () => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const objectFilterItems = useFilterStore(objectFilterItemsSelector);
  const eventFilterItems = useFilterStore(eventFilterItemsSelector);

  const clearFilter = useFilterStore((state) => state.clearFilter);

  const isOverAllTimeFilter = (filterItem?: FilterItem) => filterItem?.timeframe.dateRangeType === DateRangeType.OVER_ALL_TIME;

  const isEventsFilterEmpty =
    eventFilterItems?.items.length === 0 ||
    (eventFilterItems?.items.length === 1 &&
      eventFilterItems?.items[0].itemType === FilterItemType.EVENTS_TIMEFRAME &&
      isOverAllTimeFilter(eventFilterItems?.items[0]));

  const hasEventsFilter = !isEventsFilterEmpty;
  const hasObjectFilter = !!objectFilterItems?.items.find((item) => item.itemType === FilterItemType.EVENTS_OBJECT)?.propertyRelationships[0]
    ?.objectId;

  const hasFilter = hasEventsFilter || hasObjectFilter;

  const { currentWorkspaceId } = useWorkspace();

  const eventsQuery = useEventsInfiniteQuery({
    workspaceId: currentWorkspaceId,
    objectFilterItems,
    eventFilterItems,
  });

  const totalEventsCount = eventsQuery.data?.pages?.[0]?.totalEventsCount;

  const flatEvents = useMemo(() => (eventsQuery.data ? eventsQuery.data.pages.flatMap((d) => d.items) : []), [eventsQuery.data]);

  const resourceId = objectFilterItems?.items.find((item) => item.itemType === FilterItemType.EVENTS_NAME)?.data?.value as string | undefined;

  const eventsPropertyNamesQuery = useEventsMetadataPropertiesNamesQuery({ workspaceId: currentWorkspaceId, resourceId });

  const columns = useEventsTableColumns({
    metadataAttributeNameItems: eventsPropertyNamesQuery.data?.items,
    columnHelper: columnHelper,
  });

  const emptyStates: Record<EmptyStateType, DataTableEmptyProps> = {
    [EmptyStateType.NoEvents]: NO_EVENTS_TABLE_EMPTY,
    [EmptyStateType.NoEventsMatchingFilter]: {
      heading: 'No events match set filters',
      description: 'Looks like no events in your workspace match the filter that’s been set.',
      actionSlot: (
        <Button
          intent="primary"
          label="Clear filters"
          size="sm"
          onClick={() => {
            clearFilter(['events', 'object']);
            clearFilter(['events', 'event']);
          }}
        />
      ),
    },
    [EmptyStateType.Error]: {
      heading: 'There was problem loading data. Please try again.',
      actionSlot: <Button intent="primary" label="Reload" size="sm" onClick={() => window.location.reload()} />,
    },
  };

  const fetchMoreOnBottomReached = useFetchMoreOnBottomReached({
    fetchNextPage: eventsQuery.fetchNextPage,
    isFetching: eventsQuery.isFetching,
    events: eventsQuery.data,
    tableContainerRef,
  });

  const columnPinning = useMemo(() => {
    return {
      right: [],
      left: ['event_name'],
    };
  }, []);

  const table = useReactTable({
    columns,
    data: flatEvents,
    defaultColumn: {
      enableResizing: true,
      enableSorting: false,
    },
    state: {
      columnPinning,
    },
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: 'onChange',
  });

  const getEmptyStateType = () => {
    if (eventsQuery.isFetching || eventsPropertyNamesQuery.isFetching) {
      return;
    }

    if (eventsQuery.isError || eventsPropertyNamesQuery.isError) {
      return EmptyStateType.Error;
    }

    if (!flatEvents.length && totalEventsCount === 0) {
      return EmptyStateType.NoEvents;
    }

    if (!flatEvents.length && hasFilter) {
      return EmptyStateType.NoEventsMatchingFilter;
    }

    return;
  };

  const emptyStateType = getEmptyStateType();
  const emptyStateData = emptyStateType && emptyStates[emptyStateType];

  return (
    <div className="relative flex-1 overflow-x-auto" ref={tableContainerRef} onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}>
      <DataTable
        isDataFetching={eventsQuery.isFetching}
        isHeaderFetching={eventsPropertyNamesQuery.isFetching}
        tableContainerRef={tableContainerRef}
        table={table}
      />
      {emptyStateData && (
        <DataTableEmpty heading={emptyStateData.heading} description={emptyStateData.description} actionSlot={emptyStateData.actionSlot} />
      )}
    </div>
  );
};

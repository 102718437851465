import { Dialog } from '@headlessui/react';
import XCloseIcon from '../../../assets/icons/x-close.svg?react';
import { FC } from 'react';
import { Button } from '../../../shared/ui/Button/Button';

interface IntegrationModalProps {
  open: boolean;
  onClose: () => void;
  Component?: FC<{ close: () => void }>;
}

export const IntegrationModal: FC<IntegrationModalProps> = ({ open, onClose, Component }) => {
  return (
    <Dialog open={open} onClose={onClose} className="relative z-[999999]">
      <div className="fixed inset-0 bg-m-gray-700 opacity-95" aria-hidden="true" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="min-w-2/5 mx-auto flex max-h-full max-w-7xl flex-col items-center overflow-y-auto rounded-xl bg-white p-1">
          <div className="flex w-full justify-end">
            <Button intent="tertiary" size="xl" leadingIcon={XCloseIcon} onClick={onClose} equalPadding />
          </div>
          {Component && <Component close={onClose} />}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

import { useContext } from 'react';
import {AccountContext, AccountContextType} from "../context/accountContext.tsx";

export const useAccount = (): AccountContextType => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error('useAccountContext must be used within a AccountProvider');
  }
  return context;
};

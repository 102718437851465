import { FC, PropsWithChildren } from 'react';

interface NavItemGroupProps extends PropsWithChildren {
  heading?: string;
}

export const NavItemGroup: FC<NavItemGroupProps> = ({ heading, children }) => (
  <div className="flex flex-col">
    {heading && <div className="select-none px-2 pb-2 pt-4 text-xs font-medium text-m-gray-600">{heading}</div>}
    <ul className="flex flex-col items-stretch gap-y-px">{children}</ul>
  </div>
);

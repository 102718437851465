import { FC } from 'react';
import { useFilterContext } from '../../context/useFilterContext';
import { ALL_EVENTS, FilterItem, useFilterStore } from '../../store';
import { EventSelect } from '../common/EventSelect';
import { RowContainer } from '../common/RowContainer';
import { EventPropertySelect } from '../common/EventPropertySelect';
import { PropertyOperatorSelect } from '../common/PropertyOperatorSelect';
import { PropertyValue } from '../common/PropertyValue';
import { Control } from '../common/Control';
import { Group } from '../common/Group';
import { MetadataResourcePropertyType } from '@bigdelta/lib-shared';

interface EventsPropertyFilterRowProps {
  index: number;
  filter: FilterItem;
}

export const EventsPropertyFilterRow: FC<EventsPropertyFilterRowProps> = ({ index, filter }) => {
  const { filterKey } = useFilterContext();

  const removeItem = useFilterStore((state) => state.removeItem);
  const setItemPropertyOperator = useFilterStore((state) => state.setItemPropertyOperator);

  return (
    <RowContainer onRemove={() => removeItem(filterKey, index)}>
      <Group>
        <Control>
          <EventSelect index={index} filterDataItem={filter} includeAny />
        </Control>
        {filter.event && (
          <Control>
            <EventPropertySelect index={index} filterItem={filter} />
          </Control>
        )}
      </Group>
      {filter.property && (
        <Group>
          <Control shouldShrink={false}>
            <PropertyOperatorSelect
              index={index}
              value={filter.propertyOperator}
              onChange={(index, value) => setItemPropertyOperator(filterKey, index, value)}
            />
          </Control>
          {filter.propertyOperator && (
            <Control>
              <PropertyValue
                index={index}
                resourceId={filter.event !== ALL_EVENTS ? filter.event : undefined}
                resourceType={MetadataResourcePropertyType.EVENT}
              />
            </Control>
          )}
        </Group>
      )}
    </RowContainer>
  );
};

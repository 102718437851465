import { useWorkspace } from './useWorkspace.tsx';
import { useMemo } from 'react';
import queryKeysFactory from '../../../utils/queryKeys.ts';
import { useAccount } from './useAccount.tsx';

export const useQueryKeys = () => {
  const { currentAccountId } = useAccount();
  const { currentWorkspaceId } = useWorkspace();
  return useMemo(() => queryKeysFactory(currentAccountId, currentWorkspaceId), [currentAccountId, currentWorkspaceId]);
};

import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { Button } from '../../../shared/ui/Button/Button.tsx';

export interface SaveAutomationFormFields {
  name: string;
}

interface SaveAutomationModalProps {
  open: boolean;
  onSumbit: (data: SaveAutomationFormFields) => void;
  onClose: () => void;
}

export const SaveAutomationModal: React.FC<SaveAutomationModalProps> = ({ open, onSumbit: onSubmitProps, onClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<SaveAutomationFormFields>();

  const onSubmit = handleSubmit((data) => {
    onSubmitProps(data);
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <>
      {/* {disabled && <Tooltip id="save-report-tooltip" className="z-10" />} */}
      <Dialog open={open} onClose={handleClose} className="relative z-[999999]">
        <div className="fixed inset-0 bg-m-gray-700 opacity-95" aria-hidden="true" />
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <Dialog.Panel className="mx-auto flex w-1/3 max-w-md flex-col gap-y-6 rounded-xl bg-white p-6">
            <Dialog.Title className="text-xl text-m-olive-700">Save Automation</Dialog.Title>
            <form onSubmit={onSubmit} className="flex flex-col gap-y-6">
              <div className="flex flex-col gap-y-1.5">
                <label htmlFor="segment_name" className="text-md text-m-olive-700">
                  Name
                </label>
                <input
                  className={twMerge('rounded-lg border border-m-gray-300 px-3 py-2', errors.name && 'border-m-red-300')}
                  {...register('name', { required: true })}
                />
              </div>
              <div className="flex justify-end gap-x-3.5">
                <Button label="Cancel" intent="secondary" size="xl" onClick={handleClose} type="button" />
                <Button label="Save" intent="brand" size="xl" type="submit" />
              </div>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

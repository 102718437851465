"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IngestionResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.IngestionResponseDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        status: typebox_1.Type.Number(),
        success: typebox_1.Type.Boolean(),
    }),
]);

import { RelationshipsListData } from '@bigdelta/lib-api-client';
import { RelationshipEntityType } from '@bigdelta/lib-shared';

interface GetObjectsRelatedToEventArgs {
  relationships: RelationshipsListData['relationships'];
}

interface RelationshipObjectData {
  relationshipName: string | null;
  objectId: string;
  objectWorkspaceId: string;
}

export const getObjectsRelatedToEvent = ({ relationships }: GetObjectsRelatedToEventArgs) => {
  const objectRelMap = relationships.reduce(
    (acc, item) => {
      if (item.first_entity_type !== RelationshipEntityType.EVENT && item.second_entity_type !== RelationshipEntityType.EVENT) {
        return acc;
      }
      if (
        !!item.first_entity_id &&
        item.first_entity_type === RelationshipEntityType.OBJECT &&
        item.second_entity_type === RelationshipEntityType.EVENT
      ) {
        return {
          ...acc,
          [item.first_entity_id]: {
            relationshipName: item.name,
            objectId: item.first_entity_id,
            objectWorkspaceId: item.first_entity_workspace_id,
          },
        };
      }
      if (
        !!item.second_entity_id &&
        item.second_entity_type === RelationshipEntityType.OBJECT &&
        item.first_entity_type === RelationshipEntityType.EVENT
      ) {
        return {
          ...acc,
          [item.second_entity_id]: {
            relationshipName: item.name,
            objectId: item.second_entity_id,
            objectWorkspaceId: item.second_entity_workspace_id,
          },
        };
      }
      return acc;
    },
    {} as { [key: string]: RelationshipObjectData }
  );
  return Object.values(objectRelMap).map(({ relationshipName, objectId, objectWorkspaceId }) => ({
    relationshipName,
    objectId,
    objectWorkspaceId,
  }));
};

import { useQuery } from '@tanstack/react-query';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes, RecordsRoutes } from '../../../routes';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { suppressConsoleWarn } from '../../../shared/utils/suppressConsoleWarn';
import { capitalize } from 'lodash';
import { ToolsTable } from '../../../shared/components/ToolsTable';
import { Container } from '../../../shared/ui/Container/Container';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

const columnHelper = createColumnHelper<DataListItem[][number]>();

enum DataListItemType {
  OBJECT = 'record',
  EVENT = 'event',
}

interface DataListItem {
  id: string;
  name: string;
  type: DataListItemType;
}

export const DataListPage = () => {
  const navigate = useNavigate();
  const queryKeys = useQueryKeys();
  const { currentWorkspaceId } = useWorkspace();

  const objectsQuery = useQuery({
    queryKey: queryKeys.list('object'),
    queryFn: () => bigdeltaAPIClient.v1.objectsList({ workspace_id: currentWorkspaceId }),
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        header: 'Data',
        cell: (props) => {
          let value;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          return capitalize(value);
        },
      }),
    ],
    []
  );

  const table = useReactTable({
    columns,
    data: [
      ...(objectsQuery.data?.objects || []).map((o) => ({
        id: o.api_slug,
        name: o.plural_noun,
        type: DataListItemType.OBJECT,
      })),
      {
        id: 'events',
        name: 'Events',
        type: DataListItemType.EVENT,
      },
    ],
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id,
  });

  return (
    <Container className="py-5">
      <ToolsTable
        data={objectsQuery.data}
        onRowClick={(row) => {
          if (row.type === DataListItemType.OBJECT) {
            navigate(`${RecordsRoutes.INDEX}/${row.id}`);
          } else if (row.type === DataListItemType.EVENT) {
            navigate(AppRoutes.EVENTS);
          }
        }}
        table={table}
      />
    </Container>
  );
};

import { useNodes } from 'reactflow';
import { CustomNodeData } from '../types';
import { isNodeAutomationTrigger } from '../utils/graphNodeTypeguards';
import { NodeAutomationTrigger } from '../components/NodeAutomationTrigger';

export const useTriggerNode = () => {
  const nodes = useNodes<CustomNodeData>();

  return nodes.find((node): node is NodeAutomationTrigger => isNodeAutomationTrigger(node));
};

import React, { useState } from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { Button } from '../../../../shared/ui/Button/Button';
import { createSearchParams, Navigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { toastError } from '../../../../utils/toast';
import { BusinessModel, ErrorResponse } from '@bigdelta/lib-shared';
import { AxiosError } from 'axios';
import { bigdeltaAPIClient } from '../../../../client/bigdeltaAPIClient';
import { AccountsWorkspacesOnboardingCreatePayload } from '@bigdelta/lib-api-client';
import { useUser } from '../../../auth/hooks/useUser';
import { Step4TrackCustomers } from '../components/Step4TrackCustomers';
import { Step2DescribeCustomers } from '../components/Step2DescribeCustomers';
import { Step3DescribeBuyers } from '../components/Step3DescribeBuyers';
import { WorkspaceOnboardingFormFields } from '../types';
import { Step1CreateWorkspace } from '../components/Step1CreateWorkspace';
import { storeSelectedAccountId } from '../../../../shared/utils/selectedAccountId';
import { storeSelectedWorkspaceId } from '../../../../shared/utils/selectedWorkspaceId';

export const WorkspaceOnboarding: React.FC = () => {
  const [step, setStep] = useState(1);
  const [trackingKey, setTrackingKey] = useState<string | null>(null);
  const { fetchUser, accounts, workspaces } = useUser();

  const { accountId } = useParams();

  const selectedAccount = accounts?.find((a) => a.id === accountId);

  const methods = useForm<WorkspaceOnboardingFormFields>({
    defaultValues: {
      name: selectedAccount?.name && workspaces.length === 0 ? selectedAccount.name : '',
      industries: [],
      roles: [],
    },
    mode: 'onChange',
    resolver: (values) => {
      const errors: FieldErrors<WorkspaceOnboardingFormFields> = {};

      if (!values.name) {
        errors.name = { type: 'required', message: 'Workspace name is required' };
      }

      if (values.industries.length === 0) {
        errors.industries = { type: 'required', message: 'At least one industry must be selected' };
      }

      if (values.roles.length === 0) {
        errors.roles = { type: 'required', message: 'At least one role must be selected' };
      }

      return {
        values,
        errors,
      };
    },
  });

  const mutation = useMutation({
    mutationFn: (payload: AccountsWorkspacesOnboardingCreatePayload) =>
      bigdeltaAPIClient.v1.accountsWorkspacesOnboardingCreate(selectedAccount?.id ?? accountId ?? '', payload),
    onSuccess: async (data) => {
      setTrackingKey(data.tracking_key ?? null);
      await fetchUser?.();
      storeSelectedAccountId(selectedAccount?.id ?? accountId ?? '');
      data.id && storeSelectedWorkspaceId(data.id);
      setStep(step + 1);
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toastError(error.response?.data?.message ?? '');
    },
  });

  const onSubmit = async (data: WorkspaceOnboardingFormFields) => {
    mutation.mutate({
      name: data.name,
      business_model: BusinessModel.B2B_SAAS,
      roles: data.roles,
      industries: data.industries,
    });
  };

  const handleNext = async () => {
    let fieldsToValidate: ('name' | 'industries' | 'roles')[] = [];
    switch (step) {
      case 1:
        fieldsToValidate = ['name'];
        break;
      case 2:
        fieldsToValidate = ['industries'];
        break;
      case 3:
        fieldsToValidate = ['roles'];
        break;
    }

    const isStepValid = await methods.trigger(fieldsToValidate);

    if (isStepValid) {
      if (step < 3) {
        setStep(step + 1);
      } else if (step === 3) {
        methods.handleSubmit(onSubmit)();
      }
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <Step1CreateWorkspace />;
      case 2:
        return <Step2DescribeCustomers />;
      case 3:
        return <Step3DescribeBuyers />;
      case 4:
        return <Step4TrackCustomers trackingKey={trackingKey} />;
      default:
        return null;
    }
  };

  const getStepImage = () => {
    switch (step) {
      case 1:
        return '/onboarding/workspace_name.png';
      case 2:
        return '/onboarding/companies.png';
      case 3:
        return '/onboarding/people.png';
      case 4:
        return '/onboarding/events.png';
      default:
        return '';
    }
  };

  if (step === 5) {
    return <Navigate to={{ pathname: '/records/companies', search: createSearchParams({ onboarding: 'true' }).toString() }} />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="flex min-h-screen flex-col bg-m-coconut-100">
        <main className="flex flex-grow">
          <div className="flex w-1/2 flex-col justify-center p-8">
            <div className="mx-auto max-w-md">
              {renderStep()}
              <div className="mt-8 flex justify-between">
                {step > 1 && step < 4 && <Button intent="tertiary" size="md" label="Back" onClick={() => setStep(step - 1)} type="button" />}
                {step < 4 && <Button intent="primary" size="md" label="Next" onClick={handleNext} type="button" disabled={mutation.isLoading} />}
                {step === 4 && <Button intent="primary" size="md" label="Skip for now" onClick={() => setStep(step + 1)} type="button" />}
              </div>
            </div>
          </div>
          <div className="flex w-1/2 items-center justify-center bg-m-gray-200 p-8 shadow-[inset_20px_0px_40px_-15px_rgba(0,0,0,0.1)]">
            <img src={getStepImage()} alt={`Step ${step} Illustration`} className="w-full max-w-[80rem] rounded-lg object-contain shadow-lg" />
          </div>
        </main>
      </form>
    </FormProvider>
  );
};

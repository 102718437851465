import { useQuery } from '@tanstack/react-query';

import { useQueryKeys } from '../../features/auth/hooks/useQueryKeys.ts';
import { useWorkspace } from '../../features/auth/hooks/useWorkspace.tsx';
import { bigdeltaAPIClient } from '../../client/bigdeltaAPIClient.ts';

interface UseReportQueryParameters {
  reportId?: string;
}

export const useReportQuery = ({ reportId }: UseReportQueryParameters) => {
  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();

  return useQuery({
    queryKey: queryKeys.report(reportId),
    queryFn: () => bigdeltaAPIClient.v1.reportsDetail(reportId ?? '', { workspace_id: currentWorkspaceId }),
    enabled: !!reportId,
    staleTime: Infinity,
  });
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toProjectVO = exports.CreateProjectRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
exports.CreateProjectRequestDef = typebox_1.Type.Object({
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    title: typebox_1.Type.Optional(typebox_1.Type.String()),
    description: typebox_1.Type.Optional(typebox_1.Type.String()),
    details: typebox_1.Type.Optional(typebox_1.Type.String()),
    owner: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
    document_group_ids: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(typebox_1.Type.String({ format: 'uuid' }))),
    project_metric: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
    project_ambition: typebox_1.Type.Optional(typebox_1.Type.String()),
    impact_metric: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
    impact_size: typebox_1.Type.Optional(typebox_1.Type.String()),
    solutions_and_experiments: typebox_1.Type.Optional(typebox_1.Type.String()),
});
const toProjectVO = (project) => {
    return {
        workspaceId: project.workspace_id,
        title: project.title,
        description: project.description,
        details: project.details,
        owner: project.owner,
        documentGroupIds: project.document_group_ids,
        projectMetric: project.project_metric,
        projectAmbition: project.project_ambition,
        impactMetric: project.impact_metric,
        impactSize: project.impact_size,
        solutionsAndExperiments: project.solutions_and_experiments,
    };
};
exports.toProjectVO = toProjectVO;

import { DashboardsListData, ReportsListData } from '@bigdelta/lib-api-client';
import { createColumnHelper, useReactTable, getCoreRowModel, RowSelectionState } from '@tanstack/react-table';
import React, { FC, MouseEvent, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ToolsTable } from '../../../shared/components/ToolsTable';

import LineChartUpIcon from '../../../assets/icons/line-chart-up-02.svg?react';
import { format, parseISO } from 'date-fns';
import { suppressConsoleWarn } from '../../../shared/utils/suppressConsoleWarn';
import { isUndefined } from 'lodash';
import DeleteIcon from '../../../assets/icons/trash-01.svg?react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';

import { useWorkspace } from '../../auth/hooks/useWorkspace.tsx';
import { Dialog } from '@headlessui/react';
import { Button } from '../../../shared/ui/Button/Button.tsx';
import { CenteredLoader } from '../../../shared/components/CenteredLoader.tsx';
import { DashboardsRoutes } from '../../../routes';
import { UserTableCell } from '../../../shared/ui/UserTableCell/UserTableCell.tsx';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

const columnHelper = createColumnHelper<ReportsListData['items'][number]>();

interface ReportsTableProps {
  data?: ReportsListData['items'];
  onRowSelected?: (selectedRows: string[]) => void;
  showActions?: boolean;
}

export const ReportsTable: FC<ReportsTableProps> = ({ data, onRowSelected, showActions }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const queryKeys = useQueryKeys();
  const { currentWorkspaceId } = useWorkspace();

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [targetedReportId, setTargetedReportId] = useState('');

  const mutation = useMutation({
    mutationFn: (reportId: string) => bigdeltaAPIClient.v1.reportsDelete(reportId, { workspace_id: currentWorkspaceId }),
    onSuccess: () => {
      setIsDeleteConfirmationOpen(false);
      queryClient.invalidateQueries([...queryKeys.dashboards(), 'reportId', targetedReportId]);
      return queryClient.invalidateQueries(queryKeys.reports());
    },
  });

  const handleDeleteRequested = (event: MouseEvent<HTMLButtonElement>, reportId: string) => {
    event.stopPropagation();
    setTargetedReportId(reportId);
    setIsDeleteConfirmationOpen(true);
  };

  const dashboardsQuery = useQuery({
    queryKey: [...queryKeys.dashboards(), 'reportId', targetedReportId],
    queryFn: () => bigdeltaAPIClient.v1.dashboardsList({ workspace_id: currentWorkspaceId, contains_report_id: targetedReportId }),
  });

  const handleRowClick = (data: any) => {
    navigate(`/reports/${data.id}`);
  };

  const columnsList = [
    columnHelper.accessor('title', {
      id: 'title',
      header: 'Title',
      cell: (props) => {
        let value;
        suppressConsoleWarn(() => {
          value = props.getValue();
        });

        return value;
      },
    }),
    columnHelper.accessor('type', {
      id: 'type',
      header: 'Type',
      cell: (props) => {
        let value;
        suppressConsoleWarn(() => {
          value = props.getValue();
        });

        if (!value) {
          return '';
        }

        return (
          <div className="flex items-center gap-x-2">
            <LineChartUpIcon className="h-4 w-4 text-m-olive-400" />
            <span className="lowercase first-letter:uppercase">{value}</span>
          </div>
        );
      },
    }),
    columnHelper.accessor('owner', {
      id: 'owner',
      header: 'Owner',
      cell: (props) => {
        let value: ReportsListData['items'][number]['owner'] | undefined;
        suppressConsoleWarn(() => {
          value = props.getValue();
        });

        if (!value) {
          return '';
        }

        return <UserTableCell email={value.email} />;
      },
    }),
    columnHelper.accessor('created_at', {
      id: 'created_at',
      header: 'Date created',
      cell: (props) => {
        let value;
        suppressConsoleWarn(() => {
          value = props.getValue();
        });

        if (!value) {
          return '';
        }

        return format(parseISO(value), 'dd/MM/yyyy');
      },
    }),
    columnHelper.accessor('created_by', {
      id: 'created_by',
      header: 'Created by',
      cell: (props) => {
        let value: DashboardsListData['items'][number]['created_by'] | undefined;
        suppressConsoleWarn(() => {
          value = props.getValue();
        });

        if (!value) {
          return '';
        }

        return <UserTableCell email={value.email} />;
      },
    }),
    columnHelper.accessor('updated_at', {
      id: 'updated_at',
      header: 'Date updated',
      cell: (props) => {
        let value;
        suppressConsoleWarn(() => {
          value = props.getValue();
        });

        if (!value) {
          return '';
        }

        return format(parseISO(value), 'dd/MM/yyyy');
      },
    }),
    columnHelper.accessor('updated_by', {
      id: 'updated_by',
      header: 'Updated by',
      cell: (props) => {
        let value: DashboardsListData['items'][number]['updated_by'] | undefined;
        suppressConsoleWarn(() => {
          value = props.getValue();
        });

        if (!value) {
          return '';
        }

        return <UserTableCell email={value.email} />;
      },
    }),
  ];

  if (showActions) {
    columnsList.push(
      columnHelper.display({
        id: 'actions',
        header: 'Actions',
        cell: (context) => {
          return (
            <button onClick={(event) => handleDeleteRequested(event, context.row.id)}>
              <DeleteIcon className="h-5 w-5 cursor-pointer text-m-red-600 hover:text-m-red-700" />
            </button>
          );
        },
      })
    );
  }

  const columns = useMemo(() => columnsList, [columnsList]);

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const table = useReactTable({
    columns,
    data: data ?? [],
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
    getRowId: (row) => row.id,
  });

  useEffect(() => {
    const selectedRows: string[] = [];

    for (const [id, isSelected] of Object.entries(rowSelection)) {
      if (isSelected) {
        selectedRows.push(id);
      }
    }

    onRowSelected && onRowSelected(selectedRows);
  }, [onRowSelected, rowSelection]);

  return (
    <>
      <ToolsTable data={data} onRowClick={handleRowClick} table={table} enableSelection={!isUndefined(onRowSelected)} />
      <Dialog open={isDeleteConfirmationOpen} onClose={() => setIsDeleteConfirmationOpen(false)} className="relative z-[999999]">
        <div className="fixed inset-0 bg-m-gray-700 opacity-95" aria-hidden="true" />
        <div className="fixed inset-0 flex w-screen items-center justify-center">
          {!dashboardsQuery.isLoading && (
            <Dialog.Panel className="mx-auto flex w-3/5 max-w-lg flex-col gap-y-6 rounded-xl bg-white">
              <Dialog.Title className="p-6 pb-0 text-xl text-m-olive-700">Please Confirm</Dialog.Title>
              {dashboardsQuery.data?.range.total > 0 && (
                <>
                  <div className="px-6 text-sm">
                    Are you sure you want to delete this report? This action will also remove the report from the dashboards.
                  </div>
                  <div className="px-6 text-sm font-medium">
                    Dashboards affected ({dashboardsQuery.data?.range.total}):{' '}
                    {dashboardsQuery.data?.items.map((i, index) => (
                      <>
                        {index !== 0 && <>, </>}
                        <Link className="font-regular underline" target="_blank" to={DashboardsRoutes.INDEX + '/' + i.id}>
                          {i.name}
                        </Link>
                      </>
                    ))}
                  </div>
                </>
              )}
              {dashboardsQuery.data?.range.total === 0 && <div className="px-6 text-sm">Are you sure you want to delete this report?</div>}
              <div className="flex justify-end gap-x-2 px-6 pb-6">
                <Button size="sm" intent="tertiary" onClick={() => setIsDeleteConfirmationOpen(false)} label="Cancel" />
                <Button size="sm" intent="destructive" onClick={() => mutation.mutate(targetedReportId)} label="Delete" />
              </div>
            </Dialog.Panel>
          )}
          {dashboardsQuery.isLoading && <CenteredLoader />}
        </div>
      </Dialog>
    </>
  );
};

import { QueryValueFilterOperator } from '@bigdelta/lib-shared';
import { FilterItem, FilterItemType } from '../filters/store';
import { ObjectsDetailData, RecordQueryFiltersRequestDef } from '@bigdelta/lib-api-client';

export const getEventObjectFilterItem = (
  condition: RecordQueryFiltersRequestDef['conditions'][number],
  relationshipName: string,
  object: ObjectsDetailData
): FilterItem | null => {
  if (!condition || !('record_property' in condition) || !condition.record_property?.name) {
    return null;
  }

  return {
    itemType: FilterItemType.EVENTS_OBJECT,
    data: { value: undefined, valueType: undefined },
    propertyOperator: QueryValueFilterOperator.EQUALS,
    timeframe: {},
    property: {
      attributeId: condition.record_property.property_id as string,
      attributeName: condition.record_property.name,
      attributeType: condition.record_property.property_type as string,
    },
    propertyRelationships: [
      {
        relationshipName: relationshipName,
        objectId: object.id,
        objectWorkspaceId: object.workspace_id,
      },
    ],
  };
};

import * as Popover from '@radix-ui/react-popover';
import * as Tabs from '../../../../shared/ui/Tabs/Tabs';
import { DataAddButton } from '../common/DataAddButton';

import { v4 as uuid } from 'uuid';

import PlusIcon from '../../../../assets/icons/plus.svg?react';
import ListIcon from '../../../../assets/icons/list.svg?react';
import CalculatorIcon from '../../../../assets/icons/calculator.svg?react';
import { Combobox } from '../../../../components/Combobox';
import { useQueryKeys } from '../../../auth/hooks/useQueryKeys';
import { useQuery } from '@tanstack/react-query';

import { useWorkspace } from '../../../auth/hooks/useWorkspace';
import { capitalize } from 'lodash';
import { useRef, useState } from 'react';
import { ObjectIcon } from '../common/ObjectIcon';
import { MetricMath, WorkspaceObjectType } from '@bigdelta/lib-shared';
import { MetricsListData, ObjectsListData } from '@bigdelta/lib-api-client';
import { useReportStore } from '../../store/Report';
import { TrendsBuilderType } from '../../store/TrendsBuilder';
import { bigdeltaAPIClient } from '../../../../client/bigdeltaAPIClient.ts';
import { ObjectCountType } from '../../store/common';


export const DataSelect = () => {
  const {
    addTrendsBuilder,
    report: {
      trends: { builders },
    },
  } = useReportStore();

  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();

  const objectsQuery = useQuery({
    queryKey: queryKeys.list('object', 'active_and_virtual'),
    queryFn: () => bigdeltaAPIClient.v1.objectsList({ workspace_id: currentWorkspaceId, status: 'ACTIVE_AND_VIRTUAL' }),
  });

  const metricsQuery = useQuery({
    queryKey: queryKeys.list('metric'),
    queryFn: () => bigdeltaAPIClient.v1.metricsList({ workspace_id: currentWorkspaceId }),
  });

  const [activeTab, setActiveTab] = useState<'objects' | 'metrics' | string>('objects');
  const tabsRef = useRef<HTMLDivElement | null>(null);
  const popoverCloseRef = useRef<HTMLButtonElement | null>(null);

  const getName = () => {
    return builders ? String.fromCharCode(builders.length + 65) : 'A';
  };

  const onSelectMetric = (metric: MetricsListData['items'][number] | null) => {
    if (metric === null) {
      return;
    }

    addTrendsBuilder({
      id: uuid(),
      type: TrendsBuilderType.METRIC,
      label: metric.display_name,
      name: getName(),
      data: {
        metric: {
          metricId: metric.id ?? undefined,
        },
      },
    });
    popoverCloseRef.current?.click();
  };

  const onSelectObject = (object: ObjectsListData['objects'][number] | null) => {
    if (object === null) {
      return;
    }

    addTrendsBuilder({
      id: uuid(),
      type: TrendsBuilderType.OBJECT,
      name: getName(),
      data: {
        object: {
          workspaceObjectId: object.id,
          count: {
            type: ObjectCountType.RECORD,
            aggregate: MetricMath.TOTAL,
            record: {
              type: 'total',
              data: {},
            },
          },
        },
      },
    });
    popoverCloseRef.current?.click();
  };

  return (
    <Popover.Root defaultOpen={builders.length === 0}>
      <Popover.Trigger asChild>
        <DataAddButton>
          <PlusIcon className="h-4 w-4 text-m-olive-400" />
        </DataAddButton>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          align="end"
          className="w-[22rem] overflow-hidden rounded-lg border border-m-olive-100 bg-m-white shadow-md"
          sideOffset={8}
          onOpenAutoFocus={() => {
            setTimeout(() => tabsRef?.current?.focus());
          }}
        >
          <Popover.Close ref={popoverCloseRef} className="hidden" />
          <Tabs.Root value={activeTab} onValueChange={setActiveTab}>
            <Tabs.List className="flex shrink-0 border-b border-b-m-olive-100 px-2" ref={tabsRef}>
              <Tabs.Trigger value="objects">
                <ListIcon className="h-4 w-4 shrink-0 text-m-olive-400" />
                <span className="whitespace-nowrap">Objects</span>
              </Tabs.Trigger>
              <Tabs.Trigger value="metrics">
                <CalculatorIcon className="h-4 w-4 shrink-0 text-m-olive-400" />
                <span className="whitespace-nowrap">Saved Metrics</span>
              </Tabs.Trigger>
            </Tabs.List>

            <Tabs.Content value="objects" className="flex grow justify-center rounded-lg outline-none" tabIndex={-1}>
              <Combobox
                items={objectsQuery.data?.objects ?? []}
                height={300}
                renderOption={(object) => (
                  <div className="flex items-center gap-x-1 text-sm font-regular">
                    <ObjectIcon objectType={WorkspaceObjectType[object.object_type]} />
                    {capitalize(object.plural_noun)}
                  </div>
                )}
                className="w-full gap-y-2 border-0 p-2"
                catchInputFocus={false}
                onChange={onSelectObject}
                filterCompare={(objectItem, search) => {
                  if (!search) {
                    return true;
                  }

                  if (!objectItem) return false;

                  if (objectItem.plural_noun.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
                    return true;
                  }

                  return false;
                }}
              />
            </Tabs.Content>
            <Tabs.Content value="metrics" className="flex grow justify-center outline-none">
              <Combobox
                items={metricsQuery.data?.items ?? []}
                height={300}
                renderOption={(metric) => (
                  <div className="flex items-center gap-x-1 text-sm font-regular">
                    <CalculatorIcon className="h-4 w-4 text-m-olive-400" />
                    {capitalize(metric.display_name)}
                  </div>
                )}
                className="w-full gap-y-2 border-0 p-2"
                catchInputFocus={false}
                onChange={onSelectMetric}
                filterCompare={(metricItem, search) => {
                  if (!search) {
                    return true;
                  }

                  if (!metricItem) {
                    return false;
                  }

                  if (metricItem.display_name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
                    return true;
                  }

                  return false;
                }}
              />
            </Tabs.Content>
          </Tabs.Root>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

import { NodeAutomationBlock, NodeAutomationBlockType } from '../components/NodeAutomationBlock';
import { NodeAutomationBlockEmptyType } from '../components/NodeAutomationBlockEmpty';
import { NodeAutomationTrigger, NodeAutomationTriggerType } from '../components/NodeAutomationTrigger';
import { CustomNode } from '../types';

export const isNodeAutomationBlock = (node: CustomNode): node is NodeAutomationBlock => {
  return node.type === NodeAutomationBlockType;
};

export const isNodeAutomationBlockEmpty = (node: CustomNode): node is NodeAutomationBlock => {
  return node.type === NodeAutomationBlockEmptyType;
};

export const isNodeAutomationTrigger = (node: CustomNode): node is NodeAutomationTrigger => {
  return node.type === NodeAutomationTriggerType;
};

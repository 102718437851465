import { Float } from '@headlessui-float/react';
import { Popover } from '@headlessui/react';
import { FC } from 'react';

import PlusIcon from '../../../assets/icons/plus.svg?react';
import CloseIcon from '../../../assets/icons/x-close.svg?react';
import { EventsCombobox } from '../../../shared/components/EventsCombobox';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { EventListItem } from '@bigdelta/lib-shared';

interface EventsBuilderProps {
  title: string;
  allowMultipleEvents: boolean;
  events: (EventListItem | null)[] | undefined;

  onAddEvent: () => void;
  onRemoveEvent: (idx: number) => void;
  onSelectEvent: (value: { event_name: string } | null, idx: number) => void;
}

export const EventsBuilder: FC<EventsBuilderProps> = ({ events, title, allowMultipleEvents, onAddEvent, onRemoveEvent, onSelectEvent }) => {
  const { currentWorkspaceId } = useWorkspace();

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="font-medium">{title}</div>
        {allowMultipleEvents && (
          <button className="cursor-pointer rounded text-m-olive-400 hover:bg-m-gray-300" onClick={onAddEvent}>
            <PlusIcon className="h-5 w-5" />
          </button>
        )}
      </div>
      {events?.map((item, idx) => (
        <div key={item?.event_name.name ?? idx} className="flex w-full items-center gap-1 rounded-lg border border-m-olive-100 p-1">
          <Popover className="flex-1">
            {({ close }) => (
              <Float portal adaptiveWidth offset={2}>
                <Popover.Button className="flex-start flex w-full items-center gap-x-2 rounded-lg bg-m-white p-2 text-sm hover:bg-m-gray-200">
                  {allowMultipleEvents && (
                    <div className="flex h-4 w-4 items-center justify-center rounded-sm bg-m-gray-300 text-m-gray-700">{idx + 1}</div>
                  )}
                  {!item && <span className="truncate text-m-olive-600">Select {title.toLowerCase()}...</span>}
                  {item?.event_name && <span className="text-m-olive-900">{item?.event_name?.name}</span>}
                </Popover.Button>
                <Popover.Panel>
                  <EventsCombobox workspaceId={currentWorkspaceId} value={undefined} onChange={(value) => onSelectEvent(value, idx)} close={close} />
                </Popover.Panel>
              </Float>
            )}
          </Popover>
          {events && events.length > 1 && (
            <div className="flex items-center justify-center">
              <button className="cursor-pointer rounded p-1 text-m-olive-400 hover:bg-m-gray-300" onClick={() => onRemoveEvent(idx)}>
                <CloseIcon className="h-5 w-5" />
              </button>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

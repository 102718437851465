import { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UserContext } from '../context/userContext';
import { Navigate } from 'react-router-dom';
import { AppRoutes } from '../../../routes';
import { Button } from '../../../shared/ui/Button/Button.tsx';
import { Link } from '../../../shared/ui/Link/Link.tsx';
import { isEmpty } from 'lodash';
import { handleFormErrorResponse } from '../../../utils/handleFormErrorResponse.ts';
import { FormInput } from '../../../shared/ui/FormInput/FormInput.tsx';
import { useMutation } from '@tanstack/react-query';
import { AuthScreenTitle } from '../components/AuthScreenTitle.tsx';
import { AuthScreenContainer } from '../components/AuthScreenContainer.tsx';
import { AuthScreenLogo } from '../components/AuthScreenLogo.tsx';
import { ForgotPasswordSuccess } from '../components/ForgotPasswordSuccess.tsx';
import { toastError } from '../../../utils/toast.tsx';
import { AxiosError } from 'axios';
import { ErrorResponse } from '@bigdelta/lib-shared';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

interface ForgotPasswordSchema {
  email: string;
}

export const ForgotPasswordPage = () => {
  const userContext = useContext(UserContext);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const { mutate: startResetPasswordMutate, ...startResetPasswordMutation } = useMutation({
    mutationFn: (email: string) => bigdeltaAPIClient.v1.membersRequestResetPasswordCreate({ email }),
    onError: (error: AxiosError<ErrorResponse>) => {
      toastError(error.response.data?.message);
    },
  });

  const {
    register,
    handleSubmit,
    setError: setFieldError,
    getValues,
    formState: { errors },
  } = useForm<ForgotPasswordSchema>();

  const onSubmit: SubmitHandler<ForgotPasswordSchema> = async (data) => {
    setLoading(true);

    try {
      if (isEmpty(data.email)) {
        setError('Email is required');
        return;
      }

      setError('');

      try {
        startResetPasswordMutate(data.email);
      } catch (e: any) {
        handleFormErrorResponse(e, setError, setFieldError);
      }
    } finally {
      setLoading(false);
    }
  };

  if (userContext.user && userContext.authenticated && userContext.ready) {
    return <Navigate to={AppRoutes.ROOT} />;
  }

  return (
    <AuthScreenContainer>
      <AuthScreenLogo />
      {!startResetPasswordMutation.isSuccess && (
        <>
          <AuthScreenTitle title="Forgot your password?" subtitle="Enter your email and we will send you instructions how to reset your password." />
          <div className="flex items-center justify-center">
            {error && <div className="w-full rounded-lg border border-m-red-300 bg-m-red-200 px-4 py-2 text-md text-m-red-700">{error}</div>}
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4">
              <FormInput
                label="Work email"
                inputProps={{
                  ...register('email', { required: true }),
                  autoComplete: 'email username',
                  type: 'email',
                  placeholder: 'name@example.com',
                }}
                errorMessage={errors.email?.message}
              />
              <Button label="Submit" className="w-full" size="lg" type="submit" loading={loading} disabled={loading} />
            </div>
          </form>
        </>
      )}
      {startResetPasswordMutation.isSuccess && <ForgotPasswordSuccess email={getValues('email')} />}
      <div className="align-center mt-6 flex justify-center text-sm">
        <Link className="ml-1" to="/login">
          Back
        </Link>
      </div>
    </AuthScreenContainer>
  );
};

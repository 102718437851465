import React, { useState } from 'react';
import { FormInput } from '../../../../shared/ui/FormInput/FormInput.tsx';
import { useForm } from 'react-hook-form';
import { ImageDisplay } from '../../../../shared/components/ImageDisplay.tsx';
import { Button } from '../../../../shared/ui/Button/Button.tsx';
import { UploadImageButton } from '../../../../shared/components/UploadImageButton.tsx';
import { useWorkspace } from '../../../auth/hooks/useWorkspace.tsx';
import { useUser } from '../../../auth/hooks/useUser.ts';
import { useUpdateWorkspaceMutation } from '../../../records/data/useUpdateWorkspaceMutation.ts';
import { toastError, toastSuccess } from '../../../../utils/toast.tsx';
import { useDeleteWorkspaceMutation } from '../../../records/data/useDeleteWorkspaceMutation.ts';
import { DestructiveActionConfirmationModal } from '../../../../shared/components/DestructiveActionConfirmationModal.tsx';
import { AppRoutes } from '../../../../routes';
import { useNavigate } from 'react-router-dom';
import { captureException } from '@sentry/react';
import CheckCircleIcon from '../../../../assets/icons/check-circle.svg?react';

export type UpdateWorkspaceFormSchema = {
  name: string;
  newLogoImage?: string;
};

export const WorkspaceGeneralSettingsPage = () => {
  const { workspaces } = useUser();
  const { currentWorkspaceId } = useWorkspace();
  const navigate = useNavigate();
  const currentWorkspace = workspaces.find((w) => w.id === currentWorkspaceId);
  const mutation = useUpdateWorkspaceMutation();
  const deleteMutation = useDeleteWorkspaceMutation();
  const [loading, setLoading] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [workspaceIdCopied, setWorkspaceIdCopied] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<UpdateWorkspaceFormSchema>({
    defaultValues: {
      name: currentWorkspace?.name,
      newLogoImage: null,
    },
  });

  const onSubmit = async (data: UpdateWorkspaceFormSchema) => {
    setLoading(true);

    try {
      await mutation.mutateAsync({
        workspaceId: currentWorkspaceId,
        data: { name: data.name, logo: data.newLogoImage },
      });

      toastSuccess('Success', 'Workspace updated');
      reset({}, { keepValues: true });
    } catch (e) {
      toastError('An error occurred while updating the workspace');
    } finally {
      setLoading(false);
    }
  };
  const onConfirmDelete = async () => {
    setLoading(true);

    try {
      await deleteMutation.mutateAsync({ workspaceId: currentWorkspaceId });

      toastSuccess('Success', 'Workspace deleted');

      navigate(AppRoutes.ROOT);
    } catch (e) {
      captureException(e);

      toastError('An error occurred while deleting the workspace');
    } finally {
      setLoading(false);
    }
  };
  const newLogoImage = watch('newLogoImage');

  return (
    <div className="flex h-screen w-full flex-col items-center">
      <DestructiveActionConfirmationModal
        heading="Delete workspace"
        message="Are you sure you want to permanently delete this workspace? This action is irreversible and all information related to this workspace will be lost."
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onConfirm={onConfirmDelete}
        loading={loading}
        disabled={loading}
      />
      <div className="flex w-full max-w-screen-md flex-col gap-8">
        <div className="flex flex-col">
          <div className="text-2xl font-medium text-m-olive-900">General</div>
          <div className="text-md text-m-olive-500">Change the settings of your current workspace here</div>
        </div>
        <div className="flex flex-col gap-4">
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <div className="text-sm font-medium">Workspace id</div>
              <div className="flex items-center gap-x-2">
                <input
                  name="workspace_id_preview"
                  className="w-full rounded-lg border border-m-gray-300 px-3 py-2 text-sm"
                  value={currentWorkspaceId}
                  disabled={true}
                />
                <Button
                  trailingIcon={workspaceIdCopied ? CheckCircleIcon : undefined}
                  label={workspaceIdCopied ? 'Copied' : 'Copy'}
                  intent="brand"
                  size="lg"
                  onClick={(event) => {
                    event.preventDefault();
                    navigator.clipboard.writeText(currentWorkspaceId);
                    setWorkspaceIdCopied(true);
                  }}
                />
              </div>
            </div>
            <FormInput
              label="Workspace name"
              inputProps={{
                ...register('name', { required: true, minLength: 1 }),
                placeholder: 'Workspace name',
              }}
              errorMessage={errors.name?.message}
            />
            <div className="flex flex-col gap-2">
              <div className="text-sm font-medium">Workspace logo</div>
              <div className="flex items-center gap-4">
                <ImageDisplay imageSrc={newLogoImage || currentWorkspace.logo_image_url} />
                <div>
                  <UploadImageButton onChange={(imageSrc) => setValue('newLogoImage', imageSrc, { shouldDirty: true })} />
                </div>
              </div>
            </div>
            <div>
              <Button label="Save changes" intent="brand" disabled={!isDirty || !isValid || loading} loading={loading} />
            </div>
          </form>
          <hr className="h-px bg-m-gray-300" />
          <div>
            <Button intent="destructive" label="Permanently delete workspace" onClick={() => setDeleteConfirmationOpen(true)} />
          </div>
        </div>
      </div>
    </div>
  );
};

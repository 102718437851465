import { FC } from 'react';
import ColorHash from 'color-hash';

const colorHash = new ColorHash();

export const UserTableCell: FC<{ email: string }> = ({ email }) => {
  return (
    <div className="flex items-center gap-x-2">
      <div
        className="flex min-w-[20px] min-h-[20px] items-center justify-center rounded-full bg-m-red-600 capitalize text-m-white"
        style={{ background: colorHash.hex(email) }}
      >
        {email?.charAt(0)}
      </div>
      <div>{email}</div>
    </div>
  );
};

import { useQuery } from '@tanstack/react-query';

import { useQueryKeys } from '../../features/auth/hooks/useQueryKeys.ts';
import { bigdeltaAPIClient } from '../../client/bigdeltaAPIClient.ts';

interface UseMetricQueryParameters {
  metricId?: string;
}

export const useMetricQuery = ({ metricId }: UseMetricQueryParameters) => {
  const queryKeys = useQueryKeys();

  return useQuery({
    queryKey: queryKeys.metric(metricId),
    queryFn: () => bigdeltaAPIClient.v1.metricsDetail(metricId!),
    enabled: !!metricId,
  });
};

import { useReportStore } from '../../store/Report';
import { TrendsBuilder } from './TrendsBuilder';
import { DataSelect } from './DataSelect';
import { AddFormulaButton } from './AddFormulaButton';
import { TrendsBuilderType } from '../../store/TrendsBuilder';
import { v4 as uuid } from 'uuid';

export const TrendsConfig = () => {
  const {
    report: {
      trends: { builders },
    },
    addTrendsBuilder,
  } = useReportStore();

  const handleAddFormulaBuilder = () => {
    const name = String.fromCharCode(builders.length + 65);

    const defaultExpression = builders.map((builder) => builder.name).join('+');

    addTrendsBuilder({
      id: uuid(),
      name,
      type: TrendsBuilderType.FORMULA,
      data: {
        [TrendsBuilderType.FORMULA]: {
          expression: defaultExpression,
        },
      },
    });
  };

  return (
    <div className="flex flex-col gap-y-2 py-4">
      <div className="flex items-center justify-between ">
        <div className="text-md font-medium">Data</div>
        <DataSelect />
      </div>
      <div className="flex flex-col gap-y-2">
        {builders.map((builder) => (
          <TrendsBuilder id={builder.id} key={builder.id} />
        ))}
        <AddFormulaButton onClick={handleAddFormulaBuilder} />
      </div>
    </div>
  );
};

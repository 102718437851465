"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportGroupingConfigVODef = exports.ReportGroupingConfigSelectedGroupVODef = exports.ReportGroupingConfigGroupAttributeVODef = exports.ReportGroupingConfigGroupVODef = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.ReportGroupingConfigGroupVODef = typebox_1.Type.Object({
    attribute_id: typebox_1.Type.Optional(typebox_1.Type.String()),
    attribute_name: typebox_1.Type.String(),
});
exports.ReportGroupingConfigGroupAttributeVODef = typebox_1.Type.Object({
    attribute_id: typebox_1.Type.Optional(typebox_1.Type.String()),
    attribute_name: typebox_1.Type.String(),
    attribute_value: typebox_1.Type.String(),
});
exports.ReportGroupingConfigSelectedGroupVODef = typebox_1.Type.Object({
    group_attributes: typebox_1.Type.Optional(typebox_1.Type.Array(exports.ReportGroupingConfigGroupAttributeVODef)),
});
exports.ReportGroupingConfigVODef = typebox_1.Type.Object({
    groups: typebox_1.Type.Optional(typebox_1.Type.Array(exports.ReportGroupingConfigGroupVODef)),
    selected_groups: typebox_1.Type.Optional(typebox_1.Type.Array(exports.ReportGroupingConfigSelectedGroupVODef)),
});

import { FC } from 'react';
import { FilterItem, useFilterStore } from '../../store';
import { useFilterContext } from '../../context/useFilterContext';
import { PropertyValue } from '../common/PropertyValue';
import { RowContainer } from '../common/RowContainer';
import { MetadataResourcePropertyType, RelationshipEntityType } from '@bigdelta/lib-shared';
import { PropertyOperatorSelect } from '../common/PropertyOperatorSelect';
import { Group } from '../common/Group';
import { Control } from '../common/Control';
import { RecordPropertyMultilevelSelect } from '../common/RecordPropertyMultilevelSelect';
import { last } from 'lodash';

interface MetricsRecordPropertyFilterRowProps {
  index: number;
  filter: FilterItem;
}

export const MetricsRecordPropertyFilterRow: FC<MetricsRecordPropertyFilterRowProps> = ({ index, filter }) => {
  const { filterKey } = useFilterContext();

  const setItemPropertyOperator = useFilterStore((state) => state.setItemPropertyOperator);
  const removeItem = useFilterStore((state) => state.removeItem);

  const currentResourceId = last(filter.propertyRelationships)?.objectId;

  return (
    <RowContainer onRemove={() => removeItem(filterKey, index)}>
      <Group>
        <Control>
          <RecordPropertyMultilevelSelect
            index={index}
            filterDataItem={filter}
            parentEntityType={RelationshipEntityType.EVENT}
            parentEntityId={null}
          />
        </Control>
      </Group>
      <Group>
        <Control shouldShrink={false}>
          <PropertyOperatorSelect
            index={index}
            value={filter.propertyOperator}
            onChange={(index, value) => setItemPropertyOperator(filterKey, index, value)}
          />
        </Control>
        <Control>
          {currentResourceId && <PropertyValue index={index} resourceId={currentResourceId} resourceType={MetadataResourcePropertyType.OBJECT} />}
        </Control>
      </Group>
    </RowContainer>
  );
};

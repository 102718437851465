import { FilterItem, useFilterStore } from '../../store';
import { FC } from 'react';
import { useFilterContext } from '../../context/useFilterContext';
import { MetadataEventsListData } from '@bigdelta/lib-api-client';
import { EventNameComboboxSingle } from './EventNameComboboxSingle';
import { ValueSelectType } from '../../const';

type EventListItem = MetadataEventsListData['items'][number];

interface EventNameValueSelectSingleProps {
  filterDataItem: FilterItem;
  index: number;
}

export const EventNameValueSelectSingle: FC<EventNameValueSelectSingleProps> = ({ filterDataItem, index }) => {
  const { filterKey } = useFilterContext();
  const setItemDataValue = useFilterStore((state) => state.setItemDataValue);

  const handleEventChange = (data: EventListItem | null) => {
    if (data?.event_name) {
      setItemDataValue(filterKey, index, data.event_name);
    }
  };

  if (filterDataItem.data.valueType !== ValueSelectType.COMBOBOX_SINGLE) {
    return null;
  }

  return <EventNameComboboxSingle value={filterDataItem.data.value} onChange={handleEventChange} />;
};

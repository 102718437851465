import { FC } from 'react';
import { useReportStore, useTrendsBuilderStore } from '../../store/Report';
import * as TrendsBuilderBase from '../common/ReportBuilderBase';
import { useWorkspace } from '../../../auth/hooks/useWorkspace';
import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../../../auth/hooks/useQueryKeys';

import { capitalize } from 'lodash';
import { getWorkspaceObjectIcon } from '../../../../shared/utils/getWorkspaceObjectIcon';
import { RecordsFilterAdd } from '../common/RecordsFilterAdd';
import { FilterCard } from '../../../../shared/filters/components/common/FilterCard';
import { useFilterContext } from '../../../../shared/filters/context/useFilterContext';
import { getFilter, useFilterStore } from '../../../../shared/filters/store';
import { TrendsCountPropertySelect } from './TrendsCountPropertySelect';
import { TrendsCountAggregate } from './TrendsCountAggregate';
import { TrendsCountFilterButton } from './TrendsCountFilterButton';
import { TrendsCountFilter } from './TrendsCountFilter';
import { ObjectCountType } from '../../store/common';
import { WorkspaceObjectBlock } from '../../../../shared/components/WorkspaceObjectBlock';
import { bigdeltaAPIClient } from '../../../../client/bigdeltaAPIClient.ts';

interface TrendsBuilderObjectProps {
  id: string;
  onCreateMetric: () => void;
  onUpdateMetric: () => void;
  onRename: () => void;
}

export const TrendsBuilderObject: FC<TrendsBuilderObjectProps> = ({ id, onCreateMetric, onUpdateMetric, onRename }) => {
  const queryKeys = useQueryKeys();

  const { currentWorkspaceId } = useWorkspace();

  const removeTrendsBuilder = useReportStore((state) => state.removeTrendsBuilder);

  const name = useTrendsBuilderStore(id, (state) => state.name);
  const label = useTrendsBuilderStore(id, (state) => state.label);
  const metricId = useTrendsBuilderStore(id, (state) => state.metricId);
  const countType = useTrendsBuilderStore(id, (state) => state.data.object?.count.type);

  const { filterKey } = useFilterContext();

  const filter = useFilterStore((state) => getFilter(state, filterKey))?.items ?? [];

  const workspaceObjectId = useTrendsBuilderStore(id, (state) => state.data.object?.workspaceObjectId);

  const isCountOfEventType = () => {
    return countType === ObjectCountType.EVENT;
  };

  const objectsQuery = useQuery({
    queryKey: queryKeys.list('object', 'active_and_virtual'),
    queryFn: () => bigdeltaAPIClient.v1.objectsList({ workspace_id: currentWorkspaceId, status: 'ACTIVE_AND_VIRTUAL' }),
  });

  const handleContextAction = (action: TrendsBuilderBase.ContextMenuAction) => {
    switch (action) {
      case TrendsBuilderBase.ContextMenuAction.SAVE_AS_METRIC:
        onCreateMetric();
        break;
      case TrendsBuilderBase.ContextMenuAction.REMOVE:
        removeTrendsBuilder(id);
        break;
      case TrendsBuilderBase.ContextMenuAction.RENAME:
        onRename();
        break;
      case TrendsBuilderBase.ContextMenuAction.UPDATE_METRIC:
        onUpdateMetric();
        break;
      default:
        break;
    }
  };

  const object = objectsQuery.data?.objects?.find((object) => object.id === workspaceObjectId);

  if (!object) {
    return null;
  }

  return (
    <TrendsBuilderBase.Container storeSelector={useTrendsBuilderStore}>
      <div className="flex flex-col gap-y-5">
        <div className="flex items-center justify-between gap-x-3">
          <TrendsBuilderBase.Heading name={name} label={label} id={id} />
          <div className="flex items-center gap-x-0.5">
            <TrendsBuilderBase.Remove onClick={() => removeTrendsBuilder(id)} />
            <TrendsBuilderBase.ContextMenu onSelect={handleContextAction} metricId={metricId} />
          </div>
        </div>
        <div className="flex flex-col gap-y-4">
          <WorkspaceObjectBlock
            name={capitalize(object.plural_noun)}
            icon={getWorkspaceObjectIcon(object.object_type)}
            endSlot={<RecordsFilterAdd objectId={object.id} />}
            filters={
              !!filter?.length &&
              filter.map((filterItem, index) => {
                return (
                  <FilterCard
                    key={`${JSON.stringify(filterItem.property)}-${index}`}
                    filterIndex={index}
                    filterItem={filterItem}
                    objectId={object.id}
                    className="rounded-t-none border-t-0"
                  />
                );
              })
            }
          />
          <div className="flex flex-col gap-y-2">
            <div className="text-sm">Count</div>
            <div className="group flex flex-col rounded-lg border border-m-olive-100 bg-m-gray-200 p-2 shadow-sm">
              <div className="flex items-center gap-x-2">
                <div className="group/property flex min-w-0 shrink grow items-center overflow-hidden rounded-lg border border-m-olive-100 bg-m-white">
                  <TrendsCountPropertySelect id={id} />
                  {isCountOfEventType() && <TrendsCountFilterButton id={id} />}
                </div>
                <TrendsCountAggregate id={id} />
              </div>
              <TrendsCountFilter id={id} />
            </div>
          </div>
        </div>
      </div>
    </TrendsBuilderBase.Container>
  );
};

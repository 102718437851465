import { stratify, tree } from 'd3-hierarchy';

import { toastError } from '../../../utils/toast';
import { CustomNode } from '../types';

const defaultWidth = 288;
const defaultHeight = 119;

// TODO: Combine with getLayoutedElements from metric graph
export const getLayoutedElements = (nodes: Array<CustomNode>, edges) => {
  if (nodes.length === 0) return { nodes, edges };
  const g = tree<CustomNode>();
  const width = nodes?.[0]?.width ?? defaultWidth;
  const height = nodes?.[0]?.height ?? defaultHeight;

  try {
    const hierarchy = stratify<CustomNode>()
      .id((node) => node.id)
      .parentId((node) => edges.find((edge) => edge.target === node.id)?.source);
    const root = hierarchy(nodes);

    const layout = g.separation(() => 1).nodeSize([width + 50, height + 75])(root);

    return {
      nodes: layout.descendants().map((node) => ({ ...(node.data as any), position: { x: node.x, y: node.y } })),
      edges,
    };
  } catch (e) {
    toastError('Failed to layout elements');
    return { nodes: [], edges: [] };
  }
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toGroupVO = exports.CreateGroupRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.CreateGroupRequestDef = typebox_1.Type.Object({
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    name: typebox_1.Type.Optional(typebox_1.Type.String()),
});
const toGroupVO = (group) => {
    return {
        workspaceId: group.workspace_id,
        name: group.name,
    };
};
exports.toGroupVO = toGroupVO;

import { FC } from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { cva, type VariantProps } from 'class-variance-authority';

const linkVariants = cva('', {
  variants: {
    intent: {
      primary: 'text-m-blue-600',
      subdued: 'text-m-olive-300',
    },
    size: {
      xl: 'text-xl',
      lg: 'text-lg',
      md: 'text-md',
      sm: 'text-sm',
      xs: 'text-xs',
    },
  },
  defaultVariants: {
    intent: 'primary',
    size: 'md',
  },
});

type Props = LinkProps & VariantProps<typeof linkVariants>;

export const Link: FC<Props> = ({ className, intent, size, ...rest }) => {
  return <RouterLink className={twMerge('hover:underline', linkVariants({ intent, size }), className)} {...rest}></RouterLink>;
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toCreateDocumentRelationshipVO = exports.CreateDocumentRelationshipRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_1 = require("../model");
exports.CreateDocumentRelationshipRequestDef = typebox_1.Type.Object({
    workspace_id: typebox_1.Type.String(),
    first_document_type: typebox_1.Type.Enum(model_1.DocumentRelationshipDocumentType),
    first_document_workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    first_document_workspace_object_id: typebox_1.Type.Optional(typebox_1.Type.String()),
    first_document_id: typebox_1.Type.String(),
    second_document_type: typebox_1.Type.Enum(model_1.DocumentRelationshipDocumentType),
    second_document_workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    second_document_workspace_object_id: typebox_1.Type.Optional(typebox_1.Type.String()),
    second_document_id: typebox_1.Type.String(),
});
const toCreateDocumentRelationshipVO = (accountId, request) => {
    return {
        accountId,
        workspaceId: request.workspace_id,
        firstDocumentType: request.first_document_type,
        firstDocumentWorkspaceId: request.first_document_workspace_id,
        firstDocumentWorkspaceObjectId: request.first_document_workspace_object_id,
        firstDocumentId: request.first_document_id,
        secondDocumentType: request.second_document_type,
        secondDocumentWorkspaceId: request.second_document_workspace_id,
        secondDocumentWorkspaceObjectId: request.second_document_workspace_object_id,
        secondDocumentId: request.second_document_id,
    };
};
exports.toCreateDocumentRelationshipVO = toCreateDocumentRelationshipVO;

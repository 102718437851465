"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataImportJobStepDef = exports.DataImportStepAction = exports.DataImportStepCondition = exports.DataImportStepStatus = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const timestampedModel_1 = require("./timestampedModel");
var DataImportStepStatus;
(function (DataImportStepStatus) {
    DataImportStepStatus["WAITING_FOR_CONDITION"] = "WAITING_FOR_CONDITION";
    DataImportStepStatus["ASYNC_PROCESSING"] = "ASYNC_PROCESSING";
    DataImportStepStatus["COMPLETED"] = "COMPLETED";
    DataImportStepStatus["FAILED"] = "FAILED";
    DataImportStepStatus["CANCELLED"] = "CANCELLED";
})(DataImportStepStatus || (exports.DataImportStepStatus = DataImportStepStatus = {}));
var DataImportStepCondition;
(function (DataImportStepCondition) {
    DataImportStepCondition["BIG_QUERY_JOB_COMPLETED"] = "BIG_QUERY_JOB_COMPLETED";
})(DataImportStepCondition || (exports.DataImportStepCondition = DataImportStepCondition = {}));
var DataImportStepAction;
(function (DataImportStepAction) {
    DataImportStepAction["BIGQUERY_CREATE_SNAPSHOT"] = "BIGQUERY_CREATE_SNAPSHOT";
    DataImportStepAction["BIGQUERY_EXPORT_TO_GCS"] = "BIGQUERY_EXPORT_TO_GCS";
    DataImportStepAction["BIGQUERY_COMPARE_SNAPSHOTS"] = "BIGQUERY_COMPARE_SNAPSHOTS";
    DataImportStepAction["BIGQUERY_FIND_INSERTED_ROWS"] = "BIGQUERY_FIND_INSERTED_ROWS";
    DataImportStepAction["BIGQUERY_FIND_DELETED_ROWS"] = "BIGQUERY_FIND_DELETED_ROWS";
    DataImportStepAction["BIGQUERY_FIND_UPDATED_ROWS"] = "BIGQUERY_FIND_UPDATED_ROWS";
    DataImportStepAction["BIGQUERY_UPSERT_RECORDS"] = "UPSERT_RECORDS";
    DataImportStepAction["BIGQUERY_DELETE_RECORDS"] = "DELETE_RECORDS";
})(DataImportStepAction || (exports.DataImportStepAction = DataImportStepAction = {}));
exports.DataImportJobStepDef = typebox_1.Type.Composite([
    timestampedModel_1.TimestampedModelDef,
    typebox_1.Type.Object({
        id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        data_import_job_id: typebox_1.Type.String({ format: 'uuid' }),
        previous_step_id: typebox_1.Type.String({ format: 'uuid' }),
        status: typebox_1.Type.Enum(DataImportStepStatus),
        condition: (0, typebox_2.OptionalNullable)(typebox_1.Type.Enum(DataImportStepCondition)),
        action: typebox_1.Type.Enum(DataImportStepAction),
        next_action: (0, typebox_2.OptionalNullable)(typebox_1.Type.Enum(DataImportStepAction)),
        params: typebox_1.Type.Object({
            bigquery: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
                job_id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
                snapshot_name: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
                path: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
            })),
        }),
    }),
]);

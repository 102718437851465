import { QueryReportsCreatePayload } from '@bigdelta/lib-api-client';
import { ALL_EVENTS, Attribute, FilterItem } from '../../../shared/filters/store';
import { getRecordsQuery } from '../../records/data/getRecordsQuery';
import { EventsMath, RecordsMath, ReportSourceType, ReportsState } from '../store';
import { RelationshipObjectData } from '../../../shared/types';
import { getEventsQuery } from '../../events/data/getEventsQuery';
import { getMetricsFilterConditions } from '../../metrics/utils/getMetricsFilterConditions';
import { ReportsMetricQueryVO, ReportTypeVO } from '@bigdelta/lib-shared';

const getEventsMathObject = (
  math: 'distinct total' | 'total' | 'sum' | 'average' | 'median' | 'nps',
  reportQueryState: ReportsState['query'] | ReportsState['config']
) => {
  if (!reportQueryState.eventMathProperty) {
    return;
  }

  return {
    math,
    math_target: {
      event_property: {
        name: reportQueryState.eventMathProperty.property_name,
      },
    },
  };
};

const getRecordsRelatedMathTarget = (relationshipName: string, propertyName: string) => {
  return {
    related_records: {
      relationship_name: relationshipName,
      property_name: propertyName,
    },
  };
};

const getRecordsMathObject = (
  math: 'distinct total' | 'total' | 'sum' | 'average' | 'median' | 'nps',
  relationships: RelationshipObjectData[],
  property: Attribute | null
) => {
  const relationshipName = relationships[1]?.relationshipName;
  const propertyName = property?.attributeName;

  if (relationships?.length > 1 && relationshipName && propertyName) {
    return {
      math,
      math_target: getRecordsRelatedMathTarget(relationshipName, propertyName),
    };
  }
  if (relationships?.length === 1 && propertyName) {
    return {
      math,
      math_target: {
        record_property: {
          name: propertyName,
        },
      },
    };
  }
};

export const getMetrics = (
  reportType: ReportTypeVO,
  metricType: ReportSourceType,
  metricId: string,
  stateFilter: { items: FilterItem[]; operator: 'and' | 'or' } | null,
  reportQueryState: ReportsState['query'] | ReportsState['config']
): QueryReportsCreatePayload['metrics'] | ReportsMetricQueryVO[] | undefined => {
  if (reportType === ReportTypeVO.CHART) {
    return getChartMetrics(metricType, metricId, stateFilter, reportQueryState);
  } else if (reportType === ReportTypeVO.FUNNEL) {
    return getFunnelMetrics(reportQueryState);
  }
};

const getFunnelMetrics = (
  state: ReportsState['query'] | ReportsState['config']
): QueryReportsCreatePayload['metrics'] | ReportsMetricQueryVO[] | undefined => {
  if (!state) {
    return;
  }

  return [
    {
      funnel: {
        relationship_name: state.funnelRelationshipName,
        sequence_completion_window: {
          unit: state.funnelSequenceCompletionTimeUnit,
          value: state.funnelSequenceCompletionValue,
        },
        event_sequence: state.funnelEventSequence,
      },
    },
  ];
};

const getChartMetrics = (
  metricType: ReportSourceType,
  metricId: string,
  stateFilter: { items: FilterItem[]; operator: 'and' | 'or' } | null,
  reportQueryState: ReportsState['query'] | ReportsState['config']
): QueryReportsCreatePayload['metrics'] | ReportsMetricQueryVO[] | undefined => {
  if (metricType === ReportSourceType.METRIC) {
    const conditions = getMetricsFilterConditions(stateFilter?.items);

    return [
      {
        metric: {
          resource: {
            id: metricId,
          },
          ...(conditions?.length
            ? {
                events: {
                  filter: {
                    operator: 'and',
                    conditions,
                  },
                },
              }
            : {}),
        },
      },
    ];
  }

  if (metricType === ReportSourceType.RECORDS) {
    const query = getRecordsQuery(metricId, stateFilter);

    let mathObj;

    switch (true) {
      case reportQueryState.recordMath === RecordsMath.AGGREGATE_SUM:
        mathObj = getRecordsMathObject('sum', reportQueryState.recordMathRelationships, reportQueryState.recordMathProperty);
        break;
      case reportQueryState.recordMath === RecordsMath.AGGREGATE_AVERAGE:
        mathObj = getRecordsMathObject('average', reportQueryState.recordMathRelationships, reportQueryState.recordMathProperty);
        break;
      case reportQueryState.recordMath === RecordsMath.AGGREGATE_MEDIAN:
        mathObj = getRecordsMathObject('median', reportQueryState.recordMathRelationships, reportQueryState.recordMathProperty);
        break;
      case reportQueryState.recordMath === RecordsMath.AGGREGATE_DISTINCT_COUNT:
        mathObj = getRecordsMathObject('distinct total', reportQueryState.recordMathRelationships, reportQueryState.recordMathProperty);
        break;
      case reportQueryState.recordMath === RecordsMath.AGGREGATE_NPS:
        mathObj = getRecordsMathObject('nps', reportQueryState.recordMathRelationships, reportQueryState.recordMathProperty);
        break;
      case reportQueryState.recordMath === RecordsMath.TOTAL:
      default:
        mathObj = {
          math: 'total',
        };
    }

    return [
      {
        records: {
          ...mathObj,
          ...query,
        },
      },
    ];
  }

  if (metricType === ReportSourceType.EVENTS) {
    const query = getEventsQuery(stateFilter?.items);
    let mathObj;

    switch (true) {
      case reportQueryState.eventMath === EventsMath.DISTINCT_RECORDS && !!reportQueryState.eventMathRelatedObject?.relationshipName:
        if (!reportQueryState.eventMathRelatedObject?.relationshipName) {
          break;
        }

        mathObj = {
          math: 'distinct total',
          math_target: {
            related_records: {
              relationship_name: reportQueryState.eventMathRelatedObject.relationshipName,
            },
          },
        };
        break;
      case reportQueryState.eventMath === EventsMath.AGGREGATE_SUM && !!reportQueryState.eventMathProperty:
        mathObj = getEventsMathObject('sum', reportQueryState);
        break;
      case reportQueryState.eventMath === EventsMath.AGGREGATE_AVERAGE && !!reportQueryState.eventMathProperty:
        mathObj = getEventsMathObject('average', reportQueryState);
        break;
      case reportQueryState.eventMath === EventsMath.AGGREGATE_MEDIAN && !!reportQueryState.eventMathProperty:
        mathObj = getEventsMathObject('median', reportQueryState);
        break;
      case reportQueryState.eventMath === EventsMath.AGGREGATE_DISTINCT_COUNT && !!reportQueryState.eventMathProperty:
        mathObj = getEventsMathObject('distinct total', reportQueryState);
        break;
      case reportQueryState.eventMath === EventsMath.AGGREGATE_NPS && !!reportQueryState.eventMathProperty:
        mathObj = getEventsMathObject('nps', reportQueryState);
        break;
      case reportQueryState.eventMath === EventsMath.TOTAL_EVENTS:
      default:
        mathObj = {
          math: 'total',
        };
    }

    const filterConditions = query?.filter?.conditions ?? [];

    const defaultNameFilter =
      metricId && metricId !== ALL_EVENTS
        ? [
            {
              event_name: {
                operator: 'equals',
                value: metricId,
              },
            },
          ]
        : [];

    return [
      {
        events: {
          ...mathObj,
          filter: {
            operator: 'and',
            conditions: [...defaultNameFilter, ...filterConditions],
          },
        },
      },
    ];
  }

  if (metricType === ReportSourceType.FORMULA) {
    return [
      {
        formula: {
          expression: reportQueryState.formulaExpression,
          metrics: reportQueryState.formulaMetrics || [],
        },
      },
    ];
  }

  return;
};

import { useMemo } from 'react';
import { useEdges, useNodes } from 'reactflow';
import { CustomNodeData } from '../types';
import { TRIGGER_NODE_ID } from '../const';
import { isNodeAutomationTrigger } from '../utils/graphNodeTypeguards';
import { buildBlock } from '../utils/buildBlock';
import { AutomationsDetailData } from '@bigdelta/lib-api-client';
import { useAutomationBlocksFilterMap } from './useAutomationBlocksFilterMap';

type PartialAutomationsDetailData = Pick<AutomationsDetailData, 'configuration'> & { name?: string };

interface UseCurrentAutomationDraftProps {
  name?: string;
}

export const useCurrentAutomationDraft = ({ name }: UseCurrentAutomationDraftProps): PartialAutomationsDetailData | null => {
  const nodes = useNodes<CustomNodeData>();
  const edges = useEdges();

  const blockFilterMap = useAutomationBlocksFilterMap();

  return useMemo(() => {
    const triggerNode = nodes.find((n) => n.id === TRIGGER_NODE_ID);

    if (!triggerNode || !isNodeAutomationTrigger(triggerNode) || !triggerNode.data.triggerType) {
      return null;
    }

    const childNode = nodes.find((n) => edges.find((e) => e.source === TRIGGER_NODE_ID && e.target === n.id));

    if (!childNode) {
      return null;
    }

    return {
      name,
      configuration: {
        trigger: {
          type: triggerNode.data.triggerType,
          workspace_object_id: triggerNode.data.workspaceObject?.id,
          workspace_object_property: triggerNode.data.workspaceObjectProperty,
          event_name: triggerNode.data.eventName,
          block: buildBlock(childNode, nodes, edges, blockFilterMap)!,
        },
      },
    };
  }, [nodes, name, edges, blockFilterMap]);
};

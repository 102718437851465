"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toReportVO = exports.CreateReportRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const value_object_1 = require("../value-object");
const reportTemplatesQueryRequest_1 = require("../value-object/reportTemplatesQueryRequest");
const typebox_2 = require("../typebox");
const types_1 = require("../types");
exports.CreateReportRequestDef = typebox_1.Type.Object({
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    title: typebox_1.Type.Optional(typebox_1.Type.String()),
    description: typebox_1.Type.Optional(typebox_1.Type.String()),
    details: typebox_1.Type.Optional(typebox_1.Type.String()),
    owner: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
    document_group_ids: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(typebox_1.Type.String({ format: 'uuid' }))),
    type: typebox_1.Type.Optional(typebox_1.Type.Enum(types_1.ReportTypeVO)),
    query: typebox_1.Type.Optional(value_object_1.ReportsQueryRequestDef),
    grouping_config: (0, typebox_2.OptionalNullable)(value_object_1.ReportGroupingConfigVODef),
    context: (0, typebox_2.OptionalNullable)(typebox_1.Type.Enum(types_1.Context)),
    template: (0, typebox_2.OptionalNullable)(reportTemplatesQueryRequest_1.ReportTemplatesQueryRequestDef),
});
const toReportVO = (accountId, report, createdBy, updatedBy) => {
    return {
        accountId: accountId,
        workspaceId: report.workspace_id,
        title: report.title,
        description: report.description,
        details: report.details,
        owner: report.owner,
        documentGroupIds: report.document_group_ids,
        type: report.type,
        query: report.query,
        groupingConfig: report.grouping_config,
        createdBy: createdBy,
        updatedBy: updatedBy,
        context: report.context,
        template: report.template,
    };
};
exports.toReportVO = toReportVO;

import React, { ButtonHTMLAttributes, forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import PlusIcon from '../../../assets/icons/plus.svg?react';
import CloseIcon from '../../../assets/icons/x-close.svg?react';
import { PropertyNameObject } from '../../../shared/types.ts';
import { FocusTrap, Popover } from '@headlessui/react';
import { OpenTrigger } from '../../../shared/components/OpenTrigger.tsx';
import ListIcon from '../../../assets/icons/list.svg?react';
import { ReportCreateBreakdownProperty } from './ReportCreateBreakdownProperty.tsx';
import { FloatWrapper } from './FloatWrapper.tsx';

export type BreakdownProperty = PropertyNameObject | null;

export interface ReportBreakdownPropertyBlockProps {
  workspaceId: string;
  breakdownProperties: BreakdownProperty[];
  onChange?: (data: BreakdownProperty[]) => void;
  inline?: boolean;
}

export interface ReportBreakdownPropertySelectorProps {
  workspaceId: string;
  breakdownProperty?: BreakdownProperty;
  onChange?: (data: BreakdownProperty) => void;
  onClickCloseIcon?: () => void;
  openOnAdd?: boolean;
  inline?: boolean;
}

export const ReportBreakdownHeadingButton = forwardRef<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement>>(({ ...props }, ref) => (
  <button
    {...props}
    ref={ref}
    className="flex w-full items-center justify-between rounded-lg px-2 py-1.5 text-sm text-m-gray-700 hover:bg-m-gray-200"
  >
    <span>Breakdown</span>
    <PlusIcon className="h-4 w-4" />
  </button>
));

export const ReportBreakdownPropertySelector: React.FC<ReportBreakdownPropertySelectorProps> = ({
  workspaceId,
  onClickCloseIcon,
  onChange,
  breakdownProperty,
  openOnAdd,
  inline,
}) => {
  return (
    <div className="flex w-full flex-col items-stretch rounded-lg border">
      <Popover className="flex-1">
        {({ close }) => (
          <FloatWrapper
            composable
            adaptiveWidth
            className="relative"
            shift={{
              mainAxis: false,
              crossAxis: true,
            }}
            portal
            inline={inline}
          >
            <FloatWrapper.Reference inline={inline}>
              <div className="relative flex items-center justify-between gap-x-1.5 p-1">
                <Popover.Button className="flex-start flex w-full gap-x-2 rounded p-2 text-sm hover:bg-m-gray-200">
                  <OpenTrigger shouldOpen={!!openOnAdd} />
                  <ListIcon className="h-5 w-5 text-m-olive-400" />
                  {!breakdownProperty && <span className="text-m-olive-600">Select attribute</span>}
                  {breakdownProperty && <span className="text-m-olive-900">{breakdownProperty.property_name}</span>}
                </Popover.Button>
                <button className="pr-1" onClick={onClickCloseIcon}>
                  <CloseIcon className="h-5 w-5 text-m-olive-400" />
                </button>
              </div>
            </FloatWrapper.Reference>
            <FloatWrapper.Content inline={inline}>
              <Popover.Panel className="mt-2">
                <FocusTrap>
                  <ReportCreateBreakdownProperty
                    workspaceId={workspaceId}
                    onChange={(data) => {
                      onChange && onChange(data);
                      close();
                    }}
                  />
                </FocusTrap>
              </Popover.Panel>
            </FloatWrapper.Content>
          </FloatWrapper>
        )}
      </Popover>
    </div>
  );
};

export const ReportBreakdownPropertyBlock: React.FC<ReportBreakdownPropertyBlockProps> = (props) => {
  const {
    workspaceId,
    breakdownProperties: selectedBreakdownProperties,
    onChange,
    inline,
  } = props;
  const [showEmptySelector, setShowEmptySelector] = useState(false);
  const filteredSelectedBreakdownProperties = useMemo(() => {
    return selectedBreakdownProperties.filter((item) => item && item.property_name);
  }, [selectedBreakdownProperties]);

  const handleChange = useCallback(
    (breakdownProperty: BreakdownProperty, idx?: number) => {
      if (!onChange) {
        return;
      }

      if (idx !== undefined) {
        onChange(filteredSelectedBreakdownProperties.map((item, selectedIdx) => (selectedIdx === idx ? breakdownProperty : item)));
      } else {
        onChange([...filteredSelectedBreakdownProperties, breakdownProperty]);
        setShowEmptySelector(false);
      }
    },
    [onChange, filteredSelectedBreakdownProperties]
  );
  const handleRemoveBreakdownProperty = useCallback(
    (idx?: number) => {
      if (!onChange) {
        return;
      }

      onChange(filteredSelectedBreakdownProperties.filter((_, selectedIdx) => selectedIdx !== idx));
    },
    [onChange, filteredSelectedBreakdownProperties]
  );
  const handleClickAddBreakdownProperty = useCallback(() => {
    if (filteredSelectedBreakdownProperties.length > 0) {
      return;
    }

    setShowEmptySelector(true);
  }, [filteredSelectedBreakdownProperties]);

  useEffect(() => {
    if (filteredSelectedBreakdownProperties.length > 0) {
      setShowEmptySelector(false);
    }
  }, [filteredSelectedBreakdownProperties]);

  return (
    <div className="flex flex-col gap-2">
      <ReportBreakdownHeadingButton onClick={handleClickAddBreakdownProperty} />
      <div>
        {filteredSelectedBreakdownProperties.map((breakdownProperty, idx) => (
          <ReportBreakdownPropertySelector
            key={breakdownProperty?.property_id || idx}
            workspaceId={workspaceId}
            breakdownProperty={breakdownProperty}
            onChange={(data) => handleChange(data, idx)}
            onClickCloseIcon={() => handleRemoveBreakdownProperty(idx)}
            inline={inline}
          />
        ))}
        {showEmptySelector && (
          <ReportBreakdownPropertySelector
            workspaceId={workspaceId}
            onClickCloseIcon={() => setShowEmptySelector(false)}
            onChange={(data) => handleChange(data)}
            openOnAdd
            inline={inline}
          />
        )}
      </div>
    </div>
  );
};

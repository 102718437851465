"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnableStripeIntegrationRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../../../typebox");
exports.EnableStripeIntegrationRequestDef = typebox_1.Type.Object({
    workspace_object_slug: typebox_1.Type.String({
        description: 'Workspace Object that should be used to track state of the Stripe Customer.',
    }),
    customer_mapping_key: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({
        description: 'Customer metadata property key that should be used to map Stripe Customer with Workspace Object.',
    })),
});

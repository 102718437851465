import { Float, FloatContentProps, FloatProps, FloatReferenceProps } from '@headlessui-float/react';
import { FC, PropsWithChildren } from 'react';

type InlineProps = PropsWithChildren & {
  inline?: boolean;
  className?: string;
};

type Reference = FC<FloatReferenceProps & InlineProps>;
type Content = FC<FloatContentProps & InlineProps>;

const ReferenceComponent: Reference = ({ inline, children, ...rest }) => {
  if (inline) {
    return children;
  }

  return <Float.Reference {...rest}>{children}</Float.Reference>;
};

const ContentComponent: Content = ({ inline, children, ...rest }) => {
  if (inline) {
    return children;
  }

  return <Float.Content {...rest}>{children}</Float.Content>;
};

export const FloatWrapper: FC<FloatProps & InlineProps> & { Reference: Reference; Content: Content } = ({ children, inline, className, ...rest }) => {
  if (inline) {
    return <div className={className}>{children}</div>;
  }

  return <Float {...rest}>{children}</Float>;
};

FloatWrapper.Reference = ReferenceComponent;
FloatWrapper.Content = ContentComponent;

import { QuickAccessLink, QuickAccessLinkType } from '@bigdelta/lib-shared';
import { SavedLink } from '../store';

export const getQuickAccessLinkData = (type: QuickAccessLinkType, data: SavedLink['data']): QuickAccessLink['data'] | undefined => {
  switch (type) {
    case QuickAccessLinkType.AUTOMATION:
      return data.automation
        ? {
            automation: {
              automation_id: data.automation.automationId,
            },
          }
        : undefined;
    case QuickAccessLinkType.DASHBOARD:
      return data.dashboard
        ? {
            dashboard: {
              dashboard_id: data.dashboard.dashboardId,
            },
          }
        : undefined;
    case QuickAccessLinkType.OBJECT:
      return data.object
        ? {
            object: {
              workspace_object_id: data.object.workspaceObjectId,
            },
          }
        : undefined;
    case QuickAccessLinkType.RECORD:
      return data.record
        ? {
            record: {
              workspace_object_id: data.record.workspaceObjectId,
              record_id: data.record.recordId,
            },
          }
        : undefined;
    case QuickAccessLinkType.REPORT:
      return data.report
        ? {
            report: {
              report_id: data.report.reportId,
            },
          }
        : undefined;
    case QuickAccessLinkType.INTEGRATION:
      return data.integration
        ? {
            integration: {
              integration_id: data.integration.integrationId,
            },
          }
        : undefined;
    default:
      return undefined;
  }
};

import { AutomationsCreatePayload, AutomationsUpdatePayload } from '@bigdelta/lib-api-client';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactFlow } from 'reactflow';
import { PageHeading } from '../../../components/PageHeading';
import { Button } from '../../../shared/ui/Button/Button';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { TRIGGER_NODE_ID } from '../const';
import { isNodeAutomationTrigger } from '../utils/graphNodeTypeguards';
import { AutomationGraph } from './AutomationGraph';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { AppRoutes } from '../../../routes';
import { toastSuccess } from '../../../utils/toast';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';
import { QuickAccessLinkType } from '@bigdelta/lib-shared';
import { SaveAutomationFormFields, SaveAutomationModal } from './SaveAutomationModal';
import { useEffect, useMemo, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { useAutomationValidation } from '../hooks/useAutomationValidation';
import { Toggle } from '../../../shared/components/Toggle';
import { ActionConfirmationModal } from '../../../shared/components/ActionConfirmationModal.tsx';
import { tracking, TrackingEvent } from '../../../tracking';
import { Container } from '../../../shared/ui/Container/Container';
import { Divider } from '../../../shared/ui/Divider/Divider';

import AutomationsIcon from '../../../assets/icons/dataflow-04.svg?react';
import { useCurrentAutomationDraft } from '../hooks/useCurrentAutomationDraft';
import { buildBlock } from '../utils/buildBlock';
import { useAutomationBlocksFilterMap } from '../hooks/useAutomationBlocksFilterMap';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';
import { AutomationBlock } from '../types';

export const Automation = () => {
  const { id } = useParams();
  const { currentWorkspaceId } = useWorkspace();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { getNodes, getEdges } = useReactFlow();
  const queryKeys = useQueryKeys();

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const { isValid, errors } = useAutomationValidation();

  const [isAutomationEnabled, setIsAutomationEnabled] = useState(false);
  const [isPauseModalOpen, setIsPauseModalOpen] = useState(false);
  const [isSaveChangesModalOpen, setIsSaveChangesModalOpen] = useState(false);
  const [onChangesSavedCallback, setOnChangesSavedCallback] = useState(() => () => {});

  // TODO: This returns a new object reference every time
  const blockFilterMap = useAutomationBlocksFilterMap();

  const automationQuery = useQuery({
    queryKey: queryKeys.automation(id),
    queryFn: () => bigdeltaAPIClient.v1.automationsDetail(id!),
    enabled: !!id,
  });

  const currentAutomationDraft = useCurrentAutomationDraft({ name: automationQuery.data?.name });

  useEffect(() => {
    if (automationQuery.isSuccess) {
      setIsAutomationEnabled(automationQuery.data?.enabled || false);
    }
  }, [automationQuery.data?.enabled, automationQuery.isSuccess]);

  useEffect(() => {
    if (id) {
      tracking.track(TrackingEvent.AutomationViewed, { 'automation id': id });
    }
  }, [id]);

  const createAutomationMutation = useMutation({
    mutationFn: (payload: AutomationsCreatePayload) => bigdeltaAPIClient.v1.automationsCreate(payload),
    onSuccess: (response) => {
      tracking.track(TrackingEvent.AutomationCreated, { 'automation id': response.id, trigger: response.configuration.trigger.type });
      toastSuccess('Automation created', '');
      navigate(AppRoutes.AUTOMATIONS_VIEW(response.id));
    },
  });

  const updateAutomationMutation = useMutation({
    mutationFn: ({ id, payload }: { id: string; payload: AutomationsUpdatePayload }) => bigdeltaAPIClient.v1.automationsUpdate(id, payload),
    onSuccess: (response) => {
      tracking.track(TrackingEvent.AutomationUpdated, { 'automation id': response.id });
      toastSuccess('Automation updated', '');
      queryClient.invalidateQueries(queryKeys.automation(response.id));
      onChangesSavedCallback();
      setOnChangesSavedCallback(() => () => {});
    },
  });

  const toggleAutomationMutation = useMutation({
    mutationFn: ({ id, enabled }: { id: string; enabled: boolean }) => bigdeltaAPIClient.v1.automationsTogglePartialUpdate(id, { enabled }),
    onSuccess: (response) => {
      if (response.enabled) {
        tracking.track(TrackingEvent.AutomationEnabled, { 'automation id': response.id });
      } else {
        tracking.track(TrackingEvent.AutomationDisabled, { 'automation id': response.id });
      }

      toastSuccess(`Automation ${response.enabled ? 'enabled' : 'disabled'}`, '');
      queryClient.invalidateQueries(queryKeys.automation(response.id));
    },
  });

  const handleCreate = ({ name }: SaveAutomationFormFields) => {
    const nodes = getNodes();
    const edges = getEdges();

    setIsCreateDialogOpen(false);

    const triggerNode = nodes.find((n) => n.id === TRIGGER_NODE_ID);

    if (!triggerNode || !isNodeAutomationTrigger(triggerNode) || !triggerNode.data.triggerType) {
      return;
    }

    const childNode = nodes.find((n) => edges.find((e) => e.source === TRIGGER_NODE_ID && e.target === n.id));

    if (!childNode) {
      return;
    }

    const automation: AutomationsCreatePayload = {
      workspace_id: currentWorkspaceId,
      name,
      configuration: {
        trigger: {
          type: triggerNode.data.triggerType,
          workspace_object_id: triggerNode.data.workspaceObject?.id,
          workspace_object_property: triggerNode.data.workspaceObjectProperty,
          event_name: triggerNode.data.eventName,
          // TODO: handle null blocks
          block: buildBlock(childNode, nodes, edges, blockFilterMap) as AutomationBlock,
        },
      },
      enabled: false,
    };

    createAutomationMutation.mutate(automation);
  };

  const unsavedAutomationChangesExist = useMemo(() => {
    const automationConfiguration = JSON.stringify(automationQuery.data?.configuration);
    const automationDraftConfiguration = JSON.stringify(currentAutomationDraft?.configuration);

    return automationConfiguration !== automationDraftConfiguration;
  }, [automationQuery.data?.configuration, currentAutomationDraft?.configuration]);

  const handleSave = () => {
    if (!id) {
      setIsCreateDialogOpen(true);
    } else {
      const automationName = currentAutomationDraft?.name;

      if (unsavedAutomationChangesExist && currentAutomationDraft && automationName) {
        updateAutomationMutation.mutate({ id, payload: { ...currentAutomationDraft, name: automationName } });
      }
    }
  };

  return (
    <div className="flex h-full flex-col">
      <Container className="flex items-center justify-between">
        <div className="flex items-center gap-x-2">
          <PageHeading
            breadcrumbs={[{ label: 'Automations', to: '/automations', icon: AutomationsIcon }, { label: automationQuery.data?.name || '' }]}
            favoriteLinkConfig={
              automationQuery.data
                ? {
                    type: QuickAccessLinkType.AUTOMATION,
                    label: automationQuery.data.name,
                    data: {
                      automation: {
                        automationId: automationQuery.data.id,
                      },
                    },
                  }
                : undefined
            }
          />
          {id && automationQuery.data && (
            <>
              <Toggle
                data-tooltip-id="automation-save-tooltip"
                data-tooltip-place="right"
                deactivated={!isValid}
                enabled={isAutomationEnabled}
                onChange={(enabled) => {
                  if (enabled) {
                    if (unsavedAutomationChangesExist) {
                      setIsSaveChangesModalOpen(true);
                      setOnChangesSavedCallback(() => () => {
                        toggleAutomationMutation.mutate({ id, enabled: enabled });
                        setIsAutomationEnabled(enabled);
                      });
                    } else {
                      toggleAutomationMutation.mutate({ id, enabled: enabled });
                      setIsAutomationEnabled(enabled);
                    }
                  } else {
                    setIsPauseModalOpen(true);
                  }
                }}
              />
              {automationQuery.data.enabled && (
                <div className="rounded-full border border-m-green-300 bg-m-green-200 px-2 py-0.5 text-xs font-medium text-m-green-600">Live</div>
              )}
            </>
          )}
        </div>
        <Button
          intent="brand"
          label="Save changes"
          size="lg"
          onClick={() => {
            if (isAutomationEnabled) {
              setIsSaveChangesModalOpen(true);
            } else {
              handleSave();
            }
          }}
          disabled={!isValid || !unsavedAutomationChangesExist}
          data-tooltip-id="automation-save-tooltip"
        />
      </Container>
      <Divider />
      <Container className="grow">
        <AutomationGraph />
      </Container>

      <SaveAutomationModal open={isCreateDialogOpen} onSumbit={handleCreate} onClose={() => setIsCreateDialogOpen(false)} />
      <Tooltip id="automation-save-tooltip" className="z-10" hidden={isValid}>
        <div className="flex flex-col gap-y-2">
          {errors.map((error, index) => (
            <div key={index} className="text-m-red-300">
              {error}
            </div>
          ))}
        </div>
      </Tooltip>
      {id && (
        <ActionConfirmationModal
          open={isPauseModalOpen}
          heading="Are you sure you want to pause this workflow?"
          message="This will not cancel any ongoing runs."
          onClose={() => setIsPauseModalOpen(false)}
          onConfirm={() => {
            toggleAutomationMutation.mutate({ id, enabled: false });
            setIsAutomationEnabled(false);
            setIsPauseModalOpen(false);
          }}
        />
      )}
      <ActionConfirmationModal
        open={isSaveChangesModalOpen}
        heading="Are you sure you want to publish these changes?"
        onClose={() => setIsSaveChangesModalOpen(false)}
        onConfirm={() => {
          handleSave();
          setIsSaveChangesModalOpen(false);
        }}
      />
    </div>
  );
};

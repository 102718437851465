"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateObjectRecordTableConfigDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_1 = require("../model");
const typebox_2 = require("../typebox");
exports.UpdateObjectRecordTableConfigDef = typebox_1.Type.Object({
    resource_type: typebox_1.Type.Enum(model_1.TableResourceType),
    resource_id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    columns: typebox_1.Type.Array(model_1.ColumnConfigDef),
});

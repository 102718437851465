import { FC } from 'react';

interface ComboboxMultipleLabelProps {
  value?: string | (string | number | boolean)[] | undefined;
}

export const ComboboxMultipleLabel: FC<ComboboxMultipleLabelProps> = ({ value }) => {
  if (!Array.isArray(value) || !value?.length) {
    return 'Select value...';
  }

  return (
    <span>
      <span className="max-w-40">{value[0]}</span>
      {value.length > 1 && <span>{`, +${value.length - 1}`}</span>}
    </span>
  );
};

import { Handle, Node, NodeProps, Position, useEdges } from 'reactflow';
import { twMerge } from '../../../utils/twMerge';
import { AutomationBlock } from '../types';
import { AutomationWorkflowBlockType } from '@bigdelta/lib-shared';
import { ActionIcon } from './ActionIcon';
import { ActionTypeLabelMap } from '../const';
import { ConfigureWarning } from './ConfigureWarning';
import { useAutomationsStore } from '../store';
import { AddGraphNodeTrigger } from '../../../shared/components/AddGraphNodeTrigger';
import { MouseEvent } from 'react';
import { DelayNodeContent } from './blocks/Delay/DelayNodeContent';
import { DelayUntilNodeContent } from './blocks/DelayUntil/DelayUntilNodeContent';
import { SlackNodeContent } from './blocks/Slack/SlackNodeContent';
import { FilterRecordContent } from './blocks/FilterRecord/FilterRecordContent';
import { FilterEventContent } from './blocks/FilterEvent/FilterEventContent';

export const NodeAutomationBlockType = 'automation-block';

interface NodeAutomationBlockData {
  automationActionType: AutomationWorkflowBlockType;
  block: AutomationBlock;
  slackChannelName?: string;
  workspaceObjectId?: string;
  isDraft: boolean;
  id?: string;
}

export type NodeAutomationBlock = Node<NodeAutomationBlockData>;

export const NodeAutomationBlock = ({
  id,
  selected,
  data: { automationActionType, slackChannelName, block },
}: NodeProps<NodeAutomationBlockData>) => {
  const edges = useEdges();
  const hasChild = edges.some((edge) => edge.source === id);

  const { setCreateNodeData, createNodeData } = useAutomationsStore();

  const handleAddClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setCreateNodeData({
      source: id,
    });
  };

  return (
    <div
      className={twMerge(
        'group/node flex w-72 flex-col gap-y-3 rounded-2xl  border-2 bg-m-white p-5 shadow-md',
        selected ? 'border-m-lime-700' : 'border-m-olive-100'
      )}
    >
      <Handle position={Position.Top} type="target" />
      <div className="flex flex-col gap-y-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-2">
            <ActionIcon actionType={automationActionType} className="h-4 w-4" />
            <div className="text-md font-medium">{ActionTypeLabelMap[AutomationWorkflowBlockType[automationActionType]]}</div>
          </div>
          <div className="flex items-center justify-center rounded-md bg-m-gray-300 p-1 text-xxs text-m-olive-400">Action</div>
        </div>

        <hr className=" border-m-olive-100" />

        {automationActionType === AutomationWorkflowBlockType.SEND_SLACK_MESSAGE && (
          <SlackNodeContent warning={<ConfigureWarning />} slackChannelName={slackChannelName} />
        )}

        {automationActionType === AutomationWorkflowBlockType.DELAY_UNTIL && <DelayUntilNodeContent warning={<ConfigureWarning />} block={block} />}

        {automationActionType === AutomationWorkflowBlockType.DELAY && <DelayNodeContent warning={<ConfigureWarning />} block={block} />}

        {automationActionType === AutomationWorkflowBlockType.FILTER_RECORD && <FilterRecordContent warning={<ConfigureWarning />} block={block} />}

        {automationActionType === AutomationWorkflowBlockType.FILTER_EVENT && <FilterEventContent warning={<ConfigureWarning />} block={block} />}

        {!!automationActionType && !createNodeData && !hasChild && (
          <div className="nodrag nopan absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-full px-12 pt-4">
            <AddGraphNodeTrigger position="bottom" className="relative" onClick={handleAddClick} show={selected} />
          </div>
        )}
      </div>
      <Handle position={Position.Bottom} type="source" />
    </div>
  );
};

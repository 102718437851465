"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentRelationshipDef = exports.DocumentRelationshipDocumentType = void 0;
const typebox_1 = require("@sinclair/typebox");
const timestampedModel_1 = require("./timestampedModel");
var DocumentRelationshipDocumentType;
(function (DocumentRelationshipDocumentType) {
    DocumentRelationshipDocumentType["REPORT"] = "REPORT";
    DocumentRelationshipDocumentType["INSIGHT"] = "INSIGHT";
    DocumentRelationshipDocumentType["OPPORTUNITY"] = "OPPORTUNITY";
    DocumentRelationshipDocumentType["PROJECT"] = "PROJECT";
    DocumentRelationshipDocumentType["RECORD"] = "RECORD";
})(DocumentRelationshipDocumentType || (exports.DocumentRelationshipDocumentType = DocumentRelationshipDocumentType = {}));
exports.DocumentRelationshipDef = typebox_1.Type.Composite([
    timestampedModel_1.TimestampedModelDef,
    typebox_1.Type.Object({
        id: typebox_1.Type.String(),
        accountId: typebox_1.Type.String(),
        workspaceId: typebox_1.Type.String(),
        firstDocumentType: typebox_1.Type.Enum(DocumentRelationshipDocumentType),
        firstDocumentWorkspaceId: typebox_1.Type.String({ format: 'uuid' }),
        firstDocumentWorkspaceObjectId: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
        firstDocumentId: typebox_1.Type.String(),
        secondDocumentType: typebox_1.Type.Enum(DocumentRelationshipDocumentType),
        secondDocumentWorkspaceId: typebox_1.Type.String({ format: 'uuid' }),
        secondDocumentWorkspaceObjectId: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
        secondDocumentId: typebox_1.Type.String(),
    }),
]);

import { NestedObjectDef } from '@bigdelta/lib-api-client';
import React, { FC } from 'react';

interface IconFromUrlProps {
  iconUrl?: NestedObjectDef[string];
  size?: number;
}

const IconFromUrl: FC<IconFromUrlProps> = ({ iconUrl }) => {
  if (!iconUrl || typeof iconUrl !== 'string') {
    return null;
  }

  return (
    <div className="flex h-4 w-4 shrink-0 items-center justify-center overflow-hidden rounded-sm">
      <img src={iconUrl} className="w-full shrink-0" alt="Icon" />
    </div>
  );
};

export default IconFromUrl;

import { Button } from '../../../shared/ui/Button/Button';
import { Listbox, Option } from '../../../shared/components/Listbox';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { upperFirst } from 'lodash';
import React, { useState, useMemo, FC, useEffect } from 'react';

import { getWorkspaceObjectIcon } from '../../../shared/utils/getWorkspaceObjectIcon';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';
import { useWorkspace } from '../../auth/hooks/useWorkspace';

import StripeIcon from '../../../assets/integrations/Stripe.svg?react';

import { IntegrationSetupIllustration } from './IntegrationSetupIllustration';
import { IntegrationSetupHeading } from './IntegrationSetupHeading';
import { WorkspaceObjectType } from '@bigdelta/lib-shared';
import { toastError, toastSuccess } from '../../../utils/toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppRoutes } from '../../../routes';
import LoadingIcon from '../../../assets/icons/loading-06.svg?react';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

interface StripeIntegrationSetupProps {
  close: () => void;
}

export const StripeIntegrationSetup: FC<StripeIntegrationSetupProps> = ({ close }) => {
  const { currentWorkspaceId } = useWorkspace();

  const queryClient = useQueryClient();
  const queryKeys = useQueryKeys();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const error = searchParams.get('error');
  const state = searchParams.get('state');

  useEffect(() => {
    if (!code || error) {
      close();
      navigate(AppRoutes.INTEGRATIONS);
      toastError('An error occurred while creating Stripe integration');
    }
  }, [code, error, close, navigate]);

  const objectsQuery = useQuery({
    queryKey: queryKeys.list('object'),
    queryFn: () => bigdeltaAPIClient.v1.objectsList({ workspace_id: currentWorkspaceId }),
  });

  const objectsOptions = useMemo(() => {
    if (!objectsQuery.data?.objects) return [];

    return objectsQuery.data?.objects
      .filter((object) => [WorkspaceObjectType.USER, WorkspaceObjectType.ACCOUNT].includes(object.object_type as WorkspaceObjectType))
      .map((object) => ({
        value: object.api_slug,
        label: upperFirst(object.plural_noun),
        icon: getWorkspaceObjectIcon(object.object_type),
      }));
  }, [objectsQuery.data?.objects]);

  const [selectedObjectOption, setSelectedObjectOption] = useState<Option<string> | null>(null);

  useEffect(() => {
    if (objectsOptions.length > 0 && !selectedObjectOption) {
      setSelectedObjectOption(objectsOptions[0]);
    }
  }, [objectsOptions, selectedObjectOption]);

  const createStripeIntegrationMutation = useMutation({
    mutationFn: ({ code }: { code: string; objectSlug: string }) =>
      bigdeltaAPIClient.v1.integrationsStripeCreate({ workspace_id: currentWorkspaceId }, { code: code }),
    onSuccess: (data, variables) => {
      enableStripeIntegrationMutation.mutate({ integrationId: data.id, objectSlug: variables.objectSlug });
    },
    onError: () => {
      toastError('Failed to create Stripe integration');
    },
  });

  const enableStripeIntegrationMutation = useMutation({
    mutationFn: ({ integrationId, objectSlug }: { integrationId: string; objectSlug: string }) =>
      bigdeltaAPIClient.v1.integrationsStripeEnablePartialUpdate(integrationId, { workspace_object_slug: objectSlug }),
    onSuccess: (data) => {
      toastSuccess('Stripe integration created', '');
      queryClient.invalidateQueries(queryKeys.integrations()).then(() => navigate(`${AppRoutes.INTEGRATIONS}/${data.id}`));
    },
    onError: () => {
      toastError('Failed to create Stripe integration');
      navigate(AppRoutes.INTEGRATIONS);
    },
    onSettled: () => {
      close();
    },
  });

  const reconnectStripeIntegrationMutation = useMutation({
    mutationFn: ({ integrationId, code }: { integrationId: string; code: string }) =>
      bigdeltaAPIClient.v1.integrationsStripeReconnectPartialUpdate(integrationId, { code: code }),
    onSuccess: (data) => {
      toastSuccess('Stripe integration reconnected', '');
      queryClient.invalidateQueries(queryKeys.integrations()).then(() => navigate(`${AppRoutes.INTEGRATIONS}/${data.id}`));
    },
    onError: () => {
      toastError('Failed to reconnect Stripe integration');
      navigate(AppRoutes.INTEGRATIONS);
    },
    onSettled: () => {
      close();
    },
  });

  const handleFinish = () => {
    if (!selectedObjectOption) return;
    createStripeIntegrationMutation.mutate({
      code: code,
      objectSlug: selectedObjectOption.value,
    });
  };

  const integrationId = useMemo(() => {
    try {
      return JSON.parse(atob(state))?.integrationId;
    } catch (error) {
      return null;
    }
  }, [state]);

  useEffect(() => {
    if (!state || !code || error) {
      return;
    }

    if (integrationId) {
      reconnectStripeIntegrationMutation.mutate({
        integrationId,
        code,
      });
    } else {
      close();
      navigate(AppRoutes.INTEGRATIONS);
      toastError('Failed to reconnect Stripe integration');
    }
  }, []);

  return (
    <>
      {!state && (
        <>
          <div className="flex flex-col items-center gap-y-8 p-6 pb-3">
            <IntegrationSetupIllustration name="Stripe" icon={StripeIcon} />

            <>
              <IntegrationSetupHeading heading="Select workspace object that represents your Stripe customers" />

              <div className="flex w-full flex-col">
                <Listbox
                  options={objectsOptions}
                  value={selectedObjectOption}
                  placeholder="Select workspace object"
                  onChange={(data) => setSelectedObjectOption(data)}
                />
              </div>
            </>
          </div>

          <hr className="my-8 h-px w-full bg-m-gray-200" />

          <div className="flex w-full justify-end p-6 pt-3">
            <Button intent="brand" label="Finish" onClick={handleFinish} disabled={!selectedObjectOption} />
          </div>
        </>
      )}
      {integrationId && (
        <div className="flex flex-col items-center gap-y-8 p-6 pb-3">
          <IntegrationSetupIllustration name="Stripe" icon={StripeIcon} />
          <IntegrationSetupHeading heading="Reconnecting, please wait..." />
          <LoadingIcon className="h-8 w-8 animate-spin" />
        </div>
      )}
    </>
  );
};

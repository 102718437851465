import { StepHeading } from './StepHeading';
import { StepDescription } from './StepDescription';
import { useOnboardingFormCtx } from '../hooks/useFormContext';
import { TagCloud } from './TagCloud';
import { INDUSTRIES } from '../const';
import { ValidationError } from './ValidationError';

export const Step2DescribeCustomers: React.FC = () => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useOnboardingFormCtx();
  const industries = watch('industries');

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <StepHeading>Describe your customers</StepHeading>
        <StepDescription>We'll automatically build sample list of companies for your workspace</StepDescription>
      </div>
      <div>
        <TagCloud
          label="Select industry your company serves"
          tags={INDUSTRIES}
          value={industries}
          onChange={(newValue) => setValue('industries', newValue)}
        />
        <ValidationError error={errors.industries?.message?.toString()} />
      </div>
    </div>
  );
};

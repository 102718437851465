import { ReactFlowProvider } from 'reactflow';
import { Automation } from '../components/Automation';

export const AutomationsCreatePage = () => {
  return (
    <ReactFlowProvider>
      <Automation />
    </ReactFlowProvider>
  );
};

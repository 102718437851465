import * as Popover from '@radix-ui/react-popover';
import { FC, useRef } from 'react';
import { useTriggerNode } from '../../../hooks/useTriggerNode';
import { TriggerType } from '../../../types';
import { MetadataResourcePropertyType } from '@bigdelta/lib-shared';
import { PropertyNameObject } from '../../../../../shared/types';
import { TopLevelPropertiesCombobox } from '../../../../../shared/components/TopLevelPropertiesCombobox';

const TriggerTypeResourceTypeMap = {
  [TriggerType.EVENT_OCCURRED]: MetadataResourcePropertyType.EVENT,
  [TriggerType.RECORD_CREATED]: MetadataResourcePropertyType.OBJECT,
  [TriggerType.RECORD_UPDATED]: MetadataResourcePropertyType.OBJECT,
  [TriggerType.RECORD_DELETED]: MetadataResourcePropertyType.OBJECT,
};

interface InsertVariableProps {
  onChange: (property: PropertyNameObject | null) => void;
}

export const InsertVariable: FC<InsertVariableProps> = ({ onChange }) => {
  const closeRef = useRef<HTMLButtonElement>(null);

  const triggerNode = useTriggerNode();

  const resourceType = triggerNode?.data.triggerType && TriggerTypeResourceTypeMap[triggerNode?.data.triggerType];

  // TODO: handle missing type (e.g. when trigger not fully configured)
  if (!resourceType) {
    return null;
  }

  const resourceId = resourceType === MetadataResourcePropertyType.EVENT ? triggerNode?.data.eventName : triggerNode?.data.workspaceObject?.id;

  if (!resourceId) {
    return null;
  }

  return (
    <Popover.Root>
      <Popover.Trigger className="rounded-md border border-m-olive-100 bg-m-gray-100 px-1.5 py-0.5 text-xs hover:bg-m-gray-200 radix-state-open:bg-m-gray-200">
        <span>Insert Variable</span>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className="flex max-h-[--radix-popover-content-available-height] flex-col overflow-hidden rounded-lg border border-m-olive-100 bg-m-white p-2"
          collisionPadding={32}
          align="start"
          sideOffset={4}
        >
          <Popover.Close className="hidden" ref={closeRef} />
          <TopLevelPropertiesCombobox
            onChange={(property) => {
              onChange(property);
              closeRef.current?.click();
            }}
            value={null}
            resourceType={resourceType}
            resourceId={resourceId}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

import React, { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import { SettingsNavigation } from '../features/settings/navigation/SettingsNavigation';
import { twMerge } from '../utils/twMerge';

export const SettingsLayout: React.FC<PropsWithChildren> = () => {
  return (
    <>
      <SettingsNavigation />
      <div className={twMerge('ml-64 flex h-screen flex-col p-6 transition-all')}>
        <Outlet />
      </div>
    </>
  );
};

import ColorHash from 'color-hash';
import Color from 'color';

import { NestedObjectDef } from "@bigdelta/lib-api-client";
import { PropsWithClassName } from '../../types';
import { Badge } from './Badge';

type TagValue = Extract<NestedObjectDef[string], any[]>[number];

const colorHash = new ColorHash({
  lightness: [0.85, 0.9],
  saturation: [0.5, 0.6],
});

const getTagStyle = (tag: TagValue) => {
  const tagString = String(tag);
  const bgColor = Color(colorHash.hex(tagString));
  const borderColor = bgColor.darken(0.1);
  const textColor = bgColor.darken(0.75);

  return {
    backgroundColor: bgColor.hex(),
    borderColor: borderColor.hex(),
    color: textColor.hex(),
    borderWidth: '1px',
    borderStyle: 'solid',
  };
};

interface TagProperties extends PropsWithClassName {
  value: TagValue;
}

export const Tag = ({ value, ...rest }: TagProperties) => {
    return (
      <Badge {...rest} style={getTagStyle(value)}>
        {value}
      </Badge>
    );
}
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaterializedDocumentReferencesVODef = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_1 = require("../model");
exports.MaterializedDocumentReferencesVODef = typebox_1.Type.Object({
    records: typebox_1.Type.Optional(typebox_1.Type.Array(model_1.WorkspaceObjectRecordDef)),
    opportunities: typebox_1.Type.Optional(typebox_1.Type.Array(model_1.OpportunityDef)),
    insights: typebox_1.Type.Optional(typebox_1.Type.Array(model_1.InsightDef)),
    projects: typebox_1.Type.Optional(typebox_1.Type.Array(model_1.ProjectDef)),
    reports: typebox_1.Type.Optional(typebox_1.Type.Array(model_1.ReportDef)),
});

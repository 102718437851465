import { twMerge } from "tailwind-merge";
import { FloatingPortal } from '@floating-ui/react';

import data from './dummy_events_breakdown.json';
import CursorClickIcon from '../../../assets/icons/cursor-click-01.svg?react';

import { useShowContentClick } from "../../../shared/hooks/useShowContentClick";

// TODO: Define real type and reuses
type EventData = typeof data.result[number];

interface IconProps {
  monthEvents: any[];
}

const countEvents = (collection: Record<string, number>, event: EventData) => ({
  ...collection,
  [event.event_name]: collection?.[event.event_name] + 1 || 1,
})

export const RecordTimelineEvents = ({ monthEvents }: IconProps) => {
  const { floatingPortalContentProps, referenceProps, isOpen } = useShowContentClick();
  floatingPortalContentProps.className = twMerge('max-w-xs rounded-md p-2 shadow-lg', floatingPortalContentProps.className);

  const eventsAggregate = monthEvents.reduce(countEvents, {});

  return (
    <div role="button" className="relative cursor-pointer" {...referenceProps}>
      <div className="flex shrink-0 h-6 w-6 items-center justify-center rounded-full bg-m-blue-600 text-m-white">
        <CursorClickIcon className="h-4 w-4" />
      </div>
      <div className="flex shrink-0 absolute rounded-full items-center justify-center text-xxs text-center text-m-white bg-m-coconut-800 w-4 h-4 -top-2 -right-2">
        {monthEvents.length}
      </div>
      {isOpen && (
        <FloatingPortal>
          <div {...floatingPortalContentProps}>
              {Object.keys(eventsAggregate).map((eventName) => (
                <div key={eventName} className="text-xxs text-m-olive-500 flex items-center justify-between w-full whitespace-nowrap">
                  <span>{eventName}</span>
                  <span className="ml-4 font-bold">{eventsAggregate[eventName]}</span>
                </div>
              ))}
          </div>
        </FloatingPortal>
      )}
    </div>
  );
}
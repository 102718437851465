"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetricGraphNodeVODef = void 0;
const model_1 = require("../model");
const typebox_1 = require("@sinclair/typebox");
const index_1 = require("./index");
const typebox_2 = require("../typebox");
exports.MetricGraphNodeVODef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        workspace_id: typebox_1.Type.String({ format: 'uuid' }),
        graph_id: typebox_1.Type.String({ format: 'uuid' }),
        key: typebox_1.Type.String(),
        composition: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
            expression: typebox_1.Type.String(),
        })),
        type: typebox_1.Type.Enum(index_1.MetricGraphMetricDefinitionType),
        metric_id: typebox_1.Type.String({ format: 'uuid' }),
        created_by: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        updated_by: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    }),
    model_1.TimestampedModelDef,
]);

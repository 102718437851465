import { AutomationWorkflowBlockType } from '@bigdelta/lib-shared';
import { FC, useMemo } from 'react';
import { AutomationsCreateData } from '@bigdelta/lib-api-client';
import { useWorkspace } from '../../../../auth/hooks/useWorkspace';
import { EventsFilterNew } from '../../../../../shared/filters/components/events/EventsFilterNew';
import { useNodes } from 'reactflow';
import { CustomNodeData } from '../../../types';
import { NodeAutomationBlock, NodeAutomationBlockType } from '../../NodeAutomationBlock';

type AutomationBlock = AutomationsCreateData['configuration']['trigger']['block'];

interface FilterEventConfigProps {
  id?: string;
  type: AutomationWorkflowBlockType;
  block?: AutomationBlock;
  onChange: (block: AutomationBlock) => void;
}

//TODO: Loading state
export const FilterEventConfig: FC<FilterEventConfigProps> = ({ id, block }) => {
  const { currentWorkspaceId } = useWorkspace();

  const nodes = useNodes<CustomNodeData>();

  const currentNode = useMemo(() => {
    return nodes.find((n): n is NodeAutomationBlock => n.id === id && n.type === NodeAutomationBlockType);
  }, [id, nodes]);

  const baseFilterKey = useMemo(() => block?.id && ['automations', 'block', block?.id], [block?.id]);

  if (!baseFilterKey) {
    return;
  }

  return (
    <div className="group/builder">
      <div className="flex flex-col items-stretch">
        <div className="flex flex-col gap-y-6">
          <div className="flex flex-col gap-y-2">
            <div>
              <EventsFilterNew workspaceId={currentWorkspaceId} baseFilterKey={baseFilterKey} objectId={currentNode?.data?.workspaceObjectId} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { AutomationsCreateData } from '@bigdelta/lib-api-client';
import { FC, ReactElement } from 'react';

interface FilterRecordContentProps {
  warning: ReactElement;
  block: AutomationsCreateData['configuration']['trigger']['block'];
}

// TODO: Validation
export const FilterRecordContent: FC<FilterRecordContentProps> = () => {
  return <div className="text-sm font-regular text-m-olive-400">Filter</div>;
};

import { FilterItem, FilterItemType, getFilter, useFilterStore } from '../../store';
import { FilterAdd } from '../common/FilterAdd';

import EventIcon from '../../../../assets/icons/cursor-click-01.svg?react';
import CalendarIcon from '../../../../assets/icons/calendar.svg?react';
import ListIcon from '../../../../assets/icons/list.svg?react';
import { FC, useMemo } from 'react';
import { useFilterContext } from '../../context/useFilterContext';

import BracketsPlusIcon from '../../../../assets/icons/brackets-plus.svg?react';

interface EventsFilterAddProps {
  as?: FC;
  filterItemTypes: FilterItemType[];
  defaultFilterItem?: Partial<FilterItem>;
}

export const EventsFilterAdd: FC<EventsFilterAddProps> = ({ as, filterItemTypes, defaultFilterItem }) => {
  const { filterKey } = useFilterContext();
  const filter = useFilterStore((state) => getFilter(state, filterKey));
  const eventsTimeframeCount = filter?.items.filter((item) => item.itemType === FilterItemType.EVENTS_TIMEFRAME).length;

  const options = useMemo(
    () =>
      [
        {
          value: FilterItemType.EVENTS_NAME,
          label: 'Event name',
          icon: EventIcon,
        },
        {
          value: FilterItemType.EVENTS_PROPERTY,
          label: 'Event property',
          icon: EventIcon,
        },
        {
          value: FilterItemType.EVENTS_RECORD_PROPERTY,
          label: 'Record property',
          icon: ListIcon,
        },
        {
          value: FilterItemType.METRICS_PROPERTY,
          label: 'Metric property',
          icon: BracketsPlusIcon,
        },
        {
          value: FilterItemType.METRICS_RECORD_PROPERTY,
          label: 'Record property',
          icon: ListIcon,
        },
        ...(eventsTimeframeCount === 0
          ? [
              {
                value: FilterItemType.EVENTS_TIMEFRAME,
                label: 'Event timeframe',
                icon: CalendarIcon,
              },
            ]
          : []),
      ].filter((option) => filterItemTypes.includes(option.value)),
    [eventsTimeframeCount, filterItemTypes]
  );

  return <FilterAdd options={options} as={as} defaultFilterItem={defaultFilterItem} />;
};

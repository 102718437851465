"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetadataResourcePropertyDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const types_1 = require("../types");
exports.MetadataResourcePropertyDef = typebox_1.Type.Object({
    workspaceId: typebox_1.Type.String({ format: 'uuid' }),
    resourceType: types_1.MetadataResourcePropertyTypeType,
    resourceId: typebox_1.Type.String(),
    propertyId: typebox_1.Type.String({ format: 'uuid' }),
    propertyName: typebox_1.Type.String(),
    propertyType: (0, typebox_2.OptionalNullable)(types_1.ResourcePropertyTypeType),
    propertyValue: typebox_1.Type.String(),
});

/* eslint-disable no-case-declarations */
import React, { useMemo, useCallback, useRef, useEffect, useState, FC, HTMLProps, PropsWithChildren } from 'react';
import { Editor, Transforms, Range, createEditor, Descendant, BaseEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Slate, Editable, ReactEditor, withReact, useSelected, useFocused, RenderElementProps } from 'slate-react';

import { createPortal } from 'react-dom';
import { CustomEditor, MetricElement } from '../../../../shared/slate';
import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../../../auth/hooks/useQueryKeys';

import { useWorkspace } from '../../../auth/hooks/useWorkspace';
import { MetricsListData } from '@bigdelta/lib-api-client';
import { twMerge } from '../../../../utils/twMerge';
import { bigdeltaAPIClient } from '../../../../client/bigdeltaAPIClient.ts';
import { VariableSuggestions } from '../../../../shared/components/VariableSuggestions';
import { VariableInputElementType } from '../../../../shared/types';

interface PortalProps extends PropsWithChildren, HTMLProps<HTMLDivElement> {}

const isMetricElement = (element: any): element is MetricElement => element.type === 'metric';

export const Portal: FC<PortalProps> = ({ children, onClick }) => {
  return typeof document === 'object'
    ? createPortal(
        <div className="absolute left-0 top-0 z-50 h-full w-full" onClick={onClick}>
          {children}
        </div>,
        document.body
      )
    : null;
};

interface FormulaInput {
  onChange: (value: Descendant[]) => void;
  initialValue?: Descendant[];
  isError?: boolean;
}

export const FormulaInput: FC<FormulaInput> = ({ onChange, initialValue, isError = false }) => {
  const { currentWorkspaceId } = useWorkspace();

  const ref = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [target, setTarget] = useState<Range | null>();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState('');
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withSingleLine(withMetrics(withReact(withHistory(createEditor())))), []);

  const queryKeys = useQueryKeys();

  const metricsQuery = useQuery({
    queryKey: queryKeys.list('metric'),
    queryFn: () => bigdeltaAPIClient.v1.metricsList({ workspace_id: currentWorkspaceId }),
  });

  const metricsList = useMemo(() => {
    if (!search.length) {
      return metricsQuery.data?.items.slice(0, 10) ?? [];
    }

    return (
      metricsQuery.data?.items
        .filter((metric) => metric.display_name.toLowerCase().startsWith(search.replace(/[^a-zA-Z]/g, '').toLowerCase()))
        .slice(0, 10) ?? []
    );
  }, [metricsQuery.data?.items, search]);

  const onKeyDown = useCallback(
    (event) => {
      if (target && metricsList.length > 0) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            const prevIndex = index >= metricsList.length - 1 ? 0 : index + 1;
            setIndex(prevIndex);
            break;
          case 'ArrowUp':
            event.preventDefault();
            const nextIndex = index <= 0 ? metricsList.length - 1 : index - 1;
            setIndex(nextIndex);
            break;
          case 'Enter':
            event.preventDefault();
            Transforms.select(editor, target);
            insertMetric(editor, metricsList[index]);
            setTarget(null);
            break;
          case 'Escape':
            event.preventDefault();
            setTarget(null);
            break;
          case 'Tab':
            setTarget(null);
            setSearch('');
        }
      }
    },
    [editor, index, metricsList, target]
  );

  useEffect(() => {
    if (target && metricsList.length > 0) {
      const el = ref.current;
      const container = containerRef.current;
      const containerRect = container?.getBoundingClientRect();

      if (!el || !containerRect) return;

      el.style.width = `${containerRect.width}px`;
      el.style.top = `${containerRect.bottom + window.pageYOffset + 6}px`;
      el.style.left = `${containerRect.left + window.pageXOffset}px`;
    }
  }, [editor, index, metricsList.length, search, target]);

  const handleSlateChange = (value: Descendant[]) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection);

      // Function to check if a character is a letter
      const isLetter = (char) => /^[a-zA-Z]$/.test(char);

      // Find the start of the word, considering only letters
      let wordStart = start;
      while (wordStart.offset > 0) {
        const before = Editor.before(editor, wordStart);

        if (!before) break;

        const char = Editor.string(editor, { anchor: before, focus: wordStart });
        if (!isLetter(char)) break;
        wordStart = before;
      }

      if (wordStart.offset < start.offset) {
        // Create a range from the start of the word to the cursor
        const wordRange = Editor.range(editor, wordStart, start);
        // Get the text of the current word, filtering out non-letters
        const wordText = Editor.string(editor, wordRange).replace(/[^a-zA-Z]/g, '');

        setTarget(wordRange);
        setSearch(wordText);
      } else {
        // If we can't find a word start, just use the cursor position
        setTarget(selection);
        setSearch('');
      }
    } else {
      // If there's no selection or it's not collapsed, clear the target and search
      setTarget(null);
      setSearch('');
    }

    // Always call the onChange function
    onChange(value);
  };

  const handleMetricSuggestionClick = (metric: MetricsListData['items'][number]) => {
    if (!target) return;

    Transforms.select(editor, target);
    ReactEditor.focus(editor);
    insertMetric(editor, metric);
    setTarget(null);
  };

  const handleSuggestionsOutsideClick = () => {
    setTarget(null);
    setSearch('');
    ReactEditor.blur(editor);
  };

  return (
    <div ref={containerRef}>
      <Slate editor={editor} initialValue={initialValue ?? defaultInitialValue} key={JSON.stringify(initialValue)} onChange={handleSlateChange}>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={onKeyDown}
          className={twMerge(
            '!overflow-hidden !whitespace-nowrap rounded-lg border border-m-olive-100 p-2 pl-3 text-sm placeholder:text-sm placeholder:text-m-olive-300 focus:!overflow-visible focus:!whitespace-pre-wrap',
            isError && 'border-m-red-600 outline-m-red-600'
          )}
          renderPlaceholder={({ children, attributes }) => (
            <span className="p-2 pl-0 tracking-normal" {...attributes}>
              {children}
            </span>
          )}
          placeholder="A/B+C or search for a metric"
        />
        {target && metricsList.length > 0 && search && (
          <VariableSuggestions
            suggestionsList={metricsList}
            onSuggestionClick={handleMetricSuggestionClick}
            renderSuggestion={(metric) => metric.display_name}
            onOutsideClick={handleSuggestionsOutsideClick}
            rovingIndex={index}
            ref={ref}
          />
        )}
      </Slate>
    </div>
  );
};

const withMetrics = <T extends BaseEditor>(editor: T): T => {
  const { isInline, isVoid, markableVoid } = editor;

  editor.isInline = (element) => {
    return element.type === 'metric' ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === 'metric' ? true : isVoid(element);
  };

  editor.markableVoid = (element) => {
    return element.type === 'metric' || markableVoid(element);
  };

  return editor;
};

const withSingleLine = <T extends CustomEditor>(editor: T): T => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      if (editor.children.length > 1) {
        Transforms.mergeNodes(editor);
      }
    }

    return normalizeNode([node, path]);
  };

  return editor;
};

const insertMetric = (editor: CustomEditor, metricData: MetricsListData['items'][number]) => {
  const metricElement: MetricElement = {
    type: VariableInputElementType.Metric,
    metric: metricData,
    children: [{ text: '' }],
    hasData: true,
  };
  Transforms.insertNodes(editor, metricElement);
  Transforms.move(editor);
};

const Leaf = ({ attributes, children }) => {
  return <span {...attributes}>{children}</span>;
};

const Element: FC<RenderElementProps> = (props) => {
  const { attributes, children, element } = props;

  if (isMetricElement(element)) {
    return <Metric element={element} children={children} attributes={attributes} />;
  }

  return (
    <p {...attributes} className="leading-[2rem] tracking-wider">
      {children}
    </p>
  );
};

interface MetricProps extends Omit<RenderElementProps, 'element'> {
  element: MetricElement;
}

const Metric: FC<MetricProps> = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();

  const queryKeys = useQueryKeys();

  const metricQuery = useQuery({
    queryKey: queryKeys.single('metric', element.metric.id),
    queryFn: () => bigdeltaAPIClient.v1.metricsDetail(element.metric.id!),
    enabled: !element.hasData && !!element.metric.id,
  });

  return (
    <span
      {...attributes}
      contentEditable={false}
      className={twMerge(
        'mx-px my-0.5 inline-block select-none rounded-full bg-m-gray-300 p-0.5 px-2 py-1 align-baseline text-sm leading-normal tracking-normal',
        selected && focused && 'shadow-[0_0_0_2px_theme(colors.m-blue.400)]'
      )}
    >
      {element.metric.display_name ?? metricQuery.data?.display_name ?? '...'}
      {children}
    </span>
  );
};

const defaultInitialValue: Descendant[] = [
  {
    type: VariableInputElementType.Paragraph,
    children: [{ text: '' }],
  },
];

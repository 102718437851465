import { createScopedStore, stateReq } from '@lukesmurray/zustand-scoped';
import { produce } from 'immer';

import { MetricMath } from '@bigdelta/lib-shared';
import { Descendant } from 'slate';
import { cloneDeep } from 'lodash';
import { EventCount, EventCountData, ObjectCountType, RecordCount, RecordCountData, ReportBuilderState, ReportBuilderStateData } from './common';

export enum TrendsBuilderType {
  FORMULA = 'formula',
  OBJECT = 'object',
  METRIC = 'metric',
}

export type TrendsCount = RecordCount | EventCount;

export interface TrendsBuilderStateData<Count extends TrendsCount = TrendsCount> extends ReportBuilderStateData<TrendsBuilderStateData<Count>> {
  label?: string; // User defined label
  isEditingLabel?: boolean;
  name: string; // A, B, C...
  type: TrendsBuilderType;
  data: {
    [TrendsBuilderType.FORMULA]?: {
      expression?: string;
      expressionRaw?: Descendant[];
      expressionRawOriginal?: Descendant[]; // Used to store the original expression in order to initialize Slate only once
      expressionError?: string;
    };
    [TrendsBuilderType.OBJECT]?: {
      workspaceObjectId?: string;
      count: Count;
    };
    [TrendsBuilderType.METRIC]?: {
      metricId?: string;
    };
  };
}

export interface TrendsBuilderState extends TrendsBuilderStateData, ReportBuilderState {
  setLabel: (label: string | undefined) => void;
  setIsEditingLabel: (isEditingLabel: boolean) => void;
  setFormulaExpression: (expression: string) => void;
  setFormulaExpressionRaw: (expressionRaw: Descendant[]) => void;
  setFormulaExpressionError: (error?: string) => void;
  setCountRecordData: (recordCountData: RecordCountData) => void;
  setCountEventData: (EventCountData: EventCountData) => void;
  setCountAggregate: (aggregate: MetricMath) => void;
}

export interface TrendsBuilderInitialData {
  id: string;
  label?: string;
  name: string;
  type: TrendsBuilderType;
  data: TrendsBuilderState['data'];
  metricId?: TrendsBuilderState['metricId'];
  initialMetricBuilderData?: TrendsBuilderState['initialMetricBuilderData'];
}

export const createTrendsBuilderStore = createScopedStore<TrendsBuilderState, TrendsBuilderInitialData>()((initialData: TrendsBuilderInitialData) => {
  return stateReq((set) => ({
    ...initialData,
    setLabel: (label) =>
      set((state) =>
        produce(state, (draft) => {
          draft.label = label;
        })
      ),
    setIsEditingLabel: (isEditingLabel) =>
      set((state) =>
        produce(state, (draft) => {
          draft.isEditingLabel = isEditingLabel;
        })
      ),
    setFormulaExpression: (expression) =>
      set((state) =>
        produce(state, (draft) => {
          if (!draft.data.formula) {
            draft.data.formula = {};
          }
          draft.data.formula.expression = expression;
        })
      ),
    setFormulaExpressionError: (error) =>
      set((state) =>
        produce(state, (draft) => {
          if (!draft.data.formula) {
            draft.data.formula = {};
          }
          draft.data.formula.expressionError = error;
        })
      ),

    setFormulaExpressionRaw: (expressionRaw) =>
      set((state) =>
        produce(state, (draft) => {
          if (!draft.data.formula) {
            draft.data.formula = {};
          }
          draft.data.formula.expressionRaw = expressionRaw;
        })
      ),

    setCountRecordData: (recordCountData) =>
      set((state) =>
        produce(state, (draft) => {
          if (!draft.data[TrendsBuilderType.OBJECT]) {
            return;
          }

          draft.data[TrendsBuilderType.OBJECT].count = {
            type: ObjectCountType.RECORD,
            record: recordCountData,
            aggregate: MetricMath.SUM,
          };

          if (recordCountData.type === 'property') {
            draft.data[TrendsBuilderType.OBJECT].count.aggregate = MetricMath.DISTINCT_TOTAL;
          }

          if (recordCountData.type === 'total') {
            draft.data[TrendsBuilderType.OBJECT].count.aggregate = MetricMath.TOTAL;
          }
        })
      ),

    setCountEventData: (eventCountData) =>
      set((state) =>
        produce(state, (draft) => {
          if (!draft.data[TrendsBuilderType.OBJECT]) {
            return;
          }

          draft.data[TrendsBuilderType.OBJECT].count.type = ObjectCountType.EVENT;
          (draft.data[TrendsBuilderType.OBJECT].count as EventCount).event = eventCountData;

          if (eventCountData.type === 'property') {
            draft.data[TrendsBuilderType.OBJECT].count.aggregate = MetricMath.DISTINCT_TOTAL;
          }

          if (eventCountData.type === 'event' || eventCountData.type === 'allEvents') {
            draft.data[TrendsBuilderType.OBJECT].count.aggregate = MetricMath.TOTAL;
          }
        })
      ),

    setCountAggregate: (aggregate) =>
      set((state) =>
        produce(state, (draft) => {
          if (!draft.data[TrendsBuilderType.OBJECT]) {
            return;
          }

          draft.data[TrendsBuilderType.OBJECT].count.aggregate = aggregate;
        })
      ),

    resetInitialMetricBuilderData: () =>
      set((state) =>
        produce(state, (draft) => {
          const { initialMetricBuilderData: _, ...builderClone } = cloneDeep(state);

          draft.initialMetricBuilderData = builderClone;
        })
      ),

    setBuilder: (builder) =>
      set((state) =>
        produce(state, (draft) => {
          Object.assign(draft, builder);
        })
      ),
  }));
});

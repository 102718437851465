"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackingKeyResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const memberResponse_1 = require("./memberResponse");
exports.TrackingKeyResponseDef = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    account_id: typebox_1.Type.String({ format: 'uuid' }),
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    name: typebox_1.Type.String(),
    key: typebox_1.Type.String(),
    created_by: (0, typebox_2.OptionalNullable)(memberResponse_1.MemberResponseDef),
    created_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BigQueryDatasetVODef = exports.BigQueryTableVODef = exports.BigQueryColumnVODef = exports.BigQueryColumnMode = exports.BigQueryDataType = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
var BigQueryDataType;
(function (BigQueryDataType) {
    BigQueryDataType["INTEGER"] = "INTEGER";
    BigQueryDataType["FLOAT"] = "FLOAT";
    BigQueryDataType["NUMERIC"] = "NUMERIC";
    BigQueryDataType["BIGNUMERIC"] = "BIGNUMERIC";
    BigQueryDataType["BOOLEAN"] = "BOOLEAN";
    BigQueryDataType["STRING"] = "STRING";
    BigQueryDataType["BYTES"] = "BYTES";
    BigQueryDataType["DATE"] = "DATE";
    BigQueryDataType["DATETIME"] = "DATETIME";
    BigQueryDataType["TIME"] = "TIME";
    BigQueryDataType["TIMESTAMP"] = "TIMESTAMP";
    BigQueryDataType["RECORD"] = "RECORD";
    BigQueryDataType["GEOGRAPHY"] = "GEOGRAPHY";
    BigQueryDataType["JSON"] = "JSON";
})(BigQueryDataType || (exports.BigQueryDataType = BigQueryDataType = {}));
var BigQueryColumnMode;
(function (BigQueryColumnMode) {
    BigQueryColumnMode["NULLABLE"] = "NULLABLE";
    BigQueryColumnMode["REQUIRED"] = "REQUIRED";
    BigQueryColumnMode["REPEATED"] = "REPEATED";
})(BigQueryColumnMode || (exports.BigQueryColumnMode = BigQueryColumnMode = {}));
exports.BigQueryColumnVODef = typebox_1.Type.Object({
    name: typebox_1.Type.String(),
    type: typebox_1.Type.Enum(BigQueryDataType),
    mode: (0, typebox_2.OptionalNullable)(typebox_1.Type.Enum(BigQueryColumnMode)),
});
exports.BigQueryTableVODef = typebox_1.Type.Object({
    name: typebox_1.Type.String(),
    columns: typebox_1.Type.Array(exports.BigQueryColumnVODef),
});
exports.BigQueryDatasetVODef = typebox_1.Type.Object({
    name: typebox_1.Type.String(),
    tables: typebox_1.Type.Array(exports.BigQueryTableVODef),
});

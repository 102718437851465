"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventRelationsDef = exports.EventRelationDef = void 0;
const typebox_1 = require("../typebox");
const typebox_2 = require("@sinclair/typebox");
exports.EventRelationDef = typebox_2.Type.Object({
    object_slug: typebox_2.Type.String(),
    record_id: typebox_2.Type.String(),
    set: (0, typebox_1.OptionalNullable)(typebox_1.NestedObjectDef),
    set_once: (0, typebox_1.OptionalNullable)(typebox_1.NestedObjectDef),
});
exports.EventRelationsDef = (0, typebox_1.OptionalNullable)(typebox_2.Type.Array(exports.EventRelationDef));

import { twMerge } from 'tailwind-merge';
import { FC, useCallback, useMemo } from 'react';
import { FilterItem, FilterItemType, useFilterStore } from '../../store';
import { RelationshipEntityType } from '@bigdelta/lib-shared';
import { useFilterContext } from '../../context/useFilterContext';
import { PropertyNameObject, RelationshipObjectData } from '../../../types';

import { RecordPropertyMultilevelSelect as RecordPropertyMultilevelSelectBase } from '../../../components/RecordPropertyMultilevelSelect';
import { RecordPropertyMultilevelTriggerLabel } from '../../../components/RecordPropertyMultilevelTriggerLabel';

interface RecordPropertyMultilevelSelectProps {
  index: number;
  filterDataItem: FilterItem;
  parentEntityType: RelationshipEntityType;
  parentEntityId: string | null;
}

export const RecordPropertyMultilevelSelect: FC<RecordPropertyMultilevelSelectProps> = ({
  index,
  filterDataItem,
  parentEntityType,
  parentEntityId,
}) => {
  const { mode, filterKey } = useFilterContext();
  const setPropertyWithRelationship = useFilterStore((state) => state.setPropertyWithRelationships);

  const reset = useCallback(() => {
    setPropertyWithRelationship(filterKey, index, undefined, []);
  }, [filterKey, index, setPropertyWithRelationship]);

  const handleChange = (property: PropertyNameObject | null, rels: RelationshipObjectData[] | null) => {
    if (!property?.property_type || !rels) {
      return;
    }

    setPropertyWithRelationship(
      filterKey,
      index,
      {
        attributeId: property.property_id,
        attributeType: property.property_type,
        attributeName: property.property_name,
      },
      rels
    );
  };

  const triggerPopertyRelationships = useMemo(() => {
    return filterDataItem.itemType === FilterItemType.EVENTS_RECORD_PROPERTY || filterDataItem.itemType === FilterItemType.METRICS_RECORD_PROPERTY
      ? [{ objectId: '' }, ...filterDataItem.propertyRelationships]
      : filterDataItem.propertyRelationships;
  }, [filterDataItem.itemType, filterDataItem.propertyRelationships]);

  return (
    <RecordPropertyMultilevelSelectBase
      parentEntityId={parentEntityId}
      parentEntityType={parentEntityType}
      onChange={handleChange}
      reset={reset}
      triggerLabel={<RecordPropertyMultilevelTriggerLabel propertyRelationships={triggerPopertyRelationships} property={filterDataItem.property} />}
      buttonClassName={twMerge(mode === 'stacked' && 'w-full')}
      shouldOpen={!filterDataItem.property}
    />
  );
};

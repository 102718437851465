"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MemberPermissionRole = exports.PermissionEntityType = void 0;
var PermissionEntityType;
(function (PermissionEntityType) {
    PermissionEntityType["ACCOUNT"] = "ACCOUNT";
})(PermissionEntityType || (exports.PermissionEntityType = PermissionEntityType = {}));
var MemberPermissionRole;
(function (MemberPermissionRole) {
    MemberPermissionRole["OWNER"] = "OWNER";
    MemberPermissionRole["ADMIN"] = "ADMIN";
})(MemberPermissionRole || (exports.MemberPermissionRole = MemberPermissionRole = {}));

import { ObjectsListData, RelationshipsListData } from '@bigdelta/lib-api-client';
import { RelationshipEntityType } from '@bigdelta/lib-shared';

export const getRelatedObjectsList = (
  relationshipNames: string[],
  parentEntityType: RelationshipEntityType,
  parentEntityId: string | undefined,
  relationships: RelationshipsListData['relationships'],
  objects: ObjectsListData['objects']
) => {
  return relationshipNames
    .map((relationshipName) => relationships.find((rel) => rel.name === relationshipName))
    .map((relationship) => {
      if (parentEntityType === RelationshipEntityType.EVENT) {
        return relationship?.first_entity_type === RelationshipEntityType.EVENT ? relationship?.second_entity_id : relationship?.first_entity_id;
      }

      if (parentEntityType === RelationshipEntityType.OBJECT) {
        return relationship?.first_entity_id === parentEntityId ? relationship?.second_entity_id : relationship?.first_entity_id;
      }
    })
    .map((objectId) => objects.find((object) => object.id === objectId));
};

import { ReportSourceType } from '../store';

export const getMetricStateKey = (sourceType: ReportSourceType) => {
  switch (sourceType) {
    case ReportSourceType.EVENTS:
      return 'metricEvent';
    case ReportSourceType.METRIC:
      return 'metricMetric';
    case ReportSourceType.RECORDS:
      return 'metricObject';
  }
};

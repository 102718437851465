import { PageHeading } from '../../../components/PageHeading';
import { useQuery } from '@tanstack/react-query';

import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { Button } from '../../../shared/ui/Button/Button';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';
import { ObjectsTable } from '../components/ObjectsTable.tsx';
import React, { useMemo, useState } from 'react';
import SearchIcon from '../../../assets/icons/search-md.svg?react';
import { Link } from 'react-router-dom';
import { ObjectsRoutes } from '../../../routes';
import { CenteredLoader } from '../../../shared/components/CenteredLoader.tsx';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

export const ObjectsListPage = () => {
  const queryKeys = useQueryKeys();
  const { currentWorkspaceId } = useWorkspace();

  const objectsQuery = useQuery({
    queryKey: queryKeys.list('object', 'active_and_inactive'),
    queryFn: () => bigdeltaAPIClient.v1.objectsList({ workspace_id: currentWorkspaceId, status: 'ACTIVE_AND_INACTIVE' }),
  });

  const [searchTerm, setSearchTerm] = useState('');

  const filteredObjects = useMemo(() => {
    return objectsQuery.data?.objects?.filter((object) => object.plural_noun.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [objectsQuery, searchTerm]);

  return (
    <div className="flex h-screen w-full flex-col">
      <div className="flex flex-col">
        {!objectsQuery.isInitialLoading && (
          <>
            <div className="flex items-center justify-between" style={{ zIndex: 1 }}>
              <div>
                <PageHeading breadcrumbs={[{ label: 'Data', description: 'Modify and add objects in your workspace' }]} />
              </div>
              <div className="flex items-center gap-x-2">
                <div className="relative">
                  <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
                    <SearchIcon className="h-5 w-5 text-m-gray-600" />
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className="block w-full rounded-md border border-m-gray-300 p-2 ps-10 text-md"
                    placeholder="Search objects"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <Link to={ObjectsRoutes.SETTINGS_WORKSPACE_NEW}>
                  <Button intent="brand" size="lg" label="Create custom object" />
                </Link>
              </div>
            </div>
            <hr className="mt-4 h-px bg-m-gray-300" />
            <ObjectsTable data={filteredObjects} />
          </>
        )}
        {objectsQuery.isInitialLoading && <CenteredLoader />}
      </div>
    </div>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutomationVODef = void 0;
const typebox_1 = require("@sinclair/typebox");
const automation_1 = require("../model/automation");
const typebox_2 = require("../typebox");
exports.AutomationVODef = typebox_1.Type.Composite([
    typebox_1.Type.Omit(automation_1.AutomationDef, ['id']),
    typebox_1.Type.Object({
        id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    }),
]);

import { ChangeEvent, FC } from 'react';
import { useFilterContext } from '../../context/useFilterContext';
import { useFilterStore } from '../../store';
import { format } from 'date-fns';

interface PropertyValueDateProps {
  index: number;
}

export const PropertyValueDate: FC<PropertyValueDateProps> = ({ index }) => {
  const { filterKey } = useFilterContext();
  const filterItem = useFilterStore((state) => state.getFilterItem([...filterKey, index]));

  const setItemDataValue = useFilterStore((state) => state.setItemDataValue);

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setItemDataValue(filterKey, index, new Date(value).toISOString());
  };

  const inputValue = format(new Date(filterItem?.data.value as string), 'yyyy-MM-dd');

  return (
    <input type="date" className="rounded-lg border border-m-gray-300 px-3 py-1.5 text-md font-medium" onChange={handleChange} value={inputValue} />
  );
};

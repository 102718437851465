import { WorkspaceProvider } from '../context/workspaceContext.tsx';
import React, { useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { UserContext } from '../context/userContext.tsx';

export const WorkspaceContextRoute = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  useEffect(() => {
    if (userContext.ready) {
      if (!userContext.workspaces?.length) {
        navigate('/workspaces/onboarding/' + userContext.accounts[0].id);
      }
    }
  }, [
    navigate,
    userContext.ready,
    userContext.authenticated,
    userContext.user,
    userContext.accounts.length,
    userContext.workspaces?.length,
    userContext.accounts,
  ]);

  if (!userContext.ready || !userContext.authenticated) {
    return null;
  }

  return (
    <WorkspaceProvider>
      <Outlet />
    </WorkspaceProvider>
  );
};

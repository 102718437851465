import { FC, HTMLAttributes, LabelHTMLAttributes, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import { cva, type VariantProps } from 'class-variance-authority';

const elementVariants = cva('', {
  variants: {
    size: {
      xl: 'text-lg font-medium',
      lg: 'text-md font-medium',
      md: 'text-sm font-medium',
      sm: 'text-sm font-regular',
      xs: 'text-xs font-regular',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export type FormElementWrapperProps = HTMLAttributes<HTMLElement> & VariantProps<typeof elementVariants> & {
  label: string;
  errorMessage?: string;
};

const FormLabel: FC<LabelHTMLAttributes<HTMLLabelElement>> = ({ children, ...props }) => (
  <label className="mb-1.5" {...props}>
    {children}
  </label>
);

export const FormElementWrapper = ({ label, errorMessage, children, className, size }: PropsWithChildren & FormElementWrapperProps) => (
  <div className={twMerge('flex flex-col', elementVariants({ size }) , className)}>
    {label && <FormLabel>{label}</FormLabel>}
    {children}
    {errorMessage && <div className="mt-1 text-sm text-m-red-700">{errorMessage}</div>}
  </div>
);

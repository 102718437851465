import { useWorkspace } from '../../features/auth/hooks/useWorkspace.tsx';
import { useRelationshipsQuery } from '../data/useRelationshipsQuery.ts';
import React, { FC, useCallback, useMemo } from 'react';
import { RelationshipEntityType } from '@bigdelta/lib-shared';
import { getObjectsRelatedToObject } from '../utils/getObjectsRelatedToObject.ts';
import { getObjectsRelatedToEvent } from '../utils/getObjectsRelatedToEvent.ts';
import { useObjectsQuery } from '../data/useObjectsQuery.ts';
import { startCase } from 'lodash';

type Props = {
  value: string;
  parentEntityType: RelationshipEntityType;
  parentEntityId?: string | null;
  onChange: (relationshipName: string) => void;
};

export const RelationshipSelect: FC<Props> = ({ value, parentEntityType, parentEntityId, onChange }) => {
  const { currentWorkspaceId } = useWorkspace();
  const { data: objectList } = useObjectsQuery({ workspaceId: currentWorkspaceId });
  const { data: relationshipData } = useRelationshipsQuery({ workspaceId: currentWorkspaceId });

  const getObjectName = useCallback(
    (id?: string) => {
      if (!objectList || !id) {
        return;
      }

      return startCase(objectList.objects.find((obj) => obj.id === id)?.singular_noun);
    },
    [objectList]
  );

  const options = useMemo(() => {
    if (!relationshipData) {
      return [];
    }

    if (
      (parentEntityType === RelationshipEntityType.OBJECT && !!parentEntityId) ||
      (parentEntityType === RelationshipEntityType.EVENT && !!parentEntityId)
    ) {
      return getObjectsRelatedToObject({ entityId: parentEntityId, relationships: relationshipData.relationships });
    }

    if (parentEntityType === RelationshipEntityType.EVENT) {
      return getObjectsRelatedToEvent({ relationships: relationshipData.relationships });
    }

    return [];
  }, [parentEntityId, parentEntityType, relationshipData]);

  return (
    <select value={value} className="rounded-lg border border-m-olive-100 text-sm" onChange={(evt) => onChange(evt.target.value)}>
      {options.map((item) => (
        <option key={item.relationshipName} value={item.relationshipName}>
          {getObjectName(item?.objectId)}
        </option>
      ))}
    </select>
  );
};

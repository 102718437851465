import { QuickAccessLinkType } from '@bigdelta/lib-shared';
import { PageHeading } from '../../../components/PageHeading';
import { FC } from 'react';

interface ReportHeadingProps {
  reportId?: string;
  reportTitle: string | null;
}

export const ReportHeading: FC<ReportHeadingProps> = ({ reportId, reportTitle }) => {
  return (
    <PageHeading
      breadcrumbs={[{ label: 'Reports', to: '/reports' }, { label: reportTitle ?? 'New Report' }]}
      favoriteLinkConfig={
        reportId
          ? {
              type: QuickAccessLinkType.REPORT,
              label: reportTitle ?? 'New Report',
              data: {
                report: {
                  reportId: reportId,
                },
              },
            }
          : undefined
      }
    />
  );
};

import { FocusTrap, Popover } from '@headlessui/react';
import { useWorkspace } from '../../../auth/hooks/useWorkspace';
import FilterIcon from '../../../../assets/icons/filter-funnel-02.svg?react';
import { ButtonHTMLAttributes, FC, forwardRef, useCallback } from 'react';
import { lowerCase, snakeCase } from 'lodash';
import { twMerge } from '../../../../utils/twMerge';
import { Tooltip } from 'react-tooltip';
import { Float } from '@headlessui-float/react';
import { FilterOptions, FilterTab, OnOptionSelectHandler } from '../../../../shared/filters/components/common/FilterOptions';
import { FilterItemType, useFilterStore } from '../../../../shared/filters/store';
import { useFilterContext } from '../../../../shared/filters/context/useFilterContext';
import { RelationshipEntityType } from '@bigdelta/lib-shared';
import { OpenTrigger } from '../../../../shared/components/OpenTrigger';

// TODO: reuse ActionButtonWithTooltip from shared/filters/components/common/FilterCard
type ActionButtonWithTooltipProps = ButtonHTMLAttributes<HTMLButtonElement> & { tooltipText: string };
const ActionButtonWithTooltip = forwardRef<HTMLButtonElement, ActionButtonWithTooltipProps>(
  ({ children, tooltipText, className = '', ...restProps }, ref) => {
    const tooltipId = `${snakeCase(lowerCase(tooltipText))}-${+new Date()}`;
    return (
      <>
        <button
          data-tooltip-id={tooltipId}
          className={twMerge(
            'rounded p-1 text-m-olive-300 opacity-0 transition-opacity hover:bg-m-gray-300 hover:opacity-100 group-hover/builder:opacity-100 ui-open:opacity-100',
            className
          )}
          {...restProps}
          ref={ref}
        >
          {children}
        </button>
        <Tooltip className="!z-10 !px-2 !py-0.5 opacity-0 group-hover/builder:opacity-100" id={tooltipId} place="top">
          {tooltipText}
        </Tooltip>
      </>
    );
  }
);

interface RecordsFilterAddProps {
  objectId: string;
  defaultOpen?: boolean;
  supportedTabs?: FilterTab[];
}



export const RecordsFilterAdd: FC<RecordsFilterAddProps> = ({ objectId, supportedTabs, defaultOpen = false }: RecordsFilterAddProps) => {
  const { currentWorkspaceId } = useWorkspace();
  const { filterKey } = useFilterContext();
  const addFilterItem = useFilterStore((state) => state.addFilterItem);
  const setPropertyWithRelationships = useFilterStore((state) => state.setPropertyWithRelationships);
  const setItemEvent = useFilterStore((state) => state.setItemEvent);
  const setPropertyRelationships = useFilterStore((state) => state.setPropertyRelationships);

  // TODO: Move this into filter select
  const handleFilterSelect = useCallback<OnOptionSelectHandler>(
    (option) => {
      if (!option.relationships) {
        return;
      }

      if (option.type === RelationshipEntityType.OBJECT) {
        const { index } = addFilterItem(filterKey, FilterItemType.RECORDS_PROPERTY, {});
        setPropertyWithRelationships(
          filterKey,
          index,
          {
            attributeId: option.property.property_id,
            attributeType: option.property.property_type!,
            attributeName: option.property.property_name,
          },
          option.relationships
        );
      }

      if (option.type === RelationshipEntityType.EVENT) {
        const { index } = addFilterItem(filterKey, FilterItemType.RECORDS_EVENT, {});
        setItemEvent(filterKey, index, option.event.event_name);
        setPropertyRelationships(filterKey, index, option.relationships);
      }
    },
    [addFilterItem, filterKey, setItemEvent, setPropertyRelationships, setPropertyWithRelationships]
  );

  // TODO: Refactor into radix popover
  return (
    <Popover>
      {({ close }) => (
        <Float offset={{ mainAxis: 15, crossAxis: 14 }} portal placement="bottom-end">
          <Popover.Button as={ActionButtonWithTooltip} tooltipText={'Filter'}>
            <FilterIcon className="h-4 w-4 text-m-olive-300" />
            {defaultOpen && <OpenTrigger shouldOpen={true} />}
          </Popover.Button>
          <Popover.Panel>
            <FocusTrap>
              <div className="flex w-[20rem] flex-col gap-y-px overflow-hidden rounded-xl border border-m-gray-300 bg-m-white text-md text-m-olive-900 shadow-xl">
                <FilterOptions
                  objectId={objectId}
                  supportedTabs={supportedTabs}
                  workspaceId={currentWorkspaceId}
                  onOptionSelect={(selectedOption) => {
                    handleFilterSelect(selectedOption);
                    close();
                  }}
                />
              </div>
            </FocusTrap>
          </Popover.Panel>
        </Float>
      )}
    </Popover>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionIntervalEnum = void 0;
var SubscriptionIntervalEnum;
(function (SubscriptionIntervalEnum) {
    SubscriptionIntervalEnum["DAY"] = "day";
    SubscriptionIntervalEnum["MONTH"] = "month";
    SubscriptionIntervalEnum["WEEK"] = "week";
    SubscriptionIntervalEnum["YEAR"] = "year";
})(SubscriptionIntervalEnum || (exports.SubscriptionIntervalEnum = SubscriptionIntervalEnum = {}));

import { TimeRangeWithShiftVO } from '@bigdelta/lib-shared';

const TIME_RANGE_MAP = {
  day: {
    1: 'Today',
    7: '7D',
    30: '30D',
  },
  month: {
    3: '3M',
    6: '6M',
    12: '12M',
    24: '24M',
  },
};

export const getTimeRangeFromQueryTime = (time: TimeRangeWithShiftVO) => {
  if (time.date_range_type === 'IN_THE_LAST' && time.window?.unit) {
    return TIME_RANGE_MAP[time.window?.unit][time.window?.value];
  }
};

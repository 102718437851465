"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toCreateRelationshipVO = exports.PrivateCreateRelationshipRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const types_1 = require("../types");
exports.PrivateCreateRelationshipRequestDef = typebox_1.Type.Object({
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    name: typebox_1.Type.String({ pattern: '^[A-Za-z0-9_]+$' }),
    display_name: typebox_1.Type.String(),
    first_entity_workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    first_entity_type: typebox_1.Type.Enum(types_1.RelationshipEntityType),
    first_entity_id: typebox_1.Type.Optional(typebox_1.Type.String()),
    first_entity_property: typebox_1.Type.String(),
    second_entity_workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    second_entity_type: typebox_1.Type.Enum(types_1.RelationshipEntityType),
    second_entity_id: typebox_1.Type.Optional(typebox_1.Type.String()),
    second_entity_property: typebox_1.Type.String(),
});
const toCreateRelationshipVO = (request) => {
    return {
        workspaceId: request.workspace_id,
        name: request.name,
        displayName: request.display_name,
        firstEntityWorkspaceId: request.first_entity_workspace_id,
        firstEntityType: request.first_entity_type,
        firstEntityId: request.first_entity_id,
        firstEntityProperty: request.first_entity_property,
        secondEntityWorkspaceId: request.second_entity_workspace_id,
        secondEntityType: request.second_entity_type,
        secondEntityId: request.second_entity_id,
        secondEntityProperty: request.second_entity_property,
    };
};
exports.toCreateRelationshipVO = toCreateRelationshipVO;

import { TimeUnit } from '@bigdelta/lib-shared';
import { useReportStoreContext } from '../../../context/reportStoreContext';
import { useReportStore } from '../../../store';
import { FilterKey } from '../../../../../shared/filters/store';
import { FC } from 'react';
import { ConversionWindowSelect } from '../../ConversionWindowSelect';
import { EVENT_NAME, TEMPLATE_TYPE } from './const';
import { EventsBuilder } from '../../EventsBuilder';

interface BuilderProps {
  filterKey: FilterKey;
}

export const Builder: FC<BuilderProps> = () => {
  const { reportKey } = useReportStoreContext();
  const { config, setTemplate } = useReportStore(reportKey)((state) => state);

  const template = config.template[TEMPLATE_TYPE] ?? {
    events: [],
    sequenceCompletionValue: 1,
    sequenceCompletionTimeUnit: TimeUnit.DAY,
  };
  const templateEvents = config.template[TEMPLATE_TYPE]?.events ?? [];

  const handleAddEvent = () => {
    const currentEvents = config.template[TEMPLATE_TYPE]?.events ?? [];
    setTemplate({
      [TEMPLATE_TYPE]: {
        ...template,
        events: [...currentEvents, null],
      },
    });
  };

  const handleRemoveEvent = (idx: number) => {
    const currentEvents = config.template[TEMPLATE_TYPE]?.events ?? [];
    setTemplate({
      [TEMPLATE_TYPE]: {
        ...template,
        events: currentEvents.filter((_, eventIdx) => eventIdx !== idx),
      },
    });
  };

  const handleSelectEvent = (
    value: {
      event_name: string;
    } | null,
    idx: number
  ) => {
    const currentEvents = config.template[TEMPLATE_TYPE]?.events ?? [];
    setTemplate({
      [TEMPLATE_TYPE]: {
        ...template,
        events: currentEvents.map((item, eventIdx) => {
          if (eventIdx !== idx) {
            return item;
          }

          return value ? { event_name: { name: value?.event_name } } : null;
        }),
      },
    });
  };

  const handleChangeSequenceCompletionValue = (sequenceCompletionValue: number) => {
    setTemplate({
      [TEMPLATE_TYPE]: {
        ...template,
        sequenceCompletionValue,
      },
    });
  };

  const handleChangeSequenceCompletionTimeUnit = (sequenceCompletionTimeUnit: TimeUnit) => {
    setTemplate({
      [TEMPLATE_TYPE]: {
        ...template,
        sequenceCompletionTimeUnit,
      },
    });
  };
  return (
    <div className="flex flex-col gap-y-4 rounded-lg text-sm">
      <div className="flex flex-col gap-y-2">
        <EventsBuilder
          title={EVENT_NAME}
          allowMultipleEvents={true}
          events={templateEvents}
          onAddEvent={handleAddEvent}
          onRemoveEvent={handleRemoveEvent}
          onSelectEvent={handleSelectEvent}
        />

        {/* <ReportCreateFilterEvents filterKey={filterKey} /> */}
      </div>
      <ConversionWindowSelect
        value={template.sequenceCompletionValue}
        timeUnit={template.sequenceCompletionTimeUnit}
        onChangeValue={handleChangeSequenceCompletionValue}
        onChangeTimeUnit={handleChangeSequenceCompletionTimeUnit}
      />
    </div>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorResponseDef = exports.ValidationErrorResponse = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.ValidationErrorResponse = typebox_1.Type.Object({
    message: typebox_1.Type.Optional(typebox_1.Type.String()),
    dataPath: typebox_1.Type.Optional(typebox_1.Type.String()),
});
exports.ErrorResponseDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        error_type: typebox_1.Type.Optional(typebox_1.Type.String()),
        message: typebox_1.Type.Optional(typebox_1.Type.String()),
        errors: typebox_1.Type.Optional(typebox_1.Type.Array(exports.ValidationErrorResponse)),
    }),
]);

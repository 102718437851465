"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toExtendedMemberResponse = exports.ExtendedMemberResponseDef = exports.AuthProviderStringType = exports.AuthProvider = void 0;
const typebox_1 = require("@sinclair/typebox");
const memberResponse_1 = require("./memberResponse");
const typebox_2 = require("../typebox");
var AuthProvider;
(function (AuthProvider) {
    AuthProvider["PASSWORD"] = "PASSWORD";
    AuthProvider["GOOGLE"] = "GOOGLE";
})(AuthProvider || (exports.AuthProvider = AuthProvider = {}));
exports.AuthProviderStringType = (0, typebox_2.EnumStrings)(typebox_1.Type.Enum(AuthProvider));
exports.ExtendedMemberResponseDef = typebox_1.Type.Composite([
    memberResponse_1.MemberResponseDef,
    typebox_1.Type.Object({
        auth_provider: exports.AuthProviderStringType,
    }),
]);
const toExtendedMemberResponse = (member, authProvider) => {
    if (!member || !authProvider) {
        return null;
    }
    return Object.assign(Object.assign({}, (0, memberResponse_1.toMemberResponse)(member)), { auth_provider: authProvider });
};
exports.toExtendedMemberResponse = toExtendedMemberResponse;

import { FilterItemType } from '../../store';
import { FilterAdd } from '../common/FilterAdd';

import ListIcon from '../../../../assets/icons/list.svg?react';
import BracketsPlusIcon from '../../../../assets/icons/brackets-plus.svg?react';
import { FC, useMemo } from 'react';

interface MetricsFilterAddProps {
  as?: FC;
  filterItemTypes: FilterItemType[];
  resourceId?: string;
}

export const MetricsFilterAdd: FC<MetricsFilterAddProps> = ({ as, filterItemTypes, resourceId }) => {
  const options = useMemo(
    () =>
      [
        ...(resourceId
          ? [
              {
                value: FilterItemType.METRICS_PROPERTY,
                label: 'Metric property',
                icon: BracketsPlusIcon,
              },
            ]
          : []),
        {
          value: FilterItemType.METRICS_RECORD_PROPERTY,
          label: 'Record property',
          icon: ListIcon,
        },
      ].filter((option) => filterItemTypes.includes(option.value)),
    [filterItemTypes, resourceId]
  );

  return <FilterAdd options={options} as={as} />;
};

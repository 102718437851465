import { RecordsToolbarContainer } from '../components/RecordsToolbarContainer';
import { FiltersButton } from '../../../shared/filters/components/FiltersButton';
import { PageHeading } from '../../../components/PageHeading';
import { StandardRecordsTable } from '../components/StandardRecordsTable';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useObjectQuery } from '../../../shared/data/useObjectQuery';
import { FilterItemType, FilterKey, getFilter, useFilterStore } from '../../../shared/filters/store';
import { RecordsContext } from '../context/RecordsContext';
import { tracking, TrackingEvent } from '../../../tracking';
import { ObjectsDetailData } from '@bigdelta/lib-api-client';
import { QuickAccessLinkType, RelationshipEntityType, WorkspaceObjectType } from '@bigdelta/lib-shared';
import { FilterContext } from '../../../shared/filters/context/context.ts';
import { capitalize } from 'lodash';
import { FilterCard } from '../../../shared/filters/components/common/FilterCard.tsx';
import PlusIcon from '../../../assets/icons/plus.svg?react';
import { FilterOptionsPopover } from '../../../shared/filters/components/common/FilterOptionsPopover.tsx';
import { OnOptionSelectHandler } from '../../../shared/filters/components/common/FilterOptions.tsx';
import { Tooltip } from '../../../shared/ui/Tooltip/Tooltip';
import { twMerge } from '../../../utils/twMerge.ts';
import { getWorkspaceObjectIcon } from '../../../shared/utils/getWorkspaceObjectIcon.ts';
import { Container } from '../../../shared/ui/Container/Container.tsx';
import { Divider } from '../../../shared/ui/Divider/Divider.tsx';
import { WelcomeModal } from '../../workspaces/onboarding/components/WelcomeModal';
import { useSearchParams } from 'react-router-dom';

interface RecordsListProps {
  objectSlug: string;
  workspaceId: string;
}

export const RecordsList: FC<RecordsListProps> = ({ objectSlug, workspaceId }) => {
  const [showFilters, setShowFilters] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const isFromOnboarding = searchParams.get('onboarding') === 'true';

  const { data: object } = useObjectQuery({ objectSlug: objectSlug, workspaceId: workspaceId });

  const filterKey: FilterKey = useMemo(() => ['records', objectSlug], [objectSlug]);
  const filter = useFilterStore((state) => getFilter(state, filterKey));
  const addFilterItem = useFilterStore((state) => state.addFilterItem);
  const setPropertyWithRelationships = useFilterStore((state) => state.setPropertyWithRelationships);
  const setPropertyRelationships = useFilterStore((state) => state.setPropertyRelationships);
  const setItemEvent = useFilterStore((state) => state.setItemEvent);

  const getSubtitle = (object: ObjectsDetailData) => {
    switch (object.object_type) {
      case WorkspaceObjectType.COMPANIES:
        return 'Records of companies that you want or are selling to';
      case WorkspaceObjectType.PEOPLE:
        return 'Records of people that you want or are selling to';
      case WorkspaceObjectType.DEAL:
        return 'Records of deals that your sales team is working on';
      case WorkspaceObjectType.USER:
      case WorkspaceObjectType.ACCOUNT:
        return `Records of ${object.plural_noun} that use your product`;
      default:
        return '';
    }
  };

  useEffect(() => {
    if (object) {
      tracking.track(TrackingEvent.ListViewed, {
        list: 'record',
        'object id': object.id,
        'object name': object.singular_noun,
      });
    }
  }, [object]);

  const handleFilterSelect = useCallback<OnOptionSelectHandler>(
    (option) => {
      if (!option.relationships) {
        return;
      }

      if (option.type === RelationshipEntityType.OBJECT) {
        const { index } = addFilterItem(filterKey, FilterItemType.RECORDS_PROPERTY, {});

        if (!option.property.property_type) {
          return;
        }

        setPropertyWithRelationships(
          filterKey,
          index,
          {
            attributeId: option.property.property_id,
            attributeType: option.property.property_type,
            attributeName: option.property.property_name,
          },
          option.relationships
        );
      }

      if (option.type === RelationshipEntityType.EVENT) {
        const { index } = addFilterItem(filterKey, FilterItemType.RECORDS_EVENT, {});
        setItemEvent(filterKey, index, option.event.event_name);
        setPropertyRelationships(filterKey, index, option.relationships);
      }
    },
    [addFilterItem, filterKey, setItemEvent, setPropertyRelationships, setPropertyWithRelationships]
  );

  if (!object) {
    return null;
  }

  const isFilterEmpty = !filter || filter.items.length === 0;

  return (
    <RecordsContext.Provider value={{ objectSlug: object.api_slug, workspaceId: workspaceId }}>
      {isFromOnboarding && (
        <WelcomeModal
          onClose={() => {
            setSearchParams((prev) => {
              prev.delete('onboarding');
              return prev;
            });
          }}
        />
      )}
      <Container>
        <PageHeading
          breadcrumbs={[{ label: object.plural_noun, icon: getWorkspaceObjectIcon(object.object_type), description: getSubtitle(object) }]}
          favoriteLinkConfig={{
            type: QuickAccessLinkType.OBJECT,
            label: capitalize(object.plural_noun),
            data: {
              object: {
                workspaceObjectId: object.id,
              },
            },
          }}
        />
      </Container>
      <Divider />
      <Container>
        <RecordsToolbarContainer>
          <div className="flex items-center gap-2">
            <FiltersButton count={filter?.items?.length} onClick={() => setShowFilters((s) => !s)} isOpen={showFilters} />
          </div>
        </RecordsToolbarContainer>
      </Container>
      <Divider />
      <Container className="flex shrink grow">
        <FilterContext.Provider value={{ filterKey, workspaceId, mode: 'inline', inlineElements: false }}>
          <div className="flex flex-1 gap-4 overflow-y-auto">
            {showFilters && (
              <div className="flex w-[352px] flex-col">
                {
                  <>
                    <div className="mb-2 flex flex-row items-center justify-between pt-3">
                      <span className="select-none text-md font-medium">Filters</span>
                      <FilterOptionsPopover
                        objectId={object?.id}
                        workspaceId={workspaceId}
                        onOptionSelect={handleFilterSelect}
                        defaultOpen={isFilterEmpty}
                        trigger={
                          <Tooltip content="Add new filter">
                            <button className="flex items-center rounded p-1 text-m-olive-400 hover:bg-[#f0f0f0]" data-tooltip-id="add-filter">
                              <PlusIcon className="h-4 w-4 " />
                            </button>
                          </Tooltip>
                        }
                      />
                    </div>
                    {filter?.items?.map((filterItem, index) => {
                      return (
                        <>
                          <FilterCard
                            key={`${JSON.stringify(filterItem.property)}-${index}`}
                            objectId={object.id}
                            filterIndex={index}
                            filterItem={filterItem}
                            className={twMerge(index === 0 && 'border-t')}
                          />
                        </>
                      );
                    })}
                  </>
                }
              </div>
            )}
            {showFilters && <Divider direction="vertical" />}
            <StandardRecordsTable className="flex-1" filterKey={filterKey} />
          </div>
        </FilterContext.Provider>
      </Container>
    </RecordsContext.Provider>
  );
};

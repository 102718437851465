"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MappedEventDataQueryResultVODef = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_1 = require("../model");
const typebox_2 = require("../typebox");
const response_1 = require("../response");
exports.MappedEventDataQueryResultVODef = typebox_1.Type.Composite([
    model_1.TimestampedModelDef,
    typebox_1.Type.Object({
        id: typebox_1.Type.String(),
        accountId: typebox_1.Type.String(),
        workspaceId: typebox_1.Type.String(),
        eventName: typebox_1.Type.String(),
        properties: typebox_2.NestedObjectDef,
        relationships: typebox_1.Type.Optional(response_1.DataQueryRelationshipsResponseDef),
        relations: typebox_1.Type.Record(typebox_1.Type.String(), typebox_1.Type.String()),
        createdAt: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    }),
]);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetadataResourcePropertyNameResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
exports.MetadataResourcePropertyNameResponseDef = typebox_1.Type.Object({
    property_id: typebox_1.Type.String({ format: 'uuid' }),
    property_name: typebox_1.Type.String(),
    property_type: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
});

import { FC } from 'react';
import { FilterItemType, FilterKey } from '../../../shared/filters/store';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { MetricsFilter } from '../../../shared/filters/components/metrics/MetricsFilter';
import { useReportsStore } from '../store';

interface ReportCreateFilterMetrics {
  filterKey: FilterKey;
}

export const ReportCreateFilterMetrics: FC<ReportCreateFilterMetrics> = ({ filterKey }) => {
  const { currentWorkspaceId } = useWorkspace();
  const metricMetric = useReportsStore((state) => state.config.metricMetric);

  if (!metricMetric) {
    return null;
  }

  return (
    <MetricsFilter
      workspaceId={currentWorkspaceId}
      filterKey={filterKey}
      mode="stacked"
      filterItemTypes={[FilterItemType.METRICS_PROPERTY, FilterItemType.METRICS_RECORD_PROPERTY]}
      resourceId={metricMetric}
    />
  );
};

import DotsIcon from '../../../assets/icons/dots-vertical.svg?react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { format, parseISO } from 'date-fns';
import { useObjectsQuery } from '../../../shared/data/useObjectsQuery';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Menu } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import { SegmentsListData } from '@bigdelta/lib-api-client';
import { FC, Fragment, useMemo } from 'react';
import { getWorkspaceObjectIcon } from '../../../shared/utils/getWorkspaceObjectIcon';

import EventIcon from '../../../assets/icons/cursor-click-01.svg?react';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

interface SegmentsTablePropsEvents {
  objectId?: undefined;
  segments: SegmentsListData['items'];
  entityType: 'EVENTS';
}

interface SegmentsTablePropsRecords {
  objectId: string;
  segments: SegmentsListData['items'];
  entityType: 'RECORDS';
}

type SegmentsTableProps = SegmentsTablePropsEvents | SegmentsTablePropsRecords;

export const SegmentsTable: FC<SegmentsTableProps> = ({ objectId, segments, entityType }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const queryKeys = useQueryKeys();

  //TODO:  loop through workspaces

  const { currentWorkspaceId } = useWorkspace();

  const mutation = useMutation({
    mutationFn: (segmentId: string) => bigdeltaAPIClient.v1.segmentsDelete(segmentId, { workspace_id: currentWorkspaceId }),
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.segments());
    },
  });

  const objectsQuery = useObjectsQuery({ workspaceId: currentWorkspaceId });

  const handleDeleteSegment = (segmentId: string) => {
    mutation.mutate(segmentId);
  };

  const handleRowClick = (objectApiSlug: string | undefined, segmentId: string, segmentWorkspaceId: string) => {
    if (entityType === 'RECORDS' && objectApiSlug) {
      handleRowClickRecord(objectApiSlug, segmentId, segmentWorkspaceId);
    }
    if (entityType === 'EVENTS') {
      handleRowClickEvent(segmentId, segmentWorkspaceId);
    }
  };

  const handleRowClickRecord = (objectApiSlug: string, segmentId: string, segmentWorkspaceId: string) => {
    const objectWorkspaceId = objectsQuery.data?.objects.find((object) => object.api_slug === objectApiSlug)?.workspace_id;
    if (!objectWorkspaceId) {
      return;
    }
    navigate({
      pathname: ['/records', objectApiSlug].join('/'),
      search: createSearchParams({
        workspaceId: objectWorkspaceId,
        segmentId,
        segmentWorkspaceId,
      }).toString(),
    });
  };

  const handleRowClickEvent = (segmentId: string, segmentWorkspaceId: string) => {
    navigate({
      pathname: '/events',
      search: createSearchParams({
        workspaceId: segmentWorkspaceId,
        segmentId,
        segmentWorkspaceId,
      }).toString(),
    });
  };

  const getObjectPluralNoun = (objectId: string) => {
    const object = objectsQuery.data?.objects.find((object) => object.id === objectId);
    return object?.plural_noun;
  };

  const Icon = useMemo(() => {
    const object = objectsQuery.data?.objects.find((object) => object.id === objectId);
    return object?.object_type ? getWorkspaceObjectIcon(object?.object_type) : Fragment;
  }, [objectId, objectsQuery.data?.objects]);

  return (
    <div className="relative">
      {/* <div className="absolute top-0 left-0 w-full h-full bg-m-white opacity-80"></div> */}
      <table className="w-full table-fixed">
        <thead>
          <tr>
            <th className="w-80 px-1.5 py-4 text-left text-sm font-regular text-m-olive-400 ">
              <span className="flex items-center gap-x-2">
                {entityType === 'RECORDS' && (
                  <>
                    <Icon className="h-4 w-4 text-m-olive-400" />
                    <span className="capitalize">{getObjectPluralNoun(objectId)}</span>
                  </>
                )}
                {entityType === 'EVENTS' && (
                  <>
                    <EventIcon className="h-4 w-4 text-m-olive-400" />
                    <span className="capitalize">Events</span>
                  </>
                )}
              </span>
            </th>
            <th className="px-6 py-4 text-left text-xs font-regular text-m-olive-900">Description</th>
            <th className="w-40 px-6 py-4 text-center text-xs font-regular text-m-olive-900">Created</th>
            <th className="w-20 px-6 py-4 text-center text-xs font-regular text-m-olive-900"></th>
          </tr>
        </thead>
        <tbody>
          {segments.map(({ id: segmentId, name, description, created_at, records_query, workspace_id: segmentWorkspaceId }) => {
            const resourceId = records_query?.query.resource.id;

            const object = objectsQuery.data?.objects.find((listObject) => listObject.id === resourceId);

            const objectApiSlug = object?.api_slug;

            if (!objectApiSlug && entityType === 'RECORDS') {
              return null;
            }

            return (
              <tr className="cursor-pointer" key={segmentId}>
                <td
                  className="rounded-l-lg px-6 py-4 text-left text-sm font-medium text-m-olive-900"
                  onClick={() => handleRowClick(objectApiSlug, segmentId, segmentWorkspaceId)}
                >
                  {name}
                </td>
                <td
                  className="text-olive-900 px-6 py-4 text-left text-sm"
                  onClick={() => handleRowClick(objectApiSlug, segmentId, segmentWorkspaceId)}
                >
                  <div className="max-w-sm overflow-hidden text-ellipsis">{description}</div>
                </td>
                <td className="px-5 text-center text-sm" onClick={() => handleRowClick(objectApiSlug, segmentId, segmentWorkspaceId)}>
                  {created_at && (
                    <div className="flex flex-col items-center">
                      <span className="text-m-olive-600">{format(parseISO(created_at), 'MMM, dd')}</span>
                      <span className="text-m-olive-400">{format(parseISO(created_at), 'HH:mm')}</span>
                    </div>
                  )}
                </td>
                <td className="px-6 align-middle">
                  <Menu>
                    <Float portal offset={4} placement="bottom-end">
                      <Menu.Button className="rounded-full p-2 hover:bg-m-gray-300">
                        <DotsIcon className="h-5 w-5 text-m-olive-400" />
                      </Menu.Button>
                      <Menu.Items className="rounded-lg border border-m-gray-300 bg-m-white px-1 py-2">
                        <Menu.Item>
                          <span
                            className="cursor-default whitespace-nowrap rounded bg-m-white p-2 text-md text-m-olive-900 hover:bg-m-gray-200"
                            onClick={() => handleDeleteSegment(segmentId)}
                          >
                            Delete segment
                          </span>
                        </Menu.Item>
                      </Menu.Items>
                    </Float>
                  </Menu>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

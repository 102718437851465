import { DataSelect } from './DataSelect';
import { FunnelBuilder } from './FunnelBuilder';

export const FunnelConfig = () => (
  <div className="flex flex-col gap-y-2 py-4">
    <div className="flex flex-col items-start justify-between ">
      <div className="text-md font-medium">Data</div>
      <DataSelect />
    </div>
    <div className="flex flex-col gap-y-2">
        <FunnelBuilder  />
    </div>
  </div>
);


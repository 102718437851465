import React, { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { ExtendedMemberResponse } from '@bigdelta/lib-shared';
import { getAuthenticatedUserId } from '../../../shared/utils/authToken.ts';
import { ProtectedMembersAccountsDetailData, ProtectedMembersWorkspacesDetailData } from '@bigdelta/lib-api-client';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

/**
 * flow:
 * ready = false
 * fetch user:
 *    get userid from localstorage
 *    if !user
 *       set ready & authenticated false
 *    if user
 *        get user details from api
 *          success:
 *             set user
 *             set authenticated
 *             set ready
 *          error:
 *              set authenticated false
 *              set ready true
 */

export type UserContextState = {
  ready: boolean;
  authenticated: boolean;
  user?: ExtendedMemberResponse | null;
  workspaces?: ProtectedMembersWorkspacesDetailData | null;
  accounts?: ProtectedMembersAccountsDetailData | null;
  fetchUser: (() => Promise<boolean>) | null;
  resetAuth: () => void;
};

const initialState: UserContextState = {
  ready: false,
  authenticated: false,
  user: null,
  fetchUser: null,
  resetAuth: () => undefined,
};

export const UserContext = createContext<UserContextState>(initialState);

const UserContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [ready, setReady] = useState<boolean>(false);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<ExtendedMemberResponse | null>(null);
  const [workspaces, setWorkspaces] = useState<ProtectedMembersWorkspacesDetailData | null>(null);
  const [accounts, setAccounts] = useState<ProtectedMembersAccountsDetailData | null>(null);

  const resetAuth = useCallback(() => {
    setReady(true);
    setAuthenticated(false);
    setUser(null);
    setWorkspaces(null);
    setAccounts(null);
  }, []);

  const fetchUser = async () => {
    const userId = getAuthenticatedUserId();
    if (!userId) {
      setReady(true);
      setAuthenticated(false);
      return false;
    }

    try {
      const [userResponse, accountsResponse, workspacesResponse] = await Promise.all([
        bigdeltaAPIClient.v1.protectedMembersDetail(userId),
        bigdeltaAPIClient.v1.protectedMembersAccountsDetail(userId),
        bigdeltaAPIClient.v1.protectedMembersWorkspacesDetail(userId),
      ]);

      setReady(true);
      setAuthenticated(true);
      setUser(userResponse);
      setWorkspaces(workspacesResponse);
      setAccounts(accountsResponse);

      return true;
    } catch (e) {
      console.log(e);

      setReady(true);
      setAuthenticated(false);
      return false;
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <UserContext.Provider
      value={{
        ready,
        authenticated,
        user,
        workspaces,
        fetchUser,
        resetAuth,
        accounts,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;

import { ReportsDetailData } from '@bigdelta/lib-api-client';
import { v4 as uuid } from 'uuid';
import { TrendsBuilderType } from '../../../store/TrendsBuilder';

export const getBuilderFromMetricMetric = (queryMetric: NonNullable<ReportsDetailData['query']>['metrics'][number]) => {
  if (!queryMetric.metric) {
    return;
  }

  return {
    id: uuid(),
    name: queryMetric.name ?? 'X',
    type: TrendsBuilderType.METRIC,
    label: queryMetric.query_name ?? undefined,
    data: {
      [TrendsBuilderType.METRIC]: {
        metricId: queryMetric.metric.resource.id,
      },
    },
  };
};

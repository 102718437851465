import { FC } from 'react';

interface ToastContentProps {
  title?: string;
  message?: string;
}

export const ToastContent: FC<ToastContentProps> = ({ title, message }) => (
  <div className="ml-4 flex w-72 items-start">
    <div className="flex flex-grow flex-col gap-y-1 text-md">
      <span className="font-medium text-m-olive-900">{title ? title : 'Something went wrong'}</span>
      {message && <span className="font-regular text-m-olive-500">{message}</span>}
    </div>
  </div>
);

/* eslint-disable no-case-declarations */
import { useMemo, useCallback, useRef, useEffect, useState, FC, HTMLProps, PropsWithChildren } from 'react';
import { Editor, Transforms, Range, createEditor, Descendant, BaseSelection } from 'slate';
import { withHistory } from 'slate-history';
import { Slate, Editable, ReactEditor, withReact, useSelected, useFocused, RenderElementProps } from 'slate-react';

import { createPortal } from 'react-dom';
import { useQuery } from '@tanstack/react-query';
import { useWorkspace } from '../../../../auth/hooks/useWorkspace';
import { useQueryKeys } from '../../../../auth/hooks/useQueryKeys';
import { twMerge } from '../../../../../utils/twMerge';
import { VariableSuggestions } from '../../../../../shared/components/VariableSuggestions';
import { CustomEditor, PropertyVariableElement } from '../../../../../shared/slate';
import { PropertyNameObject, VariableInputElementType } from '../../../../../shared/types';
import { InsertVariable } from './InsertVariable';
import { bigdeltaAPIClient } from '../../../../../client/bigdeltaAPIClient';
import { MetadataResourcePropertyType } from '@bigdelta/lib-shared';

interface PortalProps extends PropsWithChildren, HTMLProps<HTMLDivElement> {}

const isPropertyVariableElement = (element: any): element is PropertyVariableElement => element.type === VariableInputElementType.PropertyVariable;

export const Portal: FC<PortalProps> = ({ children, onClick }) => {
  return typeof document === 'object'
    ? createPortal(
        <div className="absolute left-0 top-0 z-50 h-full w-full" onClick={onClick}>
          {children}
        </div>,
        document.body
      )
    : null;
};

interface SlackMessageInputProps {
  onChange: (value: Descendant[]) => void;
  initialValue?: Descendant[];
  isError?: boolean;
  resourceType: MetadataResourcePropertyType;
  resourceId?: string;
}

export const SlackMessageInput: FC<SlackMessageInputProps> = ({ onChange, initialValue, isError = false, resourceType, resourceId }) => {
  const { currentWorkspaceId } = useWorkspace();

  const ref = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [target, setTarget] = useState<Range | null>();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState('');
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withPropertyVariables(withReact(withHistory(createEditor()))), []);

  const blurSelection = useRef<BaseSelection | null>(null);

  const queryKeys = useQueryKeys();

  const propertyNamesQuery = useQuery({
    queryKey: queryKeys.list('metadata', resourceType, resourceId, 'names', search),
    queryFn: () =>
      bigdeltaAPIClient.v1.metadataResourcesPropertiesNamesList({
        workspace_id: currentWorkspaceId,
        resource_type: resourceType,
        resource_id: resourceId,
        query: search ?? undefined,
      }),
  });

  const propertyNamesList = useMemo(() => {
    return propertyNamesQuery.data?.items.slice(0, 10) ?? [];
  }, [propertyNamesQuery.data?.items]);

  const onKeyDown = useCallback(
    (event) => {
      if (target && propertyNamesList.length > 0) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            const prevIndex = index >= propertyNamesList.length - 1 ? 0 : index + 1;
            setIndex(prevIndex);
            break;
          case 'ArrowUp':
            event.preventDefault();
            const nextIndex = index <= 0 ? propertyNamesList.length - 1 : index - 1;
            setIndex(nextIndex);
            break;
          case 'Enter':
            event.preventDefault();
            Transforms.select(editor, target);
            insertVariable(editor, propertyNamesList[index]);
            setTarget(null);
            break;
          case 'Escape':
            event.preventDefault();
            setTarget(null);
            break;
          case 'Tab':
            setTarget(null);
            setSearch('');
        }
      }
    },
    [editor, index, propertyNamesList, target]
  );

  useEffect(() => {
    if (target && propertyNamesList.length > 0) {
      const el = ref.current;
      const anchorEl = containerRef.current;
      const anchorElRect = anchorEl?.getBoundingClientRect();

      if (!el || !anchorElRect) return;

      el.style.width = `${anchorElRect.width}px`;
      el.style.top = `${anchorElRect.bottom + window.scrollY + 6}px`;
      el.style.left = `${anchorElRect.left + window.scrollX}px`;
    }
  }, [editor, index, propertyNamesList.length, search, target]);

  const handleSlateChange = (value: Descendant[]) => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection);
      const wordBefore = Editor.before(editor, start, { unit: 'word' });
      const before = wordBefore && Editor.before(editor, wordBefore);
      const beforeRange = before && Editor.range(editor, before, start);
      const beforeText = beforeRange && Editor.string(editor, beforeRange);
      const beforeMatch = beforeText && beforeText.match(/^{(\w+)$/);
      const after = Editor.after(editor, start);
      const afterRange = Editor.range(editor, start, after);
      const afterText = Editor.string(editor, afterRange);
      const afterMatch = afterText.match(/^(\s|$)/);

      if (beforeMatch && afterMatch) {
        setTarget(beforeRange);
        setSearch(beforeMatch[1]);
        setIndex(0);
        return;
      }
    }
    setTarget(null);
    onChange(value);
  };

  const handleMetricSuggestionClick = (property: PropertyNameObject) => {
    if (!target) return;

    Transforms.select(editor, target);
    ReactEditor.focus(editor);
    insertVariable(editor, property);
    setTarget(null);
  };

  const handleSuggestionsOutsideClick = () => {
    setTarget(null);
    setSearch('');
    ReactEditor.blur(editor);
  };

  const handleInsertVariable = (property: PropertyNameObject | null) => {
    const endPoint = Editor.end(editor, []);
    const endRange = Editor.range(editor, endPoint);

    const selection = blurSelection.current ?? endRange;

    if (property) {
      Transforms.select(editor, selection);
      insertVariable(editor, property);
    }
  };

  return (
    <div className="flex flex-col gap-y-1">
      <div ref={containerRef}>
        <Slate editor={editor} initialValue={initialValue ?? defaultInitialValue} onChange={handleSlateChange}>
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            onKeyDown={onKeyDown}
            className={twMerge(
              '!overflow-hidden !whitespace-nowrap rounded-lg border border-m-olive-100 p-2 pl-3 text-sm placeholder:text-sm placeholder:text-m-olive-300 focus:!overflow-visible focus:!whitespace-pre-wrap',
              isError && 'border-m-red-600 outline-m-red-600'
            )}
            renderPlaceholder={({ children, attributes }) => (
              <span className="p-2 pl-0 tracking-normal" {...attributes}>
                {children}
              </span>
            )}
            placeholder="Enter a message"
            onBlur={() => {
              blurSelection.current = editor.selection;
            }}
          />
          {target && propertyNamesList.length > 0 && (
            <VariableSuggestions
              suggestionsList={propertyNamesList}
              renderSuggestion={({ property_name }) => property_name}
              onSuggestionClick={handleMetricSuggestionClick}
              onOutsideClick={handleSuggestionsOutsideClick}
              rovingIndex={index}
              ref={ref}
            />
          )}
        </Slate>
      </div>
      <div>
        <InsertVariable onChange={handleInsertVariable} />
      </div>
    </div>
  );
};

const withPropertyVariables = <T extends CustomEditor>(editor: T): T => {
  const { isInline, isVoid, markableVoid } = editor;

  editor.isInline = (element) => {
    return element.type === VariableInputElementType.PropertyVariable ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === VariableInputElementType.PropertyVariable ? true : isVoid(element);
  };

  editor.markableVoid = (element) => {
    return element.type === VariableInputElementType.PropertyVariable || markableVoid(element);
  };

  return editor;
};

const insertVariable = (editor: CustomEditor, property: PropertyNameObject) => {
  const metricElement: PropertyVariableElement = {
    type: VariableInputElementType.PropertyVariable,
    property,
    children: [{ text: '' }],
  };
  Transforms.insertNodes(editor, metricElement);
  Transforms.move(editor);
  ReactEditor.focus(editor);
};

const Leaf = ({ attributes, children }) => {
  return <span {...attributes}>{children}</span>;
};

const Element: FC<RenderElementProps> = (props) => {
  const { attributes, children, element } = props;

  if (isPropertyVariableElement(element)) {
    return <PropertyVariable element={element} children={children} attributes={attributes} />;
  }

  return <p {...attributes}>{children}</p>;
};

const defaultInitialValue: Descendant[] = [
  {
    type: VariableInputElementType.Paragraph,
    children: [{ text: '' }],
  },
];

interface PropertyVariableProps extends Omit<RenderElementProps, 'element'> {
  element: PropertyVariableElement;
}

const PropertyVariable: FC<PropertyVariableProps> = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();

  const { property } = element;

  return (
    <span
      {...attributes}
      contentEditable={false}
      className={twMerge(
        'mx-px inline-block select-none rounded-md bg-m-blue-100 px-2 align-baseline text-sm leading-normal tracking-normal',
        selected && focused && 'shadow-[0_0_0_2px_theme(colors.m-blue.400)]'
      )}
    >
      {/* TODO: add property type icon */}
      {`{ ${property.property_name} }`}
      {children}
    </span>
  );
};

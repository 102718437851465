import { RelationshipsListData } from '@bigdelta/lib-api-client';
import { RelationshipEntityType } from '@bigdelta/lib-shared';
import { RelationshipObjectData } from '../types.ts';

interface GetObjectsRelatedToObjectArgs {
  entityId: string;
  relationships: RelationshipsListData['relationships'];
}

export const getObjectsRelatedToObject = ({ entityId, relationships }: GetObjectsRelatedToObjectArgs) => {
  const objectRelMap = relationships.reduce(
    (acc, item) => {
      if (item.first_entity_type !== RelationshipEntityType.OBJECT || item.second_entity_type !== RelationshipEntityType.OBJECT) {
        return acc;
      }
      if (
        item.first_entity_type === RelationshipEntityType.OBJECT &&
        item.second_entity_type === RelationshipEntityType.OBJECT &&
        item.first_entity_id === entityId &&
        !!item.second_entity_id
      ) {
        return {
          ...acc,
          [item.second_entity_id]: {
            relationshipName: item.name,
            objectId: item.second_entity_id,
            objectWorkspaceId: item.second_entity_workspace_id,
          },
        };
      }
      if (
        item.first_entity_type === RelationshipEntityType.OBJECT &&
        item.second_entity_type === RelationshipEntityType.OBJECT &&
        item.second_entity_id === entityId &&
        !!item.first_entity_id
      ) {
        return {
          ...acc,
          [item.first_entity_id]: {
            relationshipName: item.name,
            objectId: item.first_entity_id,
            objectWorkspaceId: item.first_entity_workspace_id,
          },
        };
      }
      return acc;
    },
    {} as { [key: string]: RelationshipObjectData }
  );

  return Object.values(objectRelMap).map(({ relationshipName, objectId, objectWorkspaceId }) => ({
    relationshipName,
    objectId,
    objectWorkspaceId,
  }));
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNestedQueryFilterCondition = exports.isNestedQueryFilter = exports.QueryFiltersVODef = exports.NestedQueryFilterOperatorType = exports.NestedQueryFilterOperator = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
var NestedQueryFilterOperator;
(function (NestedQueryFilterOperator) {
    NestedQueryFilterOperator["AND"] = "and";
    NestedQueryFilterOperator["OR"] = "or";
})(NestedQueryFilterOperator || (exports.NestedQueryFilterOperator = NestedQueryFilterOperator = {}));
exports.NestedQueryFilterOperatorType = (0, typebox_2.EnumStrings)(typebox_1.Type.Enum(NestedQueryFilterOperator));
const QueryFiltersVODef = (schema, options) => typebox_1.Type.Recursive((Self) => typebox_1.Type.Object({
    operator: exports.NestedQueryFilterOperatorType,
    conditions: typebox_1.Type.Array(typebox_1.Type.Union([schema, Self])),
}), options);
exports.QueryFiltersVODef = QueryFiltersVODef;
const isNestedQueryFilter = (value) => {
    return value && !!value.operator;
};
exports.isNestedQueryFilter = isNestedQueryFilter;
const isNestedQueryFilterCondition = (value) => {
    return value && !value.operator;
};
exports.isNestedQueryFilterCondition = isNestedQueryFilterCondition;

import { FC, useEffect } from 'react';
import { useFilterContext } from '../../context/useFilterContext';
import { FilterItem, useFilterStore } from '../../store';
import { EventSelect } from '../common/EventSelect';
import { EventOperatorSelect } from '../EventOperatorSelect';
import { EventValue } from '../EventValue';
import { EventTimeframeOperatorSelect } from '../common/EventTimeframeOperatorSelect';
import { EventTimeframeInTheLast } from '../common/EventTimeframeInTheLast';
import { EventTimeframeBefore } from '../common/EventTimeframeBefore';
import { EventTimeframeAfter } from '../common/EventTimeframeAfter';
import { EventTimeframeBetween } from '../common/EventTimeframeBetween';
import { RowContainer } from '../common/RowContainer';
import { useRelationshipsQuery } from '../../../data/useRelationshipsQuery';
import { getObjectRelationshipToEvent } from '../../../utils/getObjectRelationshipToEvent';
import { useObjectQuery } from '../../../data/useObjectQuery';
import { EventPropertySelect } from '../common/EventPropertySelect';
import { PropertyOperatorSelect } from '../common/PropertyOperatorSelect';
import { PropertyValue } from '../common/PropertyValue';
import { Control } from '../common/Control';
import { Group } from '../common/Group';
import { useWorkspace } from '../../../../features/auth/hooks/useWorkspace';
import { MetadataResourcePropertyType } from '@bigdelta/lib-shared';

interface RecordsEventPropertyFilterRowProps {
  index: number;
  filter: FilterItem;
  objectSlug: string;
}

export const RecordsEventPropertyFilterRow: FC<RecordsEventPropertyFilterRowProps> = ({ index, filter, objectSlug }) => {
  const { currentWorkspaceId } = useWorkspace();
  const { filterKey } = useFilterContext();

  const removeItem = useFilterStore((state) => state.removeItem);
  const setItemPropertyOperator = useFilterStore((state) => state.setItemPropertyOperator);
  const setPropertyRelationships = useFilterStore((state) => state.setPropertyRelationships);

  const { data: relationshipData } = useRelationshipsQuery({ workspaceId: currentWorkspaceId });
  const { data: object } = useObjectQuery({ objectSlug, workspaceId: currentWorkspaceId });

  const eventRel =
    object?.id && relationshipData?.relationships
      ? getObjectRelationshipToEvent({ relationships: relationshipData.relationships, objectId: object.id })
      : undefined;

  // TODO: move this to on filter add, fix empty strings for object-event relationships
  useEffect(() => {
    if (!filter.propertyRelationships.length && eventRel?.name) {
      setPropertyRelationships(filterKey, index, [
        {
          objectId: '',
          relationshipName: eventRel.name,
          objectWorkspaceId: '',
        },
      ]);
    }
  }, [eventRel?.name, filter, filterKey, index, setPropertyRelationships]);

  return (
    <RowContainer onRemove={() => removeItem(filterKey, index)}>
      <Group>
        <Control>
          <EventSelect index={index} filterDataItem={filter} includeAny />
        </Control>
        {filter.event && (
          <Control>
            <EventPropertySelect index={index} filterItem={filter} />
          </Control>
        )}
      </Group>
      {filter.property && (
        <Group>
          <Control shouldShrink={false}>
            <PropertyOperatorSelect
              index={index}
              value={filter.propertyOperator}
              onChange={(index, value) => setItemPropertyOperator(filterKey, index, value)}
            />
          </Control>
          <Control>
            <PropertyValue index={index} resourceType={MetadataResourcePropertyType.EVENT} />
          </Control>
        </Group>
      )}
      {filter.data.value && (
        <Group>
          <Control shouldShrink={false}>
            <EventOperatorSelect index={index} />
          </Control>
          <Control>
            <EventValue index={index} />
          </Control>
        </Group>
      )}

      {filter.property && filter.eventValue && filter.data.value && (
        <Group>
          <EventTimeframeOperatorSelect index={index} />
        </Group>
      )}
      {filter.property && filter.data.value && (
        <Group>
          <EventTimeframeInTheLast index={index} />
          <EventTimeframeBefore index={index} />
          <EventTimeframeAfter index={index} />
          <EventTimeframeBetween index={index} />
        </Group>
      )}
    </RowContainer>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toInvitationResponse = exports.InvitationResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.InvitationResponseDef = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    account_id: typebox_1.Type.String({ format: 'uuid' }),
    email: typebox_1.Type.String({ format: 'email' }),
});
const toInvitationResponse = (invitation) => {
    return {
        id: invitation.id,
        account_id: invitation.account_id,
        email: invitation.email,
    };
};
exports.toInvitationResponse = toInvitationResponse;

import { useWorkspace } from '../../../../features/auth/hooks/useWorkspace.tsx';
import { ChangeEvent, useMemo, useState } from 'react';
import { useDebounce } from '@uidotdev/usehooks';
import { useMetadataEventsInfiniteQuery } from '../../../data/useMetadataEventsInfiniteQuery.ts';
import { ALL_EVENTS } from '../../store';
import { twMerge } from 'tailwind-merge';
import { ComboboxInfinite } from '../../../../components/Combobox/ComboboxInfinite.tsx';

import ClickIcon from '../../../../assets/icons/cursor-click-01.svg?react';
import { MetadataEventsListData } from '@bigdelta/lib-api-client';

type EventListItem = MetadataEventsListData['items'][number];

type EventNameComboboxContentProps = {
  value: string | undefined;
  onChange: (value: EventListItem | null) => void;
  className?: string;
  includeAny?: boolean;
  inlineElements?: boolean;
};

export const EventNameComboboxContent = ({
  value,
  onChange,
  className = '',
  inlineElements = false,
  includeAny = false,
}: EventNameComboboxContentProps) => {
  const { currentWorkspaceId } = useWorkspace();

  const [rawSearchQuery, setRawSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(rawSearchQuery, 700);

  const {
    data: eventNames,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isFetching,
    isSuccess,
  } = useMetadataEventsInfiniteQuery({
    workspaceId: currentWorkspaceId,
    searchQuery: debouncedSearchQuery,
  });

  const flatEventNames = useMemo(
    () => (eventNames ? [...(includeAny ? [{ event_name: ALL_EVENTS }] : []), ...eventNames.pages.flatMap((d) => d.items)] : []),
    [eventNames, includeAny]
  );

  return (
    <ComboboxInfinite
      items={flatEventNames}
      selectedItems={value ? { event_name: value } : null}
      onChange={(data) => {
        onChange(data as EventListItem);
      }}
      onQueryChange={(e: ChangeEvent<HTMLInputElement>) => setRawSearchQuery(e.target.value)}
      query={rawSearchQuery}
      height={450}
      placeholder="Search events..."
      catchInputFocus
      renderOption={(item) => {
        // TODO: make sure the different icons work on different events
        return (
          <div className="flex flex-row items-center justify-start gap-1">
            <ClickIcon className="m-1 h-4 w-4 text-m-olive-400" />
            <div className="text-xs capitalize leading-tight text-m-olive-800">{item?.event_name}</div>
          </div>
        );
      }}
      isFetching={isFetching}
      hasNextPage={!!hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
      isSuccess={isSuccess}
      className={twMerge(inlineElements && 'mt-2', className)}
    />
  );
};

import { FC } from 'react';
import { Link } from 'react-router-dom';
import { SavedLink, useDrawerStore } from '../features/navigation/store';
import { twMerge } from 'tailwind-merge';

import ExpandIcon from '../assets/icons/align-right-01.svg?react';
import QuestionIcon from '../assets/icons/question.svg?react';
import { FavoriteButton } from '../shared/components/FavoriteButton';
import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../features/auth/hooks/useQueryKeys';
import { useWorkspace } from '../features/auth/hooks/useWorkspace';
import { getQuickAccessLinkData } from '../features/navigation/utils';
import { useShortcuts } from '../shared/hooks/useShortcuts';
import { SVGComponent } from '../types';
import { Tooltip } from '../shared/ui/Tooltip/Tooltip';
import { bigdeltaAPIClient } from '../client/bigdeltaAPIClient.ts';

interface Breadcrumb {
  icon?: SVGComponent;
  label: string;
  description?: string;
  to?: string;
}

interface BreadcrumbTextContentProps {
  breadcrumb: Breadcrumb;
}

const BreadcrumbTextContent: FC<BreadcrumbTextContentProps> = ({ breadcrumb }) => {
  return (
    <span className="flex items-center gap-x-1">
      <span className="first-letter:capitalize">{breadcrumb.label}</span>
      {breadcrumb.description && (
        <Tooltip content={breadcrumb.description}>
          <div>
            <QuestionIcon className="relative h-4 w-4 text-m-olive-400" />
          </div>
        </Tooltip>
      )}
    </span>
  );
};

interface PageHeadingBreadcrumbsComponentProps {
  breadcrumbs?: Breadcrumb[];
}

const PageHeadingBreadcrumbsComponent: FC<PageHeadingBreadcrumbsComponentProps> = ({ breadcrumbs }) => {
  if (!breadcrumbs?.length) {
    return null;
  }

  return (
    <h1 className="flex whitespace-pre-wrap text-lg text-m-olive-700">
      {breadcrumbs.map((crumb, i) => {
        const Icon = crumb.icon;

        return (
          <div key={crumb.label} className="flex items-center">
            {crumb.to ? (
              <Link className="flex items-center gap-x-1.5 text-m-olive-400 underline" to={crumb.to}>
                {Icon && <Icon className="inline-block h-4 w-4" />}
                <span className="flex items-center gap-x-1">
                  <BreadcrumbTextContent breadcrumb={crumb} />
                </span>
              </Link>
            ) : (
              <span className="flex items-center gap-x-1.5 ">
                {Icon && <Icon className="inline-block h-4 w-4" />}
                <span className="flex items-center gap-x-1">
                  <BreadcrumbTextContent breadcrumb={crumb} />
                </span>
              </span>
            )}
            {i < breadcrumbs.length - 1 && ' / '}
          </div>
        );
      })}
    </h1>
  );
};

interface PageHeadingBreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
  favoriteLinkConfig?: Omit<SavedLink, 'id' | 'order'>;
}

type PageHeadingProps = PageHeadingBreadcrumbsProps;

export const PageHeading: FC<PageHeadingProps> = ({ breadcrumbs, favoriteLinkConfig }) => {
  const queryKeys = useQueryKeys();

  const { currentWorkspaceId } = useWorkspace();

  const { isOpen, toggleIsOpen } = useDrawerStore();

  const { addShortcut, removeShortcut, getMatchingShortcut } = useShortcuts();

  const quickAccessLinksQuery = useQuery({
    queryKey: queryKeys.list('quick-access-link'),
    queryFn: () =>
      bigdeltaAPIClient.v1.membersConfigQuickAccessLinksList({
        workspace_id: currentWorkspaceId,
      }),
    staleTime: Infinity,
  });

  const data = favoriteLinkConfig && getQuickAccessLinkData(favoriteLinkConfig.type, favoriteLinkConfig.data);

  const existingQuickAccessLink = getMatchingShortcut(favoriteLinkConfig?.type, data ?? {});

  const handleFavoriteToggle = () => {
    if (!favoriteLinkConfig || !quickAccessLinksQuery.data) {
      return;
    }

    if (existingQuickAccessLink) {
      removeShortcut(existingQuickAccessLink.id);
    } else {
      addShortcut({
        type: favoriteLinkConfig.type,
        order: quickAccessLinksQuery.data.quick_access_links.length,
        data: data || {},
        label: favoriteLinkConfig.label,
      });
    }
  };

  return (
    <div className="flex items-center py-6">
      <div className="flex items-center gap-x-3">
        {!isOpen && (
          <button
            className={twMerge(
              'ml-2 rounded border border-m-olive-400 p-0.5 text-m-olive-400 transition-colors duration-300 hover:bg-m-olive-400 hover:text-m-coconut-600'
            )}
            onClick={toggleIsOpen}
          >
            <ExpandIcon className="h-3 w-3" />
          </button>
        )}
        {/* {title && <h1 className="text-lg capitalize text-m-olive-700">{title}</h1>} */}
        <div className="flex items-center gap-x-2">
          <PageHeadingBreadcrumbsComponent breadcrumbs={breadcrumbs} />
        </div>
        {quickAccessLinksQuery.data && favoriteLinkConfig && <FavoriteButton active={!!existingQuickAccessLink} onToggle={handleFavoriteToggle} />}
      </div>
    </div>
  );
};

import { createContext, useContext } from 'react';

export interface ReportStoreContextState {
  reportKey: string;
}

export const ReportStoreContext = createContext<ReportStoreContextState>({
  reportKey: null,
});

export const useReportStoreContext = () => {
  return useContext(ReportStoreContext);
};

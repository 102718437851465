import { createContext } from 'react';
import { AttributesValue } from '../../../../features/reports/types.ts';

export interface ReportViewContextState {
  highlightedElement?: AttributesValue | null;
  setHighlightedElement: (value: AttributesValue | null) => void;
}

export const ReportViewContext = createContext<ReportViewContextState | undefined>({
  highlightedElement: null,
  setHighlightedElement: () => {},
});

import { TriggerType } from './types';
import { AutomationWorkflowBlockType } from '@bigdelta/lib-shared';

import ObjectCreatedIcon from '../../assets/icons/file-plus-02.svg?react';
import ObjectUpdatedIcon from '../../assets/icons/edit-02.svg?react';
import ObjectDeletedIcon from '../../assets/icons/trash-03.svg?react';
import EventIcon from '../../assets/icons/cursor-click-01.svg?react';

import SlackIcon from '../../assets/integrations/Slack.svg?react';
import ClockIcon from '../../assets/icons/clock-snooze.svg?react';
import FilterIcon from '../../assets/icons/filter-funnel-01.svg?react';

import { SVGComponent } from '../../types';

export const TRIGGER_NODE_ID = 'trigger';

export const TEMP_NODE_PREFIX = 'temp-';

export const TriggerTypeLabelMap: Record<TriggerType, string> = {
  [TriggerType.RECORD_CREATED]: 'Record created',
  [TriggerType.RECORD_UPDATED]: 'Record updated',
  [TriggerType.RECORD_DELETED]: 'Record deleted',
  [TriggerType.EVENT_OCCURRED]: 'Event occurred',
};

export const TriggerTypeIconMap: Record<TriggerType, SVGComponent> = {
  [TriggerType.RECORD_CREATED]: ObjectCreatedIcon,
  [TriggerType.RECORD_UPDATED]: ObjectUpdatedIcon,
  [TriggerType.RECORD_DELETED]: ObjectDeletedIcon,
  [TriggerType.EVENT_OCCURRED]: EventIcon,
};

export const ActionTypeLabelMap: Partial<Record<AutomationWorkflowBlockType, string>> = {
  [AutomationWorkflowBlockType.SEND_SLACK_MESSAGE]: 'Send message in Slack',
  [AutomationWorkflowBlockType.DELAY]: 'Delay',
  [AutomationWorkflowBlockType.DELAY_UNTIL]: 'Delay until',
  [AutomationWorkflowBlockType.FILTER_RECORD]: 'Filter record',
  [AutomationWorkflowBlockType.FILTER_EVENT]: 'Filter event',
};

export const ActionTypeIconMap: Partial<Record<AutomationWorkflowBlockType, SVGComponent>> = {
  [AutomationWorkflowBlockType.SEND_SLACK_MESSAGE]: SlackIcon,
  [AutomationWorkflowBlockType.DELAY]: ClockIcon,
  [AutomationWorkflowBlockType.DELAY_UNTIL]: ClockIcon,
  [AutomationWorkflowBlockType.FILTER_RECORD]: FilterIcon,
  [AutomationWorkflowBlockType.FILTER_EVENT]: FilterIcon,
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonAliasDef = void 0;
const timestampedModel_1 = require("./timestampedModel");
const typebox_1 = require("@sinclair/typebox");
exports.PersonAliasDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        workspaceId: typebox_1.Type.String({ format: 'uuid' }),
        personId: typebox_1.Type.String({ format: 'uuid' }),
        alias: typebox_1.Type.String({ format: 'uuid' }),
    }),
    timestampedModel_1.TimestampedModelDef,
]);

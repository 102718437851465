"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryFrequencyValueFilterOperatorType = exports.QueryValueFilterOperatorInternalType = exports.QueryValueFilterOperatorType = exports.QueryFrequencyValueFilterOperator = exports.QueryValueFilterOperatorInternal = exports.QueryValueFilterOperator = void 0;
const typebox_1 = require("../typebox");
const typebox_2 = require("@sinclair/typebox");
var QueryValueFilterOperator;
(function (QueryValueFilterOperator) {
    QueryValueFilterOperator["EQUALS"] = "equals";
    QueryValueFilterOperator["NOT_EQUALS"] = "not equals";
    QueryValueFilterOperator["CONTAINS"] = "contains";
    QueryValueFilterOperator["NOT_CONTAINS"] = "not contains";
    QueryValueFilterOperator["IN"] = "in";
    QueryValueFilterOperator["NOT_IN"] = "not in";
    QueryValueFilterOperator["STARTS_WITH"] = "starts with";
    QueryValueFilterOperator["NOT_STARTS_WITH"] = "not starts with";
    QueryValueFilterOperator["ENDS_WITH"] = "ends with";
    QueryValueFilterOperator["NOT_ENDS_WITH"] = "not ends with";
    QueryValueFilterOperator["IS_SET"] = "is set";
    QueryValueFilterOperator["NOT_IS_SET"] = "not is set";
    // numerical only
    QueryValueFilterOperator["MORE_THAN"] = "more than";
    QueryValueFilterOperator["LESS_THAN"] = "less than";
    QueryValueFilterOperator["AT_LEAST"] = "at least";
    QueryValueFilterOperator["AT_MOST"] = "at most";
    // date
    QueryValueFilterOperator["BEFORE"] = "before";
    QueryValueFilterOperator["AFTER"] = "after";
    QueryValueFilterOperator["BETWEEN"] = "between";
})(QueryValueFilterOperator || (exports.QueryValueFilterOperator = QueryValueFilterOperator = {}));
var QueryValueFilterOperatorInternal;
(function (QueryValueFilterOperatorInternal) {
    QueryValueFilterOperatorInternal["IS_NULL"] = "is null";
})(QueryValueFilterOperatorInternal || (exports.QueryValueFilterOperatorInternal = QueryValueFilterOperatorInternal = {}));
var QueryFrequencyValueFilterOperator;
(function (QueryFrequencyValueFilterOperator) {
    QueryFrequencyValueFilterOperator["EQUALS"] = "equals";
    QueryFrequencyValueFilterOperator["NOT_EQUALS"] = "not equals";
    QueryFrequencyValueFilterOperator["AT_LEAST_ONCE"] = "at least once";
    QueryFrequencyValueFilterOperator["ZERO_TIMES"] = "zero times";
    QueryFrequencyValueFilterOperator["MORE_THAN"] = "more than";
    QueryFrequencyValueFilterOperator["LESS_THAN"] = "less than";
    QueryFrequencyValueFilterOperator["AT_LEAST"] = "at least";
    QueryFrequencyValueFilterOperator["AT_MOST"] = "at most";
})(QueryFrequencyValueFilterOperator || (exports.QueryFrequencyValueFilterOperator = QueryFrequencyValueFilterOperator = {}));
exports.QueryValueFilterOperatorType = (0, typebox_1.EnumStrings)(typebox_2.Type.Enum(QueryValueFilterOperator));
exports.QueryValueFilterOperatorInternalType = (0, typebox_1.EnumStrings)(typebox_2.Type.Enum(QueryValueFilterOperatorInternal));
exports.QueryFrequencyValueFilterOperatorType = (0, typebox_1.EnumStrings)(typebox_2.Type.Enum(QueryFrequencyValueFilterOperator));

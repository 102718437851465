"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toBillingStatsResponse = exports.BillingStatsResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.BillingStatsResponseDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        account_id: typebox_1.Type.String({ format: 'uuid' }),
        current_month_total_tracked_events: typebox_1.Type.Number(),
    }),
]);
const toBillingStatsResponse = (accountId, currentMonthTotalTrackedEvents) => {
    return {
        account_id: accountId,
        current_month_total_tracked_events: currentMonthTotalTrackedEvents,
    };
};
exports.toBillingStatsResponse = toBillingStatsResponse;

import { QueryFrequencyValueFilterOperator } from '@bigdelta/lib-shared';
import { useFilterContext } from '../context/useFilterContext';
import { useFilterStore } from '../store';
import { DebouncedInput } from './common/DebouncedInput';
import { twMerge } from 'tailwind-merge';

export const EventValue = ({ index }) => {
  const { filterKey, mode } = useFilterContext();

  const filterItem = useFilterStore((state) => state.getFilterItem([...filterKey, index]));

  const setItemEventValue = useFilterStore((state) => state.setItemEventValue);

  const handleInputChange = (inputValue: string) => {
    setItemEventValue(filterKey, index, parseInt(inputValue, 10));
  };

  if (filterItem?.eventOperator === QueryFrequencyValueFilterOperator.ZERO_TIMES || !filterItem?.eventOperator) {
    return null;
  }

  return (
    <DebouncedInput
      type="number"
      value={filterItem?.eventValue?.toString() ?? ''}
      onChange={handleInputChange}
      className={twMerge(
        'rounded border-0 bg-transparent text-[12px] text-sm font-regular text-m-olive-400 ring-transparent focus:border-0 focus:text-m-olive-600 focus:ring-transparent group-hover:bg-[#f0f0f0]',
        mode === 'stacked' && 'w-full'
      )}
    />
  );
};

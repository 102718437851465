import { PageHeading } from '../../../components/PageHeading';
import { Button } from '../../../shared/ui/Button/Button';
import StarIcon from '../../../assets/icons/star-04.svg?react';
import ShuffleIcon from '../../../assets/icons/shuffle-01.svg?react';
import BarChartIcon from '../../../assets/icons/bar-chart-square-02.svg?react';
import { EmptyState } from '../../../shared/components/EmptyState.tsx';
import { useUser } from '../../auth/hooks/useUser.ts';
import { toastSuccess } from '../../../utils/toast.tsx';
import { tracking, TrackingEvent } from '../../../tracking';

const EMPTY_STATE_STAGES = [
  {
    icon: StarIcon,
    title: 'Start with high-level metrics',
    description: 'Start with revenue, costs and other high-level metrics. Break them down into sub-metrics.',
  },
  {
    icon: ShuffleIcon,
    title: 'Add assumptions',
    description: 'Make growth scenarios based on clearly defined assumptions.',
  },
  {
    icon: BarChartIcon,
    title: 'Track progress',
    description: 'Track estimate vs fact progress to see which metrics diverge from the plan.',
  },
];

export const PlanningEmptyPage = () => {
  const { user } = useUser();

  const handleOnNotifyClick = () => {
    tracking.track(TrackingEvent.PlanningNotifyRequested, { email: user.email });
    toastSuccess('Thanks for your interest!', '');
  };

  return (
    <div className="flex h-screen w-full flex-col">
      <div className="flex flex-col">
        <div className="flex items-center justify-between" style={{ zIndex: 1 }}>
          <PageHeading breadcrumbs={[{ label: 'Planning' }]} />
        </div>
        <hr className="mt-4 h-px bg-m-gray-300" />
        <EmptyState heading="Plan and set metric-based goals" stages={EMPTY_STATE_STAGES}>
          <Button intent="brand" size="xl" label="Coming Soon – Notify me" onClick={() => handleOnNotifyClick()} />
        </EmptyState>
      </div>
    </div>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toWorkspaceObjectResponse = exports.WorkspaceObjectPropertyRequirement = exports.WorkspaceObjectArrayPropertyTypes = exports.WorkspaceObjectStatus = exports.WorkspaceObjectType = void 0;
const types_1 = require("../types");
var WorkspaceObjectType;
(function (WorkspaceObjectType) {
    WorkspaceObjectType["USER"] = "USER";
    WorkspaceObjectType["ACCOUNT"] = "ACCOUNT";
    WorkspaceObjectType["WORKSPACE"] = "WORKSPACE";
    WorkspaceObjectType["NPS"] = "NPS";
    WorkspaceObjectType["CONVERSATION"] = "CONVERSATION";
    WorkspaceObjectType["DEAL"] = "DEAL";
    WorkspaceObjectType["LEAD"] = "LEAD";
    WorkspaceObjectType["SUBSCRIPTION"] = "SUBSCRIPTION";
    WorkspaceObjectType["INVOICE"] = "INVOICE";
    WorkspaceObjectType["CHARGE"] = "CHARGE";
    WorkspaceObjectType["SESSION"] = "SESSION";
    WorkspaceObjectType["CUSTOM"] = "CUSTOM";
    WorkspaceObjectType["COMPANIES"] = "COMPANIES";
    WorkspaceObjectType["PEOPLE"] = "PEOPLE";
})(WorkspaceObjectType || (exports.WorkspaceObjectType = WorkspaceObjectType = {}));
var WorkspaceObjectStatus;
(function (WorkspaceObjectStatus) {
    WorkspaceObjectStatus["ACTIVE"] = "ACTIVE";
    WorkspaceObjectStatus["INACTIVE"] = "INACTIVE";
    WorkspaceObjectStatus["VIRTUAL"] = "VIRTUAL";
})(WorkspaceObjectStatus || (exports.WorkspaceObjectStatus = WorkspaceObjectStatus = {}));
exports.WorkspaceObjectArrayPropertyTypes = [
    types_1.ResourcePropertyType.STRING_ARRAY,
    types_1.ResourcePropertyType.NUMBER_ARRAY,
    types_1.ResourcePropertyType.TIMESTAMP_ARRAY,
    types_1.ResourcePropertyType.BOOLEAN_ARRAY,
];
var WorkspaceObjectPropertyRequirement;
(function (WorkspaceObjectPropertyRequirement) {
    WorkspaceObjectPropertyRequirement["REQUIRED"] = "required";
    WorkspaceObjectPropertyRequirement["OPTIONAL"] = "optional";
})(WorkspaceObjectPropertyRequirement || (exports.WorkspaceObjectPropertyRequirement = WorkspaceObjectPropertyRequirement = {}));
const toWorkspaceObjectResponse = (workspaceObject) => {
    return {
        id: workspaceObject.id,
        account_id: workspaceObject.accountId,
        workspace_id: workspaceObject.workspaceId,
        api_slug: workspaceObject.apiSlug,
        singular_noun: workspaceObject.singularNoun,
        plural_noun: workspaceObject.pluralNoun,
        object_type: workspaceObject.objectType,
        properties: workspaceObject.properties,
        status: workspaceObject.status,
        label_properties: workspaceObject.labelProperties,
        icon_property: workspaceObject.iconProperty,
        records_changelog_enabled: workspaceObject.recordsChangelogEnabled,
    };
};
exports.toWorkspaceObjectResponse = toWorkspaceObjectResponse;

import { QueryFilterOperator } from '@bigdelta/lib-shared';

// TODO: Solve this with discriminated unions for records and events for filteritems
const EVENTS_PROPERTY_OPERATORS = ['equals', 'not equals', 'in', 'not in', 'gte', 'lte'] as const;
type EventsPropertyOperatorType = (typeof EVENTS_PROPERTY_OPERATORS)[number];
export const getEventPropertyOperator = (operator?: QueryFilterOperator): EventsPropertyOperatorType | undefined => {
  if (!operator) {
    return;
  }

  if (EVENTS_PROPERTY_OPERATORS.includes(operator as EventsPropertyOperatorType)) {
    return operator as EventsPropertyOperatorType;
  }
  return;
};

import { FC, PropsWithChildren, forwardRef, useState } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { twMerge } from '../../../utils/twMerge';
import { PropertyNameObject, RelationshipObjectData } from '../../types';
import { RecordPropertyMultilevelSelectContent } from '../../components/RecordPropertyMultilevelSelectContent';
import { MetadataResourcePropertyType, RelationshipEntityType, TableResourceType, TableSortDirection } from '@bigdelta/lib-shared';
import { ComboboxOption } from '../../../features/reports/components/common/EventPropertyCombobox';
import * as Popover from '@radix-ui/react-popover';
import * as Tabs from '../../ui/Tabs/Tabs';
import { Combobox as ComboboxPrimitive } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../../../features/auth/hooks/useQueryKeys';

import { useWorkspace } from '../../../features/auth/hooks/useWorkspace';
import { ComboboxInput } from '../../../components/Combobox/ComboboxInput';
import { RecordPropertyIcon } from '../../components/RecordPropertyIcon';

import ArrowUpIcon from '../../../assets/icons/arrow-up.svg?react';
import ArrowDownIcon from '../../../assets/icons/arrow-down.svg?react';
import { SVGComponent } from '../../../types';

import SortAscIcon from '../../../assets/icons/sort-asc.svg?react';
import SortDescIcon from '../../../assets/icons/sort-desc.svg?react';
import AddLeftIcon from '../../../assets/icons/add-left.svg?react';
import AddRightIcon from '../../../assets/icons/add-right.svg?react';
import MoveLeftIcon from '../../../assets/icons/arrow-left.svg?react';
import MoveRightIcon from '../../../assets/icons/arrow-right.svg?react';
import HideIcon from '../../../assets/icons/eye-off.svg?react';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

interface SortIconProps {
  sort: TableSortDirection | null | undefined;
}

const SortIcon: FC<SortIconProps> = ({ sort }) => {
  if (!sort) {
    return null;
  }

  return sort === TableSortDirection.ASC ? (
    <ArrowUpIcon className="h-4 w-4" />
  ) : sort === TableSortDirection.DESC ? (
    <ArrowDownIcon className="h-4 w-4" />
  ) : null;
};

interface DropdownMenuItemProps extends DropdownMenu.DropdownMenuItemProps {
  icon?: SVGComponent;
}

export const DropdownMenuItem = forwardRef<HTMLDivElement, DropdownMenuItemProps>(({ children, className, icon, ...props }, ref) => {
  const Icon = icon;

  return (
    <DropdownMenu.Item
      className={twMerge(
        'flex select-none items-center gap-x-2 rounded px-2 py-1.5 text-xs text-m-olive-800 outline-none radix-highlighted:bg-m-gray-200',
        className
      )}
      {...props}
      ref={ref}
    >
      {Icon && (
        <div className="p-1">
          <Icon className="h-4 w-4 text-m-olive-400" />
        </div>
      )}
      {children}
    </DropdownMenu.Item>
  );
});

interface DataTableColumnActionsButtonContentProps extends PropsWithChildren {
  enabled?: boolean;
  sort?: TableSortDirection | null;
}

const DataTableColumnActionsButtonContent = forwardRef<HTMLButtonElement, DataTableColumnActionsButtonContentProps>(
  ({ children, enabled = true, sort, ...props }, ref) => (
    <button
      className={twMerge(
        'flex w-full cursor-default items-center gap-x-0.5 overflow-x-hidden px-3 py-2.5 text-left',
        enabled && 'cursor-pointer hover:bg-m-gray-200 radix-state-open:bg-m-gray-200'
      )}
      {...props}
      ref={ref}
    >
      <div className="shrink grow overflow-hidden truncate">{children}</div>
      <SortIcon sort={sort} />
    </button>
  )
);

export enum HorizontalDirection {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum ColumnActionType {
  SORT = 'SORT',
  HIDE = 'HIDE',

  ADD_LEFT = 'ADD_LEFT',
  ADD_RIGHT = 'ADD_RIGHT',

  ADD_TO_END = 'ADD_TO_END',

  MOVE_LEFT = 'MOVE_LEFT',
  MOVE_RIGHT = 'MOVE_RIGHT',
}

interface ColumnActionData {
  [ColumnActionType.ADD_LEFT]?: {
    property: PropertyNameObject;
    relationships: RelationshipObjectData[] | null;
  };
  [ColumnActionType.ADD_RIGHT]?: {
    property: PropertyNameObject;
    relationships: RelationshipObjectData[] | null;
  };
  [ColumnActionType.ADD_TO_END]?: {
    property: PropertyNameObject;
    relationships: RelationshipObjectData[] | null;
  };
  [ColumnActionType.MOVE_LEFT]?: Record<string, never>;
  [ColumnActionType.MOVE_RIGHT]?: Record<string, never>;
  [ColumnActionType.SORT]?: {
    direction: TableSortDirection;
  };
  [ColumnActionType.HIDE]?: Record<string, never>;
}
export interface ColumnAction {
  type: ColumnActionType;
  data: ColumnActionData;
}

interface DataTableColumnActionsProps {
  children: React.ReactNode;
  resourceType: TableResourceType;
  resourceId?: string;
  onSelect: (action: ColumnAction) => void;
  actions: ColumnActionType[];
  defaultAction?: ColumnActionType;
  enable?: boolean;
  sort?: TableSortDirection | null;
}

export const DataTableColumnActions: FC<DataTableColumnActionsProps> = ({
  children,
  resourceId,
  resourceType,
  onSelect,
  actions,
  defaultAction,
  enable = true,
  sort,
}) => {
  const [actionType, setActionType] = useState<ColumnActionType | null>(defaultAction ?? null);

  const handleSelectProperty = (property: PropertyNameObject, relationships: RelationshipObjectData[] | null) => {
    if (
      actionType &&
      (actionType === ColumnActionType.ADD_LEFT || actionType === ColumnActionType.ADD_RIGHT || actionType === ColumnActionType.ADD_TO_END)
    ) {
      onSelect({
        type: actionType,
        data: {
          [actionType]: {
            property,
            relationships,
          },
        },
      });
      setActionType(defaultAction ?? null);
    }
  };

  if (actionType === ColumnActionType.ADD_LEFT || actionType === ColumnActionType.ADD_RIGHT || actionType === ColumnActionType.ADD_TO_END) {
    return (
      <DataTableColumnPropertiesCombobox
        resourceType={resourceType}
        resourceId={resourceId}
        onSelectProperty={handleSelectProperty}
        onClose={() => setActionType(defaultAction ?? null)}
        defaultOpen={!defaultAction}
      >
        {children}
      </DataTableColumnPropertiesCombobox>
    );
  }

  const isActive = (action: ColumnActionType) => actions.includes(action);

  return (
    <DropdownMenu.Root open={enable ? undefined : false}>
      <DropdownMenu.Trigger asChild>
        <DataTableColumnActionsButtonContent enabled={enable} sort={sort}>
          {children}
        </DataTableColumnActionsButtonContent>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="w-52 rounded-md border border-m-olive-100 bg-m-white p-2 shadow-xl"
          align="start"
          sideOffset={4}
          alignOffset={4}
        >
          {isActive(ColumnActionType.SORT) && (
            <DropdownMenuItem
              icon={SortAscIcon}
              onSelect={() => {
                setActionType(null);
                onSelect({
                  type: ColumnActionType.SORT,
                  data: {
                    [ColumnActionType.SORT]: {
                      direction: TableSortDirection.ASC,
                    },
                  },
                });
              }}
            >
              Sort ascending
            </DropdownMenuItem>
          )}
          {isActive(ColumnActionType.SORT) && (
            <DropdownMenuItem
              icon={SortDescIcon}
              onSelect={() => {
                setActionType(null);
                onSelect({
                  type: ColumnActionType.SORT,
                  data: {
                    [ColumnActionType.SORT]: {
                      direction: TableSortDirection.DESC,
                    },
                  },
                });
              }}
            >
              Sort descending
            </DropdownMenuItem>
          )}
          {isActive(ColumnActionType.ADD_LEFT) && (
            <DropdownMenuItem
              icon={AddLeftIcon}
              onSelect={() => {
                setActionType(ColumnActionType.ADD_LEFT);
              }}
            >
              Add left
            </DropdownMenuItem>
          )}
          {isActive(ColumnActionType.ADD_RIGHT) && (
            <DropdownMenuItem
              icon={AddRightIcon}
              onSelect={() => {
                setActionType(ColumnActionType.ADD_RIGHT);
              }}
            >
              Add right
            </DropdownMenuItem>
          )}
          {isActive(ColumnActionType.ADD_TO_END) && (
            <DropdownMenuItem
              icon={AddRightIcon}
              onSelect={() => {
                setActionType(ColumnActionType.ADD_TO_END);
              }}
            >
              Add right
            </DropdownMenuItem>
          )}
          {isActive(ColumnActionType.MOVE_LEFT) && (
            <DropdownMenuItem
              icon={MoveLeftIcon}
              onSelect={() => {
                setActionType(null);
                onSelect({
                  type: ColumnActionType.MOVE_LEFT,
                  data: {},
                });
              }}
            >
              Move left
            </DropdownMenuItem>
          )}
          {isActive(ColumnActionType.MOVE_RIGHT) && (
            <DropdownMenuItem
              icon={MoveRightIcon}
              onSelect={() => {
                setActionType(null);
                onSelect({
                  type: ColumnActionType.MOVE_RIGHT,
                  data: {},
                });
              }}
            >
              Move right
            </DropdownMenuItem>
          )}
          {isActive(ColumnActionType.HIDE) && (
            <DropdownMenuItem
              icon={HideIcon}
              onSelect={() => {
                setActionType(null);
                onSelect({
                  type: ColumnActionType.HIDE,
                  data: {},
                });
              }}
            >
              Hide
            </DropdownMenuItem>
          )}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

interface DataTableColumnPropertiesComboboxProps extends PropsWithChildren {
  resourceType: TableResourceType;
  resourceId?: string;
  onSelectProperty: (property: PropertyNameObject, relationships: RelationshipObjectData[] | null) => void;
  onClose: () => void;
  defaultOpen?: boolean;
  sort?: TableSortDirection;
}

const DataTableColumnPropertiesCombobox: FC<DataTableColumnPropertiesComboboxProps> = ({
  children,
  resourceType,
  resourceId,
  onSelectProperty,
  onClose,
  defaultOpen = false,
  sort,
}) => {
  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();

  const [search, setSearch] = useState('');
  const [activeTab, setActiveTab] = useState<'events' | 'properties' | string>('events');

  const eventPropertiesQuery = useQuery({
    queryKey: queryKeys.list('metadata', resourceType, resourceId, 'names'),
    queryFn: () =>
      bigdeltaAPIClient.v1.metadataResourcesPropertiesNamesList({
        workspace_id: currentWorkspaceId,
        resource_type: MetadataResourcePropertyType.EVENT,
      }),
    enabled: resourceType === TableResourceType.EVENT,
  });

  const eventProperties = eventPropertiesQuery.data?.items.filter((property) =>
    property.property_name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );

  return (
    <Popover.Root
      defaultOpen={defaultOpen}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <Popover.Trigger asChild>
        <DataTableColumnActionsButtonContent sort={sort}>{children}</DataTableColumnActionsButtonContent>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content className="min-w-80 rounded-lg shadow-xl" sideOffset={4} alignOffset={4} align="end" collisionPadding={4}>
          {resourceType === TableResourceType.OBJECT && resourceId && (
            <RecordPropertyMultilevelSelectContent
              parentEntityType={RelationshipEntityType.OBJECT}
              parentEntityId={resourceId}
              onChange={onSelectProperty}
              idDisplayMode="object"
            />
          )}
          {resourceType === TableResourceType.EVENT && (
            <div className="rounded-lg border border-m-olive-100 bg-m-white">
              <Tabs.Root value={activeTab} onValueChange={setActiveTab}>
                <Tabs.List className="flex shrink-0 border-b border-b-m-olive-100 px-2">
                  <Tabs.Trigger value="events">Properties</Tabs.Trigger>
                  <Tabs.Trigger value="properties">Records</Tabs.Trigger>
                </Tabs.List>
                <Tabs.Content value="events" className="p-2">
                  <ComboboxPrimitive value={null} onChange={(property: PropertyNameObject) => onSelectProperty(property, null)}>
                    <ComboboxInput value={search} onChange={(e) => setSearch(e.target.value)} />
                    <ComboboxPrimitive.Options static className="mt-2 flex max-h-96 flex-col overflow-y-auto pt-1">
                      {eventProperties?.map((property) => (
                        <ComboboxOption key={property.property_name} value={property}>
                          <RecordPropertyIcon propertyType={property.property_type} className="h-4 w-4 shrink-0 text-m-olive-600" />
                          <span>{property.property_name}</span>
                        </ComboboxOption>
                      ))}
                    </ComboboxPrimitive.Options>
                  </ComboboxPrimitive>
                </Tabs.Content>
                <Tabs.Content value="properties" className="p-2">
                  <RecordPropertyMultilevelSelectContent
                    parentEntityType={RelationshipEntityType.EVENT}
                    parentEntityId={null}
                    idDisplayMode="object"
                    className="border-0 p-0 shadow-none"
                    onChange={(property, relationships) => {
                      console.log(property, relationships);
                      property && onSelectProperty(property, relationships);
                    }}
                  />
                </Tabs.Content>
              </Tabs.Root>
            </div>
          )}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

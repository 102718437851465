import { ButtonHTMLAttributes, forwardRef } from 'react';
import FilterFunnelIcon from '../../../assets/icons/filter-funnel-01.svg?react';

interface FiltersButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isOpen: boolean;
  count?: number;
}

export const FiltersButton = forwardRef<HTMLButtonElement, FiltersButtonProps>(({ count, isOpen, ...props }, ref) => (
  <button className="flex items-center gap-x-2 rounded-lg border border-m-olive-100 px-2.5 py-2" {...props} ref={ref}>
    <FilterFunnelIcon className="h-4 w-4 text-m-olive-300" />
    <span className="text-sm font-medium">{isOpen ? 'Hide Filters' : 'Show Filters'}</span>
    {count ? (
      <span className="flex h-4 w-4 items-center justify-center rounded-full border border-m-blue-200 bg-m-blue-100 text-xxs font-medium text-m-blue-600">
        {count}
      </span>
    ) : null}
  </button>
));

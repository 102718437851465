"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateMemberRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const types_1 = require("../types");
exports.UpdateMemberRequestDef = typebox_1.Type.Object({
    first_name: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    last_name: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    photo: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    preferred_timezone: (0, typebox_2.OptionalNullable)((0, typebox_2.StringLiterals)(types_1.TimezoneValues)),
}, { additionalProperties: false });

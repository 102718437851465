import React, { useCallback, useEffect, useState } from 'react';
import { toString } from 'lodash';
import { Input, InputProps } from '../Input/Input.tsx';

type OnBlurInputProps = Omit<InputProps, 'onChange'> & {
  onChange: (value: string) => void;
}

export const OnBlurInput: React.FC<OnBlurInputProps> = ({ onChange, value, ...rest }) => {
  const [lastValue, setLastValue] = useState<string>(toString(value));
  const handleChange = useCallback((evt) => setLastValue(evt.target.value), [setLastValue]);
  const handleBlur = useCallback(() => {
    if (lastValue !== value) {
      onChange && onChange(lastValue);
    }
  }, [lastValue, onChange, value]);

  const handleKeyDown = useCallback(
    (evt) => {
      if (evt.key === 'Enter' || evt.key === 'Escape') {
        handleBlur();
      }
    },
    [handleBlur]
  );

  useEffect(() => {
    setLastValue(toString(value));
  }, [value]);

  return <Input onChange={handleChange} onBlur={handleBlur} onKeyDown={handleKeyDown} value={lastValue} {...rest} />;
};
import { QueryReportsCreateData } from '@bigdelta/lib-api-client';
import { PropertyNameObject } from '../../../shared/types';
import { AliasPrefix } from '../store';

export const normalizeData = (data: QueryReportsCreateData, breakdownProperty: PropertyNameObject | null, prefix: AliasPrefix) => {
  if (!breakdownProperty || !data.queries[0]) {
    return;
  }

  for (const item of data.queries[0].result) {
    const propertyKey = [prefix, breakdownProperty.property_name].join('_');

    if (item[propertyKey]?.length == 0) {
      item[propertyKey] = '{ not set }';
    }
  }

  if (data.queries[0].metadata) {
    for (const item of data.queries[0].metadata.aggregation.result) {
      const propertyKey = [prefix, breakdownProperty.property_name].join('_');

      if (item[propertyKey]?.length == 0) {
        item[propertyKey] = '{ not set }';
      }
    }
  }
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryMetricsGraphResultWithChangesWithScenariosVODef = exports.QueryMetricsGraphResultWithChangesVODef = exports.QueryMetricsGraphResultVODef = exports.QueryMetricsGraphResultBaseVODef = exports.QueryMetricsGraphNodeResultWithChangesWithScenariosVODef = exports.MetricsGraphScenarioProgressDef = exports.ScenarioProgressDef = exports.QueryMetricsGraphNodeResultWithChangesVODef = exports.QueryMetricsGraphNodeResultVODef = exports.ImpactDef = exports.QueryMetricsGraphResultCalculationDetailsDef = exports.CalculationDetailsMessageType = void 0;
const typebox_1 = require("@sinclair/typebox");
const metricGraphDefinitionVO_1 = require("./metricGraphDefinitionVO");
const reportsQueryRequest_1 = require("./reportsQueryRequest");
const queryTypes_1 = require("./queryTypes");
const typebox_2 = require("../typebox");
var CalculationDetailsMessageType;
(function (CalculationDetailsMessageType) {
    CalculationDetailsMessageType["WARNING"] = "warning";
})(CalculationDetailsMessageType || (exports.CalculationDetailsMessageType = CalculationDetailsMessageType = {}));
exports.QueryMetricsGraphResultCalculationDetailsDef = typebox_1.Type.Object({
    message: typebox_1.Type.Object({
        type: typebox_1.Type.Enum(CalculationDetailsMessageType),
        value: typebox_1.Type.String(),
    }),
});
exports.ImpactDef = typebox_1.Type.Object({
    expression: typebox_1.Type.String(),
});
exports.QueryMetricsGraphNodeResultVODef = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    type: typebox_1.Type.Enum(metricGraphDefinitionVO_1.MetricGraphMetricDefinitionType),
    format: (0, typebox_2.OptionalNullable)(reportsQueryRequest_1.ReportsMetricQueryFormatVODef),
    display_name: typebox_1.Type.String(),
    key: typebox_1.Type.String(),
    metric_id: typebox_1.Type.String(),
    value: (0, typebox_2.Nullable)(typebox_1.Type.Number()),
    impact: (0, typebox_2.OptionalNullable)(exports.ImpactDef),
});
exports.QueryMetricsGraphNodeResultWithChangesVODef = typebox_1.Type.Composite([
    exports.QueryMetricsGraphNodeResultVODef,
    typebox_1.Type.Object({
        previous: typebox_1.Type.Object({
            time_shift: queryTypes_1.TimeShiftVODef,
            value: typebox_1.Type.Number(),
        }),
    }),
]);
exports.ScenarioProgressDef = typebox_1.Type.Object({
    start_at: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'date-time' })),
    end_at: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'date-time' })),
    value: typebox_1.Type.Number(),
    start_value: typebox_1.Type.Number(),
    target_value: typebox_1.Type.Number(),
    target_achievement: typebox_1.Type.Number(),
});
exports.MetricsGraphScenarioProgressDef = typebox_1.Type.Object({
    aggregated: typebox_1.Type.Optional(exports.ScenarioProgressDef),
    segmented: typebox_1.Type.Optional(typebox_1.Type.Record(typebox_1.Type.String(), exports.ScenarioProgressDef)),
});
exports.QueryMetricsGraphNodeResultWithChangesWithScenariosVODef = typebox_1.Type.Composite([
    exports.QueryMetricsGraphNodeResultWithChangesVODef,
    typebox_1.Type.Object({
        scenario_progress: typebox_1.Type.Optional(exports.MetricsGraphScenarioProgressDef),
    }),
]);
exports.QueryMetricsGraphResultBaseVODef = typebox_1.Type.Object({
    calculation_details: typebox_1.Type.Optional(exports.QueryMetricsGraphResultCalculationDetailsDef),
    edges: typebox_1.Type.Array(metricGraphDefinitionVO_1.MetricGraphEdgeDefinitionVODef),
});
exports.QueryMetricsGraphResultVODef = typebox_1.Type.Composite([
    exports.QueryMetricsGraphResultBaseVODef,
    typebox_1.Type.Object({
        nodes: typebox_1.Type.Array(exports.QueryMetricsGraphNodeResultVODef),
        id: typebox_1.Type.String({ format: 'uuid' }),
    }),
]);
exports.QueryMetricsGraphResultWithChangesVODef = typebox_1.Type.Composite([
    exports.QueryMetricsGraphResultBaseVODef,
    typebox_1.Type.Object({
        nodes: typebox_1.Type.Array(exports.QueryMetricsGraphNodeResultWithChangesVODef),
        id: typebox_1.Type.String({ format: 'uuid' }),
    }),
]);
exports.QueryMetricsGraphResultWithChangesWithScenariosVODef = typebox_1.Type.Composite([
    exports.QueryMetricsGraphResultBaseVODef,
    typebox_1.Type.Object({
        nodes: typebox_1.Type.Array(exports.QueryMetricsGraphNodeResultWithChangesWithScenariosVODef),
        id: typebox_1.Type.String({ format: 'uuid' }),
    }),
]);

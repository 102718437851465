import ColorHash from 'color-hash';
import React, { FC, useCallback, useState } from 'react';
import RelatesIcon from '../../../../assets/icons/switch-horizontal-01.svg?react';
import { Dialog } from '@headlessui/react';
import { RelationshipForm } from './RelationshipForm.tsx';
import { Button } from '../../../../shared/ui/Button/Button.tsx';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toastError, toastSuccess } from '../../../../utils/toast.tsx';
import { useWorkspace } from '../../../auth/hooks/useWorkspace.tsx';
import { useQueryKeys } from '../../../auth/hooks/useQueryKeys.ts';
import { bigdeltaAPIClient } from '../../../../client/bigdeltaAPIClient.ts';

const bgColorHash = new ColorHash({ saturation: 0.9, lightness: 0.9 });
const textColorHash = new ColorHash({ saturation: 0.7, lightness: 0.3 });

export interface ObjectRelationship {
  id: string;
  property: string;
  related_entity_id: string;
  related_entity_name: string;
  related_entity_property: string;
}

export const RelationshipCell: FC<{
  objectId: string;
  relationship: ObjectRelationship;
}> = ({ objectId, relationship }) => {
  const { currentWorkspaceId } = useWorkspace();
  const queryClient = useQueryClient();
  const queryKeys = useQueryKeys();

  const [isRemoveRelationshipDialogOpen, setIsRemoveRelationshipDialogOpen] = useState(false);

  const removeRelationshipMutation = useMutation({
    mutationFn: () => bigdeltaAPIClient.v1.relationshipsDelete(relationship.id, { workspace_id: currentWorkspaceId }),
    onSuccess: () => {
      toastSuccess('Relationship removed', 'The object relationship has been removed successfully');
      setIsRemoveRelationshipDialogOpen(false);
      return queryClient.invalidateQueries(queryKeys.relationships());
    },
    onError: () => {
      toastError('Failed to remove object relationship');
    },
  });

  const onRemoveRelationship = useCallback(() => {
    removeRelationshipMutation.mutate();
  }, [removeRelationshipMutation]);

  if (!relationship) {
    return <div></div>;
  }

  return (
    <>
      <div
        className="flex max-w-fit cursor-pointer items-center justify-center gap-1 rounded-full px-2 py-1 text-xs font-medium"
        style={{ color: textColorHash.hex(relationship.related_entity_name), background: bgColorHash.hex(relationship.related_entity_name) }}
        onClick={() => setIsRemoveRelationshipDialogOpen(true)}
      >
        <RelatesIcon className="h-4" />
        <span className="mr-1">{relationship.related_entity_name}</span>
      </div>
      <Dialog open={isRemoveRelationshipDialogOpen} onClose={() => setIsRemoveRelationshipDialogOpen(false)} className="relative z-[999999]">
        <div className="fixed inset-0 bg-m-gray-700 opacity-95" aria-hidden="true" />
        <div className="fixed inset-0 flex w-screen items-center justify-center">
          <Dialog.Panel className="mx-auto flex w-3/5 max-w-lg flex-col gap-y-6 rounded-xl bg-white">
            <Dialog.Title className="p-6 pb-0 text-xl text-m-olive-700">Relationship</Dialog.Title>
            <RelationshipForm
              objectId={objectId}
              initialValues={{
                entity_id: relationship.related_entity_id,
                entity_property: relationship.related_entity_property,
              }}
              onCancel={() => setIsRemoveRelationshipDialogOpen(false)}
              renderSubmitButton={(isValid, handleSubmit) => {
                return <Button label="Remove" intent="destructive" size="sm" onClick={handleSubmit(onRemoveRelationship)} />;
              }}
            />
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.toDashboardResponse = exports.DashboardVODef = void 0;
const dashboard_1 = require("../model/dashboard");
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const model_1 = require("../model");
const response_1 = require("../response");
exports.DashboardVODef = typebox_1.Type.Composite([
    typebox_1.Type.Omit(dashboard_1.DashboardDef, ['owner', 'created_by', 'updated_by']),
    typebox_1.Type.Object({
        panels: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(model_1.DashboardPanelDef)),
        owner: (0, typebox_2.OptionalNullable)(model_1.MemberDef),
        createdBy: (0, typebox_2.OptionalNullable)(model_1.MemberDef),
        updatedBy: (0, typebox_2.OptionalNullable)(model_1.MemberDef),
    }),
]);
const toDashboardResponse = (dashboard) => {
    const { panels, systemCreatedAt, systemUpdatedAt, owner, createdBy, updatedBy } = dashboard, rest = __rest(dashboard, ["panels", "systemCreatedAt", "systemUpdatedAt", "owner", "createdBy", "updatedBy"]);
    return Object.assign(Object.assign({}, rest), { panels: (panels || []).map((item) => ({
            id: item.id,
            order: item.order,
            configuration: item.configuration,
        })), created_at: systemCreatedAt, updated_at: systemUpdatedAt, owner: (0, response_1.toMemberResponse)(owner), created_by: (0, response_1.toMemberResponse)(createdBy), updated_by: (0, response_1.toMemberResponse)(updatedBy) });
};
exports.toDashboardResponse = toDashboardResponse;

import { FC } from 'react';

interface AuthScreenTitleProps {
  title: string;
  subtitle?: string;
}

export const AuthScreenTitle: FC<AuthScreenTitleProps> = ({ title, subtitle }) => (
  <>
    <div className="flex items-center justify-center text-display-xs font-medium">{title}</div>
    {subtitle && <div className="mt-4 pb-12 text-center text-lg text-m-olive-400">{subtitle}</div>}
  </>
);

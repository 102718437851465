import { QueryReportsCreatePayload } from '@bigdelta/lib-api-client';

export const getTimeFromRange = (range: string): QueryReportsCreatePayload['time'] => {
  switch (range) {
    case 'Today':
      return {
        date_range_type: 'IN_THE_LAST',
        window: {
          unit: 'day',
          value: 1,
        },
      };
    case '7D':
      return {
        date_range_type: 'IN_THE_LAST',
        window: {
          unit: 'day',
          value: 7,
        },
      };
    case '30D':
      return {
        date_range_type: 'IN_THE_LAST',
        window: {
          unit: 'day',
          value: 30,
        },
      };
    case '3M':
      return {
        date_range_type: 'IN_THE_LAST',
        window: {
          unit: 'month',
          value: 3,
        },
      };
    case '6M':
      return {
        date_range_type: 'IN_THE_LAST',
        window: {
          unit: 'month',
          value: 6,
        },
      };
    case '12M':
      return {
        date_range_type: 'IN_THE_LAST',
        window: {
          unit: 'month',
          value: 12,
        },
      };
    case '24M':
      return {
        date_range_type: 'IN_THE_LAST',
        window: {
          unit: 'month',
          value: 24,
        },
      };
    default:
      return {
        date_range_type: 'IN_THE_LAST',
        window: {
          unit: 'day',
          value: 30,
        },
      };
  }
};

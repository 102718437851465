import { useCallback, useEffect } from 'react';
import { FilterItem, FilterKey, useFilterStore } from '../../../shared/filters/store';
import { getRecordFilterItemsFromSegment } from '../../../shared/utils/getRecordFilterItemsFromSegment.ts';
import { getEventFilterItemsFromSegment } from '../../../shared/utils/getEventFilterItemsFromSegment.ts';
import { useRelationshipsQuery } from '../../../shared/data/useRelationshipsQuery.ts';
import { useWorkspace } from '../../auth/hooks/useWorkspace.tsx';
import { useReportStore } from '../store';
import { FormulaNamedMetricVO } from '@bigdelta/lib-shared';
import { cloneDeep } from 'lodash';

type Parameters = {
  enabled?: boolean;
  formulaNamedMetric?: FormulaNamedMetricVO;
  filterKey: FilterKey;
  reportKey: string;
};

export const useHydrateReportStoreFromFormulaMetric = ({
  formulaNamedMetric: originalFormulaNamedMetric,
  filterKey,
  enabled,
  reportKey,
}: Parameters) => {
  const { currentWorkspaceId } = useWorkspace();
  const { isHydrated, hydrateFromNamedFormulaMetric, markHydrated } = useReportStore(reportKey)((state) => state);
  const relationshipsQuery = useRelationshipsQuery({ workspaceId: currentWorkspaceId });
  const { setFilterItems } = useFilterStore((state) => state);

  const hydrateStoreFromMetric = useCallback(() => {
    if (isHydrated || !relationshipsQuery.isSuccess) {
      return;
    }

    if (!originalFormulaNamedMetric) {
      markHydrated();
      return;
    }

    const formulaNamedMetric = cloneDeep(originalFormulaNamedMetric);
    let filterItems: FilterItem[] = [];

    if (formulaNamedMetric.records?.filter) {
      filterItems = getRecordFilterItemsFromSegment(
        formulaNamedMetric.records.filter.conditions,
        formulaNamedMetric.records.resource.id,
        relationshipsQuery.data.relationships
      ).filter((item) => typeof item !== 'undefined');
    }

    if (formulaNamedMetric.events?.filter) {
      filterItems = getEventFilterItemsFromSegment(formulaNamedMetric.events.filter.conditions, relationshipsQuery.data.relationships, false).filter(
        (item) => typeof item !== 'undefined'
      );
    }

    setFilterItems(filterKey, filterItems);
    hydrateFromNamedFormulaMetric(formulaNamedMetric, filterItems, relationshipsQuery.data.relationships);
  }, [
    originalFormulaNamedMetric,
    isHydrated,
    relationshipsQuery.isSuccess,
    relationshipsQuery.data,
    setFilterItems,
    filterKey,
    hydrateFromNamedFormulaMetric,
    markHydrated,
  ]);

  useEffect(() => {
    if (enabled) {
      hydrateStoreFromMetric();
    }
  }, [hydrateStoreFromMetric, enabled]);

  return null;
};

import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../../../../auth/hooks/useQueryKeys';
import { useWorkspace } from '../../../../auth/hooks/useWorkspace';
import { AutomationWorkflowBlockType, IntegrationType, MetadataResourcePropertyType } from '@bigdelta/lib-shared';
import { Button } from '../../../../../shared/ui/Button/Button';
import { Link } from 'react-router-dom';
import { FC, useMemo, useState } from 'react';
import { Listbox, Option } from '../../../../../shared/components/Listbox';
import { produce } from 'immer';
import { bigdeltaAPIClient } from '../../../../../client/bigdeltaAPIClient.ts';
import { SlackMessageInput } from './SlackMessageInput';
import { useTriggerNode } from '../../../hooks/useTriggerNode';
import { TriggerType } from '../../../types';
import { AutomationsCreateData } from '@bigdelta/lib-api-client';
import { Descendant, Text } from 'slate';
import { VariableInputElementType } from '../../../../../shared/types';
import { ParagraphElement, PropertyVariableElement } from '../../../../../shared/slate';
import { set } from 'lodash';

type AutomationBlock = AutomationsCreateData['configuration']['trigger']['block'];

interface ActionsSlackConfigProps {
  id?: string;
  type: AutomationWorkflowBlockType;
  block?: AutomationBlock;
  onChange: (block: AutomationBlock) => void;
}

//TODO: Loading state
export const ActionSlackConfig: FC<ActionsSlackConfigProps> = ({ id, block, onChange }) => {
  const { currentWorkspaceId } = useWorkspace();
  const [isConnectSlackClicked, setIsConnectSlackClicked] = useState(false);

  const triggerNode = useTriggerNode();

  const queryKeys = useQueryKeys();

  const integrationsQuery = useQuery({
    queryKey: queryKeys.integrations(),
    queryFn: () => bigdeltaAPIClient.v1.integrationsList({ workspace_id: currentWorkspaceId }),
    refetchInterval: isConnectSlackClicked ? 5000 : false,
    refetchOnWindowFocus: true,
  });

  const slackIntegration = integrationsQuery.data?.find((integration) => integration.type === IntegrationType.SLACK);

  const slackChannelsQuery = useQuery({
    queryKey: [...queryKeys.integration(slackIntegration?.id), 'channels'],
    queryFn: () => bigdeltaAPIClient.v1.integrationsSlackChannelsDetail(slackIntegration?.id ?? ''),
    enabled: !!slackIntegration?.id,
  });

  const slackChannelOptions = useMemo(() => {
    return slackChannelsQuery.data?.map((channel) => ({
      label: channel.name,
      value: channel.id,
      icon: () => null,
    }));
  }, [slackChannelsQuery.data]);

  const selectedChannel = useMemo(() => {
    if (!slackChannelOptions) {
      return null;
    }

    const currentOption = slackChannelOptions.find((channel) => channel.value === block?.send_slack_message?.channel_id) ?? null;

    return currentOption;
  }, [block?.send_slack_message?.channel_id, slackChannelOptions]);

  const handleChangeChannel = (option: Option<string>) => {
    if (!slackIntegration || !block) return;

    onChange(
      produce(block, (draft) => {
        draft.id = id;

        if (draft.send_slack_message) {
          draft.send_slack_message.integration_id = slackIntegration.id;
          draft.send_slack_message.channel_id = option.value;
        }
      })
    );
  };

  // const isParagraph = (element: Descendant): element is ParagraphElement => {
  //   return 'type' in element && element.type === VariableInputElementType.Paragraph;
  // };

  // const isVariable = (element: Descendant): element is PropertyVariableElement => {
  //   return 'type' in element && element.type === VariableInputElementType.PropertyVariable;
  // };

  const isText = (element: Descendant): element is Text => {
    return 'text' in element;
  };

  const serialize = (node: Descendant) => {
    if (isText(node)) {
      return node.text;
    }

    const children = node.children.map((n) => serialize(n)).join('');

    switch (node.type) {
      case VariableInputElementType.PropertyVariable:
        return `{{${node.property.property_name}}}`;
      case VariableInputElementType.Paragraph:
        return `${children}\n`;
      default:
        return children;
    }
  };

  const handleChangeMessage = (parentElements: Descendant[]) => {
    if (!block) {
      return;
    }

    const textExpression = parentElements.length && parentElements.length > 1 ? parentElements.map(serialize).join('') : serialize(parentElements[0]);

    onChange(
      produce(block, (draft) => {
        set(draft, 'send_slack_message.message_template', textExpression ?? '');
      })
    );

    return;
  };

  // TODO: This is sketchy, review later
  const parseLine = (line: string): Descendant[] => {
    const elements: Descendant[] = [];
    const regex = /{{(.*?)}}/g;
    let lastIndex = 0;
    let match;

    while ((match = regex.exec(line)) !== null) {
      // Add text element for the text before the variable
      if (match.index > lastIndex) {
        elements.push({
          text: line.slice(lastIndex, match.index),
        });
      }

      // Add variable element
      elements.push(
        { text: '' },
        {
          type: VariableInputElementType.PropertyVariable,
          property: { property_name: match[1] },
          children: [{ text: '' }],
        } as PropertyVariableElement,
        { text: '' }
      );

      lastIndex = regex.lastIndex;
    }

    // Add remaining text after the last variable
    if (lastIndex < line.length) {
      elements.push({
        text: line.slice(lastIndex),
      } as Text);
    }

    return [
      {
        type: VariableInputElementType.Paragraph,
        children: [{ text: '' }, ...elements],
      } as ParagraphElement,
    ];
  };

  // TODO: Fix multiple new lines collapsing into one
  const parseMessageTemplate = (messageTemplate: string | undefined): Descendant[] | undefined => {
    if (!messageTemplate) {
      return;
    }

    const parentElements: Descendant[] = messageTemplate
      .split(/\r?\n/)
      .filter((str) => str)
      .map((line) => {
        return {
          type: VariableInputElementType.Paragraph,
          children: parseLine(line),
        };
      });

    return parentElements;
  };

  let resourceType: MetadataResourcePropertyType | undefined =
    triggerNode?.data.triggerType &&
    [TriggerType.RECORD_CREATED, TriggerType.RECORD_DELETED, TriggerType.RECORD_UPDATED].includes(triggerNode.data.triggerType)
      ? MetadataResourcePropertyType.OBJECT
      : undefined;
  resourceType = triggerNode?.data.triggerType === TriggerType.EVENT_OCCURRED ? MetadataResourcePropertyType.EVENT : resourceType;

  const resourceId = resourceType === MetadataResourcePropertyType.OBJECT ? triggerNode?.data.workspaceObject?.id : undefined;

  return (
    <div>
      <div className="flex flex-col items-stretch">
        {!!integrationsQuery.isSuccess && !slackIntegration?.id && (
          <Link to="/integrations" target="__blank" onClick={() => setIsConnectSlackClicked(true)}>
            <Button intent="primary" label="Connect Slack" fullWidth />
          </Link>
        )}
        {!!slackIntegration?.id && slackChannelsQuery.isLoading && <div>Loading...</div>}
        <div className="flex flex-col gap-y-6">
          {!!slackIntegration?.id && slackChannelsQuery.isSuccess && slackChannelOptions && (
            <div className="flex flex-col gap-y-2">
              <label className="text-md">Channel</label>
              <Listbox options={slackChannelOptions} value={selectedChannel} onChange={handleChangeChannel} placeholder="Select channel" />
            </div>
          )}
          {!!slackIntegration?.id && slackChannelsQuery.isSuccess && slackChannelOptions && (
            <div className="flex flex-col gap-y-2">
              <label className="text-md">Message</label>
              {resourceType && (
                <SlackMessageInput
                  onChange={handleChangeMessage}
                  resourceType={resourceType}
                  resourceId={resourceId}
                  initialValue={parseMessageTemplate(block?.send_slack_message?.message_template)}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

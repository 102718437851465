import { useCallback, useMemo } from 'react';
import PlusIcon from '../../../../assets/icons/plus.svg?react';
import { FilterOptionsPopover } from '../../../../shared/filters/components/common/FilterOptionsPopover';
import { FilterCard } from '../../../../shared/filters/components/common/FilterCard';
import { Tooltip } from '../../../../shared/ui/Tooltip/Tooltip';
import { RelationshipEntityType } from '@bigdelta/lib-shared';
import { FilterItemType, FilterKey, getFilter, useFilterStore } from '../../../../shared/filters/store';
import { OnOptionSelectHandler } from '../../../../shared/filters/components/common/FilterOptions';
import { useFilterContext } from '../../../../shared/filters/context/useFilterContext';
import { ObjectsListData } from '@bigdelta/lib-api-client';
import { useWorkspace } from '../../../auth/hooks/useWorkspace';
import { FilterContext } from '../../../../shared/filters/context/context';
interface FunnelStepsProps {
  object: ObjectsListData['objects'][number];
}

export const FunnelSteps = ({ object }: FunnelStepsProps) => {
  const { filterKey: funnelKey } = useFilterContext();
  const filterKey: FilterKey = useMemo(() => [...funnelKey, object.api_slug], [object, funnelKey]);
  const workspaceId = useWorkspace().currentWorkspaceId;
  const { addFilterItem, setItemEvent, setPropertyRelationships } = useFilterStore((state) => state);
  const steps = useFilterStore((state) => getFilter(state, filterKey));

  const handleFilterSelect = useCallback<OnOptionSelectHandler>(
    (option) => {
      if (!option.relationships) {
        return;
      }

      if (option.type === RelationshipEntityType.EVENT) {
        const { index } = addFilterItem(filterKey, FilterItemType.RECORDS_EVENT, {});
        setItemEvent(filterKey, index, option.event.event_name);
        setPropertyRelationships(filterKey, index, option.relationships);
      }
    },
    [addFilterItem, filterKey, setItemEvent, setPropertyRelationships]
  );
  const isFilterEmpty = !steps || steps?.items?.length === 0;

  return (
    <>
      <div className="mb-2 flex flex-row items-center justify-between pt-3">
        <span className="select-none text-md font-medium">Steps</span>
        <FilterOptionsPopover
          objectId={object?.id}
          workspaceId={workspaceId}
          supportedTabs={['events']}
          onOptionSelect={handleFilterSelect}
          defaultOpen={isFilterEmpty}
          trigger={
            <Tooltip content="Add more steps">
              <button className="flex items-center rounded p-1 text-m-olive-400 hover:bg-[#f0f0f0]" data-tooltip-id="add-filter">
                <PlusIcon className="h-4 w-4 " />
              </button>
            </Tooltip>
          }
        />
      </div>
      <FilterContext.Provider
          value={{
          filterKey,
          workspaceId: workspaceId,
          mode: 'inline',
          inlineElements: false,
        }}
      >
      {steps?.items?.map((step, index) => (
        <FilterCard
          disableEventWindow
          key={`${JSON.stringify(step.property)}-${index}`}
          objectId={object.id}
          filterIndex={index}
          filterItem={step}
          className="border-y"
        />
      ))}
      </FilterContext.Provider>
    </>
  );
};


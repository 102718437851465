import { Cell, Row } from '@tanstack/react-table';
import { FC } from 'react';
import { Link, To } from 'react-router-dom';
import { ConditionalWrap } from '../../utils/ConditionalWrap';
import { twMerge } from '../../../utils/twMerge';
import { PropsWithClassName, SVGComponent } from '../../../types';

import { FloatingPortal } from '@floating-ui/react';
import { useShowContentHover } from '../hooks/useShowContentHover';
import IconFromUrl from './IconFromUrl';

interface CellContentDefaultProps extends PropsWithClassName {
  text: string;
  cell?: Cell<any, unknown>;
  link?: (row: Row<any>) => To;
  leadingIcon?: SVGComponent;
  leadingIconUrl?: string;
  trailingIcon?: SVGComponent;
  fullText?: string;
}

export const CellContentDefault: FC<CellContentDefaultProps> = ({
  link,
  cell,
  text,
  leadingIcon,
  leadingIconUrl,
  trailingIcon,
  className,
  fullText,
}) => {
  const LeadingIcon = leadingIcon;
  const TrailingIcon = trailingIcon;

  const { floatingPortalContentProps, referenceProps, isOpen } = useShowContentHover();

  return (
    <>
      <ConditionalWrap
        condition={!!link && !!cell}
        wrap={(children) => (
          <Link to={link ? link(cell!.row) : ''} className="block truncate">
            {children}
          </Link>
        )}
      >
        <div className={twMerge('flex items-center gap-x-2.5 px-3 py-2 text-m-olive-600', className)} {...referenceProps}>
          {!leadingIconUrl && LeadingIcon && <LeadingIcon className="h-4 w-4 shrink-0" />}
          {leadingIconUrl && <IconFromUrl iconUrl={leadingIconUrl} />}
          <span className="shrink overflow-hidden text-ellipsis ">{text}</span>
          {TrailingIcon && <TrailingIcon className="h-4 w-4 shrink-0" />}
        </div>
        {fullText && isOpen && (
          <FloatingPortal>
            <div {...floatingPortalContentProps}>
              <div className="px-3 py-2">
                <span className="overscroll-none whitespace-pre-wrap text-m-olive-600">{fullText}</span>
              </div>
            </div>
          </FloatingPortal>
        )}
      </ConditionalWrap>
    </>
  );
};

import { Dialog } from '@headlessui/react';
import { FC } from 'react';
import { Button } from '../ui/Button/Button.tsx';

interface ActionConfirmationModalProps {
  heading: string;
  message?: string;

  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ActionConfirmationModal: FC<ActionConfirmationModalProps> = ({ open, heading, message, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose} className="relative z-[999999]">
      <div className="fixed inset-0 bg-m-gray-700 opacity-95" aria-hidden="true" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="max-h-1/3 mx-auto flex min-w-96 max-w-xl flex-col items-stretch rounded-xl bg-white p-1">
          <div className="p-6 text-left text-xl font-medium">{heading}</div>
          {message && <div className="px-6 pb-6 text-lg font-regular">{message}</div>}
          <hr className="h-px bg-m-olive-100" />
          <div className="flex w-full justify-end gap-x-2 px-6 py-4">
            <Button size="md" label="Cancel" intent="secondary" onClick={() => onClose()} />
            <Button size="md" label="Confirm" intent="destructive" onClick={() => onConfirm()} />
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportTemplatesQueryResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const reportsQueryResponse_1 = require("./reportsQueryResponse");
const types_1 = require("../types");
const recordsDataQueryResponse_1 = require("./recordsDataQueryResponse");
exports.ReportTemplatesQueryResponseDef = typebox_1.Type.Object({
    [types_1.TemplateType.CUSTOMER_ACTIVATION_NEW_SIGNUPS]: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
        new_relations_overview: typebox_1.Type.Object({
            current_day_count: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
            previous_day_count: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
            current_week_count: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
            previous_week_count: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
            current_month_count: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
            previous_month_count: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
        }),
        daily_new_relations_7_days: (0, typebox_2.OptionalNullable)(reportsQueryResponse_1.ReportSingletQueryResultVODef),
        weekly_new_relations_6_months: (0, typebox_2.OptionalNullable)(reportsQueryResponse_1.ReportSingletQueryResultVODef),
        monthly_new_relations_12_months: (0, typebox_2.OptionalNullable)(reportsQueryResponse_1.ReportSingletQueryResultVODef),
    })),
    [types_1.TemplateType.CUSTOMER_ACTIVATION_ACTIVE_USERS]: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
        active_users_overview: typebox_1.Type.Object({
            current_day_count: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
            previous_day_count: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
            current_week_count: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
            previous_week_count: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
            current_month_count: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
            previous_month_count: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
        }),
        daily_active_relations_7_days: (0, typebox_2.OptionalNullable)(reportsQueryResponse_1.ReportSingletQueryResultVODef),
        weekly_active_relations_6_months: (0, typebox_2.OptionalNullable)(reportsQueryResponse_1.ReportSingletQueryResultVODef),
        monthly_active_relations_12_months: (0, typebox_2.OptionalNullable)(reportsQueryResponse_1.ReportSingletQueryResultVODef),
    })),
    [types_1.TemplateType.CUSTOMER_ACTIVATION_ACTIVATION_FLOW]: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
        activation_funnel: (0, typebox_2.OptionalNullable)(reportsQueryResponse_1.ReportSingletQueryResultVODef),
        funnel_completed_relations: (0, typebox_2.OptionalNullableRef)(typebox_1.Type.Ref(recordsDataQueryResponse_1.RecordsDataQueryResponseDef)),
        funnel_started_relations: (0, typebox_2.OptionalNullableRef)(typebox_1.Type.Ref(recordsDataQueryResponse_1.RecordsDataQueryResponseDef)),
    })),
    [types_1.TemplateType.CUSTOMER_RETENTION_RETENTION]: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
        retention_weekly: (0, typebox_2.OptionalNullable)(reportsQueryResponse_1.ReportSingletQueryResultVODef),
    })),
    [types_1.TemplateType.PRODUCT_USAGE_FEATURE_REPORT]: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
        users_using_feature: (0, typebox_2.OptionalNullableRef)(typebox_1.Type.Ref(recordsDataQueryResponse_1.RecordsDataQueryResponseDef)),
        one_time_feature_users: (0, typebox_2.OptionalNullableRef)(typebox_1.Type.Ref(recordsDataQueryResponse_1.RecordsDataQueryResponseDef)),
        current_day_new_feature_users: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
        previous_day_new_feature_users: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
        current_week_new_feature_users: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
        previous_week_new_feature_users: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
        current_month_new_feature_users: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
        previous_month_new_feature_users: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
        daily_unique_relations_3_months: (0, typebox_2.OptionalNullable)(reportsQueryResponse_1.ReportSingletQueryResultVODef),
        weekly_unique_relations_6_months: (0, typebox_2.OptionalNullable)(reportsQueryResponse_1.ReportSingletQueryResultVODef),
        monthly_unique_relations_12_months: (0, typebox_2.OptionalNullable)(reportsQueryResponse_1.ReportSingletQueryResultVODef),
        retention_last_3_months: (0, typebox_2.OptionalNullable)(reportsQueryResponse_1.ReportSingletQueryResultVODef),
    })),
    [types_1.TemplateType.MAPS_NEW_SIGNUPS]: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
        unique_relations_by_country_7_days: (0, typebox_2.OptionalNullable)(reportsQueryResponse_1.ReportSingletQueryResultVODef),
    })),
    [types_1.TemplateType.MAPS_ACTIVE_CUSTOMERS]: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
        unique_relations_by_country_7_days: (0, typebox_2.OptionalNullable)(reportsQueryResponse_1.ReportSingletQueryResultVODef),
    })),
    [types_1.TemplateType.WEBSITE_WEBSITE]: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
        website_overview: typebox_1.Type.Object({
            visitors_7_days: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
            visitors_previous_7_days: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
            pageviews_7_days: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
            pageviews_previous_7_days: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
            sessions_7_days: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
            sessions_previous_7_days: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
            bounce_rate_7_days: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
            bounce_rate_previous_7_days: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
        }),
        daily_unique_visitors_1_month: (0, typebox_2.OptionalNullable)(reportsQueryResponse_1.ReportSingletQueryResultVODef),
        daily_sessions_1_month: (0, typebox_2.OptionalNullable)(reportsQueryResponse_1.ReportSingletQueryResultVODef),
        pageviews_top_paths_1_month: (0, typebox_2.OptionalNullable)(reportsQueryResponse_1.ReportSingletQueryResultVODef),
        visitors_top_paths_1_month: (0, typebox_2.OptionalNullable)(reportsQueryResponse_1.ReportSingletQueryResultVODef),
        visitors_top_referrers_1_month: (0, typebox_2.OptionalNullable)(reportsQueryResponse_1.ReportSingletQueryResultVODef),
        unique_visitors_by_country_1_month: (0, typebox_2.OptionalNullable)(reportsQueryResponse_1.ReportSingletQueryResultVODef),
        unique_visitors_by_device_type_1_month: (0, typebox_2.OptionalNullable)(reportsQueryResponse_1.ReportSingletQueryResultVODef),
        retention_cohort_1_month: (0, typebox_2.OptionalNullable)(reportsQueryResponse_1.ReportSingletQueryResultVODef),
    })),
    [types_1.TemplateType.RECURRING_REVENUE]: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
        monthly_mrr_12_months: (0, typebox_2.OptionalNullable)(reportsQueryResponse_1.ReportSingletQueryResultVODef),
        monthly_arr_12_months: (0, typebox_2.OptionalNullable)(reportsQueryResponse_1.ReportSingletQueryResultVODef),
    })),
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateIdentifyUserEventVODef = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_1 = require("../model");
exports.CreateIdentifyUserEventVODef = typebox_1.Type.Object({
    accountId: typebox_1.Type.String(),
    workspaceId: typebox_1.Type.String(),
    userId: typebox_1.Type.String(),
    anonymousId: typebox_1.Type.String(),
    createdBy: model_1.AuthorDef,
});

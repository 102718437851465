"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toCreateTrackingKeyVO = exports.CreateTrackingKeyRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.CreateTrackingKeyRequestDef = typebox_1.Type.Object({
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    name: typebox_1.Type.String(),
});
const toCreateTrackingKeyVO = (createTrackingKeyRequest, createdBy) => {
    return {
        workspaceId: createTrackingKeyRequest.workspace_id,
        name: createTrackingKeyRequest.name,
        createdBy: createdBy,
    };
};
exports.toCreateTrackingKeyVO = toCreateTrackingKeyVO;

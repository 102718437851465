import { useDebounce } from '@uidotdev/usehooks';
import { ChangeEvent, ForwardedRef, forwardRef, HTMLProps, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface DebouncedInputProps extends Omit<HTMLProps<HTMLInputElement>, 'onChange'> {
  value?: string;
  debounceDelay?: number;
  onChange: (value: string) => void;
}

export const DebouncedInput = forwardRef(
  ({ value, onChange, className, debounceDelay, ...props }: DebouncedInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const [rawValue, setRawValue] = useState(value);
    const debouncedValue = useDebounce(rawValue, debounceDelay || 700);

    const handleValueChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setRawValue(value);
    };

    useEffect(() => {
      if (debouncedValue !== undefined) {
        onChange(debouncedValue);
      }
    }, [debouncedValue, onChange]);

    return (
      <input
        ref={ref}
        value={rawValue ?? ''}
        onChange={handleValueChange}
        className={twMerge('rounded-lg border border-m-gray-300 px-3 py-1.5 text-md font-medium', className)}
        {...props}
      />
    );
  }
);

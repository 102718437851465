import { Select, SelectItem } from '../common/Select';
import { FC } from 'react';
import { capitalize } from 'lodash';
import { granulatrityOptions, useReportStore } from '../../store/Report';

export const GranularitySelect: FC = () => {
  const timerange = useReportStore((state) => state.display.timerange);
  const granularity = useReportStore((state) => state.display.granularity);
  const setGranularity = useReportStore((state) => state.setGranularity);

  if (!granularity || !timerange) {
    return null;
  }

  return (
    <Select value={granularity} onValueChange={setGranularity} hasArrow size="sm">
      {granulatrityOptions[timerange].map((granularity) => (
        <SelectItem key={granularity} value={granularity}>
          {capitalize(granularity)}
        </SelectItem>
      ))}
    </Select>
  );
};

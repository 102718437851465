import { FC, useEffect } from 'react';
import { useReportStore, useTrendsBuilderStore } from '../../store/Report';
import * as TrendsBuilderBase from '../common/ReportBuilderBase';
import { useWorkspace } from '../../../auth/hooks/useWorkspace';
import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../../../auth/hooks/useQueryKeys';

import CalculatorIcon from '../../../../assets/icons/calculator.svg?react';
import { getTrendsBuilderStateFromSavedReport } from '../../utils/trends/getTrendsBuilderStateFromSavedReport';
import { getRecordFilterItemsFromSegment } from '../../../../shared/utils/getRecordFilterItemsFromSegment';
import { getEventCountPropertiesFilterItems } from '../../utils/trends/transformSavedReportToState/transformSavedReportToState';
import { Filter, useFilterStore } from '../../../../shared/filters/store';
import { WorkspaceObjectBlock } from '../../../../shared/components/WorkspaceObjectBlock';
import { bigdeltaAPIClient } from '../../../../client/bigdeltaAPIClient.ts';

interface TrendsBuilderMetricProps {
  id: string;
}

export const TrendsBuilderMetric: FC<TrendsBuilderMetricProps> = ({ id }) => {
  const queryKeys = useQueryKeys();

  const { currentWorkspaceId } = useWorkspace();

  const removeTrendsBuilder = useReportStore((state) => state.removeTrendsBuilder);

  const name = useTrendsBuilderStore(id, (state) => state.name);
  const label = useTrendsBuilderStore(id, (state) => state.label);
  const setBuilder = useTrendsBuilderStore(id, (state) => state.setBuilder);

  const metricId = useTrendsBuilderStore(id, (state) => state.data.metric?.metricId);

  const setFilterItems = useFilterStore((state) => state.setFilterItems);
  const setOperator = useFilterStore((state) => state.setOperator);

  const metricQuery = useQuery({
    queryKey: queryKeys.single('metric', metricId),
    queryFn: () => bigdeltaAPIClient.v1.metricsDetail(metricId!),
    enabled: !!metricId,
  });

  const relationshipsQuery = useQuery({
    queryKey: queryKeys.list('relationship'),
    queryFn: () => bigdeltaAPIClient.v1.relationshipsList({ workspace_id: currentWorkspaceId }),
  });

  const objectsQuery = useQuery({
    queryKey: queryKeys.list('object'),
    queryFn: () => bigdeltaAPIClient.v1.objectsList({ workspace_id: currentWorkspaceId }),
  });

  useEffect(() => {
    if (metricQuery.data) {
      if (
        !metricQuery.data.query_configuration.metric_query ||
        !relationshipsQuery.data?.relationships ||
        !objectsQuery.data?.objects ||
        !metricQuery.data?.id
      ) {
        // TODO: handle this somewhere else
        return;
      }

      // TODO: Map here is not needed, it's blindly copied from transformSavedReportToState
      const builderFilterMap: Record<string, Filter> = {};

      const builderData = getTrendsBuilderStateFromSavedReport(
        metricQuery.data.query_configuration.metric_query,
        currentWorkspaceId,
        relationshipsQuery.data?.relationships,
        objectsQuery.data?.objects
      );

      const metric = metricQuery.data.query_configuration.metric_query;

      if (metric.records?.filter?.conditions && builderData?.data.object?.workspaceObjectId) {
        const operator = metric.records.filter.operator;

        const filterItems = getRecordFilterItemsFromSegment(
          metric.records?.filter?.conditions,
          builderData.data.object?.workspaceObjectId,
          relationshipsQuery.data.relationships
        );

        builderFilterMap[id] = { operator, items: filterItems };
      }

      if (metric.events?.filter?.conditions && builderData?.data.object?.workspaceObjectId) {
        const recordFilter = metric.events.filter.conditions[1];

        if (!recordFilter || !('related_records' in recordFilter)) {
          return;
        }

        if (!recordFilter.related_records?.filter?.conditions) {
          return;
        }

        const operator = recordFilter.related_records?.filter.operator;

        const filterItems = getRecordFilterItemsFromSegment(
          recordFilter.related_records.filter.conditions,
          builderData.data.object?.workspaceObjectId,
          relationshipsQuery.data.relationships
        );

        const eventCountFilterItems = getEventCountPropertiesFilterItems(metric, relationshipsQuery.data.relationships) ?? [];

        builderFilterMap[id] = { operator, items: [...filterItems, ...eventCountFilterItems] };
      }

      if (!builderData) {
        // TODO: handle this
        return;
      }

      const metricBuilderData = { ...builderData, id, metricId: metricQuery.data.id, label: metricQuery.data.display_name };

      Object.entries(builderFilterMap).forEach(([builderId, { operator, items }]) => {
        // TODO: Combine into one action
        setFilterItems(['reports', builderId], items);
        setOperator(['reports', builderId], operator);
      });

      setBuilder({ ...metricBuilderData, initialMetricBuilderData: metricBuilderData });
    }
  }, [
    currentWorkspaceId,
    id,
    metricQuery.data,
    objectsQuery.data?.objects,
    relationshipsQuery.data?.relationships,
    setBuilder,
    setFilterItems,
    setOperator,
  ]);

  if (!label) {
    return null;
  }

  return (
    <TrendsBuilderBase.Container isLoading={true} storeSelector={useTrendsBuilderStore}>
      <div className="flex flex-col gap-y-5">
        <div className="flex items-center justify-between">
          <TrendsBuilderBase.Heading name={name} label={label} id={id} />
          <TrendsBuilderBase.Remove onClick={() => removeTrendsBuilder(id)} />
        </div>
        <WorkspaceObjectBlock name={label} icon={CalculatorIcon} />
      </div>
    </TrendsBuilderBase.Container>
  );
};

import { RelationshipsListData } from '@bigdelta/lib-api-client';
import { RelationshipEntityType } from '@bigdelta/lib-shared';

interface GetObjectsRelatedToObjectArgs {
  entityId: string;
  relationships: RelationshipsListData['relationships'];
}

export const getObjectRelationships = ({ entityId, relationships }: GetObjectsRelatedToObjectArgs) => {
  const objectRelMap = relationships.reduce(
    (acc, item) => {
      if (item.first_entity_type !== RelationshipEntityType.OBJECT || item.second_entity_type !== RelationshipEntityType.OBJECT) {
        return acc;
      }
      if (
        item.first_entity_type === RelationshipEntityType.OBJECT &&
        item.second_entity_type === RelationshipEntityType.OBJECT &&
        item.first_entity_id === entityId &&
        !!item.second_entity_id
      ) {
        return {
          ...acc,
          [item.second_entity_id]: item,
        };
      }
      if (
        item.first_entity_type === RelationshipEntityType.OBJECT &&
        item.second_entity_type === RelationshipEntityType.OBJECT &&
        item.second_entity_id === entityId &&
        !!item.first_entity_id
      ) {
        return {
          ...acc,
          [item.first_entity_id]: item,
        };
      }
      return acc;
    },
    {} as { [key: string]: RelationshipsListData['relationships'][number] }
  );

  return Object.values(objectRelMap);
};

import { Navigate, createSearchParams } from 'react-router-dom';
import { useObjectsQuery } from '../../../shared/data/useObjectsQuery';
import { useWorkspace } from '../../auth/hooks/useWorkspace';

export const RecordsIndex = () => {
  const { currentWorkspaceId } = useWorkspace();

  const { data: objectList } = useObjectsQuery({ workspaceId: currentWorkspaceId });

  if (objectList?.objects) {
    const firstObject = objectList.objects[0];

    return (
      <Navigate
        to={{
          pathname: `/records/${firstObject?.api_slug}`,
          search: createSearchParams({ workspaceId: firstObject?.workspace_id }).toString(),
        }}
      />
    );
  }
};

import { QueryEventsCreatePayload } from '@bigdelta/lib-api-client';
import { FilterItem, FilterItemType } from '../../../shared/filters/store';
import { DateRangeType } from '@bigdelta/lib-shared';

// TODO: Validations
export const getEventsTime = (filterItems: FilterItem[] | null): QueryEventsCreatePayload['time'] => {
  const timeframeFilterItem = filterItems?.find((item) => item.itemType === FilterItemType.EVENTS_TIMEFRAME);
  const { timeframe } = timeframeFilterItem ?? {};

  if (timeframe?.dateRangeType) {
    if (timeframe.dateRangeType === DateRangeType.ABSOLUTE && timeframe.startAt && timeframe.endAt) {
      return {
        date_range_type: timeframe.dateRangeType,
        start_at: new Date(timeframe.startAt).toISOString(),
        end_at: new Date(timeframe.endAt).toISOString(),
      };
    }

    if (timeframe.dateRangeType === DateRangeType.BEFORE && timeframe.endAt) {
      return {
        date_range_type: timeframe.dateRangeType,
        end_at: new Date(timeframe.endAt).toISOString(),
      };
    }

    if (timeframe.dateRangeType === DateRangeType.AFTER && timeframe.startAt) {
      return {
        date_range_type: timeframe.dateRangeType,
        start_at: new Date(timeframe.startAt).toISOString(),
      };
    }

    if (timeframe.dateRangeType === DateRangeType.IN_THE_LAST && timeframe.window?.unit && timeframe.window?.value) {
      return {
        date_range_type: timeframe.dateRangeType,
        window: {
          unit: timeframe.window.unit,
          value: timeframe.window.value,
        },
      };
    }
  }
  return {
    date_range_type: DateRangeType.OVER_ALL_TIME,
  };
};

import React, { FC, HTMLAttributes, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { Select } from '../../../../shared/ui/Select/Select.tsx';
import { FormElementWrapper } from '../../../../shared/ui/FormElementWrapper/FormElementWrapper.tsx';
import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../../../auth/hooks/useQueryKeys.ts';
import { useWorkspace } from '../../../auth/hooks/useWorkspace.tsx';
import { CenteredLoader } from '../../../../shared/components/CenteredLoader.tsx';
import { RelationshipFormSchema } from './RelationshipFormSchema.ts';
import { capitalize } from 'lodash';
import { Button } from '../../../../shared/ui/Button/Button.tsx';
import { ObjectRelationship } from './RelationshipCell.tsx';
import { bigdeltaAPIClient } from '../../../../client/bigdeltaAPIClient.ts';

interface Props extends HTMLAttributes<HTMLElement> {
  objectId: string;
  existingRelationships?: ObjectRelationship[];
  initialValues?: RelationshipFormSchema;
  onCancel: () => void;
  renderSubmitButton?: (isValid: boolean, handleSubmit: (handler: (data: RelationshipFormSchema) => any) => any) => React.ReactNode;
}

interface EntityProperty {
  id: string;
  displayName: string;
}

export const RelationshipForm: FC<Props> = ({ className, objectId, existingRelationships, initialValues, onCancel, renderSubmitButton }) => {
  const {
    register,
    formState: { errors, isValid },
    reset,
    handleSubmit,
    watch,
    setValue,
  } = useForm<RelationshipFormSchema>({
    defaultValues: initialValues,
  });

  const queryKeys = useQueryKeys();
  const { currentWorkspaceId } = useWorkspace();

  const objectsQuery = useQuery({
    queryKey: queryKeys.list('object'),
    queryFn: () => bigdeltaAPIClient.v1.objectsList({ workspace_id: currentWorkspaceId }),
  });

  const eligibleObjects = useMemo(() => {
    return objectsQuery.data?.objects.filter((o) => o.id !== objectId);
  }, [objectId, objectsQuery.data?.objects]);

  useEffect(() => {
    if (!initialValues && eligibleObjects.length > 0) {
      reset({ entity_id: eligibleObjects[0].id });
    } else {
      reset(initialValues);
    }
  }, [eligibleObjects, initialValues, reset]);

  const selectedEntityId = watch('entity_id');

  const objectPropertiesWithoutRelationships: EntityProperty[] = useMemo(() => {
    const selectedObject = eligibleObjects?.find((o) => o.id === selectedEntityId);
    const existingRelationshipsForSelectedObject = existingRelationships
      ?.filter((r) => r.related_entity_id === selectedObject?.id)
      .map((r) => r.related_entity_property);

    return [
      ...(selectedObject ? [{ id: '$met_remote_id', displayName: 'id' }] : []),
      ...(selectedObject?.properties
        ?.filter((i) => i.type === 'string' || i.type === 'number' || i.type === 'string[]' || i.type === 'number[]')
        .map((i) => {
          return { id: i.name, displayName: i.name };
        }) || []),
    ].filter((i) => !existingRelationshipsForSelectedObject?.includes(i.id));
  }, [selectedEntityId, eligibleObjects, existingRelationships]);

  useEffect(() => {
    if (initialValues?.entity_property) {
      setValue('entity_property', initialValues.entity_property);
    } else if (objectPropertiesWithoutRelationships?.length > 0) {
      setValue('entity_property', objectPropertiesWithoutRelationships[0].id);
    } else {
      setValue('entity_property', '');
    }
  }, [initialValues, objectPropertiesWithoutRelationships, setValue]);

  return (
    <>
      {!objectsQuery.isInitialLoading && (
        <div className={twMerge('relative flex flex-col gap-4 px-6 pb-6', className)}>
          <FormElementWrapper label="Entity" errorMessage={errors.entity_id?.message} size="sm">
            <Select {...register('entity_id', { required: true, disabled: !!initialValues?.entity_id })} className="text-xs">
              {eligibleObjects?.map((object) => (
                <option key={object.id} value={object.id}>
                  {capitalize(object.singular_noun)}
                </option>
              ))}
            </Select>
          </FormElementWrapper>
          <FormElementWrapper label="Property" errorMessage={errors.entity_property?.message} size="sm">
            <Select
              {...register('entity_property', {
                required: true,
                disabled: !!initialValues?.entity_property || objectPropertiesWithoutRelationships?.length === 0,
              })}
              className="text-xs"
            >
              {objectPropertiesWithoutRelationships?.map((property) => (
                <option key={property.id} value={property.id}>
                  {property.displayName}
                </option>
              ))}
            </Select>
          </FormElementWrapper>
          <div className="flex justify-end gap-x-2">
            <Button size="sm" intent="tertiary" onClick={onCancel} label="Cancel" />
            {renderSubmitButton &&
              renderSubmitButton(
                isValid && objectPropertiesWithoutRelationships?.length > 0 && !errors.entity_id?.message && !errors.entity_property?.message,
                (handler) => handleSubmit(handler)
              )}
          </div>
        </div>
      )}
      {objectsQuery.isInitialLoading && <CenteredLoader />}
    </>
  );
};

import { FC } from 'react';
import { useFilterContext } from '../../context/useFilterContext';
import { FilterItem, useFilterStore } from '../../store';
import { RowContainer } from '../common/RowContainer';
import { EventNameOperatorSelect } from '../common/EventNameOperatorSelect';
import { EventNameValueSelectSingle } from '../common/EventNameValueSelectSingle';
import { ValueSelectType } from '../../const';
import { EventNameValueSelectMultiple } from '../common/EventNameValueSelectMultiple';
import { Control } from '../common/Control';
import { Group } from '../common/Group';

interface EventsNameFilterRowProps {
  index: number;
  filter: FilterItem;
}

export const EventsNameFilterRow: FC<EventsNameFilterRowProps> = ({ index, filter }) => {
  const { filterKey } = useFilterContext();

  const removeItem = useFilterStore((state) => state.removeItem);

  return (
    <RowContainer onRemove={() => removeItem(filterKey, index)}>
      <Group>
        {/* <Control shouldShrink={true}> */}
        <span className="text-md">Event name</span>
        {/* </Control> */}
        <Control>
          <EventNameOperatorSelect index={index} filterItem={filter} />
        </Control>
      </Group>
      <Group>
        <Control>
          {filter.data.valueType === ValueSelectType.COMBOBOX_MULTIPLE && <EventNameValueSelectMultiple index={index} filterDataItem={filter} />}
          {filter.data.valueType === ValueSelectType.COMBOBOX_SINGLE && <EventNameValueSelectSingle index={index} filterDataItem={filter} />}
        </Control>
      </Group>
    </RowContainer>
  );
};

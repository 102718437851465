import { forwardRef } from 'react';
import { Input } from '../../../../shared/ui/Input/Input';
import { Label } from './Label';

interface InputWrapperProps extends React.ComponentPropsWithoutRef<typeof Input> {
  label: string;
}

export const InputWrapper = forwardRef<HTMLInputElement, InputWrapperProps>(({ label, ...props }, ref) => (
  <div className="flex flex-col gap-2">
    <Label>{label}</Label>
    <Input size="md" {...props} ref={ref} />
  </div>
));

InputWrapper.displayName = 'InputWrapper';

import { AppRoutes } from '../../../routes';
import { Button } from '../../../shared/ui/Button/Button';
import { AuthScreenTitle } from './AuthScreenTitle';
import { Link as RouterLink } from 'react-router-dom';

export const ResetPasswordSuccess = () => (
  <>
    <AuthScreenTitle title="Password Reset Successful" subtitle="Your password has been reset. You can now log in with your new credentials." />
    <RouterLink to={AppRoutes.LOGIN}>
      <Button label="Login" className="w-full" size="lg" type="submit" />
    </RouterLink>
  </>
);

import { createSearchParams } from 'react-router-dom';

import HomeIcon from '../../../assets/icons/home-line.svg?react';
import AutomationsIcon from '../../../assets/icons/dataflow-04.svg?react';
// import MarketingIcon from '../../../assets/icons/flag-04.svg?react';
// import SalesIcon from '../../../assets/icons/briefcase-01.svg?react';
// import ProductIcon from '../../../assets/icons/cursor-box.svg?react';
// import RevenueIcon from '../../../assets/icons/bank-note-02.svg?react';
import DataIcon from '../../../assets/icons/data.svg?react';

import { WorkspaceObjectStatus, WorkspaceObjectType } from '@bigdelta/lib-shared';
import { ProfileMenu } from './ProfileMenu';
import { AppRoutes } from '../../../routes';
import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';

import { useWorkspace } from '../../auth/hooks/useWorkspace';

import { NavItem } from './NavItem';
import { Favorites } from './Favorites';
import { useDrawerStore } from '../store';
import { twMerge } from 'tailwind-merge';
import { NavItemGroup } from './NavItemGroup';
import { SVGComponent } from '../../../types';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

interface NavigationObjectItem {
  label: string;
  workspaceObjectType?: WorkspaceObjectType | null;
  to?: string;
  children?: NavigationItem[];
}

interface NavigationItem {
  label: string;
  icon?: SVGComponent;
  to?: string;
  children?: NavigationObjectItem[];
}

const MainNavigation: NavigationItem[] = [
  { label: 'Home', icon: HomeIcon, to: AppRoutes.ROOT },
  { label: 'Automations', icon: AutomationsIcon, to: AppRoutes.AUTOMATIONS },
  { label: 'Data', icon: DataIcon, to: AppRoutes.DATA },
];

// const ContextNavigation = [
//   {
//     label: 'Marketing',
//     icon: MarketingIcon,
//     children: [
//       { label: 'Overview', to: '/' },
//       { label: 'Reports', to: `${AppRoutes.REPORTS}?context=${Context.MARKETING}` },
//       { label: 'Touchpoints', workspaceObjectType: null },
//       { label: 'Attributions', workspaceObjectType: null },
//     ],
//   },
//   {
//     label: 'Sales',
//     icon: SalesIcon,
//     children: [
//       { label: 'Overview', to: '/' },
//       { label: 'Reports', to: `${AppRoutes.REPORTS}?context=${Context.SALES}` },
//       { label: 'Deals', workspaceObjectType: WorkspaceObjectType.DEAL },
//       { label: 'Notes', workspaceObjectType: null },
//       { label: 'Tasks', workspaceObjectType: null },
//     ],
//   },
//   {
//     label: 'Product',
//     icon: ProductIcon,
//     children: [
//       { label: 'Overview', to: '/' },
//       { label: 'Reports', to: `${AppRoutes.REPORTS}?context=${Context.PRODUCT}` },
//       { label: 'Users', workspaceObjectType: WorkspaceObjectType.USER },
//       { label: 'Accounts', workspaceObjectType: WorkspaceObjectType.ACCOUNT },
//       { label: 'Features', workspaceObjectType: null },
//       { label: 'Events', to: AppRoutes.EVENTS },
//     ],
//   },
//   {
//     label: 'Revenue',
//     icon: RevenueIcon,
//     children: [
//       { label: 'Overview', to: '/.' },
//       { label: 'Reports', to: `${AppRoutes.REPORTS}?context=${Context.REVENUE}` },
//       { label: 'Metric tree', to: AppRoutes.TREE },
//       { label: 'Metrics', to: AppRoutes.METRICS },
//     ],
//   },
// ];

export const Navigation = () => {
  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();
  const { isOpen } = useDrawerStore();

  const objectsQuery = useQuery({
    queryKey: queryKeys.list('object', WorkspaceObjectStatus.ACTIVE),
    queryFn: () => bigdeltaAPIClient.v1.objectsList({ workspace_id: currentWorkspaceId, status: WorkspaceObjectStatus.ACTIVE }),
  });

  const getNavigationItems = (navItems: NavigationItem[]) => {
    if (!objectsQuery.data) {
      return null;
    }

    return navItems.map((item) => {
      const children = item.children?.map((childNavItem) => {
        if (childNavItem.to) {
          return <NavItem key={item.label} to={childNavItem.to} label={childNavItem.label} level={1} />;
        }

        if (childNavItem.workspaceObjectType === null) {
          return null;
        }

        if (childNavItem.workspaceObjectType) {
          const object = objectsQuery.data.objects.find((object) => object.object_type === childNavItem.workspaceObjectType);

          if (!object) {
            return null;
          }

          const sameWorkspace = object.workspace_id === currentWorkspaceId;
          const to = `/records/${object.api_slug}${sameWorkspace ? '' : `?${createSearchParams({ workspaceId: object.workspace_id }).toString()}`}`;

          return <NavItem key={item.label} label={childNavItem.label} to={to} level={1} />;
        }
      });

      return (
        <NavItem key={item.label} label={item.label} icon={item.icon} to={item.to ?? '#'}>
          {children}
        </NavItem>
      );
    });
  };

  const mainNavigation = getNavigationItems(MainNavigation);
  // const contextNavigation = getNavigationItems(ContextNavigation);

  return (
    <div
      className={twMerge(
        'fixed z-50 flex h-screen w-64 flex-col gap-y-4 bg-m-coconut-600 px-5 py-4 pt-5 transition-all',
        isOpen ? 'w-64' : 'w-0 overflow-hidden px-0'
      )}
    >
      <ProfileMenu />
      <div className="h-full overflow-y-auto">
        <NavItemGroup>{mainNavigation}</NavItemGroup>
        {
          <NavItemGroup heading="Shortcuts">
            <Favorites />
          </NavItemGroup>
        }
        {/*DO NOT REMOVE. Temporarily hiding part of functionality. */}
        {/*<NavItemGroup heading="Context">{contextNavigation}</NavItemGroup>*/}
      </div>
    </div>
  );
};

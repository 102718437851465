import { useOnboardingFormCtx } from '../hooks/useFormContext';
import { InputWrapper } from './InputWrapper';
import { StepDescription } from './StepDescription';
import { StepHeading } from './StepHeading';
import { ValidationError } from './ValidationError';

export const Step1CreateWorkspace: React.FC = () => {
  const {
    register,
    formState: { errors },
    getValues,
  } = useOnboardingFormCtx();

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <StepHeading>Create your workspace</StepHeading>
        {getValues('name') !== '' && <StepDescription>Company name will be used as a name for your first workspace</StepDescription>}
      </div>
      <div>
        <InputWrapper
          label="Workspace Name"
          {...register('name', { required: 'Workspace name is required' })}
          placeholder="Enter your workspace name"
        />
        <ValidationError error={errors.name?.message?.toString()} />
      </div>
    </div>
  );
};

import { StateCreator, StoreMutatorIdentifier } from 'zustand';
import { toast } from 'react-hot-toast';
import { ToastContent } from '../../components/ToastContent';

import AlertIcon from '../../assets/icons/alert-circle.svg?react';

type ErrorHandler = <T, Mps extends [StoreMutatorIdentifier, unknown][] = [], Mcs extends [StoreMutatorIdentifier, unknown][] = []>(
  f: StateCreator<T, Mps, Mcs>,
  name?: string
) => StateCreator<T, Mps, Mcs>;

type ErrorHandlerImpl = <T>(f: StateCreator<T, [], []>, name?: string) => StateCreator<T, [], []>;

const errorHandlerImpl: ErrorHandlerImpl = (f, _) => (set, get, store) => {
  // type T = ReturnType<typeof f>;
  const loggedSet: typeof set = (...a) => {
    try {
      set(...a);
    } catch (e: any) {
      console.log('eee', e);
      toast(<ToastContent message={e?.message} />, { icon: <AlertIcon className="h-5 w-5 text-m-red-600" /> });
    }
  };
  store.setState = loggedSet;

  return f(loggedSet, get, store);
};

export const errorHandler = errorHandlerImpl as unknown as ErrorHandler;

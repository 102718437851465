import { FC, useEffect, useRef } from 'react';
import { DebouncedInput } from './DebouncedInput';

interface PropertyValueTextFieldProps {
  assignedOperator: string;
  onChange: (value: string) => void;
  value: string;
}

export const PropertyValueTextField: FC<PropertyValueTextFieldProps> = ({ value, assignedOperator, onChange }) => {
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    setTimeout(() => {
      ref.current?.focus();
    }, 0);
  }, [assignedOperator]);
  return (
    <DebouncedInput
      ref={ref}
      value={value}
      onChange={onChange}
      className="w-full rounded border-0 bg-transparent text-[12px] text-sm font-regular text-m-olive-400 ring-transparent focus:border-0 focus:text-m-olive-600 focus:ring-transparent group-hover:bg-[#f0f0f0]"
    />
  );
};

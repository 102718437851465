import { FC, useCallback } from 'react';

interface OpenTriggerProps {
  shouldOpen: boolean;
}

export const OpenTrigger: FC<OpenTriggerProps> = ({ shouldOpen }) => {
  const refCallback = useCallback(
    (node: HTMLDivElement | null) => {
      if (shouldOpen && node) {
        setTimeout(() => {
          node.click();
        }, 0);
      }
    },
    [shouldOpen]
  );

  return <div ref={refCallback} className="hidden" />;
};

import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { ToolsTable } from '../../../shared/components/ToolsTable';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../routes';
import { AutomationsListData } from '@bigdelta/lib-api-client';
import { BadgeLive } from './BadgeLive';
import { BadgePaused } from './BadgePaused';

const columnHelper = createColumnHelper<AutomationsListData['items'][number]>();

interface AutomationsTableProps {
  data: AutomationsListData;
}

export const AutomationsTable: FC<AutomationsTableProps> = ({ data }) => {
  const navigate = useNavigate();

  const handleRowClick = (data: AutomationsListData['items'][number]) => {
    navigate(AppRoutes.AUTOMATIONS_VIEW(data.id));
  };

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        header: 'Name',
      }),
      columnHelper.accessor('enabled', {
        header: 'Status',
        cell: (props) => {
          const value = props.getValue();

          return value ? <BadgeLive /> : <BadgePaused />;
        },
      }),
    ];
  }, []);

  const table = useReactTable({
    data: data.items,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return <ToolsTable data={data} table={table} onRowClick={handleRowClick} />;
};

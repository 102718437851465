import {
  Children,
  Dispatch,
  FC,
  KeyboardEventHandler,
  PropsWithChildren,
  SetStateAction,
  cloneElement,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';

interface MenuContextState {
  value: string | null;
  setValue: (value: string | null) => void;
  activeIndex: number;
  setActiveIndex: Dispatch<SetStateAction<number>>;
}

const MenuContext = createContext<MenuContextState>({ value: null, setValue: () => {}, activeIndex: 0, setActiveIndex: () => {} });

const useMenu = () => {
  return useContext(MenuContext);
};

export const Menu: FC<PropsWithChildren & { onChange: (val: string | null) => void }> = ({ children, onChange }) => {
  const [value, setValue] = useState<string | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const updateMenuValue = (v: string | null) => {
    setValue(v);

    if (Array.isArray(children) && !children.find((child) => child.props.value === v)) {
      onChange(v);
    }
  };

  return <MenuContext.Provider value={{ value, setValue: updateMenuValue, activeIndex, setActiveIndex }}>{children}</MenuContext.Provider>;
};

interface MenuGroupProps extends PropsWithChildren {
  value?: string | null;
}

export const MenuGroup: FC<MenuGroupProps> = ({ children, value = null }) => {
  const { value: menuValue, setActiveIndex, activeIndex, setValue } = useMenu();

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    switch (e.code) {
      case 'ArrowDown':
        e.preventDefault();
        setActiveIndex((prev) => (activeIndex < Children.count(children) - 1 ? prev + 1 : prev));
        break;
      case 'ArrowUp':
        e.preventDefault();
        setActiveIndex((prev) => (activeIndex > 0 ? prev - 1 : 0));
        break;
      case 'Enter':
      case 'Space':
        e.preventDefault();
        if (Array.isArray(children) && children[activeIndex]) {
          setValue(children[activeIndex].props.value);
          setActiveIndex(0);
        }
    }
  };

  const childrenWithActive = useMemo(() => {
    if (!Array.isArray(children)) return children;

    return children.map((child, idx) => (idx === activeIndex ? cloneElement(child, { isActive: true }) : cloneElement(child, { isActive: false })));
  }, [activeIndex, children]);

  const refCallback = useCallback((node) => {
    if (node) {
      node.focus();
    }
  }, []);

  if (value !== menuValue) return null;

  return (
    <div tabIndex={0} ref={refCallback} onKeyDown={handleKeyDown} className="flex flex-col gap-y-px outline-none">
      {childrenWithActive}
    </div>
  );
};

interface MenuItemProps extends PropsWithChildren {
  value: string | null;
  isActive?: boolean;
}

// TODO: Remove styles, make generic
export const MenuItem: FC<MenuItemProps> = ({ children, value, isActive = false, ...props }) => {
  const { setValue } = useMenu();

  return (
    <button
      className={twMerge(
        'flex w-full items-center gap-x-2 rounded-md px-2.5 py-3 text-sm hover:bg-m-gray-200 focus:bg-m-gray-200 focus:outline-none',
        isActive && 'bg-m-gray-200'
      )}
      onClick={() => setValue(value)}
      tabIndex={-1}
      {...props}
    >
      {children}
    </button>
  );
};

import { Select, SelectItem, SelectProps, SelectItemProps } from './common/Select';
import { useReportStore } from '../store/Report';

import Calculator from '../../../assets/icons/calculator.svg?react';


type SelectItem = Omit<SelectItemProps, 'children'> & {
  label: string;
}

type FunnelBreakdownProps = SelectProps;

// Dummy component for future breakdown implementation
export const FunnelBreakdown = ({ ...selectProps }: FunnelBreakdownProps) => {
  // const breakdown = useReportStore((state) => state.breakdown);
  const setBreakdown = useReportStore((state) => () => {
    console.log('fake breakdown set', state.breakdown);
  });

  return (
    <div className="flex flex-col gap-y-2 py-4">
      <div className="text-md font-medium">Breakdown</div>
      <Select
        value={"Breakdown"}
        onValueChange={setBreakdown}
        hasArrow
        {...selectProps}
      >
          <SelectItem key="Breakdown" value="Breakdown" icon={Calculator}>
            Breakdown
          </SelectItem>
      </Select>
    </div>
  );
};

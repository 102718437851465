"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OpportunityDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const model_1 = require("../model");
exports.OpportunityDef = typebox_1.Type.Composite([
    model_1.TimestampedModelDef,
    typebox_1.Type.Object({
        id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        accountId: typebox_1.Type.String({ format: 'uuid' }),
        workspaceId: typebox_1.Type.String({ format: 'uuid' }),
        title: typebox_1.Type.Optional(typebox_1.Type.String()),
        description: typebox_1.Type.Optional(typebox_1.Type.String()),
        details: typebox_1.Type.Optional(typebox_1.Type.String()),
        owner: typebox_1.Type.Optional(model_1.MemberDef),
        createdBy: typebox_1.Type.Optional(model_1.MemberDef),
        updatedBy: typebox_1.Type.Optional(model_1.MemberDef),
        documentGroups: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(model_1.DocumentGroupDef)),
        opportunityMetric: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
        opportunityAmbition: typebox_1.Type.Optional(typebox_1.Type.String()),
        impactMetric: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
        impactSize: typebox_1.Type.Optional(typebox_1.Type.String()),
    }),
]);

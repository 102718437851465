"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toMetricResponse = exports.MetricResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const metricVO_1 = require("../value-object/metricVO");
const types_1 = require("../types");
const memberResponse_1 = require("./memberResponse");
exports.MetricResponseDef = typebox_1.Type.Object({
    id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    key: typebox_1.Type.String(),
    display_name: typebox_1.Type.String(),
    account_id: typebox_1.Type.String({ format: 'uuid' }),
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    description: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    source: (0, typebox_2.OptionalNullable)(typebox_1.Type.Enum(types_1.MetricSource)),
    owner: (0, typebox_2.OptionalNullable)(memberResponse_1.MemberResponseDef),
    created_by: (0, typebox_2.OptionalNullable)(memberResponse_1.MemberResponseDef),
    updated_by: (0, typebox_2.OptionalNullable)(memberResponse_1.MemberResponseDef),
    created_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    updated_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    query_configuration: typebox_1.Type.Unsafe(metricVO_1.MetricQueryConfigurationVODef),
    system_managed: typebox_1.Type.Boolean(),
});
const toMetricResponse = (metric) => {
    return {
        id: metric.id,
        key: metric.key,
        account_id: metric.accountId,
        created_at: metric.systemCreatedAt,
        created_by: (0, memberResponse_1.toMemberResponse)(metric.createdBy),
        display_name: metric.displayName,
        description: metric.description,
        source: metric.source,
        owner: (0, memberResponse_1.toMemberResponse)(metric.owner),
        updated_at: metric.systemUpdatedAt,
        updated_by: (0, memberResponse_1.toMemberResponse)(metric.updatedBy),
        workspace_id: metric.workspaceId,
        query_configuration: metric.queryConfiguration,
        system_managed: metric.systemManaged,
    };
};
exports.toMetricResponse = toMetricResponse;

import { AxiosError } from 'axios';
import { UseFormSetError } from 'react-hook-form';

export const handleFormErrorResponse = (e: AxiosError<any>, setGlobalError?: (err: string) => void, setFieldError?: UseFormSetError<any>) => {
  if (setGlobalError) {
    setGlobalError(e?.response?.data?.reason || e?.response?.data?.message || e?.response?.data?.errorType);
  }

  if (setFieldError) {
    const fieldErrors = e?.response?.data?.errors || [];
    for (const fieldError of fieldErrors) {
      setFieldError(fieldError.dataPath, { type: 'custom', message: fieldError.message });
    }
  }

  console.error(e);
};

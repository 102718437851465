import { FC } from 'react';

interface IntegrationSetupHeadingProps {
  heading?: string;
  subheading?: string;
}

export const IntegrationSetupHeading: FC<IntegrationSetupHeadingProps> = ({ heading, subheading }) => (
  <div className="flex flex-col items-center gap-y-4">
    {heading && <div className="text-center text-display-xs font-medium">{heading}</div>}
    {subheading && <div className="text-center text-lg">{subheading}</div>}
  </div>
);

import React, { FC, FormEvent, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Button } from '../../../shared/ui/Button/Button.tsx';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toastError } from '../../../utils/toast.tsx';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { useAccount } from '../../auth/hooks/useAccount.tsx';
import CheckCircleIcon from '../../../assets/icons/check-circle.svg?react';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

interface CreateApiKeyDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateApiKeyDialog: FC<CreateApiKeyDialogProps> = ({ isOpen, onClose }) => {
  const queryKeys = useQueryKeys();
  const queryClient = useQueryClient();
  const { currentAccountId } = useAccount();

  const handleApiKeyDialogClose = () => {
    setApiKeyName('');
    onClose();
  };

  const createApiKeyMutation = useMutation({
    mutationFn: (name: string) => {
      return bigdeltaAPIClient.v1.authApiKeyCreate({
        account_id: currentAccountId,
        name: name,
      });
    },
    onSuccess: (data) => {
      handleApiKeyDialogClose();
      setApiSecretKeyPreviewValue(data.secret);
      setApiKeyPreviewDialogOpen(true);
      return queryClient.invalidateQueries(queryKeys.apiKeys());
    },
    onError: () => {
      toastError('An error occurred while creating API Key');
    },
  });

  const [apiKeyName, setApiKeyName] = useState('');

  const handleSubmitApiKey = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (apiKeyName) {
      createApiKeyMutation.mutate(apiKeyName);
    }
  };

  const [isApiKeyPreviewDialogOpen, setApiKeyPreviewDialogOpen] = useState(false);
  const [apiSecretKeyPreviewValue, setApiSecretKeyPreviewValue] = useState('');
  const [apiSecretKeyCopied, setApiSecretKeyCopied] = useState(false);

  const handleApiKeyPreviewDialogClose = () => {
    setApiKeyPreviewDialogOpen(false);
    setApiSecretKeyPreviewValue('');
    setApiSecretKeyCopied(false);
  };

  const handleCopyApiSecretKey = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    navigator.clipboard.writeText(apiSecretKeyPreviewValue);
    setApiSecretKeyCopied(true);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleApiKeyDialogClose} className="relative z-50">
        <div className="fixed inset-0 bg-m-gray-700 opacity-95" aria-hidden="true" />
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <Dialog.Panel className="mx-auto flex w-1/3 max-w-xl flex-col gap-y-6 rounded-xl bg-white p-6">
            <Dialog.Title className="text-xl text-m-olive-700">Create API Key</Dialog.Title>
            <form onSubmit={handleSubmitApiKey} className="flex flex-col gap-y-6">
              <div className="flex flex-col gap-y-1.5">
                <label htmlFor="api_key_name" className="text-md text-m-olive-700">
                  Name
                </label>
                <input
                  name="api_key_name"
                  className="rounded-lg border border-m-gray-300 px-3 py-2"
                  value={apiKeyName}
                  placeholder="My API Key name"
                  onChange={(e) => setApiKeyName(e.target.value)}
                />
              </div>
              <div className="flex justify-end gap-x-3.5">
                <Button type="button" label="Cancel" intent="secondary" size="lg" onClick={handleApiKeyDialogClose} />
                <Button label="Create API Key" intent="brand" size="lg" type="submit" disabled={!apiKeyName} />
              </div>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
      <Dialog open={isApiKeyPreviewDialogOpen} onClose={handleApiKeyPreviewDialogClose} className="relative z-50">
        <div className="fixed inset-0 bg-m-gray-700 opacity-95" aria-hidden="true" />
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <Dialog.Panel className="mx-auto flex w-1/3 max-w-xl flex-col gap-y-6 rounded-xl bg-white p-6">
            <Dialog.Title className="text-xl text-m-olive-700">Save your secret key</Dialog.Title>
            <form className="flex flex-col gap-y-6">
              <div className="flex flex-col gap-y-1.5">
                <p className="text-lg text-m-olive-700">
                  Please save this secret key somewhere safe and accessible. You won't be able to view it on Bigdelta again.
                </p>
                <label htmlFor="api_key_name" className="mt-5 text-md text-m-olive-700">
                  API Secret Key
                </label>
                <div className="flex items-center gap-x-2">
                  <input
                    name="api_secret_key_preview"
                    className="w-full rounded-lg border border-m-gray-300 px-3 py-2"
                    value={apiSecretKeyPreviewValue}
                    disabled={true}
                  />
                  <Button
                    trailingIcon={apiSecretKeyCopied ? CheckCircleIcon : undefined}
                    label={apiSecretKeyCopied ? 'Copied' : 'Copy'}
                    intent="brand"
                    size="lg"
                    onClick={handleCopyApiSecretKey}
                  />
                </div>
              </div>
              <div className="flex justify-end gap-x-3.5">
                <Button label="Done" intent="secondary" size="lg" onClick={handleApiKeyPreviewDialogClose} />
              </div>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

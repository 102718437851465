import { autoUpdate, offset, size, useFloating, useClick, useInteractions, flip, shift } from '@floating-ui/react';
import { useMemo, useState } from 'react';

interface UseShowContentClickOptions {
  hideOnMouseLeave?: boolean;
};

export const useShowContentClick = ({
  hideOnMouseLeave = true,
}: UseShowContentClickOptions = {}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: (open) => {
      if (open) {
        setIsOpen(true);
        document.body.classList.add('overscroll-none');
      } else {
        setIsOpen(false);
        document.body.classList.remove('overscroll-none');
      }
    },
    middleware: [
      offset({
        mainAxis: -37,
      }),
      flip(),
      shift(),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            minWidth: `${rects.reference.width}px`,
            maxWidth: '500px',
          });
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  });
  const click = useClick(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([click]);

  const floatingPortalContentProps = useMemo(
    () => ({
      ref: refs.setFloating,
      onMouseLeave: hideOnMouseLeave ?
      () => {
        setIsOpen(false);
      } : null,
      style: floatingStyles,
      ...getFloatingProps(),
      className: 'z-50 bg-m-white text-sm shadow-lg',
    }),
    [floatingStyles, hideOnMouseLeave, getFloatingProps, refs.setFloating]
  );

  const referenceProps = useMemo(
    () => ({
      ref: refs.setReference,
      ...getReferenceProps(),
    }),
    [getReferenceProps, refs.setReference]
  );

  return useMemo(
    () => ({
      floatingPortalContentProps,
      referenceProps,
      isOpen,
    }),
    [floatingPortalContentProps, isOpen, referenceProps]
  );
};

import { FC } from 'react';
import { useFilterContext } from '../../context/useFilterContext';
import { useFilterStore } from '../../store';
import { format } from 'date-fns';

interface PropertyValueDateRangeProps {
  index: number;
}

export const PropertyValueDateRange: FC<PropertyValueDateRangeProps> = ({ index }) => {
  const { filterKey } = useFilterContext();
  const filterItem = useFilterStore((state) => state.getFilterItem([...filterKey, index]));

  const setItemDataValue = useFilterStore((state) => state.setItemDataValue);

  const handleChange = (value: string, dateIndex: number) => {
    const newDateRange = [...(filterItem?.data.value as string[])];
    newDateRange[dateIndex] = new Date(value).toISOString();
    setItemDataValue(filterKey, index, newDateRange);
  };

  if (!filterItem?.data.value?.length) {
    return null;
  }

  return (
    <div className="flex items-center gap-x-2">
      <input
        type="date"
        className="rounded-lg border border-m-gray-300 px-3 py-1.5 text-md font-medium"
        onChange={(e) => handleChange(e.target.value, 0)}
        value={format(new Date(filterItem?.data.value[0] as string), 'yyyy-MM-dd')}
      />
      <span className="text-sm">and</span>
      <input
        type="date"
        className="rounded-lg border border-m-gray-300 px-3 py-1.5 text-md font-medium"
        onChange={(e) => handleChange(e.target.value, 1)}
        value={format(new Date(filterItem?.data.value[1] as string), 'yyyy-MM-dd')}
      />
    </div>
  );
};

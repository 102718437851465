import { FilterCard } from '../../../../../shared/filters/components/common/FilterCard';
import { useFilterContext } from '../../../../../shared/filters/context/useFilterContext';
import { getFilter, useFilterStore } from '../../../../../shared/filters/store';

import { capitalize } from 'lodash';
import { ObjectsListData } from '@bigdelta/lib-api-client';
import { getWorkspaceObjectIcon } from '../../../../../shared/utils/getWorkspaceObjectIcon';
import { RecordsFilterAdd } from '../../../../reports/components/common/RecordsFilterAdd';
import { FC } from 'react';
import { WorkspaceObjectBlock } from '../../../../../shared/components/WorkspaceObjectBlock';

interface FilterRecordConfigObjectBlockWithFilterProps {
  workspaceObject: ObjectsListData['objects'][number];
}

export const FilterRecordConfigObjectBlockWithFilter: FC<FilterRecordConfigObjectBlockWithFilterProps> = ({ workspaceObject }) => {
  const { filterKey } = useFilterContext();
  const filter = useFilterStore((state) => getFilter(state, filterKey));

  return (
    <WorkspaceObjectBlock
      name={capitalize(workspaceObject.plural_noun)}
      icon={getWorkspaceObjectIcon(workspaceObject.object_type)}
      endSlot={<RecordsFilterAdd objectId={workspaceObject.id} defaultOpen={!filter?.items} />}
      filters={
        !!filter?.items.length &&
        filter.items.map((filterItem, index) => {
          return (
            <FilterCard
              key={`${JSON.stringify(filterItem.property)}-${index}`}
              filterIndex={index}
              filterItem={filterItem}
              objectId={workspaceObject.id}
              className="rounded-t-none border-t-0"
            />
          );
        })
      }
    />
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toMetricGraphNodeResponse = exports.MetricGraphNodeResponseDef = void 0;
const metricGraphDefinitionVO_1 = require("../value-object/metricGraphDefinitionVO");
const memberResponse_1 = require("./memberResponse");
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
exports.MetricGraphNodeResponseDef = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    graph_id: typebox_1.Type.String({ format: 'uuid' }),
    key: typebox_1.Type.String(),
    impact: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
        expression: typebox_1.Type.String(),
    })),
    type: typebox_1.Type.Enum(metricGraphDefinitionVO_1.MetricGraphMetricDefinitionType),
    metric_id: typebox_1.Type.String({ format: 'uuid' }),
    created_by: (0, typebox_2.OptionalNullable)(memberResponse_1.MemberResponseDef),
    updated_by: (0, typebox_2.OptionalNullable)(memberResponse_1.MemberResponseDef),
    created_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    updated_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
});
const toMetricGraphNodeResponse = (metricGraphNode) => {
    return {
        id: metricGraphNode.id,
        workspace_id: metricGraphNode.workspace_id,
        graph_id: metricGraphNode.graph_id,
        key: metricGraphNode.key,
        impact: metricGraphNode.composition,
        type: metricGraphNode.type,
        metric_id: metricGraphNode.metric_id,
        created_by: (0, memberResponse_1.toMemberResponse)(metricGraphNode.created_by),
        updated_by: (0, memberResponse_1.toMemberResponse)(metricGraphNode.updated_by),
        created_at: metricGraphNode.systemCreatedAt,
        updated_at: metricGraphNode.systemUpdatedAt,
    };
};
exports.toMetricGraphNodeResponse = toMetricGraphNodeResponse;

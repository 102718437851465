import { ComboboxOptionProps, Combobox as ComboboxPrimitive } from '@headlessui/react';
import { FC, forwardRef, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { RecordPropertyIcon } from './RecordPropertyIcon';
import { useQueryKeys } from '../../features/auth/hooks/useQueryKeys';
import { useWorkspace } from '../../features/auth/hooks/useWorkspace';
import { ComboboxInput } from '../../components/Combobox/ComboboxInput';
import { PropertyNameObject } from '../types';
import { bigdeltaAPIClient } from '../../client/bigdeltaAPIClient';
import { MetadataResourcePropertyType } from '@bigdelta/lib-shared';

export const ComboboxOption = forwardRef<HTMLLIElement, ComboboxOptionProps<any, any>>(({ children, ...props }, ref) => {
  return (
    <ComboboxPrimitive.Option
      className="flex cursor-default items-center gap-x-2 rounded-md px-2.5 py-3 text-sm leading-tight ui-selected:bg-m-gray-200 ui-active:bg-m-gray-200"
      ref={ref}
      {...props}
    >
      {children}
    </ComboboxPrimitive.Option>
  );
});

interface TopLevelPropertiesComboboxProps {
  onChange: (value: PropertyNameObject | null) => void;
  value: PropertyNameObject | null;
  resourceType: MetadataResourcePropertyType;
  resourceId: string;
}

export const TopLevelPropertiesCombobox: FC<TopLevelPropertiesComboboxProps> = ({ onChange, value, resourceType, resourceId }) => {
  const { currentWorkspaceId } = useWorkspace();

  const [search, setSearch] = useState('');

  const queryKeys = useQueryKeys();

  const propertiesQuery = useQuery({
    queryKey: queryKeys.list('metadata', 'properties', resourceType, resourceId),
    queryFn: () =>
      bigdeltaAPIClient.v1.metadataResourcesPropertiesNamesList({
        workspace_id: currentWorkspaceId,
        resource_type: resourceType,
        resource_id: resourceId,
      }),
  });

  const properties = propertiesQuery.data?.items.filter((property) =>
    property.property_name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );

  return (
    <ComboboxPrimitive value={value} onChange={onChange}>
      <ComboboxInput value={search} onChange={(e) => setSearch(e.target.value)} />
      <ComboboxPrimitive.Options static className="flex flex-col overflow-hidden pt-2">
        <div className="min-h-0 shrink overflow-auto">
          {properties?.map((property) => (
            <ComboboxOption key={property.property_id} value={property}>
              <RecordPropertyIcon propertyType={property.property_type} className="h-4 w-4 shrink-0 text-m-olive-600" />
              <span>{property.property_name}</span>
            </ComboboxOption>
          ))}
        </div>
      </ComboboxPrimitive.Options>
    </ComboboxPrimitive>
  );
};

import { useNodes } from 'reactflow';
import { Filter, FilterState, useFilterStore } from '../../../shared/filters/store';
import { isNodeAutomationBlock } from '../utils/graphNodeTypeguards';
import { AutomationWorkflowBlockType } from '@bigdelta/lib-shared';
import { get } from 'lodash';
import { CustomNodeData } from '../types';
import { useCallback } from 'react';
import { getBlockFilterKey } from '../utils/getBlockFilterKey';

export interface BlockFilterMap {
  [key: string]: Filter | BlockFilterMap;
}

export const useAutomationBlocksFilterMap = () => {
  const nodes = useNodes<CustomNodeData>();

  const selector = useCallback(
    (state: FilterState) => {
      return nodes.reduce<BlockFilterMap>((acc, node) => {
        if (
          isNodeAutomationBlock(node) &&
          [AutomationWorkflowBlockType.FILTER_RECORD, AutomationWorkflowBlockType.FILTER_EVENT].includes(node.data.automationActionType) &&
          node.data.block.id
        ) {
          acc[node.data.block.id] = get(state, getBlockFilterKey(node.data.block.id));
        }
        return acc;
      }, {});
    },
    [nodes]
  );

  return useFilterStore(selector);
};

export function isFilter(value: unknown): value is Filter {
  if (typeof value !== 'object' || value === null) {
    return false;
  }

  const candidate = value as Filter;
  return Array.isArray(candidate.items) && (candidate.operator === 'and' || candidate.operator === 'or');
}

export function isBlockFilterMap(value: unknown): value is BlockFilterMap {
  if (typeof value !== 'object' || value === null) {
    return false;
  }

  return Object.values(value as BlockFilterMap).every((item): item is Filter | BlockFilterMap => isFilter(item) || isBlockFilterMap(item));
}

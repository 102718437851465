"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrganizationResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.OrganizationResponseDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
        name: typebox_1.Type.String(),
        website: typebox_1.Type.Optional(typebox_1.Type.String()),
        company_size: typebox_1.Type.Optional(typebox_1.Type.String()),
    }),
]);

import { forwardRef } from 'react';
import { FormElementWrapper, FormElementWrapperProps } from '../FormElementWrapper/FormElementWrapper.tsx';
import { useMembersQuery } from '../../data/useMembersQuery.ts';
import { Select, SelectProps } from '../Select/Select.tsx';

export type CurrentWorkspaceMemberFormSelectProps = FormElementWrapperProps & {
  selectProps: SelectProps;
};

export const CurrentWorkspaceMemberFormSelect = forwardRef<HTMLSelectElement, CurrentWorkspaceMemberFormSelectProps>(
  ({ selectProps, ...rest }, ref) => {
    const membersQuery = useMembersQuery();

    return (
      <FormElementWrapper {...rest}>
        <Select ref={ref} disabled={membersQuery.isInitialLoading} {...selectProps}>
          <option disabled value="" className="text-m-olive-300">
            {selectProps.placeholder}
          </option>
          {membersQuery?.data?.map((value) => (
            <option key={value.id} value={value.id}>
              {value.email}
            </option>
          ))}
        </Select>
      </FormElementWrapper>
    );
  }
);

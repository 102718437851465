"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MemberDef = void 0;
const timestampedModel_1 = require("./timestampedModel");
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
exports.MemberDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        email: typebox_1.Type.String(),
        first_name: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
        last_name: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
        is_onboarding_form_filled: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
        preferred_timezone: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
        photo_image_url: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    }),
    timestampedModel_1.TimestampedModelDef,
]);

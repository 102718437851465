import { PropsWithChildren, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { PropsWithClassName } from '../types';

interface FilterTriggerProps extends PropsWithChildren, PropsWithClassName {
  open?: boolean;
  textClassName?: string;
}

export const FilterTrigger = forwardRef<HTMLButtonElement, FilterTriggerProps>(
  ({ children, open = false, className, textClassName, ...props }, ref) => (
    <button className={twMerge('flex items-center gap-x-1.5 rounded p-2', className)} {...props} ref={ref}>
      <span className={twMerge(textClassName, 'whitespace-nowrap text-[12px] leading-tight text-m-olive-400')}>{children}</span>
      {/*<ChevronDownIcon className="h-5 w-5 text-m-olive-400" />*/}
    </button>
  )
);

import React, { ButtonHTMLAttributes, FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { PageHeading } from '../../../components/PageHeading.tsx';
import { QuickAccessLinkType } from '@bigdelta/lib-shared';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';

import { Button } from '../../../shared/ui/Button/Button.tsx';
import { format, parseISO } from 'date-fns';
import { UserTableCell } from '../../../shared/ui/UserTableCell/UserTableCell.tsx';
import { IntegrationDisconnectModal } from './IntegrationDisconnectModal.tsx';
import { toastError, toastSuccess } from '../../../utils/toast.tsx';
import { AppRoutes } from '../../../routes';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

interface SlackIntegrationProfileProps {
  integrationId: string;
}

interface TabButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  tab: 'info';
  currentTab: 'info';
}

const TabButton: FC<TabButtonProps> = ({ label, tab, currentTab, ...props }) => {
  const isActive = tab === currentTab;

  return (
    <button
      className={twMerge(
        'text-m flex shrink-0 items-center px-3 py-4 font-medium',
        isActive ? 'border-b-2 border-m-blue-600 text-m-blue-600' : 'border-b border-m-gray-300 text-m-olive-600'
      )}
      {...props}
    >
      <div>{label}</div>
    </button>
  );
};

export const SlackIntegrationProfile: FC<SlackIntegrationProfileProps> = ({ integrationId }) => {
  const queryKeys = useQueryKeys();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState<'info'>('info');
  const [isDisconnectIntegrationConfirmationOpen, setIsDisconnectIntegrationConfirmationOpen] = useState(false);

  const disconnectIntegrationMutation = useMutation({
    mutationFn: (id: string) => bigdeltaAPIClient.v1.integrationsDelete(id),
    onSuccess: () => {
      toastSuccess('Integration disconnected', '');
      navigate(AppRoutes.INTEGRATIONS);
      queryClient.invalidateQueries(queryKeys.integrations());
      return queryClient.cancelQueries({ queryKey: queryKeys.integration(integrationId) });
    },
    onError: () => {
      toastError('An error occurred while disconnecting integration!');
    },
    onSettled: () => {
      setIsDisconnectIntegrationConfirmationOpen(false);
    },
  });

  const { data: slackIntegration } = useQuery({
    queryKey: queryKeys.integration(integrationId),
    queryFn: () => bigdeltaAPIClient.v1.integrationsSlackDetail(integrationId),
    enabled: !!integrationId,
  });

  return (
    <>
      <div className="flex items-center justify-between" style={{ zIndex: 1 }}>
        <PageHeading
          breadcrumbs={[
            {
              label: 'Integrations',
              to: '/integrations',
            },
            { label: slackIntegration?.id || '' },
          ]}
          favoriteLinkConfig={
            slackIntegration
              ? {
                  type: QuickAccessLinkType.INTEGRATION,
                  label: 'Slack Integration',
                  data: {
                    integration: {
                      integrationId: slackIntegration?.id,
                    },
                  },
                }
              : undefined
          }
        />
      </div>
      <div className="flex h-screen w-full flex-col">
        <div className="flex items-start justify-between" style={{ zIndex: 1 }}>
          <div className="flex">
            <TabButton
              tab="info"
              currentTab={currentTab}
              label="Info"
              onClick={() => {
                setCurrentTab('info');
              }}
            />
          </div>
          <div className="flex items-center gap-x-2">
            {currentTab === 'info' && (
              <Button
                intent="destructive"
                onClick={() => setIsDisconnectIntegrationConfirmationOpen(true)}
                label="Disconnect"
                size="md"
                className="bg-m-red-100 text-m-red-600"
              />
            )}
          </div>
        </div>
        <div className="w-full border-b border-m-gray-300"></div>
        <div className="relative h-full overflow-scroll">
          {currentTab === 'info' && slackIntegration && (
            <div className="flex w-full flex-col gap-y-4 overflow-hidden text-ellipsis whitespace-nowrap py-4 text-left text-sm font-regular first:rounded-l-lg last:rounded-r-lg">
              <div>
                <label className="text-md font-medium">Connected at:</label>
                <div className="relative pt-2">{format(parseISO(slackIntegration.created_at), 'dd/MM/yyyy')}</div>
              </div>
              <div>
                <label className="text-md font-medium">Connected by:</label>
                <div className="relative pt-2">
                  <UserTableCell email={slackIntegration.created_by?.email} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <IntegrationDisconnectModal
        open={!!isDisconnectIntegrationConfirmationOpen}
        onClose={() => setIsDisconnectIntegrationConfirmationOpen(false)}
        onConfirm={() => disconnectIntegrationMutation.mutate(integrationId)}
        disconnectWarningMessage="Are you sure you want to disconnect Slack integration? Once disconnected, you will no longer receive notifications from our platform."
      />
    </>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RangeResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const RangeResponseDef = (itemsSchema) => {
    return typebox_1.Type.Object({
        range: typebox_1.Type.Object({
            from: typebox_1.Type.Number(),
            to: typebox_1.Type.Number(),
            total: typebox_1.Type.Number(),
        }),
        items: typebox_1.Type.Array(itemsSchema),
    });
};
exports.RangeResponseDef = RangeResponseDef;

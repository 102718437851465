import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';
import { QuickAccessLink, QuickAccessLinkType } from '@bigdelta/lib-shared';
import { useQueryKeys } from '../../features/auth/hooks/useQueryKeys';
import { useWorkspace } from '../../features/auth/hooks/useWorkspace';
import { toastError } from '../../utils/toast';
import { MembersConfigQuickAccessLinksUpdatePayload } from '@bigdelta/lib-api-client';
import { bigdeltaAPIClient } from '../../client/bigdeltaAPIClient.ts';

export const useShortcuts = () => {
  const { currentWorkspaceId } = useWorkspace();

  const queryKeys = useQueryKeys();
  const queryClient = useQueryClient();

  const quickAccessLinksQuery = useQuery({
    queryKey: queryKeys.list('quick-access-link'),
    queryFn: () =>
      bigdeltaAPIClient.v1.membersConfigQuickAccessLinksList({
        workspace_id: currentWorkspaceId,
      }),
    staleTime: Infinity,
  });

  const quickAccessLinksUpdateMutation = useMutation({
    mutationFn: (payload: MembersConfigQuickAccessLinksUpdatePayload) =>
      bigdeltaAPIClient.v1.membersConfigQuickAccessLinksUpdate({ workspace_id: currentWorkspaceId }, payload),
    onMutate: async (newLinksPayload) => {
      await queryClient.cancelQueries({ queryKey: queryKeys.list('quick-access-link') });

      const previousLinks = queryClient.getQueryData(queryKeys.list('quick-access-link'));

      queryClient.setQueryData(queryKeys.list('quick-access-link'), () => newLinksPayload);

      return { previousLinks };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.list('quick-access-link'),
      });
    },
    onError: (_a, _b, context) => {
      queryClient.setQueryData(queryKeys.list('quick-access-link'), context?.previousLinks ?? { quick_access_links: [] });
      toastError('Could not update shortcuts');
    },
  });

  const removeShortcut = useCallback(
    (id: string) => {
      if (!quickAccessLinksQuery.data) {
        return;
      }

      quickAccessLinksUpdateMutation.mutate({
        quick_access_links: quickAccessLinksQuery.data.quick_access_links.filter((link) => link.id !== id) ?? [],
      });
    },
    [quickAccessLinksQuery.data, quickAccessLinksUpdateMutation]
  );

  const addShortcut = useCallback(
    (link: MembersConfigQuickAccessLinksUpdatePayload['quick_access_links'][number]) => {
      if (!quickAccessLinksQuery.data) {
        return;
      }

      const newQuickAccessLinks = [...quickAccessLinksQuery.data.quick_access_links, link].map((link, order) => ({ ...link, order }));

      quickAccessLinksUpdateMutation.mutate({
        quick_access_links: newQuickAccessLinks,
      });
    },
    [quickAccessLinksQuery.data, quickAccessLinksUpdateMutation]
  );

  const getMatchingShortcut = useCallback(
    (type: QuickAccessLinkType | undefined, data: QuickAccessLink['data']) => {
      return quickAccessLinksQuery.data?.quick_access_links.find((link) => isEqual(link.type, type) && isEqual(link.data, data));
    },
    [quickAccessLinksQuery]
  );

  return useMemo(
    () => ({
      getMatchingShortcut,
      addShortcut,
      removeShortcut,
      shortcuts: quickAccessLinksQuery.data?.quick_access_links,
    }),
    [addShortcut, getMatchingShortcut, quickAccessLinksQuery.data?.quick_access_links, removeShortcut]
  );
};

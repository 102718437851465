import { FC } from 'react';
import EventIcon from '../../../../assets/icons/cursor-click-01.svg?react';
import ListIcon from '../../../../assets/icons/list.svg?react';
import { FilterItemType } from '../../store';
import { FilterAdd } from '../common/FilterAdd';

interface RecordsFilterAddProps {
  as?: FC;
}

const options = [
  {
    value: FilterItemType.RECORDS_PROPERTY,
    label: 'Record property',
    icon: ListIcon,
  },
  {
    value: FilterItemType.RECORDS_EVENT_NAME,
    label: 'Event name',
    icon: EventIcon,
  },
  {
    value: FilterItemType.RECORDS_EVENT_PROPERTY,
    label: 'Event property',
    icon: EventIcon,
  },
];

export const RecordsFilterAdd: FC<RecordsFilterAddProps> = ({ as }) => {
  return <FilterAdd options={options} as={as} />;
};

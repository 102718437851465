import { useCallback, useEffect } from 'react';
import { FilterItem, FilterKey, useFilterStore } from '../../../shared/filters/store';
import { getRecordFilterItemsFromSegment } from '../../../shared/utils/getRecordFilterItemsFromSegment.ts';
import { getEventFilterItemsFromSegment } from '../../../shared/utils/getEventFilterItemsFromSegment.ts';
import { useRelationshipsQuery } from '../../../shared/data/useRelationshipsQuery.ts';
import { useWorkspace } from '../../auth/hooks/useWorkspace.tsx';
import { useReportStore } from '../store';
import { useReportQuery } from '../../../shared/data/useReportQuery.ts';

type Parameters = {
  enabled?: boolean;
  reportId?: string;
  filterKey: FilterKey;
  reportKey: string;
};

export const useHydrateReportQueryStoreFromReport = ({ reportId, filterKey, enabled, reportKey }: Parameters) => {
  const { currentWorkspaceId } = useWorkspace();
  const { isHydrated, hydrateFromReportData, markHydrated } = useReportStore(reportKey)((state) => state);

  const relationshipsQuery = useRelationshipsQuery({ workspaceId: currentWorkspaceId });
  const setFilterItems = useFilterStore((state) => state.setFilterItems);
  const reportQuery = useReportQuery({ reportId });

  const hydrateStoreFromQuery = useCallback(() => {
    if (!reportId) {
      markHydrated();
      return;
    }

    if (!reportQuery.isSuccess || isHydrated || !relationshipsQuery.isSuccess || !reportQuery.data.query) {
      return;
    }

    let filterItems: FilterItem[] = [];

    if (reportQuery.data.query.metrics[0].records && reportQuery.data.query.metrics[0].records?.filter) {
      filterItems = getRecordFilterItemsFromSegment(
        reportQuery.data.query.metrics[0].records.filter.conditions,
        reportQuery.data.query.metrics[0].records.resource.id,
        relationshipsQuery.data.relationships
      ).filter((item) => typeof item !== 'undefined');
    }

    if (reportQuery.data.query.metrics[0].events && reportQuery.data.query.metrics[0].events?.filter) {
      filterItems = getEventFilterItemsFromSegment(
        reportQuery.data.query.metrics[0].events.filter.conditions,
        relationshipsQuery.data.relationships,
        false
      ).filter((item) => typeof item !== 'undefined');
    }

    setFilterItems(filterKey, filterItems);
    hydrateFromReportData(reportQuery.data, reportQuery.data.query?.metrics?.[0], filterItems, relationshipsQuery.data.relationships);
  }, [
    filterKey,
    hydrateFromReportData,
    isHydrated,
    markHydrated,
    relationshipsQuery.data,
    relationshipsQuery.isSuccess,
    reportId,
    reportQuery.data,
    reportQuery.isSuccess,
    setFilterItems,
  ]);

  useEffect(() => {
    if (enabled) {
      hydrateStoreFromQuery();
    }
  }, [hydrateStoreFromQuery, enabled]);

  return null;
};

import { FC } from 'react';
import { Select, SelectItem } from '../common/Select';
import { useTrendsBuilderStore } from '../../store/Report';
import { MetricMath, ResourcePropertyType } from '@bigdelta/lib-shared';

const allowedAggregateOptions = {
  [ResourcePropertyType.NUMBER]: [MetricMath.SUM, MetricMath.AVERAGE, MetricMath.MIN, MetricMath.MAX, MetricMath.DISTINCT_TOTAL],
  [ResourcePropertyType.DATETIME64]: [MetricMath.MIN, MetricMath.MAX, MetricMath.DISTINCT_TOTAL],
  [ResourcePropertyType.DATETIME_UTC]: [MetricMath.MIN, MetricMath.MAX, MetricMath.DISTINCT_TOTAL],
  [ResourcePropertyType.TIMESTAMP]: [MetricMath.MIN, MetricMath.MAX, MetricMath.DISTINCT_TOTAL],
};

const defaultAggregateOptions = [MetricMath.DISTINCT_TOTAL];

const aggregationLabels = {
  [MetricMath.SUM]: 'Sum',
  [MetricMath.AVERAGE]: 'Average',
  [MetricMath.MEDIAN]: 'Median',
  [MetricMath.MIN]: 'Min',
  [MetricMath.MAX]: 'Max',
  [MetricMath.DISTINCT_TOTAL]: 'Distinct count',
};

interface AggregateSelectProps {
  value?: MetricMath;
  onChange: (value: MetricMath) => void;
  propertyType?: string;
}

const TrendsCountAggregateEvent: FC<AggregateSelectProps> = ({ value, onChange }) => {
  return (
    <Select value={value} onValueChange={onChange}>
      <SelectItem value={MetricMath.TOTAL}>Total Events</SelectItem>
      <SelectItem value={MetricMath.DISTINCT_TOTAL}>Unique Records</SelectItem>
    </Select>
  );
};

// TODO: Check list of available options for TrendsCountAggregateProperty
const TrendsCountAggregateProperty: FC<AggregateSelectProps> = ({ value, onChange, propertyType }) => {
  const getOptions = (): MetricMath[] => {
    if (!propertyType) {
      return defaultAggregateOptions;
    }

    return allowedAggregateOptions[propertyType] ?? defaultAggregateOptions;
  };

  return (
    <Select value={value} onValueChange={onChange}>
      {getOptions().map((option) => (
        <SelectItem value={option} key={option}>
          {aggregationLabels[option]}
        </SelectItem>
      ))}
    </Select>
  );
};

interface TrendsCountAggregateProps {
  id: string;
}

export const TrendsCountAggregate: FC<TrendsCountAggregateProps> = ({ id }) => {
  const count = useTrendsBuilderStore(id, (state) => state.data.object?.count);
  const setCountAggregate = useTrendsBuilderStore(id, (state) => state.setCountAggregate);

  const value = count?.aggregate;

  const getPropertyType = () => {
    if (count?.type === 'record' && count.record?.type === 'property') {
      return count.record.data.property?.property_type ?? undefined;
    }

    if (count?.type === 'event' && count.event?.type === 'property') {
      return count.event.data.property?.property.property_type ?? undefined;
    }

    return undefined;
  };

  if (count?.type === 'event' && count.event?.type === 'property') {
    return <TrendsCountAggregateProperty value={value} onChange={setCountAggregate} propertyType={getPropertyType()} />;
  }

  if (count?.type === 'record' && count.record?.type === 'property') {
    return <TrendsCountAggregateProperty value={value} onChange={setCountAggregate} propertyType={getPropertyType()} />;
  }

  if (count?.type === 'event' && count.event?.type === 'event') {
    return <TrendsCountAggregateEvent value={value} onChange={setCountAggregate} />;
  }

  if (count?.type === 'event' && count.event?.type === 'allEvents') {
    return <TrendsCountAggregateEvent value={value} onChange={setCountAggregate} />;
  }

  return null;
};

import PlayCircleIcon from '../../../assets/icons/play-circle.svg?react';
import CheckCircleIcon from '../../../assets/icons/check-circle.svg?react';
import LoadingIcon from '../../../assets/icons/loading-05.svg?react';
import AlertCircleIcon from '../../../assets/icons/alert-circle.svg?react';
import { FC } from 'react';

interface AutomationStatsProps {
  completed: number | null | undefined;
  running: number | null | undefined;
  failed: number | null | undefined;
}

export const AutomationStats: FC<AutomationStatsProps> = ({ completed, running, failed }) => (
  <div className="flex flex-col gap-y-4">
    <div className="text-xs font-medium">All time stats</div>
    <div className="grid grid-cols-2 gap-2.5">
      <div className="flex flex-col gap-y-6 rounded-xl border border-m-olive-100 p-4">
        <PlayCircleIcon className="h-6 w-6 text-m-blue-600" />
        <span className="text-xs">{`${(completed ?? 0) + (failed ?? 0)} Total runs`}</span>
      </div>
      <div className="flex flex-col gap-y-6 rounded-xl border border-m-olive-100 p-4">
        <CheckCircleIcon className="h-6 w-6 text-m-green-600" />

        <span className="text-xs">{`${completed ?? 0} Completed`}</span>
      </div>
      <div className="flex flex-col gap-y-6 rounded-xl border border-m-olive-100 p-4">
        <LoadingIcon className="h-6 w-6 text-m-gray-600" />

        <span className="text-xs">{`${running ?? 0} Running`}</span>
      </div>
      <div className="flex flex-col gap-y-6 rounded-xl border border-m-olive-100 p-4">
        <AlertCircleIcon className="h-6 w-6 text-m-red-600" />

        <span className="text-xs">{`${failed ?? 0} Failed`}</span>
      </div>
    </div>
  </div>
);

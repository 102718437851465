import { Float } from '@headlessui-float/react';
import { Listbox } from '@headlessui/react';
import { FC, useEffect, useMemo } from 'react';
import ChevronDownIcon from '../../assets/icons/chevron-down.svg?react';
import { twMerge } from 'tailwind-merge';
import { useObjectsQuery } from '../data/useObjectsQuery';
import { useRelationshipsQuery } from '../data/useRelationshipsQuery';
import { RelationshipEntityType } from '@bigdelta/lib-shared';
import { getObjectsRelatedToObject } from '../utils/getObjectsRelatedToObject';
import { getObjectsRelatedToEvent } from '../utils/getObjectsRelatedToEvent';
import { RelationshipObjectData } from '../types';
import { PropsWithClassName } from '../../types';

interface RelatedObjectSelectProps extends PropsWithClassName {
  workspaceId: string;
  onChange: (objectIdRelObj: RelationshipObjectData | null) => void;
  value: RelationshipObjectData | null;
  parentEntityType: RelationshipEntityType;
  parentEntityId?: string;
  parentEntityWorkspaceId: string;
  defaultValue?: string;
  labelPrefix?: string;
}

export const RelatedObjectSelect: FC<RelatedObjectSelectProps> = ({
  workspaceId,
  value,
  onChange,
  parentEntityType,
  parentEntityId,
  defaultValue,
  labelPrefix = 'Show:',
  className,
}) => {
  const { data: objectList } = useObjectsQuery({ workspaceId });
  const { data: relationshipData } = useRelationshipsQuery({ workspaceId });

  const objectIdsRels = useMemo(() => {
    if (!relationshipData) {
      return [];
    }

    if (parentEntityType === RelationshipEntityType.OBJECT && !!parentEntityId) {
      return getObjectsRelatedToObject({ entityId: parentEntityId, relationships: relationshipData.relationships });
    }

    if (parentEntityType === RelationshipEntityType.EVENT) {
      return getObjectsRelatedToEvent({ relationships: relationshipData.relationships });
    }

    return [];
  }, [parentEntityId, parentEntityType, relationshipData]);

  // TODO: fix types
  const options: (any | null)[] = useMemo(() => {
    if (defaultValue) {
      return [null, ...objectIdsRels];
    }
    return objectIdsRels;
  }, [defaultValue, objectIdsRels]);

  // TODO: this is a hack to set the default value to the first option
  useEffect(() => {
    if (parentEntityType === RelationshipEntityType.EVENT && !value && options.length) {
      onChange(options[0]);
    }
  }, [onChange, options, parentEntityType, value]);

  const getObjName = (id?: string) => {
    if (!objectList || !id) {
      return;
    }

    return objectList.objects.find((obj) => obj.id === id)?.plural_noun;
  };

  const getLabel = (value: RelationshipObjectData | null) => {
    if (value) {
      return <span>{`${labelPrefix} ${getObjName(value.objectId)}`}</span>;
    }

    if (defaultValue) {
      return <span>{`${labelPrefix} ${defaultValue}`}</span>;
    }

    return <span>{`Select object`}</span>;
  };

  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <Float offset={4} portal>
          <Listbox.Button
            className={twMerge(
              '-ml-2 flex items-center rounded bg-transparent px-2 py-1 text-xs hover:bg-m-gray-300',
              open && 'bg-m-gray-300',
              className
            )}
          >
            {getLabel(value)}
            <ChevronDownIcon className="h-4 w-4 text-m-olive-400" />
          </Listbox.Button>
          <Listbox.Options className="gap-y-0.5 rounded-lg border border-m-gray-300 bg-m-white px-3.5 py-4">
            <div className="px-2.5 py-1.5 text-xs text-m-olive-500">Select Object to show</div>
            {options.map((objIdRel) => {
              if (objIdRel === null) {
                return (
                  <Listbox.Option key={'null'} value={null} className="cursor-default bg-m-white p-2.5 text-md text-m-olive-900 hover:bg-m-gray-200">
                    {defaultValue}
                  </Listbox.Option>
                );
              }

              return (
                <Listbox.Option
                  key={`${objIdRel.objectId} - ${objIdRel.relationshipName}`}
                  value={objIdRel}
                  className="cursor-default bg-m-white p-2.5 text-md text-m-olive-900 hover:bg-m-gray-200"
                >
                  {getObjName(objIdRel.objectId)}
                </Listbox.Option>
              );
            })}
          </Listbox.Options>
        </Float>
      )}
    </Listbox>
  );
};

import { createColumnHelper, useReactTable, getCoreRowModel } from '@tanstack/react-table';
import React, { FC, FormEvent, useMemo, useState } from 'react';
import { ToolsTable } from '../../../shared/components/ToolsTable';

import { suppressConsoleWarn } from '../../../shared/utils/suppressConsoleWarn';
import DeleteIcon from '../../../assets/icons/trash-01.svg?react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { Dialog } from '@headlessui/react';
import { Button } from '../../../shared/ui/Button/Button.tsx';
import { AuthApiKeyListData, AuthTrackingKeyListData } from '@bigdelta/lib-api-client';
import { format, parseISO } from 'date-fns';
import { UserTableCell } from '../../../shared/ui/UserTableCell/UserTableCell.tsx';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

const columnHelper = createColumnHelper<AuthApiKeyListData[number]>();

interface ApiKeysTableProps {
  data?: AuthApiKeyListData;
}

export const ApiKeysTable: FC<ApiKeysTableProps> = ({ data }) => {
  const queryClient = useQueryClient();
  const queryKeys = useQueryKeys();

  const [isDeleteApiKeyDialogOpen, setDeleteApiKeyDialogOpen] = useState(false);
  const [targetedApiKeyId, setTargetedApiKeyId] = useState('');
  const [deleteConfirmationText, setDeleteConfirmationText] = useState('');

  const mutation = useMutation({
    mutationFn: (apiKeyId: string) => bigdeltaAPIClient.v1.authApiKeyDelete(apiKeyId),
    onSuccess: () => {
      handleDeleteApiKeyDialogClose();
      return queryClient.invalidateQueries(queryKeys.apiKeys());
    },
  });

  const EXPECTED_CONFIRMATION_TEXT = 'Delete API Key';

  const handleDeleteApiKey = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (deleteConfirmationText === EXPECTED_CONFIRMATION_TEXT) {
      mutation.mutate(targetedApiKeyId);
    }
  };

  const handleDeleteApiKeyDialogClose = () => {
    setTargetedApiKeyId('');
    setDeleteConfirmationText('');
    setDeleteApiKeyDialogOpen(false);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        header: 'Name',
        cell: (props) => {
          let value;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          return value;
        },
      }),
      columnHelper.accessor('key', {
        id: 'key',
        header: 'API Key',
        cell: (props) => {
          let value;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          return value;
        },
      }),
      columnHelper.display({
        id: 'secret_key',
        header: 'API Secret Key',
        cell: () => {
          return '********************************';
        },
      }),
      columnHelper.accessor('created_by', {
        id: 'created_by',
        header: 'Created by',
        cell: (props) => {
          let value: AuthTrackingKeyListData[number]['created_by'] | undefined;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          if (!value) {
            return '';
          }

          return <UserTableCell email={value.email} />;
        },
      }),
      columnHelper.accessor('created_at', {
        id: 'created_at',
        header: 'Date created',
        cell: (props) => {
          let value;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          if (!value) {
            return '';
          }

          return format(parseISO(value), 'dd/MM/yyyy');
        },
      }),
      columnHelper.display({
        id: 'actions',
        header: 'Actions',
        cell: (context) => {
          return (
            <div className="flex items-center gap-x-2">
              <button
                onClick={() => {
                  setTargetedApiKeyId(context.row.id);
                  setDeleteApiKeyDialogOpen(true);
                }}
              >
                <DeleteIcon className="h-5 w-5 cursor-pointer text-m-red-600 hover:text-m-red-700" />
              </button>
            </div>
          );
        },
      }),
    ],
    []
  );

  const table = useReactTable({
    columns,
    data: data ?? [],
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id,
  });

  return (
    <>
      <ToolsTable data={data} table={table} />
      <Dialog open={isDeleteApiKeyDialogOpen} onClose={handleDeleteApiKeyDialogClose} className="relative z-50">
        <div className="fixed inset-0 bg-m-gray-700 opacity-95" aria-hidden="true" />
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <Dialog.Panel className="mx-auto flex w-1/3 max-w-xl flex-col gap-y-6 rounded-xl bg-white p-6">
            <Dialog.Title className="text-xl text-m-olive-700">Delete API Key</Dialog.Title>
            <form onSubmit={handleDeleteApiKey} className="flex flex-col gap-y-6">
              <div className="flex flex-col gap-y-1.5">
                <p className="text-lg text-m-olive-700">Type "{EXPECTED_CONFIRMATION_TEXT}" to continue:</p>
                <input
                  name="delete_confirmation"
                  className="mt-5 rounded-lg border border-m-gray-300 px-3 py-2"
                  value={deleteConfirmationText}
                  placeholder={EXPECTED_CONFIRMATION_TEXT}
                  onChange={(e) => setDeleteConfirmationText(e.target.value)}
                />
              </div>
              <div className="flex justify-end gap-x-3.5">
                <Button type="button" label="Cancel" intent="secondary" size="lg" onClick={handleDeleteApiKeyDialogClose} />
                <Button
                  label="Delete API Key"
                  intent={deleteConfirmationText !== EXPECTED_CONFIRMATION_TEXT ? 'tertiary' : 'destructive'}
                  size="lg"
                  type="submit"
                  disabled={deleteConfirmationText !== EXPECTED_CONFIRMATION_TEXT}
                />
              </div>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

import React, { forwardRef, PropsWithChildren } from 'react';
import CloseCircle from '../../assets/icons/x-circle.svg?react';

type Props = PropsWithChildren & {
  title: React.ReactNode;
  onClose?: () => void;
};

export const SidePanelFooterWrapper = (props) => {
  return <div className="overflow fixed bottom-0 right-[-1px] w-full max-w-[450px] border-t border-m-olive-100 bg-white p-4" {...props} />;
};

export const SidePanel = forwardRef<HTMLDivElement, Props>(({ title, onClose, children }, ref) => {
  return (
    <div ref={ref} className={'fixed right-0 top-0 h-screen w-full max-w-[450px] overflow-auto border-l border-m-olive-100 bg-white pb-16 shadow-lg'} style={{zIndex: 9999}}>
      <div className="flex items-center justify-between p-6">
        <div className="text-lg font-medium">{title}</div>
        <CloseCircle className="h-6 w-6 cursor-pointer text-m-olive-300 hover:text-m-olive-400" onClick={onClose} />
      </div>
      {children}
    </div>
  );
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMetricGraphRequestDef = exports.GetMetricGraphCalculationRequestDef = exports.GetMetricGraphVODef = void 0;
const queryTypes_1 = require("./queryTypes");
const typebox_1 = require("@sinclair/typebox");
const model_1 = require("../model");
exports.GetMetricGraphVODef = typebox_1.Type.Object({
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    id: typebox_1.Type.String({ format: 'uuid' }),
    time: queryTypes_1.TimeRangeWithShiftVODef,
    segment: typebox_1.Type.Optional(model_1.MetricScenarioSegmentDef),
});
exports.GetMetricGraphCalculationRequestDef = typebox_1.Type.Object({
    metric_scenario_ids: typebox_1.Type.Optional(typebox_1.Type.Array(typebox_1.Type.String({ format: 'uuid' }))),
    metric_scenarios: typebox_1.Type.Optional(typebox_1.Type.Array(model_1.MetricScenarioCalculationPropertiesDef)),
}, { additionalProperties: false });
exports.GetMetricGraphRequestDef = typebox_1.Type.Object({
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    calculation: typebox_1.Type.Optional(exports.GetMetricGraphCalculationRequestDef),
    time: queryTypes_1.TimeRangeVODef,
    segment: typebox_1.Type.Optional(model_1.MetricScenarioSegmentDef),
}, { additionalProperties: false });

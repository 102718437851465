"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutomationStatusHistoryDef = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.AutomationStatusHistoryDef = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    automation_id: typebox_1.Type.String({ format: 'uuid' }),
    automation_version: typebox_1.Type.Number(),
    enabled: typebox_1.Type.Boolean(),
    updated_by: typebox_1.Type.String({ format: 'uuid' }),
    updated_at: typebox_1.Type.String({ format: 'date-time' }),
});

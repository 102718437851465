import toast from 'react-hot-toast';
import { ToastContent } from '../components/ToastContent';
import CheckIcon from '../assets/icons/check-circle.svg?react';
import AlertIcon from '../assets/icons/alert-circle.svg?react';

export const toastSuccess = (title: string, message: string) => {
  toast(<ToastContent title={title} message={message} />, { icon: <CheckIcon className="h-5 w-5 text-m-green-600" /> });
};

export const toastError = (message: string) => {
  toast(<ToastContent message={message} />, { icon: <AlertIcon className="h-5 w-5 text-m-red-600" /> });
};

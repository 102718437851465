import { FC } from 'react';
import { PropsWithClassName } from '../../../types';
import { ActionTypeIconMap } from '../const';
import { AutomationWorkflowBlockType } from '@bigdelta/lib-shared';

interface ActionIconProps extends PropsWithClassName {
  actionType: AutomationWorkflowBlockType;
}

export const ActionIcon: FC<ActionIconProps> = ({ actionType, className }) => {
  const Icon = ActionTypeIconMap[actionType];

  if (!Icon) {
    return null;
  }

  return <Icon className={className} />;
};

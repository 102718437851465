"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BigQueryIntegrationSyncRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_1 = require("../../../model");
const typebox_2 = require("../../../typebox");
exports.BigQueryIntegrationSyncRequestDef = typebox_1.Type.Object({
    sink_type: typebox_1.Type.Enum(model_1.BigQuerySinkType),
    record: (0, typebox_2.OptionalNullable)(model_1.BigQueryRecordSinkConfigurationDef),
});

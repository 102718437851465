"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BigQueryIntegrationEnableRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../../../typebox");
exports.BigQueryIntegrationEnableRequestDef = typebox_1.Type.Object({
    gcp_project_id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    dataset: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    location: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
});

import UserIcon from '../../assets/icons/user-03.svg?react';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  imageSrc?: string;
  circle?: boolean;
};

export const ImageDisplay: FC<Props> = ({ imageSrc, circle }) => {
  return (
    <div
      className={twMerge(
        'flex h-14 w-14 items-center justify-center overflow-hidden rounded-md border border-m-olive-100 bg-m-gray-300',
        circle && 'rounded-full'
      )}
    >
      {!imageSrc && <UserIcon className="h-6 w-6 text-m-olive-400" />}
      {imageSrc && <img src={imageSrc} />}
    </div>
  );
};

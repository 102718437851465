import { PropsWithChildren, forwardRef } from 'react';
import ChevronDownIcon from '../assets/icons/chevron-down.svg?react';
import { twMerge } from 'tailwind-merge';
import { PropsWithClassName } from '../types';

interface SelectTriggerSmallProps extends PropsWithChildren, PropsWithClassName {
  open?: boolean;
  textClassName?: string;
}

export const SelectTriggerSmall = forwardRef<HTMLButtonElement, SelectTriggerSmallProps>(
  ({ children, open = false, className, textClassName, ...props }, ref) => (
    <button
      className={twMerge(
        'flex items-center justify-between gap-x-3 rounded-lg border border-m-gray-300 px-3 py-1.5 text-md font-medium text-m-olive-600',
        open && 'bg-m-gray-200',
        className
      )}
      {...props}
      ref={ref}
    >
      <span className={twMerge(textClassName, 'whitespace-nowrap')}>{children}</span>
      <ChevronDownIcon className="h-5 w-5 text-m-olive-400" />
    </button>
  )
);

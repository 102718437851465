import { useFilterContext } from '../../context/useFilterContext';
import { useFilterStore } from '../../store';
import * as Select from '../../../../components/SelectFilter.tsx';
import { DebouncedInput } from './DebouncedInput';
import { Control } from './Control';
import { twMerge } from 'tailwind-merge';

const options = ['day', 'month'];

export const EventTimeframeInTheLast = ({ index }) => {
  const { filterKey, mode } = useFilterContext();
  const filterItem = useFilterStore((state) => state.getFilterItem([...filterKey, index]));

  const setItemEventTimeframeWindowValue = useFilterStore((state) => state.setItemEventTimeframeWindowValue);
  const setItemEventTimeframeWindowUnit = useFilterStore((state) => state.setItemEventTimeframeWindowUnit);

  const handleInputChange = (inputValue: string) => {
    setItemEventTimeframeWindowValue(filterKey, index, parseInt(inputValue, 10));
  };

  const handleUnitChange = (unit) => {
    setItemEventTimeframeWindowUnit(filterKey, index, unit);
  };

  if (filterItem?.timeframe.dateRangeType !== 'IN_THE_LAST') {
    return null;
  }

  return (
    <>
      <Control>
        <DebouncedInput
          type="number"
          value={filterItem?.timeframe.window?.value?.toString()}
          onChange={handleInputChange}
          className={twMerge(
            'w-16 rounded border-0 bg-transparent text-[12px] text-sm font-regular text-m-olive-400 ring-transparent focus:border-0 focus:text-m-olive-600 focus:ring-transparent group-hover:bg-[#f0f0f0]',
            mode === 'stacked' && 'w-full'
          )}
        />
      </Control>
      <Control>
        <Select.Root value={filterItem.timeframe.window?.unit} onValueChange={handleUnitChange}>
          <Select.Trigger placeholder="[unit]" label={filterItem.timeframe.window?.unit} className={twMerge(mode === 'stacked' && 'w-full')} />
          {options && (
            <Select.Content>
              {options.map((option) => (
                <Select.Item key={option} value={option} label={option} />
              ))}
            </Select.Content>
          )}
        </Select.Root>
      </Control>
    </>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetricGraphEdgeDefinitionVODef = exports.MetricGraphMetricDefinitionType = void 0;
const typebox_1 = require("@sinclair/typebox");
var MetricGraphMetricDefinitionType;
(function (MetricGraphMetricDefinitionType) {
    MetricGraphMetricDefinitionType["SUM"] = "sum";
    MetricGraphMetricDefinitionType["AVERAGE"] = "average";
    MetricGraphMetricDefinitionType["LATEST"] = "latest";
    MetricGraphMetricDefinitionType["COUNT"] = "count";
    MetricGraphMetricDefinitionType["RATE"] = "rate";
})(MetricGraphMetricDefinitionType || (exports.MetricGraphMetricDefinitionType = MetricGraphMetricDefinitionType = {}));
exports.MetricGraphEdgeDefinitionVODef = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    parent_node_id: typebox_1.Type.String(),
    child_node_id: typebox_1.Type.String(),
    influence: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
});

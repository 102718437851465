"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecordsDataQueryRequestDef = exports.RecordsDataQueryRecursiveVODef = exports.RecordQueryFiltersVODef = exports.RecordQueryFiltersRequestDef = exports.RecordsDataQueryFieldSelectVODef = exports.RecordQueryFilterVODef = exports.PropagatePropertyVO = exports.RecordQueryFilterRequestBase = exports.CoalesceFilterKeyVODef = exports.RecordQueryFilterValueDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const queryFiltersVO_1 = require("./queryFiltersVO");
const queryTypes_1 = require("./queryTypes");
const createRelationshipVO_1 = require("./createRelationshipVO");
const dataQueryPropertiesVO_1 = require("./dataQueryPropertiesVO");
const types_1 = require("../types");
const eventsDataQueryVO_1 = require("./eventsDataQueryVO");
const dataQuerySort_1 = require("../types/dataQuerySort");
exports.RecordQueryFilterValueDef = typebox_1.Type.Union([
    typebox_1.Type.String(),
    typebox_1.Type.Null(),
    typebox_1.Type.Number(),
    typebox_1.Type.Boolean(),
    typebox_1.Type.Array(typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Number(), typebox_1.Type.Boolean()])),
]);
exports.CoalesceFilterKeyVODef = typebox_1.Type.Object({
    coalesce_value: typebox_1.Type.String(),
});
const RecordIdFilterVODef = typebox_1.Type.Object({
    operator: types_1.QueryValueFilterOperatorType,
    value: typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Array(typebox_1.Type.String())]),
});
const RecordRelatedEventsFilterVODef = typebox_1.Type.Object({
    relationship_name: createRelationshipVO_1.RelationshipNameLowerCaseDef,
    operator: types_1.QueryFrequencyValueFilterOperatorType,
    value: typebox_1.Type.Optional(exports.RecordQueryFilterValueDef),
    time: typebox_1.Type.Optional(queryTypes_1.TimeRangeWithShiftVODef),
    filter: typebox_1.Type.Optional(eventsDataQueryVO_1.EventsQueryRequestQueryDef),
});
const CommonRecordPropertyFilterProperties = {
    name: typebox_1.Type.String(),
    operator: types_1.QueryValueFilterOperatorType,
    value: typebox_1.Type.Optional(exports.RecordQueryFilterValueDef),
    type: (0, typebox_2.OptionalNullable)(types_1.ResourcePropertyTypeType),
    property_id: typebox_1.Type.Optional(typebox_1.Type.String()),
    property_type: typebox_1.Type.Optional(types_1.ResourcePropertyTypeType),
};
const RecordPropertyFilterVORequestDef = typebox_1.Type.Object(Object.assign({}, CommonRecordPropertyFilterProperties));
const RecordPropertyFilterVODef = typebox_1.Type.Object(Object.assign(Object.assign({}, CommonRecordPropertyFilterProperties), { coalesce: typebox_1.Type.Optional(exports.CoalesceFilterKeyVODef) }));
const RecordFieldFilterVODef = typebox_1.Type.Object({
    name: typebox_1.Type.String(),
    operator: typebox_1.Type.Union([types_1.QueryValueFilterOperatorType, types_1.QueryValueFilterOperatorInternalType]),
    value: typebox_1.Type.Optional(exports.RecordQueryFilterValueDef),
    type: typebox_1.Type.Optional(types_1.ResourcePropertyTypeType),
    coalesce: typebox_1.Type.Optional(exports.CoalesceFilterKeyVODef),
});
exports.RecordQueryFilterRequestBase = typebox_1.Type.Recursive((Self) => typebox_1.Type.Object({
    [types_1.RecordFilterResourceType.RECORD_ID]: typebox_1.Type.Optional(RecordIdFilterVODef),
    [types_1.RecordFilterResourceType.RELATED_EVENTS]: typebox_1.Type.Optional(RecordRelatedEventsFilterVODef),
    [types_1.RecordFilterResourceType.RECORD_PROPERTY]: typebox_1.Type.Optional(RecordPropertyFilterVORequestDef),
    [types_1.RecordFilterResourceType.RELATED_RECORDS]: typebox_1.Type.Optional(typebox_1.Type.Object({
        relationship_name: createRelationshipVO_1.RelationshipNameLowerCaseDef,
        filter: typebox_1.Type.Optional((0, queryFiltersVO_1.QueryFiltersVODef)(Self, { $id: 'RecordQueryFilterRequestBaseRelatedRecordsFilter' })),
    })),
}), { $id: 'RecordQueryFilterRequestBase' });
exports.PropagatePropertyVO = typebox_1.Type.Object({
    name: typebox_1.Type.String(),
    alias: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    is_array_property: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
});
exports.RecordQueryFilterVODef = typebox_1.Type.Recursive((Self) => typebox_1.Type.Object({
    [types_1.RecordFilterResourceType.RECORD_ID]: typebox_1.Type.Optional(RecordIdFilterVODef),
    [types_1.RecordFilterResourceType.RECORD_FIELD]: typebox_1.Type.Optional(RecordFieldFilterVODef),
    [types_1.RecordFilterResourceType.RELATED_EVENTS]: typebox_1.Type.Optional(RecordRelatedEventsFilterVODef),
    [types_1.RecordFilterResourceType.RECORD_PROPERTY]: typebox_1.Type.Optional(RecordPropertyFilterVODef),
    [types_1.RecordFilterResourceType.RELATED_RECORDS]: typebox_1.Type.Optional(typebox_1.Type.Object({
        relationship_name: createRelationshipVO_1.RelationshipNameLowerCaseDef,
        filter: typebox_1.Type.Optional((0, queryFiltersVO_1.QueryFiltersVODef)(Self)),
        optional: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
        propagate_properties: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(typebox_1.Type.Object({
            name: typebox_1.Type.String(),
            relation_alias: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
            property_value_alias: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
            group_value_alias: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
            is_array_property: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
        }))),
        relationship_properties: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(typebox_1.Type.Ref(dataQueryPropertiesVO_1.DataQueryRelationshipPropertiesVODef))),
    })),
}));
exports.RecordsDataQueryFieldSelectVODef = typebox_1.Type.Object({
    expression: typebox_1.Type.Optional(typebox_1.Type.String()),
    field: typebox_1.Type.Optional(typebox_1.Type.String()),
    alias: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
});
const CommonRecordsDataQueryProperties = (filterDef) => ({
    properties: typebox_1.Type.Optional(dataQueryPropertiesVO_1.DataQueryPropertiesVODef),
    relationships: typebox_1.Type.Optional(dataQueryPropertiesVO_1.DataQueryRelationshipsVODef),
    sort: (0, typebox_2.OptionalNullable)((0, typebox_2.OptionalNullableRefArray)(typebox_1.Type.Ref(dataQuerySort_1.DataQuerySortElementDef))),
    query: typebox_1.Type.Object({
        resource: typebox_1.Type.Object({
            id: typebox_1.Type.String(),
        }),
        filter: filterDef,
    }),
    limit: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number({ minimum: 1, maximum: 100, default: 50 })),
    offset: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number({ minimum: 0, default: 0 })),
});
exports.RecordQueryFiltersRequestDef = (0, queryFiltersVO_1.QueryFiltersVODef)((0, typebox_2.OptionalNullable)(exports.RecordQueryFilterRequestBase), {
    $id: 'RecordQueryFiltersRequestDef',
});
exports.RecordQueryFiltersVODef = (0, queryFiltersVO_1.QueryFiltersVODef)((0, typebox_2.OptionalNullable)(exports.RecordQueryFilterVODef));
exports.RecordsDataQueryRecursiveVODef = typebox_1.Type.Object(Object.assign(Object.assign({}, CommonRecordsDataQueryProperties(exports.RecordQueryFiltersVODef)), { fields: typebox_1.Type.Array(exports.RecordsDataQueryFieldSelectVODef), time: typebox_1.Type.Optional(queryTypes_1.TimeRangeWithShiftVODef) }));
exports.RecordsDataQueryRequestDef = typebox_1.Type.Object(Object.assign({}, CommonRecordsDataQueryProperties(typebox_1.Type.Optional(typebox_1.Type.Ref(exports.RecordQueryFiltersRequestDef)))));

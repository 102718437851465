import { Float } from '@headlessui-float/react';
import { Listbox as ListboxBase } from '@headlessui/react';
import { FC } from 'react';

import ChevronDownIcon from '../../assets/icons/chevron-down.svg?react';
import { SVGComponent } from '../../types';

export interface Option<T = any> {
  value: T;
  label: string;
  icon?: SVGComponent;
}

interface ListboxProps {
  placeholder?: string;
  options: Option[];
  value: Option | null;
  onChange: (data: Option) => void;
  disabled?: boolean;
}

export const Listbox: FC<ListboxProps> = ({ options, value, onChange, placeholder, disabled }) => {
  return (
    <ListboxBase value={value} onChange={onChange} disabled={disabled || false}>
      <Float portal offset={4} adaptiveWidth>
        <ListboxBase.Button className="rounded-lg border border-m-olive-100 px-2.5 py-2 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-m-olive-500">
          <div className="flex items-center justify-between">
            {!value && placeholder && <span className="text-md text-m-olive-300">{placeholder}</span>}
            {value && (
              <div className="flex items-center gap-x-2">
                {/* <UsersIcon className="h-5 w-5 text-m-olive-300" /> */}
                <span className="text-sm">{value.label}</span>
              </div>
            )}
            <ChevronDownIcon className="h-5 w-5 text-m-olive-300" />
          </div>
        </ListboxBase.Button>
        <ListboxBase.Options className="flex max-h-40 w-full cursor-default flex-col gap-y-px overflow-y-auto rounded-lg border border-m-olive-100 bg-m-white p-2">
          {options.map((option) => (
            <ListboxBase.Option key={option.value} value={option} className="rounded-md px-2.5 py-2 text-sm ui-active:bg-m-gray-300">
              {option.label}
            </ListboxBase.Option>
          ))}
        </ListboxBase.Options>
      </Float>
    </ListboxBase>
  );
};

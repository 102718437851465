interface ComboboxInfiniteItemProps<T> {
  item: T;
  isLoaderRow: boolean;
  selected?: boolean;
  hasNextPage: boolean;
  itemCount: number;
  isFetching: boolean;
  isSuccess: boolean;
  multiple?: boolean;
  disabled?: boolean;
  renderOption: (option: T) => React.ReactNode;
}

export const ComboboxInfiniteItem = <T extends Record<string, string | null | undefined>>({
  isLoaderRow,
  selected,
  hasNextPage,
  renderOption,
  itemCount,
  isFetching,
  isSuccess,
  item,
  multiple = false,
  disabled,
}: ComboboxInfiniteItemProps<T>) => {
  return (
    <div className="flex items-center gap-x-2 px-4 py-3" data-tooltip-id="combobox-tooltip" data-tooltip-content={item?.property_value?.toString()}>
      {multiple && !isLoaderRow && (
        <input
          readOnly
          type="checkbox"
          checked={selected}
          tabIndex={-1}
          className="rounded border border-m-olive-200 disabled:bg-m-gray-400 hover:disabled:bg-m-gray-400"
          disabled={disabled}
        />
      )}
      <span className="overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium text-m-olive-600">
        {isLoaderRow && hasNextPage && 'Loading more...'}
        {isLoaderRow && !itemCount && isFetching && 'Loading...'}
        {isLoaderRow && !itemCount && isSuccess && 'No results'}
        {!isLoaderRow && renderOption(item)}
      </span>
    </div>
  );
};

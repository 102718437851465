import { useInfiniteQuery } from '@tanstack/react-query';

import { MetadataResourcePropertyType } from '@bigdelta/lib-shared';
import { bigdeltaAPIClient } from '../../client/bigdeltaAPIClient.ts';

interface UseMetadataPropertiesNamesInfiniteQueryArgsBase {
  workspaceId?: string;
  searchQuery: string;
  enabled?: boolean;
}

export interface UseMetadataPropertiesNamesInfiniteQueryArgsObject extends UseMetadataPropertiesNamesInfiniteQueryArgsBase {
  objectId: string;
  resourceType: MetadataResourcePropertyType.OBJECT;
}

export interface UseMetadataPropertiesNamesInfiniteQueryArgsEvent extends UseMetadataPropertiesNamesInfiniteQueryArgsBase {
  objectId?: string;
  resourceType: MetadataResourcePropertyType.EVENT;
}

export interface UseMetadataPropertiesNamesInfiniteQueryArgsMetric extends UseMetadataPropertiesNamesInfiniteQueryArgsBase {
  objectId?: string;
  resourceType: MetadataResourcePropertyType.METRIC;
}

type UseMetadataPropertiesNamesInfiniteQueryArgs =
  | UseMetadataPropertiesNamesInfiniteQueryArgsObject
  | UseMetadataPropertiesNamesInfiniteQueryArgsEvent
  | UseMetadataPropertiesNamesInfiniteQueryArgsMetric;

const getIsEnabled = (enabled: boolean, resourceType: MetadataResourcePropertyType, objectId?: string, workspaceId?: string): boolean => {
  if (resourceType === 'OBJECT') {
    return !!objectId && !!workspaceId && enabled;
  } else {
    return !!workspaceId && enabled;
  }
};

export const useMetadataPropertiesNamesInfiniteQuery = ({
  objectId,
  workspaceId,
  searchQuery,
  resourceType,
  enabled = true,
}: UseMetadataPropertiesNamesInfiniteQueryArgs) => {
  return useInfiniteQuery(
    ['metadata', resourceType, objectId, 'properties', 'names', searchQuery, 'infinite'],
    ({ pageParam }) => {
      return bigdeltaAPIClient.v1.metadataResourcesPropertiesNamesList({
        resource_id: objectId,
        resource_type: resourceType,
        workspace_id: workspaceId ?? '',
        limit: 500,
        offset: pageParam ?? 0,
        query: searchQuery ? searchQuery : undefined,
      });
    },
    {
      enabled: getIsEnabled(enabled, resourceType, objectId, workspaceId),
      getNextPageParam: (lastPage) => {
        if (lastPage.range.to + 1 < lastPage.range.total) {
          return lastPage.range.to + 1;
        } else {
          return undefined;
        }
      },
    }
  );
};

import { FC } from 'react';
import { FloatingPortal } from '@floating-ui/react';

import { useShowContentHover } from '../hooks/useShowContentHover';
import { twMerge } from '../../../utils/twMerge';
import { PropsWithClassName } from '../../../types';
import { NestedObjectDef } from '@bigdelta/lib-api-client';
import { Tag } from '../../components/Tag';
import { Badge } from '../../components/Badge';

type TagList = Extract<NestedObjectDef[string], any[]>;
type Tag = TagList[number];

interface CellContentTagsProps extends PropsWithClassName {
  tags: TagList;
}

const TAG_COUNT = 3;

export const CellContentTags: FC<CellContentTagsProps> = ({ tags, className }) => {
  const { floatingPortalContentProps, referenceProps, isOpen } = useShowContentHover();

  const displayTags = tags.slice(0, TAG_COUNT);
  const remainingCount = tags.length - displayTags.length;
  floatingPortalContentProps.className = twMerge('pointer-events-none', floatingPortalContentProps.className);

  return (
    <>
      <div className={twMerge('flex items-center gap-x-1.5 px-3 py-1', className)} {...referenceProps}>
        {displayTags.map((tag, index) => (
          <Tag key={index} value={tag} />
        ))}
        {remainingCount > 0 && <Badge variant='grey' className="text-xs text-m-olive-600">+{remainingCount}</Badge>}
      </div>
      {isOpen && (
        <FloatingPortal>
          <div {...floatingPortalContentProps}>
            <div className="max-w-xs rounded-md bg-m-white p-2 shadow-lg">
              {tags.map((tag, index) => (
                <Tag key={index} className="m-0.5" value={tag} />
              ))}
            </div>
          </div>
        </FloatingPortal>
      )}
    </>
  );
};

import { FC, useMemo } from 'react';
import { useReportStore, useTrendsBuilderStore } from '../../store/Report';
import { TrendsBuilderType } from '../../store/TrendsBuilder';
import { TrendsBuilderObject } from './TrendsBuilderObject';
import { TrendsBuilderMetric } from './TrendsBuilderMetric';
import { TrendsBuilderFormula } from './TrendsBuilderFormula';
import { FilterContext } from '../../../../shared/filters/context/context';
import { useWorkspace } from '../../../auth/hooks/useWorkspace';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MetricsCreatePayload, MetricsUpdatePayload } from '@bigdelta/lib-api-client';

import { useQueryKeys } from '../../../auth/hooks/useQueryKeys';
import { getMetricFromBuilder } from '../../utils/trends/getReportQueryRequestMetrics/getReportQueryRequestMetrics';
import { toastError, toastSuccess } from '../../../../utils/toast';
import { getTrendsBuilderStateFromSavedReport } from '../../utils/trends/getTrendsBuilderStateFromSavedReport';
import { useFilterStore } from '../../../../shared/filters/store';
import { bigdeltaAPIClient } from '../../../../client/bigdeltaAPIClient.ts';

interface TrendsBuilderProps {
  id: string;
}

export const TrendsBuilder: FC<TrendsBuilderProps> = ({ id }) => {
  const { currentWorkspaceId } = useWorkspace();
  const builders = useReportStore((state) => state.report.trends.builders);
  const builder = useTrendsBuilderStore(id, (state) => state);
  const filterKey = useMemo(() => ['reports', id], [id]);
  const filter = useFilterStore((state) => state.getFilter(filterKey));
  const queryClient = useQueryClient();

  const queryKeys = useQueryKeys();

  const objectsQuery = useQuery({
    queryKey: queryKeys.list('object'),
    queryFn: () => bigdeltaAPIClient.v1.objectsList({ workspace_id: currentWorkspaceId }),
  });

  const relationshipsQuery = useQuery({
    queryKey: queryKeys.list('relationship'),
    queryFn: () => bigdeltaAPIClient.v1.relationshipsList({ workspace_id: currentWorkspaceId }),
  });

  const updateMetricMutation = useMutation({
    mutationFn: ({ metricId, data }: { metricId: string; data: MetricsUpdatePayload }) => bigdeltaAPIClient.v1.metricsUpdate(metricId, data),
    onError: () => {
      toastError('Failed to update metric');
    },
    onSuccess: () => {
      toastSuccess('Updated', 'Metric updated');
      builder.resetInitialMetricBuilderData();
      queryClient.invalidateQueries(queryKeys.list('metric'));
    },
  });

  const createMetricMutation = useMutation({
    mutationFn: (payload: MetricsCreatePayload) => bigdeltaAPIClient.v1.metricsCreate(payload),
    onError: () => {
      toastError('Failed to create metric');
    },
    onSuccess: (createdMetric) => {
      if (!createdMetric.query_configuration.metric_query || !createdMetric.id) {
        // TODO: handle error
        return;
      }

      if (!relationshipsQuery.data?.relationships || !objectsQuery.data?.objects) {
        return;
      }

      const newBuilder = getTrendsBuilderStateFromSavedReport(
        createdMetric.query_configuration.metric_query,
        currentWorkspaceId,
        relationshipsQuery.data.relationships,
        objectsQuery.data.objects
      );

      if (!newBuilder) {
        return;
      }

      const builderData = {
        ...newBuilder,
        id,
        metricId: createdMetric.id,
        label: createdMetric.display_name,
      };

      builder.setBuilder({
        ...builderData,
        initialMetricBuilderData: builderData,
      });

      toastSuccess('Created', 'Metric created');

      queryClient.invalidateQueries(queryKeys.list('metric'));
    },
  });

  const handleCreateMetric = () => {
    if (!relationshipsQuery.data) {
      // TODO: handle rels data not ready somewhere else
      return;
    }

    if (!builder.label?.length) {
      builder.setIsEditingLabel(true);
      return;
    }

    // TODO: move this up the component tree?
    const metric = getMetricFromBuilder(builder, builders, relationshipsQuery.data?.relationships, true, { [id]: filter });

    createMetricMutation.mutate({
      workspace_id: currentWorkspaceId,
      display_name: builder.label,
      query_configuration: {
        metric_query: metric,
      },
    });
  };

  const handleUpdateMetric = () => {
    if (!builder.metricId || !relationshipsQuery.data || !builder.label) {
      return;
    }

    const metric = getMetricFromBuilder(builder, builders, relationshipsQuery.data?.relationships, true, { [id]: filter });

    updateMetricMutation.mutate({
      metricId: builder.metricId,
      data: {
        workspace_id: currentWorkspaceId,
        display_name: builder.label,
        query_configuration: {
          metric_query: metric,
        },
      },
    });
  };

  const handleStartRename = () => {
    builder.setIsEditingLabel(true);
  };

  return (
    <FilterContext.Provider value={{ filterKey, workspaceId: currentWorkspaceId, mode: 'inline', inlineElements: false }}>
      {builder.type === TrendsBuilderType.OBJECT && (
        <TrendsBuilderObject id={id} onCreateMetric={handleCreateMetric} onUpdateMetric={handleUpdateMetric} onRename={handleStartRename} />
      )}
      {builder.type === TrendsBuilderType.METRIC && <TrendsBuilderMetric id={id} />}
      {builder.type === TrendsBuilderType.FORMULA && (
        <TrendsBuilderFormula id={id} onCreateMetric={handleCreateMetric} onUpdateMetric={handleUpdateMetric} onRename={handleStartRename} />
      )}
    </FilterContext.Provider>
  );
};

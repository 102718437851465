import { ALL_EVENTS, FilterItem, FilterKey, useFilterStore } from '../../store';
import { EventPropertySelect } from '../common/EventPropertySelect.tsx';
import { PropertyOperatorSelect } from '../common/PropertyOperatorSelect.tsx';
import { PropertyValue } from '../common/PropertyValue.tsx';
import { MetadataResourcePropertyType } from '@bigdelta/lib-shared';
import { ActionButtonWithTooltip } from '../common/ActionButtonWithTooltip.tsx';

import XIcon from '../../../../assets/icons/x-close.svg?react';

export function EventProperty({ filterKey, index, filterItem }: { filterKey: FilterKey; index: number; filterItem: FilterItem }) {
  const setItemPropertyOperator = useFilterStore((state) => state.setItemPropertyOperator);
  const removeItem = useFilterStore((state) => state.removeItem);
  return (
    <div className="flex flex-col gap-2">
      <div className="group flex flex-row justify-between gap-2">
        <div className="flex flex-row justify-start gap-2 overflow-hidden">
          <EventPropertySelect index={index} filterItem={filterItem} />
          {filterItem.property && (
            <>
              <PropertyOperatorSelect
                index={index}
                value={filterItem.propertyOperator}
                onChange={(index, value) => setItemPropertyOperator(filterKey, index, value)}
              />
              {filterItem.propertyOperator && (
                <PropertyValue
                  index={index}
                  resourceId={filterItem.event !== ALL_EVENTS ? filterItem.event : undefined}
                  resourceType={MetadataResourcePropertyType.EVENT}
                />
              )}
            </>
          )}
        </div>
        <ActionButtonWithTooltip
          tooltipText="Delete property filter"
          onClick={() => {
            removeItem(filterKey, index);
          }}
        >
          <XIcon className="h-3 w-3" />
        </ActionButtonWithTooltip>
      </div>
    </div>
  );
}

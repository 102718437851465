import { useQuery } from '@tanstack/react-query';

import { AppRoutes, DashboardsRoutes } from '../../../routes';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { NavItem } from './NavItem';
import { createSearchParams } from 'react-router-dom';
import { QuickAccessLink, QuickAccessLinkType, WorkspaceObjectStatus } from '@bigdelta/lib-shared';

import AutomationIcon from '../../../assets/icons/dataflow-04.svg?react';
import DashboardIcon from '../../../assets/icons/presentation-chart-03.svg?react';
import RecordIcon from '../../../assets/icons/file-plus-02.svg?react';
import ReportIcon from '../../../assets/icons/line-chart-up-01.svg?react';
import IntegrationIcon from '../../../assets/icons/share-07.svg?react';
import CursorClickIcon from '../../../assets/icons/cursor-click-01.svg?react';
import { getWorkspaceObjectIcon } from '../../../shared/utils/getWorkspaceObjectIcon';
import { useShortcuts } from '../../../shared/hooks/useShortcuts';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient';

export const Favorites = () => {
  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();

  const objectsQuery = useQuery({
    queryKey: queryKeys.list('object', WorkspaceObjectStatus.ACTIVE),
    queryFn: () => bigdeltaAPIClient.v1.objectsList({ workspace_id: currentWorkspaceId, status: WorkspaceObjectStatus.ACTIVE }),
  });

  const { removeShortcut, shortcuts } = useShortcuts();

  const getRecordLink = (record: QuickAccessLink['data']['record']) => {
    if (!record || !objectsQuery.data) return '';

    const object = objectsQuery.data.objects.find((object) => object.id === record.workspace_object_id);

    if (!object) return '';

    return `/records/${object.api_slug}/${record.record_id}?${createSearchParams({ workspaceId: object.workspace_id }).toString()}`;
  };

  const getObjectLink = (object: QuickAccessLink['data']['object']) => {
    if (!object || !objectsQuery.data) return '';

    const objData = objectsQuery.data.objects.find((o) => o.id === object.workspace_object_id);

    if (!objData) return '';

    return `/records/${objData.api_slug}?${createSearchParams({ workspaceId: objData.workspace_id }).toString()}`;
  };

  const getObjectIcon = (workspaceObjectId: string) => {
    const objData = objectsQuery.data?.objects.find((o) => o.id === workspaceObjectId);

    return objData?.object_type ? getWorkspaceObjectIcon(objData?.object_type) : undefined;
  };

  const getLinkIcon = (link: QuickAccessLink) => {
    switch (link.type) {
      case QuickAccessLinkType.AUTOMATION:
        return AutomationIcon;
      case QuickAccessLinkType.DASHBOARD:
        return DashboardIcon;
      case QuickAccessLinkType.OBJECT:
        return link.data.object?.workspace_object_id ? getObjectIcon(link.data.object?.workspace_object_id) ?? RecordIcon : RecordIcon;
      case QuickAccessLinkType.RECORD:
        return link.data.record?.workspace_object_id ? getObjectIcon(link.data.record?.workspace_object_id) ?? RecordIcon : RecordIcon;
      case QuickAccessLinkType.REPORT:
        return ReportIcon;
      case QuickAccessLinkType.INTEGRATION:
        return IntegrationIcon;
      case QuickAccessLinkType.EVENTS:
        return CursorClickIcon;
      default:
        undefined;
    }
  };

  return shortcuts?.map((link) => {
    switch (link.type) {
      case QuickAccessLinkType.OBJECT:
        return (
          <NavItem
            key={link.id}
            icon={getLinkIcon(link)}
            label={link.label}
            isFavorite={true}
            to={getObjectLink(link.data.object)}
            onRemoveFavorite={() => removeShortcut(link.id)}
          />
        );
      case QuickAccessLinkType.RECORD:
        return (
          <NavItem
            key={link.id}
            icon={getLinkIcon(link)}
            label={link.label}
            isFavorite={true}
            to={getRecordLink(link.data.record)}
            onRemoveFavorite={() => removeShortcut(link.id)}
          />
        );
      case QuickAccessLinkType.REPORT:
        return (
          <NavItem
            key={link.id}
            icon={getLinkIcon(link)}
            label={link.label}
            isFavorite={true}
            to={`${AppRoutes.REPORTS}/${link.data.report?.report_id}`}
            onRemoveFavorite={() => removeShortcut(link.id)}
          />
        );
      case QuickAccessLinkType.DASHBOARD:
        return (
          <NavItem
            key={link.id}
            icon={getLinkIcon(link)}
            label={link.label}
            isFavorite={true}
            to={`${DashboardsRoutes.INDEX}/${link.data.dashboard?.dashboard_id}`}
            onRemoveFavorite={() => removeShortcut(link.id)}
          />
        );
      case QuickAccessLinkType.AUTOMATION:
        return (
          <NavItem
            key={link.id}
            icon={getLinkIcon(link)}
            label={link.label}
            isFavorite={true}
            to={AppRoutes.AUTOMATIONS_VIEW(link.data.automation?.automation_id ?? '')}
            onRemoveFavorite={() => removeShortcut(link.id)}
          />
        );
      case QuickAccessLinkType.INTEGRATION:
        return (
          <NavItem
            key={link.id}
            icon={getLinkIcon(link)}
            label={link.label}
            isFavorite={true}
            to={`${AppRoutes.INTEGRATIONS}/${link.data.integration?.integration_id}`}
            onRemoveFavorite={() => removeShortcut(link.id)}
          />
        );
      case QuickAccessLinkType.EVENTS:
        return (
          <NavItem
            key={link.id}
            icon={getLinkIcon(link)}
            label={link.label}
            isFavorite={true}
            to={AppRoutes.EVENTS}
            onRemoveFavorite={() => removeShortcut(link.id)}
          />
        );
    }
  });
};

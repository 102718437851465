import { AutomationsCreateData } from '@bigdelta/lib-api-client';
import { format } from 'date-fns';
import { FC, ReactElement } from 'react';

interface DelayUntilNodeContentProps {
  warning: ReactElement;
  block: AutomationsCreateData['configuration']['trigger']['block'];
}

export const DelayUntilNodeContent: FC<DelayUntilNodeContentProps> = ({ block, warning }) => {
  if (!block.delay_until?.datetime) {
    return warning;
  }

  return <div className="text-sm font-regular text-m-olive-400">{format(new Date(block.delay_until.datetime), 'd MMMM yyyy')}</div>;
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventListItemDef = exports.ReportQueryRecordsRequestDef = exports.ReportsQueryRequestDef = exports.DataInsightsDisplayOptionsVODef = exports.ReportsMetricQueryVODef = exports.ReportsMetricQueryFormatVODef = exports.ReportsQueryMetricsFormulaQueryVODef = exports.FormulaNamedMetricVODef = exports.ReportsQueryMetricsMetricQueryVODef = exports.ReportsQueryMetricFunnelQueryVODef = exports.EventSequenceStepVODef = exports.ReportsQueryMetricRetentionQueryVODef = exports.ReportsRetentionMetricEventQueryVODef = exports.ReportsQueryMetricPredefinedQueryVODef = exports.ReportsQueryMetricRecordQueryVODef = exports.ReportsQueryMetricEventQueryVODef = exports.RecordMathTarget = exports.EventMathTarget = exports.DataInsightsMetricQueryFilterRequest = exports.DataMetricQueryFilterVODef = exports.ReportsQueryGroupingVODef = exports.ReportsQueryGroupVODef = exports.RelatedRecordsQueryGroupVODef = exports.RecordFieldQueryGroupVODef = exports.RecordPropertyQueryGroupVODef = exports.ReportsQueryGroupFilterVODef = exports.ReportsRecordPropertyFilterVODef = exports.RetentionRepresentationType = exports.RetentionRepresentation = exports.PercentageScaleType = exports.PercentageScale = exports.CurrencyUnitType = exports.CurrencyUnit = void 0;
const typebox_1 = require("../typebox");
const typebox_2 = require("@sinclair/typebox");
const recordsDataQueryVO_1 = require("./recordsDataQueryVO");
const queryFiltersVO_1 = require("./queryFiltersVO");
const queryTypes_1 = require("./queryTypes");
const types_1 = require("../types");
const createRelationshipVO_1 = require("./createRelationshipVO");
var CurrencyUnit;
(function (CurrencyUnit) {
    CurrencyUnit["CENTS"] = "cents";
    CurrencyUnit["DECIMAL"] = "decimal";
})(CurrencyUnit || (exports.CurrencyUnit = CurrencyUnit = {}));
exports.CurrencyUnitType = (0, typebox_1.EnumStrings)(typebox_2.Type.Enum(CurrencyUnit));
var PercentageScale;
(function (PercentageScale) {
    PercentageScale["ZERO_TO_HUNDRED"] = "0-100";
    PercentageScale["ZERO_TO_ONE"] = "0-1";
})(PercentageScale || (exports.PercentageScale = PercentageScale = {}));
exports.PercentageScaleType = (0, typebox_1.EnumStrings)(typebox_2.Type.Enum(PercentageScale));
var RetentionRepresentation;
(function (RetentionRepresentation) {
    RetentionRepresentation["WEIGHTED_AVERAGE"] = "weighted average";
    RetentionRepresentation["COHORT"] = "cohort";
    RetentionRepresentation["PLAIN"] = "plain";
})(RetentionRepresentation || (exports.RetentionRepresentation = RetentionRepresentation = {}));
exports.RetentionRepresentationType = (0, typebox_1.EnumStrings)(typebox_2.Type.Enum(RetentionRepresentation));
exports.ReportsRecordPropertyFilterVODef = typebox_2.Type.Object({
    operator: queryTypes_1.GroupOperatorType,
    value: typebox_2.Type.Union([typebox_2.Type.String(), typebox_2.Type.Array(typebox_2.Type.String())]),
});
exports.ReportsQueryGroupFilterVODef = typebox_2.Type.Object({
    data_created: (0, typebox_1.OptionalNullable)(typebox_2.Type.Object({
        operator: typebox_2.Type.Literal(types_1.ReportsQueryGroupOperator.IN_PERIOD.toString()),
    })),
    record_property: (0, typebox_1.OptionalNullable)(typebox_2.Type.Object({
        name: typebox_2.Type.String(),
        operator: (0, typebox_1.StringLiterals)(Object.values(types_1.ReportsQueryGroupOperator)),
    })),
    event_property: (0, typebox_1.OptionalNullable)(typebox_2.Type.Object({
        name: typebox_2.Type.String(),
        operator: (0, typebox_1.StringLiterals)(Object.values(types_1.ReportsQueryGroupOperator)),
    })),
});
exports.RecordPropertyQueryGroupVODef = typebox_2.Type.Object({
    name: typebox_2.Type.String(),
    value_type: typebox_2.Type.Optional(queryTypes_1.GroupValueTypeType),
    is_field: (0, typebox_1.OptionalNullable)(typebox_2.Type.Boolean()),
    required_for_grouping: (0, typebox_1.OptionalNullable)(typebox_2.Type.Boolean()),
    required_for_aggregation: (0, typebox_1.OptionalNullable)(typebox_2.Type.Boolean()),
    required_for_sorting: (0, typebox_1.OptionalNullable)(typebox_2.Type.Boolean()),
    sort: (0, typebox_1.OptionalNullable)((0, typebox_1.OptionalNullableRef)(typebox_2.Type.Ref(types_1.DataQuerySortElementDef))),
    filter: (0, typebox_1.OptionalNullable)(typebox_2.Type.Array(exports.ReportsRecordPropertyFilterVODef)),
});
exports.RecordFieldQueryGroupVODef = typebox_2.Type.Object({
    name: typebox_2.Type.String(),
    value_type: typebox_2.Type.Optional(queryTypes_1.GroupValueTypeType),
    required_for_sorting: (0, typebox_1.OptionalNullable)(typebox_2.Type.Boolean()),
    sort: (0, typebox_1.OptionalNullable)((0, typebox_1.OptionalNullableRef)(typebox_2.Type.Ref(types_1.DataQuerySortElementDef))),
    filter: (0, typebox_1.OptionalNullable)(typebox_2.Type.Array(exports.ReportsRecordPropertyFilterVODef)),
});
exports.RelatedRecordsQueryGroupVODef = typebox_2.Type.Recursive((Self) => typebox_2.Type.Object({
    relationship_name: typebox_2.Type.String(),
    [types_1.RecordFilterResourceType.RECORD_PROPERTY]: (0, typebox_1.OptionalNullable)(typebox_2.Type.Array(exports.RecordPropertyQueryGroupVODef)),
    [types_1.RecordFilterResourceType.RELATED_RECORDS]: (0, typebox_1.OptionalNullable)(typebox_2.Type.Array(Self)),
}), { $id: 'RelatedRecordsQueryGroupVODef' });
exports.ReportsQueryGroupVODef = typebox_2.Type.Object({
    [queryTypes_1.TrackedEventFilterResourceType.EVENT_PROPERTY]: typebox_2.Type.Optional(typebox_2.Type.Object({
        name: typebox_2.Type.String(),
        value_type: typebox_2.Type.Optional(queryTypes_1.GroupValueTypeType),
    })),
    [queryTypes_1.TrackedEventFilterResourceType.EVENT_NAME]: typebox_2.Type.Optional(typebox_2.Type.Object({})),
    [types_1.RecordFilterResourceType.RECORD_PROPERTY]: (0, typebox_1.OptionalNullable)(exports.RecordPropertyQueryGroupVODef),
    [types_1.RecordFilterResourceType.RECORD_FIELD]: (0, typebox_1.OptionalNullable)(exports.RecordFieldQueryGroupVODef),
    [types_1.RecordFilterResourceType.RELATED_RECORDS]: (0, typebox_1.OptionalNullableRef)(typebox_2.Type.Ref(exports.RelatedRecordsQueryGroupVODef)),
});
exports.ReportsQueryGroupingVODef = typebox_2.Type.Object({
    filter: (0, typebox_1.OptionalNullable)(typebox_2.Type.Array(exports.ReportsQueryGroupFilterVODef)),
    groups: (0, typebox_1.OptionalNullable)(typebox_2.Type.Array(exports.ReportsQueryGroupVODef)),
    period_include_past_events: (0, typebox_1.OptionalNullable)(typebox_2.Type.Boolean()),
});
exports.DataMetricQueryFilterVODef = typebox_2.Type.Object({
    resourceType: typebox_2.Type.Union([typebox_2.Type.Enum(queryTypes_1.TrackedEventFilterResourceType), typebox_2.Type.Enum(types_1.RecordFilterResourceType)]),
    filter_operator: typebox_2.Type.Union([types_1.QueryValueFilterOperatorType, queryTypes_1.MetricFilterOperatorType]),
    filter_key: typebox_2.Type.Optional(typebox_2.Type.String()),
    filter_value: typebox_2.Type.Union([
        typebox_2.Type.String(),
        typebox_2.Type.Number(),
        typebox_2.Type.Boolean(),
        typebox_2.Type.Array(typebox_2.Type.Union([typebox_2.Type.String(), typebox_2.Type.Number(), typebox_2.Type.Boolean()])),
    ]),
    time: typebox_2.Type.Optional(queryTypes_1.TimeRangeWithShiftVODef),
});
exports.DataInsightsMetricQueryFilterRequest = (0, queryFiltersVO_1.QueryFiltersVODef)((0, typebox_1.OptionalNullable)(exports.DataMetricQueryFilterVODef), {
    $id: 'DataInsightsMetricQueryFilterRequest',
});
exports.EventMathTarget = typebox_2.Type.Object({
    groups: (0, typebox_1.OptionalNullable)(typebox_2.Type.Array(exports.ReportsQueryGroupVODef)),
    event_property: typebox_2.Type.Optional(typebox_2.Type.Object({
        name: typebox_2.Type.String(),
        property_type: typebox_2.Type.Optional(typebox_2.Type.String()),
    })),
    related_records: typebox_2.Type.Optional(typebox_2.Type.Object({
        relationship_name: typebox_2.Type.String(),
    })),
});
exports.RecordMathTarget = typebox_2.Type.Object({
    record_property: typebox_2.Type.Optional(typebox_2.Type.Object({
        name: typebox_2.Type.String(),
        property_type: typebox_2.Type.Optional(typebox_2.Type.String()),
    })),
    related_records: typebox_2.Type.Optional(typebox_2.Type.Object({
        relationship_name: typebox_2.Type.String(),
        property_name: typebox_2.Type.Optional(typebox_2.Type.String()),
        property_type: typebox_2.Type.Optional(typebox_2.Type.String()),
    })),
});
exports.ReportsQueryMetricEventQueryVODef = typebox_2.Type.Object({
    math: queryTypes_1.MetricMathType,
    math_target: typebox_2.Type.Optional(exports.EventMathTarget),
    filter: typebox_2.Type.Optional(typebox_2.Type.Unsafe(typebox_2.Type.Ref('EventsQueryRequestQueryDef'))),
});
exports.ReportsQueryMetricRecordQueryVODef = typebox_2.Type.Object({
    math: queryTypes_1.MetricMathType,
    math_target: typebox_2.Type.Optional(exports.RecordMathTarget),
    resource: typebox_2.Type.Object({
        id: typebox_2.Type.String(),
    }),
    filter: typebox_2.Type.Optional(typebox_2.Type.Ref(recordsDataQueryVO_1.RecordQueryFiltersRequestDef)),
});
exports.ReportsQueryMetricPredefinedQueryVODef = typebox_2.Type.Object({
    id: typebox_2.Type.String(),
});
exports.ReportsRetentionMetricEventQueryVODef = typebox_2.Type.Object({
    filter: typebox_2.Type.Optional(typebox_2.Type.Unsafe(typebox_2.Type.Ref('EventsQueryRequestQueryDef'))),
});
exports.ReportsQueryMetricRetentionQueryVODef = typebox_2.Type.Object({
    representation: typebox_2.Type.Optional(exports.RetentionRepresentationType),
    relationship_name: createRelationshipVO_1.RelationshipNameLowerCaseDef,
    entry_event: exports.ReportsRetentionMetricEventQueryVODef,
    retention_event: exports.ReportsRetentionMetricEventQueryVODef,
});
exports.EventSequenceStepVODef = typebox_2.Type.Object({
    [queryTypes_1.TrackedEventFilterResourceType.EVENT_NAME]: typebox_2.Type.Object({
        name: typebox_2.Type.String(),
    }),
    filter: typebox_2.Type.Optional(typebox_2.Type.Unsafe(typebox_2.Type.Ref('EventsQueryRequestQueryDef'))),
});
exports.ReportsQueryMetricFunnelQueryVODef = typebox_2.Type.Object({
    relationship_name: createRelationshipVO_1.RelationshipNameLowerCaseDef,
    record_filter: typebox_2.Type.Optional(typebox_2.Type.Ref(recordsDataQueryVO_1.RecordQueryFiltersRequestDef)),
    sequence_completion_window: queryTypes_1.TimeRangeWindowVODef,
    event_sequence: typebox_2.Type.Array(exports.EventSequenceStepVODef, {
        minItems: 2,
    }),
});
exports.ReportsQueryMetricsMetricQueryVODef = typebox_2.Type.Object({
    resource: typebox_2.Type.Object({
        id: typebox_2.Type.String(),
    }),
    events: typebox_2.Type.Optional(typebox_2.Type.Object({
        filter: typebox_2.Type.Optional(typebox_2.Type.Unsafe(typebox_2.Type.Ref('EventsQueryRequestQueryDef'))),
    })),
    records: typebox_2.Type.Optional(typebox_2.Type.Object({
        filter: typebox_2.Type.Optional(typebox_2.Type.Unsafe(typebox_2.Type.Ref('RecordQueryFiltersRequestDef'))),
    })),
});
exports.FormulaNamedMetricVODef = typebox_2.Type.Object({
    name: typebox_2.Type.String(),
    [queryTypes_1.MetricResourceType.EVENTS]: typebox_2.Type.Optional(exports.ReportsQueryMetricEventQueryVODef),
    [queryTypes_1.MetricResourceType.RECORDS]: typebox_2.Type.Optional(exports.ReportsQueryMetricRecordQueryVODef),
    [queryTypes_1.MetricResourceType.METRIC]: typebox_2.Type.Optional(exports.ReportsQueryMetricsMetricQueryVODef),
});
exports.ReportsQueryMetricsFormulaQueryVODef = typebox_2.Type.Object({
    expression: typebox_2.Type.String({ minLength: 1 }),
    metrics: typebox_2.Type.Array(exports.FormulaNamedMetricVODef, { minItems: 1 }),
}, { additionalProperties: false });
exports.ReportsMetricQueryFormatVODef = typebox_2.Type.Object({
    monetary: typebox_2.Type.Optional(typebox_2.Type.Object({
        unit: exports.CurrencyUnitType,
        code: typebox_2.Type.String(),
    })),
    percentage: typebox_2.Type.Optional(typebox_2.Type.Object({
        scale: exports.PercentageScaleType,
    })),
});
exports.ReportsMetricQueryVODef = typebox_2.Type.Object({
    [queryTypes_1.MetricResourceType.EVENTS]: typebox_2.Type.Optional(exports.ReportsQueryMetricEventQueryVODef),
    [queryTypes_1.MetricResourceType.RECORDS]: typebox_2.Type.Optional(exports.ReportsQueryMetricRecordQueryVODef),
    [queryTypes_1.MetricResourceType.PREDEFINED]: typebox_2.Type.Optional(exports.ReportsQueryMetricPredefinedQueryVODef),
    [queryTypes_1.MetricResourceType.RETENTION]: typebox_2.Type.Optional(exports.ReportsQueryMetricRetentionQueryVODef),
    [queryTypes_1.MetricResourceType.METRIC]: typebox_2.Type.Optional(exports.ReportsQueryMetricsMetricQueryVODef),
    [queryTypes_1.MetricResourceType.FORMULA]: typebox_2.Type.Optional(exports.ReportsQueryMetricsFormulaQueryVODef),
    [queryTypes_1.MetricResourceType.FUNNEL]: typebox_2.Type.Optional(exports.ReportsQueryMetricFunnelQueryVODef),
    format: typebox_2.Type.Optional(exports.ReportsMetricQueryFormatVODef),
    query_name: (0, typebox_1.OptionalNullable)(typebox_2.Type.String()),
    name: (0, typebox_1.OptionalNullable)(typebox_2.Type.String()),
});
exports.DataInsightsDisplayOptionsVODef = typebox_2.Type.Object({
    time_granularity: typebox_2.Type.Optional(typebox_2.Type.Enum(queryTypes_1.TimeGranularity)),
    chart_type: typebox_2.Type.Optional(typebox_2.Type.Enum(queryTypes_1.ChartType)),
});
exports.ReportsQueryRequestDef = typebox_2.Type.Object({
    display_options: typebox_2.Type.Optional(exports.DataInsightsDisplayOptionsVODef),
    grouping: (0, typebox_1.OptionalNullable)(exports.ReportsQueryGroupingVODef),
    metrics: typebox_2.Type.Array(exports.ReportsMetricQueryVODef),
    time: queryTypes_1.TimeRangeWithShiftVODef,
}, { additionalProperties: false, $id: 'ReportsQueryRequestDef' });
exports.ReportQueryRecordsRequestDef = typebox_2.Type.Object({
    query: typebox_2.Type.Ref(exports.ReportsQueryRequestDef),
    from_timestamp: (0, typebox_1.OptionalNullable)(typebox_2.Type.String({ format: 'date-time' })),
    to_timestamp: (0, typebox_1.OptionalNullable)(typebox_2.Type.String({ format: 'date-time' })),
    activation_timestamp: (0, typebox_1.OptionalNullable)(typebox_2.Type.String({ format: 'date-time' })),
    retention_timestamp: (0, typebox_1.OptionalNullable)(typebox_2.Type.String({ format: 'date-time' })),
    dimensions: (0, typebox_1.OptionalNullable)(typebox_2.Type.Record(typebox_2.Type.String(), typebox_1.PrimitiveTypeDef)),
    stage: (0, typebox_1.OptionalNullable)(typebox_2.Type.Number({ minimum: 0 })),
    limit: (0, typebox_1.OptionalNullable)(typebox_2.Type.Number({ minimum: 1, maximum: 100, default: 50 })),
    offset: (0, typebox_1.OptionalNullable)(typebox_2.Type.Number({ minimum: 0, default: 0 })),
}, { additionalProperties: false, $id: 'ReportQueryRecordsRequestDef' });
exports.EventListItemDef = typebox_2.Type.Object({
    [queryTypes_1.TrackedEventFilterResourceType.EVENT_NAME]: typebox_2.Type.Object({
        name: typebox_2.Type.String(),
    }),
});

import React from 'react';
import BreakdownIcon from '../../../assets/icons/flex-align-top.svg?react';
import BothIcon from '../../../assets/icons/flex-align-middle.svg?react';
import ChartIcon from '../../../assets/icons/flex-align-bottom.svg?react';
import { twMerge } from 'tailwind-merge';

export enum ReportViewMode {
  CHART = 'chart',
  BOTH = 'both',
  BREAKDOWN = 'breakdown',
}

export interface ReportViewModeSeparatorProps {
  value: ReportViewMode;
  onChange: (value: ReportViewMode) => void;
}

export const ReportViewModeSeparator: React.FC<ReportViewModeSeparatorProps> = ({ value, onChange }) => {
  return (
    <div className="flex items-center gap-2">
      <hr className="w-full border-2 border-m-gray-200" />
      <ChartIcon className={twMerge("h-12 w-12 cursor-pointer", value === ReportViewMode.CHART ? 'text-m-olive-400' : 'text-m-gray-400')} onClick={() => onChange(ReportViewMode.CHART)} />
      <BothIcon className={twMerge("h-12 w-12 cursor-pointer", value === ReportViewMode.BOTH ? 'text-m-olive-400' : 'text-m-gray-400')} onClick={() => onChange(ReportViewMode.BOTH)} />
      <BreakdownIcon className={twMerge("h-12 w-12 cursor-pointer", value === ReportViewMode.BREAKDOWN ? 'text-m-olive-400' : 'text-m-gray-400')} onClick={() => onChange(ReportViewMode.BREAKDOWN)} />
      <hr className="w-full border-2 border-m-gray-200" />
    </div>
  );
};

export default ReportViewModeSeparator;

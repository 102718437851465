import { EventTimeframeOperatorSelect } from '../common/EventTimeframeOperatorSelect.tsx';
import { EventTimeframeInTheLast } from '../common/EventTimeframeInTheLast.tsx';
import { EventTimeframeBefore } from '../common/EventTimeframeBefore.tsx';
import { EventTimeframeAfter } from '../common/EventTimeframeAfter.tsx';
import { EventTimeframeBetween } from '../common/EventTimeframeBetween.tsx';

type EventTimeframeProps = {
  filterIndex: number;
};

export function EventTimeframe({ filterIndex }: EventTimeframeProps) {
  return (
    <div className="group flex flex-row flex-wrap items-center gap-2">
      <EventTimeframeOperatorSelect index={filterIndex} />
      <EventTimeframeInTheLast index={filterIndex} />
      <EventTimeframeBefore index={filterIndex} />
      <EventTimeframeAfter index={filterIndex} />
      <EventTimeframeBetween index={filterIndex} />
    </div>
  );
}

import { Float } from '@headlessui-float/react';
import { FocusTrap, Popover } from '@headlessui/react';
import { ReportCreateSelectedMetricAggregateTrigger } from './ReportCreateSelectedMetricContainer';
import { useEffect } from 'react';
import ChevronRightIcon from '../../../assets/icons/chevron-right.svg?react';
import { Menu, MenuGroup, MenuItem } from '../../../shared/components/Menu';
import { RecordsMath, useReportsStore } from '../store';

const MATH_VALUE_LABEL_MAP = {
  [RecordsMath.TOTAL]: 'Total records',
  aggregate: 'Aggregate',
  [RecordsMath.AGGREGATE_SUM]: 'Sum',
  [RecordsMath.AGGREGATE_AVERAGE]: 'Average',
  [RecordsMath.AGGREGATE_MEDIAN]: 'Median',
  [RecordsMath.AGGREGATE_NPS]: 'NPS',
  [RecordsMath.AGGREGATE_DISTINCT_COUNT]: 'Distinct count',
};

export const MetricRecordsMath = () => {
  const recordsMath = useReportsStore((state) => state.config.recordMath);
  const setRecordsMath = useReportsStore((state) => state.setRecordsMath);

  return (
    <Popover>
      {({ close }) => (
        <Float offset={4} portal>
          <Popover.Button as={ReportCreateSelectedMetricAggregateTrigger}>
            <span className="overflow-hidden text-ellipsis whitespace-nowrap">{recordsMath && MATH_VALUE_LABEL_MAP[recordsMath]}</span>
          </Popover.Button>
          <Popover.Panel>
            <FocusTrap>
              <div className="flex w-64 flex-col gap-y-px rounded-xl border border-m-gray-300 bg-m-white p-2.5 text-md text-m-olive-900 shadow-xl">
                <Menu
                  onChange={(data) => {
                    setRecordsMath(data as RecordsMath);
                    close();
                  }}
                >
                  <MenuGroup>
                    <MenuItem value={RecordsMath.TOTAL}>{MATH_VALUE_LABEL_MAP[RecordsMath.TOTAL]}</MenuItem>
                    <MenuItem value="aggregate">
                      <div className="flex w-full items-center justify-between">
                        <span>{MATH_VALUE_LABEL_MAP['aggregate']}</span>
                        <ChevronRightIcon className="h-4 w-4 text-m-olive-600" />
                      </div>
                    </MenuItem>
                  </MenuGroup>

                  <MenuGroup value="aggregate">
                    <MenuItem value={RecordsMath.AGGREGATE_SUM}>{MATH_VALUE_LABEL_MAP[RecordsMath.AGGREGATE_SUM]}</MenuItem>
                    <MenuItem value={RecordsMath.AGGREGATE_AVERAGE}>{MATH_VALUE_LABEL_MAP[RecordsMath.AGGREGATE_AVERAGE]}</MenuItem>
                    <MenuItem value={RecordsMath.AGGREGATE_MEDIAN}>{MATH_VALUE_LABEL_MAP[RecordsMath.AGGREGATE_MEDIAN]}</MenuItem>
                    <MenuItem value={RecordsMath.AGGREGATE_NPS}>{MATH_VALUE_LABEL_MAP[RecordsMath.AGGREGATE_NPS]}</MenuItem>
                    <MenuItem value={RecordsMath.AGGREGATE_DISTINCT_COUNT}>{MATH_VALUE_LABEL_MAP[RecordsMath.AGGREGATE_DISTINCT_COUNT]}</MenuItem>
                  </MenuGroup>
                </Menu>
              </div>
            </FocusTrap>
          </Popover.Panel>
        </Float>
      )}
    </Popover>
  );
};

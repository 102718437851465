import { RecordQueryFiltersRequestDef, RelationshipsListData } from '@bigdelta/lib-api-client';
import { getRecordEventsFilterFromCondition } from './getRecordEventsFilterFromCondition';
import { FilterItem, FilterItemType } from '../filters/store';
import { RelationshipEntityType } from '@bigdelta/lib-shared';
import { getRelatedRecordFilterFromCondition } from './getRelatedRecordFilterFromCondition';
import { toastError } from '../../utils/toast';

export type RecordQueryFiltersCondition = Exclude<RecordQueryFiltersRequestDef['conditions'][number], RecordQueryFiltersRequestDef>;

export const getRecordFilterItemsFromSegment = (
  conditions: (RecordQueryFiltersCondition | RecordQueryFiltersRequestDef)[],
  objectId: string,
  relationships: RelationshipsListData['relationships']
): FilterItem[] => {
  return conditions.flatMap((condition) => {
    let filterItem: FilterItem | undefined = undefined;

    if (!condition) {
      return [];
    }

    if ('related_events' in condition) {
      try {
        filterItem = getRecordEventsFilterFromCondition(condition);
      } catch (e: any) {
        toastError(e.message);
      }
    }

    if ('related_records' in condition) {
      try {
        filterItem = getRelatedRecordFilterFromCondition(
          condition,
          relationships,
          FilterItemType.RECORDS_PROPERTY,
          RelationshipEntityType.OBJECT,
          objectId
        );
      } catch (e: any) {
        toastError(e.message);
      }
    }

    if ('record_property' in condition) {
      try {
        filterItem = getRelatedRecordFilterFromCondition(
          condition,
          relationships,
          FilterItemType.RECORDS_PROPERTY,
          RelationshipEntityType.OBJECT,
          objectId
        );
      } catch (e: any) {
        toastError(e.message);
      }
    }

    return filterItem ? [filterItem] : [];
  });
};

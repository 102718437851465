import { useMutation } from '@tanstack/react-query';

import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

interface UseSegmentCreateMutationArgs {
  onSuccess?: () => void;
}

interface DeleteWorkspaceMutationParameters {
  workspaceId: string;
}

export const useDeleteWorkspaceMutation = ({ onSuccess }: UseSegmentCreateMutationArgs = {}) => {
  return useMutation({
    mutationFn: ({ workspaceId }: DeleteWorkspaceMutationParameters) => {
      return bigdeltaAPIClient.v1.workspacesDelete(workspaceId);
    },
    onSuccess,
  });
};

import { FC, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../../features/auth/hooks/useQueryKeys';
import { useWorkspace } from '../../features/auth/hooks/useWorkspace';
import { Listbox } from './Listbox';
import { bigdeltaAPIClient } from '../../client/bigdeltaAPIClient.ts';

interface WorkspaceObjectSelectProps {
  onChange: (workspaceObjectProperty: string) => void;
  workspaceObjectId: string;
  value: string | null;
}

export const WorkspaceObjectPropertySelect: FC<WorkspaceObjectSelectProps> = ({ onChange, workspaceObjectId, value }) => {
  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();

  const objectQuery = useQuery({
    queryKey: queryKeys.object(workspaceObjectId),
    queryFn: () => bigdeltaAPIClient.v1.objectsDetail(workspaceObjectId, { workspace_id: currentWorkspaceId }),
  });

  const availableOptions = useMemo(() => {
    if (!objectQuery.data?.properties) return [];

    return [
      { value: undefined, label: '(any)' },
      ...(objectQuery.data?.properties.map((property) => ({
        value: property.name,
        label: property.name,
      })) || []),
    ];
  }, [objectQuery.data?.properties]);

  const handleChange = (data) => {
    onChange(data.value);
  };

  const selectedOption = availableOptions.find((option) => option.value === value) ?? null;

  return (
    <div className="flex w-full flex-col">
      <Listbox options={availableOptions} value={selectedOption} placeholder="Select workspace object property" onChange={handleChange} />
    </div>
  );
};

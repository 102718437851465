import { Button } from '../ui/Button/Button.tsx';
import React, { FC, useCallback, useRef, useState } from 'react';
import UploadIcon from '../../assets/icons/upload-01.svg?react';
import { Dialog } from '@headlessui/react';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../utils/getCroppedImg.ts';

type Props = {
  onChange: (imageSrc: string) => void;
  circle?: boolean;
};

export const UploadImageButton: FC<Props> = ({ onChange, circle }) => {
  const [imageSrc, setImageSrc] = React.useState(null);
  const [showCropDialog, setShowCropDialog] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onFileChange = async (evt) => {
    if (evt.target.files && evt.target.files.length > 0) {
      const file = evt.target.files[0];
      const imageDataUrl = await readFile(file);

      setImageSrc(imageDataUrl);
      setShowCropDialog(true);
    }
  };

  const onCropComplete = (_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onSelect = useCallback(async () => {
    if (onChange) {
      const result = await getCroppedImg(imageSrc, croppedAreaPixels);

      onChange(result);
    }

    setShowCropDialog(false);
  }, [croppedAreaPixels, imageSrc, onChange]);

  return (
    <>
      <Dialog open={showCropDialog} onClose={() => setShowCropDialog(false)} className="relative z-[999999]">
        <div className="fixed inset-0 bg-m-gray-700 opacity-50" aria-hidden="true" />
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <Dialog.Panel className="max-h-1/3 mx-auto flex min-w-96 max-w-xl flex-col items-center gap-4 rounded-xl bg-white p-4">
            <Dialog.Title className="text-xl text-m-olive-700">Crop image</Dialog.Title>
            <div className="relative h-64 w-64">
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={1}
                cropShape={circle ? 'round' : 'rect'}
                cropSize={{ height: 128, width: 128 }}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
            <Button label="Select" onClick={onSelect} intent="secondary" />
          </Dialog.Panel>
        </div>
      </Dialog>
      <Button label="Upload image" intent="secondary" leadingIcon={UploadIcon} type="button" onClick={() => fileInputRef.current.click()} />
      <input onChange={onFileChange} multiple={false} ref={fileInputRef} type="file" accept="image/*" hidden />
    </>
  );
};

const readFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
};

import { useCallback, useContext, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';
import { setAuthenticationToken } from '../../../shared/utils/authToken.ts';
import { tracking, TrackingEvent } from '../../../tracking';
import { SubmitHandler, UseFormSetError } from 'react-hook-form';
import { handleFormErrorResponse } from '../../../utils/handleFormErrorResponse.ts';
import { AppRoutes } from '../../../routes';
import { UserContext, UserContextState } from '../context/userContext.tsx';
import { useNavigate, useSearchParams } from 'react-router-dom';

export interface SignUpFormSchema {
  email: string;
}

export interface LoginFormSchema {
  email: string;
  password: string;
}

export const useAuth = (
  setFieldError?: UseFormSetError<any>
): {
  userContext: UserContextState;
  searchParams: URLSearchParams;
  onSignIn: SubmitHandler<LoginFormSchema>;
  onGoogleSignIn: (idToken: string) => Promise<void>;
  onSignUp: SubmitHandler<SignUpFormSchema>;
  error: string;
  loading: boolean;
  invited: boolean;
} => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [invited, setInvited] = useState<boolean>(false);

  const redirectUrl = searchParams.get('redirect');
  const navigateAfterLogin = redirectUrl ? redirectUrl : AppRoutes.ROOT;
  const referrer = searchParams.get('referrer');

  useEffect(() => {
    if (referrer === 'invitation') {
      setInvited(true);
    }
  }, [referrer]);

  const onSignIn: SubmitHandler<LoginFormSchema> = useCallback(
    async (data) => {
      setLoading(true);

      try {
        if (isEmpty(data.email) || isEmpty(data.password)) {
          setError('Email and password are required');
          return;
        }

        setError('');

        try {
          const response = await bigdeltaAPIClient.v1.authTokenCreate({
            grant_type: 'password',
            username: data.email,
            password: data.password,
          });

          setAuthenticationToken(response.token);

          if (userContext.fetchUser) {
            const result = await userContext.fetchUser();

            if (result) {
              setError('');
              tracking.track(TrackingEvent.LoggedIn);
              navigate(navigateAfterLogin);
            }
          }
        } catch (e: any) {
          handleFormErrorResponse(e, setError, setFieldError);
        }
      } finally {
        setLoading(false);
      }
    },
    [navigate, navigateAfterLogin, setFieldError, userContext]
  );

  const onGoogleSignIn = useCallback(
    async (idToken: string) => {
      setLoading(true);

      try {
        if (isEmpty(idToken)) {
          setError('Error occurred while trying to sign in with Google!');
          return;
        }

        setError('');

        try {
          const response = await bigdeltaAPIClient.v1.authTokenCreate({
            grant_type: 'id_token',
            id_token: idToken,
          });

          setAuthenticationToken(response.token);

          if (userContext.fetchUser) {
            const result = await userContext.fetchUser();

            if (result) {
              setError('');
              tracking.track(TrackingEvent.LoggedIn);
              navigate(navigateAfterLogin);
            }
          }
        } catch (e: any) {
          setError(e?.response?.data?.message);
        }
      } finally {
        setLoading(false);
      }
    },
    [navigate, navigateAfterLogin, userContext]
  );

  const onSignUp: SubmitHandler<SignUpFormSchema> = useCallback(
    async (data) => {
      setLoading(true);
      try {
        setError('');

        try {
          const response = await bigdeltaAPIClient.v1.membersSignupCreate({
            email: data.email,
          });

          setAuthenticationToken(response.token);

          if (userContext.fetchUser) {
            const result = await userContext.fetchUser();

            if (result) {
              setError('');
              tracking.track(TrackingEvent.SignupStepOneCompleted);
              navigate(navigateAfterLogin);
            }
          }
        } catch (e: any) {
          setError(e?.response?.data?.reason || e?.response?.data?.message || e?.response?.data?.errorType);
          console.error(e);
        }
      } finally {
        setLoading(false);
      }
    },
    [navigate, navigateAfterLogin, userContext]
  );

  return { userContext, searchParams, onSignIn, onGoogleSignIn, onSignUp, error, loading, invited };
};

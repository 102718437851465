import { FC, PropsWithChildren } from 'react';
import { useFilterContext } from '../../context/useFilterContext';
import { twMerge } from 'tailwind-merge';

interface ControlProps extends PropsWithChildren {
  shouldShrink?: boolean;
}

export const Control: FC<ControlProps> = ({ children, shouldShrink = true }) => {
  const { mode } = useFilterContext();
  return (
    <div
      className={twMerge(
        mode === 'stacked' && 'flex min-w-0 flex-grow basis-auto flex-col items-stretch gap-2',
        mode === 'inline' && 'flex',
        mode === 'stacked' && !shouldShrink && 'flex-shrink-0'
      )}
    >
      {children}
    </div>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toGroupResponse = exports.GroupResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
exports.GroupResponseDef = typebox_1.Type.Object({
    id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    accountId: typebox_1.Type.String({ format: 'uuid' }),
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    name: typebox_1.Type.Optional(typebox_1.Type.String()),
    created_by: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
    updated_by: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
    created_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    updated_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
});
const toGroupResponse = (group) => {
    return {
        id: group.id,
        accountId: group.accountId,
        workspace_id: group.workspaceId,
        name: group.name,
        created_by: group.createdBy,
        updated_by: group.updatedBy,
        created_at: group.systemCreatedAt,
        updated_at: group.systemUpdatedAt,
    };
};
exports.toGroupResponse = toGroupResponse;

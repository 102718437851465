"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateDashboardVODef = void 0;
const dashboard_1 = require("../model/dashboard");
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
exports.CreateDashboardVODef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    }),
    typebox_1.Type.Omit(dashboard_1.DashboardPropertiesDef, ['created_by', 'updated_by']),
]);

import { Handle, Node, NodeProps, Position, useEdges } from 'reactflow';
import { twMerge } from '../../../utils/twMerge';
import { AddGraphNodeTrigger } from '../../../shared/components/AddGraphNodeTrigger';
import { useAutomationsStore } from '../store';
import { TriggerType } from '../types';
import { ObjectsListData } from '@bigdelta/lib-api-client';
import { MouseEvent } from 'react';
import { TriggerIcon } from './TriggerIcon';
import { TriggerTypeLabelMap } from '../const';
import { ConfigureWarning } from './ConfigureWarning';

export const NodeAutomationTriggerType = 'automation-trigger';

interface NodeAutomationTriggerData {
  isEmpty: string;
  triggerType?: TriggerType;
  workspaceObject?: ObjectsListData['objects'][number];
  workspaceObjectProperty?: string;
  eventName?: string;
}

export type NodeAutomationTrigger = Node<NodeAutomationTriggerData>;

export const NodeAutomationTrigger = ({
  id,
  data: { triggerType, isEmpty, workspaceObject, workspaceObjectProperty, eventName },
  selected,
}: NodeProps<NodeAutomationTriggerData>) => {
  const { setCreateNodeData, createNodeData } = useAutomationsStore();
  const edges = useEdges();
  const hasChild = edges.some((edge) => edge.source === id);

  const handleAddClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setCreateNodeData({
      source: 'trigger',
    });
  };

  const isValid = () => {
    if (triggerType && [TriggerType.RECORD_CREATED, TriggerType.RECORD_UPDATED, TriggerType.RECORD_DELETED].includes(triggerType)) {
      return !!workspaceObject;
    }
    if (triggerType && triggerType === TriggerType.EVENT_OCCURRED) {
      return !!eventName;
    }
    return true;
  };

  return (
    <div
      className={twMerge(
        'group/node flex w-72 flex-col gap-y-3  rounded-2xl border-2 bg-m-white p-5 shadow-md',
        selected ? 'border-m-lime-700' : 'border-m-olive-100'
      )}
    >
      {isEmpty && <div className="p-5 text-center text-sm font-regular text-m-gray-700">Select trigger in the side panel</div>}
      {!isEmpty && triggerType && (
        <div className="flex flex-col gap-y-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-x-2">
              <TriggerIcon triggerType={triggerType} className="h-4 w-4" />
              <div className="text-md font-medium">{TriggerTypeLabelMap[TriggerType[triggerType]]}</div>
            </div>
            <div className="flex items-center justify-center rounded-md bg-m-gray-300 p-1 text-xxs text-m-olive-400">Trigger</div>
          </div>

          <hr className=" border-m-olive-100" />
          {!workspaceObject && !eventName && <ConfigureWarning />}
          {workspaceObject && <div className="text-sm font-regular text-m-olive-400">{`Object: ${workspaceObject.plural_noun}`}</div>}
          {workspaceObjectProperty && <div className="text-sm font-regular text-m-olive-400">{`Object property: ${workspaceObjectProperty}`}</div>}
          {eventName && <div className="text-sm font-regular text-m-olive-400">{`Event: ${eventName}`}</div>}
        </div>
      )}

      {!isEmpty && !createNodeData && !hasChild && isValid() && (
        <div className="nodrag nopan absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-full px-12 pt-4">
          <AddGraphNodeTrigger position="bottom" className="relative" onClick={handleAddClick} show={selected} />
        </div>
      )}
      <Handle position={Position.Bottom} type="source" />
    </div>
  );
};

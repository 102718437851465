"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.toIntegrationResponse = exports.IntegrationResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const types_1 = require("../../types");
const typebox_2 = require("../../typebox");
const memberResponse_1 = require("../memberResponse");
exports.IntegrationResponseDef = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    account_id: typebox_1.Type.String({ format: 'uuid' }),
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    type: typebox_1.Type.Enum(types_1.IntegrationType),
    status: typebox_1.Type.Enum(types_1.IntegrationStatus),
    created_by: (0, typebox_2.OptionalNullable)(memberResponse_1.MemberResponseDef),
    created_at: typebox_1.Type.String({ format: 'date-time' }),
});
const toIntegrationResponse = (integration, memberResponseMapper) => __awaiter(void 0, void 0, void 0, function* () {
    return {
        id: integration.id,
        account_id: integration.account_id,
        workspace_id: integration.workspace_id,
        type: integration.type,
        status: integration.status,
        created_at: integration.systemCreatedAt,
        created_by: yield memberResponseMapper(integration.created_by),
    };
});
exports.toIntegrationResponse = toIntegrationResponse;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkspaceObjectRecordChangeDef = exports.ActionType = exports.ChangeType = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_1 = require("../model");
var ChangeType;
(function (ChangeType) {
    ChangeType["created"] = "created";
    ChangeType["updated"] = "updated";
    ChangeType["archived"] = "archived";
})(ChangeType || (exports.ChangeType = ChangeType = {}));
var ActionType;
(function (ActionType) {
    ActionType["added"] = "added";
    ActionType["updated"] = "updated";
    ActionType["removed"] = "removed";
})(ActionType || (exports.ActionType = ActionType = {}));
exports.WorkspaceObjectRecordChangeDef = typebox_1.Type.Object({
    modified_by: model_1.AuthorDef,
    modified_at: typebox_1.Type.String({ format: 'date-time' }),
    type: typebox_1.Type.Enum(ChangeType),
    properties: typebox_1.Type.Record(typebox_1.Type.String(), typebox_1.Type.Object({
        action: typebox_1.Type.Enum(ActionType),
        old_value: typebox_1.Type.Any(),
        new_value: typebox_1.Type.Any(),
    })),
});

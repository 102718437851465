"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateTypeDef = exports.TemplateType = void 0;
const typebox_1 = require("../typebox");
const typebox_2 = require("@sinclair/typebox");
var TemplateType;
(function (TemplateType) {
    TemplateType["CUSTOMER_ACTIVATION_NEW_SIGNUPS"] = "customer_activation_new_signups";
    TemplateType["CUSTOMER_ACTIVATION_ACTIVATION_FLOW"] = "customer_activation_activation_flow";
    TemplateType["CUSTOMER_ACTIVATION_ACTIVE_USERS"] = "customer_activation_active_users";
    // CUSTOMER_RETENTION_POWER_USERS = 'customer_retention_power_users',
    TemplateType["CUSTOMER_RETENTION_RETENTION"] = "customer_retention_retention";
    TemplateType["PRODUCT_USAGE_FEATURE_REPORT"] = "product_usage_feature_report";
    TemplateType["MAPS_NEW_SIGNUPS"] = "maps_new_signups";
    TemplateType["MAPS_ACTIVE_CUSTOMERS"] = "maps_active_customers";
    // MAPS_POWER_USERS = 'maps_power_users',
    TemplateType["RECURRING_REVENUE"] = "recurring_revenue";
    // RECURRING_REVENUE_MRR = 'recurring_revenue_mrr',
    // RECURRING_REVENUE_MRR_BREAKDOWN = 'recurring_revenue_mrr_breakdown',
    // RECURRING_REVENUE_MRR_MOVEMENT = 'recurring_revenue_mrr_movement',
    // SUBSCRIBERS_NUMBER_OF_SUBSCRIBERS = 'subscribers_number_of_subscribers',
    // SUBSCRIBERS_AVG_REVENUE_PER_ACCOUNT = 'subscribers_avg_revenue_per_account',
    // SUBSCRIBERS_AVG_SELLING_PRICE = 'subscribers_avg_selling_price',
    // SUBSCRIBERS_LIFETIME_VALUE = 'subscribers_lifetime_value',
    // RETENTION_CHURN_CUSTOMER_CHURN = 'retention_churn_customer_churn',
    // RETENTION_CHURN_GROSS_REVENUE_CHURN = 'retention_churn_gross_revenue_churn',
    // RETENTION_CHURN_GROSS_REVENUE_RETENTION = 'retention_churn_gross_revenue_retention',
    // RETENTION_CHURN_NET_REVENUE_RETENTION = 'retention_churn_net_revenue_retention',
    // CASHFLOW_SUCCESSFUL_PAYMENTS = 'cashflow_successful_payments',
    // CASHFLOW_FAILED_TRANSACTIONS = 'cashflow_failed_transactions',
    // CASHFLOW_REFUNDS = 'cashflow_refunds',
    // CASHFLOW_NON_RECURRING_CASH_FLOW = 'cashflow_non_recurring_cash_flow',
    // MAPS_CUSTOMERS = 'maps_customers',
    // MAPS_AVG_REVENUE_PER_ACCOUNT = 'maps_avg_revenue_per_account',
    // MAPS_AVG_SALES_PRICE = 'maps_avg_sales_price',
    // MAPS_CUSTOMER_CHURN_RATE = 'maps_customer_churn_rate',
    // MAPS_CUSTOMER_LIFETIME_VALUE = 'maps_customer_lifetime_value',
    TemplateType["WEBSITE_WEBSITE"] = "website_website";
})(TemplateType || (exports.TemplateType = TemplateType = {}));
exports.TemplateTypeDef = (0, typebox_1.EnumStrings)(typebox_2.Type.Enum(TemplateType));

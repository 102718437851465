import { FC } from 'react';
import { twMerge } from '../../../utils/twMerge';

interface DividerProps {
  direction?: 'horizontal' | 'vertical';
}

export const Divider: FC<DividerProps> = ({ direction = 'horizontal' }) => {
  return <hr className={twMerge('bg-m-olive-100', direction === 'horizontal' ? 'h-px w-full' : 'h-full w-px')} />;
};

import { FC, useCallback } from 'react';
import { useFilterContext } from '../../context/useFilterContext';
import { FilterItem, useFilterStore } from '../../store';
import { RowContainer } from '../common/RowContainer';
import { PropertyOperatorSelect } from '../common/PropertyOperatorSelect';
import { PropertyValue } from '../common/PropertyValue';
import { Control } from '../common/Control';
import { Group } from '../common/Group';
import { MetadataResourcePropertyType } from '@bigdelta/lib-shared';
import { Float } from '@headlessui-float/react';
import { Popover } from '@headlessui/react';
import { SelectTriggerSmall } from '../../../../components/SelectTriggerSmall';
import { OpenTrigger } from '../../../components/OpenTrigger';
import { useQuery } from '@tanstack/react-query';
import { useWorkspace } from '../../../../features/auth/hooks/useWorkspace';
import { MetadataResourcesPropertiesNamesListData } from '@bigdelta/lib-api-client';
import { Combobox } from '../../../../components/Combobox';
import { PropertyNameObject } from '../../../types';
import { useQueryKeys } from '../../../../features/auth/hooks/useQueryKeys.ts';
import { bigdeltaAPIClient } from '../../../../client/bigdeltaAPIClient.ts';

type PropertyNameObj = MetadataResourcesPropertiesNamesListData['items'][number];

interface MetricsPropertyFilterRowProps {
  index: number;
  filterItem: FilterItem;
  resourceId: string;
}

export const MetricsPropertyFilterRow: FC<MetricsPropertyFilterRowProps> = ({ index, filterItem, resourceId }) => {
  const { currentWorkspaceId } = useWorkspace();
  const { filterKey } = useFilterContext();
  const queryKeys = useQueryKeys();

  const removeItem = useFilterStore((state) => state.removeItem);
  const setItemPropertyOperator = useFilterStore((state) => state.setItemPropertyOperator);
  const setPropertyWithRelationships = useFilterStore((state) => state.setPropertyWithRelationships);

  const metadataPropertyNamesQuery = useQuery({
    queryKey: queryKeys.list('metadata', 'metrics', 'properties', 'names', filterItem.event),
    queryFn: () =>
      bigdeltaAPIClient.v1.metadataResourcesPropertiesNamesList({
        workspace_id: currentWorkspaceId,
        resource_type: MetadataResourcePropertyType.METRIC,
        resource_id: resourceId,
      }),
  });

  const handlePropertyChange = (data: PropertyNameObject | null) => {
    if (data) {
      setPropertyWithRelationships(
        filterKey,
        index,
        {
          attributeId: data.property_id,
          attributeType: data.property_type ?? 'string',
          attributeName: data.property_name,
        },
        []
      );
    }
  };

  const filterCompare = useCallback((item: PropertyNameObj, query: string) => item.property_name.toLocaleLowerCase().includes(query), []);

  return (
    <RowContainer onRemove={() => removeItem(filterKey, index)}>
      <Group>
        <Control>
          <Popover>
            {({ open, close }) => (
              <Float placement="bottom-start" offset={4} portal>
                <Popover.Button as={SelectTriggerSmall} open={open} className="w-full">
                  {filterItem.property?.attributeName ?? 'Select property'}
                  <OpenTrigger shouldOpen={!filterItem.property?.attributeName} />
                </Popover.Button>
                <Popover.Panel>
                  <Combobox
                    items={metadataPropertyNamesQuery.data?.items ?? []}
                    selectedItems={
                      filterItem.property ? { property_id: filterItem.property.attributeId, property_name: filterItem.property.attributeName } : null
                    }
                    onChange={(data) => {
                      handlePropertyChange(data);
                      close();
                    }}
                    catchInputFocus
                    height={380}
                    renderOption={(item) => item.property_name}
                    filterCompare={filterCompare}
                  >
                    {metadataPropertyNamesQuery.isSuccess && metadataPropertyNamesQuery.data?.items.length === 0 && (
                      <div className="flex items-center gap-x-2 whitespace-nowrap bg-m-white px-4 py-3 text-sm font-medium text-m-olive-600">
                        This metric has no properties
                      </div>
                    )}
                  </Combobox>
                </Popover.Panel>
              </Float>
            )}
          </Popover>
        </Control>
      </Group>
      <Group>
        <Control shouldShrink={false}>
          <PropertyOperatorSelect
            index={index}
            value={filterItem.propertyOperator}
            onChange={(index, value) => setItemPropertyOperator(filterKey, index, value)}
          />
        </Control>
        <Control>
          <PropertyValue index={index} resourceType={MetadataResourcePropertyType.METRIC} resourceId={resourceId} />
        </Control>
      </Group>
    </RowContainer>
  );
};

import { Button } from '../../../shared/ui/Button/Button';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isEmpty, orderBy } from 'lodash';
import React, { useState, FC, useEffect } from 'react';

import { useQueryKeys } from '../../auth/hooks/useQueryKeys';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import AlertTriangleIcon from '../../../assets/icons/alert-triangle.svg?react';

import BigQueryIcon from '../../../assets/integrations/Bigquery.svg?react';

import { IntegrationSetupIllustration } from './IntegrationSetupIllustration';
import { IntegrationSetupHeading } from './IntegrationSetupHeading';
import { ErrorResponse, ErrorType, IntegrationStatus, IntegrationType } from '@bigdelta/lib-shared';
import { toastError, toastSuccess } from '../../../utils/toast';
import { Input } from '../../../shared/ui/Input/Input.tsx';
import { AxiosError } from 'axios';
import { CodeSnippet } from '../../../shared/components/CodeSnippet.tsx';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../routes';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

interface BigQueryIntegrationSetupProps {
  close: () => void;
}

export const BigQueryIntegrationSetup: FC<BigQueryIntegrationSetupProps> = ({ close }) => {
  const { currentWorkspaceId } = useWorkspace();

  const queryClient = useQueryClient();
  const queryKeys = useQueryKeys();
  const navigate = useNavigate();

  const [gcpProjectId, setGcpProjectId] = useState('');
  const [location, setLocation] = useState('');
  const [dataset, setDataset] = useState('');
  const [validationErrors, setValidationErrors] = useState(null);

  const { data: bigQueryIntegrationId } = useQuery({
    queryKey: queryKeys.integrations(),
    queryFn: () => bigdeltaAPIClient.v1.integrationsList({ workspace_id: currentWorkspaceId }),
    select: (integrations) => orderBy(integrations, 'created_at', 'desc')?.find((data) => data.type === IntegrationType.BIGQUERY)?.id,
  });

  const bigQueryIntegrationQuery = useQuery({
    queryKey: queryKeys.integration(bigQueryIntegrationId),
    queryFn: () => bigdeltaAPIClient.v1.integrationsBigqueryDetail(bigQueryIntegrationId),
    enabled: !!bigQueryIntegrationId,
  });

  useEffect(() => {
    if (bigQueryIntegrationQuery.data?.configuration?.gcp_project_id) {
      setGcpProjectId(bigQueryIntegrationQuery.data.configuration.gcp_project_id);
    }

    if (bigQueryIntegrationQuery.data?.configuration?.provided_location) {
      setLocation(bigQueryIntegrationQuery.data.configuration.provided_location);
    }

    if (bigQueryIntegrationQuery.data?.configuration?.provided_dataset) {
      setDataset(bigQueryIntegrationQuery.data.configuration.provided_dataset);
    }
  }, [bigQueryIntegrationQuery.data?.configuration]);

  const { data: grantAccessCommands } = useQuery({
    queryKey: [...queryKeys.integration(bigQueryIntegrationId), 'grant_access_commands', gcpProjectId, location, dataset],
    queryFn: () =>
      bigdeltaAPIClient.v1.integrationsBigqueryGrantAccessCommandsDetail(bigQueryIntegrationId, {
        gcp_project_id: gcpProjectId,
        location: location,
        dataset: dataset,
      }),
    select: (response) => response.commands,
    enabled: !!bigQueryIntegrationId,
    keepPreviousData: true,
  });

  const createBigQueryIntegrationMutation = useMutation({
    mutationFn: (projectId: string) =>
      bigdeltaAPIClient.v1.integrationsBigqueryCreate({ workspace_id: currentWorkspaceId }, { gcp_project_id: projectId }),
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.integrations());
    },
    onError: () => {
      toastError('Failed to create BigQuery integration!');
    },
  });

  const enableBigQueryIntegrationMutation = useMutation({
    mutationFn: ({
      integrationId,
      projectId,
      datasetId,
      location,
    }: {
      integrationId: string;
      projectId: string;
      datasetId: string;
      location: string;
    }) =>
      bigdeltaAPIClient.v1.integrationsBigqueryEnablePartialUpdate(integrationId, {
        gcp_project_id: projectId,
        dataset: datasetId,
        location: location,
      }),
    onSuccess: (data) => {
      toastSuccess('BigQuery integration created', '');
      close();
      setValidationErrors([]);
      queryClient.invalidateQueries(queryKeys.integrations()).then(() => navigate(`${AppRoutes.INTEGRATIONS}/${data.id}`));
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      if (error?.response?.data.error_type === ErrorType.VALIDATION_FAILED) {
        setValidationErrors(error?.response?.data?.errors);
      }
    },
    retry: false,
    meta: {
      suppressError: [400],
    },
  });

  const handleContinue = () => {
    if (isEmpty(gcpProjectId)) return;
    createBigQueryIntegrationMutation.mutate(gcpProjectId);
  };

  const handleFinish = () => {
    if (isEmpty(bigQueryIntegrationId)) return;

    enableBigQueryIntegrationMutation.mutate({
      integrationId: bigQueryIntegrationId,
      projectId: gcpProjectId,
      datasetId: dataset,
      location: location,
    });
  };

  return (
    <>
      {!bigQueryIntegrationQuery.isInitialLoading && !bigQueryIntegrationQuery.data && (
        <>
          <div className="flex flex-col items-center gap-y-8 p-6 pb-3">
            <IntegrationSetupIllustration name="BigQuery" icon={BigQueryIcon} />
            <IntegrationSetupHeading heading="Enter your GCP Project ID" />
            <div className="flex w-full flex-col">
              <input
                type="text"
                id="gcp-project-id"
                className="block w-full rounded-md border border-m-gray-300 p-2 text-md"
                placeholder="my-gcp-project-id"
                value={gcpProjectId}
                onChange={(e) => setGcpProjectId(e.target.value)}
              />
            </div>
          </div>

          <hr className="my-8 h-px w-full bg-m-gray-200" />

          <div className="flex w-full justify-end p-6 pt-3">
            <Button
              intent="brand"
              label="Continue"
              onClick={handleContinue}
              disabled={isEmpty(gcpProjectId) || createBigQueryIntegrationMutation.isLoading}
              loading={createBigQueryIntegrationMutation.isLoading}
            />
          </div>
        </>
      )}
      {bigQueryIntegrationQuery.data && bigQueryIntegrationQuery.data.status === IntegrationStatus.DRAFT && (
        <>
          <div className="flex flex-col items-center gap-y-8 p-6 pb-3">
            <IntegrationSetupIllustration name="BigQuery" icon={BigQueryIcon} />
            <IntegrationSetupHeading heading="Grant Permissions" />

            <div className="text-xs">
              Bigdelta has created a service account for your integration. To grant the required permissions for this account, you can either enter
              the name and location of the dataset to be synced and run the following generated commands in your Google Cloud Shell or manually grant
              access to specific datasets and tables directly in BigQuery. After access is granted, please click "Finish".
            </div>

            <div className="flex w-full flex-col gap-y-1.5">
              <label className="text-md font-medium">GCP Project ID:</label>
              <div className="relative">
                <Input className="w-full pr-8" onChange={(e) => setGcpProjectId(e.target.value)} value={gcpProjectId} />
              </div>
              <label className="text-md font-medium">Location:</label>
              <div className="relative">
                <Input
                  className="w-full pr-8"
                  placeholder="dataset-to-be-synced-location"
                  onChange={(e) => setLocation(e.target.value)}
                  value={location}
                />
              </div>
              <label className="text-md font-medium">Dataset:</label>
              <div className="relative">
                <Input className="w-full pr-8" placeholder="dataset-to-be-synced" onChange={(e) => setDataset(e.target.value)} value={dataset} />
              </div>
            </div>

            <div className="flex flex-col items-center gap-y-2">
              {validationErrors?.length > 0 && (
                <div className="mb-4 flex items-center gap-x-2 rounded-md bg-m-red-100 px-2 py-1 text-xs text-m-red-600">
                  <AlertTriangleIcon className="h-4 w-4" />
                  <span>Permissions check failed!</span>
                </div>
              )}
              {grantAccessCommands?.map((i) => (
                <CodeSnippet
                  code={i.command}
                  className={validationErrors?.find((e) => e.message === i.permission_type) ? 'ring-2 ring-m-red-600' : 'ring-0'}
                />
              ))}
            </div>
          </div>

          <hr className="my-4 h-px w-full bg-m-gray-200" />

          <div className="flex w-full justify-end p-6 pt-3">
            <Button
              intent="brand"
              label="Finish"
              onClick={handleFinish}
              disabled={enableBigQueryIntegrationMutation.isLoading}
              loading={enableBigQueryIntegrationMutation.isLoading}
              leadingIcon={validationErrors?.length > 0 ? AlertTriangleIcon : undefined}
            />
          </div>
        </>
      )}
    </>
  );
};

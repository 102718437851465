"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkspaceObjectRecordDef = exports.WorkspaceObjectRecordRemoteIdReferenceDef = void 0;
const timestampedModel_1 = require("./timestampedModel");
const typebox_1 = require("../typebox");
const typebox_2 = require("@sinclair/typebox");
const author_1 = require("./author");
exports.WorkspaceObjectRecordRemoteIdReferenceDef = typebox_2.Type.Object({
    workspaceId: typebox_2.Type.String(),
    workspaceObjectId: typebox_2.Type.String(),
    remoteId: typebox_2.Type.String(),
});
exports.WorkspaceObjectRecordDef = typebox_2.Type.Composite([
    timestampedModel_1.TimestampedModelDef,
    typebox_2.Type.Object({
        id: typebox_2.Type.String({ format: 'uuid' }),
        remoteId: (0, typebox_1.OptionalNullable)(typebox_2.Type.String()),
        accountId: typebox_2.Type.String({ format: 'uuid' }),
        workspaceId: typebox_2.Type.String({ format: 'uuid' }),
        workspaceObjectId: typebox_2.Type.String({ format: 'uuid' }),
        properties: typebox_1.NestedObjectDef,
        version: typebox_2.Type.Number(),
        createdAt: typebox_2.Type.Optional(typebox_2.Type.String({ format: 'date-time' })),
        activeSince: typebox_2.Type.String({ format: 'date-time' }),
        activeUntil: typebox_2.Type.Optional(typebox_2.Type.String({ format: 'date-time' })),
        modifiedBy: (0, typebox_1.OptionalNullable)(author_1.AuthorDef),
    }),
]);

import { createColumnHelper, useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import { ToolsTable } from '../../../shared/components/ToolsTable';

import { suppressConsoleWarn } from '../../../shared/utils/suppressConsoleWarn';
import DeleteIcon from '../../../assets/icons/trash-01.svg?react';
import RefreshIcon from '../../../assets/icons/refresh-ccw-05.svg?react';
import { useAccount } from '../../auth/hooks/useAccount.tsx';
import { useUser } from '../../auth/hooks/useUser.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toastSuccess } from '../../../utils/toast.tsx';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { ActionConfirmationModal } from '../../../shared/components/ActionConfirmationModal.tsx';
import { twMerge } from 'tailwind-merge';
import { UserTableCell } from '../../../shared/ui/UserTableCell/UserTableCell.tsx';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

const columnHelper = createColumnHelper<MemberTableItem>();

export type EntryType = 'member' | 'invitation';

export interface MemberTableItem {
  entryType: EntryType;
  id: string;
  email: string;
}

interface MembersTableProps {
  data?: MemberTableItem[];
}

export const MembersTable: FC<MembersTableProps> = ({ data }) => {
  const { currentAccountId } = useAccount();
  const { user } = useUser();

  const queryClient = useQueryClient();
  const queryKeys = useQueryKeys();

  const [memberToBeRemovedId, setMemberToBeRemovedId] = useState<string | null>(null);
  const [invitationToBeCancelledId, setInvitationToBeCancelledId] = useState<string | null>(null);

  const [resentInvitations, setResentInvitations] = useState<string[]>([]);

  const { mutate: cancelInvitationMutate } = useMutation({
    mutationFn: (invitationId: string) => bigdeltaAPIClient.v1.membersInvitationsCancelCreate({ invitation_id: invitationId }),
    onSuccess: () => {
      toastSuccess('Invitation canceled', '');
      queryClient.invalidateQueries(queryKeys.invitations());
    },
  });

  const { mutate: resendInvitationMutate } = useMutation({
    mutationFn: (invitationId: string) => bigdeltaAPIClient.v1.membersInvitationsResendCreate({ invitation_id: invitationId }),
    onSuccess: (response) => {
      setResentInvitations([...resentInvitations, response.id]);
      toastSuccess('Invitation resent', '');
      queryClient.invalidateQueries(queryKeys.invitations());
    },
  });

  const { mutate: removeMemberFromAccountMutate } = useMutation({
    mutationFn: ({ memberId, accountId }: { memberId: string; accountId: string }) =>
      bigdeltaAPIClient.v1.membersPermissionsDelete(memberId, { account_id: accountId }),
    onSuccess: () => {
      toastSuccess('Member removed', '');
      queryClient.invalidateQueries(queryKeys.members());
    },
  });

  const handleResendInvitation = useCallback(
    (event: MouseEvent<HTMLButtonElement>, invitationId: string) => {
      event.stopPropagation();
      if (resentInvitations.includes(invitationId)) {
        return;
      }
      resendInvitationMutate(invitationId);
    },
    [resendInvitationMutate, resentInvitations]
  );

  const handleStartCancelInvitation = useCallback((event: MouseEvent<HTMLButtonElement>, invitationId: string) => {
    event.stopPropagation();
    setInvitationToBeCancelledId(invitationId);
  }, []);

  const handleConfirmCancelInvitation = useCallback(() => {
    if (!invitationToBeCancelledId) {
      return;
    }
    cancelInvitationMutate(invitationToBeCancelledId);
    setInvitationToBeCancelledId(null);
  }, [cancelInvitationMutate, invitationToBeCancelledId]);

  const handleStartRemoveMemberFromAccount = useCallback((event: MouseEvent<HTMLButtonElement>, memberId: string) => {
    event.stopPropagation();
    setMemberToBeRemovedId(memberId);
  }, []);

  const handleConfirmRemoveMemberFromAccount = useCallback(() => {
    if (!memberToBeRemovedId) {
      return;
    }
    removeMemberFromAccountMutate({ memberId: memberToBeRemovedId, accountId: currentAccountId });
    setMemberToBeRemovedId(null);
  }, [currentAccountId, memberToBeRemovedId, removeMemberFromAccountMutate]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('email', {
        id: 'name',
        header: 'Name',
        cell: (props) => {
          let value;
          suppressConsoleWarn(() => {
            value = props.getValue();
          });

          return <UserTableCell email={value} />;
        },
      }),
      columnHelper.display({
        id: 'actions',
        header: 'Actions',
        size: 200,
        cell: (context) => {
          return (
            <div className="flex items-center gap-x-2">
              {context.row.original.entryType === 'invitation' && (
                <>
                  <span className="text-blue-80 rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium dark:bg-blue-900 dark:text-blue-300">
                    Invite Pending
                  </span>
                  <button onClick={(event) => handleResendInvitation(event, context.row.id)}>
                    <RefreshIcon
                      className={twMerge(
                        'h-5 w-5 cursor-pointer text-m-olive-300 hover:text-m-olive-400',
                        resentInvitations.includes(context.row.id) && 'text-m-olive-200 hover:text-m-olive-200'
                      )}
                    />
                  </button>
                  <button onClick={(event) => handleStartCancelInvitation(event, context.row.id)}>
                    <DeleteIcon className="h-5 w-5 cursor-pointer text-m-red-600 hover:text-m-red-700" />
                  </button>
                </>
              )}
              {context.row.original.entryType === 'member' && context.row.original.id !== user.id && (
                <button onClick={(event) => handleStartRemoveMemberFromAccount(event, context.row.id)}>
                  <DeleteIcon className="h-5 w-5 cursor-pointer text-m-red-600 hover:text-m-red-700" />
                </button>
              )}
            </div>
          );
        },
      }),
    ],
    [resentInvitations, user.id, handleResendInvitation, handleStartCancelInvitation, handleStartRemoveMemberFromAccount]
  );

  const table = useReactTable({
    columns,
    data: data ?? [],
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id,
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
  });

  return (
    <>
      <ToolsTable data={data} table={table} />
      <ActionConfirmationModal
        open={!!memberToBeRemovedId}
        heading="Remove Member"
        message="Are you sure you want to remove this member from this account?"
        onClose={() => setMemberToBeRemovedId(null)}
        onConfirm={handleConfirmRemoveMemberFromAccount}
      />
      <ActionConfirmationModal
        open={!!invitationToBeCancelledId}
        heading="Cancel invitation"
        message="Are you sure you want to cancel this invitation?"
        onClose={() => setInvitationToBeCancelledId(null)}
        onConfirm={handleConfirmCancelInvitation}
      />
    </>
  );
};

import { useContext } from 'react';
import { FilterContext } from './context';

export const useFilterContext = () => {
  const filterContext = useContext(FilterContext);
  if (!filterContext) {
    throw new Error('No FilterContext.Provider found when calling useFilterContext.');
  }
  return filterContext;
};

import { getAuthenticationToken } from '../shared/utils/authToken.ts';
import { createBigdeltaAPIClient } from '@bigdelta/lib-api-client';

const bigdeltaAPIClient = createBigdeltaAPIClient({
  baseURL: import.meta.env.VITE_APP_API_BASE_URL,
  notFoundAsNull: false,
});

bigdeltaAPIClient.instance.interceptors.request.use(
  (config) => {
    const token = getAuthenticationToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export { bigdeltaAPIClient };

import { ObjectsListData, RelationshipColumnDef, RelationshipsListData } from '@bigdelta/lib-api-client';
import { DataQueryRelationshipPropertiesVO, RelationshipEntityType, TableColumnType } from '@bigdelta/lib-shared';
import { MET_REMOTE_ID } from '../../../features/records/const';

const removeDuplicates = <T extends Record<K, unknown>, K extends PropertyKey>(arr: T[], prop: K) => {
  const seen = new Set();
  return arr.filter((item) => {
    const value = item[prop];
    return seen.has(value) ? false : seen.add(value);
  });
};

export const mapToDataQueryRelationshipProperties = (
  relationshipProperties: DataQueryRelationshipPropertiesVO[] | null | undefined,
  columnRelationship: RelationshipColumnDef | null | undefined,
  relationshipsList: RelationshipsListData['relationships'],
  entityType: RelationshipEntityType,
  entityId: string | undefined,
  objectsList: ObjectsListData['objects']
): DataQueryRelationshipPropertiesVO[] => {
  const currentRelationships = relationshipProperties || [];

  const updateExistingRelationship = (
    relationships: DataQueryRelationshipPropertiesVO[],
    index: number,
    propertyName: string,
    objectId: string
  ): DataQueryRelationshipPropertiesVO[] => {
    const objectLabelProperties = objectsList.find((o) => o.id === objectId)?.label_properties;
    const objectIconProperty = objectsList.find((o) => o.id === objectId)?.icon_property;

    return relationships.map((r, i) => {
      if (i === index) {
        return {
          ...r,
          properties: removeDuplicates(
            [
              ...(r.properties || []),
              { name: propertyName },
              ...(objectLabelProperties?.map((name) => ({ name })) ?? [{ name: MET_REMOTE_ID }]),
              ...(objectIconProperty ? [{ name: objectIconProperty }] : []),
            ],
            'name'
          ),
        };
      }
      return r;
    });
  };

  const addNewRelationship = (
    relationships: DataQueryRelationshipPropertiesVO[],
    relationshipName: string,
    propertyName: string,
    objectId: string
  ): DataQueryRelationshipPropertiesVO[] => {
    const objectLabelProperties = objectsList.find((o) => o.id === objectId)?.label_properties;
    const objectIconProperty = objectsList.find((o) => o.id === objectId)?.icon_property;

    return [
      ...relationships,
      {
        relationship_name: relationshipName,
        relationship_properties: undefined,
        properties: removeDuplicates(
          [
            { name: propertyName },
            ...(objectLabelProperties?.map((name) => ({ name })) ?? [{ name: MET_REMOTE_ID }]),
            ...(objectIconProperty ? [{ name: objectIconProperty }] : []),
          ],
          'name'
        ),
      },
    ];
  };

  const updateNestedRelationship = (
    relationships: DataQueryRelationshipPropertiesVO[],
    index: number,
    columnRelationship: RelationshipColumnDef,
    entityType: RelationshipEntityType,
    entityId: string | undefined
  ): DataQueryRelationshipPropertiesVO[] => {
    return relationships.map((r, i) => {
      if (i === index) {
        return {
          ...r,
          relationship_properties: mapToDataQueryRelationshipProperties(
            r.relationship_properties,
            columnRelationship.relationship,
            relationshipsList,
            entityType,
            entityId,
            objectsList
          ),
        };
      }
      return r;
    });
  };

  const addNestedRelationship = (
    relationships: DataQueryRelationshipPropertiesVO[],
    relationshipName: string,
    columnRelationship: RelationshipColumnDef,
    entityType: RelationshipEntityType,
    entityId: string | undefined
  ): DataQueryRelationshipPropertiesVO[] => {
    const nestedProperties = mapToDataQueryRelationshipProperties(
      null,
      columnRelationship.relationship,
      relationshipsList,
      entityType,
      entityId,
      objectsList
    );
    return [
      ...relationships,
      {
        relationship_name: relationshipName,
        relationship_properties: nestedProperties,
        properties: [],
      },
    ];
  };

  const handlePropertyType = (
    relationships: DataQueryRelationshipPropertiesVO[],
    columnRelationship: RelationshipColumnDef,
    objectId: string
  ): DataQueryRelationshipPropertiesVO[] => {
    const columnRelationshipName = columnRelationship.name;
    const propertyName = columnRelationship.property?.property_name;

    if (!propertyName) {
      return relationships;
    }

    const existingRelationshipIndex = relationships.findIndex((r) => r.relationship_name === columnRelationshipName);

    if (existingRelationshipIndex !== -1) {
      return updateExistingRelationship(relationships, existingRelationshipIndex, propertyName, objectId);
    } else {
      return addNewRelationship(relationships, columnRelationshipName, propertyName, objectId);
    }
  };

  const handleRelationshipType = (
    relationships: DataQueryRelationshipPropertiesVO[],
    columnRelationship: RelationshipColumnDef,
    entityType: RelationshipEntityType,
    entityId: string | undefined
  ): DataQueryRelationshipPropertiesVO[] => {
    const columnRelationshipName = columnRelationship.name;
    const existingRelationshipIndex = relationships.findIndex((r) => r.relationship_name === columnRelationshipName);

    if (existingRelationshipIndex !== -1) {
      return updateNestedRelationship(relationships, existingRelationshipIndex, columnRelationship, entityType, entityId);
    } else {
      return addNestedRelationship(relationships, columnRelationshipName, columnRelationship, entityType, entityId);
    }
  };

  let relatedEntityId: string | undefined;
  let relatedEntityType: RelationshipEntityType | undefined;
  const rel = relationshipsList.find((r) => r.name === columnRelationship?.name);

  if (rel) {
    if ((rel.first_entity_id || undefined) === entityId && rel.first_entity_type === entityType) {
      relatedEntityId = rel.second_entity_id;
      relatedEntityType = rel.second_entity_type as RelationshipEntityType;
    }
    if ((rel.second_entity_id || undefined) === entityId && rel.second_entity_type === entityType) {
      relatedEntityId = rel.first_entity_id;
      relatedEntityType = rel.first_entity_type as RelationshipEntityType;
    }
  }

  if (!relatedEntityType) {
    throw Error('Related entity type not found');
  }

  if (columnRelationship?.type === TableColumnType.PROPERTY) {
    if (!relatedEntityId) {
      throw Error('Related entity id not found');
    }

    return handlePropertyType(currentRelationships, columnRelationship, relatedEntityId);
  } else if (columnRelationship?.type === TableColumnType.RELATIONSHIP) {
    return handleRelationshipType(currentRelationships, columnRelationship, relatedEntityType, relatedEntityId);
  }

  return currentRelationships;
};

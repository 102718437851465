import { ObjectsDetailData, ObjectsRecordDetailData } from "@bigdelta/lib-api-client";
import { getWorkspaceObjectIcon } from "../../../shared/utils/getWorkspaceObjectIcon";

interface RecordNameProps {
    record: ObjectsRecordDetailData;
    object: ObjectsDetailData;
}

export const RecordName = ({ object, record }: RecordNameProps) => {
    const name = object.label_properties.map((property) => record.properties[property]).find(Boolean) as string;
    const logo = record.properties?.[object.icon_property] as string;
    const FallbackIcon = getWorkspaceObjectIcon(object.object_type);
    const fallbackValue = record.id;

    return (
    <div className="flex flex-row justify-start items-center mb-6">
        <div className="flex justify-start items-center mr-4 h-16">
            {
                logo
                    ? <img className="w-auto max-h-full" src={logo} alt={`${name} logo`} />
                    : <FallbackIcon className="inline-block h-4 w-4 mr-4 shrink-0" />
            }
        </div>
        <h2 className="text-xl border-m-blue-400 text-m-black">{name || fallbackValue}</h2>

    </div>
    );
}

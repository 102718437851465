"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateTrackedEventVODef = void 0;
const typebox_1 = require("../typebox");
const typebox_2 = require("@sinclair/typebox");
const model_1 = require("../model");
exports.CreateTrackedEventVODef = typebox_2.Type.Object({
    accountId: typebox_2.Type.String(),
    workspaceId: typebox_2.Type.String(),
    email: (0, typebox_1.OptionalNullable)(typebox_2.Type.String()),
    eventName: typebox_2.Type.String(),
    properties: (0, typebox_1.OptionalNullable)(typebox_1.NestedObjectDef),
    relations: (0, typebox_1.OptionalNullable)(model_1.EventRelationsDef),
    createdAt: (0, typebox_1.OptionalNullable)(typebox_2.Type.String({ format: 'date-time' })),
    trackIpAndGeolocation: typebox_2.Type.Optional(typebox_2.Type.Boolean()),
    ip: typebox_2.Type.Optional(typebox_2.Type.String()),
});

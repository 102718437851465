import { ComboboxInfinite } from '../../../components/Combobox/ComboboxInfinite';
import { useMetadataPropertiesNamesInfiniteQuery } from '../../../shared/data/useMetadataAttributeNamesInfiniteQuery';
import { ReportSourceType, useReportsStore } from '../store';
import { MetadataResourcePropertyType } from '@bigdelta/lib-shared';
import { ChangeEvent, FC, useMemo, useState } from 'react';
import { useDebounce } from '@uidotdev/usehooks';
import { PropertyNameObject } from '../../../shared/types.ts';
import { ALL_EVENTS } from '../../../shared/filters/store';

interface ReportCreateBreakdownPropertyProps {
  workspaceId: string;
  onChange?: (data: PropertyNameObject | null) => void;
}

export const ReportCreateBreakdownProperty: FC<ReportCreateBreakdownPropertyProps> = ({ workspaceId, onChange }) => {
  const [rawSearchQuery, setRawSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(rawSearchQuery, 700);

  const metricType = useReportsStore((state) => state.config.metricType);
  const metricEvent = useReportsStore((state) => state.config.metricEvent);
  const metricMetric = useReportsStore((state) => state.config.metricMetric);
  const metricObject = useReportsStore((state) => state.config.metricObject);

  const breakdownProperty = useReportsStore((state) => state.config.breakdownProperty);

  const propertiesQueryParams = useMemo(() => {
    if (metricType === ReportSourceType.EVENTS) {
      return {
        workspaceId: workspaceId,
        resourceType: MetadataResourcePropertyType.EVENT as const,
        objectId: metricEvent === ALL_EVENTS ? undefined : metricEvent,
        searchQuery: debouncedSearchQuery,
      };
    }
    if (metricType === ReportSourceType.RECORDS && metricObject) {
      return {
        workspaceId: workspaceId,
        resourceType: MetadataResourcePropertyType.OBJECT as const,
        objectId: metricObject,
        searchQuery: debouncedSearchQuery,
      };
    }
    if (metricType === ReportSourceType.METRIC && metricMetric)
      return {
        workspaceId: workspaceId,
        resourceType: MetadataResourcePropertyType.METRIC as const,
        objectId: metricMetric,
        searchQuery: debouncedSearchQuery,
      };

    return {
      workspaceId: workspaceId,
      resourceType: MetadataResourcePropertyType.EVENT as const,
      searchQuery: debouncedSearchQuery,
    };
  }, [debouncedSearchQuery, metricEvent, metricMetric, metricObject, metricType, workspaceId]);

  const propertiesQuery = useMetadataPropertiesNamesInfiniteQuery(propertiesQueryParams);

  const flatProperties = useMemo(() => (propertiesQuery.data ? [...propertiesQuery.data.pages.flatMap((d) => d.items)] : []), [propertiesQuery]);

  return (
    <ComboboxInfinite
      items={flatProperties}
      selectedItems={breakdownProperty}
      onChange={(data) => onChange && onChange(data)}
      renderOption={(property) => property.property_name}
      height={400}
      hasNextPage={!!propertiesQuery.hasNextPage}
      fetchNextPage={propertiesQuery.fetchNextPage}
      isFetching={propertiesQuery.isFetching}
      isSuccess={propertiesQuery.isSuccess}
      isFetchingNextPage={propertiesQuery.isFetchingNextPage}
      onQueryChange={(e: ChangeEvent<HTMLInputElement>) => setRawSearchQuery(e.target.value)}
      query={rawSearchQuery}
    />
  );
};

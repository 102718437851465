"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackedEventResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const model_1 = require("../model");
exports.TrackedEventResponseDef = typebox_1.Type.Object({
    id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    event_name: typebox_1.Type.String(),
    properties: (0, typebox_2.OptionalNullable)(typebox_2.NestedObjectDef),
    relations: (0, typebox_2.OptionalNullable)(model_1.EventRelationsDef),
    created_at: typebox_1.Type.String({ format: 'date-time' }),
});

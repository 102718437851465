import { EventsQueryRequestQueryDef, ObjectsListData, RelationshipsListData, ReportsDetailData } from '@bigdelta/lib-api-client';
import { Filter, FilterItemType } from '../../../../../shared/filters/store';
import { TrendsBuilderInitialData } from '../../../store/TrendsBuilder';
import { getReportStateFromSavedReport } from '../getReportStateFromSavedReport';
import { getTrendsBuilderStateFromSavedReport } from '../getTrendsBuilderStateFromSavedReport';
import { getRecordFilterItemsFromSegment } from '../../../../../shared/utils/getRecordFilterItemsFromSegment';
import { EventQueryFiltersCondition, getEventFilterItemsFromSegment } from '../../../../../shared/utils/getEventFilterItemsFromSegment';

export const getEventCountPropertiesFilterItems = (
  metric: NonNullable<ReportsDetailData['query']>['metrics'][number],
  relationships: RelationshipsListData['relationships']
) => {
  const defaultFilter = metric.events?.filter?.conditions[0];

  if (!defaultFilter || !('conditions' in defaultFilter)) {
    return;
  }
  let eventCountFilterConditions: (EventQueryFiltersCondition | EventsQueryRequestQueryDef)[] | null = null;

  const eventCountFilter = defaultFilter.conditions[2];
  if (eventCountFilter && 'conditions' in eventCountFilter) {
    eventCountFilterConditions = eventCountFilter.conditions.flatMap((condition) => (condition ? [condition] : []));
  }

  return getEventFilterItemsFromSegment(eventCountFilterConditions, relationships).map((item) => ({
    ...item,
    itemType: FilterItemType.TRENDS_COUNT_EVENT_PROPERTY,
  }));
};

// TODO: split into multiple functions to improve readability
export const transformSavedReportToState = (
  savedReportData: ReportsDetailData,
  workspaceId: string,
  relationships: RelationshipsListData['relationships'],
  objects: ObjectsListData['objects']
) => {
  const builderFilterMap: Record<string, Filter> = {};
  const builders: TrendsBuilderInitialData[] = [];

  const reportData = getReportStateFromSavedReport(savedReportData);
  if (reportData) {
    savedReportData.query?.metrics
      .map((metric) => {
        return {
          builder: getTrendsBuilderStateFromSavedReport(metric, workspaceId, relationships, objects),
          metric,
        };
      })
      .flatMap(({ builder, metric }) => (builder ? { builder, metric } : []))
      .forEach(({ builder, metric }) => {
        if (metric.records?.filter?.conditions && builder.data.object?.workspaceObjectId) {
          const operator = metric.records.filter.operator;

          const filterItems = getRecordFilterItemsFromSegment(
            metric.records?.filter?.conditions,
            builder.data.object?.workspaceObjectId,
            relationships
          );

          builderFilterMap[builder.id] = { operator, items: filterItems };
        }

        if (metric.events?.filter?.conditions && builder.data.object?.workspaceObjectId) {
          const recordFilter = metric.events.filter.conditions[1];

          if (!recordFilter || !('related_records' in recordFilter)) {
            return;
          }

          if (!recordFilter.related_records?.filter?.conditions) {
            return;
          }

          const operator = recordFilter.related_records?.filter.operator;

          const filterItems = getRecordFilterItemsFromSegment(
            recordFilter.related_records.filter.conditions,
            builder.data.object?.workspaceObjectId,
            relationships
          );

          const eventCountFilterItems = getEventCountPropertiesFilterItems(metric, relationships) ?? [];

          builderFilterMap[builder.id] = { operator, items: [...filterItems, ...eventCountFilterItems] };
        }
        builders.push(builder);
      });
  }

  return { reportData, builderFilterMap, builders };
};

import { SidePanel, SidePanelFooterWrapper } from '../../../shared/components/SidePanel.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useMemo, useRef } from 'react';
import { DashboardsRoutes } from '../../../routes';
import { CenteredLoader } from '../../../shared/components/CenteredLoader.tsx';
import { Button } from '../../../shared/ui/Button/Button.tsx';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DashboardsCreatePayload } from '@bigdelta/lib-api-client';

import { toastError, toastSuccess } from '../../../utils/toast.tsx';
import { useWorkspace } from '../../auth/hooks/useWorkspace.tsx';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { useOutsideClickHandler } from '../../../shared/hooks/useOutsideClickHandler.ts';
import { DashboardProfileForm } from './DashboardProfileForm.tsx';
import { DashboardFormSchema } from './DashboardFormSchema.ts';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

export const EditDashboardPage = () => {
  const { dashboardId } = useParams();
  const { currentWorkspaceId } = useWorkspace();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const queryKeys = useQueryKeys();

  const dashboardQuery = useQuery({
    queryKey: ['dashboard', dashboardId],
    queryFn: () => bigdeltaAPIClient.v1.dashboardsDetail(dashboardId || ''),
  });

  const { isSuccess, isInitialLoading } = dashboardQuery;

  const defaultValues: DashboardFormSchema = useMemo(() => {
    if (!isSuccess) {
      return null;
    }

    const dashboard = dashboardQuery.data;

    return {
      name: dashboard.name,
      description: dashboard.description,
      owner: dashboard.owner?.id,
    };
  }, [isSuccess, dashboardQuery.data]);

  const updateDashboardMutation = useMutation({
    mutationFn: ({ dashboardId, payload }: { dashboardId: string; payload: DashboardsCreatePayload }) =>
      bigdeltaAPIClient.v1.dashboardsUpdate(dashboardId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.dashboards());
      queryClient.invalidateQueries(['dashboard', dashboardId]);
      toastSuccess('Updated', 'Dashboard updated successfully');
      navigate(DashboardsRoutes.INDEX);
    },
    onError: () => {
      toastError('An error occurred while updating the dashboard');
    },
  });

  const onSubmit = useCallback(
    (data: DashboardFormSchema) => {
      updateDashboardMutation.mutate({
        dashboardId: dashboardId,
        payload: {
          name: data.name,
          description: data.description,
          owner: data.owner,
          workspace_id: currentWorkspaceId,
        },
      });
    },
    [currentWorkspaceId, dashboardId, updateDashboardMutation]
  );

  const sidePanelRef = useRef();
  useOutsideClickHandler(sidePanelRef, () => {
    navigate(DashboardsRoutes.INDEX);
  });

  return (
    <SidePanel ref={sidePanelRef} title="Edit Dashboard" onClose={() => navigate(DashboardsRoutes.INDEX)}>
      {!isInitialLoading && (
        <DashboardProfileForm
          defaultValues={defaultValues}
          renderSubmitButton={(handleSubmit) => (
            <SidePanelFooterWrapper>
              <Button label="Save changes" intent="brand" fullWidth onClick={handleSubmit(onSubmit)} />
            </SidePanelFooterWrapper>
          )}
        />
      )}
      {isInitialLoading && <CenteredLoader />}
    </SidePanel>
  );
};

import { FC } from 'react';
import { useReportStore, useTrendsBuilderStore } from '../../store/Report';
import * as TrendsBuilderBase from '../common/ReportBuilderBase';
import { FormulaInput } from './FormulaInput';
import { Descendant } from 'slate';
import { getParsedFormulaData } from '../../utils/trends/getParsedFormulaData';

interface TrendsBuilderFormulaProps {
  id: string;
  onCreateMetric: () => void;
  onUpdateMetric: () => void;
  onRename: () => void;
}

export const TrendsBuilderFormula: FC<TrendsBuilderFormulaProps> = ({ id, onCreateMetric, onUpdateMetric, onRename }) => {
  const removeTrendsBuilder = useReportStore((state) => state.removeTrendsBuilder);

  const name = useTrendsBuilderStore(id, (state) => state.name);
  const label = useTrendsBuilderStore(id, (state) => state.label);
  const metricId = useTrendsBuilderStore(id, (state) => state.metricId);

  const builders = useReportStore((state) => state.report.trends.builders);

  const setFormulaExpressionRaw = useTrendsBuilderStore(id, (state) => state.setFormulaExpressionRaw);
  const setFormulaExpressionError = useTrendsBuilderStore(id, (state) => state.setFormulaExpressionError);

  const expressionRawOriginal = useTrendsBuilderStore(id, (state) => state.data.formula?.expressionRawOriginal);
  const expressionError = useTrendsBuilderStore(id, (state) => state.data.formula?.expressionError);

  const handleContextAction = (action: TrendsBuilderBase.ContextMenuAction) => {
    switch (action) {
      case TrendsBuilderBase.ContextMenuAction.SAVE_AS_METRIC:
        onCreateMetric();
        break;
      case TrendsBuilderBase.ContextMenuAction.UPDATE_METRIC:
        onUpdateMetric();
        break;
      case TrendsBuilderBase.ContextMenuAction.RENAME:
        onRename();
        break;
      case TrendsBuilderBase.ContextMenuAction.REMOVE:
        removeTrendsBuilder(id);
        break;
      default:
        break;
    }
  };

  const handleFormulaChange = (value: Descendant[]) => {
    // TODO: debounce here

    const formulaValidationData = getParsedFormulaData(value, name, builders);

    if (formulaValidationData?.error) {
      setFormulaExpressionError(formulaValidationData.error);
      return;
    }

    setFormulaExpressionError();
    setFormulaExpressionRaw(value);
  };

  return (
    <TrendsBuilderBase.Container storeSelector={useTrendsBuilderStore}>
      <div className="flex flex-col gap-y-5">
        <div className="flex items-center justify-between gap-x-3">
          <TrendsBuilderBase.Heading name={name} label={label} id={id} />
          <div className="flex items-center gap-x-0.5">
            <TrendsBuilderBase.Remove onClick={() => removeTrendsBuilder(id)} />
            <TrendsBuilderBase.ContextMenu onSelect={handleContextAction} metricId={metricId} />
          </div>
        </div>
        <div className="flex flex-col gap-y-2">
          <FormulaInput onChange={handleFormulaChange} initialValue={expressionRawOriginal} isError={!!expressionError} />
          {expressionError && <div className="text-sm text-m-red-600">{expressionError}</div>}
        </div>
      </div>
    </TrendsBuilderBase.Container>
  );
};

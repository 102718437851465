import { MetricsListData, ReportsDetailData } from '@bigdelta/lib-api-client';
import { TrendsBuilderStateData, TrendsBuilderType } from '../../../store/TrendsBuilder';
import { v4 as uuid } from 'uuid';
import { MathNode, isConstantNode, isOperatorNode, isSymbolNode, parse as mathParse } from 'mathjs';
import { VariableInputElementType } from '../../../../../shared/types';
import { Text } from 'slate';
import { CustomElement } from '../../../../../shared/slate';

export const getBuilderFromFormulaMetric = (
  queryMetric: NonNullable<ReportsDetailData['query']>['metrics'][number]
): TrendsBuilderStateData | undefined => {
  if (!queryMetric.formula || !queryMetric.name) {
    return;
  }

  const parse = (mathNode: MathNode): string[] => {
    if (isOperatorNode(mathNode)) {
      return [parse(mathNode.args[0]), mathNode.op as string, parse(mathNode.args[1])].flat();
    }

    if (isConstantNode(mathNode)) {
      return [mathNode.value.toString()];
    }

    if (isSymbolNode(mathNode)) {
      return [mathNode.name as string];
    }

    throw new Error('Unknown node type: ' + mathNode.type);
  };

  const node = mathParse(queryMetric.formula.expression);

  const expressionArray = parse(node);

  const editorElements: Array<CustomElement | Text> = expressionArray
    .map((expressionItem) => {
      if (expressionItem.startsWith('SAVED_')) {
        const metricId = queryMetric.formula?.metrics.find((metric) => metric.name === expressionItem)?.metric?.resource.id;

        if (!metricId) {
          return;
        }

        return {
          type: VariableInputElementType.Metric as const,
          metric: {
            id: metricId,
          } as MetricsListData['items'][number],
          hasData: false,
          children: [
            {
              text: '',
            },
          ],
        };
      } else {
        return {
          text: expressionItem,
        };
      }
    })
    .flatMap((expressionItem) => (expressionItem ? [expressionItem] : []));

  return {
    id: uuid(),
    type: TrendsBuilderType.FORMULA,
    name: queryMetric.name,
    label: queryMetric.query_name ?? undefined,
    data: {
      [TrendsBuilderType.FORMULA]: {
        expressionRawOriginal: [
          {
            type: VariableInputElementType.Paragraph,
            children: [{ text: '' }, ...editorElements, { text: '' }],
          },
        ],
        expressionRaw: [
          {
            type: VariableInputElementType.Paragraph,
            children: [{ text: '' }, ...editorElements, { text: '' }],
          },
        ],
      },
    },
  };
};

import { EventsQueryRequestQueryDef, RelationshipsListData } from '@bigdelta/lib-api-client';
import { FilterItem, FilterItemType } from '../filters/store';
import { getEventNameFilterFromCondition } from './getEventNameFilterFromCondition';
import { getEventPropertyFilterFromCondition } from './getEventPropertyFilterFromCondition';
import { RelationshipEntityType } from '@bigdelta/lib-shared';
import { getRelatedRecordFilterFromCondition } from './getRelatedRecordFilterFromCondition';
import { toastError } from '../../utils/toast';

export type EventQueryFiltersCondition = Exclude<EventsQueryRequestQueryDef['conditions'][number], EventsQueryRequestQueryDef>;

export const getEventFilterItemsFromSegment = (
  conditions: (EventQueryFiltersCondition | EventsQueryRequestQueryDef)[] | null,
  relationships: RelationshipsListData['relationships'],
  shouldCreateEventNameItem: boolean = true
): FilterItem[] => {
  if (!conditions) {
    return [];
  }

  return conditions.flatMap((condition) => {
    let filterItem: FilterItem | undefined = undefined;

    if (!condition) {
      return [];
    }

    if ('event_name' in condition && shouldCreateEventNameItem) {
      filterItem = getEventNameFilterFromCondition(condition);
    }

    if ('event_property' in condition) {
      filterItem = getEventPropertyFilterFromCondition(condition);
    }

    if ('related_records' in condition) {
      try {
        filterItem = getRelatedRecordFilterFromCondition(
          condition,
          relationships,
          FilterItemType.EVENTS_RECORD_PROPERTY,
          RelationshipEntityType.EVENT
        );
      } catch (e: any) {
        toastError(e.message);
      }
    }

    return filterItem ? [filterItem] : [];
  });
};

import { Combobox as ComboboxPrimitive, ComboboxInputProps } from '@headlessui/react';
import { ChangeEvent, HTMLAttributes, useCallback, useEffect, useRef } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { ComboboxInput } from './ComboboxInput';
import { ComboboxOptions } from './ComboboxOptions';
import { ComboboxInfiniteItem } from './ComboboxInfiniteItem';
import { twMerge } from 'tailwind-merge';

type ComboboxInfiniteProps<T> = HTMLAttributes<HTMLElement> & {
  items: T[];
  selectedItems?: T | null;
  onChange: (data: T | null) => void;
  catchInputFocus?: boolean;
  height: number;
  by?: (a: any, z: any) => boolean;
  renderOption: (option: T) => React.ReactNode;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
  onQueryChange: (e: ChangeEvent<HTMLInputElement>) => void;
  query: string;
  isFetching: boolean;
  isSuccess: boolean;
};

export const ComboboxInfinite = <T extends Record<string, string | null | undefined>>({
  items,
  selectedItems,
  onChange,
  catchInputFocus = false,
  height,
  by,
  renderOption,
  hasNextPage,
  isFetchingNextPage,
  isFetching,
  fetchNextPage,
  onQueryChange,
  query,
  isSuccess,
  className,
}: ComboboxInfiniteProps<T>) => {
  const parentRef = useRef<HTMLUListElement | null>(null);

  const getCount = () => {
    switch (true) {
      case hasNextPage:
      case !items.length && isFetching:
      case !items.length && isSuccess:
        return items.length + 1;
      default:
        return items.length;
    }
  };

  const rowVirtualizer = useVirtualizer({
    count: getCount(),
    getScrollElement: () => parentRef?.current,
    estimateSize: () => 44,
  });

  const virtualItems = rowVirtualizer.getVirtualItems();

  useEffect(() => {
    const [lastItem] = [...virtualItems].reverse();

    if (!lastItem) {
      return;
    }

    if (lastItem.index >= items.length - 1 && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, items.length, isFetchingNextPage, rowVirtualizer, virtualItems]);

  const onRefChange = useCallback(
    (node: HTMLInputElement) => {
      if (catchInputFocus) {
        setTimeout(() => {
          node?.focus();
        }, 0);
      }
    },
    [catchInputFocus]
  );

  return (
    <ComboboxPrimitive value={selectedItems} by={by} onChange={(data) => onChange(data)}>
      <div className={twMerge('flex flex-col overflow-hidden rounded-lg border border-m-gray-300 p-2', className)}>
        <ComboboxInput onChange={onQueryChange} value={query} ref={onRefChange} />
        <ComboboxOptions virtualHeight={rowVirtualizer.getTotalSize()} maxHeight={height} ref={parentRef} className="mt-1">
          {rowVirtualizer.getVirtualItems().map(({ index, key, size, start }) => {
            const isLoaderRow = index > items.length - 1;
            const item = items[index];

            return (
              <ComboboxPrimitive.Option
                key={key}
                value={item}
                className="absolute left-0 top-0 w-full cursor-pointer rounded-md ui-active:bg-m-gray-200"
                data-index={index}
                ref={rowVirtualizer.measureElement}
                style={{
                  height: `${size}px`,
                  transform: `translateY(${start}px)`,
                }}
              >
                <ComboboxInfiniteItem
                  item={item}
                  isLoaderRow={isLoaderRow}
                  hasNextPage={hasNextPage}
                  itemCount={items.length}
                  isFetching={isFetching}
                  isSuccess={isSuccess}
                  renderOption={renderOption}
                />
              </ComboboxPrimitive.Option>
            );
          })}
        </ComboboxOptions>
      </div>
    </ComboboxPrimitive>
  );
};

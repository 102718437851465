"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateAutomationRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const automation_1 = require("../model/automation");
exports.UpdateAutomationRequestDef = typebox_1.Type.Omit(automation_1.AutomationDef, [
    'id',
    'workspace_id',
    'version',
    'systemCreatedAt',
    'systemUpdatedAt',
    'created_by',
    'updated_by',
    'updated_at',
    'deleted_at',
    'enabled',
]);

import { HTMLAttributes } from 'react';
import { ResourcePropertyType } from '@bigdelta/lib-shared';

import BooleanIcon from '../../assets/icons/check.svg?react';
import ListIcon from '../../assets/icons/list.svg?react';
import TextIcon from '../../assets/icons/letter-spacing-01.svg?react';
import ObjectIcon from '../../assets/icons/cube-01.svg?react';
import CalendarIcon from '../../assets/icons/calendar.svg?react';

type RecordPropertyProps = HTMLAttributes<SVGElement> & {
  propertyType?: string | null;
};

export const RecordPropertyIcon = ({ propertyType, ...props }: RecordPropertyProps) => {
  const type = propertyType as ResourcePropertyType;
  if (type === ResourcePropertyType.BOOLEAN) {
    return <BooleanIcon {...props} />;
  }

  if (type === ResourcePropertyType.STRING) {
    return <TextIcon {...props} />;
  }

  if (type === ResourcePropertyType.OBJECT) {
    return <ObjectIcon {...props} />;
  }

  if (type === ResourcePropertyType.DATETIME64) {
    return <CalendarIcon {...props} />;
  }

  return <ListIcon {...props} />;
};

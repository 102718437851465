import { v4 as uuid } from 'uuid';

import { MetricMath } from '@bigdelta/lib-shared';
import { TrendsBuilderStateData, TrendsBuilderType } from '../../../store/TrendsBuilder';
import { ObjectsListData, RelationshipsListData, ReportsDetailData } from '@bigdelta/lib-api-client';
import { ObjectCount, ObjectCountType, RecordCountData } from '../../../store/common';

const getTopLevelRecordPropertyCountData = (
  propertyName: string,
  propertyType: string | undefined,
  objectId: string,
  objectWorkspaceId: string
): RecordCountData['data'] => {
  return {
    property: {
      property_name: propertyName,
      property_id: propertyName,
      property_type: propertyType,
    },
    relationships: [
      {
        objectId,
        objectWorkspaceId,
        relationshipName: null,
      },
    ],
  };
};

const getRelatedRecordPropertyCountData = (
  propertyName: string,
  propertyType: string | undefined,
  objectId: string,
  objectWorkspaceId: string,
  relationshipName: string,
  relatedObject: ObjectsListData['objects'][number]
): RecordCountData['data'] => {
  return {
    property: {
      property_name: propertyName,
      property_id: propertyName,
      property_type: propertyType,
    },
    relationships: [
      {
        objectId,
        objectWorkspaceId,
        relationshipName: null,
      },
      {
        objectId: relatedObject.id,
        objectWorkspaceId: relatedObject.workspace_id,
        relationshipName,
      },
    ],
  };
};

export const getBuilderFromRecordMetric = (
  queryMetric: NonNullable<ReportsDetailData['query']>['metrics'][number],
  workspaceId: string,
  relationships: RelationshipsListData['relationships'],
  objects: ObjectsListData['objects']
): TrendsBuilderStateData | undefined => {
  if (!queryMetric.records) {
    return;
  }

  let count: ObjectCount;

  if (queryMetric.records.math === MetricMath.TOTAL) {
    count = {
      aggregate: MetricMath.TOTAL,
      type: ObjectCountType.RECORD,
      record: {
        type: 'total',
        data: {},
      },
    };
  }

  if (
    [MetricMath.DISTINCT_TOTAL, MetricMath.SUM, MetricMath.AVERAGE, MetricMath.MEDIAN, MetricMath.MAX, MetricMath.MIN].includes(
      queryMetric.records.math as MetricMath
    ) &&
    queryMetric.records.math_target?.record_property
  ) {
    const propertyName = queryMetric.records.math_target?.record_property?.name;

    if (!propertyName) {
      return;
    }

    count = {
      aggregate: queryMetric.records.math as MetricMath,
      type: ObjectCountType.RECORD,
      record: {
        type: 'property',
        data: getTopLevelRecordPropertyCountData(
          propertyName,
          queryMetric.records.math_target?.record_property?.property_type,
          queryMetric.records.resource.id,
          workspaceId
        ),
      },
    };
  }

  if (
    [MetricMath.DISTINCT_TOTAL, MetricMath.SUM, MetricMath.AVERAGE, MetricMath.MEDIAN, MetricMath.MAX, MetricMath.MIN].includes(
      queryMetric.records.math as MetricMath
    ) &&
    queryMetric.records.math_target?.related_records
  ) {
    const propertyName = queryMetric.records.math_target?.related_records.property_name;
    const propertyType = queryMetric.records.math_target?.related_records.property_type;
    const relationshipName = queryMetric.records.math_target?.related_records.relationship_name;
    const queryObjectId = queryMetric.records.resource.id;

    if (!propertyName || !relationshipName) {
      return;
    }

    const relationship = relationships.find((rel) => rel.name === relationshipName);
    const relatedObjectId = relationship?.first_entity_id === queryObjectId ? relationship?.second_entity_id : relationship?.first_entity_id;

    if (!relatedObjectId) {
      return;
    }

    const relatedObject = objects.find((obj) => obj.id === relatedObjectId);

    if (!relatedObject) {
      return;
    }

    count = {
      aggregate: queryMetric.records.math as MetricMath,
      type:  ObjectCountType.RECORD,
      record: {
        type: 'property',
        data: getRelatedRecordPropertyCountData(
          propertyName,
          propertyType,
          queryMetric.records.resource.id,
          workspaceId,
          relationshipName,
          relatedObject
        ),
      },
    };
  }

  if (!count!) {
    return;
  }

  return {
    id: uuid(),
    name: queryMetric.name ?? 'X',
    label: queryMetric.query_name ?? undefined,
    type: TrendsBuilderType.OBJECT,
    data: {
      [TrendsBuilderType.OBJECT]: {
        workspaceObjectId: queryMetric.records.resource.id,
        count,
      },
    },
  };
};

import BigdeltaIcon from '../../../assets/bigdelta-m.svg?react';
import { FC } from 'react';
import { SVGComponent } from '../../../types';

interface IntegrationSetupIllustrationProps {
  name: string;
  icon: SVGComponent;
}

export const IntegrationSetupIllustration: FC<IntegrationSetupIllustrationProps> = ({ name, icon }) => {
  const Icon = icon;

  return (
    <div className="flex select-none gap-x-8">
      <div className="flex flex-col items-center gap-y-3">
        <BigdeltaIcon className="h-16 w-16 rounded-2xl p-[3px]" />
        <div>Bigdelta</div>
      </div>
      <div className="flex h-16 items-center whitespace-nowrap text-m-olive-300">{'<----------->'}</div>
      <div className="flex flex-col items-center gap-y-3">
        <Icon className="h-16 w-16" />
        <div>{name}</div>
      </div>
    </div>
  );
};

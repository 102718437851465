"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataQuerySortElementDef = exports.QuerySortOrderStringType = exports.QuerySortOrder = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
var QuerySortOrder;
(function (QuerySortOrder) {
    QuerySortOrder["ASC"] = "asc";
    QuerySortOrder["DESC"] = "desc";
})(QuerySortOrder || (exports.QuerySortOrder = QuerySortOrder = {}));
exports.QuerySortOrderStringType = (0, typebox_2.EnumStrings)(typebox_1.Type.Enum(QuerySortOrder));
exports.DataQuerySortElementDef = typebox_1.Type.Recursive((Self) => typebox_1.Type.Object({
    property: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
        name: typebox_1.Type.String(),
        order: exports.QuerySortOrderStringType,
    })),
    field: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
        name: typebox_1.Type.String(),
        order: exports.QuerySortOrderStringType,
    })),
    related_records: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
        relationship_name: typebox_1.Type.String({ pattern: '^[a-z0-9_-]+$' }),
        sort: Self,
    })),
}), {
    $id: 'DataQuerySortElementDef',
});

import { PageHeading } from '../../../components/PageHeading';
import { MetricsTable } from '../components/MetricsTable';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { Button } from '../../../shared/ui/Button/Button.tsx';
import { EmptyState, EmptyStateStageProps } from '../../../shared/components/EmptyState.tsx';
import { useWorkspace } from '../../auth/hooks/useWorkspace.tsx';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { useQuery } from '@tanstack/react-query';

import React, { useMemo, useState } from 'react';
import PuzzlePieceIcon from '../../../assets/icons/puzzle-piece-01.svg?react';
import PlusSquareIcon from '../../../assets/icons/plus-square.svg?react';
import PlayIcon from '../../../assets/icons/play.svg?react';
import SearchIcon from '../../../assets/icons/search-md.svg?react';
import { AppRoutes } from '../../../routes';
import { CenteredLoader } from '../../../shared/components/CenteredLoader.tsx';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

const EMPTY_STATE_STAGES: EmptyStateStageProps[] = [
  {
    icon: PuzzlePieceIcon,
    iconProps: { className: 'text-m-olive-500' },
    title: (
      <div className="text-2xl">
        <span className="font-bold">Adopt</span> metrics
      </div>
    ),
    description: 'Adopt industry-standard metric definitions to eliminate confusion across teams.',
  },
  {
    icon: PlusSquareIcon,
    iconProps: { className: 'text-m-olive-500' },
    title: (
      <div className="text-2xl">
        <span className="font-bold">Define</span> metrics
      </div>
    ),
    description: 'Utilize clear calculation formulas for accurate and reliable measurement.',
  },
  {
    icon: PlayIcon,
    iconProps: { className: 'text-m-olive-500' },
    title: (
      <div className="text-2xl">
        <span className="font-bold">Use</span> metrics
      </div>
    ),
    description: 'Ensure unified metrics understanding for improved decision-making and performance tracking.',
  },
];

export const MetricList = () => {
  const { currentWorkspaceId } = useWorkspace();
  const navigate = useNavigate();
  const queryKeys = useQueryKeys();

  const metricsQuery = useQuery({
    queryKey: queryKeys.metrics(),
    queryFn: () => bigdeltaAPIClient.v1.metricsList({ workspace_id: currentWorkspaceId }),
  });

  const isEmpty = !metricsQuery?.data?.items || metricsQuery?.data?.items.length === 0;

  const [searchTerm, setSearchTerm] = useState('');

  const filteredMetrics = useMemo(() => {
    return metricsQuery?.data?.items.filter((metric) => metric.display_name.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [metricsQuery, searchTerm]);

  return (
    <div className="flex flex-col">
      {!metricsQuery.isInitialLoading && (
        <>
          <div className="z-10 flex items-center justify-between">
            <PageHeading breadcrumbs={[{ label: 'Metrics' }]} />
            <div className="flex items-center gap-x-2">
              {!isEmpty && (
                <div className="relative">
                  <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
                    <SearchIcon className="h-5 w-5 text-m-gray-600" />
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className="block w-full rounded-md border border-m-gray-300 p-2 ps-10 text-md"
                    placeholder="Search metrics"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              )}
              <Link to="new">
                <Button label="Create metric" intent={!isEmpty ? 'brand' : 'secondary'} size="lg" />
              </Link>
            </div>
          </div>
          <hr className="mt-4 border-m-gray-300" />
          {!isEmpty && <MetricsTable metrics={filteredMetrics} />}
          {isEmpty && (
            <div>
              <EmptyState heading="Use clear and consistent metrics through your analytics" stages={EMPTY_STATE_STAGES}>
                <Button intent="brand" size="xl" label="Create metric" onClick={() => navigate(AppRoutes.CREATE_METRIC)} />
              </EmptyState>
            </div>
          )}
          <Outlet />
        </>
      )}
      {metricsQuery.isInitialLoading && <CenteredLoader />}
    </div>
  );
};

import { Combobox } from '../../../../components/Combobox';
import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { MetadataResourcePropertyType } from '@bigdelta/lib-shared';
import { ALL_EVENTS, Attribute } from '../../store';
import { useQueryKeys } from '../../../../features/auth/hooks/useQueryKeys.ts';
import { PropertyNameObject } from '../../../types.ts';
import { MetadataResourcesPropertiesNamesListData } from '@bigdelta/lib-api-client';
import { bigdeltaAPIClient } from '../../../../client/bigdeltaAPIClient.ts';

type PropertyNameObj = MetadataResourcesPropertiesNamesListData['items'][number];

type EventPropertyListProps = {
  eventName: string;
  workspaceId: string;
  onChange: (property: Attribute) => void;
};

export const EventPropertyList = ({ eventName, workspaceId, onChange }: EventPropertyListProps) => {
  const queryKeys = useQueryKeys();
  const { data: metadataPropertyNames } = useQuery({
    queryKey: queryKeys.list('metadata', 'events', 'properties', 'names', eventName),
    queryFn: () =>
      bigdeltaAPIClient.v1.metadataResourcesPropertiesNamesList({
        workspace_id: workspaceId,
        resource_type: MetadataResourcePropertyType.EVENT,
        resource_id: eventName === ALL_EVENTS ? undefined : eventName,
      }),
  });

  const handlePropertyChange = (data: PropertyNameObject | null) => {
    if (data) {
      onChange({
        attributeId: data.property_id,
        attributeType: data.property_type ?? 'string',
        attributeName: data.property_name,
      });
    }
  };

  const filterCompare = useCallback((item: PropertyNameObj, query: string) => item.property_name.toLocaleLowerCase().includes(query), []);

  return (
    <Combobox
      items={metadataPropertyNames?.items ?? []}
      selectedItems={null}
      onChange={(data) => {
        handlePropertyChange(data);
        close();
      }}
      catchInputFocus
      height={380}
      renderOption={(item) => item.property_name}
      filterCompare={filterCompare}
    />
  );
};

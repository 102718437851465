import { forwardRef } from 'react';
import { Input, InputProps } from '../Input/Input.tsx';
import { FormElementWrapper, FormElementWrapperProps } from '../FormElementWrapper/FormElementWrapper.tsx';

type FormInputProps = FormElementWrapperProps & {
  inputProps: InputProps;
};

export const FormInput = forwardRef<HTMLInputElement, FormInputProps>(({ inputProps, ...rest }, ref) => (
  <FormElementWrapper {...rest}>
    <Input ref={ref} {...inputProps} />
  </FormElementWrapper>
));

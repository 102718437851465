"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateAutomationRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const automation_1 = require("../model/automation");
exports.CreateAutomationRequestDef = typebox_1.Type.Omit(automation_1.AutomationDef, [
    'id',
    'systemCreatedAt',
    'systemUpdatedAt',
    'version',
    'deleted_at',
    'updated_at',
]);

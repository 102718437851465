import { FC } from 'react';
import * as Select from '../../../components/SelectFilter.tsx';
import { useFilterStore } from '../store';
import { useFilterContext } from '../context/useFilterContext';
import { eventOperators } from '../const';
import { QueryFrequencyValueFilterOperator } from '@bigdelta/lib-shared';
import { twMerge } from 'tailwind-merge';
import { getOperatorLabel } from '../../utils/getOperatorLabel.ts';

interface EventOperatorSelectProps {
  index: number;
}

export const EventOperatorSelect: FC<EventOperatorSelectProps> = ({ index }) => {
  const { filterKey, mode } = useFilterContext();
  const filterItem = useFilterStore((state) => state.getFilterItem([...filterKey, index]));
  const setItemEventOperator = useFilterStore((state) => state.setItemEventOperator);

  const getOperators = () => {
    if (!filterItem?.event) {
      return null;
    }

    return Object.values(eventOperators);
  };

  const operators = getOperators();

  const handleChange = (value: string) => {
    setItemEventOperator(filterKey, index, value as QueryFrequencyValueFilterOperator);
  };

  if (!filterItem?.event) {
    return null;
  }

  return (
    <Select.Root value={filterItem?.eventOperator} onValueChange={handleChange}>
      <Select.Trigger
        placeholder="[operator]"
        label={getOperatorLabel(filterItem?.eventOperator) ?? ''}
        className={twMerge(mode === 'stacked' && 'w-full')}
      />
      {operators && (
        <Select.Content>
          {operators.map((operator) => (
            <Select.Item key={operator} value={operator} label={getOperatorLabel(operator)} />
          ))}
        </Select.Content>
      )}
    </Select.Root>
  );
};

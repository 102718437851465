"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataQueryRelationshipsVODef = exports.DataQueryRelationshipPropertiesVODef = exports.DataQueryPropertiesVODef = exports.DataQueryPropertyVODef = void 0;
const typebox_1 = require("@sinclair/typebox");
const createRelationshipVO_1 = require("./createRelationshipVO");
const typebox_2 = require("../typebox");
const dataQuerySort_1 = require("../types/dataQuerySort");
exports.DataQueryPropertyVODef = typebox_1.Type.Object({
    name: typebox_1.Type.String(),
    // todo this field indicates whether this property should be retrieved from fields
    // should probably either have a separate data structure for field data propagation or rename this one to something that encompasses both fields and properties
    is_field: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
    relation_id_alias: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    property_value_alias: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    group_value_alias: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    active_since_value_alias: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    aggregation_value_alias: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    sort_value_alias: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    is_array_property: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
    required_for_grouping: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
    required_for_retrieval: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
    required_for_aggregation: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
    required_for_sorting: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
    sort: (0, typebox_2.OptionalNullableRef)(typebox_1.Type.Ref(dataQuerySort_1.DataQuerySortElementDef)),
    unfold_values: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
});
exports.DataQueryPropertiesVODef = typebox_1.Type.Array(exports.DataQueryPropertyVODef);
exports.DataQueryRelationshipPropertiesVODef = typebox_1.Type.Recursive((Self) => typebox_1.Type.Object({
    relationship_name: createRelationshipVO_1.RelationshipNameLowerCaseDef,
    properties: (0, typebox_2.OptionalNullable)(exports.DataQueryPropertiesVODef),
    relationship_properties: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(Self)),
}), {
    $id: 'DataQueryRelationshipPropertiesVO',
});
exports.DataQueryRelationshipsVODef = typebox_1.Type.Object({
    relationship_properties: typebox_1.Type.Array(typebox_1.Type.Ref(exports.DataQueryRelationshipPropertiesVODef)),
});

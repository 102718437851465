import { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UserContext } from '../context/userContext';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { AppRoutes } from '../../../routes';
import { Button } from '../../../shared/ui/Button/Button.tsx';
import { isEmpty } from 'lodash';
import { handleFormErrorResponse } from '../../../utils/handleFormErrorResponse.ts';
import { FormInput } from '../../../shared/ui/FormInput/FormInput.tsx';
import { AuthScreenTitle } from '../components/AuthScreenTitle.tsx';
import { useMutation } from '@tanstack/react-query';
import { AuthScreenContainer } from '../components/AuthScreenContainer.tsx';
import { AuthScreenLogo } from '../components/AuthScreenLogo.tsx';
import { ResetPasswordSuccess } from '../components/ResetPasswordSuccess.tsx';
import { setAuthenticationToken } from '../../../shared/utils/authToken.ts';
import { TrackingEvent, tracking } from '../../../tracking/index.ts';
import { toastSuccess } from '../../../utils/toast.tsx';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

interface ResetPasswordFormSchema {
  password: string;
  passwordConfirm: string;
}

export const ResetPasswordPage = () => {
  const userContext = useContext(UserContext);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const { token } = useParams();

  const {
    register,
    handleSubmit,
    setError: setFieldError,
    watch,
    formState: { errors },
  } = useForm<ResetPasswordFormSchema>();

  const resetPasswordMutation = useMutation({
    mutationFn: (password: string) => bigdeltaAPIClient.v1.membersResetPasswordCreate({ token, password }),
    onSuccess: async (response) => {
      setAuthenticationToken(response.token);

      if (userContext.fetchUser) {
        const result = await userContext.fetchUser();

        if (result) {
          setError('');
          tracking.track(TrackingEvent.LoggedIn);
          navigate(AppRoutes.ROOT);
          toastSuccess('Your password has been changed', 'You are now logged in.');
        }
      }
    },
  });

  const onSubmit: SubmitHandler<ResetPasswordFormSchema> = async (data) => {
    setLoading(true);

    try {
      if (isEmpty(data.password) || isEmpty(data.passwordConfirm)) {
        setError('Password and password confirmation are required');
        return;
      }

      setError('');

      try {
        await resetPasswordMutation.mutateAsync(data.password);
      } catch (e: any) {
        handleFormErrorResponse(e, setError, setFieldError);
      }
    } finally {
      setLoading(false);
    }
  };

  if (userContext.user && userContext.authenticated && userContext.ready) {
    return <Navigate to={AppRoutes.ROOT} />;
  }

  return (
    <AuthScreenContainer>
      <AuthScreenLogo />
      {!resetPasswordMutation.isSuccess && (
        <>
          <AuthScreenTitle title="Reset your password" />
          <div className="flex items-center justify-center py-4">
            {error && <div className="w-full rounded-lg border border-m-red-300 bg-m-red-200 px-4 py-2 text-md text-m-red-700">{error}</div>}
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4">
              <FormInput
                label="Password"
                inputProps={{
                  ...register('password', { required: true }),
                  autoComplete: 'new-password',
                  type: 'password',
                  placeholder: '••••••••',
                }}
                errorMessage={errors.password?.message}
              />
              <FormInput
                label="Confirm Password"
                inputProps={{
                  ...register('passwordConfirm', {
                    required: true,
                    validate: (val: string) => {
                      if (watch('password') != val) {
                        return 'Passwords do no match';
                      }
                    },
                  }),
                  autoComplete: 'new-password',
                  type: 'password',
                  placeholder: '••••••••',
                }}
                errorMessage={errors.passwordConfirm?.message}
              />
              <Button label="Submit" className="w-full" size="lg" type="submit" loading={loading} disabled={loading} />
            </div>
          </form>
        </>
      )}
      {resetPasswordMutation.isSuccess && <ResetPasswordSuccess />}
    </AuthScreenContainer>
  );
};

import { QueryRecordsCreateData } from '@bigdelta/lib-api-client';
import { FetchNextPageOptions, InfiniteData, InfiniteQueryObserverResult } from '@tanstack/react-query';
import { RefObject, useCallback, useEffect, useRef } from 'react';

const DEFAULT_TRIGGER_OFFSET = 500;

type FetchNextPage = (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<QueryRecordsCreateData, unknown>>;

interface UseFetchMoreOnBottomReachedArgs {
  fetchNextPage: FetchNextPage;
  isFetching: boolean;
  records: InfiniteData<QueryRecordsCreateData> | undefined;
  tableContainerRef: RefObject<HTMLDivElement>;
  triggerOffset?: number;
}

export const useFetchMoreOnBottomReached = ({
  fetchNextPage,
  isFetching,
  records,
  tableContainerRef,
  triggerOffset = DEFAULT_TRIGGER_OFFSET,
}: UseFetchMoreOnBottomReachedArgs) => {
  const ref = useRef<HTMLDivElement>(tableContainerRef.current);

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (
          scrollHeight - scrollTop - clientHeight < triggerOffset &&
          !isFetching &&
          // totalFetched < totalDBRowCount
          Number(records?.pages?.[records?.pages.length - 1].items.length) > 0
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, records?.pages, triggerOffset]
  );

  useEffect(() => {
    fetchMoreOnBottomReached(ref?.current);
  }, [fetchMoreOnBottomReached]);

  return fetchMoreOnBottomReached;
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateWorkspaceObjectRecordVODef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const model_1 = require("../model");
exports.CreateWorkspaceObjectRecordVODef = typebox_1.Type.Object({
    id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    remoteId: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    workspaceId: typebox_1.Type.String({ format: 'uuid' }),
    workspaceObjectIdOrSlug: typebox_1.Type.String({ format: 'uuid' }),
    properties: (0, typebox_2.OptionalNullable)(typebox_2.NestedObjectDef),
    propertiesOnce: (0, typebox_2.OptionalNullable)(typebox_2.NestedObjectDef),
    version: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
    activeSince: (0, typebox_2.OptionalNullable)(typebox_2.DateType),
    activeUntil: (0, typebox_2.OptionalNullable)(typebox_2.DateType),
    modifiedBy: model_1.AuthorDef,
});

import { PropsWithChildren, FC, useState, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';
import ChevronDownIcon from '../../../assets/icons/chevron-down.svg?react';
import FavoriteRemoveIcon from '../../../assets/icons/favorite-remove.svg?react';
import { SVGComponent } from '../../../types';

interface NavItemProps extends PropsWithChildren {
  icon?: SVGComponent;
  label?: string | null;
  to?: string;
  level?: number;
  isFavorite?: boolean;
  onRemoveFavorite?: () => void;
}

export const NavItem: FC<NavItemProps> = ({ children, icon: Icon, label, to, level = 0, isFavorite = false, onRemoveFavorite }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isExpandable = !!children;

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (isExpandable) {
      event.preventDefault();
    }
    setIsExpanded((prev) => !prev);
  };

  return (
    <li className="relative flex flex-col items-stretch">
      <div className="rounded-md hover:bg-m-coconut-700 has-[:focus-visible]:bg-m-coconut-700">
        <Link
          to={!isExpandable && to ? to : '#'}
          className={twMerge(
            'peer flex items-center gap-x-1.5 rounded-md px-2 py-1.5 outline-none focus-visible:bg-m-coconut-700 focus-visible:ring-2 focus-visible:ring-inset',
            isFavorite && 'pr-10'
          )}
          onClick={(event) => handleClick(event)}
        >
          {Icon ? <Icon className="h-4 w-4 shrink-0 text-m-olive-600" /> : <div style={{ width: `${level}rem` }} />}
          <span className="min-w-0 grow truncate text-left text-sm text-m-olive-600">{label}</span>
          {isExpandable && <ChevronDownIcon className={twMerge('h-4 w-4 text-m-olive-600 transition-transform', isExpanded && 'rotate-180')} />}
        </Link>
        {isFavorite && (
          <button
            className="absolute right-0 top-1/2 z-10 h-4 w-4 -translate-x-4 -translate-y-1/2 rounded-sm border-none opacity-0 hover:bg-m-coconut-700 hover:opacity-100 hover:brightness-90 focus:ring-0 focus-visible:opacity-100 peer-hover:opacity-100 peer-focus-visible:opacity-100"
            data-tooltip-id="nav-item-remove-favorite"
            onClick={onRemoveFavorite}
          >
            <FavoriteRemoveIcon className="h-4 w-4 text-m-olive-500" />
          </button>
        )}
      </div>
      {children && isExpanded && <ul className="flex flex-col items-stretch gap-y-px">{children}</ul>}
      <Tooltip id="nav-item-remove-favorite" className="!px-2 !py-0.5">
        Remove shortcut
      </Tooltip>
    </li>
  );
};

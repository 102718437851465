import { Dialog } from '@headlessui/react';
import XCloseIcon from '../../../assets/icons/x-close.svg?react';
import { FC } from 'react';
import { Button } from '../../../shared/ui/Button/Button';
import React from 'react';
import { twMerge } from '../../../utils/twMerge';

interface IntegrationMetricsModalProps {
  open: boolean;
  onClose: () => void;
  Component?: FC<{ close: () => void }>;
  metrics: string[];
}

export const IntegrationMetricsModal: FC<IntegrationMetricsModalProps> = ({ open, onClose, metrics }) => {
  return (
    <Dialog open={open} onClose={onClose} className="relative z-[999999]">
      <div className="fixed inset-0 bg-m-gray-700 opacity-95" aria-hidden="true" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="max-h-1/3 mx-auto flex w-2/5 max-w-xl flex-col items-center gap-y-6 rounded-xl bg-white p-1">
          <div className="flex w-full items-center justify-between p-2 pl-6">
            <div className="text-xl font-medium">{metrics?.length} ready to use metrics</div>
            <Button intent="tertiary" size="xl" leadingIcon={XCloseIcon} onClick={onClose} equalPadding />
          </div>
          <div className="flex max-h-64 w-full flex-col overflow-y-auto px-6">
            {metrics?.map((metric, index) => (
              <div key={metric} className={twMerge(index % 2 == 0 && 'bg-m-gray-200', 'rounded-lg px-6 py-3.5 text-sm')}>
                {metric}
              </div>
            ))}
          </div>
          <div className="flex w-full justify-end p-4">
            <Button size="md" label="Close" intent="primary" onClick={() => onClose()} />
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InsightTypeVO = void 0;
var InsightTypeVO;
(function (InsightTypeVO) {
    InsightTypeVO["OBSERVATION"] = "OBSERVATION";
    InsightTypeVO["NEED"] = "NEED";
    InsightTypeVO["JOB"] = "JOB";
    InsightTypeVO["PAIN"] = "PAIN";
    InsightTypeVO["GAIN"] = "GAIN";
    InsightTypeVO["QUOTE"] = "QUOTE";
})(InsightTypeVO || (exports.InsightTypeVO = InsightTypeVO = {}));

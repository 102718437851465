import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../../features/auth/hooks/useQueryKeys';
import { TableResourceType } from '@bigdelta/lib-shared';
import { bigdeltaAPIClient } from '../../client/bigdeltaAPIClient.ts';

export const useEventsMetadataPropertiesNamesQuery = ({ workspaceId, resourceId }: { workspaceId?: string; resourceId?: string }) => {
  const queryKeys = useQueryKeys();

  return useQuery(
    queryKeys.list('metadata', TableResourceType.EVENT, resourceId, 'names'),
    () =>
      bigdeltaAPIClient.v1.metadataResourcesPropertiesNamesList({
        resource_type: 'EVENT',
        resource_id: resourceId,
        workspace_id: workspaceId!,
      }),
    { enabled: !!workspaceId }
  );
};

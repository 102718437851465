import React, { FC, HTMLAttributes, useEffect } from 'react';
import { FormInput } from '../../../shared/ui/FormInput/FormInput.tsx';
import { useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { ObjectFormSchema } from './ObjectFormSchema.ts';
import { FormElementWrapper } from '../../../shared/ui/FormElementWrapper/FormElementWrapper.tsx';
import { Select } from '../../../shared/ui/Select/Select.tsx';

interface Props extends HTMLAttributes<HTMLElement> {
  isCustomObject: boolean;
  initialValues?: ObjectFormSchema;
  deactivateButton?: React.ReactNode;
  objectProperties?: string[];
  renderSubmitButton?: (isDirty: boolean, isValid: boolean, handleSubmit: (handler: (data: ObjectFormSchema) => any) => any) => React.ReactNode;
}

export const ObjectProfileForm: FC<Props> = ({
  className,
  isCustomObject,
  initialValues,
  deactivateButton,
  objectProperties,
  renderSubmitButton,
}) => {
  const {
    register,
    formState: { errors, isDirty, isValid },
    reset,
    handleSubmit,
  } = useForm<ObjectFormSchema>({
    defaultValues: initialValues,
  });

  useEffect(() => {
    reset(initialValues);
  }, [initialValues, reset]);

  return (
    <div className={twMerge('relative flex flex-col gap-4 p-6', className)}>
      <div className="flex flex-row justify-between gap-2">
        <FormInput
          label="Singular noun"
          inputProps={{
            ...register('singular_noun', { required: true }),
            placeholder: 'Object',
            size: 'sm',
            disabled: !isCustomObject,
          }}
          size="sm"
          errorMessage={errors.singular_noun?.message}
          className="w-full"
        />
        <FormInput
          label="Plural noun"
          inputProps={{
            ...register('plural_noun', { required: true }),
            placeholder: 'Objects',
            size: 'sm',
            disabled: !isCustomObject,
          }}
          size="sm"
          errorMessage={errors.plural_noun?.message}
          className="w-full"
        />
      </div>
      {!isCustomObject && <span className="-mt-3 text-xs text-m-olive-500">Cannot be changed for standard objects.</span>}
      <FormInput
        label="Indentifier / Slug"
        inputProps={{
          ...register('api_slug', { required: true }),
          placeholder: 'objects',
          size: 'sm',
          disabled: !isCustomObject || initialValues?.api_slug?.length > 0,
        }}
        size="sm"
        errorMessage={errors.api_slug?.message}
      />
      {isCustomObject && initialValues?.api_slug && (
        <span className="-mt-3 text-xs text-m-olive-500">
          The slug cannot be changed to avoid unexpected outcomes. Please contact support if a change is necessary.
        </span>
      )}
      {!isCustomObject && <span className="-mt-3 text-xs text-m-olive-500">Cannot be changed for standard objects.</span>}
      <FormElementWrapper label="Label" errorMessage={errors.label_property_0?.message || errors.label_property_1?.message} size="sm">
        <span className="mb-2 text-xs text-m-olive-500">
          Select object properties to be used as labels for the records. Properties will be used for labels in the following order. If none is
          selected, record ID will be used as label.
        </span>
        <Select {...register('label_property_0')} className="text-xs">
          <option value="">(none)</option>
          {objectProperties?.map((property) => (
            <option key={property} value={property}>
              {property === '$met_remote_id' ? 'id' : property}
            </option>
          ))}
        </Select>
        <Select {...register('label_property_1')} className="mt-2 text-xs">
          <option value="">(none)</option>
          {objectProperties?.map((property) => (
            <option key={property} value={property}>
              {property === '$met_remote_id' ? 'id' : property}
            </option>
          ))}
        </Select>
      </FormElementWrapper>
      <div className="flex flex-row justify-between gap-2">
        <div className="w-1/2">{deactivateButton}</div>
        <div className="w-1/2">{renderSubmitButton && renderSubmitButton(isDirty, isValid, (handler) => handleSubmit(handler))}</div>
      </div>
    </div>
  );
};

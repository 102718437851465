import CalculatorIcon from '../../../../assets/icons/calculator.svg?react';

import { useFunnelBuilderStore } from '../../store/Report';
import { Select, SelectItem } from '../common/Select';
import { FunnelCount } from '../../store/FunnelBuilder';


const DUMMY_ITEMS = [
  { label: 'Conversion rate', value: 'conversion_rate', icon: CalculatorIcon }
]
export const FunnelCountSelect = () => {
  const setCount = useFunnelBuilderStore(null, (state) => state.setCount);
  const value = useFunnelBuilderStore(null, (state) => state?.data.object?.count.type);


  const handleCountSelect = (property: string) => {
    setCount({ type: property as FunnelCount['type'] });
  };


  return (
    <div className="flex flex-col">
      <div className="text-sm mb-5">Count</div>
      <Select
        value={value ?? undefined}
        onValueChange={handleCountSelect}
        hasArrow
      >
        {DUMMY_ITEMS.map(({ label, ...itemProps }) => (
          <SelectItem key={label} {...itemProps}>
            {label}
          </SelectItem>
        ))}
      </Select>
    </div>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Range = void 0;
class Range {
    constructor(offset, limit, total) {
        const notEmpty = total > 0;
        const to = (offset + limit >= total ? total : offset + limit) - 1;
        this.from = notEmpty ? offset : 0;
        this.to = notEmpty ? to : 0;
        this.total = total;
    }
}
exports.Range = Range;

import { ChangeEvent, FC, useMemo, useState } from 'react';
import { Popover } from '@headlessui/react';
import { FilterItem } from '../../store';
import { ValueSelectType } from '../../const';
import { ComboboxInfinite } from '../../../../components/Combobox/ComboboxInfinite';
import { useDebounce } from '@uidotdev/usehooks';
import { useMetadataPropertiesValuesInfiniteQuery } from '../../../data/useMetadataAttributeValuesInfiniteQuery';
import { MetadataResourcesPropertiesValuesListData } from '@bigdelta/lib-api-client';
import { useFilterContext } from '../../context/useFilterContext';
import { twMerge } from 'tailwind-merge';
import { useWorkspace } from '../../../../features/auth/hooks/useWorkspace';
import { last } from 'lodash';
import { OpenTrigger } from '../../../components/OpenTrigger';
import { MetadataResourcePropertyType } from '@bigdelta/lib-shared';
import { FloatWrapper } from '../../../../features/reports/components/FloatWrapper.tsx';
import { FilterTrigger } from '../../../../components/FilterTrigger.tsx';

type AttributeValueObject = MetadataResourcesPropertiesValuesListData['items'][number];

interface PropertyValueComboboxSingleProps {
  filterItem: FilterItem;
  onChange: (value: AttributeValueObject | null) => void;
  attributeValues?: AttributeValueObject[];
  resourceId?: string;
  propertyId?: string;
  resourceType: MetadataResourcePropertyType;
}

export const PropertyValueComboboxSingle: FC<PropertyValueComboboxSingleProps> = ({ filterItem, onChange, resourceId, propertyId, resourceType }) => {
  const { mode, inlineElements } = useFilterContext();
  const { currentWorkspaceId } = useWorkspace();

  const selectedItems = useMemo(() => {
    if (filterItem.data.valueType === ValueSelectType.COMBOBOX_SINGLE && !!filterItem.data.value) {
      return { property_value: filterItem.data.value };
    } else {
      return null;
    }
  }, [filterItem.data.value, filterItem.data.valueType]);

  const [rawSearchQuery, setRawSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(rawSearchQuery, 700);

  const currentEntityId = last(filterItem.propertyRelationships)?.objectId ?? resourceId;

  const {
    data: metadataValues,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isFetching,
    isSuccess,
  } = useMetadataPropertiesValuesInfiniteQuery({
    resourceId: currentEntityId,
    propertyId: propertyId,
    workspaceId: currentWorkspaceId,
    searchQuery: debouncedSearchQuery,
    resourceType,
  });

  const flatMetadataValues = useMemo(() => (metadataValues ? metadataValues.pages.flatMap((d) => d.items) : []), [metadataValues]);

  return (
    <Popover>
      {({ open, close }) => (
        <FloatWrapper
          placement="bottom-start"
          offset={4}
          portal
          shift={{
            mainAxis: false,
            crossAxis: true,
          }}
          inline={inlineElements}
        >
          <Popover.Button
            as={FilterTrigger}
            open={open}
            textClassName="overflow-hidden text-ellipsis"
            className={twMerge('group-hover:bg-[#f0f0f0]', mode === 'stacked' && 'w-full overflow-hidden')}
          >
            <OpenTrigger shouldOpen={!filterItem.data?.value} />
            {filterItem.data?.value || 'Select value...'}
          </Popover.Button>
          <Popover.Panel>
            <ComboboxInfinite
              items={flatMetadataValues}
              selectedItems={selectedItems}
              onChange={(data) => {
                onChange(data);
                close();
              }}
              onQueryChange={(e: ChangeEvent<HTMLInputElement>) => setRawSearchQuery(e.target.value)}
              query={rawSearchQuery}
              height={380}
              catchInputFocus
              renderOption={(item) => {
                return item?.property_value;
              }}
              by={(a, b) => a?.attributeValue === b?.attributeValue}
              isFetching={isFetching}
              hasNextPage={!!hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              fetchNextPage={fetchNextPage}
              isSuccess={isSuccess}
            />
          </Popover.Panel>
        </FloatWrapper>
      )}
    </Popover>
  );
};

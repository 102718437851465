"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegrationStatus = void 0;
var IntegrationStatus;
(function (IntegrationStatus) {
    IntegrationStatus["DRAFT"] = "draft";
    IntegrationStatus["ENABLED"] = "enabled";
    IntegrationStatus["BROKEN"] = "broken";
    IntegrationStatus["DELETED"] = "deleted";
})(IntegrationStatus || (exports.IntegrationStatus = IntegrationStatus = {}));

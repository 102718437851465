import { FC, useState } from 'react';
import { AutomationSidePanelHeading } from './AutomationSidePanelHeading';
import { TriggerCombobox } from './TriggerCombobox';
import { TriggerType } from '../types';
import { RecordTriggerConfig } from './RecordTriggerConfig';
import { EventOccurredConfig } from './EventOccurredConfig';
import { Button } from '../../../shared/ui/Button/Button';
import { TriggerTypeLabelMap } from '../const';
import { TriggerIcon } from './TriggerIcon';

export interface Trigger {
  triggerType: TriggerType | null;
  workspaceObjectId?: string;
  workspaceObjectProperty?: string;
  eventName?: string;
}

interface BaseConfigComponentProps {
  triggerType: TriggerType;
  workspaceObjectId?: string;
  workspaceObjectProperty?: string;
  eventName?: string;
  onChange: (trigger: Trigger) => void;
}

const ConfigComponentMap: Record<TriggerType, FC<BaseConfigComponentProps>> = {
  RECORD_CREATED: RecordTriggerConfig,
  RECORD_UPDATED: RecordTriggerConfig,
  RECORD_DELETED: RecordTriggerConfig,
  EVENT_OCCURRED: EventOccurredConfig,
};

enum EditStep {
  TRIGGER_SELECT = 'TRIGGER_SELECT',
  TRIGGER_CONFIG = 'TRIGGER_CONFIG',
}

interface ConfigTriggerProps {
  onChange: (trigger: Trigger) => void;
  triggerType: TriggerType | undefined;
  workspaceObjectId: string | undefined;
  workspaceObjectProperty: string | undefined;
  eventName: string | undefined;
}

export const ConfigTrigger: FC<ConfigTriggerProps> = ({ onChange, triggerType, workspaceObjectId, workspaceObjectProperty, eventName }) => {
  const ConfigComponent = triggerType ? ConfigComponentMap[triggerType] : null;

  const [step, setStep] = useState<EditStep>(() => {
    if (!triggerType) {
      return EditStep.TRIGGER_SELECT;
    }
    if (workspaceObjectId || eventName) {
      return EditStep.TRIGGER_CONFIG;
    }
    return EditStep.TRIGGER_CONFIG;
  });
  const [stepHistory, setStepHistory] = useState<EditStep[]>([step]);

  const heading = step === EditStep.TRIGGER_CONFIG && triggerType ? TriggerTypeLabelMap[TriggerType[triggerType]] : 'Select trigger';

  const handleClickBack = () => {
    if (stepHistory.length > 1) {
      setStepHistory((prev) => prev.slice(0, -1));
      setStep(stepHistory[stepHistory.length - 2]);
    } else if (step === EditStep.TRIGGER_CONFIG) {
      setStep(EditStep.TRIGGER_SELECT);
    }
  };

  return (
    <div className="flex flex-col gap-y-9">
      <AutomationSidePanelHeading title={heading} onClickBack={handleClickBack} />
      {step === EditStep.TRIGGER_SELECT && (
        <TriggerCombobox
          onChange={(triggerType: TriggerType | null) => {
            onChange({
              triggerType,
              workspaceObjectId: undefined,
              eventName: undefined,
            });
          }}
          value={triggerType}
        />
      )}
      {step === EditStep.TRIGGER_CONFIG && ConfigComponent && triggerType && (
        <>
          <div className="flex items-center justify-between border-b border-m-olive-100 py-4">
            <div className="flex items-center gap-x-2 text-m-olive-600">
              <TriggerIcon triggerType={triggerType} className="h-6 w-6" />
              <div className="text-lg font-medium">{TriggerTypeLabelMap[TriggerType[triggerType]]}</div>
              <div className="flex items-center justify-center rounded-md bg-m-gray-300 p-1 text-xxs text-m-olive-400">Trigger</div>
            </div>
            <Button
              intent="secondary"
              label="Change"
              size="xs"
              onClick={() => {
                setStep(EditStep.TRIGGER_SELECT);
                setStepHistory((prev) => [...prev, EditStep.TRIGGER_SELECT]);
              }}
            />
          </div>
          <ConfigComponent
            triggerType={triggerType}
            workspaceObjectId={workspaceObjectId}
            workspaceObjectProperty={workspaceObjectProperty}
            eventName={eventName}
            onChange={onChange}
          />
        </>
      )}
    </div>
  );
};

import { useMemo } from 'react';
import { getDataMap } from '../utils/getDataMap';
import { AliasPrefix } from '../store';
import { QueryReportsCreateData } from '@bigdelta/lib-api-client';
import { PropertyNameObject } from '../../../shared/types';
import { ReportTypeVO } from '@bigdelta/lib-shared';

interface UseChartDataMapArgs {
  reportType: ReportTypeVO;
  dataQueries: QueryReportsCreateData['queries'] | undefined;
  breakdownSelectedValues?: { property_value: string }[];
  breakdownProperty?: PropertyNameObject;
  breakdownPropertyPrefix?: AliasPrefix;
  breakdownSelectedValuesColorMap: Record<string, string> | undefined;
}

export const useChartDataMap = ({
  reportType,
  dataQueries,
  breakdownSelectedValuesColorMap,
  breakdownSelectedValues,
  breakdownProperty,
  breakdownPropertyPrefix,
}: UseChartDataMapArgs) => {
  return useMemo(() => {
    if (!dataQueries) {
      return {};
    }

    return getDataMap({
      reportType,
      dataQueries,
      breakdownProperty,
      breakdownPropertyPrefix,
      breakdownSelectedValues,
      breakdownSelectedValuesColorMap,
    });
  }, [breakdownProperty, breakdownPropertyPrefix, breakdownSelectedValues, breakdownSelectedValuesColorMap, dataQueries, reportType]);
};

import mixpanel from 'mixpanel-browser';
import { clarity } from 'clarity-js';
import { Bigdelta } from "@bigdelta/bigdelta-browser";

const mixpanelToken = import.meta.env.VITE_APP_MIXPANEL_TOKEN;
const bigdeltaTrackingKey = import.meta.env.VITE_APP_BIGDELTA_TRACKING_KEY;
const isDev = import.meta.env.DEV;
const bigdeltaTrackingEnabled = !isDev || import.meta.env.VITE_APP_FORCE_ENABLE_BIGDELTA_TRACKING === 'true';

const bigdelta = bigdeltaTrackingKey
  ? new Bigdelta({
      baseURL: import.meta.env.VITE_APP_API_BASE_URL,
      trackingKey: bigdeltaTrackingKey,
      defaultTrackingConfig: { pageViews: { enabled: true } },
    })
  : null;

if (mixpanelToken) {
  mixpanel.init(mixpanelToken, { debug: false, track_pageview: false, persistence: 'localStorage' });
}

export const tracking = {
  identify: (userId: string, accountId: string | undefined, workspaceId: string | undefined) => {
    if (!isDev && mixpanelToken) {
      mixpanel.identify(userId);
    }
    if (bigdelta && bigdeltaTrackingEnabled) {
      bigdelta.identify({
        users: userId,
        ...(accountId ? { accounts: accountId } : {}),
        ...(workspaceId ? { workspaces: workspaceId } : {}),
      });
    }
    if (!isDev) {
      clarity.start({
        projectId: import.meta.env.VITE_APP_CLARITY_PROJECT_ID,
        upload: 'https://m.clarity.ms/collect',
        track: true,
        content: true,
      });
      clarity.identify(userId);
    }
  },
  track: (event: string, properties?: Record<string, any>) => {
    if (!isDev && mixpanelToken) {
      mixpanel.track(event, properties);
    }
    if (bigdeltaTrackingEnabled && bigdelta) {
      bigdelta.track({
        event_name: event,
        properties,
      });
    }
  },
  reset: () => {
    if (!isDev && mixpanelToken) {
      mixpanel.reset();
    }

    if (bigdeltaTrackingEnabled && bigdelta) {
      bigdelta.reset();
    }
  },
};

export { TrackingEvent } from './TrackingEvent';
export { PageViewTracker } from './PageViewTracker';

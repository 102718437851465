import { FC, HTMLProps, PropsWithChildren, forwardRef, ReactNode } from 'react';
import * as RadixPortal from '@radix-ui/react-portal';
import { twMerge } from '../../utils/twMerge';

interface PortalProps extends PropsWithChildren, HTMLProps<HTMLDivElement> {}

export const Portal: FC<PortalProps> = ({ children, onClick }) => {
  return (
    <RadixPortal.Root>
      <div className="absolute left-0 top-0 z-50 h-full w-full" onClick={onClick}>
        {children}
      </div>
    </RadixPortal.Root>
  );
};

interface VariableSuggestionsProps<T> {
  onOutsideClick: () => void;
  suggestionsList: T[];
  onSuggestionClick: (suggestion: T) => void;
  rovingIndex: number;
  renderSuggestion: (suggestion: T) => ReactNode;
}

export const VariableSuggestions = forwardRef<HTMLDivElement, VariableSuggestionsProps<any>>(
  ({ onOutsideClick, suggestionsList, onSuggestionClick, rovingIndex, renderSuggestion }, ref) => {
    return (
      <Portal onClick={onOutsideClick}>
        <div
          ref={ref}
          className="absolute rounded-lg border border-m-olive-100 bg-m-white p-2 shadow-md"
          style={{
            top: '-9999px',
            left: '-9999px',
          }}
        >
          {suggestionsList.map((suggestion, i) => (
            <div
              key={i}
              onClick={() => {
                onSuggestionClick(suggestion);
              }}
              className={twMerge('rounded-md px-2.5 py-2 text-sm font-regular', i === rovingIndex ? 'bg-m-gray-200' : 'bg-transparent')}
            >
              {renderSuggestion(suggestion)}
            </div>
          ))}
        </div>
      </Portal>
    );
  }
);

import { Combobox as ComboboxPrimitive } from '@headlessui/react';
import { ChangeEvent, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { ComboboxInput } from './ComboboxInput';
import { ComboboxOptions } from './ComboboxOptions';
import { twMerge } from 'tailwind-merge';

interface ComboboxProps<T> extends PropsWithChildren {
  items: T[];
  selectedItems?: T | null;
  onChange: (data: T | null) => void;
  catchInputFocus?: boolean;
  height: number;
  by?: (a: any, z: any) => boolean;
  renderOption: (option: T) => React.ReactNode;
  filterCompare: (item: T, query: string) => boolean;
  className?: string;
}

export const Combobox = <T extends Record<string, any>>({
  items,
  selectedItems,
  onChange,
  catchInputFocus = false,
  height,
  renderOption,
  filterCompare,
  className,
  children,
}: ComboboxProps<T>) => {
  const [search, setSearch] = useState('');
  const [filteredItems, setFilteredItems] = useState<T[]>(Object.assign([], items) ?? []);
  const parentRef = useRef<HTMLUListElement | null>(null);

  const rowVirtualizer = useVirtualizer({
    count: filteredItems.length,
    getScrollElement: () => parentRef?.current,
    estimateSize: () => 44,
  });

  useEffect(() => {
    if (!items) {
      return;
    }

    if (!search) {
      setFilteredItems(Object.assign([], items));
      return;
    }

    setFilteredItems(() => items.filter((item) => filterCompare(item, search.toLocaleLowerCase())));
  }, [search, items, filterCompare]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onRefChange = useCallback(
    (node: HTMLInputElement) => {
      if (catchInputFocus) {
        setTimeout(() => {
          node?.focus();
        }, 0);
      }
    },
    [catchInputFocus]
  );

  return (
    <ComboboxPrimitive value={selectedItems} onChange={(data) => onChange(data)}>
      <div className={twMerge('flex flex-col overflow-hidden rounded-lg border border-m-gray-300', className)}>
        <ComboboxInput onChange={handleInputChange} value={search} ref={onRefChange} />
        {!!items.length && (
          <ComboboxOptions virtualHeight={rowVirtualizer.getTotalSize()} maxHeight={height} ref={parentRef}>
            {rowVirtualizer.getVirtualItems().map(({ index, key, size, start }) => {
              const item = filteredItems[index];
              const Icon = item.icon;

              return (
                <ComboboxPrimitive.Option
                  key={key}
                  value={item}
                  className="absolute left-0 top-0 w-full cursor-default rounded-md ui-selected:bg-m-gray-200 ui-active:bg-m-gray-200"
                  data-index={index}
                  ref={rowVirtualizer.measureElement}
                  style={{
                    height: `${size}px`,
                    transform: `translateY(${start}px)`,
                  }}
                >
                  <div className="flex items-center gap-x-2 px-4 py-3">
                    {Icon && <Icon className="h-4 w-4" />}
                    <span className="whitespace-nowrap text-sm font-medium text-m-olive-600">{renderOption(item)}</span>
                  </div>
                </ComboboxPrimitive.Option>
              );
            })}
          </ComboboxOptions>
        )}
        {children}
      </div>
    </ComboboxPrimitive>
  );
};

import React, { FC, forwardRef, PropsWithChildren, useState } from 'react';
import CloseIcon from '../../../assets/icons/x-close.svg?react';
import ChevronIcon from '../../../assets/icons/chevron-down.svg?react';
import DotsIcon from '../../../assets/icons/dots-horizontal.svg?react';
import { Dialog, Menu } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import TrashIcon from '../../../assets/icons/trash-04.svg?react';
import { FilterKey, useFilterStore } from '../../../shared/filters/store';
import { useForm } from 'react-hook-form';
import { Button } from '../../../shared/ui/Button/Button';
import LoadingIcon from '../../../assets/icons/loading-05.svg?react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MetricsCreatePayload } from '@bigdelta/lib-api-client';
import { toastError, toastSuccess } from '../../../utils/toast';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { getMetrics } from '../utils/getMetrics';
import { ReportSourceType, useReportsStore } from '../store';
import { MetricSource } from '@bigdelta/lib-shared';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

export const ReportCreateSelectedMetricAggregateTrigger = forwardRef<HTMLButtonElement, PropsWithChildren>(({ children, ...props }, ref) => (
  <button {...props} ref={ref} className="flex items-center gap-x-1.5 rounded p-0.5 px-2 text-m-olive-400 hover:bg-m-gray-300">
    <span className="text-sm">{children}</span>
    <ChevronIcon className="h-5 w-5" />
  </button>
));

interface SaveMetricFormFields {
  displayName: string;
  shortName: string;
  owner: string;
  description?: string;
}

interface ReportCreateSelectedMetricProps extends PropsWithChildren {
  onRemove: () => void;
  source: React.ReactElement;
  filterKey: FilterKey;
}

export const ReportCreateSelectedMetricContainer: FC<ReportCreateSelectedMetricProps> = ({ onRemove, children, source, filterKey }) => {
  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();

  const queryClient = useQueryClient();

  const [isOpen, setIsOpen] = useState(false);

  const { register, handleSubmit, reset } = useForm<SaveMetricFormFields>();

  const { reportType, config: reportConfigState, getConfigMetricId } = useReportsStore((state) => state);

  const setMetricType = useReportsStore((state) => state.setMetricType);
  const setMetricMetric = useReportsStore((state) => state.setMetricMetric);
  const updateQuery = useReportsStore((state) => state.updateQuery);

  const filter = useFilterStore((state) => state.getFilter(filterKey));

  const membersQuery = useQuery({
    queryKey: queryKeys.members(),
    queryFn: () => bigdeltaAPIClient.v1.membersList({ workspace_id: currentWorkspaceId }),
  });

  const saveMetricMutation = useMutation({
    mutationFn: (payload: MetricsCreatePayload) => bigdeltaAPIClient.v1.metricsCreate(payload),
    onSuccess: (response) => {
      queryClient.invalidateQueries(['metrics']);
      reset();
      setIsOpen(false);
      toastSuccess('Created', 'Metric created successfully');

      setMetricType(ReportSourceType.METRIC);
      setMetricMetric(response.id ?? undefined);
      updateQuery([]);
    },
    onError: () => {
      toastError('An error occurred while creating the metric');
    },
  });

  const clearFilter = useFilterStore((state) => state.clearFilter);

  const isLoading = false;

  const handleClearFilter = () => {
    clearFilter(filterKey);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const onSubmit = handleSubmit((data: SaveMetricFormFields) => {
    const metricId = getConfigMetricId();
    if (reportConfigState.metricType && metricId) {
      const [metricQuery] = getMetrics(reportType, reportConfigState.metricType, metricId, filter, reportConfigState) ?? [];

      saveMetricMutation.mutate({
        workspace_id: currentWorkspaceId,
        display_name: data.displayName,
        query_configuration: {
          metric_query: metricQuery,
        },
        description: data.description,
        source: MetricSource.USER,
        owner: data.owner,
      });
    }
  });

  return (
    <>
      <div className="group/card flex w-full flex-col items-stretch rounded-lg border border-m-olive-100">
        <div className="flex items-center justify-between gap-x-1.5 px-2 py-1">
          {source}
          <button
            onClick={onRemove}
            className="mt-[10px] cursor-pointer self-start rounded text-m-olive-400 hover:bg-m-red-100 hover:text-m-red-600 group-hover/card:visible"
          >
            <CloseIcon className="h-5 w-5" />
          </button>
          <Menu>
            <Float portal autoPlacement offset={4}>
              <Menu.Button className="mt-[10px] self-start rounded text-m-olive-400 hover:bg-m-gray-300">
                <DotsIcon className="h-5 w-5" />
              </Menu.Button>
              <Menu.Items className="flex w-48 flex-col rounded-lg border border-m-gray-300 bg-m-white p-2 shadow-md">
                {/*{!disableCreateMetric && (*/}
                {/*  <Menu.Item>*/}
                {/*    <button*/}
                {/*      className="flex items-center justify-start gap-x-2 rounded-md px-2.5 py-2 text-sm text-m-olive-800 hover:bg-m-gray-300"*/}
                {/*      onClick={handleStartSaveMetric}*/}
                {/*    >*/}
                {/*      <BracketsPlusIcon className="h-4 w-4" />*/}
                {/*      <span>Save as metric</span>*/}
                {/*    </button>*/}
                {/*  </Menu.Item>*/}
                {/*)}*/}
                <Menu.Item>
                  <button
                    className="flex items-center justify-start gap-x-2 rounded-md px-2.5 py-2 text-sm text-m-olive-800 hover:bg-m-gray-300"
                    onClick={handleClearFilter}
                  >
                    <TrashIcon className="h-4 w-4" />
                    <span>Clear filter</span>
                  </button>
                </Menu.Item>
              </Menu.Items>
            </Float>
          </Menu>
        </div>
        {children}
      </div>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-[999999]">
        <div className="fixed inset-0 bg-m-gray-700 opacity-95" aria-hidden="true" />
        <div className="fixed inset-0 flex w-screen items-center justify-center">
          <Dialog.Panel className="mx-auto flex w-3/5 max-w-lg flex-col gap-y-6 rounded-xl bg-white">
            <Dialog.Title className="p-6 pb-0 text-xl text-m-olive-700">Save as Metric</Dialog.Title>
            <form onSubmit={onSubmit}>
              <div className="grid grid-cols-[minmax(140px,_auto)_1fr] items-center gap-y-6 px-6 py-3">
                <label className="text-sm text-m-olive-600">Name</label>
                <input
                  placeholder="Metric name"
                  className="rounded-md border border-m-gray-300 text-xs"
                  {...register('displayName', { required: true })}
                />

                <label className="text-sm text-m-olive-600">Short name</label>
                <input
                  placeholder="Short metric name"
                  className="rounded-md border border-m-gray-300 text-xs"
                  {...register('shortName', { required: true })}
                />

                <label className="text-sm text-m-olive-600">Owner</label>
                <select className="rounded-md border border-m-gray-300 text-xs" {...register('owner', { required: true })}>
                  {membersQuery.data?.map((member) => (
                    <option key={member.id} value={member.id}>
                      {member.email}
                    </option>
                  ))}
                </select>

                <label className="self-start text-sm text-m-olive-600">Description (optional)</label>
                <textarea className="resize-none rounded-md border border-m-gray-300 px-3 py-2 text-xs" {...register('description')} />
              </div>
              <hr className="my-3.5 h-px bg-m-gray-300" />
              <div className="flex w-full justify-end gap-x-3.5 p-6 pt-3.5">
                <Button label="Cancel" intent="secondary" size="xl" type="button" onClick={handleClose} />
                <Button label={!isLoading ? 'Save' : <LoadingIcon className="h-4 w-4 animate-spin" />} intent="brand" size="xl" type="submit" />
              </div>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toOpportunityVO = exports.CreateOpportunityRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
exports.CreateOpportunityRequestDef = typebox_1.Type.Object({
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    title: typebox_1.Type.Optional(typebox_1.Type.String()),
    description: typebox_1.Type.Optional(typebox_1.Type.String()),
    details: typebox_1.Type.Optional(typebox_1.Type.String()),
    owner: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
    document_group_ids: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(typebox_1.Type.String({ format: 'uuid' }))),
    opportunity_metric: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
    opportunity_ambition: typebox_1.Type.Optional(typebox_1.Type.String()),
    impact_metric: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
    impact_size: typebox_1.Type.Optional(typebox_1.Type.String()),
});
const toOpportunityVO = (opportunity) => {
    return {
        workspaceId: opportunity.workspace_id,
        title: opportunity.title,
        description: opportunity.description,
        details: opportunity.details,
        owner: opportunity.owner,
        documentGroupIds: opportunity.document_group_ids,
        opportunityMetric: opportunity.opportunity_metric,
        opportunityAmbition: opportunity.opportunity_ambition,
        impactMetric: opportunity.impact_metric,
        impactSize: opportunity.impact_size,
    };
};
exports.toOpportunityVO = toOpportunityVO;

import { AliasPrefix, ReportSourceType } from '../store';

export const getPropertyPrefix = (sourceType: ReportSourceType) => {
  switch (sourceType) {
    case ReportSourceType.EVENTS:
    case ReportSourceType.METRIC:
      return AliasPrefix.EVENT_ATTRIBUTE;
    case ReportSourceType.RECORDS:
      return AliasPrefix.RECORD_ATTRIBUTE;
    default:
      return null;
  }
};

import { useMemo, ChangeEvent, useState, FC, HTMLAttributes } from 'react';
import { ComboboxInfinite } from '../../components/Combobox/ComboboxInfinite';
import { useMetadataEventsInfiniteQuery } from '../data/useMetadataEventsInfiniteQuery';
import { useDebounce } from '@uidotdev/usehooks';
import { ALL_EVENTS } from '../filters/store';

type EventNameComboboxSingleProps = Omit<HTMLAttributes<HTMLElement>, 'onChange'> & {
  workspaceId: string;
  includeAny?: boolean;
  value: string | undefined;
  onChange: (value: { event_name: string } | null) => void;
  close?: () => void;
};

export const EventsCombobox: FC<EventNameComboboxSingleProps> = ({ includeAny, value, workspaceId, onChange, close, ...rest }) => {
  const [rawSearchQuery, setRawSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(rawSearchQuery, 700);

  const {
    data: eventNames,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isFetching,
    isSuccess,
  } = useMetadataEventsInfiniteQuery({
    workspaceId: workspaceId,
    searchQuery: debouncedSearchQuery,
  });

  const flatEventNames = useMemo(
    () => (eventNames ? [...(includeAny ? [{ event_name: ALL_EVENTS }] : []), ...eventNames.pages.flatMap((d) => d.items)] : []),
    [eventNames, includeAny]
  );

  return (
    <ComboboxInfinite
      items={flatEventNames}
      selectedItems={value ? { event_name: value } : null}
      onChange={(data) => {
        onChange(data);
        close && close();
      }}
      onQueryChange={(e: ChangeEvent<HTMLInputElement>) => setRawSearchQuery(e.target.value)}
      query={rawSearchQuery}
      height={450}
      catchInputFocus
      renderOption={(item) => {
        return item?.event_name;
      }}
      isFetching={isFetching}
      hasNextPage={!!hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
      isSuccess={isSuccess}
      {...rest}
    />
  );
};

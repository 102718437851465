import { WorkspaceObjectType } from '@bigdelta/lib-shared';
import { upperFirst } from 'lodash';
import { FC, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../../features/auth/hooks/useQueryKeys';
import { useWorkspace } from '../../features/auth/hooks/useWorkspace';
import { getWorkspaceObjectIcon } from '../utils/getWorkspaceObjectIcon';
import { Listbox } from './Listbox';
import { bigdeltaAPIClient } from '../../client/bigdeltaAPIClient.ts';

interface WorkspaceObjectSelectProps {
  onChange: (workspaceObjectId: string) => void;
  only?: WorkspaceObjectType[];
  value: string | null;
}

export const WorkspaceObjectSelect: FC<WorkspaceObjectSelectProps> = ({ only, onChange, value }) => {
  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();

  const objectsQuery = useQuery({
    queryKey: queryKeys.list('object'),
    queryFn: () => bigdeltaAPIClient.v1.objectsList({ workspace_id: currentWorkspaceId }),
  });

  const objectsOptions = useMemo(() => {
    if (!objectsQuery.data?.objects) return [];

    return objectsQuery.data?.objects
      .filter((object) => (only ? only.includes(object.object_type as WorkspaceObjectType) : true))
      .map((object) => ({
        value: object.id,
        label: upperFirst(object.plural_noun),
        icon: getWorkspaceObjectIcon(object.object_type),
      }));
  }, [objectsQuery.data?.objects, only]);

  const handleChange = (data) => {
    onChange(data.value);
  };

  const selectedOption = objectsOptions.find((option) => option.value === value) ?? null;

  return (
    <div className="flex w-full flex-col">
      <Listbox options={objectsOptions} value={selectedOption} placeholder="Select workspace object" onChange={handleChange} />
    </div>
  );
};

import React, { FC, useMemo } from 'react';
import { ReportSourceType, useReportStore } from '../store';
import { ReportMetric } from './ReportMetric.tsx';
import { ReportBreakdownPropertyBlock } from './ReportBreakdownPropertyBlock.tsx';
import { ButtonIntent } from '../../../shared/ui/Button/Button.tsx';
import { FilterKey } from '../../../shared/filters/store';
import { tracking, TrackingEvent } from '../../../tracking';

export type ReportBuilderProps = {
  workspaceId?: string;
  reportId: string;
  inline: boolean;
  selectorIntent?: ButtonIntent;
  filterKey: FilterKey;
  isFormulaMetricChild?: boolean;
  formulaReference?: string;
  disableRemoval?: boolean;
  breakdownDisabled?: boolean;
  onRemoveMetric?: () => void;
};

export const ReportQueryBuilder: FC<ReportBuilderProps> = ({
  workspaceId,
  reportId,
  inline,
  selectorIntent,
  filterKey,
  isFormulaMetricChild,
  formulaReference,
  onRemoveMetric,
  disableRemoval,
  breakdownDisabled,
}) => {
  const { config: reportConfigState, setBreakdownProperty } = useReportStore()((state) => state);

  const showGrouping = useMemo(() => {
    if (!reportConfigState || isFormulaMetricChild || breakdownDisabled) {
      return false;
    }

    return (
      (reportConfigState.metricType === ReportSourceType.EVENTS && reportConfigState.metricEvent) ||
      (reportConfigState.metricType === ReportSourceType.RECORDS && reportConfigState.metricObject)
      // || (reportConfigState.metricType === ReportSourceType.METRIC && reportConfigState.metricMetric)
    );
  }, [reportConfigState, isFormulaMetricChild, breakdownDisabled]);

  return (
    <div className="flex w-full flex-col gap-4">
      <ReportMetric
        inline={inline}
        selectorIntent={selectorIntent}
        filterKey={filterKey}
        formulaReference={formulaReference}
        onRemoveMetric={onRemoveMetric}
        disableRemoval={disableRemoval}
      />
      {showGrouping && (
        <ReportBreakdownPropertyBlock
          workspaceId={workspaceId!}
          breakdownProperties={[reportConfigState.breakdownProperty]}
          onChange={(data) => {
            setBreakdownProperty(data.length > 0 ? data[0] : null);
            if (data.length > 0) {
              tracking.track(TrackingEvent.ReportBreakdownSelected, {
                'report id': reportId,
                'data type': reportConfigState.metricType,
                'breakdown property id': data[0].property_id,
                'breakdown property name': data[0].property_name,
              });
            }
          }}
          inline={inline}
        />
      )}
    </div>
  );
};

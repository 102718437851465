import { QueryReportsCreatePayload } from '@bigdelta/lib-api-client';
import { ReportTimerange } from '../../store/Report';
import { DateRangeType } from '@bigdelta/lib-shared';

export const getTimerangeFromQueryTime = (queryTime: QueryReportsCreatePayload['time']): ReportTimerange | undefined => {
  if (!queryTime?.window || queryTime.date_range_type !== DateRangeType.IN_THE_LAST) {
    return;
  }

  const { value, unit } = queryTime.window;

  if (value === 24 && unit === 'hour') {
    return ReportTimerange['24H'];
  }

  if (value === 7 && unit === 'day') {
    return ReportTimerange['7D'];
  }

  if (value === 30 && unit === 'day') {
    return ReportTimerange['30D'];
  }

  if (value === 3 && unit === 'month') {
    return ReportTimerange['3M'];
  }

  if (value === 6 && unit === 'month') {
    return ReportTimerange['6M'];
  }

  if (value === 12 && unit === 'month') {
    return ReportTimerange['12M'];
  }

  if (value === 24 && unit === 'month') {
    return ReportTimerange['24M'];
  }

  return;
};

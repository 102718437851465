import { NestedObjectDef, ObjectsDetailData, ObjectsRecordDetailData } from '@bigdelta/lib-api-client';
import { FC } from 'react';
import { RecordName } from './RecordName';
import { AttributeValue } from './AttributeValue';
import { RecordPropertyIcon } from '../../../shared/components/RecordPropertyIcon';
import { OverflowText } from '../../../shared/components/OverflowText';

interface PropertiesTableProps {
  properties: ObjectsRecordDetailData['properties'];
  object: ObjectsDetailData;
}

type NestedValue = NestedObjectDef[string];

const PropertiesTable = ({ properties, object }: PropertiesTableProps) => {
  const notTitleOrLogo = ([key]: [string, NestedValue]) => !object.label_properties.includes(key) && key !== object.icon_property;

  const fields = Object.entries(properties).filter(notTitleOrLogo);

  return fields.map(([key, value]) => (
      <div className="flex" key={key}>
        <div className="w-2/5 text-ellipsis text-m-olive-500 capitalize mr-2">
          <div className='flex flex-nowrap justify-start items-center'>
            <RecordPropertyIcon className="h-4 mr-2 shrink-0" propertyType={object.properties?.find(({ name }) => name === key)?.type} />
            <OverflowText>{key}</OverflowText>
          </div>
        </div>
        <div className="w-3/5 min-w-32 text-m-olive-600 flex flex-wrap justify-start items-center gap-2">
          <AttributeValue overflowVisible value={value} />
        </div>
      </div>
  ));
};

interface RecordPropertiesProps {
  record: ObjectsRecordDetailData;
  object: ObjectsDetailData;
}

export const RecordProperties: FC<RecordPropertiesProps> = ({ record, object }) => {
  return (
    <>
      <h4 className="w-full border-b border-m-olive-100 p-4 text-xs font-medium leading-none text-m-olive-600">
        <div className="flex h-4 items-center">Record Details</div>
      </h4>
      <div className="flex min-w-0 flex-1 gap-y-2 grow basis-0 flex-col overflow-y-auto border-r border-m-olive-100 p-4 text-sm">
        <RecordName object={object} record={record} />
        <PropertiesTable properties={record.properties} object={object} />
      </div>
    </>
  );
};

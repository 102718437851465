"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toMetricGraphEdgeResponse = exports.MetricGraphEdgeResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const memberResponse_1 = require("./memberResponse");
exports.MetricGraphEdgeResponseDef = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    graph_id: typebox_1.Type.String({ format: 'uuid' }),
    parent_node_id: typebox_1.Type.String({ format: 'uuid' }),
    child_node_id: typebox_1.Type.String({ format: 'uuid' }),
    influence: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
    created_by: (0, typebox_2.OptionalNullable)(memberResponse_1.MemberResponseDef),
    updated_by: (0, typebox_2.OptionalNullable)(memberResponse_1.MemberResponseDef),
    created_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    updated_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
});
const toMetricGraphEdgeResponse = (metricGraphEdge) => {
    return {
        id: metricGraphEdge.id,
        workspace_id: metricGraphEdge.workspace_id,
        graph_id: metricGraphEdge.graph_id,
        parent_node_id: metricGraphEdge.parent_node_id,
        child_node_id: metricGraphEdge.child_node_id,
        influence: metricGraphEdge.influence,
        created_by: (0, memberResponse_1.toMemberResponse)(metricGraphEdge.created_by),
        updated_by: (0, memberResponse_1.toMemberResponse)(metricGraphEdge.updated_by),
        created_at: metricGraphEdge.systemCreatedAt,
        updated_at: metricGraphEdge.systemUpdatedAt,
    };
};
exports.toMetricGraphEdgeResponse = toMetricGraphEdgeResponse;

import React, { useContext, useEffect } from 'react';
import { AccountProvider } from '../context/accountContext.tsx';
import { Outlet, useNavigate } from 'react-router-dom';
import { UserContext } from '../context/userContext.tsx';
import { AppRoutes } from '../../../routes';

export const AccountContextRoute = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  useEffect(() => {
    if (userContext.ready) {
      if (!userContext.accounts?.length) {
        navigate(AppRoutes.ACCOUNT_ONBOARDING);
      }
    }
  }, [navigate, userContext.ready, userContext.authenticated, userContext.user, userContext.accounts?.length, userContext.workspaces?.length]);

  if (!userContext.ready || !userContext.authenticated) {
    return null;
  }

  return (
    <AccountProvider>
      <Outlet />
    </AccountProvider>
  );
};

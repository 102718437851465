import { useMutation } from '@tanstack/react-query';

import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

interface UseSegmentCreateMutationArgs {
  onSuccess?: () => void;
}

interface UpdateWorkspaceMutationParameters {
  workspaceId: string;
  data: {
    name?: string;
    logo?: string;
  };
}

export const useUpdateWorkspaceMutation = ({ onSuccess }: UseSegmentCreateMutationArgs = {}) => {
  return useMutation({
    mutationFn: ({ workspaceId, data }: UpdateWorkspaceMutationParameters) => {
      return bigdeltaAPIClient.v1.workspacesPartialUpdate(workspaceId, data);
    },
    onSuccess,
  });
};

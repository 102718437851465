import { Edge, MarkerType } from 'reactflow';

export const getEdge = (id: string, source: string, target: string): Edge => ({
  id,
  source: source,
  target: target,
  type: 'smoothstep',
  markerEnd: {
    type: MarkerType.Arrow,
    color: '#898A7E',
  },
  style: {
    strokeWidth: 4,
    stroke: '#898A7E',
  },
});

import { PanelSize, PanelType } from '@bigdelta/lib-shared';
import { FC, useRef } from 'react';
import { RecordProfilePanelBase } from './RecordProfilePanelBase';
import { useQuery } from '@tanstack/react-query';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { ObjectsProfileLayoutsDetailData, QueryRecordsCreateData } from '@bigdelta/lib-api-client';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useRecordsTableColumns } from '../hooks/useRecordsTableColumns';
import { useObjectQuery } from '../../../shared/data/useObjectQuery';
import { DataTable } from '../../../shared/tables/components/DataTable';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';

interface RecordProfilePanelTableProps {
  panel: ObjectsProfileLayoutsDetailData['tabs'][number]['panels'][number];
}

const columnHelper = createColumnHelper<QueryRecordsCreateData['items'][number]>();

export const RecordProfilePanelTable: FC<RecordProfilePanelTableProps> = ({ panel }) => {
  const { currentWorkspaceId } = useWorkspace();

  const queryKeys = useQueryKeys();

  const tableContainerRef = useRef<HTMLDivElement>(null);

  // TODO: this is a workaround to get the workspace object id from the panel data
  const workspaceObjectId = panel.data.table?.query.query.resource.id;
  const { data: object } = useObjectQuery({ objectSlug: workspaceObjectId, workspaceId: currentWorkspaceId });

  const tableQueryPayload = panel.data.table?.query;

  const records = useQuery({
    queryKey: queryKeys.single('object', 'profile', 'panel', panel.name, tableQueryPayload, 'table'),
    queryFn: () => bigdeltaAPIClient.v1.queryRecordsCreate({ workspace_id: currentWorkspaceId }, tableQueryPayload!),
    enabled: !!tableQueryPayload,
  });

  const columns = useRecordsTableColumns({
    columnHelper,
    workspaceId: currentWorkspaceId,
    workspaceObject: object,
    displayProperties: true,
    enableActions: false,
    layout: panel.data.table?.layout_config,
    onLayoutChange: () => {},
  });

  const columnPinning = {
    right: [],
    left: ['label'],
  };

  const table = useReactTable({
    columns,
    data: records.data?.items ?? [],
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnPinning,
    },
  });

  if (panel.type !== PanelType.TABLE) {
    return null;
  }

  return (
    <RecordProfilePanelBase title={panel.name} size={panel.size as PanelSize}>
      <div className="overflow-x-auto overscroll-none" ref={tableContainerRef}>
        <DataTable isDataFetching={false} isHeaderFetching={false} tableContainerRef={tableContainerRef} table={table} />
      </div>
    </RecordProfilePanelBase>
  );
};

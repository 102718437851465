import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../../features/auth/hooks/useQueryKeys.ts';
import { useWorkspace } from '../../features/auth/hooks/useWorkspace.tsx';
import { bigdeltaAPIClient } from '../../client/bigdeltaAPIClient.ts';

export const useMembersQuery = () => {
  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();

  return useQuery({
    queryKey: queryKeys.members(),
    queryFn: () => bigdeltaAPIClient.v1.membersList({ workspace_id: currentWorkspaceId }),
  });
};

import { Link } from 'react-router-dom';
import { EmptyState } from '../../../shared/components/EmptyState';
import { Button } from '../../../shared/ui/Button/Button';

import DataflowIcon from '../../../assets/icons/dataflow-04.svg?react';
import DataIcon from '../../../assets/icons/data.svg?react';
import MessageIcon from '../../../assets/icons/notification-message.svg?react';

const EMPTY_STATE_STAGES = [
  {
    icon: DataflowIcon,
    title: 'Visual canvas',
    description: 'Build automated workflows by placing and linking blocks on canvas.',
  },
  {
    icon: DataIcon,
    title: 'Integrate',
    description: 'Use data from Stripe, HubSpot, Intercom or other tools.',
  },
  {
    icon: MessageIcon,
    title: 'In the loop',
    description: 'Get notifications in Slack when something important happens.',
  },
];

export const AutomationsEmptyState = () => (
  <EmptyState heading="Automate anything" stages={EMPTY_STATE_STAGES}>
    <Link to="new">
      <Button intent="brand" size="xl" label="Create automation" />
    </Link>
  </EmptyState>
);

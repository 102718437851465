import { ChartData } from 'chart.js';
import { ComponentProps, FC } from 'react';
import { Bar } from 'react-chartjs-2';
import { formatMonetary } from '../../../shared/utils/formatMonetary';
import { QueryReportsCreateData } from '@bigdelta/lib-api-client';
import { Datapoint } from '../types';
import { merge } from 'lodash';

interface VerticalBarChartProps {
  labels?: string[];
  datasets: ChartData<'bar', Datapoint[], string>['datasets'];
  metadata: QueryReportsCreateData['queries'][number]['metadata'];
  options?: ComponentProps<typeof Bar>['options'];
}

export const VerticalBarChart: FC<VerticalBarChartProps> = ({ datasets, metadata, options }) => {
  return (
    <Bar
      data={{
        datasets,
      }}
      options={merge(
        {
          plugins: {
            datalabels: {
              backgroundColor: 'white',
              borderRadius: 4,
              borderWidth: 1,
              borderColor: '#000',
              padding: 6,
              offset: -10,
              textAlign: 'center',
              align: 'start',
              formatter: function (_, context) {
                const data = context.dataset.data[context.dataIndex] as Datapoint;
                const conversionRate = Number(data.conversion_rate === null && data.y > 0 ? 1 : data.conversion_rate || 0).toLocaleString('en', {
                  style: 'percent',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                });
                return `${conversionRate}\n${data.y}`;
              },
              anchor: 'end',
              color: '#000',
            },
            tooltip: {
              enabled: false,
            },
          },
          maintainAspectRatio: false,
          responsive: true,
          elements: {
            point: {
              pointStyle: false,
            },
            line: {
              borderColor: '#5B70DF',
              tension: 0.01,
            },
          },
          scales: {
            x: {
              offset: true,
              stacked: true,
              offsetAfterAutoskip: true,
              ticks: {
                color: '#898A7E',
                display: !!datasets?.length,
                autoSkip: true,
                maxTicksLimit: 20,
                maxRotation: 0,
                minRotation: 0,
              },
              border: {
                display: false,
              },
              grid: {
                display: false,
              },
            },
            y: {
              offset: true,
              stacked: true,
              ticks: {
                color: '#898A7E',
                display: !!datasets?.length,
                callback: (rawValue) => {
                  if (metadata?.format?.monetary && typeof rawValue === 'number') {
                    return formatMonetary(rawValue, metadata.format.monetary);
                  }

                  return rawValue;
                },
              },
              border: {
                display: false,
              },
              grid: {
                color: '#EBECEB',
              },
            },
          },
        },
        options
      )}
    />
  );
};

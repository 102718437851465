import { AutomationsCreateData } from '@bigdelta/lib-api-client';
import { NodeAutomationTrigger } from './components/NodeAutomationTrigger';
import { NodeAutomationBlockEmpty } from './components/NodeAutomationBlockEmpty';
import { NodeAutomationBlock } from './components/NodeAutomationBlock';

export enum TriggerType {
  RECORD_CREATED = 'RECORD_CREATED',
  RECORD_UPDATED = 'RECORD_UPDATED',
  RECORD_DELETED = 'RECORD_DELETED',
  EVENT_OCCURRED = 'EVENT_OCCURRED',
}

export type AutomationBlock = AutomationsCreateData['configuration']['trigger']['block'];

export type CustomNodeData = NodeAutomationTrigger['data'] | NodeAutomationBlockEmpty['data'] | NodeAutomationBlock['data'];

export type CustomNode = NodeAutomationTrigger | NodeAutomationBlockEmpty | NodeAutomationBlock;

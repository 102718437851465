import { useInfiniteQuery } from '@tanstack/react-query';

import { MetadataResourcePropertyType } from '@bigdelta/lib-shared';
import { bigdeltaAPIClient } from '../../client/bigdeltaAPIClient.ts';

interface UseMetadataPropertiesValuesInfiniteQueryArgs {
  resourceId?: string;
  workspaceId?: string;
  propertyId?: string;
  resourceType?: MetadataResourcePropertyType;
  searchQuery: string;
}

// attributeId ?? '', objectId ?? '',

export const useMetadataPropertiesValuesInfiniteQuery = ({
  resourceId,
  workspaceId,
  propertyId,
  resourceType,
  searchQuery,
}: UseMetadataPropertiesValuesInfiniteQueryArgs) => {
  return useInfiniteQuery(
    ['metadata', resourceId, 'attributes', propertyId, 'values', searchQuery],
    ({ pageParam }) => {
      return bigdeltaAPIClient.v1.metadataResourcesPropertiesValuesList({
        resource_id: resourceId,
        property_id: propertyId ?? '',
        workspace_id: workspaceId ?? '',
        limit: 50,
        offset: pageParam ?? 0,
        query: searchQuery,
        resource_type: resourceType,
      });
    },
    {
      enabled: !!workspaceId && !!propertyId,
      getNextPageParam: (lastPage) => {
        if (lastPage.range.to + 1 < lastPage.range.total) {
          return lastPage.range.to + 1;
        } else {
          return undefined;
        }
      },
    }
  );
};

import { Float } from '@headlessui-float/react';
import { Popover } from '@headlessui/react';
import { EventsCombobox } from '../../../shared/components/EventsCombobox';

import React, { FC, useCallback, useEffect } from 'react';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { RelationshipEntityType } from '@bigdelta/lib-shared';
import PlusIcon from '../../../assets/icons/plus.svg?react';
import CloseIcon from '../../../assets/icons/x-close.svg?react';
import { useReportStore } from '../store';
import { RelationshipSelect } from '../../../shared/components/RelationshipSelect.tsx';
import { ConversionWindowSelect } from './ConversionWindowSelect.tsx';

type Props = {
  reportKey?: string;
};

export const ReportFunnelBuilder: FC<Props> = ({ reportKey }) => {
  const { config, setFunnelRelationshipName, setFunnelSequenceCompletionTimeUnit, setFunnelSequenceCompletionValue, setFunnelEventSequence } =
    useReportStore(reportKey)((state) => state);
  const { currentWorkspaceId } = useWorkspace();

  const handleSelectEvent = useCallback(
    (value: any, idx: number) => {
      setFunnelEventSequence(
        config.funnelEventSequence.map((item, eventIdx) => (eventIdx === idx ? { event_name: { name: value.event_name } } : item))
      );
    },
    [config.funnelEventSequence, setFunnelEventSequence]
  );
  const handleRemoveEvent = useCallback(
    (idx: number) => {
      setFunnelEventSequence(config.funnelEventSequence.filter((_, eventIdx) => eventIdx !== idx));
    },
    [config.funnelEventSequence, setFunnelEventSequence]
  );
  const handleAddSequenceStep = useCallback(() => {
    setFunnelEventSequence([...config.funnelEventSequence, null]);
  }, [config.funnelEventSequence, setFunnelEventSequence]);

  useEffect(() => {
    if (!config.funnelEventSequence || config.funnelEventSequence?.length === 0) {
      setFunnelEventSequence([null]);
    }
  }, [config.funnelEventSequence, setFunnelEventSequence]);

  return (
    <div className="flex flex-col gap-y-4 rounded-lg text-sm">
      <div className="flex flex-col gap-y-2">
        <div className="flex items-center justify-between">
          <div>Event sequence</div>
          <button className="cursor-pointer rounded text-m-olive-400 hover:bg-m-gray-300" onClick={handleAddSequenceStep}>
            <PlusIcon className="h-5 w-5" />
          </button>
        </div>
        {config.funnelEventSequence?.map((item, idx) => (
          <div className="flex w-full items-center gap-1 rounded-lg border border-m-olive-100 p-1">
            <Popover className="flex-1">
              {({ close }) => (
                <Float portal adaptiveWidth offset={2}>
                  <Popover.Button className="flex-start flex w-full items-center gap-x-2 rounded-lg bg-m-white p-2 text-sm hover:bg-m-gray-200">
                    <div className="flex h-4 w-4 items-center justify-center rounded-sm bg-m-gray-300 text-m-gray-700">{idx + 1}</div>
                    {!item && <span className="text-m-olive-600">Select event</span>}
                    {item?.event_name && <span className="text-m-olive-900">{item?.event_name?.name}</span>}
                  </Popover.Button>
                  <Popover.Panel>
                    <EventsCombobox
                      workspaceId={currentWorkspaceId}
                      value={undefined}
                      onChange={(value) => handleSelectEvent(value, idx)}
                      close={close}
                    />
                  </Popover.Panel>
                </Float>
              )}
            </Popover>
            {config.funnelEventSequence?.length > 1 && (
              <div className="flex items-center justify-center">
                <button className="cursor-pointer rounded p-1 text-m-olive-400 hover:bg-m-gray-300" onClick={() => handleRemoveEvent(idx)}>
                  <CloseIcon className="h-5 w-5" />
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="flex items-center justify-between">
          <div>Customer type</div>
        </div>
        <RelationshipSelect
          value={config.funnelRelationshipName}
          parentEntityType={RelationshipEntityType.EVENT}
          onChange={setFunnelRelationshipName}
        />
      </div>
      <ConversionWindowSelect
        value={config.funnelSequenceCompletionValue}
        timeUnit={config.funnelSequenceCompletionTimeUnit}
        onChangeTimeUnit={setFunnelSequenceCompletionTimeUnit}
        onChangeValue={setFunnelSequenceCompletionValue}
      />
    </div>
  );
};

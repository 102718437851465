import { QueryRecordsCreateData, RelationshipColumnDef } from '@bigdelta/lib-api-client';
import { TableColumnType } from '@bigdelta/lib-shared';

// TODO: combine with resolvePropertyValue?

type RelationshipPropertiesType = NonNullable<QueryRecordsCreateData['items'][number]['relationships']>['relationship_properties'];
export type RelationType = NonNullable<NonNullable<RelationshipPropertiesType>[number]['relations']>[number];

export const resolveRelations = (
  relationshipProperties: NonNullable<QueryRecordsCreateData['items'][number]['relationships']>['relationship_properties'],
  columnDef: RelationshipColumnDef
): RelationType[] | null | undefined => {
  if (columnDef.type === TableColumnType.PROPERTY && columnDef.property) {
    const relationshipName = columnDef.name;
    const foundRelationshipObject = relationshipProperties?.find((rel) => rel.relationship_name === relationshipName);

    return foundRelationshipObject?.relations;
  }

  if (columnDef.type === TableColumnType.RELATIONSHIP && columnDef.relationship) {
    const relationshipName = columnDef.name;
    const foundRelationshipPropertiesObject = relationshipProperties?.find((rel) => rel.relationship_name === relationshipName);

    const nestedRelationshipDef = columnDef.relationship;

    return (
      foundRelationshipPropertiesObject?.relations?.flatMap((relation) =>
        resolveRelations(relation.relationship_properties, nestedRelationshipDef)
      ) ?? null
    )?.flatMap((relation) => (relation ? [relation] : []));
  }
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toMemberResponse = exports.MemberResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
exports.MemberResponseDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: typebox_1.Type.String({ format: 'uuid' }),
        first_name: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
        last_name: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
        email: typebox_1.Type.String(),
        is_onboarding_form_filled: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
        preferred_timezone: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
        photo_image_url: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    }),
]);
const toMemberResponse = (member) => {
    if (!member) {
        return null;
    }
    return {
        id: member.id,
        first_name: member.first_name,
        last_name: member.last_name,
        email: member.email,
        is_onboarding_form_filled: member.is_onboarding_form_filled,
        preferred_timezone: member.preferred_timezone,
        photo_image_url: member.photo_image_url,
    };
};
exports.toMemberResponse = toMemberResponse;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toSegmentResponse = exports.SegmentResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const recordsDataQueryVO_1 = require("../value-object/recordsDataQueryVO");
const eventsDataQueryVO_1 = require("../value-object/eventsDataQueryVO");
const typebox_2 = require("../typebox");
const types_1 = require("../types");
exports.SegmentResponseDef = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    account_id: typebox_1.Type.String({ format: 'uuid' }),
    name: typebox_1.Type.String(),
    description: typebox_1.Type.Optional(typebox_1.Type.String()),
    resource_type: typebox_1.Type.Enum(types_1.SegmentResourceType),
    events_query: (0, typebox_2.OptionalNullableRef)(typebox_1.Type.Ref(eventsDataQueryVO_1.EventsDataQueryRequestDef)),
    records_query: (0, typebox_2.OptionalNullable)(recordsDataQueryVO_1.RecordsDataQueryRequestDef),
    created_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    updated_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
});
const toSegmentResponse = (segment) => {
    return {
        id: segment.id,
        account_id: segment.accountId,
        created_at: segment.systemCreatedAt,
        description: segment.description,
        name: segment.name,
        records_query: segment.recordsQuery,
        events_query: segment.eventsQuery,
        resource_type: segment.resourceType,
        updated_at: segment.systemUpdatedAt,
        workspace_id: segment.workspaceId,
    };
};
exports.toSegmentResponse = toSegmentResponse;

import { FC, Fragment } from 'react';
import { useFilterContext } from '../../../../shared/filters/context/useFilterContext.ts';
import { FilterItemType, getFilter, useFilterStore } from '../../../../shared/filters/store/index.ts';
import { Control } from '../../../../shared/filters/components/common/Control.tsx';
import { EventPropertySelect } from '../../../../shared/filters/components/common/EventPropertySelect.tsx';
import { PropertyOperatorSelect } from '../../../../shared/filters/components/common/PropertyOperatorSelect.tsx';
import { MetadataResourcePropertyType } from '@bigdelta/lib-shared';
import { PropertyValue } from '../../../../shared/filters/components/common/PropertyValue.tsx';
import XIcon from '../../../../assets/icons/x-close.svg?react';
import FilterIcon from '../../../../assets/icons/filter-funnel-01.svg?react';
import { ActionButtonWithTooltip } from '../../../../shared/filters/components/common/ActionButtonWithTooltip.tsx';

interface TrendsCountFilterProps {
  id: string;
}

export const TrendsCountFilter: FC<TrendsCountFilterProps> = () => {
  const { filterKey } = useFilterContext();
  const filter = useFilterStore((state) => getFilter(state, filterKey))?.items ?? [];
  const setItemPropertyOperator = useFilterStore((state) => state.setItemPropertyOperator);
  const removeItem = useFilterStore((state) => state.removeItem);

  const trendsCountFilterNum = filter.filter((filterItem) => filterItem.itemType === FilterItemType.TRENDS_COUNT_EVENT_PROPERTY).length;

  if (!trendsCountFilterNum) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-1 pt-2">
      {filter.map((filterItem, filterIndex) => {
        if (filterItem.itemType !== FilterItemType.TRENDS_COUNT_EVENT_PROPERTY) {
          return null;
        }

        return (
          <Fragment key={JSON.stringify(filterItem.property)}>
            <div className="ml-px flex flex-row items-center justify-between pl-3">
              <div className="flex flex-row items-center gap-1">
                <FilterIcon className="h-4 w-4 text-m-olive-300" />
                <Control>
                  <EventPropertySelect index={filterIndex} filterItem={filterItem} />
                </Control>
                {filterItem.property && (
                  <>
                    <Control shouldShrink={false}>
                      <PropertyOperatorSelect
                        index={filterIndex}
                        value={filterItem.propertyOperator}
                        onChange={(index, value) => setItemPropertyOperator(filterKey, index, value)}
                      />
                    </Control>
                    <Control>
                      <PropertyValue index={filterIndex} resourceType={MetadataResourcePropertyType.EVENT} />
                    </Control>
                  </>
                )}
              </div>
              <ActionButtonWithTooltip
                onClick={() => {
                  removeItem(filterKey, filterIndex);
                }}
                tooltipText={'Remove filter'}
              >
                <XIcon className="h-4 w-4 text-m-olive-300" />
              </ActionButtonWithTooltip>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

import { Float } from '@headlessui-float/react';
import { Menu } from '@headlessui/react';
import { isUndefined } from 'mathjs';
import { HorizontalBarChart } from '../../reports/components/HorizontalBarChart';
import { ReportChart } from '../../reports/components/ReportChart.tsx';
import { AliasPrefix } from '../../reports/store';
import { AttributesValue } from '../../reports/types';
import { FC, useCallback } from 'react';
import { QueryReportsCreateData, ReportsDetailData } from '@bigdelta/lib-api-client';
import ColorHash from 'color-hash';

import DotsIcon from '../../../assets/icons/dots-vertical.svg?react';
import XIcon from '../../../assets/icons/x.svg?react';
import { ChartType, ReportTypeVO, TimeGranularity } from '@bigdelta/lib-shared';

const colorHash = new ColorHash();

interface DashboardItemProps {
  reportType: ReportTypeVO;
  index: number;
  panelId: string;
  report: ReportsDetailData;
  granularity: TimeGranularity;
  queries?: QueryReportsCreateData['queries'];
  onViewReport: (report: ReportsDetailData) => void;
  onRemoveFromDashboard: (panelId: string) => void;
  breakdownPropertyPrefix?: AliasPrefix;
  hideMenu?: boolean;
}

export const DashboardItem: FC<DashboardItemProps> = ({
  reportType,
  index,
  panelId,
  report,
  granularity,
  queries,
  hideMenu,
  onViewReport,
  onRemoveFromDashboard,
  breakdownPropertyPrefix = AliasPrefix.RECORD_ATTRIBUTE,
}) => {
  const getGrouping = useCallback((groupingConfig: ReportsDetailData['grouping_config']) => {
    const selectedGroups = groupingConfig?.selected_groups;
    const groupProperty = groupingConfig?.groups?.[0];

    if (groupProperty?.attribute_id) {
      const breakdownVals = selectedGroups?.reduce((acc, selectedGroup) => {
        const property = selectedGroup.group_attributes?.[0];

        if (!property?.attribute_id) {
          return acc;
        }

        const breakdownProp = {
          property_name: property.attribute_name,
          property_id: property.attribute_id,
          property_value: property.attribute_value,
        };

        return [...acc, breakdownProp];
      }, [] as AttributesValue[]);

      if (!breakdownVals) {
        return {};
      }

      return {
        breakdownProperty: {
          property_name: groupProperty.attribute_name,
          property_id: groupProperty.attribute_id,
        },
        breakdownSelectedValues: breakdownVals,
        breakdownSelectedValuesColorMap: breakdownVals.reduce(
          (acc, val) => {
            acc[val.property_value] = !isUndefined(val.property_value) ? colorHash.hex(val.property_value) : '#000000';
            return acc;
          },
          {} as Record<string, string>
        ),
      };
    }

    return {};
  }, []);

  const chartType = report.query?.display_options?.chart_type ?? ChartType.LINE;

  const { breakdownProperty, breakdownSelectedValues, breakdownSelectedValuesColorMap } = getGrouping(report?.grouping_config);

  return (
    <div className="flex flex-col gap-y-5 rounded-lg border border-m-gray-300 p-5" key={report?.id}>
      <div className="flex items-center justify-between gap-x-2">
        <h3 className="cursor-pointer text-sm font-medium text-m-olive-900" onClick={() => onViewReport(report)}>
          {report?.title}
        </h3>
        {!hideMenu && (
          <Menu>
            <Float portal placement={(index + 1) % 3 === 0 ? 'left-start' : 'right-start'} offset={4}>
              <Menu.Button className="rounded text-m-olive-400 hover:bg-m-gray-300">
                <DotsIcon className="h-5 w-5" />
              </Menu.Button>
              <Menu.Items className="flex w-60 flex-col rounded-lg border border-m-gray-300 bg-m-white p-2 shadow-md">
                <Menu.Item>
                  <button
                    className="flex items-center justify-start gap-x-2 rounded-md px-2.5 py-2 text-md text-m-olive-800 hover:bg-m-gray-300"
                    onClick={() => onRemoveFromDashboard(panelId)}
                  >
                    <XIcon className="h-5 w-5 text-m-red-600" />
                    <span>Remove from dashboard</span>
                  </button>
                </Menu.Item>
              </Menu.Items>
            </Float>
          </Menu>
        )}
      </div>

      <div className="flex">
        {[ChartType.LINE, ChartType.STACKED].includes(chartType as ChartType) && (
          <ReportChart
            reportType={reportType}
            chartType={chartType as ChartType}
            dataQueries={queries}
            granularity={granularity}
            breakdownProperty={breakdownProperty}
            breakdownPropertyPrefix={breakdownPropertyPrefix}
            breakdownSelectedValues={breakdownSelectedValues}
            breakdownSelectedValuesColorMap={breakdownSelectedValuesColorMap}
            lineOptions={{
              scales: {
                y: {
                  position: 'right',
                  ticks: {
                    maxTicksLimit: 3,
                  },
                },
                x: {
                  display: false,
                },
              },
            }}
            stackedBarOptions={{
              scales: {
                y: {
                  position: 'right',
                  ticks: {
                    maxTicksLimit: 3,
                  },
                },
                x: {
                  display: false,
                },
              },
            }}
          />
        )}
        {chartType === ChartType.HORIZONTAL && (
          <HorizontalBarChart
            dataQuery={queries?.[0]}
            breakdownProperty={breakdownProperty}
            breakdownPropertyPrefix={breakdownPropertyPrefix}
            breakdownSelectedValues={breakdownSelectedValues}
            breakdownSelectedValuesColorMap={breakdownSelectedValuesColorMap}
          />
        )}
      </div>
    </div>
  );
};

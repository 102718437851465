import { forwardRef, InputHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import { cva, type VariantProps } from 'class-variance-authority';

const inputVariants = cva('', {
  variants: {
    size: {
      xl: 'text-lg',
      lg: 'text-md',
      md: 'text-sm',
      sm: 'text-sm',
      xs: 'text-xs',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> & VariantProps<typeof inputVariants>;

export const Input = forwardRef<HTMLInputElement, InputProps>(({ className, size, ...rest }, ref) => (
  <input
    ref={ref}
    className={twMerge('rounded-lg border-m-olive-100 px-4 py-2 focus:border-m-blue-600 disabled:text-m-olive-500 disabled:bg-gray-50 disabled:cursor-not-allowed', inputVariants({ size }), className)}
    {...rest}
  />
));

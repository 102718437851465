import { useInfiniteQuery } from '@tanstack/react-query';

import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

const LIMIT = 50;

interface UseRelatedEventsInfiniteQueryArgs {
  recordId?: string;
  workspaceId?: string;
  relationshipName?: string;
}

export const useRelatedEventsInfiniteQuery = ({ recordId, workspaceId, relationshipName }: UseRelatedEventsInfiniteQueryArgs) =>
  useInfiniteQuery({
    queryKey: ['record', recordId, 'events'],
    queryFn: async ({ pageParam = 0 }) => {
      const limit = LIMIT;
      const offset = pageParam * limit;
      return bigdeltaAPIClient.v1.queryEventsCreate(
        { workspace_id: workspaceId ?? '' },
        {
          query: {
            filter: {
              operator: 'and',
              conditions: [
                {
                  related_records: {
                    relationship_name: relationshipName ?? '',
                    filter: {
                      operator: 'and',
                      conditions: [
                        {
                          record_id: {
                            operator: 'equals',
                            value: recordId ?? '',
                          },
                        },
                      ],
                    },
                  },
                },
              ],
            },
          },
          time: {
            date_range_type: 'OVER_ALL_TIME',
          },
          limit,
          offset,
        }
      );
    },
    enabled: !!recordId && !!relationshipName && !!workspaceId,
    getNextPageParam: (lastPage, allPages) => {
      const nextPage = lastPage.items.length === LIMIT ? allPages.length : undefined;
      return nextPage;
    },
  });

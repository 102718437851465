import { FilterItem } from '../../store';
import { useObjectsQuery } from '../../../data/useObjectsQuery.ts';
import { RecordPropertyIcon } from '../../../components/RecordPropertyIcon.tsx';
import { capitalize } from 'lodash';

type RecordPropertyPathProps = {
  filterItem: FilterItem;
  workspaceId: string;
};

export function RecordPropertyPath({ filterItem, workspaceId }: RecordPropertyPathProps) {
  const { data: objectList } = useObjectsQuery({ workspaceId });
  const relationships = filterItem.propertyRelationships;
  const shouldTruncate = relationships.length > 2;

  const path = shouldTruncate
    ? relationships
        .slice(0, 1)
        .map((rel) => objectList?.objects.find((o) => o.id === rel.objectId))
        .filter(Boolean)
        .map((o) => capitalize(o.singular_noun))
        .concat(`(${relationships.length - 1})`)
        .join(' -> ')
    : relationships
        .map((rel) => objectList?.objects.find((o) => o.id === rel.objectId))
        .filter(Boolean)
        .map((o) => capitalize(o.singular_noun))
        .join(' -> ');

  return (
    <div className="flex flex-1 select-none items-center gap-2 overflow-hidden">
      <RecordPropertyIcon className="h-4 w-4 shrink-0 text-m-olive-300" propertyType={filterItem.property?.attributeType} />
      <span className="shrink-1 truncate text-sm text-m-olive-300">
        {path}
        {' -> '}
      </span>
      <span className="shrink-0 whitespace-nowrap text-sm accent-m-olive-700">{filterItem.property?.attributeName}</span>
    </div>
  );
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toApiKeyVO = exports.CreateApiKeyRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.CreateApiKeyRequestDef = typebox_1.Type.Object({
    account_id: typebox_1.Type.String({ format: 'uuid' }),
    name: typebox_1.Type.String(),
});
const toApiKeyVO = (createApiKeyRequest, scope, createdBy) => {
    return {
        accountId: createApiKeyRequest.account_id,
        name: createApiKeyRequest.name,
        scope: scope,
        createdBy: createdBy,
    };
};
exports.toApiKeyVO = toApiKeyVO;

import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Button } from '../../../../shared/ui/Button/Button';

interface WelcomeModalProps {
  onClose: () => void;
}

export const WelcomeModal: React.FC<WelcomeModalProps> = ({ onClose }) => {
  return (
    <Dialog.Root defaultOpen={true} onOpenChange={(open) => !open && onClose()}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-50 bg-m-black bg-opacity-50 backdrop-blur-sm" />
        <Dialog.Content className="fixed left-1/2 top-1/2 z-50 w-full max-w-2xl -translate-x-1/2 -translate-y-1/2 transform rounded-lg bg-m-white p-8 shadow-lg">
          <Dialog.Title className="mb-6 text-center text-display-sm text-m-olive-800">Welcome to Bigdelta</Dialog.Title>
          <Dialog.Description className="mb-8 text-md text-m-olive-600">
            We've added some companies to your company list. As more visitors come to your website, we'll identify companies behind them and populate
            the company list further.
          </Dialog.Description>
          <div className="flex justify-center">
            <Dialog.Close asChild>
              <Button intent="brand" size="lg" label="Start using Bigdelta" />
            </Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

import { FC } from 'react';
import FilterIcon from '../../../../assets/icons/filter-funnel-02.svg?react';
import { FilterItemType, useFilterStore } from '../../../../shared/filters/store';
import { useFilterContext } from '../../../../shared/filters/context/useFilterContext';
import { useTrendsBuilderStore } from '../../store/Report';
import { TrendsBuilderType } from '../../store/TrendsBuilder';
import { EventCount } from '../../store/common';

interface TrendsCountFilterButtonProps {
  id: string;
}

export const TrendsCountFilterButton: FC<TrendsCountFilterButtonProps> = ({ id }) => {
  const { filterKey } = useFilterContext();
  const count = useTrendsBuilderStore(id, (state) => state.data[TrendsBuilderType.OBJECT]?.count as EventCount);

  const event = count?.event?.data.event ?? count?.event?.data.property?.event;

  const addFilterItem = useFilterStore((state) => state.addFilterItem);

  return (
    <button
      className="invisible w-0 rounded-r-md border-m-olive-100 p-0 transition-opacity hover:bg-m-gray-300 group-hover/builder:visible group-hover/builder:w-auto group-hover/builder:border-l group-hover/builder:p-2.5"
      onClick={() => addFilterItem(filterKey, FilterItemType.TRENDS_COUNT_EVENT_PROPERTY, { event: event ?? undefined })}
    >
      <FilterIcon className="h-4 w-4 text-m-olive-300" />
    </button>
  );
};

import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface AutomationsState {
  createNodeData:
    | {
        source?: string;
        target?: string;
      }
    | undefined;
}

interface AutomationsActions {
  setCreateNodeData: (data: AutomationsState['createNodeData']) => void;
}

const initialState = {
  createNodeData: undefined,
};

export const useAutomationsStore = create<AutomationsState & AutomationsActions>()(
  immer((set) => ({
    ...initialState,
    setCreateNodeData: (data) => set(() => ({ createNodeData: data })),
  }))
);

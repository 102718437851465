import { NestedObjectDef } from '@bigdelta/lib-api-client';

type PropertyValue = NestedObjectDef[string];

export const renderValue = (value: PropertyValue) => {
  if (value === null || value === undefined || (Array.isArray(value) && value.length === 0)) {
    return '';
  }

  switch (typeof value) {
    case 'object':
      if (Array.isArray(value)) {
        return value.length === 1 ? `${value[0]}` : `${value[0]}...`;
      }
      return JSON.stringify(value);

    case 'number':
    case 'string':
      return value.toString();
    case 'boolean':
      return `${value}`;
    default:
      return '';
  }
};

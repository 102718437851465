"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetricVODef = exports.MetricQueryConfigurationVODef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const model_1 = require("../model");
const reportsQueryRequest_1 = require("./reportsQueryRequest");
const queryTypes_1 = require("./queryTypes");
const types_1 = require("../types");
const reportGroupingConfigVO_1 = require("./reportGroupingConfigVO");
exports.MetricQueryConfigurationVODef = typebox_1.Type.Object({
    metric_query: (0, typebox_2.OptionalNullable)(reportsQueryRequest_1.ReportsMetricQueryVODef),
    grouping: (0, typebox_2.OptionalNullable)(reportsQueryRequest_1.ReportsQueryGroupingVODef),
    grouping_config: (0, typebox_2.OptionalNullable)(reportGroupingConfigVO_1.ReportGroupingConfigVODef),
    time: (0, typebox_2.OptionalNullable)(typebox_1.Type.Partial(queryTypes_1.TimeRangeWithShiftVODef)),
});
exports.MetricVODef = typebox_1.Type.Composite([
    model_1.TimestampedModelDef,
    typebox_1.Type.Object({
        id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        key: typebox_1.Type.String(),
        accountId: typebox_1.Type.String({ format: 'uuid' }),
        workspaceId: typebox_1.Type.String({ format: 'uuid' }),
        description: typebox_1.Type.Optional(typebox_1.Type.String()),
        source: (0, typebox_2.OptionalNullable)(typebox_1.Type.Enum(types_1.MetricSource)),
        owner: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        createdBy: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        updatedBy: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        displayName: typebox_1.Type.String(),
        queryConfiguration: typebox_1.Type.Unsafe(exports.MetricQueryConfigurationVODef),
        systemManaged: typebox_1.Type.Boolean(),
    }),
]);

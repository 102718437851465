import { ButtonHTMLAttributes, FC } from 'react';
import FormulaIcon from '../../../../assets/icons/variable.svg?react';

// TODO: replace with ui/Button when fixed
export const AddFormulaButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = (props) => (
  <button
    className="flex items-center gap-x-1 rounded-md border border-transparent bg-m-white px-2 py-1 text-m-olive-700 hover:bg-m-gray-300 disabled:bg-m-white disabled:text-m-olive-400"
    {...props}
  >
    <FormulaIcon className="h-4 w-4 text-m-olive-400" />
    <span className="text-xs font-regular">Add formula</span>
  </button>
);

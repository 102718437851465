import { FC, ReactNode } from 'react';
import { SVGComponent } from '../../types';
import { twMerge } from '../../utils/twMerge';

interface WorkspaceObjectBlockProps {
  icon: SVGComponent;
  name: string;
  endSlot?: ReactNode;
  filters?: ReactNode;
}

export const WorkspaceObjectBlock: FC<WorkspaceObjectBlockProps> = ({ icon: Icon, name, endSlot, filters }) => (
  <div className="flex flex-col overflow-hidden">
    <div className={twMerge('flex items-center justify-between border border-m-olive-100 px-3 py-2', filters ? 'rounded-t-lg' : 'rounded-lg')}>
      <div className="flex items-center gap-x-2">
        <Icon className="h-4 w-4 shrink-0 text-m-olive-400" />
        <div className="truncate text-sm font-medium text-m-olive-700">{name}</div>
      </div>
      {endSlot && <div className="flex items-center justify-end">{endSlot}</div>}
    </div>
    {filters && <div className="rounded-b-lg">{filters}</div>}
  </div>
);

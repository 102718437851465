"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecordFilterResourceType = void 0;
var RecordFilterResourceType;
(function (RecordFilterResourceType) {
    RecordFilterResourceType["RECORD_ID"] = "record_id";
    RecordFilterResourceType["RECORD_FIELD"] = "record_field";
    RecordFilterResourceType["RECORD_PROPERTY"] = "record_property";
    RecordFilterResourceType["RELATED_EVENTS"] = "related_events";
    RecordFilterResourceType["RELATED_RECORDS"] = "related_records";
})(RecordFilterResourceType || (exports.RecordFilterResourceType = RecordFilterResourceType = {}));

import { MetadataResourcesPropertiesValuesListData, QueryReportsCreatePayload } from '@bigdelta/lib-api-client';
import { ChartType } from '@bigdelta/lib-shared';

// export type QueryInsightsGroupItem = Extract<QueryReportsCreatePayload['group'], Array<any>>[number];

export type QueryInsightsGroupItem = any;

export type AttributesValue = MetadataResourcesPropertiesValuesListData['items'][number];

export interface Datapoint {
  x: number | string;
  y: number;
  conversion_rate?: number;
  title?: string;
}
export interface Dataset {
  data: Datapoint[];
  color: string;
  regularColor: string;
}

export enum TemplateRelationship {
  EVENT_USERS = 'event_users',
  EVENT_WORKSPACES = 'event_workspaces',
  EVENT_ACCOUNTS = 'event_accounts',
}

export type ReportsQueryRecords = NonNullable<QueryReportsCreatePayload['metrics'][number]['records']>;
export type ReportsQueryEvents = NonNullable<QueryReportsCreatePayload['metrics'][number]['events']>;

import { FC, HTMLAttributes } from 'react';
import { AliasPrefix } from '../store';
import { useQueryReportsQuery } from '../data/useQueryReportsQuery.ts';
import { ReportChart } from './ReportChart.tsx';
import { ReportViewMode } from './ReportViewModeSeparator.tsx';
import { Button } from '../../../shared/ui/Button/Button.tsx';
import { twMerge } from 'tailwind-merge';
import { HorizontalBarChart } from './HorizontalBarChart.tsx';
import { ChartType, ReportTypeVO, TimeGranularity } from '@bigdelta/lib-shared';
import { useReportQuery } from '../data/useReportQuery.ts';
import { PropertyNameObject } from '../../../shared/types.ts';

export type ReportChartViewProps = HTMLAttributes<HTMLElement> & {
  reportType: ReportTypeVO;
  workspaceId?: string;
  showOverlay: boolean;
  chartType: ChartType;
  reportViewMode: ReportViewMode;
  onGenerateReport: () => any;
  hideChart?: boolean;
  reportQuery: ReturnType<typeof useQueryReportsQuery> | ReturnType<typeof useReportQuery>;
  isHasSource: boolean;
  timeGranularity: TimeGranularity;
  breakdownSelectedValues?: { property_value: string }[];
  breakdownProperty?: PropertyNameObject;
  breakdownPropertyPrefix?: AliasPrefix;
  breakdownSelectedValuesColorMap?: Record<string, string>;
};

export const ReportChartView: FC<ReportChartViewProps> = ({
  reportType,
  reportQuery,
  chartType,
  reportViewMode,
  hideChart,
  showOverlay,
  onGenerateReport,
  className,
  isHasSource,
  timeGranularity,
  breakdownSelectedValues,
  breakdownProperty,
  breakdownPropertyPrefix,
  breakdownSelectedValuesColorMap,
}) => {
  return (
    <div className={twMerge('relative', className)}>
      {!hideChart && (chartType === ChartType.LINE || chartType === ChartType.STACKED) && (
        <ReportChart
          reportType={reportType}
          chartType={chartType}
          reportViewMode={reportViewMode}
          dataQueries={reportQuery.data?.queries}
          granularity={timeGranularity}
          breakdownProperty={breakdownProperty}
          breakdownPropertyPrefix={breakdownPropertyPrefix}
          breakdownSelectedValues={breakdownSelectedValues}
          breakdownSelectedValuesColorMap={breakdownSelectedValuesColorMap}
        />
      )}
      {!hideChart && chartType === ChartType.HORIZONTAL && (
        <HorizontalBarChart
          dataQuery={reportQuery.data?.queries[0]}
          breakdownProperty={breakdownProperty}
          breakdownPropertyPrefix={breakdownPropertyPrefix}
          breakdownSelectedValues={breakdownSelectedValues}
          breakdownSelectedValuesColorMap={breakdownSelectedValuesColorMap}
        />
      )}
      {!reportQuery.isSuccess && (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          {!isHasSource && (
            <div className="rounded-lg bg-m-gray-300 p-4 text-center text-sm font-medium text-m-olive-500">{'Add data source to build a report'}</div>
          )}
          {isHasSource && (
            <Button
              label={
                reportQuery.isFetching ? (
                  <div className="flex items-center">
                    <span>Building report</span>
                  </div>
                ) : (
                  'Build report'
                )
              }
              intent="brand"
              size="xl"
              onClick={onGenerateReport}
            />
          )}
        </div>
      )}
      {isHasSource && showOverlay && (
        <div className="absolute top-0 flex h-full min-w-full items-center justify-center bg-m-white opacity-90">
          <Button label="Update report" intent="brand" size="xl" onClick={onGenerateReport} />
        </div>
      )}
    </div>
  );
};

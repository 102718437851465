/* eslint-disable @typescript-eslint/no-var-requires */
/** @type {import('tailwindcss').Config} */
/*eslint-env node*/
// eslint-disable-next-line @typescript-eslint/no-var-requires, no-undef
// const defaultTheme = require('tailwindcss/defaultTheme');
import defaultTheme from 'tailwindcss/defaultTheme';

import tailwindForms from '@tailwindcss/forms';
import tailwindRadix from 'tailwindcss-radix';
import tailwindHeadless from '@headlessui/tailwindcss';

export default {
  content: ['./index.html', './src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    fontWeight: {
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
    },
    fontSize: {
      xxs: ['11px', '14px'],
      xs: ['12px', '18px'],
      sm: ['13px', '20px'],
      md: ['14px', '20px'],
      lg: ['16px', '24px'],
      xl: ['18px', '28px'],
      '2xl': ['20px', '30px'],
      'display-xs': ['24px', '32px'],
      'display-sm': ['30px', '38px'],
      'display-md': ['36px', '44px'],
      'display-lg': ['48px', '60px'],
      'display-xl': ['60px', '72px'],
      'display-2xl': ['72px', '90px'],
    },
    extend: {
      fontFamily: {
        sans: ['"Inter"', ...defaultTheme.fontFamily.sans],
      },
      colors: {
        'm-white': '#FFFFFF',
        'm-black': '#000000',
        'm-lime': {
          100: '#FBFDED',
          200: '#F7FBDB',
          300: '#EFF7B7',
          400: '#E8F393',
          500: '#E0EF6F',
          600: '#E2F362',
          700: '#CAE216',
          800: '#828D2D',
          900: '#565E1E',
          1000: '#2B2F0F',
        },
        'm-blue': {
          100: '#EFF1FC',
          200: '#DEE2F9',
          300: '#BDC6F2',
          400: '#9DA9EC',
          500: '#7C8DE5',
          600: '#5B70DF',
          700: '#495AB2',
          800: '#374386',
          900: '#242D59',
          1000: '#12162D',
        },
        'm-olive': {
          100: '#EBECEA',
          200: '#D8D8D4',
          300: '#B0B1A9',
          400: '#898A7E',
          500: '#616353',
          600: '#3A3C28',
          700: '#2E3020',
          800: '#232418',
          900: '#171810',
          1000: '#0C0C08',
        },
        'm-red': {
          100: '#FDF0F0',
          200: '#FBE1E1',
          300: '#F8C3C4',
          400: '#F4A5A6',
          500: '#F18789',
          600: '#EF5C5E',
          700: '#BE5456',
          800: '#8E3F40',
          900: '#5F2A2B',
          1000: '#2F1515',
        },
        'm-orange': {
          100: '#FDF5EC',
          200: '#FCECD9',
          300: '#F8D8B3',
          400: '#F5C58E',
          500: '#F1B168',
          600: '#EE9E42',
          700: '#BE7E35',
          800: '#8F5F28',
          900: '#5F3F1A',
          1000: '#30200D',
        },
        'm-beige': {
          100: '#FDFCF7',
          200: '#FBF8EF',
          300: '#F7F1DF',
          400: '#F3EBCF',
          500: '#EFE4BF',
          600: '#EBDDAF',
          700: '#BCB18C',
          800: '#8D8569',
          900: '#5E5846',
          1000: '#2F2C23',
        },
        'm-green': {
          100: '#F1FAEE',
          200: '#E4F4DE',
          300: '#C8E9BC',
          400: '#ADDF9B',
          500: '#91D479',
          600: '#76C958',
          700: '#5EA146',
          800: '#477935',
          900: '#2F5023',
          1000: '#182812',
        },
        'm-gray': {
          100: '#FEFEFE',
          200: '#FAFAFA',
          300: '#F4F4F4',
          400: '#D4D4D2',
          500: '#B3B3B0',
          600: '#8A8B86',
          700: '#5B5C54',
          800: '#33342C',
          900: '#23231E',
          1000: '#11120F',
        },
        'm-coconut': {
          100: '#FEFDFD',
          200: '#FCFBFA',
          300: '#F9F8F5',
          400: '#F6F4F0',
          500: '#F3F1EB',
          600: '#F0EDE6',
          700: '#E8E3D9',
          800: '#908E8A',
          900: '#605F5C',
          1000: '#302F2E',
        },
      },
      keyframes: {
        'background-move': {
          '0%, 100%': { 'background-position': '0% 50%' },
          '50%': { 'background-position': '100% 50%' },
        },
      },
      animation: {
        'background-move': 'background-move 2s ease infinite',
      },
      boxShadow: {
        border: 'inset 0px 0px 0px 0.5px var(--shadow-color)',
        'tab-active': 'inset 0 -1px 0 0 var(--shadow-color), 0 1px 0 0 var(--shadow-color)',
      },
    },
  },
  plugins: [tailwindForms, tailwindRadix, tailwindHeadless],
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toCreateWorkspaceVO = exports.WorkspaceOnboardingRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const types_1 = require("../types");
exports.WorkspaceOnboardingRequestDef = typebox_1.Type.Object({
    name: typebox_1.Type.String(),
    business_model: typebox_1.Type.Enum(types_1.BusinessModel),
    roles: typebox_1.Type.Optional(typebox_1.Type.Array(typebox_1.Type.String())),
    industries: typebox_1.Type.Optional(typebox_1.Type.Array(typebox_1.Type.String())),
});
const toCreateWorkspaceVO = (request, accountId) => {
    return {
        accountId: accountId,
        business_model: request.business_model,
        name: request.name,
        roles: request.roles,
        industries: request.industries,
    };
};
exports.toCreateWorkspaceVO = toCreateWorkspaceVO;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserOnboardingRequestDef = exports.MemberPasswordDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
exports.MemberPasswordDef = typebox_1.Type.String({
    minLength: 8,
    errorMessage: 'Password must consist of at least 8 characters',
});
exports.UserOnboardingRequestDef = typebox_1.Type.Object({
    first_name: typebox_1.Type.String(),
    last_name: typebox_1.Type.String(),
    password: (0, typebox_2.OptionalNullable)(exports.MemberPasswordDef),
});

import React, { FC, HTMLAttributes, useCallback, useEffect } from 'react';
import { FormInput } from '../../shared/ui/FormInput/FormInput.tsx';
import { useForm } from 'react-hook-form';
import { MetricFormSchema } from './MetricFormSchema.ts';
import { twMerge } from 'tailwind-merge';
import { ReportBuilderWithChartParameters, useReportQueryBuilderWithChart } from '../../features/reports/hooks/useReportQueryBuilderWithChart.tsx';
import { ButtonComponentProps } from '../../shared/ui/Button/Button.tsx';
import { getMetrics } from '../../features/reports/utils/getMetrics.ts';
import { useReportStore } from '../../features/reports/store';
import { useFilterStore } from '../../shared/filters/store';
import { getMetricStateKey } from '../../features/reports/utils/getMetricStateKey.ts';
import { getGrouping } from '../../features/reports/utils/getGrouping.ts';
import { getGroupingConfig } from '../../features/reports/utils/getGroupingConfig.ts';
import { CurrentWorkspaceMemberFormSelect } from '../../shared/ui/FormSelect/CurrentWorkspaceMemberFormSelect.tsx';
import { ReportStoreContext } from '../../features/reports/context/reportStoreContext.ts';
import { ChartType } from '@bigdelta/lib-shared';

interface Props extends HTMLAttributes<HTMLElement> {
  metricId?: string | null;
  disabled?: boolean;
  defaultValues?: MetricFormSchema;
  primaryAction?: ButtonComponentProps;
  renderSubmitButton?: (handleSubmit: (handler: (data: MetricFormSchema) => any) => any, disableSave: boolean) => React.ReactNode;
  reportBuilderParams?: Partial<ReportBuilderWithChartParameters>;
}

export const MetricForm: FC<Props> = ({ metricId, disabled, className, defaultValues, renderSubmitButton, reportBuilderParams }) => {
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<MetricFormSchema>({
    defaultValues,
  });

  const chartType = ChartType.LINE;
  const { report, builder, showOverlay, isSuccess, filterKey, reportKey } = useReportQueryBuilderWithChart({
    ...reportBuilderParams,
    inline: true,
    metricId,
    chartType,
    wrapInFormula: true,
    autoUpdateQueryConfig: true,
    reportBuilderProps: { selectorIntent: 'secondary' },
  });
  const filter = useFilterStore((state) => state.getFilter(filterKey));

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);
  const { reportType, config: reportConfigState, query: queryState, breakdownConfig } = useReportStore(reportKey)((state) => state);

  const onSubmit = useCallback(
    (handler) => {
      return (data: MetricFormSchema) => {
        const { metricType } = reportConfigState;
        const { breakdownProperty } = queryState;
        const queryMetricId = queryState[getMetricStateKey(metricType)];
        const [metricQuery] = getMetrics(reportType, metricType, queryMetricId, filter, reportConfigState) ?? [];
        const grouping = getGrouping(metricType, breakdownProperty, chartType);
        const groupingConfig = getGroupingConfig(breakdownConfig, breakdownProperty) ?? {
          groups: undefined,
          selected_groups: undefined,
        };

        const result: MetricFormSchema = {
          display_name: data.display_name,
          owner: data.owner,
          query_configuration: {
            metric_query: metricQuery,
            grouping,
            grouping_config: groupingConfig,
          },
          description: data.description,
        };
        return handler(result);
      };
    },
    [reportType, breakdownConfig, chartType, filter, queryState, reportConfigState]
  );

  return (
    <ReportStoreContext.Provider value={{ reportKey }}>
      <div className={twMerge('relative flex flex-col gap-4 p-6', className)}>
        <FormInput
          label="Metric name"
          inputProps={{
            ...register('display_name', { required: true }),
            placeholder: 'Metric name',
            disabled,
          }}
          errorMessage={errors.display_name?.message}
        />
        <CurrentWorkspaceMemberFormSelect
          label="Owner"
          selectProps={{ ...register('owner', { required: true }), size: 'sm', placeholder: 'Select owner', disabled }}
          errorMessage={errors.owner?.message}
        />
        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-between text-sm font-medium">
            <div>Chart</div>
            {/*<ChevronDownIcon className="w-4 h-4" />*/}
          </div>
          <div className={twMerge('relative rounded-lg border border-m-olive-100 p-4')}>{report}</div>
        </div>
        {!disabled && (
          <div className="flex flex-col gap-4">
            <div className="text-sm font-medium">Data set</div>
            {builder}
          </div>
        )}
        {renderSubmitButton && renderSubmitButton((handler) => handleSubmit(onSubmit(handler)), showOverlay || !isSuccess)}
      </div>
    </ReportStoreContext.Provider>
  );
};

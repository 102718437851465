"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BusinessModel = void 0;
var BusinessModel;
(function (BusinessModel) {
    BusinessModel["B2B_SAAS"] = "b2b saas";
    BusinessModel["MARKETPLACE"] = "marketplace";
    BusinessModel["ECOMMERCE"] = "ecommerce";
    BusinessModel["CONSUMER_SUBSCRIPTIONS"] = "consumer subscriptions";
    BusinessModel["DEMO"] = "demo";
    BusinessModel["NOT_SELECTED"] = "not selected";
})(BusinessModel || (exports.BusinessModel = BusinessModel = {}));

import { HTMLAttributes } from 'react';
import { twMerge } from '../../../../utils/twMerge.ts';

export function WhiteElevatedCard({ children, className, ...restProps }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={twMerge('flex items-center justify-between gap-2 rounded border border-m-olive-100 bg-white py-1 pl-1 pr-2.5 shadow-sm', className)}
      {...restProps}
    >
      {children}
    </div>
  );
}

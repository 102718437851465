import { ButtonHTMLAttributes, FC } from 'react';
import { Switch } from '@headlessui/react';

interface ToggleProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onChange' | 'value'> {
  enabled: boolean;
  onChange: (enabled: boolean) => void;
  deactivated?: boolean;
}

export const Toggle: FC<ToggleProps> = ({ onChange, enabled, deactivated, ...props }) => {
  return (
    <Switch
      {...props}
      disabled={deactivated || false}
      checked={enabled}
      onChange={onChange}
      className={`${enabled ? 'bg-m-blue-600' : 'bg-m-gray-500'}
          relative inline-flex h-[16px] w-[30px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={`${enabled ? 'translate-x-3.5' : 'translate-x-0'}
            pointer-events-none inline-block h-[12px] w-[12px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
      />
    </Switch>
  );
};

import { createContext, PropsWithChildren, useContext } from 'react';
import { ButtonIntent } from '../../../shared/ui/Button/Button.tsx';
import { FilterKey } from '../../../shared/filters/store';

export interface ReportMetricContextType {
  formulaReference?: string;
  inline?: boolean;
  selectorIntent?: ButtonIntent;
  disableCreateMetric?: boolean;
  disableRemoval?: boolean;
  filterKey: FilterKey;
  onRemoveMetric?: () => void;
}

type Props = PropsWithChildren & ReportMetricContextType;

const defaultValues: ReportMetricContextType = {
  inline: false,
  disableCreateMetric: false,
  filterKey: null,
  formulaReference: null,
};
export const ReportMetricContext = createContext<ReportMetricContextType | null>(defaultValues);

export const ReportMetricProvider: React.FC<Props> = ({ children, ...rest }) => {
  return <ReportMetricContext.Provider value={rest}>{children}</ReportMetricContext.Provider>;
};

export const useReportMetric = (): ReportMetricContextType => {
  const context = useContext(ReportMetricContext);
  if (!context) {
    return defaultValues;
  }

  return context;
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetricGraphVODef = void 0;
const model_1 = require("../model");
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
exports.MetricGraphVODef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        workspace_id: typebox_1.Type.String({ format: 'uuid' }),
        name: typebox_1.Type.String(),
        created_by: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        updated_by: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    }),
    model_1.TimestampedModelDef,
]);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetricGraphPlanViewDef = exports.CreateMetricGraphPlanRequestDef = exports.MetricGraphPlanScenarioDef = exports.MetricGraphPlanDef = void 0;
const timestampedModel_1 = require("./timestampedModel");
const typebox_1 = require("@sinclair/typebox");
const metricScenario_1 = require("./metricScenario");
exports.MetricGraphPlanDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: typebox_1.Type.String(),
        workspace_id: typebox_1.Type.String(),
        name: typebox_1.Type.Optional(typebox_1.Type.String()),
        metric_graph_id: typebox_1.Type.String(),
    }),
    timestampedModel_1.TimestampedModelDef,
]);
exports.MetricGraphPlanScenarioDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: typebox_1.Type.String(),
        workspace_id: typebox_1.Type.String(),
        metric_graph_plan_id: typebox_1.Type.String(),
        metric_scenario_id: typebox_1.Type.String(),
    }),
    timestampedModel_1.TimestampedModelDef,
]);
exports.CreateMetricGraphPlanRequestDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        workspace_id: typebox_1.Type.String(),
        name: typebox_1.Type.Optional(typebox_1.Type.String()),
        metric_graph_id: typebox_1.Type.String(),
        metric_scenarios: typebox_1.Type.Array(metricScenario_1.CreateMetricScenarioRequestDef),
    }),
    timestampedModel_1.TimestampedModelDef,
]);
exports.MetricGraphPlanViewDef = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    workspace_id: typebox_1.Type.String(),
    name: typebox_1.Type.Optional(typebox_1.Type.String()),
    metric_graph_id: typebox_1.Type.String(),
    metric_scenarios: typebox_1.Type.Array(typebox_1.Type.Omit(metricScenario_1.MetricScenarioDef, ['systemCreatedAt', 'systemUpdatedAt'])),
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toInsightVO = exports.CreateInsightRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const types_1 = require("../types");
exports.CreateInsightRequestDef = typebox_1.Type.Object({
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    title: typebox_1.Type.Optional(typebox_1.Type.String()),
    description: typebox_1.Type.Optional(typebox_1.Type.String()),
    details: typebox_1.Type.Optional(typebox_1.Type.String()),
    owner: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
    document_group_ids: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(typebox_1.Type.String({ format: 'uuid' }))),
    type: typebox_1.Type.Enum(types_1.InsightTypeVO),
    source: typebox_1.Type.Optional(typebox_1.Type.String()),
});
const toInsightVO = (insight) => {
    return {
        workspaceId: insight.workspace_id,
        title: insight.title,
        description: insight.description,
        details: insight.details,
        owner: insight.owner,
        documentGroupIds: insight.document_group_ids,
        source: insight.source,
        type: insight.type,
    };
};
exports.toInsightVO = toInsightVO;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateStandardWorkspaceObjectRecordVODef = void 0;
const model_1 = require("../model");
const typebox_1 = require("@sinclair/typebox");
const createWorkspaceObjectRecordVO_1 = require("./createWorkspaceObjectRecordVO");
exports.CreateStandardWorkspaceObjectRecordVODef = typebox_1.Type.Composite([
    typebox_1.Type.Omit(createWorkspaceObjectRecordVO_1.CreateWorkspaceObjectRecordVODef, ['workspaceObjectIdOrSlug']),
    typebox_1.Type.Object({ objectType: typebox_1.Type.Enum(model_1.WorkspaceObjectType) }),
]);

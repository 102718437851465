import { ChangeEvent, FC } from 'react';
import { useFilterContext } from '../../context/useFilterContext';
import { useFilterStore } from '../../store';
import { Control } from './Control';

interface EventTimeframeAfterProps {
  index: number;
}

export const EventTimeframeAfter: FC<EventTimeframeAfterProps> = ({ index }) => {
  const { filterKey } = useFilterContext();
  const filterItem = useFilterStore((state) => state.getFilterItem([...filterKey, index]));
  const setItemEventTimeframeStartAt = useFilterStore((state) => state.setItemEventTimeframeStartAt);

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setItemEventTimeframeStartAt(filterKey, index, value);
  };

  if (filterItem?.timeframe.dateRangeType !== 'AFTER') {
    return null;
  }

  return (
    <Control>
      <input
        type="date"
        className="rounded border-0 bg-transparent text-[12px] text-sm font-regular text-m-olive-400 ring-transparent focus:border-0 focus:text-m-olive-600 focus:ring-transparent group-hover:bg-[#f0f0f0]"
        onChange={handleChange}
        value={filterItem.timeframe.startAt ?? ''}
      />
    </Control>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportTemplatesQueryRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const types_1 = require("../types");
const reportsQueryRequest_1 = require("./reportsQueryRequest");
const queryTypes_1 = require("./queryTypes");
exports.ReportTemplatesQueryRequestDef = typebox_1.Type.Object({
    type: types_1.TemplateTypeDef,
    query: typebox_1.Type.Object({
        [types_1.TemplateType.CUSTOMER_ACTIVATION_NEW_SIGNUPS]: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
            relationship_name: typebox_1.Type.String(),
            events: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
                filter: typebox_1.Type.Optional(typebox_1.Type.Unsafe(typebox_1.Type.Ref('EventsQueryRequestQueryDef'))),
            })),
        })),
        [types_1.TemplateType.CUSTOMER_ACTIVATION_ACTIVATION_FLOW]: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
            relationship_name: typebox_1.Type.String(),
            funnel: typebox_1.Type.Object({
                sequence_completion_window: (0, typebox_2.OptionalNullable)(queryTypes_1.TimeRangeWindowVODef),
                event_sequence: typebox_1.Type.Array(reportsQueryRequest_1.EventSequenceStepVODef, {
                    minItems: 1,
                }),
            }),
        })),
        [types_1.TemplateType.CUSTOMER_ACTIVATION_ACTIVE_USERS]: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
            relationship_name: typebox_1.Type.String(),
            events: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
                filter: typebox_1.Type.Optional(typebox_1.Type.Unsafe(typebox_1.Type.Ref('EventsQueryRequestQueryDef'))),
            })),
        })),
        [types_1.TemplateType.CUSTOMER_RETENTION_RETENTION]: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
            relationship_name: typebox_1.Type.String(),
            entry_event: reportsQueryRequest_1.ReportsRetentionMetricEventQueryVODef,
            retention_event: reportsQueryRequest_1.ReportsRetentionMetricEventQueryVODef,
        })),
        [types_1.TemplateType.PRODUCT_USAGE_FEATURE_REPORT]: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
            relationship_name: typebox_1.Type.String(),
            feature_adoption_event: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
                filter: typebox_1.Type.Optional(typebox_1.Type.Unsafe(typebox_1.Type.Ref('EventsQueryRequestQueryDef'))),
            })),
            retention_period: typebox_1.Type.Unsafe((0, typebox_2.StringLiterals)([queryTypes_1.TimeGranularity.DAY, queryTypes_1.TimeGranularity.WEEK, queryTypes_1.TimeGranularity.MONTH])),
        })),
        [types_1.TemplateType.MAPS_NEW_SIGNUPS]: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
            relationship_name: typebox_1.Type.String(),
            signup_event: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
                filter: typebox_1.Type.Optional(typebox_1.Type.Unsafe(typebox_1.Type.Ref('EventsQueryRequestQueryDef'))),
            })),
        })),
        [types_1.TemplateType.MAPS_ACTIVE_CUSTOMERS]: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
            relationship_name: typebox_1.Type.String(),
            events: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
                filter: typebox_1.Type.Optional(typebox_1.Type.Unsafe(typebox_1.Type.Ref('EventsQueryRequestQueryDef'))),
            })),
        })),
        [types_1.TemplateType.WEBSITE_WEBSITE]: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
            pageview_event: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
                filter: typebox_1.Type.Optional(typebox_1.Type.Unsafe(typebox_1.Type.Ref('EventsQueryRequestQueryDef'))),
            })),
            retention_period: typebox_1.Type.Unsafe((0, typebox_2.StringLiterals)([queryTypes_1.TimeGranularity.DAY, queryTypes_1.TimeGranularity.WEEK, queryTypes_1.TimeGranularity.MONTH])),
        })),
        [types_1.TemplateType.RECURRING_REVENUE]: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
            relationship_name: typebox_1.Type.String(),
        })),
    }),
});
